import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation, UiSchema } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.moveRequestUpdate.types";

export const getSchema =
  (initialValues: { [key: string]: any }, _: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [],
      properties: {
        [EField.NoteTelco]: {
          default: getValue(EField.NoteTelco, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): UiSchema => ({
  [EField.NoteTelco]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.note_telco.title"),
    multiline: true,
    optional: true,
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    return errors;
  };
