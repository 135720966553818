import releaseRentDepositForm from "@rentiohq/shared-frontend/dist/forms/releaseRentDeposit";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/releaseRentDeposit/schema.releaseRentDeposit.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import logger from "@rentiohq/shared/dist/logger";
import { IMember } from "@rentiohq/shared/dist/types/auth.types";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import { EContractMemberTypes } from "@rentiohq/shared/dist/types/contract.types";
import {
  Card,
  Error,
  Loading,
  MultiStepForm,
  Page,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ts } from "../../services";
const formId = generateFormId();

export const RentDepositReleaseForm: FC<any> = ({ contract }) => {
  const [signers, setSigners] = useState<IMember<EContractMemberTypes>[]>();
  const params = useParams<{ propertyId: string; rentDepositId: string }>();
  const rentDepositId = params.rentDepositId
    ? +params.rentDepositId
    : undefined;
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { internalModeEnabled } = useInternalMode();

  const rentDeposit = useSelector((state: IRootStore) =>
    rentDepositId ? state.rentDeposit.rentDeposits[rentDepositId] : undefined,
  );
  const rentDepositFetchError = useSelector((state: IRootStore) =>
    rentDepositId
      ? state.rentDeposit.rentDepositsById[rentDepositId]?.fetchError
      : undefined,
  );

  const propertyIdToFetch = propertyId || rentDeposit?.propertyId;
  const { detail: property, isFetching: isFetchingProperty } =
    propertyHooks.useDetail({
      id: propertyIdToFetch,
    });

  const { isBroker } = authHooks.useSelf();

  const contactMeMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactMeMaster(state),
  );

  const legalContact = useSelector((state: IRootStore) =>
    isBroker
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.Legal)
      : undefined,
  );
  const isFetchingLegalContact = useSelector((state: IRootStore) =>
    isBroker
      ? state.contact.contactsById[EContactCustomId.Legal].isFetching
      : false,
  );

  useEffect(() => {
    if (!rentDepositId) {
      return;
    }

    dispatch(
      rentDepositActions.getRentDeposit.actions.start({
        rentDepositId,
      }),
    );
  }, [rentDepositId]);

  useEffect(() => {
    if (!rentDeposit) {
      return;
    }

    if (rentDeposit.rentDepositContractIn) {
      const s = rentDeposit.rentDepositContractIn.members.filter(m =>
        m.roles.includes(EContractMemberTypes.Signer),
      );
      dispatch(
        contactActions.getContactsByAccountIds.actions.start({
          accountIds: s.map(s => s.account.id),
        }),
      );
      setSigners(s);
    }
  }, [rentDeposit]);

  const handleSubmit = (rentDepositId: number) => {
    if (propertyId) {
      navigate(`/properties/${propertyId}?rentDepositId=${rentDepositId}`);
    } else {
      navigate(
        `/follow-up/rent-deposits?page=1&rentDepositId=${rentDepositId}`,
      );
    }
  };

  const signerContacts: IContact[] | null = useSelector(
    (state: IPartialRootState) => {
      if (!signers) return null;
      return signers.reduce<IContact[]>((allSigners, signer) => {
        const contact = contactSelectors.getContact(
          state,
          undefined,
          signer.account.id,
        );
        if (contact) allSigners.push(contact);
        return allSigners;
      }, []);
    },
  );

  if (!rentDeposit || isFetchingLegalContact) {
    return <Loading />;
  }

  if (!property && isFetchingProperty) {
    return <Loading />;
  }

  if (rentDepositFetchError) {
    logger.logError({ error: rentDepositFetchError });
    return <Error errors={[rentDepositFetchError]} />;
  }

  if (!rentDepositId) {
    return null;
  }

  const rentDepositContract = rentDeposit.rentDepositContractIn;
  if (!signerContacts || !property || !rentDepositContract) {
    return null;
  }

  return (
    <Page
      title={ts.rentDepositReleaseHeading()}
      subtitle={rentDeposit.property && formatAddress(rentDeposit.property)}
      breadcrumbs={{
        to: !!propertyId
          ? `/properties/${propertyId}`
          : "/follow-up/rent-deposits?page=1",
        content: !!propertyId
          ? ts.contractDetailBreadcrumb()
          : ts.followUpRentDepositsBreadcrumb(),
      }}
    >
      <Card>
        <MultiStepForm
          formId={`release-rent-deposit-form-${formId}`}
          schemas={releaseRentDepositForm({
            relatedContract: rentDepositContract,
            isBroker,
            legalContact,
            contactMeMaster,
            signerContacts,
            internalModeEnabled,
          })}
          withSummary={true}
          activeStep={contract ? "summary" : undefined}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector()}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector()}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector()}
          submitActionCreator={(formData: any) => {
            return submitActionCreator(
              rentDepositId,
              rentDepositContract,
              property,
            )(formData);
          }}
          onSuccess={handleSubmit}
        />
      </Card>
    </Page>
  );
};
