import { ITask } from "@rentiohq/shared/dist/types/task.types";
import React from "react";
import { DocumentOverview } from "../../../../components/Documents";

interface IProps {
  taskId: number;
  task: ITask;
}

export const TaskDocumentsOverview: React.FC<IProps> = ({ task }) => (
  <DocumentOverview
    extraFilterData={{ taskId: { eq: task.id } }}
    passPropsDocumentDetail={{ taskId: task.id }}
    withTemplates={false}
  />
);
