import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField, ETemplateDocumentContractCreateOption, } from "./schema.createContract.types";
import { getNavigationTitle } from "./schema.createContract.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var _b;
        return ({
            required: [EField.TemplateDocumentContractCreateOption],
            properties: (_a = {},
                _a[EField.Header + "7"] = {
                    default: undefined,
                },
                _a[EField.TemplateDocumentContractCreateOption] = {
                    default: getValue(EField.TemplateDocumentContractCreateOption, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(ETemplateDocumentContractCreateOption).map(function (templateDocumentContractCreateOption) { return ({
                        id: templateDocumentContractCreateOption,
                        value: getLocalizedText("contract.template_document_contract.option.label.".concat(templateDocumentContractCreateOption)),
                    }); }),
                },
                _a[EField.TemplateDocumentContractCreateOptionInfo + 1] = {
                    showIf: function (formData) {
                        return formData[EField.TemplateDocumentContractCreateOption] ===
                            ETemplateDocumentContractCreateOption.None;
                    },
                },
                _a[EField.TemplateDocumentContractCreateOptionInfo + 2] = {
                    showIf: function (formData) {
                        return formData[EField.TemplateDocumentContractCreateOption] ===
                            ETemplateDocumentContractCreateOption.Create;
                    },
                },
                _a[EField.TemplateDocumentContractCreateOptionInfo + 3] = {
                    showIf: function (formData) {
                        return formData[EField.TemplateDocumentContractCreateOption] ===
                            ETemplateDocumentContractCreateOption.CreateAndSign;
                    },
                },
                _a[EField.TemplateDocumentSelected] = {
                    default: getValue(EField.TemplateDocumentSelected, initialValues, defaultValues),
                    // @ts-ignore
                    options: (_b = extraData.templates) === null || _b === void 0 ? void 0 : _b.map(function (template) { return ({
                        id: template.id,
                        value: template.name,
                    }); }),
                    requiredIf: function (formData) {
                        return formData[EField.TemplateDocumentContractCreateOption] !==
                            ETemplateDocumentContractCreateOption.None;
                    },
                    showIf: function (formData) {
                        return formData[EField.TemplateDocumentContractCreateOption] !==
                            ETemplateDocumentContractCreateOption.None;
                    },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("contract_info.add.template_document_contract.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "7"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.add.template_document_contract.heading"),
        },
        _a[EField.TemplateDocumentContractCreateOption] = {
            "ui:field": ERentioFormField.InlineSelect,
            summaryTitle: getLocalizedText("contract.template_document_contract.option.title.summary"),
            formatSummary: function (formData) {
                return getLocalizedText("contract.template_document_contract.option.label.".concat(formData));
            },
        },
        _a[EField.TemplateDocumentContractCreateOptionInfo + 1] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("contract.template_document_contract.option.info.".concat(ETemplateDocumentContractCreateOption.None)),
        },
        _a[EField.TemplateDocumentContractCreateOptionInfo + 2] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("contract.template_document_contract.option.info.".concat(ETemplateDocumentContractCreateOption.Create)),
        },
        _a[EField.TemplateDocumentContractCreateOptionInfo + 3] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("contract.template_document_contract.option.info.".concat(ETemplateDocumentContractCreateOption.CreateAndSign)),
        },
        _a[EField.TemplateDocumentSelected] = {
            "ui:field": ERentioFormField.DropdownSelect,
            formatSummary: function (formData) {
                var _a;
                if (!formData) {
                    return;
                }
                var template = (_a = extraData.templates) === null || _a === void 0 ? void 0 : _a.find(function (template) { return template.id === formData; });
                return template === null || template === void 0 ? void 0 : template.name;
            },
            title: getLocalizedText("contract.template_document_contract.template.title"),
            summaryTitle: getLocalizedText("contract.template_document_contract.template.title.summary"),
        },
        _a;
};
