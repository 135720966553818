import { BlockMapBuilder, CharacterMetadata, ContentBlock, EditorState, Modifier, } from "draft-js";
import { List, Repeat } from "immutable";
var seenKeys = {};
var MULTIPLIER = Math.pow(2, 24);
// TODO: Copied from draftjs source code
var generateRandomKey = function () {
    var key;
    while (key === undefined || seenKeys.hasOwnProperty(key) || !isNaN(+key)) {
        key = Math.floor(Math.random() * MULTIPLIER).toString(32);
    }
    seenKeys[key] = true;
    return key;
};
// TODO: Copied from draftjs source code
export var insertAtomicBlock = function (params) {
    var editorState = params.editorState, entityKey = params.entityKey, character = params.character, targetSelection = params.targetSelection, appendUnstyled = params.appendUnstyled;
    var contentState = editorState.getCurrentContent();
    var selectionState = editorState.getSelection();
    // TODO:
    // const afterRemoval = Modifier.removeRange(
    //   contentState,
    //   selectionState,
    //   "backward",
    // );
    // const targetSelection = contentState.getSelectionAfter();
    var afterSplit = Modifier.splitBlock(contentState, targetSelection);
    var insertionTarget = afterSplit.getSelectionAfter();
    var asAtomicBlock = Modifier.setBlockType(afterSplit, insertionTarget, "atomic");
    var charData = CharacterMetadata.create({ entity: entityKey });
    var atomicBlockConfig = {
        key: generateRandomKey(),
        type: "atomic",
        text: character,
        characterList: List(Repeat(charData, character.length)),
    };
    var fragmentArray = [new ContentBlock(atomicBlockConfig)];
    if (appendUnstyled) {
        var atomicDividerBlockConfig = {
            key: generateRandomKey(),
            type: "unstyled",
        };
        fragmentArray.push(new ContentBlock(atomicDividerBlockConfig));
    }
    var fragment = BlockMapBuilder.createFromArray(fragmentArray);
    var withAtomicBlock = Modifier.replaceWithFragment(asAtomicBlock, insertionTarget, fragment);
    var newContent = withAtomicBlock.merge({
        selectionBefore: selectionState,
        selectionAfter: withAtomicBlock.getSelectionAfter().set("hasFocus", true),
    });
    // @ts-ignore
    return EditorState.push(editorState, newContent, "insert-fragment");
};
