import { EPaymentOrderType } from "@rentiohq/shared/dist/types/payment.types";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createPayment.types";
import { customContactsSelector, customContactsValueSelector, getNavigationTitle, getRelatedContract, getSubtitle, } from "./schema.createPayment.utils";
import { customGetContactsActions, customIsFetchingSelector, } from "./schema.createPayment.utils.fetchContacts";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.CommissionPayee],
            properties: (_a = {},
                _a[EField.Header + "9a"] = {
                    default: undefined,
                },
                _a[EField.CommissionPayee] = {
                    default: getValue(EField.CommissionPayee, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payment.payment_order.commission_payee.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "9a"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.commission_payee.heading");
            },
        },
        _a[EField.CommissionPayee] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.commission_payee.field");
            },
            selectContactFromListCtaTitle: getLocalizedText("payment.payment_order.commission_payee.select_other.cta"),
            addCtaLabel: getLocalizedText("payment.payment_order.commission_payee.other.label"),
            isMultiSelect: false,
            asOptionList: true,
            // Custom selectors & actions
            valueSelector: function (state, selectedAccountIds) {
                return customContactsValueSelector({
                    state: state,
                    propertyId: extraData.property.id,
                    selectedAccountIds: selectedAccountIds,
                });
            },
            customGetSubtitleSelector: function (state, formData, item) {
                return getSubtitle({
                    state: state,
                    contract: getRelatedContract(extraData, formData),
                    item: item,
                });
            },
            customExtraAccountsSelector: function (state, formData) {
                if (formData === void 0) { formData = {}; }
                return customContactsSelector({
                    type: EPaymentOrderType.BrokerFee,
                    payerType: "payee",
                    state: state,
                    contract: getRelatedContract(extraData, formData),
                });
            },
            customIsFetchingSelector: function (state) {
                return customIsFetchingSelector(state, extraData);
            },
            customGetContactsActions: customGetContactsActions(extraData),
        },
        _a);
};
