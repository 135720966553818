import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ETemplateAccessScope, ETemplateRegionBelgium, ETemplateTag, ETemplateType, } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMappedCountries } from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { EField } from "./schema.template.types";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.AccessScope, EField.Name, EField.Type],
            properties: (_a = {},
                _a[EField.AccessScope] = {
                    default: getValue(EField.AccessScope, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(ETemplateAccessScope).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("template.access_scope.".concat(id.toLowerCase())),
                    }); }),
                },
                _a[EField.Name] = {
                    default: getValue(EField.Name, initialValues, defaultValues),
                },
                _a[EField.Type] = {
                    default: getValue(EField.Type, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(ETemplateType).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("template.type.".concat(stringToSnakeCase(id)).toLowerCase()),
                    }); }),
                },
                _a[EField.Tags] = {
                    default: getValue(EField.Tags, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(ETemplateTag).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("template.tag.".concat(stringToSnakeCase(id)).toLowerCase()),
                    }); }),
                },
                _a[EField.Country] = {
                    default: getValue(EField.Country, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedCountries(),
                },
                _a[EField.Region] = {
                    default: getValue(EField.Region, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(ETemplateRegionBelgium).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("template.region.".concat(id.toLowerCase())),
                    }); }),
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Country] === "BE"; },
                },
                _a[EField.AvailableFrom] = {
                    default: getValue(EField.AvailableFrom, initialValues, defaultValues),
                },
                _a[EField.AvailableTo] = {
                    default: getValue(EField.AvailableTo, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {},
        _a[EField.AccessScope] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("template.field.access_scope"),
        },
        _a[EField.Name] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("template.field.name"),
        },
        _a[EField.Type] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("template.field.type"),
        },
        _a[EField.Tags] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("template.field.tags"),
            isMultiSelect: true,
        },
        _a[EField.Country] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("template.field.country"),
        },
        _a[EField.Region] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("template.field.region"),
            optional: true,
        },
        _a[EField.AvailableFrom] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("template.field.available_from"),
            optional: true,
        },
        _a[EField.AvailableTo] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("template.field.available_to"),
            optional: true,
            getMinDate: function (formData) { return formData[EField.AvailableFrom]; },
        },
        _a);
};
export var getValidate = function (_) { return function (formData, errors) {
    // if (isBefore(formData[EField.EndDate], formData[EField.StartDate])) {
    //   errors[EField.EndDate].addError(
    //     getLocalizedText(`form.error.end_date.error.not_before_start_date`),
    //   );
    // }
    return errors;
}; };
