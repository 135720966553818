import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as applicationHooks from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.hooks";
import * as applicationUtils from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.utils";
import {
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import { Loading } from "@rentiohq/web-shared/dist/components";
import { findLastIndex } from "lodash";
import { getSteps } from "scenes/Properties/scenes/Applications/PropertyDetailApplications.util";
import PropertyListItem from "../PropertyListItem";
import { PropertyListItemItem } from "../PropertyListItem/PropertyListItem.header";
import * as S from "./PropertyListItemApplications.styles";

interface IProps {
  property: IProperty;
  selectedParentId?: number;
  onSetParentId: (parentId?: number) => void;
}

const PropertyListItemApplications = (props: IProps) => {
  const { property, onSetParentId } = props;

  // Redux
  const { broker } = authHooks.useSelf();

  const isGroup = property.typeId === EPropertyTypeId.Group;
  const { countSteps, isFetching } = applicationHooks.useCountSteps({
    query: isGroup
      ? undefined
      : applicationUtils.getApplicationsQuery({
          filter: {
            archivedAt: { is: null },
            propertyId: { eq: property.id },
          },
        }),
  });

  // Helpers
  const intakeType = broker?.intakeType || 2;
  const steps = getSteps(intakeType);

  // Render
  if (isGroup) {
    return (
      <PropertyListItem property={property} onSetParentId={onSetParentId} />
    );
  }

  if (countSteps?.steps) {
    const allSteps = steps.flat();

    const lastActiveStepIndex = findLastIndex(
      allSteps,
      step => !!countSteps.steps[step],
    );

    return (
      <PropertyListItem
        property={property}
        link={`/properties/${property.id}/applications`}
        onSetParentId={onSetParentId}
      >
        <S.PropertyListItemItemSpacer />
        {allSteps.map((step, index) => {
          const isFirst = index === 0;
          const isLast = allSteps.length - 1 === index;

          const isCurrentActive = index <= lastActiveStepIndex;
          const isNextActive = index + 1 <= lastActiveStepIndex;
          const count = countSteps.steps[step];

          return (
            <PropertyListItemItem
              key={step}
              style={{
                flex: 1,
              }}
            >
              <S.InnerItemWrap>
                {!isFirst && <S.LineLeft isActive={isCurrentActive} />}
                {!isLast && <S.LineRight isActive={isNextActive} />}
                <S.Step isActive={isCurrentActive}>{count}</S.Step>
              </S.InnerItemWrap>
            </PropertyListItemItem>
          );
        })}
        <S.PropertyListItemItemSpacer />
      </PropertyListItem>
    );
  }

  if (isFetching) {
    return (
      <PropertyListItem
        property={property}
        link={`/properties/${property.id}/applications`}
        onSetParentId={onSetParentId}
      >
        <S.LoadingWrap>
          <S.LoadingInnerWrap>
            <Loading asDots={true} />
          </S.LoadingInnerWrap>
        </S.LoadingWrap>
      </PropertyListItem>
    );
  }

  return (
    <PropertyListItem
      property={property}
      link={`/properties/${property.id}/applications`}
      onSetParentId={onSetParentId}
    />
  );
};

export default PropertyListItemApplications;
