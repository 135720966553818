import styled, { spacing } from "@rentiohq/web-theme";

export const SelectedCount = styled.div`
  color: black;
  padding: 2px 4px;
  margin-left: ${spacing("tight")};
  font-size: 10pt;
  background-color: white;
  border-radius: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
