import { connect } from "react-redux";
import { getMessagesStart, sendReadReceiptStart, } from "../../../reduxV2/chats/chat.actions";
import ChatMessage from "./ChatMessage";
var mapStateToProps = function (state) {
    var _a;
    return {
        pendingRefs: state.socket.pendingRefs,
        readyState: state.socket.readyState,
        isBroker: !!((_a = state.auth.user) === null || _a === void 0 ? void 0 : _a.brokerId),
        activeUser: state.auth.user,
    };
};
var mapDispatchToProps = {
    getMessages: getMessagesStart.getAction,
    sendReadReceipt: sendReadReceiptStart.getAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatMessage);
