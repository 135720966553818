import { REDUCER_KEY_RENT_DEPOSIT, } from "./rentDeposit.reducer";
export var getRentDeposit = function (state, rentDepositId) {
    return state.rentDeposit.rentDeposits[rentDepositId];
};
export var getRentDeposits = function (state, identifier) {
    var _a, _b;
    return (_b = (_a = state.rentDeposit.byIdentifier[identifier]) === null || _a === void 0 ? void 0 : _a.ids) === null || _b === void 0 ? void 0 : _b.map(function (id) { return state.rentDeposit.rentDeposits[id]; }).filter(function (rentDeposit) { return !!rentDeposit; });
};
export var getRentDepositsForPage = function (state, identifier, page) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.rentDeposit.pagedRentDeposits[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.rentDepositIds) === null || _c === void 0 ? void 0 : _c.map(function (rentDepositId) { return state.rentDeposit.rentDeposits[rentDepositId]; }).filter(function (rentDeposit) { return !!rentDeposit; });
};
export var isFetchingRentDepositsForPage = function (state, identifier, page) {
    var _a, _b;
    return ((_b = (_a = state.rentDeposit.pagedRentDeposits[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching) ||
        false;
};
export var rentDepositsForPageFetchError = function (state, identifier, page) { var _a, _b; return (_b = (_a = state.rentDeposit.pagedRentDeposits[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError; };
export var isFetchingRentDeposits = function (state, identifier) { var _a; return ((_a = state.rentDeposit.byIdentifier[identifier]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchRentDepositsError = function (state, identifier) { var _a; return (_a = state.rentDeposit.byIdentifier[identifier]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var getIsExhaustedFetchingRentDeposits = function (state, identifier) { var _a; return ((_a = state.rentDeposit.byIdentifier[identifier]) === null || _a === void 0 ? void 0 : _a.isExhausted) || false; };
