import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EIntakeStep } from "@rentiohq/shared/dist/types/application.types";
import { UiSchema } from "react-native-jsonschema-form-test";
import { ts } from "../../services";
import { EField, IExtraData } from "./schema.duplicateApplication.types";

export interface IStepOption {
  id: EIntakeStep;
  value: string;
}

export const getSchema =
  (defaultValues: any, extraData: IExtraData, steps: EIntakeStep[][]) =>
  (initialValues: any) => ({
    required: [EField.Property],
    properties: {
      [EField.Property]: {
        default: undefined,
      },
      [EField.Step]: {
        default: getValue(EField.Step, initialValues, defaultValues),
        options: steps.reduce((allOptions, step) => {
          const getOptionFromStep = (s: EIntakeStep) => ({
            id: s,
            value: getLocalizedText(
              `property.dashboard.application.${s}.title`,
            ),
          });

          const intakeStepsInThisStep = Array.isArray(step) ? step : [step];
          return [
            ...allOptions,
            ...intakeStepsInThisStep.map(getOptionFromStep),
          ];
        }, [] as IStepOption[]),
      },
    },
  });

export const uiSchema = (extraData: IExtraData): UiSchema => ({
  "rentio:navigationTitle": ts.applicationDuplicateModalHeading(),
  [EField.Property]: {
    "ui:field": ERentioFormField.PropertySelectInline,
    customIsFetchingSelector: () => false,
    customPropertySelector: (_: any) => {
      return;
    },
    customPropertyFilter: {
      typeId: { neq: 1 },
      id: { neq: extraData.propertyId },
      propertyInfoId: { isNot: null },
    },
  },
  [EField.Step]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("application.duplicate.field.step.title"),
  },
});
