import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    return {
      required: [EField.DocumentToUpload],
      properties: {
        title1: {
          default: undefined,
        },
        // info1: {
        //   default: undefined,
        // },
        [EField.DocumentToUpload]: {
          default: getValue(
            EField.DocumentToUpload,
            initialValues,
            defaultValues,
          ),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData) => {
  return {
    "rentio:title": utils.i18n.t(
      `registration_${stringToSnakeCase(
        extraData.documentType,
      )}.create.form.step.addendum`,
    ),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t(
        `registration.create.form.field.${stringToSnakeCase(
          extraData.documentType,
        )}_select_title`,
      ),
    },
    // info1: {
    //   "ui:field": ERentioFormField.HelpInfo,
    //   subtitle: getLocalizedText(
    //     `registration.create.form.field.${stringToSnakeCase(
    //       extraData.documentType,
    //     )}_select_info`,
    //   ),
    // },
    [EField.DocumentToUpload]: {
      "ui:field": ERentioFormField.DocumentSelectInline,
      valueSelector: (state: IPartialRootState, formData: any[] = []) =>
        (formData || [])
          .map(item => {
            if (typeof item === "string") {
              const document = state.DOCUMENTS_V2.detail[item]?.entity;
              if (document) {
                return document.filename;
              }

              return getLocalizedText("summary.view.document");
            }

            return item.name;
          })
          .join(", "),
      accept: ["application/pdf"],
      mimeTypes: ["application/pdf"],
      multiple: false,
      useExistingDocuments: true,
      getExistingDocumentsWhereFilter: () => ({
        propertyId: { eq: extraData.property.id },
      }),
      title: getLocalizedText(
        `registration.create.form.field.${stringToSnakeCase(
          extraData.documentType,
        )}_upload_document.title`,
      ),
      info: getLocalizedText(
        `registration.create.form.field.${stringToSnakeCase(
          extraData.documentType,
        )}_upload_document.info`,
      ),
      hideOnSummaryIfEmpty: true,
    },
  };
};
