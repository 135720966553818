var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import Hyperlink from "react-native-hyperlink";
import { getName } from "../../../redux/contact/contact.utils";
import { ISocketReadyState } from "../../../redux/socket/socket.types";
import { generateRef } from "../../../redux/socket/socket.utils";
import { getFormattedChatDate } from "../../../reduxV2/chats/chat.utils";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import images from "../../assets";
import ChatAttachmentPreview from "../ChatAttachmentPreview";
import * as S from "./ChatMessage.styles";
import { isOnlyEmojis } from "./ChatMessage.utils";
var ChatMessage = /** @class */ (function (_super) {
    __extends(ChatMessage, _super);
    function ChatMessage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderStatus = function () {
            var message = _this.props.message;
            var status = message.status;
            var myActiveAccountId = _this.getMyActiveAccountId();
            var isSender = _this.getIsSender();
            if (!isSender) {
                return null;
            }
            var didDeliver = true;
            var didRead = true;
            status.forEach(function (_a) {
                var accountId = _a.accountId, deliveredAt = _a.deliveredAt, readAt = _a.readAt;
                if (accountId === myActiveAccountId) {
                    return;
                }
                if (!deliveredAt) {
                    didDeliver = false;
                }
                if (!readAt) {
                    didRead = false;
                }
            });
            if (didRead) {
                return _jsx(S.ReadIcon, { source: images.ICO_CHAT_MESSAGE_READ });
            }
            if (didDeliver) {
                return _jsx(S.DeliveredIcon, { source: images.ICO_CHAT_MESSAGE_DELIVERED });
            }
            return _jsx(S.SentIcon, { source: images.ICO_CHAT_MESSAGE_SENT });
        };
        // Event handlers
        _this.handlePressUrl = function (url) {
            if (_this.props.onPressUrl) {
                _this.props.onPressUrl(url);
            }
        };
        // Helpers
        _this.sendReadReceipt = function (props) {
            var conversationIsActive = props.conversationIsActive, message = props.message, sendReadReceipt = props.sendReadReceipt, readyState = props.readyState;
            if (!conversationIsActive) {
                return;
            }
            if (readyState !== ISocketReadyState.Open) {
                return;
            }
            if (_this.getIsSendingReadReceipt(props)) {
                return;
            }
            var myAccountId = _this.getMyAccountId();
            var status = message.status.find(function (_a) {
                var accountId = _a.accountId;
                return accountId === myAccountId;
            });
            if (!status || status.readAt) {
                return;
            }
            var ref = generateRef();
            _this.pendingRef = ref;
            sendReadReceipt({
                ref: ref,
                chatId: message.chatId,
                messageId: message.id,
                accountId: status.accountId,
            });
        };
        _this.getIsSendingReadReceipt = function (_a) {
            var pendingRefs = _a.pendingRefs;
            return _this.pendingRef && pendingRefs.includes(_this.pendingRef);
        };
        _this.getIsSender = function () { return !!_this.props.message.account.activeAccountId; };
        _this.getMyActiveAccountId = function () {
            var memberSelf = _this.props.chat.members.find(function (member) { return !!member.account.activeAccountId; });
            if (memberSelf) {
                return memberSelf.account.activeAccountId;
            }
            return -1;
        };
        _this.getMyAccountId = function () {
            var memberSelf = _this.props.chat.members.find(function (member) { return !!member.account.activeAccountId; });
            if (memberSelf) {
                return memberSelf.account.id;
            }
            return -1;
        };
        return _this;
    }
    // Lifecycle methods
    ChatMessage.prototype.componentDidMount = function () {
        this.sendReadReceipt(this.props);
    };
    ChatMessage.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        this.sendReadReceipt(nextProps);
    };
    // Render
    ChatMessage.prototype.render = function () {
        var _a = this.props, chat = _a.chat, isBroker = _a.isBroker, message = _a.message, onPress = _a.onPress, onLongPress = _a.onLongPress;
        var text = message.text, account = message.account, attachment = message.attachment, user = message.user;
        var isGroupChat = chat.members.length > 2;
        var isSender = this.getIsSender();
        // Show the name of the other parties in group chats
        var showName = !isSender && isGroupChat;
        // Show the employee name for yourself if you're a broker
        var showEmployeeName = isSender && isBroker;
        return (_jsx(S.OuterWrap, __assign({ isSender: isSender }, { children: _jsxs(S.Wrap, __assign({ as: attachment || Platform.OS !== "web" ? TouchableOpacity : View, hasAttachment: !!attachment, onPress: onPress, onLongPress: onLongPress, isSender: isSender }, { children: [attachment && (_jsx(S.AspectRatioStyled, __assign({ aspectRatio: 1 }, { children: _jsx(S.AttachmentInnerWrap, __assign({ isSender: isSender }, { children: attachment.previewUrl ? (_jsx(ChatAttachmentPreview, { image: attachment.previewUrl, isSender: isSender })) : (_jsx(S.AttachmentText, __assign({ isSender: isSender }, { children: getLocalizedText("chat.attachment.preview.unavailable") }))) })) }))), showName && account && (_jsx(S.SenderName, __assign({ isSender: isSender }, { children: getName(account) }))), showEmployeeName && user && (_jsx(S.SenderName, __assign({ isSender: isSender }, { children: getName(user) }))), text && (
                    // @ts-ignore
                    _jsx(Hyperlink, __assign({ onPress: this.handlePressUrl, linkStyle: { textDecorationLine: "underline" } }, { children: _jsx(S.Message, __assign({ isSender: isSender, isOnlyEmojis: isOnlyEmojis(text) }, { children: text })) }))), _jsxs(S.StatusWrap, __assign({ isSender: isSender }, { children: [_jsx(S.Date, __assign({ isSender: isSender }, { children: getFormattedChatDate(message) })), this.renderStatus()] }))] })) })));
    };
    return ChatMessage;
}(React.PureComponent));
export default ChatMessage;
