var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DEFAULT_GROUPS, DEFAULT_VARIABLES } from "../Editor.config";
import { sortFields } from "./Fields.context.utils";
// Context
export var FieldsContext = React.createContext({
    fields: {
        groups: [],
        variables: [],
        loops: [],
        conditions: [],
    },
    resetFields: function (newFields) { },
    setFields: function (params) { return undefined; },
    removeGroup: function (id) { return undefined; },
    removeVariable: function (id) { return undefined; },
    removeLoop: function (id) { return undefined; },
    removeCondition: function (id) { return undefined; },
});
// Provider
var Provider = function (props) {
    var defaultConfig = props.defaultConfig, children = props.children;
    // State
    var _a = React.useState(sortFields(defaultConfig)), fields = _a[0], setFieldsConfig = _a[1];
    // Helpers
    var setFields = function (params) {
        var groupId = params.groupId, group = params.group, variableId = params.variableId, variable = params.variable, loop = params.loop, condition = params.condition;
        var groupToSet = group;
        if (groupId && !fields.groups.find(function (x) { return x.id === groupId; })) {
            groupToSet = DEFAULT_GROUPS.find(function (x) { return x.id === groupId; });
        }
        var variableToSet = variable;
        if (variableId && !fields.variables.find(function (x) { return x.id === variableId; })) {
            variableToSet = DEFAULT_VARIABLES.find(function (x) { return x.id === variableId; });
        }
        setFieldsConfig(sortFields({
            groups: __spreadArray(__spreadArray([], fields.groups.filter(function (x) { return x.id !== (groupToSet === null || groupToSet === void 0 ? void 0 : groupToSet.id); }), true), (groupToSet ? [groupToSet] : []), true),
            variables: __spreadArray(__spreadArray([], fields.variables.filter(function (x) { return x.id !== (variableToSet === null || variableToSet === void 0 ? void 0 : variableToSet.id); }), true), (variableToSet ? [variableToSet] : []), true),
            loops: __spreadArray(__spreadArray([], fields.loops.filter(function (x) { return x.id !== (loop === null || loop === void 0 ? void 0 : loop.id); }), true), (loop ? [loop] : []), true),
            conditions: __spreadArray(__spreadArray([], fields.conditions.filter(function (x) { return x.id !== (condition === null || condition === void 0 ? void 0 : condition.id); }), true), (condition ? [condition] : []), true),
        }));
    };
    var removeGroup = function (id) {
        var groups = fields.groups.filter(function (x) { return x.id !== id; });
        setFieldsConfig(__assign(__assign({}, fields), { groups: groups }));
    };
    var removeVariable = function (id) {
        var variable = fields.variables.find(function (x) { return x.id === id; });
        if (!variable) {
            return;
        }
        var variables = fields.variables.filter(function (x) { return x.id !== id; });
        var groupInUse = !!variables.find(function (x) { return x.groupId === variable.groupId; }) ||
            !!fields.loops.find(function (x) { return x.groupId === variable.groupId; });
        setFieldsConfig(__assign(__assign({}, fields), { groups: groupInUse
                ? fields.groups
                : fields.groups.filter(function (x) { return x.id !== variable.groupId; }), variables: variables }));
    };
    var removeLoop = function (id) {
        var loop = fields.loops.find(function (x) { return x.id === id; });
        if (!loop) {
            return;
        }
        setFieldsConfig(__assign(__assign({}, fields), { loops: fields.loops.filter(function (x) { return x.id !== id; }) }));
    };
    var removeCondition = function (id) {
        var condition = fields.conditions.find(function (x) { return x.id === id; });
        if (!condition) {
            return;
        }
        setFieldsConfig(__assign(__assign({}, fields), { conditions: fields.conditions.filter(function (x) { return x.id !== id; }) }));
    };
    // Render
    return (_jsx(FieldsContext.Provider, __assign({ value: {
            fields: fields,
            resetFields: setFieldsConfig,
            setFields: setFields,
            removeGroup: removeGroup,
            removeVariable: removeVariable,
            removeLoop: removeLoop,
            removeCondition: removeCondition,
        } }, { children: children })));
};
export default Provider;
