var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { TouchableOpacity, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
import ListItem from "../ListItem";
export var Wrap = styled(TouchableOpacity)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n"], ["\n  flex-direction: row;\n  align-items: center;\n"])));
/*export const StyledLink = styled2(Link)`
  text-decoration: none;
  color: rgb(73, 73, 73);
  width: 100%;
`;*/
export var ChatListItem = styled(ListItem)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-height: 72px;\n  ", "\n"], ["\n  min-height: 72px;\n  ", "\n"])), desktopStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    min-height: 80px;\n  "], ["\n    min-height: 80px;\n  "])))));
export var UnreadIndicatorWrap = styled(View)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n  padding-top: 4px;\n  padding-bottom: 4px;\n  margin-left: 4px;\n  margin-right: 8px;\n  ", "\n"], ["\n  align-items: center;\n  justify-content: center;\n  padding-top: 4px;\n  padding-bottom: 4px;\n  margin-left: 4px;\n  margin-right: 8px;\n  ", "\n"])), desktopStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    margin-left: 8px;\n  "], ["\n    margin-left: 8px;\n  "])))));
export var UnreadIndicator = styled(View)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 8px;\n  height: 8px;\n  border-radius: 4px;\n  background-color: ", ";\n"], ["\n  width: 8px;\n  height: 8px;\n  border-radius: 4px;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global5; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
