import { Box } from "@rebass/grid";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { spacing } from "@rentiohq/web-theme";
interface IProps {
  title: string;
  content?: string;
}

export const RepairInfoItem = (props: IProps) => {
  const { title, content } = props;

  return (
    <Box mt={spacing("base")}>
      <TextStyle variation="strong" style={{ marginRight: spacing("tight") }}>
        {title}
      </TextStyle>
      <TextStyle element="div">{content || "-"}</TextStyle>
    </Box>
  );
};
