import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import * as templateDocumentHooks from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.hooks";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { IAccount, IMember } from "@rentiohq/shared/dist/types/auth.types";
import { EDocumentMemberTypes } from "@rentiohq/shared/dist/types/document.types";
import { getMembersWithRole } from "@rentiohq/shared/dist/utils/roles.utils";
import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import {
  Card,
  DisplayText,
  ESpacings,
  Icon,
  Loading,
  Select,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { AccountList } from "@rentiohq/web-shared/dist/components/AccountList";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { ts } from "@rentiohq/web-shared/dist/services";
import utils from "@rentiohq/web-shared/dist/utils";
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import queryString from "query-string";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { documentPackageUtils } from "utils";
import { ITabProps } from "../Document.types";
import * as S from "./DocumentInfo.styles";

const DocumentInfo: React.FC<ITabProps> = props => {
  let location = useLocation();

  const { document, linkedProperty, linkedTask, mainActions } = props;

  const { documentPackageId } = document;

  const navigate = useNavigate();

  // State
  const [showShare, setShowShare] = React.useState(false);

  // Redux
  const dispatch = useDispatch();
  const { user } = authHooks.useSelf();

  const { updateMembers } = documentHooks.useUpdate({ id: document.id });

  const isFetching = useSelector((state: documentSelectors.IPartialRootState) =>
    documentSelectors.isFetchingDetail(state, document.id),
  );

  const documentPackage = useSelector((state: IRootStore) =>
    documentPackageId
      ? documentPackageSelectors.getDocumentPackage(state, documentPackageId)
      : undefined,
  );
  const isFetchingDocumentPackage = useSelector((state: IRootStore) =>
    documentPackageId
      ? documentPackageSelectors.isFetchingDocumentPackage(
          state,
          documentPackageId,
        )
      : false,
  );

  const { detail: templateDocument } = templateDocumentHooks.useDetail({
    id: document.templateDocumentId,
  });

  // Lifecycle
  React.useEffect(() => {
    if (!documentPackageId) {
      return;
    }

    if (isFetchingDocumentPackage) {
      return;
    }

    dispatch(
      documentPackageActions.getDocumentPackage.actions.start({
        documentPackageId,
      }),
    );
  }, [documentPackageId]);

  // Event handlers
  const handleShareWithMember = (member: IMember<EDocumentMemberTypes>) => {
    updateMembers({
      membersToAdd: [
        {
          roles: [EDocumentMemberTypes.Viewer],
          accountId: member.account.id,
        },
      ],
      membersToRemove: [],
    });

    setShowShare(false);
  };

  const handleRemoveMember = (
    accountId: number,
    originalAccountId?: number,
  ) => {
    const accountIds = [accountId];
    if (originalAccountId && originalAccountId !== accountId) {
      accountIds.push(originalAccountId);
    }

    updateMembers({
      membersToAdd: [],
      membersToRemove: accountIds.map(accountId => ({
        roles: [EDocumentMemberTypes.Viewer],
        accountId,
      })),
    });
  };

  // Helpers
  const documentTypeData = utils.document.getTypeData(document.mimeType);
  const isPdf = documentTypeData.type.toLowerCase() === "pdf";

  const isAdmin = document.roles.includes(EDocumentMemberTypes.Admin);

  const adminMembers = getMembersWithRole(
    document.members,
    EDocumentMemberTypes.Admin,
  );

  const sharedWithMembers = getMembersWithRole(
    document.members,
    EDocumentMemberTypes.Viewer,
  ).filter(member => member.account.id !== user!.activeAccountId);

  const parentMembers = uniqBy(
    flatten([
      ...(linkedProperty?.members || []),
      ...(linkedTask?.members || []),
    ]),
    "account.id",
  );
  const parentMembersAndNotYetMemberOfDocument = parentMembers.filter(
    member =>
      !document.members
        .map(member => member.account.id)
        .includes(member.account.id),
  );

  const canShare = isAdmin && parentMembersAndNotYetMemberOfDocument.length > 0;

  // Event handlers
  const handleReload = () => {
    setTimeout(() => {
      dispatch(
        documentActions.getDetailStart.getAction({
          id: document.id,
        }),
      );
    }, 0);
  };

  // Render
  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const renderSigningContent = () => {
    if (documentPackageId && !documentPackage) {
      return <Loading />;
    }

    if (document.templateDocumentId && !templateDocument) {
      return <Loading />;
    }

    if (!documentPackage) {
      return (
        <Button
          onClick={() => {
            navigate(
              appendQueryParams({
                path: "/forms/document-package",
                queryParams: {
                  propertyId: document.propertyId,
                  contractId: templateDocument?.contractId,
                  documentIdToSign: document.id,
                },
              }),
            );
          }}
          appearance="link"
        >
          {getLocalizedText("document_package.excerpt.action")}
        </Button>
      );
    }

    return (
      <AccountList
        accounts={
          documentPackage
            ? (documentPackage.signers.map(signer => ({
                id: signer.accountId,
                // cspell:ignore firstame
                firstname: signer.firstame,
                lastname: signer.lastname,
                company: signer.company,
              })) as IAccount[])
            : []
        }
        // getActions={getActions}
        getSubtitle={(accountIds: number[]) => {
          const signer = documentPackage?.signers.find(signer =>
            accountIds.includes(signer.accountId),
          );
          if (!signer) {
            return null;
          }

          return documentPackageUtils.renderSignerSubtitle(signer);
        }}
      />
    );
  };

  const renderSelectMember = (params: { data: IMember<string> }) => {
    const member = params.data;
    const { roles = [] } = member;

    return (
      <>
        <div>{getName(member.account)}</div>
        <TextStyle variation="subdued" size="small">
          {join(roles.map(role => ts.role(role)))}
        </TextStyle>
      </>
    );
  };

  return (
    <>
      {document.url ? (
        <Card
          heading={renderCardHeader(
            getLocalizedText("document.preview.header"),
          )}
          space={ESpacings.base}
          actions={mainActions}
        >
          {document.thumbnailUrl ? (
            <S.OverviewAsidePreview
              onClick={() => utils.document.viewDocument(document)}
            >
              <img src={document.thumbnailUrl} alt={document.filename} />
            </S.OverviewAsidePreview>
          ) : (
            <Button
              appearance="link"
              onClick={() => utils.document.viewDocument(document)}
            >
              {getLocalizedText("document.view")}
            </Button>
          )}
        </Card>
      ) : (
        <Card
          heading={renderCardHeader(
            getLocalizedText("document.preview.header"),
          )}
          space={ESpacings.base}
          actions={mainActions}
        >
          <TextStyle variation="subdued">
            {isFetching
              ? getLocalizedText("fetch.loading")
              : getLocalizedText("document.info.generating")}
          </TextStyle>

          <Spacer weight={ESpacerWeight.W08} />

          <Button appearance="link" onClick={handleReload}>
            {getLocalizedText("system.reload")}
          </Button>
        </Card>
      )}

      {isPdf && document.url && (
        <Card
          heading={renderCardHeader(
            getLocalizedText("document_package.excerpt.heading"),
          )}
          space={ESpacings.base}
          actions={
            documentPackageId
              ? [
                  {
                    content: getLocalizedText("document_package.view.action"),
                    onClick: () => {
                      navigate(
                        appendQueryParams({
                          path: location.pathname,
                          queryParams: {
                            ...queryString.parse(location.search),
                            documentPackageId,
                          },
                        }),
                      );
                    },
                  },
                ]
              : undefined
          }
        >
          {renderSigningContent()}
        </Card>
      )}

      {document.templateDocumentId && !document.documentPackageId && (
        <Card
          heading={renderCardHeader(
            getLocalizedText("document.template_document"),
          )}
          subHeading={getLocalizedText("document.template_document.info")}
          space={ESpacings.base}
        >
          <Button
            appearance="link"
            onClick={() => {
              navigate(`/template-document/${document.templateDocumentId}`);
            }}
          >
            {getLocalizedText("document.template_document.cta.edit")}
          </Button>
        </Card>
      )}

      {adminMembers.length > 0 && (
        <Card
          heading={renderCardHeader(getLocalizedText("document.owner"))}
          space={ESpacings.base}
        >
          <AccountList
            accounts={adminMembers.map(member => member.account)}
            getActions={() => []}
          />
        </Card>
      )}

      {(sharedWithMembers.length > 0 || canShare) && (
        <Card
          heading={renderCardHeader(
            getLocalizedText("documents.members.header.title"),
          )}
          space={ESpacings.base}
        >
          <AccountList
            accounts={sharedWithMembers.map(member => member.account)}
            actions={[
              {
                content: (
                  <TextStyle variation={"negative"}>
                    {getLocalizedText("system.remove")}
                  </TextStyle>
                ),
                media: <Icon source="bin" color="red" />,
                onClick: handleRemoveMember,
                isContactAction: false,
              },
            ]}
          />
          {showShare && (
            <>
              <Box mt={3} mb={3}>
                <Select
                  value={-1}
                  inputValue={""}
                  id={`document-contact-select`}
                  label={ts.contactSelectSearchLabel()}
                  name={`document-contact-select`}
                  options={parentMembersAndNotYetMemberOfDocument}
                  onChange={handleShareWithMember}
                  optionRender={renderSelectMember}
                  icon={<Icon source="search" />}
                  placeholder={ts.system("search")}
                  clearValueAfterSelection={true}
                />
              </Box>

              <Button appearance="link" onClick={() => setShowShare(false)}>
                {ts.system("cancel")}
              </Button>
            </>
          )}

          {!showShare && (
            <>
              <Spacer weight={ESpacerWeight.W12} />
              <Button appearance="link" onClick={() => setShowShare(true)}>
                {getLocalizedText("property.documents.share.label")}
              </Button>
            </>
          )}
        </Card>
      )}

      {canShare && <div style={{ height: 200 }} />}
    </>
  );
};

export default DocumentInfo;
