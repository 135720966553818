import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { capitalizeString, stringToSnakeCase, } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { RichUtils } from "draft-js";
import { insertAtomicBlock } from "../../utils/draftjs.utils";
export var ECustomAction;
(function (ECustomAction) {
    ECustomAction[ECustomAction["InsertVariable"] = 0] = "InsertVariable";
    ECustomAction[ECustomAction["InsertCondition"] = 1] = "InsertCondition";
    ECustomAction[ECustomAction["InsertLoop"] = 2] = "InsertLoop";
    ECustomAction[ECustomAction["InsertIndexCount"] = 3] = "InsertIndexCount";
    ECustomAction[ECustomAction["InsertImage"] = 4] = "InsertImage";
    ECustomAction[ECustomAction["InsertPageBreak"] = 5] = "InsertPageBreak";
})(ECustomAction || (ECustomAction = {}));
export var getInsertCustomActions = function (params) {
    var editorState = params.editorState, setEditorState = params.setEditorState, onClickCustomAction = params.onClickCustomAction, isTemplate = params.isTemplate;
    // Helpers
    // const currentInlineStyle = editorState.getCurrentInlineStyle();
    var selectionState = editorState.getSelection();
    var isCollapsed = selectionState.isCollapsed();
    // Actions
    var result = [];
    if (isTemplate) {
        result.push({
            content: getLocalizedText("editor.insert_custom.insert_variable"),
            onClick: function () {
                onClickCustomAction(ECustomAction.InsertVariable);
            },
            // isDisabled: !isCollapsed,
        });
        result.push({
            content: getLocalizedText("editor.insert_custom.insert_condition"),
            onClick: function () {
                onClickCustomAction(ECustomAction.InsertCondition);
            },
            isDisabled: isCollapsed,
        });
        result.push({
            content: getLocalizedText("editor.insert_custom.insert_loop"),
            onClick: function () {
                onClickCustomAction(ECustomAction.InsertLoop);
            },
            isDisabled: isCollapsed,
        });
        result.push({
            content: getLocalizedText("editor.insert_custom.insert_index_count"),
            onClick: function () {
                onClickCustomAction(ECustomAction.InsertIndexCount);
            },
        });
    }
    result.push({
        content: getLocalizedText("editor.insert_custom.insert_image"),
        onClick: function () {
            var data = {};
            setEditorState(insertAtomicBlock({ editorState: editorState, type: "IMAGE", data: data }));
        },
        // isDisabled: !isCollapsed,
    });
    result.push({
        content: getLocalizedText("editor.insert_custom.insert_page_break"),
        onClick: function () {
            var data = {};
            setEditorState(insertAtomicBlock({ editorState: editorState, type: "PAGE_BREAK", data: data }));
        },
        // isDisabled: !isCollapsed,
    });
    return result;
};
export var STYLE_ACTIONS = [
    "bold",
    "underline",
    "italic",
    "strikethrough",
    "code",
].map(function (id) { return ({
    id: id.toUpperCase(),
    icon: "icoEditor".concat(capitalizeString(id)),
}); });
export var getBlockTypes = function () {
    var result = [
        "paragraph",
        "blockquote",
        "unordered-list-item",
        "ordered-list-item",
    ];
    ["one", "two", "three", "four", "five", "six"].forEach(function (id) {
        result.push("header-".concat(id));
    });
    return result;
};
export var getBlockTypeActions = function (params) {
    var editorState = params.editorState, setEditorState = params.setEditorState;
    var toggleBlockType = function (blockType) {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };
    return getBlockTypes().map(function (blockType) {
        return {
            content: getLocalizedText("editor.block_type.".concat(stringToSnakeCase(blockType))),
            onClick: function () { return toggleBlockType(blockType); },
        };
    });
};
