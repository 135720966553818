var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from "./AccountIndicator.styled";
export var AccountIndicator = function (props) {
    var size = props.size, selected = props.selected, onClick = props.onClick, children = props.children;
    return (_jsx(S.CircleContainer, __assign({ style: { position: "relative" }, size: size, onClick: onClick }, { children: _jsx(S.IndicatorCircle, __assign({ size: size, selected: selected }, { children: children })) })));
};
