import { Box } from "@rebass/grid";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentHooks from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.hooks";
import {
  ETemplateDocumentStatus,
  ITemplateDocument,
} from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import { EDocumentCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.types";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import * as propertySelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import logger from "@rentiohq/shared/dist/logger";
import {
  EDocumentMemberTypes,
  IDocument,
} from "@rentiohq/shared/dist/types/document.types";
import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import {
  Card,
  DropdownMenu,
  Dropzone,
  ESpacings,
  Filters,
  Icon,
  Loading,
  Lozenge,
  MultiStepForm,
  Stack,
  TextStyle,
  check,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import utils from "@rentiohq/web-shared/dist/utils";
import { showDropErrors } from "@rentiohq/web-shared/dist/utils/file";
import TemplateDocumentContractCreateModal from "components/TemplateDocumentContractCreateModal";
import queryString from "query-string";
import isEmpty from "ramda/es/isEmpty";
import React, { useState } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { getAvailableTemplatesForType } from "scenes/Properties/scenes/Contracts/components/ContractCard/ContractCard.utils";
import { StringParam, withDefault } from "serialize-query-params";
import { getArchivedBackground } from "utils/color.utils";
import createDocumentForm from "../../../../forms/createDocument";
import { ts } from "../../../../services";
import { getCategoryById } from "../../utils";
import { DocumentsList } from "../DocumentsList/DocumentsList";
import { IDocumentOverviewProps } from "./DocumentOverview.types";

const formId = generateFormId();

export const DocumentOverview = (props: IDocumentOverviewProps) => {
  const {
    extraFilterData,
    passPropsDocumentDetail,
    withTemplates = false,
  } = props;

  let location = useLocation();

  const params = useParams<{ propertyId?: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [queryParams, setQueryParamValue] = useQueryParams({
    documentId: StringParam,
    templateDocumentId: StringParam,
    query: withDefault(StringParam, ""),
  });

  const [activePage, setActivePage] = useState<number>(1);
  const [archivedPage, setArchivedPage] = useState<number>(1);

  const [showArchive, setShowArchive] = React.useState<boolean>(false);
  const [isTemplateArchiving, setTemplateArchiving] =
    React.useState<boolean>(false);

  const [queryInput, setQueryInput] = useState<string>(queryParams.query || "");

  const [queryDebounced] = useDebounce(queryInput);

  const { user, isBroker } = authHooks.useSelf();
  const documentCategories = useSelector((state: IRootStore) =>
    documentSelectors.getDocumentCategories(state),
  );

  const property = useSelector((state: IRootStore) =>
    propertySelectorsV2.getDetail(state, propertyId),
  );

  const { archive, isArchiving } = documentHooks.useArchive({});
  const { unarchive, isUnArchiving } = documentHooks.useUnarchive({});

  const {
    items: documents = [],
    isFetching: isFetchingDocuments = false,
    totalPages = 1,
    fetchError,
    refetch: refetchActiveDocuments,
  } = documentHooks.usePaged(
    documentUtils.getDocumentPagedQuery({
      page: activePage,
      extraFilterData,
      search: queryDebounced,
      customFilters: [
        EDocumentCustomFilter.Active,
        EDocumentCustomFilter.ExcludeInternalDocuments,
      ],
    }),
  );

  const {
    items: archivedDocuments = [],
    isFetching: isFetchingArchivedDocuments = false,
    fetchError: fetchArchivedError,
    totalPages: totalArchivedPages = 1,
    refetch: refetchArchivedDocuments,
  } = documentHooks.usePaged(
    documentUtils.getDocumentPagedQuery({
      page: archivedPage,
      extraFilterData,
      customFilters: [
        EDocumentCustomFilter.Archived,
        EDocumentCustomFilter.ExcludeInternalDocuments,
      ],
    }),
  );

  const {
    data: activeTemplateDocuments,
    refetchPage: refetchActiveTemplateDocuments,
  } = templateDocumentHooks.usePaged({
    disabled: !withTemplates,
    page: 0,
    limit: 100,
    filter: {
      where: {
        ...extraFilterData,
        status: ETemplateDocumentStatus.New,
        archivedAt: { eq: null },
      },
      search: queryDebounced,
    },
  });

  const {
    data: archivedTemplateDocuments,
    refetchPage: refetchArchivedTemplateDocuments,
  } = templateDocumentHooks.usePaged({
    disabled: !withTemplates,
    page: 0,
    limit: 100,
    filter: {
      where: {
        ...extraFilterData,
        status: ETemplateDocumentStatus.New,
        archivedAt: { neq: null },
      },
      search: queryDebounced,
    },
  });

  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );

  const availableTemplates = property
    ? getAvailableTemplatesForType({
        type: "general",
        isBroker,
        templates,
        // contract,
        property,
      })
    : undefined;

  const { documentId, templateDocumentId } = queryParams;

  const handleFiltersQueryChange = (newQuery: string) => {
    setActivePage(1);
    setArchivedPage(1);
    setQueryInput(newQuery);
  };

  const handleFilterQueryValueRemove = () => {
    setActivePage(1);
    setArchivedPage(1);
    setQueryInput("");
  };

  const [files, setFiles] = React.useState<File[]>();
  const [editDocument, setEditDocument] = React.useState<IDocument>();
  const [isUploading, setIsUploading] = React.useState<boolean>();

  const [showTemplateDocumentCreate, setShowTemplateDocumentCreate] =
    React.useState(false);

  // Event handlers
  const handleDrop = (
    acceptedFiles: any[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    showDropErrors({ acceptedFiles, fileRejections, event });

    if (acceptedFiles.length === 0) {
      return;
    }

    setFiles(acceptedFiles);
  };

  const handleClickEdit = (document: IDocument) => {
    setEditDocument(document);
  };

  const handleArchiveTemplate = (templateDocument: ITemplateDocument) => {
    const isArchived = templateDocument.archivedAt;

    confirm({
      title: isArchived
        ? ts.unarchiveConfirm("document")
        : ts.archiveConfirm("document"),
      type: "warning",
      primaryActions: [
        {
          title: isArchived ? ts.system("unarchive") : ts.system("archive"),
          onPress: () => {
            setTemplateArchiving(true);

            if (isArchived) {
              dispatch(
                templateDocumentActions.update.actions.start({
                  id: templateDocument.id,
                  data: { archivedAt: null },
                  onSuccess: () => {
                    refetchActiveTemplateDocuments();
                    refetchArchivedTemplateDocuments();

                    setTemplateArchiving(false);
                  },
                  onFailure: () => {
                    setTemplateArchiving(false);
                  },
                }),
              );
              return;
            }

            dispatch(
              templateDocumentActions.update.actions.start({
                id: templateDocument.id,
                data: { archivedAt: new Date() },
                onSuccess: (data: ITemplateDocument) => {
                  refetchActiveTemplateDocuments();
                  refetchArchivedTemplateDocuments();

                  setTemplateArchiving(false);
                },
                onFailure: () => {
                  setTemplateArchiving(false);
                },
              }),
            );
          },
        },
      ],
    });
  };

  const handleArchive = (document: IDocument) => {
    const isArchived = document.archivedAt;

    confirm({
      title: isArchived
        ? ts.unarchiveConfirm("document")
        : ts.archiveConfirm("document"),
      type: "warning",
      primaryActions: [
        {
          title: isArchived ? ts.system("unarchive") : ts.system("archive"),
          onPress: () => {
            if (isArchived) {
              unarchive(
                {
                  onSuccess: () => {
                    refetchActiveDocuments();
                    refetchArchivedDocuments();
                  },
                },
                document.id,
              );
              return;
            }

            archive(
              {
                onSuccess: () => {
                  refetchActiveDocuments();
                  refetchArchivedDocuments();
                },
              },
              document.id,
            );
          },
        },
      ],
    });
  };

  const handleDelete = (document: IDocument) => {
    confirm({
      title: ts.removeConfirm("document"),
      type: "warning",
      primaryActions: [
        {
          title: ts.system("remove"),
          onPress: () => {
            dispatch(
              documentActions.removeStart.getAction({ id: document.id }),
            );
          },
        },
      ],
    });
  };

  const handleEdit = (values: IDocument) => {
    if (!editDocument) {
      return;
    }

    dispatch(
      documentActions.updateStart.getAction({
        id: editDocument.id,
        data: values,
      }),
    );
    handleModalClose();
  };

  const handleAdd = async (values: any) => {
    try {
      setIsUploading(true);

      const uploads = (files || []).map(async (file: any) => {
        const meta = { ...passPropsDocumentDetail, ...values };

        const preSignedUrl = await documentApi.uploadDocumentToS3(file);

        return documentApi.uploadDocument(preSignedUrl, meta);
      });

      const uploadedDocuments = await Promise.all(uploads);

      setFiles(undefined);
      setQueryParamValue({ documentId: uploadedDocuments[0].id });

      refetchActiveDocuments();
    } catch (unknownError) {
      const error = unknownError as any;
      logger.logError({ error: new Error(error as any) });
    }

    setIsUploading(false);
  };

  const handlePageClick = (selected: number, isArchived?: boolean) => {
    if (isArchived) {
      setArchivedPage(selected + 1);
      return;
    }

    setActivePage(selected + 1);
  };

  const handleModalClose = () => {
    setFiles(undefined);
    setEditDocument(undefined);
  };

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    multiple: false,
    onDrop: handleDrop,
    maxSize: CONFIG.UPLOAD_MAX_FILE_SIZE,
  });

  if (!user || isEmpty(documentCategories)) {
    return null;
  }

  const handleShowArchive = () => {
    setShowArchive(prevResult => !prevResult);
  };

  const getDocumentRows = (docs: IDocument[]) => {
    if (!docs) {
      return [];
    }

    return docs.map(document => {
      const category = getCategoryById(
        document.categoryId,
        documentCategories || [],
      );
      const typeData = utils.document.getTypeData(document.mimeType);
      const canEditDocument = check(document.roles, "document:update");
      const canDeleteDocument = check(document.roles, "document:delete");
      const isPDF = typeData.type.toLowerCase() === "pdf";

      const handlePrepareToSignClick = () => {
        navigate(
          appendQueryParams({
            path: "/forms/document-package",
            queryParams: {
              propertyId,
              contractId: document.contractId,
              documentIdToSign: document.id,
            },
          }),
        );
      };

      const actions = [
        [
          // {
          //   content: getLocalizedText("system.view_detail"),
          //   onClick: () => {
          //     setQueryParamValue({ documentId: document.id });
          //   },
          // },
          {
            content: ts.viewDocument(),
            onClick: () => utils.document.viewDocument(document),
          },
          {
            content: ts.downloadDocument(),
            onClick: () => (window.location.href = document.downloadUrl),
          },
          canEditDocument && {
            content: ts.editDocument(),
            onClick: () => handleClickEdit(document),
          },
          {
            content: document.archivedAt
              ? ts.unarchiveDocument()
              : ts.archiveDocument(),
            onClick: () => handleArchive(document),
          },
          canDeleteDocument && {
            content: (
              <TextStyle variation={"negative"}>
                {ts.deleteDocument()}
              </TextStyle>
            ),
            onClick: () => handleDelete(document),
          },
        ].filter(Boolean),
        [
          // TODO: Only show when necessary
          !document.documentPackageId &&
            isPDF && {
              content: ts.signDocument(),
              onClick: handlePrepareToSignClick,
            },
          !!document.documentPackageId && {
            content: ts.documentPackageViewAction(),
            onClick: () => {
              navigate(
                appendQueryParams({
                  path: location.pathname,
                  queryParams: {
                    ...queryString.parse(location.search),
                    documentPackageId: document.documentPackageId,
                  },
                }),
              );
            },
          },
        ].filter(Boolean),
      ].filter(Boolean) as any;

      return {
        id: document.id,
        content: [
          <>
            <Box>
              <Stack spacing="tight">
                <Stack.Item>
                  {utils.text.truncate(document.filename, 100, true)}
                </Stack.Item>
                {!!document.documentPackageId && (
                  <Icon size="small" source="contentPenWrite" />
                )}
              </Stack>
            </Box>
            <Box mt={ESpacings.extraTight}>
              <Stack spacing="tight">
                <Lozenge isBold={true}>{ts.documentCategory(category)}</Lozenge>

                {document.templateDocumentId && (
                  <>
                    <Lozenge isBold={true} appearance="info">
                      {getLocalizedText("document.tag.template_document")}
                    </Lozenge>
                  </>
                )}
              </Stack>
            </Box>
          </>,
          utils.date.format(document.createdAt),
          // document.filesize
          //   ? formatBytes(document.fileSize)
          //   : ts.system("processing"),
          document.members.length > 1
            ? document.members.map((member: any) => {
                return (
                  member.account.id !== user!.activeAccountId && (
                    <TextStyle
                      key={member.account.id}
                      element="div"
                      variation={
                        member.roles.includes(EDocumentMemberTypes.Admin)
                          ? "positive"
                          : "default"
                      }
                    >
                      {getName(member.account)}
                    </TextStyle>
                  )
                );
              })
            : "-",
        ],
        isSelected: document.id === documentId,
        actions,
        background: document.archivedAt ? getArchivedBackground() : undefined,
      };
    });
  };

  const getTemplateDocumentRows = (templateDocs?: ITemplateDocument[]) =>
    templateDocs?.map(templateDocument => {
      const actions = [
        // {
        //   content: getLocalizedText("system.view_detail"),
        //   onClick: () => {
        //     setQueryParamValue({ templateDocumentId: templateDocument.id });
        //   },
        // },
        {
          content: getLocalizedText("document.template_document.cta.edit"),
          onClick: () => {
            navigate(`/template-document/${templateDocument.id}`);
          },
        },
        {
          content: templateDocument.archivedAt
            ? ts.unarchiveDocument()
            : ts.archiveDocument(),
          onClick: () => handleArchiveTemplate(templateDocument),
        },
      ];

      const { createdAt, id, name } = templateDocument;
      return {
        id,
        content: [
          <>
            <Box>{utils.text.truncate(name, 100, true)}</Box>
            <Box mt={ESpacings.extraTight}>
              <Lozenge isBold={true} appearance="info">
                {getLocalizedText("document.tag.template_document")}
              </Lozenge>
            </Box>
          </>,
          utils.date.format(createdAt),
          "-",
        ],
        isSelected: id === templateDocumentId,
        actions,
        background: templateDocument.archivedAt
          ? getArchivedBackground()
          : undefined,
      };
    });

  const renderContent = () => {
    if (isFetchingDocuments && !documents) {
      return <Loading />;
    }

    const handleRowClick = (rowIndex: number, isArchived?: boolean) => {
      const selectedTemplateDocuments = isArchived
        ? archivedTemplateDocuments
        : activeTemplateDocuments;

      const selectedDocuments = isArchived ? archivedDocuments : documents;

      const documentTemplate = selectedTemplateDocuments?.[rowIndex];
      if (documentTemplate) {
        setQueryParamValue({ templateDocumentId: documentTemplate.id });
        return;
      }

      const normalizedRowIndex =
        rowIndex - (selectedTemplateDocuments || []).length;
      const document = selectedDocuments[normalizedRowIndex];
      if (document) {
        setQueryParamValue({ documentId: document.id });
        return;
      }
    };

    const activeTemplateDocumentRows = getTemplateDocumentRows(
      activeTemplateDocuments,
    );
    const archivedTemplateDocumentRows = getTemplateDocumentRows(
      archivedTemplateDocuments,
    );

    const documentRows = getDocumentRows(documents);
    const archivedDocumentRows = getDocumentRows(archivedDocuments);

    const getRows = (archived?: boolean) => {
      if (archived) {
        if (activePage === 1) {
          return [
            ...(archivedTemplateDocumentRows || []),
            ...archivedDocumentRows,
          ];
        }
        return archivedDocumentRows;
      }

      if (archivedPage === 1) {
        return [...(activeTemplateDocumentRows || []), ...documentRows];
      }
      return documentRows;
    };

    return (
      <>
        <Card>
          <Box mb={ESpacings.loose}>
            <Stack>
              <Stack.Item hasFill={true}>
                <Filters
                  queryValue={queryInput}
                  queryPlaceholder={ts.propertyDocumentsSearchPlaceholder()}
                  onQueryChange={handleFiltersQueryChange}
                  onQueryClear={handleFilterQueryValueRemove}
                />
              </Stack.Item>

              {withTemplates &&
              availableTemplates &&
              availableTemplates.length > 0 ? (
                <DropdownMenu
                  children={
                    <Button appearance="primary" iconAfterName="chevronDown">
                      {ts.addDocument()}
                    </Button>
                  }
                  actions={[
                    {
                      content: ts.addDocument(),
                      onClick: () => {
                        inputRef.current?.click();
                      },
                    },
                    {
                      content: getLocalizedText(
                        "document.cta.generate_from_template",
                      ),
                      onClick: () => {
                        setShowTemplateDocumentCreate(true);
                      },
                    },
                  ]}
                />
              ) : (
                <Button
                  appearance="primary"
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                >
                  {ts.addDocument()}
                </Button>
              )}
            </Stack>
          </Box>

          <DocumentsList
            emptyStateTitle={ts.documentsEmpty()}
            rows={getRows()}
            page={activePage}
            totalPages={totalPages}
            isLoadingData={
              isArchiving || isTemplateArchiving || isFetchingDocuments
            }
            isFetching={isFetchingDocuments}
            fetchError={fetchError}
            handlePageClick={handlePageClick}
            handleRowClick={handleRowClick}
          />

          <Box mt={ESpacings.base}>
            <Dropzone.Dropzone
              {...getRootProps()}
              isDraggingOver={isDragActive}
            >
              <input {...getInputProps()} />
              <Dropzone.DropzonePlaceholder>
                {ts.fileDropZonePlaceholder()}
              </Dropzone.DropzonePlaceholder>
            </Dropzone.Dropzone>
          </Box>
        </Card>

        {archivedDocumentRows.length > 0 &&
          !showArchive &&
          queryInput === "" && (
            <Box my={ESpacings.loose}>
              <Button appearance="link" onClick={handleShowArchive}>
                {ts.propertyDocumentsShowArchiveAction()}
              </Button>
            </Box>
          )}

        {archivedDocumentRows.length > 0 &&
          showArchive &&
          queryInput === "" && (
            <>
              <Card heading={"Archived"}>
                <DocumentsList
                  emptyStateTitle={ts.documentsArchiveEmpty()}
                  rows={getRows(true)}
                  page={archivedPage}
                  totalPages={totalArchivedPages}
                  isLoadingData={
                    isUnArchiving ||
                    isTemplateArchiving ||
                    isFetchingArchivedDocuments
                  }
                  isFetching={isFetchingArchivedDocuments}
                  fetchError={fetchArchivedError}
                  isArchived={true}
                  handlePageClick={handlePageClick}
                  handleRowClick={handleRowClick}
                />
              </Card>
              <Box mt={ESpacings.loose}>
                <Button appearance="link" onClick={handleShowArchive}>
                  {ts.propertyDocumentsHideArchiveAction()}
                </Button>
              </Box>
            </>
          )}
      </>
    );
  };

  return (
    <>
      {renderContent()}

      {files && (
        <MultiStepForm
          formId={`create-document-${formId}`}
          schemas={createDocumentForm({
            categories: documentCategories || [],
            files,
          })}
          asModal={true}
          withAside={false}
          onSuccess={handleAdd}
          modalProps={{ onClose: handleModalClose }}
          submitLabel={ts.system("save")}
          isLoading={isUploading}
        />
      )}

      {!!editDocument && (
        <MultiStepForm
          formId={`edit-document-${formId}-${editDocument.id}`}
          schemas={createDocumentForm({
            categories: documentCategories || [],
            document: editDocument,
          })}
          asModal={true}
          withAside={false}
          onSuccess={handleEdit}
          modalProps={{ onClose: handleModalClose }}
          submitLabel={ts.system("submit")}
        />
      )}

      {property && showTemplateDocumentCreate && (
        <TemplateDocumentContractCreateModal
          property={property}
          onClose={() => {
            setShowTemplateDocumentCreate(false);
          }}
          type={"general"}
        />
      )}
    </>
  );
};
