import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import { IComment } from "@rentiohq/shared/dist/types/comment.types";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  ContactFetchListItem,
  ESpacings,
  Grid,
  Icon,
  ProfilePicture,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./CommentListItem.styled";
import { getFormattedCreatedAt } from "./CommentListItem.utils";

interface IProps {
  comment: IComment;
  commentIdentifier: string;
  handleClickDelete: () => void;
}

export const CommentListItem: React.FC<IProps> = ({
  comment,
  handleClickDelete,
}) => {
  const navigate = useNavigate();
  const { user } = authHooks.useSelf();

  const handleClickContact = (person: any, isContact: boolean) => () => {
    if (!isContact) {
      return;
    }
    navigate(`/contacts/${person.id}`);
  };

  const renderContent = (person: any, isContact: boolean) => (
    <>
      <Grid.Item
        style={{ flexDirection: "row", display: "flex", alignItems: "center" }}
      >
        <S.AccountName
          isContact={isContact}
          onClick={handleClickContact(person, isContact)}
        >
          <TextStyle variation={["subdued", "strong"]}>
            {getName(person)}
          </TextStyle>
        </S.AccountName>
        <S.DotSeparator>
          <TextStyle variation="subdued">·</TextStyle>
        </S.DotSeparator>
        <S.CommentCreatedAt>
          <TextStyle variation="subdued">
            {getFormattedCreatedAt(comment.createdAt)}
          </TextStyle>
        </S.CommentCreatedAt>
      </Grid.Item>
      <S.CommentContent>
        <TextStyle size={"medium"}>{comment.content}</TextStyle>
      </S.CommentContent>
    </>
  );

  const renderContact = (contact: IContact) => {
    const isMe = contact.accountId === user?.activeAccountId;
    const actions = isMe
      ? [
          {
            content: <Icon source="bin" color="red" size="small" />,
            onClick: handleClickDelete,
          },
        ]
      : [];

    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.base }}
        media={
          <S.IconWrap onClick={handleClickContact(contact, true)}>
            {contact && (
              <ProfilePicture
                size="mediumLarge"
                variationProfile="light"
                profile={contact}
              />
            )}
          </S.IconWrap>
        }
        mediaSize={"mediumLarge"}
        primaryActions={actions as [IAction]}
        item={contact}
      >
        {renderContent(contact, true)}
      </ResourceListItem>
    );
  };

  const renderAccount = (account: IAccount) => {
    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.base }}
        media={
          <S.IconWrap>
            {account && (
              <ProfilePicture
                size="mediumLarge"
                variationProfile="light"
                profile={account}
              />
            )}
          </S.IconWrap>
        }
        mediaSize={"mediumLarge"}
        item={account}
      >
        {renderContent(account, false)}
      </ResourceListItem>
    );
  };

  return (
    <ContactFetchListItem
      key={comment.id}
      account={comment.createdByAccount}
      renderAccount={renderAccount}
      renderContact={renderContact}
    />
  );
};
