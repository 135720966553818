import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as insuranceActions from "@rentiohq/shared-frontend/dist/redux/insurance/insurance.actions";
import * as insuranceApi from "@rentiohq/shared-frontend/dist/redux/insurance/insurance.api";
import * as insuranceSelectors from "@rentiohq/shared-frontend/dist/redux/insurance/insurance.selectors";
import * as contractSelectors from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EInsuranceExternalStatus } from "@rentiohq/shared/dist/types/contract.types";
import {
  Error,
  Grid,
  Icon,
  Loading,
  Lozenge,
  Page,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { TLozengeAppearance } from "@rentiohq/web-shared/dist/components/Lozenge/Lozenge.types";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ETabs } from "utils/tabs.utils";
import InsuranceHistory from "./components/InsuranceHistory/InsuranceHistory";
import InsuranceInfo from "./components/InsuranceInfo";

interface IProps {
  contractId: string;
  onDrawerClose?: () => void;
}

const Insurance: React.FC<IProps> = props => {
  const { contractId, onDrawerClose } = props;

  const { internalModeEnabled } = useInternalMode();

  const [tab, setTab] = React.useState<ETabs>(ETabs.Info);

  // Redux
  const dispatch = useDispatch();

  const insurance = useSelector((state: IRootStore) =>
    insuranceSelectors.insuranceByContract(state, contractId),
  );
  const isFetching = useSelector((state: IRootStore) =>
    insuranceSelectors.isFetchingByContract(state, contractId),
  );
  const fetchError = useSelector((state: IRootStore) =>
    insuranceSelectors.fetchErrorByContract(state, contractId),
  );

  const contract = useSelector((state: IRootStore) =>
    contractSelectors.getDetail(state, contractId),
  );

  const propertyId = insurance?.propertyId;

  const {
    detail: property,
    isFetching: isFetchingProperty,
    fetchError: propertyFetchError,
  } = propertyHooks.useDetail({
    id: propertyId,
  });

  // Lifecycle
  React.useEffect(() => {
    if (isFetching) {
      return;
    }

    dispatch(
      insuranceActions.getInsurance.actions.start({
        contractId,
      }),
    );
  }, []);

  // Event handlers
  const handleInfoTabClick = () => {
    setTab(ETabs.Info);
  };

  const handleHistoryTabClick = () => {
    setTab(ETabs.History);
  };

  const handlePollExternal = async () => {
    if (!insurance) {
      return;
    }

    try {
      await insuranceApi.pollStatusExternal(insurance.id);

      showAlert({
        type: "success",
        message: getLocalizedText("insurance.insurance.poll.alert.success"),
      });

      dispatch(
        insuranceActions.getInsurance.actions.start({
          insuranceId: insurance.id,
        }),
      );
    } catch (unknownError) {
      const error = unknownError as any;
      showAlert({ error, type: "error" });
    }
  };

  const handleResendInvite = async () => {
    if (!insurance) {
      return;
    }

    try {
      await insuranceApi.resendInviteExternal(insurance.id);

      showAlert({
        type: "success",
        message: getLocalizedText("insurance.resend_invite.alert.success"),
      });

      dispatch(
        insuranceActions.getInsurance.actions.start({
          insuranceId: insurance.id,
        }),
      );
    } catch (unknownError) {
      const error = unknownError as any;
      showAlert({ error, type: "error" });
    }
  };

  const handleResetInsurance = async () => {
    if (!contract?.insuranceExternalAccessCode) {
      return;
    }

    dispatch(
      insuranceActions.resetInsuranceProposal.actions.start({
        contractId: contract.id,
        externalCode: contract.insuranceExternalAccessCode,
        onSuccess: (contractId: string) => {
          onDrawerClose?.();
        },
      }),
    );
  };

  // Render
  if (!insurance || !property) {
    if (isFetchingProperty || isFetching) {
      return <Loading />;
    }

    if (fetchError) {
      return <Error errors={[fetchError]} />;
    }

    if (propertyFetchError) {
      return <Error errors={[propertyFetchError]} />;
    }

    return null;
  }

  const metadata = (
    <>
      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon
            source={utils.properties.getIcon(property.typeId || 0)}
            size="small"
          />
        </Grid.Item>
        <Grid.Item>
          <Button appearance="link" url={`/properties/${property.id}`}>
            {formatAddress(property)}
          </Button>
        </Grid.Item>
      </Grid>

      {insurance.createdAt && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="calendar" size="small" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText("insurance.detail.created_at", {
                date: formatDate(insurance.createdAt),
              })}
            </Grid.Item>
          </Grid>
        </>
      )}

      {insurance.completedDate && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="checkDouble" size="small" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText("insurance.detail.completed_at", {
                date: formatDate(insurance.completedDate),
              })}
            </Grid.Item>
          </Grid>
        </>
      )}

      {insurance.lastRemindedAt && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="synchronizeArrows" size="small" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText("insurance.detail.last_reminded_at", {
                date: formatDate(insurance.lastRemindedAt),
              })}
            </Grid.Item>
          </Grid>
        </>
      )}
    </>
  );

  let tabs = [
    {
      content: getLocalizedText("insurance.detail.tabs.info"),
      onClick: handleInfoTabClick,
      isActive: tab === ETabs.Info,
    },
    {
      content: getLocalizedText("insurance.detail.tabs.history"),
      onClick: handleHistoryTabClick,
      isActive: tab === ETabs.History,
    },
  ];

  const actions: IAction[] = [];
  if (insurance && internalModeEnabled) {
    actions.push({
      content: getLocalizedText("insurance.poll.cta.title"),
      onClick: handlePollExternal,
    });
    actions.push({
      content: "Resend new insurance proposal",
      onClick: handleResetInsurance,
    });
  }

  let statusAppearance: TLozengeAppearance = "info";
  switch (insurance.externalStatus) {
    case EInsuranceExternalStatus.Completed:
      statusAppearance = "success";
      break;

    case EInsuranceExternalStatus.Failed:
      statusAppearance = "error";
      break;

    default:
      break;
  }

  return (
    <Page
      title={getLocalizedText("insurance.detail.title", {
        address: formatAddress(property, false, false),
      })}
      subtitle={
        <Lozenge isBold={true} appearance={statusAppearance}>
          {getLocalizedText(
            `insurance.external_status.${insurance.externalStatus}`.toLowerCase(),
          )}
        </Lozenge>
      }
      metadata={metadata}
      tabs={tabs}
      setDocumentTitle={false}
      dropdown={actions}
    >
      {tab === ETabs.Info && (
        <InsuranceInfo
          setTab={setTab}
          insurance={insurance}
          onDrawerClose={onDrawerClose}
          onPollExternal={handlePollExternal}
          onResendInvite={handleResendInvite}
        />
      )}

      {tab === ETabs.History && (
        <InsuranceHistory setTab={setTab} insurance={insurance} />
      )}
    </Page>
  );
};

export default Insurance;
