import { Box } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ESpacings, Loading } from "@rentiohq/web-shared/dist/components";
import Banner from "@rentiohq/web-shared/dist/components/Banner";
import Button from "@rentiohq/web-shared/dist/components/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { BrokerContactForm } from "../components/BrokerContactForm";

export const LegalContact: React.FC<{}> = () => {
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { user } = authHooks.useSelf();
  const legalContact = useSelector((state: IRootStore) =>
    user?.brokerId
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.Legal)
      : undefined,
  );
  const isFetchingContact = useSelector((state: IRootStore) =>
    user?.brokerId
      ? state.contact.contactsById[EContactCustomId.Legal].isFetching
      : false,
  );

  React.useEffect(() => {
    if (!user) {
      return;
    }
    if (!legalContact && user.brokerId) {
      dispatch(
        contactActions.getContact.actions.start({
          contactId: EContactCustomId.Legal,
          brokerId: user.brokerId,
        }),
      );
    }
  }, [legalContact, user]);

  const handleShowFormClick = () => {
    setShowForm(true);
  };

  const renderContent = () => {
    if (!user?.brokerId) {
      return null;
    }

    if (isFetchingContact) {
      return <Loading />;
    }

    if (!legalContact && !showForm) {
      return (
        <Button appearance="primary" onClick={handleShowFormClick}>
          {getLocalizedText("company.legal_contact.action.label")}
        </Button>
      );
    }

    return (
      <BrokerContactForm
        contact={legalContact}
        type={EContactCustomId.Legal}
        brokerId={user.brokerId}
      />
    );
  };

  return (
    <>
      <Box mb={ESpacings.loose}>
        <Banner
          hasDismiss={false}
          icon="contentPenWrite"
          title={getLocalizedText("company.legal_contact.info.heading")}
        >
          {!legalContact &&
            getLocalizedText("company.legal_contact.info.content")}
        </Banner>
      </Box>
      {renderContent()}
    </>
  );
};
