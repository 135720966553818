var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var EditorStateContext = React.createContext({
    editorState: undefined,
    setEditorState: function (newValue) { },
});
// Provider
var Provider = function (props) {
    var children = props.children, editorState = props.editorState, setEditorState = props.setEditorState;
    // Render
    return (_jsx(EditorStateContext.Provider, __assign({ value: {
            editorState: editorState,
            setEditorState: setEditorState,
        } }, { children: children })));
};
export default Provider;
