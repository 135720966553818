export var ETasksTimeId;
(function (ETasksTimeId) {
    ETasksTimeId["Today"] = "TODAY";
    ETasksTimeId["ThisWeek"] = "THIS_WEEK";
    ETasksTimeId["NextWeek"] = "NEXT_WEEK";
    ETasksTimeId["All"] = "ALL";
})(ETasksTimeId || (ETasksTimeId = {}));
export var ETasksActionId;
(function (ETasksActionId) {
    ETasksActionId["Todo"] = "TODO";
    ETasksActionId["FollowUp"] = "FOLLOW_UP";
    ETasksActionId["Done"] = "DONE";
})(ETasksActionId || (ETasksActionId = {}));
