import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import {
  getContractDocument,
  getLocationDescription,
} from "@rentiohq/shared-frontend/dist/redux/registration/registration.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import {
  ERegistrationContractStatus,
  ERegistrationContractType,
  ERegistrationDocumentType,
  IRegistration,
} from "@rentiohq/shared/dist/types/registration.types";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { NavigateFunction } from "react-router-dom";

export const getActivitiesFilter = (registration: IRegistration) => {
  const { id: rentRegistrationId } = registration;
  return {
    where: { rentRegistrationId },
  };
};

export const getLocationDescriptionActions = (params: {
  registration: IRegistration;
  property?: IProperty;
  navigate: NavigateFunction;
  dispatch: any;
}) => {
  const { registration, navigate, dispatch } = params;

  const { locationDescriptionStatus } = registration;

  const document = getLocationDescription(registration);
  const contractDocument = getContractDocument(registration);

  const status = document?.status || locationDescriptionStatus;

  const actionAddRegistration: IAction = {
    content: (
      <TextStyle variation={"positive"}>
        {getLocalizedText("registration.detail.cta.add_location_description")}
      </TextStyle>
    ),
    onClick: () => {
      navigate(
        `/properties/${registration.propertyId}/contracts/${registration.contractId}/registration?id=${registration.id}&documentType=${ERegistrationDocumentType.PropertyInspectionReport}&existingDocument=${document?.id}`,
      );
    },
  };

  const getMarkAsAction = (status: ERegistrationContractStatus) => {
    const result: IAction = {
      content: (
        <TextStyle variation={"default"}>
          {getLocalizedText("system.mark_entity_as", {
            entity: getLocalizedText(
              "system.location_description",
            ).toLowerCase(),
            value: getLocalizedText(
              `registration.status.${stringToSnakeCase(status)}`.toLowerCase(),
            ),
          })}
        </TextStyle>
      ),
      onClick: () => {
        if (document) {
          dispatch(
            registrationActions.registrationUpdateDocument.actions.start({
              registrationId: registration.id,
              documentId: document.id,
              document: {
                status,
              },
            }),
          );

          return;
        }

        const locationDescriptionDocument = {
          documentId: undefined,
          documentType: ERegistrationDocumentType.PropertyInspectionReport,
          type: ERegistrationContractType.Amendment,
          signedAt: new Date(),
          signers: [],
          status,
        };

        dispatch(
          registrationActions.registrationAddDocuments.actions.start({
            registrationId: registration.id,
            documents: [locationDescriptionDocument],
          }),
        );
      },
    };

    return result;
  };

  const actionMarkRegisteredManually = getMarkAsAction(
    ERegistrationContractStatus.RegisteredManually,
  );
  const actionMarkNone = getMarkAsAction(ERegistrationContractStatus.None);
  const actionMarkNotApplicable = getMarkAsAction(
    ERegistrationContractStatus.NotApplicable,
  );

  const result: IAction[] = [];

  if (
    // Registering a property inspection report without a barcode from the (pending) contract will fail
    !!contractDocument?.barCode &&
    [
      ERegistrationContractStatus.None,
      ERegistrationContractStatus.Rejected,
      ERegistrationContractStatus.Failed,
    ].includes(status)
  ) {
    result.push(actionAddRegistration);
  }

  if (
    [
      ERegistrationContractStatus.RegisteredManually,
      ERegistrationContractStatus.NotApplicable,
      ERegistrationContractStatus.Rejected,
      ERegistrationContractStatus.Failed,
    ].includes(status)
  ) {
    result.push(actionMarkNone);
  }

  if (
    [
      ERegistrationContractStatus.None,
      ERegistrationContractStatus.Rejected,
      ERegistrationContractStatus.Failed,
    ].includes(status) &&
    actionMarkRegisteredManually
  ) {
    result.push(actionMarkRegisteredManually);
    result.push(actionMarkNotApplicable);
  }

  if (status === ERegistrationContractStatus.RegisteredManually) {
    result.push(actionMarkNotApplicable);
  }

  if (status === ERegistrationContractStatus.NotApplicable) {
    result.push(actionMarkRegisteredManually);
  }

  return result;
};

export const getContractActions = (params: {
  registration: IRegistration;
  property?: IProperty;
  navigate: NavigateFunction;
  dispatch: any;
  onDrawerClose?: () => void;
}) => {
  const { registration, property, navigate, dispatch, onDrawerClose } = params;

  const document = getContractDocument(registration);
  if (!document) {
    return [];
  }

  const actions: IAction[] = [];

  if (document.status === ERegistrationContractStatus.Registered) {
    actions.push({
      content: (
        <TextStyle>{getLocalizedText("registration.addendum.title")}</TextStyle>
      ),
      onClick: () => {
        navigate(
          `/properties/${property?.id}/contracts/${registration?.contractId}/registration?id=${registration.id}&documentType=${ERegistrationDocumentType.Addendum}`,
        );
      },
    });
  }

  if (document.status === ERegistrationContractStatus.Failed) {
    actions.push({
      content: (
        <TextStyle variation={"negative"}>
          {getLocalizedText("system.delete")}
        </TextStyle>
      ),
      onClick: () => {
        dispatch(
          registrationActions.deleteRegistration.actions.start({
            id: registration.id,
            onSuccess: () => {
              onDrawerClose?.();
            },
          }),
        );
      },
    });
  }

  return actions;
};
