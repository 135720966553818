import { EField as EContactField } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  getAllRequiredCompanyFields,
  getAllRequiredContactFields,
} from "@rentiohq/shared-frontend/dist/utils/forms.utils";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  ERegistrationContractType,
  ERegistrationMemberType,
} from "@rentiohq/shared/dist/types/registration.types";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const initialValues = {
    [EField.Owners]: extraData.registration.members
      ?.filter(member => member.roles.includes(ERegistrationMemberType.Owner))
      .map(member => member.account.id),
    [EField.SignAsBroker]: extraData.registration.members
      ?.filter(member => member.roles.includes(ERegistrationMemberType.Broker))
      .map(member => member.account.id),
    [EField.Tenants]: extraData.registration.members
      ?.filter(member => member.roles.includes(ERegistrationMemberType.Tenant))
      .map(member => member.account.id),
    [EField.Type]: ERegistrationContractType.Amendment,
    [EField.SignedAt]: new Date(),
  };

  return initialValues;
};

export const getRegistrationAddDocumentCompanyFieldsSchema = (
  contact: IContact,
) =>
  getAllRequiredCompanyFields({
    contact,
    requiredFields: [
      EContactField.Address,
      EContactField.DateOfBirth,
      EContactField.Nationality,
      EContactField.PlaceOfBirth,
      EContactField.VATNumber,
    ],
    appendIdentityNumberToRequiredFields: true,
  });

export const getRegistrationAddDocumentContactFieldsSchema = (
  contact: IContact,
) =>
  getAllRequiredContactFields({
    contact,
    requiredFields: [
      EContactField.Address,
      EContactField.DateOfBirth,
      EContactField.Nationality,
      EContactField.PlaceOfBirth,
    ],
    appendIdentityNumberToRequiredFields: true,
  });
