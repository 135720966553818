import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import {
  ETemplateType,
  ITemplate,
} from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import { ITemplateDocument } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as contractActions from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.actions";
import { getIndexInfoSimulation } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.api";
import { getIndexInfoAvailable } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertyActions from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import {
  EPaymentOrderType,
  IPaymentOrder,
} from "@rentiohq/shared/dist/types/payment.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import {
  Modal,
  MultiStepForm,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import RentioInternalRenderer from "@rentiohq/web-shared/dist/components/RentioInternalRenderer/RentioInternalRenderer";
import { EField } from "@rentiohq/web-shared/dist/forms/indexContract/schema.indexContract.types";
import { getMaximumPrice } from "@rentiohq/web-shared/dist/forms/indexContract/schema.indexContract.utils";
import indexContractSchemas from "@rentiohq/web-shared/dist/forms/indexContractBulkIndex";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { parseFields } from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.utils";
import { ts as tsCommon } from "@rentiohq/web-shared/dist/services";
import {
  FORCED_DELAY,
  PREFERENCE_KEY_BRAND_COLOR,
  PREFERENCE_KEY_BRAND_LOGO,
  PREFERENCE_KEY_GENERATE_INDEXATION_LETTER,
} from "components/IndexContractModal/Index.constants";
import { getIndexationLetterTitle } from "components/IndexContractModal/Index.utils";
import {
  getDefaultDocumentConfig,
  getDefaultVariablesData,
} from "components/TemplateDocumentContractCreateModal/TemplateDocumentContractCreateModal.utils";
import { compact, reject } from "lodash";
import hash from "object-hash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ProgressBar } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ProgressBar";
import usePreference from "scenes/Settings/hooks/usePreference";

const formId = generateFormId();

export interface IIndexContractBulkIndexModalProps {
  contractIds: string[];
  onClose: (documentId?: string) => void;
}

export const IndexContractBulkIndexModal = (
  props: IIndexContractBulkIndexModalProps,
) => {
  const { contractIds, onClose } = props;

  const [isPending, setIsPending] = useState(false);
  const [itemsToIndex, setItemsToIndex] = useState<string[]>();
  const [indexedItems, setIndexedItems] = useState<string[]>();
  const [failedItems, setFailedItems] = useState<string[]>();

  const dispatch = useDispatch();

  // @ts-ignore
  const contacts = useSelector((state: IRootStore) =>
    contactSelectors.getAllContacts(state),
  );
  // @ts-ignore
  const legalContact = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.Legal),
  );
  // @ts-ignore
  const meMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.MeMaster),
  );

  // @ts-ignore
  const { broker } = authHooks.useSelf();

  // @ts-ignore
  const brandColor = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_COLOR),
  );
  // @ts-ignore
  const brokerLogoDocumentId = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_LOGO),
  );

  const [
    generateIndexationLetterDefault = false,
    setGenerateIndexationLetterDefault,
  ] = usePreference<boolean>({
    preferenceKey: PREFERENCE_KEY_GENERATE_INDEXATION_LETTER,
    preferencePersistScope: EPreferencePersistScope.RemoteUser,
  });

  const preferences = useSelector(
    (state: IRootStore) => state.systemLocal.preferences,
  );

  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );
  const availableTemplates = templates?.filter(
    x => x.type === ETemplateType.Indexation,
  );

  // Actions
  const indexSingleContract = async (params: {
    contractId: string;
    template?: ITemplate;
    forceFullIndexation: boolean;
    retroactive: boolean;
  }) => {
    const { contractId, template, forceFullIndexation, retroactive } = params;

    // Get contract
    const contract = await new Promise<IContract>((resolve, reject) => {
      dispatch(
        contractActions.getDetailStart.getAction({
          id: contractId,
          onSuccess: contract => {
            resolve(contract.data);
          },
          onFailure: reject,
          skipThrottle: true,
        }),
      );
    });

    // Get property
    const property = await new Promise<IProperty>((resolve, reject) => {
      dispatch(
        propertyActions.getDetailStart.getAction({
          id: contract.propertyId,
          onSuccess: property => {
            resolve(property.data);
          },
          onFailure: reject,
          skipThrottle: true,
        }),
      );
    });

    const indexInfoAvailable = getIndexInfoAvailable(contract.indexInfo);
    if (!indexInfoAvailable) {
      throw new Error("Indexation not possible");
    }

    const newPrice = getMaximumPrice({
      indexInfo: indexInfoAvailable,
      epcLabel: property.epcLabel,
      forceFullIndexation,
    });

    // Skip indexing if price is the same
    if (newPrice === contract.currentPrice) {
      return new Promise((resolve, reject) => {
        dispatch(
          contractActions.indexSkipStart.getAction({
            id: contractId,
            onSuccess: () => {
              resolve(true);
            },
            onFailure: () => {
              reject();
            },
          }),
        );
      });
    }

    const retroactivityMonths = retroactive
      ? indexInfoAvailable.retroactivityMonths
      : 0;

    if (template) {
      const rentPaymentOrders = await new Promise<IPaymentOrder[]>(resolve => {
        const filterData = {
          where: {
            completedAt: null,
            type: EPaymentOrderType.Rent,
            propertyId: contract.propertyId,
            contractId: contract.id,
          },
          order: "startedAt DESC, id DESC",
        };

        dispatch(
          paymentActions.getPaymentOrdersByIdentifier.actions.start({
            paymentOrdersIdentifier: hash(filterData),
            refetch: true,
            filterData,
            onSuccess: paymentOrders => {
              resolve(paymentOrders);
            },
            onFailure: () => {
              reject(undefined);
            },
          }),
        );
      });

      const indexInfoSimulationResponse = await getIndexInfoSimulation(
        contract.id,
        {
          newPrice,
          retroactivityMonths,
          forceFullIndexation,
        },
      );
      const indexInfoSimulation = indexInfoSimulationResponse.data.data;

      const templateDocument = await new Promise<ITemplateDocument>(
        (resolve, reject) => {
          dispatch(
            templateDocumentActions.create.actions.start({
              data: {
                templateId: template.id,
                propertyId: property.id,
                contractId: contract.id,
                name: getIndexationLetterTitle({ property }),
                type: template.type,
                documentConfig: getDefaultDocumentConfig({
                  brandColor,
                }),
                variablesData: getDefaultVariablesData({
                  legalContact,
                  meMaster,
                  broker,
                  brokerLogoDocumentId,
                  property,
                  contract,
                  contacts,
                  fields: parseFields(template.fields),
                  preferences,
                  rentPaymentOrder: rentPaymentOrders[0],
                  indexInfo: contract.indexInfo,
                  indexInfoSimulation,
                  indexInfoRequest: {
                    newPrice,
                    isRetroactive: retroactivityMonths > 0,
                    forceFullIndexation,
                  },
                }),
              },
              onSuccess: (templateDocument: ITemplateDocument) => {
                resolve(templateDocument);
              },
              onFailure: reject,
            }),
          );
        },
      );

      await new Promise((resolve, reject) => {
        dispatch(
          templateDocumentActions.generateTemplateDocumentPdf.actions.start({
            id: templateDocument.id,
            onSuccess: (templateDocumentAfterGenerate: ITemplateDocument) => {
              resolve(templateDocumentAfterGenerate);
            },
            onFailure: reject,
          }),
        );
      });
    }

    // Index
    return new Promise((resolve, reject) => {
      dispatch(
        contractActions.indexStart.getAction({
          id: contractId,
          newPrice,
          retroactivityMonths,
          forceFullIndexation,
          onSuccess: () => {
            resolve(true);
          },
          onFailure: () => {
            reject();
          },
        }),
      );
    });
  };

  const startBulkIndex = async (formData: any) => {
    setIsPending(true);

    setGenerateIndexationLetterDefault(formData[EField.GenerateLetter]);

    setItemsToIndex(contractIds);
    setIndexedItems([]);
    setFailedItems([]);

    const templateId = formData[EField.LetterType];
    const template = formData[EField.GenerateLetter]
      ? availableTemplates?.find(template => template.id === templateId)
      : undefined;

    const forceFullIndexation = formData[EField.ForceFullIndexation] || false;
    const retroactive = formData[EField.Retroactivity] || false;

    let itemsToIndexLocal = [...contractIds];
    const indexedItemsLocal = [];
    const failedItemsLocal = [];

    for await (const contractId of contractIds) {
      await new Promise(resolve => setTimeout(resolve, FORCED_DELAY));

      try {
        await indexSingleContract({
          contractId,
          template,
          forceFullIndexation,
          retroactive,
        });

        indexedItemsLocal.push(contractId);
        setIndexedItems(indexedItemsLocal);
      } catch (error) {
        failedItemsLocal.push(contractId);
        setFailedItems(failedItemsLocal);
      }

      itemsToIndexLocal = itemsToIndexLocal.filter(x => x !== contractId);
      setItemsToIndex(itemsToIndexLocal);
    }

    setIsPending(false);
  };

  // Event handlers
  const handleCloseWithConfirm = () => {
    if (isPending) {
      confirm({
        title: getLocalizedText("indexation.confirm.progress_cancel.title"),
        info: getLocalizedText("indexation.confirm.progress_cancel.info"),
        type: "warning",
        primaryActions: [
          {
            title: getLocalizedText("system.stop"),
            onPress: () => {
              onClose();
            },
          },
        ],
      });

      return;
    }

    onClose();
  };

  const handleIndexSuccess = (formData: any) => {
    confirm({
      title: getLocalizedText("indexation.confirm.bulk_index.title"),
      info: getLocalizedText("indexation.confirm.bulk_index.info"),
      type: "warning",
      primaryActions: [
        {
          title: tsCommon.contractIndexIndexNowAction(),
          onPress: () => {
            startBulkIndex(formData);
          },
        },
      ],
    });
  };

  // Render
  const isLoading = false;
  // isUpdatingProperty || isLoadingIndexSimulationInfo || isIndexing;

  if (itemsToIndex) {
    const completedItemsCount = (indexedItems || []).length;
    const failedItemsCount = (failedItems || []).length;
    const totalItemsCount =
      (itemsToIndex || []).length + completedItemsCount + failedItemsCount;

    return (
      <Modal
        heading={getLocalizedText("properties.bulk_index", {
          value: `${contractIds.length}`,
        })}
        shouldCloseOnOverlayClick={false}
        hasDismiss={false}
        actions={compact([
          isPending
            ? {
                content: getLocalizedText("system.cancel"),
                onClick: () => {
                  handleCloseWithConfirm();
                },
                appearance: "outline",
              }
            : undefined,
          !isPending
            ? {
                content: getLocalizedText("system.close"),
                onClick: () => {
                  handleCloseWithConfirm();
                },
                appearance: "primary",
              }
            : undefined,
        ])}
        onClose={() => {
          handleCloseWithConfirm();
        }}
      >
        <ProgressBar
          total={totalItemsCount}
          completed={completedItemsCount}
          pending={failedItemsCount}
        />

        <TextStyle>
          {completedItemsCount + failedItemsCount}/{totalItemsCount}
        </TextStyle>

        {failedItemsCount > 0 && (
          <TextStyle variation="negative" element="div">
            {getLocalizedText(
              `indexation.bulk_action.failed_items_info`,
              {
                value: `${failedItemsCount}`,
              },
              failedItemsCount,
            )}
          </TextStyle>
        )}

        <RentioInternalRenderer
          items={{
            Failed: failedItems ? join(failedItems) : undefined,
            Indexed: indexedItems ? join(indexedItems) : undefined,
            "To do": itemsToIndex ? join(itemsToIndex) : undefined,
          }}
        />
      </Modal>
    );
  }

  return (
    <MultiStepForm
      formId={`index-contact-bulk-index-${formId}`}
      schemas={indexContractSchemas({
        showGenerateLetter: !!availableTemplates?.[0],
        defaultGenerateLetter: generateIndexationLetterDefault,
        letterTemplates: availableTemplates,
      })}
      asModal={true}
      withAside={false}
      onSuccess={handleIndexSuccess}
      modalProps={{
        heading: getLocalizedText("properties.bulk_index", {
          value: `${contractIds.length}`,
        }),
        shouldCloseOnOverlayClick: true,
        onClose,
      }}
      submitLabel={tsCommon.contractIndexIndexNowAction()}
      isLoading={isLoading}
    />
  );
};
