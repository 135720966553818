import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { DocumentOverview } from "../../../../components/Documents";

export const ContactDocuments = () => {
  const params = useParams<{ contactId: string }>();
  const contactId = params.contactId ? +params.contactId : undefined;

  const contact = useSelector((state: IRootStore) =>
    contactId ? contactSelectors.getContact(state, contactId) : undefined,
  );

  if (!contact) {
    return null;
  }

  return (
    <DocumentOverview
      extraFilterData={{ accountId: { in: [contact.accountId] } }}
      passPropsDocumentDetail={{ accountId: contact.accountId }}
      withTemplates={false}
    />
  );
};
