import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  DisplayText,
  TextField,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import * as t from "../../../services/translationService";
import { IPaymentFollowUpScreenProps } from "../PaymentFollowUpModal.types";
import * as S from "./MailScreen.styled";

export const MailScreen: React.FC<IPaymentFollowUpScreenProps> = ({
  postBody,
  setPostBody,
  renderActivities,
  contact,
}) => {
  const handleMessageChange = ({ target: { value } }: any) => {
    setPostBody({ ...postBody, message: value });
  };

  if (!contact) {
    return null;
  }

  return (
    <>
      <S.Mail>
        <S.Header>
          <div>
            <TextStyle variation="strong">{t.system("to")}</TextStyle>:{" "}
            {`${getName(contact)} <${contact.email}>`}
          </div>
          <div>
            <TextStyle variation="strong">{t.system("subject")}</TextStyle>:{" "}
            {t.paymentsFollowUpMailHeading({ locale: contact.locale })}
          </div>
        </S.Header>
        <S.Content>
          <DisplayText size="medium">{postBody.title}</DisplayText>
          <TextField
            value={postBody.message}
            name="message"
            label={t.paymentsFollowUpMailContentLabel()}
            multiline={true}
            onChange={handleMessageChange}
          />
          {/* <Box mt={ESpacings.base}>
            <Button appearance="primary">{t.paymentsFollowUpMailAction()}</Button>
          </Box> */}
        </S.Content>
      </S.Mail>
      <TextStyle variation="subdued">
        {t.paymentsFollowUpMailFootnote()}
      </TextStyle>
      {renderActivities}
    </>
  );
};
