import styled from "@rentiohq/web-theme";

export const Wrap = styled.button<{ pressable: boolean }>`
  background-color: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  border: none;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.pressable ? 1 : 0.2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
