var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import { TooltipTrigger as TooltipTriggerBase } from "./CustomTooltip";
export var TooltipTrigger = styled(TooltipTriggerBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: ", ";\n"], ["\n  cursor: ", ";\n"])), function (props) { return (props.noCursor ? "inherit" : "help"); });
export var TooltipContentWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 480px;\n  margin: ", ";\n\n  padding: ", ";\n  border-radius: ", ";\n\n  font-size: ", ";\n  opacity: 1;\n  background-color: ", ";\n  color: white;\n"], ["\n  max-width: 480px;\n  margin: ", ";\n\n  padding: ", ";\n  border-radius: ", ";\n\n  font-size: ", ";\n  opacity: 1;\n  background-color: ", ";\n  color: white;\n"])), function (props) {
    var spaceToBaseElement = spacing("extraTight");
    switch (props.placement) {
        case "bottom":
            return "".concat(spaceToBaseElement, " 0 0 0");
        case "left":
            return "0 ".concat(spaceToBaseElement, " 0 0");
        case "right":
            return "0 0 0 ".concat(spaceToBaseElement, " 0");
        case "top":
        default:
            return "0 0 ".concat(spaceToBaseElement, " 0");
    }
}, spacing("tight"), spacing("tight"), function (_a) {
    var theme = _a.theme;
    return rem("14px", theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral80;
});
var templateObject_1, templateObject_2;
