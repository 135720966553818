import { JSONSchema6 } from "json-schema";
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import * as t from "../../../../services/translationService";
import { EField, EHasAnswered } from "./schema.followUpPhone.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";

const hasAnswered = (formData: any) =>
  formData[EField.HasAnswered] === EHasAnswered.Answered;

export const getSchema =
  (initialValues: { [key: string]: any }) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [EField.HasAnswered],
    properties: {
      [EField.HasAnswered]: {
        default: getValue(EField.HasAnswered, initialValues, defaultValues),
        // @ts-ignore
        options: [
          {
            id: EHasAnswered.NotAnswered,
            value: t.paymentsFollowUpModalPhoneStatusNotAnsweredLabel(),
          },
          {
            id: EHasAnswered.Answered,
            value: t.paymentsFollowUpModalPhoneStatusAnsweredLabel(),
          },
        ],
      },
      [EField.Summary]: {
        default: getValue(EField.Summary, initialValues, defaultValues),
        // @ts-ignore TODO: Typescript upgrade
        showIf: hasAnswered,
        requiredIf: hasAnswered,
      },
      [EField.PromisedDate]: {
        default: getValue(EField.PromisedDate, initialValues, defaultValues),
        // @ts-ignore TODO: Typescript upgrade
        showIf: hasAnswered,
      },
      [EField.SendToPayer]: {
        default: getValue(EField.SendToPayer, initialValues, defaultValues),
        // @ts-ignore TODO: Typescript upgrade
        showIf: hasAnswered,
        options: [
          {
            id: "1",
            value: t.paymentsFollowUpModalPhoneSendToPayerLabel(),
          },
        ],
      },
    },
  });

export const uiSchema = () => ({
  [EField.HasAnswered]: {
    "ui:field": ERentioFormField.InlineSelect,
  },
  [EField.Summary]: {
    "ui:field": ERentioFormField.Text,
    title: t.paymentsFollowUpModalPhoneSummaryLabel(),
    multiline: true,
  },
  [EField.PromisedDate]: {
    "ui:field": ERentioFormField.Date,
    title: t.paymentsFollowUpModalPhonePromisedDataLabel(),
  },
  [EField.SendToPayer]: {
    "ui:field": ERentioFormField.InlineSelect,
    isMultiSelect: true,
  },
});
