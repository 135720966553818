var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var UsageItemsWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  gap: 40px 0;\n"], ["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  gap: 40px 0;\n"])));
export var UsageItemWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  align-items: center;\n"], ["\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  align-items: center;\n"])));
export var ChartWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 450px;\n"], ["\n  width: 100%;\n  max-width: 450px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
