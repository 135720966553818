var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { matchPath, useLocation } from "react-router-dom";
import Icon from "../../../Icon";
import TextStyle from "../../../TextStyle";
import * as S from "../../Navigation.styled";
export var Item = function (props) {
    var url = props.url, icon = props.icon, label = props.label, _a = props.showActivityIndicator, showActivityIndicator = _a === void 0 ? false : _a, disabled = props.disabled, accessibilityLabel = props.accessibilityLabel;
    var location = useLocation();
    var active = !!matchPath(props.matchPath ? props.matchPath : url, location.pathname);
    return (_jsx(S.StyledNavigationListItem, { children: _jsx(S.StyledNavigationItem, __assign({ to: url, "aria-disabled": disabled, "aria-label": accessibilityLabel, isActive: active }, { children: _jsx(S.Tooltip, __assign({ noCursor: true, tooltipContent: _jsx(TextStyle, __assign({ variation: "positive" }, { children: label })), placement: "right" }, { children: _jsxs(S.StyledNavigationItemIcon, __assign({ isActive: active }, { children: [_jsx(Icon, { source: icon }), showActivityIndicator && (_jsx(S.StyledNavigationItemActivityIndicator, {}))] })) })) })) }));
};
