import React from "react";
export var useToggle = function (defaultValue) {
    if (defaultValue === void 0) { defaultValue = false; }
    var _a = React.useState(defaultValue), value = _a[0], setToggleValue = _a[1];
    var setOn = function () {
        if (value) {
            return;
        }
        setToggleValue(true);
    };
    var setOff = function () {
        if (!value) {
            return;
        }
        setToggleValue(false);
    };
    var toggle = function () {
        setToggleValue(function (prevValue) { return !prevValue; });
    };
    return [value, toggle, setOn, setOff];
};
