import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { EField } from "./schema.propertyInviteApplication.types";
import { ts } from "../../services";

export const getSchema = (_?: any) => (__: any) => ({
  required: [EField.Email],
  properties: {
    [EField.Email]: {
      format: "email",
      default: undefined,
    },
  },
});

export const uiSchema = () => ({
  "rentio:navigationTitle": ts.propertyInviteApplicationModalHeading(),
  [EField.Email]: {
    "ui:field": ERentioFormField.Email,
    title: ts.propertyInviteApplicationEmailLabel(),
    autoFocus: true,
  },
});
