import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { UiSchema } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.moveRequestUpdate.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [],
    properties: {
      [EField.HandoverDocuments]: {
        default: getValue(
          EField.HandoverDocuments,
          initialValues,
          defaultValues,
        ),
      },
    },
  });

export const uiSchema = (extraData: IExtraData): UiSchema => ({
  [EField.HandoverDocuments]: {
    "ui:field": ERentioFormField.DocumentSelectInline,
    title: getLocalizedText("move.field.electricity_handover_documents.title"),
    optional: true,
    multiple: true,
    accept: ["application/*", "image/*"],
  },
});
