var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import sortBy from "lodash/sortBy";
import sortedUniq from "lodash/sortedUniq";
import React from "react";
// Context
export var IndexCountContext = React.createContext({
    onIndexCountVisible: function (indexCount) { },
    onIndexCountInvisible: function (indexCount) { },
    getRealIndexCount: function (indexCount) { return -12; },
});
// Provider
var Provider = function (props) {
    var children = props.children;
    // State
    // TODO: Why ref needed + rerender state to force index count components rerender
    var indexCounts = React.useRef([]);
    var _a = React.useState(-1), setRerender = _a[1];
    // Helpers
    var getRealIndexCount = function (indexCount) {
        var _a;
        return ((_a = indexCounts.current) === null || _a === void 0 ? void 0 : _a.indexOf(indexCount)) + 1;
    };
    // Event handlers
    var handleIndexCountVisible = function (indexCount) {
        indexCounts.current = sortedUniq(sortBy(__spreadArray(__spreadArray([], indexCounts.current, true), [indexCount], false)));
        setRerender(Math.random());
    };
    var handleIndexCountInvisible = function (indexCount) {
        indexCounts.current = __spreadArray([], indexCounts.current, true).filter(function (x) { return x !== indexCount; });
        setRerender(Math.random());
    };
    // Render
    return (_jsx(IndexCountContext.Provider, __assign({ value: {
            onIndexCountVisible: handleIndexCountVisible,
            onIndexCountInvisible: handleIndexCountInvisible,
            getRealIndexCount: getRealIndexCount,
        } }, { children: children })));
};
export default Provider;
