import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EPaymentRequestFollowUpType } from "@rentiohq/shared/dist/types/payment.types";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { createTitle, getPaymentOrders } from "../../../utils/payment";
import { IPaymentFollowUpScreenProps } from "../PaymentFollowUpModal.types";
import createFollowUpRegisteredMailForm from "../forms/followUpRegisteredMail";

const formId = generateFormId();

export const RegisteredMailScreen: React.FC<any> = React.forwardRef(
  (
    {
      followUpItem,
      onClose,
      renderActivities,
      setIsLoading,
      contact,
    }: IPaymentFollowUpScreenProps,
    formRef: any,
  ) => {
    const dispatch = useDispatch();
    const isPendingFollowUp =
      useSelector((state: IRootStore) =>
        paymentSelectors.isPendingFollowUp(
          state,
          followUpItem.id,
          EPaymentRequestFollowUpType.RegisteredMail,
        ),
      ) || false;
    const followUpError =
      useSelector((state: IRootStore) =>
        paymentSelectors.followUpError(
          state,
          followUpItem.id,
          EPaymentRequestFollowUpType.RegisteredMail,
        ),
      ) || false;
    const prevIsPendingFollowUp = usePrevious(isPendingFollowUp);

    React.useEffect(() => {
      setIsLoading?.(isPendingFollowUp);
    }, [isPendingFollowUp, setIsLoading]);

    React.useEffect(() => {
      if (!!prevIsPendingFollowUp && !isPendingFollowUp && !followUpError) {
        onClose?.();
      }
    }, [prevIsPendingFollowUp, isPendingFollowUp, followUpError]);

    const handleSuccess = async (values: any) => {
      const { address: inputAddress, ...data } =
        mapObjectWithTimeZonedDatesToUtcIsoString(values);
      const { country: countryId, ...address } = inputAddress;
      dispatch(
        paymentActions.paymentRequestFollowUpRegisteredMail.actions.start({
          paymentRequestId: followUpItem.id,
          body: {
            ...data,
            ...address,
            countryId,
          },
        }),
      );
    };

    if (!contact) {
      return null;
    }

    return (
      <>
        <MultiStepForm
          ref={formRef}
          formId={`follow-up-registered-mail-${formId}`}
          preventSubmitThroughEnter={true}
          // schemas={schemas}
          schemas={createFollowUpRegisteredMailForm({
            subject: `${getLocalizedText(
              "payments.follow_up.registered_mail.heading",
              undefined,
              undefined,
              contact.locale,
            )}: ${createTitle({
              paymentOrders: getPaymentOrders(followUpItem),
              paymentRequest: followUpItem,
            })}`,
            content: getLocalizedText(
              "payments.follow_up.registered_mail.content",
              // @ts-ignore-next-line
              { ...contact },
              undefined,
              contact.locale,
            ),
            contact,
            property: followUpItem.property as any,
          })}
          showControls={false}
          withAside={false}
          onSuccess={handleSuccess}
          isLoading={isPendingFollowUp}
          getPrecommitConfirm={(params: { onProceed: () => void }) => {
            const { onProceed } = params;

            return {
              type: "warning",
              title: getLocalizedText(
                "payments.follow_up.registered_mail.heading",
              ),
              info: getLocalizedText(
                "payments.follow_up.registered_mail.confirm",
              ),
              primaryActions: [
                {
                  title: getLocalizedText("system.confirm"),
                  onPress: () => {
                    onProceed();
                  },
                },
              ],
              secondaryAction: {
                title: getLocalizedText("system.previous"),
              },
            };
          }}
        />
        {renderActivities}
      </>
    );
  },
);
