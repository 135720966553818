import * as actions from "./exportFile.actions";
import * as api from "./exportFile.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./exportFile.constants";
import * as hooks from "./exportFile.hooks";
import * as promises from "./exportFile.promises";
import reducer from "./exportFile.reducer";
import { saga } from "./exportFile.saga";
import * as selectors from "./exportFile.selectors";
import * as utils from "./exportFile.utils";
export * from "./exportFile.types";
export var REDUCER_KEY_EXPORT_FILE = REDUCER_KEY;
export var exportFileModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: utils,
};
