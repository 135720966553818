import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import { formatLongDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import FileFieldInternal from "@rentiohq/web-shared/dist/components/SharedForm/fields/FileField/FileFieldInternal";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import usePreference from "scenes/Settings/hooks/usePreference";
import { SharedSettingsProps } from "../Settings.types";

interface SettingsImageUploadProps extends SharedSettingsProps {
  title: string;
  tags?: string[];
  fileNamePrefix?: string;
}

export default function SettingsImageUpload({
  title,
  preferenceKey,
  preferencePersistScope,
  fileNamePrefix = "Image",
  tags = [],
}: SettingsImageUploadProps) {
  const dispatch = useDispatch();

  const [image, saveImage, clearImage] = usePreference({
    preferenceKey,
    preferencePersistScope,
  });

  const documentCategories = useSelector((state: IRootStore) =>
    documentSelectors.getDocumentCategories(state),
  );

  const uploadImage = async (newValue: any) => {
    return new Promise<string>((resolve, reject) => {
      dispatch(
        documentActions.uploadDocumentStart.getAction({
          file: newValue?.[0],
          metaData: {
            filename: `${fileNamePrefix}(${formatLongDate(new Date())})`,
            categoryId:
              documentCategories?.find(
                (category: { label: string }) => category.label === "image",
              )?.id || -1,
            tags,
          },
          onSuccess: resolve,
          onFailure: reject,
        }),
      );
    });
  };

  return (
    <FileFieldInternal
      title={title}
      multiple={false}
      disabled={false}
      accept={"image/*"}
      formData={image ? [image] : []}
      hideInput={!!image}
      onChange={async (newValue: any[]) => {
        if (newValue.length === 0) {
          clearImage();
          return;
        }

        if (typeof newValue?.[0] !== "object") {
          return;
        }

        const documentId = await uploadImage(newValue);
        saveImage(documentId ?? null);
      }}
    />
  );
}
