export var EInvoiceClientType;
(function (EInvoiceClientType) {
    EInvoiceClientType["Broker"] = "broker";
    EInvoiceClientType["User"] = "user";
})(EInvoiceClientType || (EInvoiceClientType = {}));
export var EInvoiceResourceModelType;
(function (EInvoiceResourceModelType) {
    // Property = "property",
    EInvoiceResourceModelType["DocumentPackage"] = "documentPackage";
    EInvoiceResourceModelType["PaymentRequest"] = "paymentRequest";
})(EInvoiceResourceModelType || (EInvoiceResourceModelType = {}));
export var EInvoiceResourceType;
(function (EInvoiceResourceType) {
})(EInvoiceResourceType || (EInvoiceResourceType = {}));
