var _a;
import api from "../../utils/api/api.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./property.constants";
export var FETCH_LIMIT = 20;
export var getPaged = (_a = generateApi(GENERATOR_PARAMS), _a.getPaged), getDetail = _a.getDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, update = _a.update, create = _a.create, remove = _a.remove, removeMembers = _a.removeMembers, addMembers = _a.addMembers;
export var sendApplicationInvitation = function (propertyId, data) {
    return api.post("/properties/".concat(propertyId, "/invite-applicant"), {
        data: data,
        isV2: true,
    });
};
export var getAccountContactInfo = function (params) {
    var propertyId = params.propertyId, accountId = params.accountId;
    return api.get("/properties/".concat(propertyId, "/members/").concat(accountId, "/light-contact-info"), { isV2: true });
};
