var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from "@rentiohq/web-theme";
import { rem } from "polished";
import React from "react";
import { Link } from "react-router-dom";
import { SIDEBAR_WIDTH } from "../Frame/Frame.styled";
import { Tooltip as TooltipBase } from "../Tooltip";
export var navigationWidth = 300;
export var StyledNavigationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-bottom: 40px;\n"], ["\n  padding-bottom: 40px;\n"])));
export var StyledNavigation = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"], ["\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"])));
export var StyledNavigationListItem = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var StyledNavigationItem = styled(function (_a) {
    var isActive = _a.isActive, rest = __rest(_a, ["isActive"]);
    return React.createElement(Link, __assign({}, rest));
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  appearance: none;\n  cursor: pointer;\n  color: ", ";\n  text-decoration: none;\n  text-align: left;\n\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  ", "\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  appearance: none;\n  cursor: pointer;\n  color: ", ";\n  text-decoration: none;\n  text-align: left;\n\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  ", "\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral80;
}, function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return isActive
        ? "\n        color: ".concat(theme.colors.global5, ";")
        : "";
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.global5;
});
export var StyledNavigationItemIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n\n  width: ", "px;\n  height: 56px;\n  border-right: 4px solid transparent;\n  padding-left: 4px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n"], ["\n  position: relative;\n\n  width: ", "px;\n  height: 56px;\n  border-right: 4px solid transparent;\n  padding-left: 4px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ", "\n"])), SIDEBAR_WIDTH, function (_a) {
    var isActive = _a.isActive, theme = _a.theme;
    return isActive ? "border-right-color: ".concat(theme.colors.global5, ";") : "";
});
export var StyledNavigationItemActivityIndicator = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 16px;\n  right: 20px;\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  background-color: ", ";\n"], ["\n  position: absolute;\n  top: 16px;\n  right: 20px;\n  width: ", ";\n  height: ", ";\n  border-radius: 50%;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("8px", theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return rem("8px", theme.baseFontSize);
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("red", 800);
});
export var Tooltip = styled(TooltipBase).attrs(function (props) { return ({
    tooltipContentStyle: {
        marginLeft: -16,
        backgroundColor: props.theme.colors.neutral30,
        color: props.theme.colors.global5,
        fontWeight: 500,
    },
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
