import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { appendWhere } from "@rentiohq/shared/dist/utils/api.utils";
import { Page } from "@rentiohq/web-shared/dist/components";
import { TabAction } from "@rentiohq/web-shared/dist/types";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { PaymentHistory } from "./PaymentHistory";
import { PaymentOrders } from "./PaymentOrders";

const PATH_PAYMENTS_OVERVIEW = "/payments-overview";

const PATH_PAYMENT_ORDERS = `${PATH_PAYMENTS_OVERVIEW}/payment-orders`;
const PATH_PAYMENT_HISTORY = `${PATH_PAYMENTS_OVERVIEW}/payment-history`;

export const PaymentsOverview = () => {
  // Hooks
  const location = useLocation();

  const { count: paymentOrdersCount } = useCount({
    countBase: appendWhere("payment-orders/count", {
      completedAt: null,
    }),
  });

  const getTabs = () => {
    const tabs: TabAction[] = [];

    tabs.push({
      content: getLocalizedText("payments.follow_up.payment_history.heading"),
      url: PATH_PAYMENT_HISTORY,
      isActive: !!matchPath(PATH_PAYMENT_HISTORY, location.pathname),
    });

    tabs.push({
      content: getLocalizedText("payments.follow_up.payment_orders.heading"),
      url: PATH_PAYMENT_ORDERS,
      isActive: !!matchPath(PATH_PAYMENT_ORDERS, location.pathname),
      count: paymentOrdersCount,
    });

    return tabs;
  };

  const renderContent = () => {
    if (!!matchPath(PATH_PAYMENT_ORDERS, location.pathname)) {
      return <PaymentOrders />;
    }

    if (!!matchPath(PATH_PAYMENT_HISTORY, location.pathname)) {
      return <PaymentHistory />;
    }

    return <PaymentHistory />;
  };

  return (
    <Page
      title={getLocalizedText("payments_overview.heading")}
      metadata={getLocalizedText("payments_overview.page.description")}
      fullWidth
      tabs={getTabs()}
    >
      {renderContent()}
    </Page>
  );
};
