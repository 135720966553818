import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { Modal, MultiStepForm } from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import partnerImportWhiseSchemas from "forms/partnerImportWhise";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "forms/partnerImportWhise/schema.partnerImportWhise.actions";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ts } from "services";

const { i18n } = utils;

enum EStep {
  Intro,
  Form,
}

interface IProps {
  onClose: () => void;
}

const PartnerImportWhise: React.FC<IProps> = props => {
  const { onClose } = props;

  const navigate = useNavigate();

  // State
  const [formId] = React.useState(generateFormId());
  const [step, setStep] = React.useState<EStep>(EStep.Intro);

  // Render
  const renderIntro = () => {
    return (
      <>
        <Modal
          width="medium"
          onClose={onClose}
          heading={getLocalizedText("partner_import.whise.modal.heading")}
          actions={[
            {
              content: ts.system("cancel"),
              onClick: onClose,
              appearance: "outline",
            },
            {
              content: ts.system("next"),
              onClick: () => {
                setStep(EStep.Form);
              },
              appearance: "primary",
            },
          ]}
        >
          {i18n.tm("partner_import.whise.modal.intro.content", {
            markdownProps: { listAsChecklist: true },
          })}
        </Modal>
      </>
    );
  };

  const renderForm = () => (
    <MultiStepForm
      formId={`partner-import-${formId}`}
      schemas={partnerImportWhiseSchemas({})}
      asModal={true}
      withAside={false}
      modalProps={{
        onClose,
        heading: getLocalizedText("partner_import.whise.modal.heading"),
        width: "medium",
      }}
      modalActions={[
        {
          content: getLocalizedText("system.previous"),
          onClick: () => {
            setStep(EStep.Intro);
          },
          appearance: "outline",
        },
      ]}
      isPerformingSubmitActionSelector={isPerformingSubmitActionSelector}
      performingSubmitActionErrorSelector={performingSubmitActionErrorSelector}
      performingSubmitActionResultSelector={
        performingSubmitActionResultSelector
      }
      submitActionCreator={submitActionCreator}
      onSuccess={(property?: IProperty) => {
        if (property) {
          navigate(`/properties/${property.id}`);
          return;
        }

        onClose();
      }}
      submitLabel={getLocalizedText("system.import")}
    />
  );

  switch (step) {
    case EStep.Form:
      return renderForm();

    case EStep.Intro:
    default:
      return renderIntro();
  }
};

export default PartnerImportWhise;
