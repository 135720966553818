var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AtomicBlockUtils, EditorState, Modifier, SelectionState, } from "draft-js";
import { getEntityRange } from "draftjs-utils";
export var performActions = function (x, actions) {
    var action = actions.shift();
    if (!action) {
        return x;
    }
    var newX = action(x);
    if (actions.length === 0) {
        return newX;
    }
    return performActions(newX, actions);
};
export var getSelectionAsCursorAtStart = function (editorState) {
    var originalSelection = editorState.getSelection();
    return SelectionState.createEmpty(originalSelection.getStartKey()).merge({
        focusOffset: originalSelection.getStartOffset(),
        anchorOffset: originalSelection.getStartOffset(),
        anchorKey: originalSelection.getStartKey(),
    });
};
export var getSelectionAsCursorAtEnd = function (editorState) {
    var originalSelection = editorState.getSelection();
    return SelectionState.createEmpty(originalSelection.getEndKey()).merge({
        focusOffset: originalSelection.getEndOffset(),
        anchorOffset: originalSelection.getEndOffset(),
        anchorKey: originalSelection.getEndKey(),
    });
};
export var removeSelectedText = function (editorState) {
    var contentState = editorState.getCurrentContent();
    var selection = editorState.getSelection();
    var removeRange = Modifier.removeRange(contentState, selection, "forward");
    return EditorState.push(editorState, removeRange, "remove-range");
};
export var insertAtomicBlock = function (params) {
    var editorState = params.editorState, type = params.type, data = params.data;
    var contentState = editorState.getCurrentContent();
    var contentStateWithEntity = contentState.createEntity(type, "IMMUTABLE", data);
    var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    var newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
};
export var getKeysForConditionOrLoop = function (params) {
    var editorState = params.editorState, rendererType = params.rendererType, id = params.id;
    // TODO: Find more efficient way to get entities
    var contentState = editorState.getCurrentContent();
    var blocksAsArray = contentState.getBlocksAsArray();
    var entityKeys = [];
    var blockKeys = [];
    var didFindAll = function () { return entityKeys.length >= 2 || blockKeys.length >= 2; };
    blocksAsArray.find(function (block) {
        var blockKey = block.getKey();
        block.getCharacterList().find(function (randomCharacter) {
            var entityKey = randomCharacter === null || randomCharacter === void 0 ? void 0 : randomCharacter.getEntity();
            if (!entityKey) {
                return false;
            }
            var entity = contentState.getEntity(entityKey);
            var type = entity.getType();
            if (type !== rendererType) {
                return false;
            }
            var data = entity.getData();
            if (data.id !== id) {
                return false;
            }
            if (data.renderType === "BLOCK") {
                blockKeys.push(blockKey);
            }
            else if (data.renderType === "INLINE") {
                entityKeys.push(entityKey);
            }
            return didFindAll();
        });
        return didFindAll();
    });
    return { entityKeys: entityKeys, blockKeys: blockKeys };
};
export var getBlockKeyForEntityKey = function (params) {
    var editorState = params.editorState, entityKey = params.entityKey;
    // TODO: Find more efficient way to get block
    var contentState = editorState.getCurrentContent();
    var blocksAsArray = contentState.getBlocksAsArray();
    var block = blocksAsArray.find(function (block) {
        return !!block
            .getCharacterList()
            .find(function (randomCharacter) { return (randomCharacter === null || randomCharacter === void 0 ? void 0 : randomCharacter.getEntity()) === entityKey; });
    });
    return block === null || block === void 0 ? void 0 : block.getKey();
};
export var removeEntity = function (params) {
    var editorState = params.editorState, entityKey = params.entityKey;
    // Get block for entity
    var blockKey = getBlockKeyForEntityKey({
        editorState: editorState,
        entityKey: entityKey,
    });
    if (!blockKey) {
        return;
    }
    // Create selection
    var range = getEntityRange(editorState, entityKey);
    var selectionState = SelectionState.createEmpty(blockKey);
    var updatedSelection = selectionState.merge({
        anchorOffset: range === null || range === void 0 ? void 0 : range.start,
        focusOffset: range === null || range === void 0 ? void 0 : range.end,
    });
    // Remove text
    var contentState = editorState.getCurrentContent();
    var removeRange = Modifier.removeRange(contentState, updatedSelection, "forward");
    return EditorState.push(editorState, removeRange, "remove-range");
};
export var removeEntities = function (params) {
    var editorState = params.editorState, entityKeys = params.entityKeys;
    return __spreadArray([], entityKeys, true).reverse().reduce(function (editorStateTemp, entityKey) {
        return removeEntity({
            editorState: editorStateTemp,
            entityKey: entityKey,
        }) || editorStateTemp;
    }, editorState);
};
var removeBlock = function (params) {
    var editorState = params.editorState, blockKey = params.blockKey;
    // TODO: Remove
    // const newContentState = editorState.getCurrentContent().delete(blockKey);
    // const newEditorState = EditorState.set(editorState, {
    //   currentContent: newContentState,
    // });
    // Get selection for block
    var selection = SelectionState.createEmpty(blockKey);
    // Remove selection
    var contentState = editorState.getCurrentContent();
    var removeRange = Modifier.removeRange(contentState, selection, "forward");
    return EditorState.push(editorState, removeRange, "remove-range");
};
export var removeBlocks = function (params) {
    var editorState = params.editorState, blockKeys = params.blockKeys;
    if (blockKeys.length === 0) {
        return editorState;
    }
    var blockKey = blockKeys[0], remainingBlockKeys = blockKeys.slice(1);
    return removeBlocks({
        editorState: removeBlock({ editorState: editorState, blockKey: blockKey }),
        blockKeys: remainingBlockKeys,
    });
};
