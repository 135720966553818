var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spacer, { ESpacerDirection, ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as documentsApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EDocumentPackageSignerStatus } from "@rentiohq/shared/dist/types/documentPackage.types";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { useSelector } from "react-redux";
import { StringParam } from "serialize-query-params";
import { Icon, Labelled } from "../../../../components";
import { StyledHelpText } from "../../../../components/Form/Form.styled";
import { getTypeData } from "../../../../utils/document";
import AutocompleteField from "../../../AutocompleteField";
import DocumentFetchListItem from "../../../DocumentFetchListItem";
import { ResourceList, ResourceListItem } from "../../../ResourceList";
import TextStyle from "../../../TextStyle";
import UploadField from "../../../UploadField";
var FileFieldInternal = function (props) {
    var title = props.title, info = props.info, error = props.error, _a = props.optional, optional = _a === void 0 ? false : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b, _c = props.multiple, multiple = _c === void 0 ? true : _c, _d = props.hideInput, hideInput = _d === void 0 ? false : _d, formData = props.formData, asDropzone = props.asDropzone, maxFileSize = props.maxFileSize, accept = props.accept, onChange = props.onChange, onPressExtraInfo = props.onPressExtraInfo, useExistingDocuments = props.useExistingDocuments, existingDocumentsWhereFilter = props.existingDocumentsWhereFilter, mimeTypes = props.mimeTypes;
    var _e = useQueryParams({
        documentId: StringParam,
    }), setQueryParamValue = _e[1];
    // Redux
    var documentCategories = useSelector(function (state) {
        return documentSelectors.getDocumentCategories(state);
    });
    // State
    var _f = React.useState([]), files = _f[0], setFiles = _f[1];
    // Lifecycle
    React.useEffect(function () {
        if (typeof formData === "undefined") {
            setFiles([]);
            return;
        }
        Array.isArray(formData) ? setFiles(formData) : setFiles([formData]);
    }, [formData]);
    // Event handlers
    var handleChange = function (newValue) {
        if (newValue === void 0) { newValue = []; }
        setFiles(newValue);
        onChange(newValue);
    };
    // Render
    var renderDocument = function (item) {
        // Local file
        if (typeof item === "object") {
            return (_jsx(ResourceListItem, __assign({ item: item, media: _jsx(Icon, { source: getTypeData(item.type || "").icon, size: "mediumLarge" }), mediaSize: "mediumLarge", boxProps: { p: 2 }, 
                // variation={validateContact ? "warning" : undefined}
                actions: [
                    {
                        media: _jsx(Icon, { source: "bin", color: "red" }),
                        content: (_jsx(TextStyle, __assign({ variation: "negative" }, { children: getLocalizedText("system.remove") }))),
                        onClick: function () {
                            handleChange(__spreadArray([], files, true).filter(function (x) { return x.name !== item.name; }));
                        },
                    },
                ] }, { children: _jsx("div", { children: item.name }) })));
        }
        // Document id
        if (typeof item === "string") {
            return (_jsx(DocumentFetchListItem, { documentId: item, fetchDocumentPackage: true, renderDocument: function (document, documentPackage) {
                    var signersCount = 0;
                    var incompleteSignersCount = 0;
                    if (documentPackage) {
                        signersCount = documentPackage.signers.length;
                        incompleteSignersCount = documentPackage.signers.filter(function (signer) {
                            return signer.status !== EDocumentPackageSignerStatus.Finished;
                        }).length;
                    }
                    return (_jsxs(ResourceListItem, __assign({ item: document, media: _jsx(Icon, { source: getTypeData(document.mimeType || "").icon, size: "mediumLarge" }), variation: incompleteSignersCount > 0 ? "warning" : undefined, mediaSize: "mediumLarge", boxProps: { p: 2 }, actions: [
                            {
                                media: (_jsx(Icon, { source: getTypeData(document.mimeType || "").icon })),
                                content: getLocalizedText("document.button.view_document"),
                                onClick: function () {
                                    setQueryParamValue({ documentId: document.id });
                                },
                            },
                            {
                                media: _jsx(Icon, { source: "bin", color: "red" }),
                                content: (_jsx(TextStyle, __assign({ variation: "negative" }, { children: getLocalizedText("system.remove") }))),
                                onClick: function () {
                                    handleChange(__spreadArray([], files, true).filter(function (x) { return x !== item; }));
                                },
                            },
                        ] }, { children: [_jsx("div", { children: document.filename }), _jsxs("div", __assign({ style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline",
                                } }, { children: [_jsx(TextStyle, __assign({ variation: "subdued", size: "small" }, { children: documentUtils.getCategoryName(document.categoryId, documentCategories || []) })), documentPackage && signersCount > 0 && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08, direction: ESpacerDirection.Horizontal }), _jsx(Icon, { source: "contentPenWrite", size: "small", color: incompleteSignersCount > 0 ? "red" : "green" }), _jsx(Spacer, { weight: ESpacerWeight.W04, direction: ESpacerDirection.Horizontal }), _jsxs(TextStyle, __assign({ variation: [
                                                    "code",
                                                    incompleteSignersCount > 0 ? "warn" : "positive",
                                                ], size: "small" }, { children: [signersCount - incompleteSignersCount, "/", signersCount] }))] }))] }))] })));
                } }));
        }
        return null;
    };
    var renderContent = function () {
        return (_jsxs(_Fragment, { children: [files.length > 0 && (_jsxs(_Fragment, { children: [_jsx(ResourceList, { items: files, renderItem: renderDocument, hasLastSeparator: false }), _jsx(Spacer, { weight: ESpacerWeight.W08 })] })), !hideInput && useExistingDocuments && (_jsxs(_Fragment, { children: [_jsx(AutocompleteField, { disabled: disabled, clearAfterChange: true, 
                            // label={label}
                            placeholder: getLocalizedText("file.documents.search.placeholder"), name: "document-search", loadOptions: function (query) { return __awaiter(void 0, void 0, void 0, function () {
                                var search, extraFilterData, dataItems, documents, unknownError_1;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 2, , 3]);
                                            search = query.length > 0 ? query : undefined;
                                            extraFilterData = __assign({}, existingDocumentsWhereFilter);
                                            if (mimeTypes) {
                                                extraFilterData.mimeType = {
                                                    in: mimeTypes,
                                                };
                                            }
                                            return [4 /*yield*/, documentsApi.getAllWhere({
                                                    query: {
                                                        filter: extraFilterData,
                                                        search: search,
                                                    },
                                                })];
                                        case 1:
                                            dataItems = (_a.sent()).data.data;
                                            documents = dataItems.items;
                                            // Render
                                            return [2 /*return*/, documents.map(function (document) { return ({
                                                    data: document.id,
                                                    content: (_jsxs(_Fragment, { children: [_jsx("div", { children: document.filename }), _jsx(TextStyle, __assign({ variation: "subdued", size: "small" }, { children: documentUtils.getCategoryName(document.categoryId, documentCategories || []) }))] })),
                                                    media: (_jsx(Icon, { source: getTypeData(document.mimeType).icon })),
                                                }); })];
                                        case 2:
                                            unknownError_1 = _a.sent();
                                            return [2 /*return*/, []];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); }, onChange: function (documentId) {
                                if (multiple) {
                                    handleChange(uniqBy(__spreadArray(__spreadArray([], files, true), [documentId], false), function (x) {
                                        if (typeof x === "object") {
                                            return x.name;
                                        }
                                        return x;
                                    }));
                                    return;
                                }
                                handleChange([documentId]);
                            }, error: error, errorMessageHidden: true, optional: optional, autoFocus: true, renderId: 0, createOptions: [] }), _jsx(Spacer, { weight: ESpacerWeight.W08 })] })), !hideInput && (_jsx(UploadField, { id: typeof title === "string" ? title : "", name: title, asDropzone: asDropzone, multiple: multiple, maxFileSize: maxFileSize, accept: accept, onChange: function (newFiles) {
                        if (multiple) {
                            handleChange(uniqBy(__spreadArray(__spreadArray([], files, true), newFiles, true), function (x) {
                                if (typeof x === "object") {
                                    return x.name;
                                }
                                return x;
                            }));
                            return;
                        }
                        handleChange(newFiles);
                    }, value: files, required: !optional, disabled: disabled, error: error }))] }));
    };
    return (_jsx(_Fragment, { children: title ? (_jsxs(Labelled, __assign({ label: title, error: error, optional: optional, onPressExtraInfo: onPressExtraInfo }, { children: [renderContent(), info && _jsx(StyledHelpText, { children: info })] }))) : (_jsx(_Fragment, { children: info && _jsx(StyledHelpText, { children: info }) })) }));
};
export default FileFieldInternal;
