import styled from "@rentiohq/web-theme";

export const Wrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacing()} ${theme.spacing("loose")}`};
  border-bottom: 1px solid ${({ theme }) => theme.getColor("gray", 200)};
`;

export const Icon = styled.div`
  margin-right: ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.getColor("gray", 600)};
`;

export const Content = styled.div`
  flex: 1;
`;

export const Address = styled.div`
  margin-top: 4px;
  color: ${({ theme }) => theme.getColor("primary")};
  cursor: pointer;
`;
