import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  DisplayText,
  Grid,
  Icon,
  Lozenge,
  ResourceListItem,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { getIconForContractType } from "@rentiohq/web-shared/dist/components/Cards/ContractCard";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ts } from "../../../../../services";
import {
  getContractCost,
  getContractDates,
  getContractStatus,
  renderContractNames,
  statusToAppearanceMap,
} from "../../../../../utils/contract";
import { IContractListItemProps } from "../Contract.types";

export const ContractListItem: React.FC<IContractListItemProps> = ({
  contract,
}) => {
  const navigate = useNavigate();

  const status = getContractStatus(contract);
  const monthlyCost = getContractCost(contract, false);

  return (
    <div>
      <ResourceListItem
        item={contract}
        media={
          <Icon
            source={getIconForContractType(contract.contractType!)}
            size="large"
          />
        }
        actions={[
          {
            content: getLocalizedText("contract.edit"),
            onClick: () => {
              navigate(
                `/properties/${contract.propertyId}/contracts/${contract.id}/edit`,
              );
            },
          },
        ]}
      >
        <Grid alignItems="center" justifyContent="space-between">
          <Grid.Item flex="1">
            <Stack alignment="center">
              <DisplayText size="small" space="extraTight">
                {utils.contract.getDisplayName(contract)}
              </DisplayText>
              <Lozenge appearance={statusToAppearanceMap[status]}>
                {ts.contractStatus(status)}
              </Lozenge>
            </Stack>
            <TextStyle variation={["subdued", "strong"]} element="div">
              {renderContractNames({ contract }) ||
                ts.contractsOverviewContractNoTenants()}
            </TextStyle>
            <TextStyle variation="subdued" element="div">
              {getContractDates(contract)}
            </TextStyle>
          </Grid.Item>

          {monthlyCost && (
            <Grid.Item justifySelf="flex-end">
              <DisplayText size="medium" element="div">
                <TextStyle
                  style={{ textAlign: "right" }}
                  variation="code"
                  element="div"
                >
                  {monthlyCost}
                </TextStyle>
              </DisplayText>
            </Grid.Item>
          )}
        </Grid>
      </ResourceListItem>
    </div>
  );
};
