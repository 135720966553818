import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField, EPaymentOrderCreateOption, } from "./schema.createContract.types";
import { getNavigationTitle } from "./schema.createContract.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.PaymentOrderCreateOption],
            properties: (_a = {},
                _a[EField.Header + "8"] = {
                    default: undefined,
                },
                _a[EField.PaymentOrderCreateOption] = {
                    default: getValue(EField.PaymentOrderCreateOption, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(EPaymentOrderCreateOption).map(function (paymentOrderCreateOption) { return ({
                        id: paymentOrderCreateOption,
                        value: getLocalizedText("contract.payment_order.option.label.".concat(paymentOrderCreateOption)),
                    }); }),
                },
                _a[EField.PaymentOrderCreateOptionInfo + 1] = {
                    showIf: function (formData) {
                        return formData[EField.PaymentOrderCreateOption] ===
                            EPaymentOrderCreateOption.None;
                    },
                },
                _a[EField.PaymentOrderCreateOptionInfo + 2] = {
                    showIf: function (formData) {
                        return formData[EField.PaymentOrderCreateOption] ===
                            EPaymentOrderCreateOption.Create;
                    },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("contract_info.add.payment_orders.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "8"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.add.payment_orders.heading"),
        },
        _a[EField.PaymentOrderCreateOption] = {
            "ui:field": ERentioFormField.InlineSelect,
            formatSummary: function (formData) {
                return getLocalizedText("contract.payment_order.option.label.".concat(formData));
            },
            summaryTitle: getLocalizedText("contract.payment_order.option.title.summary"),
        },
        _a[EField.PaymentOrderCreateOptionInfo + 1] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("contract.payment_order.option.info.".concat(EPaymentOrderCreateOption.None)),
        },
        _a[EField.PaymentOrderCreateOptionInfo + 2] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("contract.payment_order.option.info.".concat(EPaymentOrderCreateOption.Create)),
        },
        _a;
};
