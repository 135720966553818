import styled, { borderRadius } from "@rentiohq/web-theme";

export const CommunicationPreviewWrapper = styled.div<{
  bgColor?: string;
}>`
  border-radius: ${borderRadius()};
  border: 1px solid;
  background-color: ${props => props.bgColor ?? "white"};
  width: 50%;
  max-width: 220px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
