import styled from "@rentiohq/web-theme";
import {
  WIDTH_CONTRACT_DATE,
  WIDTH_TENANTS,
} from "./PropertyListItemRent.constants";

export const ChildrenWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LoadingWrap = styled.div`
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const LoadingInnerWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -25px;
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: ${WIDTH_TENANTS}px;
  @media (min-width: 1350px) {
    width: ${WIDTH_TENANTS + WIDTH_CONTRACT_DATE}px;
  }
  /* @media (min-width: 1400px) {
    width: ${WIDTH_TENANTS + WIDTH_CONTRACT_DATE}px;
  } */
`;

export const StartDateWrap = styled.div`
  display: none;
  @media (min-width: 1350px) {
    display: inherit;
  }
`;
