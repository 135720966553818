var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
import React from "react";
import { DisplayText, Modal, MultiStepForm, TextStyle, } from "../../../../../../components";
import { getKeysForConditionOrLoop, performActions, removeBlocks, removeEntities, } from "../../../../utils/draftjs.utils";
import schemas from "../../../InsertLoopModal/schemas";
import { EField } from "../../../InsertLoopModal/schemas/schema.insertLoop.types";
import { DisplayTypeContext } from "../../contexts/DisplayType.context";
import { EditorStateContext } from "../../contexts/EditorState.context";
import { FieldsContext } from "../../contexts/Fields.context";
import { LOOP_RENDERER_TYPE } from "../../decorators/LoopRenderer";
import { DEFAULT_VARIABLES } from "../../Editor.config";
import LoopInfo from "../LoopInfo";
import * as S from "./LoopModal.styles";
var LoopModal = function (props) {
    var _a, _b;
    var id = props.id, onClose = props.onClose;
    // Context
    var _c = React.useContext(FieldsContext), fields = _c.fields, setFields = _c.setFields, removeLoop = _c.removeLoop;
    var _d = React.useContext(EditorStateContext), editorState = _d.editorState, setEditorState = _d.setEditorState;
    var isTemplate = React.useContext(DisplayTypeContext).isTemplate;
    var loop = fields.loops.find(function (x) { return x.id === id; });
    var variableId = loop === null || loop === void 0 ? void 0 : loop.variableId;
    var variable = (_a = fields.variables) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === variableId; });
    var groupId = loop === null || loop === void 0 ? void 0 : loop.groupId;
    var group = (_b = fields.groups) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === groupId; });
    // Refs
    var editFormRef = React.createRef();
    // State
    var formId = React.useState(generateFormId())[0];
    var _e = React.useState(0), tabIndex = _e[0], setTabIndex = _e[1];
    // Helpers
    var getCleanFormData = function (formData) {
        var variableId = formData[EField.Name];
        var groupId = formData[EField.Group];
        var separator = formData[EField.Separator];
        var lastSeparator = formData[EField.LastSeparator];
        return {
            variableId: variableId,
            groupId: groupId,
            separator: separator,
            lastSeparator: lastSeparator,
        };
    };
    var insertFields = function (formData) {
        if (!loop) {
            return;
        }
        var _a = getCleanFormData(formData), groupId = _a.groupId, variableId = _a.variableId, newLoopConfig = __rest(_a, ["groupId", "variableId"]);
        var existingVariable = __spreadArray(__spreadArray([], fields.variables, true), DEFAULT_VARIABLES, true).find(function (x) { return x.id === variableId; });
        var newLoop = __assign({ id: loop.id, groupId: groupId, variableId: variableId }, newLoopConfig);
        setFields({
            groupId: (existingVariable === null || existingVariable === void 0 ? void 0 : existingVariable.groupId) || groupId,
            variableId: variableId,
            loop: newLoop,
        });
        return loop;
    };
    // Event handlers
    var onUpdate = function (formData) {
        if (!loop) {
            showAlert({
                type: "error",
                message: getLocalizedText("No loop"),
            });
            return;
        }
        insertFields(formData);
        showAlert({
            type: "info",
            message: getLocalizedText("Loop updated"),
        });
        onClose();
    };
    var handleRemoveInstance = function () {
        if (!editorState || !loop) {
            return;
        }
        var _a = getKeysForConditionOrLoop({
            editorState: editorState,
            rendererType: LOOP_RENDERER_TYPE,
            id: id,
        }), entityKeys = _a.entityKeys, blockKeys = _a.blockKeys;
        setEditorState(performActions(editorState, [
            function (x) { return removeEntities({ editorState: x, entityKeys: entityKeys }); },
            function (x) { return removeBlocks({ editorState: x, blockKeys: blockKeys }); },
        ]));
        removeLoop(loop.id);
        onClose();
        showAlert({
            type: "info",
            message: getLocalizedText("Loop removed"),
        });
    };
    var handleRemoveInstanceWithConfirm = function () {
        confirm({
            title: getLocalizedText("editor.loop_instance.remove.confirm.title"),
            primaryActions: [
                {
                    title: getLocalizedText("system.remove"),
                    onPress: handleRemoveInstance,
                },
            ],
        });
    };
    // Render
    if (!loop || (loop.variableId && !variable) || (loop.groupId && !group)) {
        return (_jsx(Modal, __assign({ onClose: onClose, heading: "Loop", shouldCloseOnOverlayClick: true, hasDismiss: true, width: "medium" }, { children: _jsx(DisplayText, __assign({ size: "small" }, { children: _jsx(TextStyle, __assign({ variation: "subdued" }, { children: "Loop/group/variable not found" })) })) })));
    }
    var info = (_jsxs(_Fragment, { children: [_jsx(LoopInfo, { loop: loop, group: group, variable: variable }), isTemplate && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W32 }), _jsx(S.RemoveButton, { title: getLocalizedText("system.remove"), onPress: handleRemoveInstanceWithConfirm })] }))] }));
    var edit = (_jsx(_Fragment, { children: _jsx(MultiStepForm, { formId: "edit-loop-".concat(formId), schemas: schemas({
                fields: fields,
                loop: loop,
            }), withAside: false, onSuccess: onUpdate, showControls: false, submitLabel: getLocalizedText("system.update"), ref: editFormRef }) }));
    return (_jsx(Modal, { onClose: onClose, heading: "Loop", shouldCloseOnOverlayClick: true, hasDismiss: true, width: "medium", onChangeTab: setTabIndex, tabs: [
            {
                content: getLocalizedText("system.info"),
                component: info,
                isShown: true,
            },
            {
                content: getLocalizedText("system.edit"),
                component: edit,
                isShown: isTemplate,
            },
        ].filter(function (x) { return x.isShown; }), actions: tabIndex > 0
            ? [
                {
                    content: getLocalizedText("system.update"),
                    onClick: function () {
                        var _a;
                        (_a = editFormRef.current) === null || _a === void 0 ? void 0 : _a.handleExternalSubmit();
                    },
                    appearance: "primary",
                },
            ]
            : undefined }));
};
export default LoopModal;
