import React from "react";
import * as t from "../../../services/translationService";

interface IProps {
  address: any;
}

export const ContactAddress: React.FC<IProps> = ({ address }) => {
  const { street, number, city, zip, box, state, countryId } = address;
  return (
    <>
      {street && (
        <div>
          {street}
          {number && ` ${number}`}
          {number && box && `/${box}`}
        </div>
      )}
      {(zip || city) && (
        <div>
          {zip}
          {zip && city && ` `}
          {city}
        </div>
      )}
      {state && <div>{state}</div>}
      {countryId && <div>{t.system(`country.${countryId.toLowerCase()}`)}</div>}
    </>
  );
};
