var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ETaskStatus } from "@rentiohq/shared/dist/types/task.types";
import Lozenge from "../Lozenge/Lozenge";
var RepairLozenge = function (_a) {
    var text = _a.text, status = _a.status;
    var getStatus = function () {
        switch (status) {
            case ETaskStatus.Finished:
                return "success";
            case ETaskStatus.Cancelled:
                return "warning";
            case ETaskStatus.Failed:
                return "error";
            case ETaskStatus.Open:
            case ETaskStatus.Pending:
            default:
                return "default";
        }
    };
    return (_jsx(Lozenge, __assign({ isUpperCase: true, appearance: getStatus() }, { children: text })));
};
export default RepairLozenge;
