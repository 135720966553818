import { Route } from "react-router-dom";
import { RentioLegal } from "./RentioLegal";
import { RentioLegalCaseForm } from "./components/RentioLegalCaseForm";

export const getRentioLegalScene = () => (
  <Route path="rentio-legal">
    <Route path="add" element={<RentioLegalCaseForm isEdit={false} />} />

    <Route path="edit" element={<RentioLegalCaseForm isEdit={true} />} />

    <Route index element={<RentioLegal />} />
  </Route>
);
