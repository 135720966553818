import { ETemplateDocumentStatus } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import React from "react";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  contract: IContract;
}

const StatusDotTemplateDocumentContract: React.FC<IProps> = props => {
  const { contract } = props;

  // Helpers
  const getStatus = () => {
    if (contract.manuallyCompletedTemplateDocumentContract) {
      return EStatus.Success;
    }

    switch (
      contract.templateDocumentContractStatus ||
      ETemplateDocumentStatus.New
    ) {
      default:
      case ETemplateDocumentStatus.New:
      case ETemplateDocumentStatus.Failed:
        return EStatus.Error;

      case ETemplateDocumentStatus.Generating:
        return EStatus.Warning;

      case ETemplateDocumentStatus.Generated:
        return EStatus.Success;
    }
  };

  // Render
  const renderTooltipContent = () => {
    if (contract.manuallyCompletedTemplateDocumentContract) {
      return getLocalizedText(
        contract.manuallyCompletedTemplateDocumentContract,
      );
    }

    if (!contract) {
      return;
    }

    const { templateDocumentContractStatus = ETemplateDocumentStatus.New } =
      contract;
    return getLocalizedText(
      `template_document.status.${templateDocumentContractStatus}`.toLowerCase(),
    );
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

export default StatusDotTemplateDocumentContract;
