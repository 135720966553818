var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FormSwitch from "@rentiohq/shared-frontend/dist/components/components/FormSwitch/FormSwitch";
import Spacer, { ESpacerDirection, ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import { formatLongDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Select, TextField } from "../../../../../../components";
import DateFieldInternal from "../../../../../../components/SharedForm/fields/DateField/DateFieldInternal";
import FileFieldInternal from "../../../../../../components/SharedForm/fields/FileField/FileFieldInternal";
import { EDefaultVariableId } from "../../Editor.config";
import { EVariableType, } from "../../Editor.types";
import { getNewVariablesDataForVariableRemoved, getNewVariablesDataForVariableUpdate, variableDataIsFilledIn, variableIsLooped, } from "../../Editor.utils";
import { DisplayTypeContext } from "../../contexts/DisplayType.context";
import { DocumentIdsContext } from "../../contexts/DocumentIds.context";
import { FieldsContext } from "../../contexts/Fields.context";
import { VariablesDataContext } from "../../contexts/VariablesData.context";
import { DefaultVariableLozenge } from "../DefaultVariableLozenge";
import VariableModal from "../VariableModal";
import * as S from "./VariablesFormVariable.styles";
export var VariablesFormVariable = function (props) {
    var variable = props.variable, instanceIndexGroup = props.instanceIndexGroup, groupInstancesToRender = props.groupInstancesToRender;
    var _a = variable.isRequired, isRequired = _a === void 0 ? true : _a;
    var name = variable.name;
    var localizedName = getLocalizedText(name);
    // Redux
    var dispatch = useDispatch();
    var documentCategories = useSelector(function (state) {
        return documentSelectors.getDocumentCategories(state);
    });
    var legalContact = useSelector(function (state) {
        return contactSelectors.getContactByCustomId(state, EContactCustomId.Legal);
    });
    // Context
    var _b = React.useContext(DisplayTypeContext), isTemplate = _b.isTemplate, isTemplateDocument = _b.isTemplateDocument, isTemplateDocumentEditor = _b.isTemplateDocumentEditor;
    var _c = React.useContext(VariablesDataContext), variablesData = _c.variablesData, setVariablesData = _c.setVariablesData;
    var fields = React.useContext(FieldsContext).fields;
    var insertDocumentId = React.useContext(DocumentIdsContext).insertDocumentId;
    var _d = React.useState(false), showVariableModal = _d[0], setShowVariableModal = _d[1];
    // Helpers
    var variableData = variablesData[variable.id] || [];
    var isLooped = variableIsLooped({ variableId: variable.id, fields: fields });
    var instancesToRender = (function () {
        if (!isLooped) {
            return 1;
        }
        // + 1 for empty next field
        return Math.max(1, variableData.length + 1);
    })();
    var isDisabled = !(isTemplateDocument || isTemplateDocumentEditor);
    // Event handlers
    var handlePressRemove = function (instanceIndex) { return function () {
        var newVariablesData = getNewVariablesDataForVariableRemoved({
            instanceIndexVariable: instanceIndex,
            variableId: variable.id,
            variablesData: variablesData,
            fields: fields,
        });
        setVariablesData(newVariablesData);
    }; };
    var handleFocus = function () {
        var _a;
        var variableElement = document.getElementsByClassName("variable-".concat(variable.id))[0];
        var conditionElement = document.getElementsByClassName("condition-variable-".concat(variable.id))[0];
        (_a = (variableElement || conditionElement)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
    };
    // Render
    var renderLabel = function (params) {
        var variable = params.variable, instanceIndex = params.instanceIndex;
        if (instanceIndex !== 0) {
            return undefined;
        }
        return (_jsxs(_Fragment, { children: [localizedName, isLooped && " 🔁", _jsx(DefaultVariableLozenge, { variable: variable, renderWrapper: function (children) { return (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08, direction: ESpacerDirection.Horizontal }), children] })); } })] }));
    };
    var renderTextField = function (params) {
        var instanceIndex = params.instanceIndex, value = params.value, isEmptyTrailingInLoop = params.isEmptyTrailingInLoop;
        var textFieldProps = {};
        if (variable.type === EVariableType.Number) {
            textFieldProps.type = "number";
            textFieldProps.min = variable.minNumber;
            textFieldProps.max = variable.maxNumber;
        }
        var showError = isTemplateDocument &&
            isRequired &&
            !variableDataIsFilledIn(value) &&
            !isEmptyTrailingInLoop;
        return (_jsx(TextField, __assign({}, textFieldProps, { multiline: variable.isMultilineText, error: showError ? "-" : undefined, errorMessageHidden: true, onPressExtraInfo: isTemplate
                ? function () {
                    setShowVariableModal(true);
                }
                : undefined, 
            // size="small"
            name: localizedName, label: renderLabel({ variable: variable, instanceIndex: instanceIndex }), optional: !isRequired, placeholder: localizedName, disabled: isDisabled, 
            // @ts-ignore
            value: value, onFocus: handleFocus, onChange: function (event) {
                var newVariablesData = getNewVariablesDataForVariableUpdate({
                    instanceIndexGroup: instanceIndexGroup,
                    instanceIndexVariable: instanceIndex,
                    variableId: variable.id,
                    variablesData: variablesData,
                    fields: fields,
                    value: event.target.value,
                });
                setVariablesData(newVariablesData);
            } })));
    };
    var renderDateField = function (params) {
        var instanceIndex = params.instanceIndex, value = params.value, isEmptyTrailingInLoop = params.isEmptyTrailingInLoop;
        var formattedValue = undefined;
        if (value) {
            if (typeof value === "object") {
                formattedValue = value;
            }
            else if (typeof value === "string") {
                formattedValue = new Date(value);
            }
        }
        var showError = isTemplateDocument &&
            isRequired &&
            !variableDataIsFilledIn(value) &&
            !isEmptyTrailingInLoop;
        return (_jsx(DateFieldInternal, { title: renderLabel({ variable: variable, instanceIndex: instanceIndex }), optional: !isRequired, placeholder: localizedName, error: showError ? "-" : undefined, errorMessageHidden: true, onPressExtraInfo: isTemplate
                ? function () {
                    setShowVariableModal(true);
                }
                : undefined, value: formattedValue, onFocus: handleFocus, onChange: function (newValue) {
                var newVariablesData = getNewVariablesDataForVariableUpdate({
                    instanceIndexGroup: instanceIndexGroup,
                    instanceIndexVariable: instanceIndex,
                    variableId: variable.id,
                    variablesData: variablesData,
                    fields: fields,
                    value: newValue,
                });
                setVariablesData(newVariablesData);
            }, minDate: variable.minDate ? new Date(variable.minDate) : undefined, maxDate: variable.maxDate ? new Date(variable.maxDate) : undefined, isDisabled: isDisabled }));
    };
    var renderBooleanField = function (params) {
        var instanceIndex = params.instanceIndex, value = params.value;
        // TODO: Field business logic should be moved to template document fields
        var disabledForced = variable.id === EDefaultVariableId.BrokerIsSignerOnBehalfOfOwners &&
            !legalContact;
        return (_jsx(FormSwitch, { label: renderLabel({ variable: variable, instanceIndex: instanceIndex }), checked: !!value, onChange: function () {
                handleFocus();
                var newVariablesData = getNewVariablesDataForVariableUpdate({
                    instanceIndexGroup: instanceIndexGroup,
                    instanceIndexVariable: instanceIndex,
                    variableId: variable.id,
                    variablesData: variablesData,
                    fields: fields,
                    value: !value,
                });
                setVariablesData(newVariablesData);
            }, extraInfoUrl: isTemplate ? "_" : undefined, onPressExtraInfo: function () {
                setShowVariableModal(true);
            }, disabled: disabledForced || isDisabled }));
    };
    var renderImageField = function (params) {
        var instanceIndex = params.instanceIndex, value = params.value;
        return (_jsx(FileFieldInternal, { title: renderLabel({ variable: variable, instanceIndex: instanceIndex }), multiple: false, disabled: isDisabled, optional: !isRequired, accept: "image/*", mimeTypes: ["image/gif", "image/jpeg", "image/png"], formData: value ? [value] : [], hideInput: !!value, onChange: function (newValue) { return __awaiter(void 0, void 0, void 0, function () {
                var documentId, newVariablesData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            documentId = undefined;
                            if (!(typeof (newValue === null || newValue === void 0 ? void 0 : newValue[0]) === "string")) return [3 /*break*/, 1];
                            documentId = newValue === null || newValue === void 0 ? void 0 : newValue[0];
                            return [3 /*break*/, 3];
                        case 1:
                            if (!(typeof (newValue === null || newValue === void 0 ? void 0 : newValue[0]) === "object")) return [3 /*break*/, 3];
                            return [4 /*yield*/, new Promise(function (resolve, reject) {
                                    var _a;
                                    dispatch(documentActions.uploadDocumentStart.getAction({
                                        file: newValue === null || newValue === void 0 ? void 0 : newValue[0],
                                        metaData: {
                                            filename: "Image ".concat(formatLongDate(new Date())),
                                            categoryId: ((_a = documentCategories === null || documentCategories === void 0 ? void 0 : documentCategories.find(function (category) { return category.label === "other"; })) === null || _a === void 0 ? void 0 : _a.id) || -1,
                                            tags: ["templateDocument"],
                                        },
                                        onSuccess: resolve,
                                        onFailure: reject,
                                    }));
                                })];
                        case 2:
                            documentId = _a.sent();
                            _a.label = 3;
                        case 3:
                            if (documentId) {
                                insertDocumentId(documentId);
                            }
                            newVariablesData = getNewVariablesDataForVariableUpdate({
                                instanceIndexGroup: instanceIndexGroup,
                                instanceIndexVariable: instanceIndex,
                                variableId: variable.id,
                                variablesData: variablesData,
                                fields: fields,
                                value: documentId,
                            });
                            setVariablesData(newVariablesData);
                            return [2 /*return*/];
                    }
                });
            }); }, onPressExtraInfo: isTemplate
                ? function () {
                    setShowVariableModal(true);
                }
                : undefined, useExistingDocuments: true, existingDocumentsWhereFilter: {} }));
    };
    var renderDropdownField = function (params) {
        var instanceIndex = params.instanceIndex, value = params.value, isEmptyTrailingInLoop = params.isEmptyTrailingInLoop;
        var showError = isTemplateDocument &&
            isRequired &&
            !variableDataIsFilledIn(value) &&
            !isEmptyTrailingInLoop;
        return (_jsx(Select, { id: localizedName, name: localizedName, error: showError ? "-" : undefined, errorMessageHidden: true, label: renderLabel({ variable: variable, instanceIndex: instanceIndex }), optional: !isRequired, 
            // @ts-ignore
            isDisabled: isDisabled, placeholder: localizedName, value: "".concat(value), onMenuOpen: handleFocus, options: __spreadArray(__spreadArray([], (variable.dropdownValues || []).map(function (x) { return ({
                label: x,
                value: x,
            }); }), true), (isRequired
                ? []
                : [
                    {
                        label: "-",
                        value: undefined,
                    },
                ]), true), onPressExtraInfo: isTemplate
                ? function () {
                    setShowVariableModal(true);
                }
                : undefined, onChange: function (x) {
                var newVariablesData = getNewVariablesDataForVariableUpdate({
                    instanceIndexGroup: instanceIndexGroup,
                    instanceIndexVariable: instanceIndex,
                    variableId: variable.id,
                    variablesData: variablesData,
                    fields: fields,
                    value: x.value,
                });
                setVariablesData(newVariablesData);
                handleFocus();
            } }));
    };
    var renderField = function (params) {
        switch (variable.type) {
            case EVariableType.String:
            case EVariableType.Number:
                return renderTextField(params);
            case EVariableType.Date:
                return renderDateField(params);
            case EVariableType.Boolean:
                return renderBooleanField(params);
            case EVariableType.Image:
                return renderImageField(params);
            case EVariableType.Dropdown:
                return renderDropdownField(params);
            default:
                break;
        }
    };
    var renderInstance = function (instanceIndex) {
        var _a;
        var mergedInstanceIndex = Math.max(0, instanceIndexGroup, instanceIndex);
        var value = (_a = variablesData[variable.id]) === null || _a === void 0 ? void 0 : _a[mergedInstanceIndex];
        var showRemove = isTemplateDocument &&
            instancesToRender - 1 > 0 &&
            // Don't show remove on last index
            (instancesToRender === 0 ||
                (instancesToRender > 0 && instanceIndex !== instancesToRender - 1));
        var isEmptyTrailingInLoop = mergedInstanceIndex > 0 &&
            mergedInstanceIndex ===
                Math.max(1, groupInstancesToRender, instancesToRender) - 1;
        return (_jsxs(_Fragment, { children: [instanceIndex > 0 && _jsx(Spacer, { weight: ESpacerWeight.W04 }), _jsxs(S.InstanceRemoveWrap, { children: [_jsx(S.RemoveWrap, __assign({ isFirst: instanceIndex === 0 }, { children: showRemove ? (_jsx("button", __assign({ onClick: handlePressRemove(instanceIndex) }, { children: _jsx(Icon, { source: "bin", size: "small" }) }))) : (_jsx(S.RemovePlaceholder, {})) })), _jsx(S.InstanceWrap, { children: renderField({ instanceIndex: instanceIndex, value: value, isEmptyTrailingInLoop: isEmptyTrailingInLoop }) })] })] }));
    };
    return (_jsxs(S.Wrap, { children: [Array(instancesToRender)
                .fill(0)
                .map(function (_, instanceIndex) {
                return (_jsx(React.Fragment, { children: renderInstance(instanceIndex) }, instanceIndex));
            }), showVariableModal && (_jsx(VariableModal, { variableId: variable.id, onClose: function () {
                    setShowVariableModal(false);
                } }))] }));
};
export default VariablesFormVariable;
