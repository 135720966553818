var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentHooks from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.hooks";
import * as templateDocumentSelectors from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.selectors";
import { convertToRaw } from "draft-js";
import uniq from "lodash/uniq";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Error } from "../../components";
import * as systemActions from "../../redux/system/system.actions";
import { EPreferencePersistScope } from "../../redux/system/system.types";
import Editor from "../TemplateEditor/components/Editor";
import { EDisplayType, } from "../TemplateEditor/components/Editor/Editor.types";
import { useParsedTemplateDocument } from "./TemplateDocumentEditor.hooks";
import { getSignerFields } from "./TemplateDocumentEditor.utils";
var TemplateDocumentEditor = function () {
    // Router
    var navigate = useNavigate();
    var templateDocumentId = useParams().templateDocumentId;
    // Redux
    var dispatch = useDispatch();
    var _a = templateDocumentHooks.useDetail({ id: templateDocumentId }), templateDocument = _a.detail, isFetching = _a.isFetching, fetchError = _a.fetchError;
    var detail = useSelector(function (state) {
        return templateDocumentSelectors.templateDocumentDetail(state, templateDocumentId);
    });
    var isFetchingDetail = useSelector(function (state) {
        return templateDocumentSelectors.isFetchingTemplateDocumentDetail(state, templateDocumentId);
    });
    var fetchDetailError = useSelector(function (state) {
        return templateDocumentSelectors.templateDocumentDetailFetchError(state, templateDocumentId);
    });
    var isUpdatingDetail = useSelector(function (state) {
        return templateDocumentSelectors.isUpdatingTemplateDocumentDetail(state, templateDocumentId);
    });
    var _b = useParsedTemplateDocument(detail), rawState = _b.rawState, fields = _b.fields, variablesData = _b.variablesData, documentConfig = _b.documentConfig;
    // Lifecycle
    React.useEffect(function () {
        if (!templateDocumentId || isFetchingDetail) {
            return;
        }
        dispatch(templateDocumentActions.getTemplateDocumentDetail.actions.start({
            id: templateDocumentId,
        }));
    }, [templateDocumentId]);
    // Event handlers
    var handlePressSave = function (params) {
        var withGeneratePdf = params.withGeneratePdf, newEditorState = params.editorState, newFields = params.fields, newVariablesData = params.variablesData, newDocumentConfig = params.documentConfig, documentIds = params.documentIds, goBackOnSuccess = params.goBackOnSuccess;
        if (!templateDocumentId || !newVariablesData) {
            return;
        }
        // Store values in preferences
        var preferences = {};
        (newFields.variables || []).forEach(function (variable) {
            if (variable.persisted) {
                preferences["template_document:field:".concat(variable.id)] =
                    newVariablesData[variable.id];
            }
        });
        if (Object.keys(preferences).length > 0) {
            dispatch(systemActions.setPreferences({
                persistScope: EPreferencePersistScope.RemoteBroker,
                preferences: preferences,
            }));
        }
        // Update, generate PDF & navigate
        dispatch(templateDocumentActions.updateTemplateDocumentDetail.actions.start({
            id: templateDocumentId,
            data: {
                data: JSON.stringify(convertToRaw(newEditorState.getCurrentContent())),
                fields: JSON.stringify(newFields),
                variablesData: JSON.stringify(newVariablesData),
                documentConfig: JSON.stringify(newDocumentConfig),
                documentIds: uniq(__spreadArray(__spreadArray([], ((detail === null || detail === void 0 ? void 0 : detail.documentIds) || []), true), documentIds, true)),
                signerFields: getSignerFields({
                    fields: newFields,
                    variablesData: newVariablesData,
                }),
            },
            suppressSuccessMessage: withGeneratePdf,
            onSuccess: function () {
                if (withGeneratePdf) {
                    dispatch(templateDocumentActions.generateTemplateDocumentPdf.actions.start({
                        id: templateDocumentId,
                        onSuccess: function () {
                            navigate(-1);
                        },
                    }));
                }
                if (goBackOnSuccess) {
                    navigate(-1);
                }
            },
        }));
    };
    // Render
    var error = fetchError || fetchDetailError;
    if (error) {
        return _jsx(Error, { errors: [error] });
    }
    return (_jsx(Editor, { displayType: EDisplayType.TemplateDocument, templateDocumentId: templateDocumentId, title: templateDocument === null || templateDocument === void 0 ? void 0 : templateDocument.name, onPressSave: handlePressSave, isFetching: isFetching && isFetchingDetail, isUpdating: isUpdatingDetail, rawState: rawState, fields: fields, variablesData: variablesData, documentConfig: documentConfig, documentIds: (detail === null || detail === void 0 ? void 0 : detail.documentIds) || [] }));
};
export default TemplateDocumentEditor;
