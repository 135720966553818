import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import { ITemplateDocument } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import {
  Card,
  DisplayText,
  ESpacings,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StringParam } from "serialize-query-params";

interface IProps {
  templateDocument: ITemplateDocument;
  property: IProperty;
  onDrawerClose?: () => void;
}

const TemplateDocumentInfo: React.FC<IProps> = props => {
  const { templateDocument, onDrawerClose } = props;

  const navigate = useNavigate();

  const [, setQueryParamValue] = useQueryParams({
    documentId: StringParam,
    templateDocumentId: StringParam,
  });

  // Redux
  const dispatch = useDispatch();

  // Lifecycle
  React.useEffect(() => {
    if (templateDocument.documentId) {
      setQueryParamValue({
        documentId: templateDocument.documentId,
        templateDocumentId: null,
      });
    }
  }, [templateDocument.documentId]);

  // Event handlers
  const handlePressViewDocument = () => {
    setQueryParamValue({
      documentId: templateDocument.documentId,
      templateDocumentId: null,
    });
  };

  // Render
  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  return (
    <>
      {templateDocument.documentId && (
        <Card
          heading={renderCardHeader(getLocalizedText("system.document"))}
          space={ESpacings.base}
        >
          <Button appearance="link" onClick={handlePressViewDocument}>
            {getLocalizedText("summary.view.document")}
          </Button>
        </Card>
      )}

      <Card
        heading={renderCardHeader(
          getLocalizedText("template_document.detail.section.general.title"),
        )}
        subHeading={getLocalizedText(
          "template_document.detail.section.general.info",
        )}
        space={ESpacings.base}
        actions={[
          {
            content: (
              <TextStyle variation="negative">
                {getLocalizedText("system.delete")}
              </TextStyle>
            ),
            onClick: () => {
              confirm({
                title: getLocalizedText("system.remove_confirm.title", {
                  entity: getLocalizedText(
                    "system.entity.template_document",
                  ).toLowerCase(),
                }),
                primaryActions: [
                  {
                    title: getLocalizedText("system.remove"),
                    onPress: () => {
                      dispatch(
                        templateDocumentActions.deleteObject.actions.start({
                          id: templateDocument.id,
                        }),
                      );

                      onDrawerClose?.();
                    },
                  },
                ],
              });
            },
          },
        ]}
      >
        <Button
          appearance="link"
          onClick={() => {
            navigate(`/template-document/${templateDocument.id}`);
          }}
        >
          {getLocalizedText("document.template_document.cta.edit")}
        </Button>
      </Card>
    </>
  );
};

export default TemplateDocumentInfo;
