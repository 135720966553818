import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Card, Page } from "@rentiohq/web-shared/dist/components";
import { TabAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { Outlet, matchPath, useLocation } from "react-router-dom";
import { ESettingsPaths } from "./Settings.types";

const getFullSettingsPath = (subpath: ESettingsPaths) =>
  `/${ESettingsPaths.Base}/${subpath}`;

const tenantProfilePath = getFullSettingsPath(ESettingsPaths.TenantProfile);
const tenantProfileNotificationsPath = getFullSettingsPath(
  ESettingsPaths.TenantProfileNotifications,
);
const templatePreferencesPath = getFullSettingsPath(
  ESettingsPaths.TemplatePreferences,
);
const communicationPath = getFullSettingsPath(ESettingsPaths.Communication);

export const Settings: React.FC<{}> = () => {
  const tabs: TabAction[] = [];

  let location = useLocation();

  tabs.push({
    name: ESettingsPaths.TenantProfile,
    content: getLocalizedText("page.settings.tab.mytenantprofile"),
    url: tenantProfilePath,
    isActive: !!matchPath(tenantProfilePath, location.pathname),
  });

  tabs.push({
    name: ESettingsPaths.TenantProfileNotifications,
    content: getLocalizedText("page.settings.tab.mailing_preferences"),
    url: tenantProfileNotificationsPath,
    isActive: !!matchPath(tenantProfileNotificationsPath, location.pathname),
  });

  tabs.push({
    name: ESettingsPaths.TemplatePreferences,
    content: getLocalizedText("page.settings.tab.template_preferences"),
    url: templatePreferencesPath,
    isActive: !!matchPath(templatePreferencesPath, location.pathname),
  });

  tabs.push({
    name: ESettingsPaths.Communication,
    content: getLocalizedText("page.settings.tab.communication"),
    url: communicationPath,
    isActive: !!matchPath(communicationPath, location.pathname),
  });

  return (
    <Page title={getLocalizedText("page.settings.title")} tabs={tabs}>
      <Card>
        <Outlet />
      </Card>
    </Page>
  );
};
