import { EField } from "./schema.insertLoop.types";
export var getInitialValues = function (extraData) {
    var _a, _b;
    var loop = extraData.loop;
    if (loop) {
        return _a = {},
            _a[EField.Name] = loop.variableId,
            _a[EField.Group] = loop.groupId,
            _a[EField.Separator] = loop.separator,
            _a[EField.LastSeparator] = loop.lastSeparator,
            _a;
    }
    return _b = {},
        _b[EField.Name] = undefined,
        _b[EField.Group] = undefined,
        _b[EField.Separator] = undefined,
        _b[EField.LastSeparator] = undefined,
        _b;
};
