export var EDocumentMemberTypes;
(function (EDocumentMemberTypes) {
    EDocumentMemberTypes["Admin"] = "ADMIN";
    EDocumentMemberTypes["Viewer"] = "VIEWER";
})(EDocumentMemberTypes || (EDocumentMemberTypes = {}));
export var EDocumentCategoryLabels;
(function (EDocumentCategoryLabels) {
    EDocumentCategoryLabels["Other"] = "other";
    EDocumentCategoryLabels["Contract"] = "contract";
    EDocumentCategoryLabels["Certificate"] = "certificate";
    EDocumentCategoryLabels["invoice"] = "invoice";
    EDocumentCategoryLabels["RentDeposit"] = "rent_deposit";
    EDocumentCategoryLabels["LocationDescription"] = "location_description";
    EDocumentCategoryLabels["IdCardBack"] = "id_card_back";
    EDocumentCategoryLabels["IdCardFront"] = "id_card_front";
    EDocumentCategoryLabels["Image"] = "image";
    EDocumentCategoryLabels["RentalAssignment"] = "rental_assignment";
    EDocumentCategoryLabels["Attachment"] = "attachment";
    EDocumentCategoryLabels["Electricity"] = "electricity";
    EDocumentCategoryLabels["RegisteredMail"] = "registered_mail";
    // cspell:ignore rentdeposit
    EDocumentCategoryLabels["RentDepositValidation"] = "rentdeposit_validation";
    EDocumentCategoryLabels["Examination"] = "examination";
    EDocumentCategoryLabels["Epc"] = "epc";
    EDocumentCategoryLabels["HeaterMaintenance"] = "heater_maintenance";
    EDocumentCategoryLabels["RegisteredLetter"] = "registered_letter";
    EDocumentCategoryLabels["Quotation"] = "quotation";
    EDocumentCategoryLabels["CancelledContract"] = "cancelled_contract";
})(EDocumentCategoryLabels || (EDocumentCategoryLabels = {}));
