var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EEnvironment } from "@rentiohq/shared/dist/config/app.config.types";
import { ELocale } from "@rentiohq/shared/dist/types/i18n.types";
import { EPropertyEPCLabel, } from "@rentiohq/shared/dist/types/property.types";
import { getLocale, getLocalizedText } from "./i18n/i18n.utils";
var getTenantProfileDomain = function () {
    var locale = getLocale();
    switch (locale) {
        case ELocale.NL:
            return "mijnhuurprofiel.be";
        case ELocale.FR:
            return "monprofillocataire.be";
        default:
            return "mytenantprofile.be";
    }
};
var getTenantProfileEnvSubDomain = function () {
    var env = CONFIG.ENVIRONMENT;
    switch (env) {
        case EEnvironment.Dev:
            return "dev";
        case EEnvironment.Demo:
            return "demo";
        case EEnvironment.Stag:
            return "staging";
        case EEnvironment.Prod:
        default:
            return undefined;
    }
};
var getTenantProfileSubDomainItems = function (broker) {
    var items = [];
    if (CONFIG.ENVIRONMENT === EEnvironment.Prod) {
        if (broker === null || broker === void 0 ? void 0 : broker.intakePrefix) {
            items.push(broker.intakePrefix);
        }
        else if (broker === null || broker === void 0 ? void 0 : broker.maxImmoBrokerId) {
            items.push(broker.maxImmoBrokerId);
        }
    }
    var envSubDomain = getTenantProfileEnvSubDomain();
    if (envSubDomain) {
        items.push(envSubDomain);
    }
    return items;
};
export var getTenantProfileUrl = function (property, broker) {
    if (!property.propertyInfoId) {
        return;
    }
    var domain = getTenantProfileDomain();
    var subDomainItems = getTenantProfileSubDomainItems(broker);
    var host = __spreadArray(__spreadArray([], subDomainItems, true), [domain], false).join(".");
    return "https://".concat(host, "/property/").concat(property.propertyInfoId);
};
export var isFlemishZip = function (zip) {
    if (!zip) {
        return false;
    }
    var zipAsNumber = +zip;
    if (isNaN(zipAsNumber)) {
        return false;
    }
    // The last number in the range is not included
    // e.g. 2000 will check 1999
    var ranges = [
        [1500, 2000],
        [2000, 3000],
        [3000, 4000],
        [8000, 9000],
        [9000, 10000],
    ];
    return ranges.some(function (range) {
        return zipAsNumber >= range[0] && zipAsNumber < range[1];
    });
};
export var isBrusselsZip = function (zip) {
    if (!zip) {
        return false;
    }
    var zipAsNumber = +zip;
    if (isNaN(zipAsNumber)) {
        return false;
    }
    return zipAsNumber >= 1000 && zipAsNumber < 1300;
};
export var getAvailableEpcLabelsForProperty = function (params) {
    var countryId = params.countryId, zip = params.zip;
    // By default use all available EPC-labels for non-Belgian properties
    var epcLabels = Object.values(EPropertyEPCLabel);
    if (countryId && zip) {
        if (countryId === "BE") {
            if (isFlemishZip(zip)) {
                epcLabels = [
                    EPropertyEPCLabel.Unknown,
                    EPropertyEPCLabel.NoEPCAvailable,
                    EPropertyEPCLabel.APlus,
                    EPropertyEPCLabel.A,
                    EPropertyEPCLabel.B,
                    EPropertyEPCLabel.C,
                    EPropertyEPCLabel.D,
                    EPropertyEPCLabel.E,
                    EPropertyEPCLabel.F,
                ];
            }
            else {
                // Brussels & wallonia have the same EPC-labels
                epcLabels = [
                    EPropertyEPCLabel.Unknown,
                    EPropertyEPCLabel.NoEPCAvailable,
                    EPropertyEPCLabel.APlus,
                    EPropertyEPCLabel.A,
                    EPropertyEPCLabel.B,
                    EPropertyEPCLabel.C,
                    EPropertyEPCLabel.D,
                    EPropertyEPCLabel.E,
                    EPropertyEPCLabel.F,
                    EPropertyEPCLabel.G,
                ];
            }
        }
    }
    return Object.values(epcLabels).map(function (type) { return ({
        id: type,
        value: getLocalizedText("epc.label.".concat(type.toLowerCase())),
    }); });
};
