import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { Can } from "@rentiohq/web-shared/dist/components";
import { Navigate, useParams } from "react-router-dom";
import { PaymentsOverview } from "./PaymentsOverview";

export const PaymentsOverviewContainer = () => {
  const params = useParams<{ propertyId: string }>();

  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const { detail: property } = propertyHooks.useDetail({
    id: propertyId,
    shouldRefetch: false,
  });

  const renderContent = () => {
    if (!property) {
      return;
    }

    return <PaymentsOverview property={property} />;
  };

  return (
    <Can
      role={property?.roles}
      perform="propertyPayments:visit"
      yes={renderContent}
      no={() => <Navigate to={`/properties/${propertyId}`} />}
    />
  );
};
