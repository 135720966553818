var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getSignatureReferenceForAccountId } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { DisplayTypeContext } from "../../../Editor/contexts/DisplayType.context";
import { FieldsContext } from "../../../Editor/contexts/Fields.context";
import { LoopContext } from "../../../Editor/contexts/Loop.context";
import { VariablesDataContext } from "../../../Editor/contexts/VariablesData.context";
import * as S from "./SignatureBlock.styles";
var SignatureBlock = function (props) {
    var _a, _b, _c;
    var variableId = props.variableId;
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var isPrint = React.useContext(DisplayTypeContext).isPrint;
    var variable = (_a = fields.variables) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === variableId; });
    var groupId = variable === null || variable === void 0 ? void 0 : variable.groupId;
    var group = (_b = fields.groups) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === groupId; });
    var loopConfig = React.useContext(LoopContext).loopConfig;
    // Render
    if (!variableId || !variable || !group) {
        return null;
    }
    var index = 0;
    if (loopConfig) {
        var isLoopedVariable = loopConfig.variableId === variableId || loopConfig.groupId === groupId;
        if (isLoopedVariable) {
            index = loopConfig.index;
        }
    }
    var variableData = (_c = variablesData[variableId]) === null || _c === void 0 ? void 0 : _c[index];
    if (isPrint) {
        return (_jsx(S.InnerWrap, { children: _jsx(S.ResizeableWrap, __assign({ asVariable: false, isFilledIn: !!variableData, isPrint: true }, { children: _jsx(S.ReferencePrintText, { children: typeof variableData === "number" &&
                        getSignatureReferenceForAccountId(variableData) }) })) }));
    }
    return (_jsx(S.InnerWrap, { children: _jsx(S.ResizeableWrap, __assign({ asVariable: true, isFilledIn: !!variableData }, { children: "".concat(getLocalizedText(variable.name), " (").concat(getLocalizedText(group.name), ")") })) }));
};
export default SignatureBlock;
