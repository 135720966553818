var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import logger from "@rentiohq/shared/dist/logger";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateSuccessText, getGeneralRemoveSuccessText, getGeneralUpdateSuccessText, } from "../../utils/i18n/i18n.utils";
import * as commentActions from "./comment.actions";
import { createComment, deleteComment, getComments, updateComment, } from "./comment.api";
import { getComments as getCommentsSelector } from "./comment.selectors";
var FETCH_LIMIT = 5;
function getCommentsStartFlow(_a) {
    var commentIdentifier, baseUrl, limit, refetch, comments, _b, data, isExhausted, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                commentIdentifier = payload.commentIdentifier, baseUrl = payload.baseUrl, limit = payload.limit, refetch = payload.refetch;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 7]);
                _b = getCommentsSelector;
                return [4 /*yield*/, select()];
            case 2:
                comments = _b.apply(void 0, [_c.sent(), commentIdentifier]);
                return [4 /*yield*/, call(getComments, baseUrl, refetch ? 0 : (comments || []).length, limit)];
            case 3:
                data = (_c.sent()).data;
                isExhausted = data.length < (limit || FETCH_LIMIT);
                return [4 /*yield*/, put(commentActions.getComments.actions.success({
                        commentIdentifier: commentIdentifier,
                        comments: data,
                        refetch: refetch,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_1 = _c.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(commentActions.getComments.actions.failure({
                        error: error,
                        commentIdentifier: commentIdentifier,
                    }))];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function createCommentStartStartFlow(_a) {
    var content, baseUrl, commentIdentifier, data, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                content = payload.content, baseUrl = payload.baseUrl, commentIdentifier = payload.commentIdentifier;
                return [4 /*yield*/, call(createComment, baseUrl, content)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(commentActions.createComment.actions.success({
                        comment: data,
                        commentIdentifier: commentIdentifier,
                    }))];
            case 2:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("comment"),
                });
                return [3 /*break*/, 5];
            case 3:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(commentActions.createComment.actions.failure({ error: error }))];
            case 4:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function updateCommentStartFlow(_a) {
    var commentId, updatedComment, comment, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                commentId = payload.commentId, updatedComment = payload.updatedComment;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(updateComment, commentId, updatedComment)];
            case 2:
                comment = (_b.sent()).data;
                return [4 /*yield*/, put(commentActions.updateComment.actions.success({
                        commentId: commentId,
                        comment: comment,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("comment"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(commentActions.updateComment.actions.failure({
                        error: error,
                        commentId: commentId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function deleteCommentStartFlow(_a) {
    var commentId, commentIdentifier, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                commentId = payload.commentId, commentIdentifier = payload.commentIdentifier;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(deleteComment, commentId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(commentActions.deleteComment.actions.success({
                        commentId: commentId,
                        commentIdentifier: commentIdentifier,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralRemoveSuccessText("comment"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(commentActions.deleteComment.actions.failure({
                        error: error,
                        commentId: commentId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(commentActions.getComments.types.START, getCommentsStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(commentActions.createComment.types.START, createCommentStartStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(commentActions.updateComment.types.START, updateCommentStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(commentActions.deleteComment.types.START, deleteCommentStartFlow)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default saga;
