import isEmpty from "lodash/isEmpty";
import { ERentioFormField } from "../../components/components/Form";
import { getName } from "../../redux/contact/contact.utils";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createRentDeposit.types";
import { getForeigners, getNavigationTitle, } from "./schema.createRentDeposit.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a, _b;
        return {
            required: [EField.IdentityDocuments],
            properties: (_a = {},
                _a[EField.Header + "5"] = {
                    default: undefined,
                },
                _a[EField.IdentityDocuments] = {
                    default: (function () {
                        var value = getValue(EField.IdentityDocuments, initialValues, defaultValues);
                        if (!value || isEmpty(value)) {
                            var foreigners = getForeigners(getValue(EField.Signers, initialValues, defaultValues));
                            return (foreigners || []).map(function (contact) {
                                var _a;
                                return (_a = {},
                                    _a[EField.IdentityDocumentAccountId] = contact.accountId,
                                    _a.contact = contact,
                                    _a);
                            });
                        }
                        return value;
                    })(),
                    type: "array",
                    items: {
                        type: "object",
                        required: [
                            EField.IdentityDocumentIdentityFrontDocumentId,
                            EField.IdentityDocumentIdentityBackDocumentId,
                        ],
                        properties: (_b = {},
                            _b[EField.IdentityDocumentAccountId] = {},
                            _b[EField.IdentityDocumentIdentityFrontDocumentId] = {
                                minLength: 1,
                            },
                            _b[EField.IdentityDocumentIdentityBackDocumentId] = { minLength: 1 },
                            _b),
                    },
                    uniqueItems: true,
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a, _b;
    return _a = {
            "rentio:title": getLocalizedText("rent_deposit.identity_documents.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "5"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit.identity_documents.heading"),
        },
        _a[EField.IdentityDocuments] = {
            "ui:field": ERentioFormField.Array,
            title: getLocalizedText("rent_deposit.identity_documents.heading"),
            "ui:options": {
                orderable: false,
                addable: false,
                removable: false,
            },
            getItemTitle: function (itemData) { return getName(itemData.contact); },
            items: (_b = {},
                _b[EField.IdentityDocumentAccountId] = { "ui:widget": "hidden" },
                _b[EField.IdentityDocumentIdentityFrontDocumentId] = {
                    "ui:field": ERentioFormField.DocumentSelectInline,
                    formatSummary: function (formData) {
                        return formData === null || formData === void 0 ? void 0 : formData.map(function (doc) { return doc.name; }).join(", ");
                    },
                    accept: ["image/*"],
                    multiple: false,
                    title: getLocalizedText("rent_deposit.identity_documents.front_document.label"),
                },
                _b[EField.IdentityDocumentIdentityBackDocumentId] = {
                    "ui:field": ERentioFormField.DocumentSelectInline,
                    formatSummary: function (formData) {
                        return formData === null || formData === void 0 ? void 0 : formData.map(function (doc) { return doc.name; }).join(", ");
                    },
                    accept: ["image/*"],
                    multiple: false,
                    title: getLocalizedText("rent_deposit.identity_documents.back_document.label"),
                },
                _b),
            formatSummary: function (input) {
                return input
                    .map(function (item) { return item.contact && getName(item.contact); })
                    .filter(Boolean)
                    .join(", ");
            },
        },
        _a;
};
