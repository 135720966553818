import styled, { spacing } from "@rentiohq/web-theme";

export const Wrapper = styled.div`
  transform: translate3d(0px, 0px, 0px) scaleY(-1);
  height: 100%;
  overflow-y: auto;
  > div {
    transform: scaleY(-1);
  }
`;

export const System = styled.div`
  padding: ${spacing("tight")};
  text-align: center;
`;

export const ItemWrap = styled.div`
  padding: ${spacing("tight")};
  width: 100%;
  max-width: 850px;
  margin: auto;
`;
