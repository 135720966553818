import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import {
  EExportFileType,
  EExportType,
  ERevenuesInvoicedStatus,
  ERevenuesPaymentStatus,
  exportFileModule,
  IExportRevenuesParameters,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.revenuesExport.types";

export const isPerformingSubmitActionSelector = (state: IRootStore) =>
  exportFileModule.selectors.isCreating(state);

export const performingSubmitActionErrorSelector = (state: IRootStore) =>
  exportFileModule.selectors.createError(state);

export const performingSubmitActionResultSelector = (state: IRootStore) =>
  paymentSelectors.getPaymentRequestsForPage(state, "revenues-export", 1);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const paymentStatus = formData[
      EField.PaymentStatus
    ] as ERevenuesPaymentStatus[];
    const invoicedStatus = formData[
      EField.Invoiced
    ] as ERevenuesInvoicedStatus[];
    const startDate = new Date(formData[EField.From]);
    const endDate = new Date(formData[EField.To]);

    const exportRevenuesParameters: IExportRevenuesParameters = {
      startDate,
      endDate,
      paymentStatus,
      invoicedStatus,
    };

    return exportFileModule.actions.createStart.getAction({
      data: {
        exportFileType: EExportFileType.Csv,
        exportType: EExportType.Revenues,
        exportParameters: { exportRevenuesParameter: exportRevenuesParameters },
      },
    });
  };
