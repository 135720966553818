import { orderBy } from "lodash";
import { REDUCER_KEY_COMMENT } from "./comment.reducer";
export var getComments = function (state, commentIdentifier) {
    var commentState = state.comment.commentsByIdentifier[commentIdentifier];
    if (!commentState) {
        return;
    }
    var commentIds = commentState.commentIds;
    if (!commentIds) {
        return;
    }
    var comments = commentIds
        .map(function (commentId) { return state.comment.comments[commentId]; })
        .filter(function (comment) { return comment !== undefined; });
    return orderBy(comments, ["createdAt"], ["desc"]);
};
export var getIsFetchingComments = function (state, commentIdentifier) {
    var commentState = state.comment.commentsByIdentifier[commentIdentifier];
    if (!commentState) {
        return false;
    }
    return commentState.isFetching;
};
export var getFetchCommentsError = function (state, commentIdentifier) {
    var commentState = state.comment.commentsByIdentifier[commentIdentifier];
    if (!commentState) {
        return;
    }
    return commentState.fetchError;
};
export var getIsDeletingComment = function (state, commentId) {
    var commentState = state.comment.commentsById[commentId];
    if (!commentState) {
        return false;
    }
    return commentState.isDeleting;
};
