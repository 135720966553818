import * as S from "./ProgressBar.styled";

interface IProgressBarProps {
  completed: number;
  pending?: number;
  total: number;
}

export const ProgressBar = (props: IProgressBarProps) => {
  const { completed, pending, total } = props;

  return (
    <S.Wrap>
      <S.Pending pending={(completed + (pending || 0)) / total} />
      <S.Completed completed={completed / total} />
    </S.Wrap>
  );
};
