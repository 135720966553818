import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import {
  EPaymentRequestStatus,
  IPaymentRequest,
} from "@rentiohq/shared/dist/types/payment.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { Modal } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../services";

interface IThirdPartyAccountModalProps {
  paymentRequest: IPaymentRequest;
  onClose: (refetch: boolean) => void;
}

export const ThirdPartyAccountModal: React.FC<IThirdPartyAccountModalProps> = ({
  paymentRequest,
  onClose,
}) => {
  const dispatch = useDispatch();
  const isMarkingThirdPartyReceived = useSelector((state: IRootStore) =>
    paymentRequest
      ? paymentSelectors.isMarkingThirdPartyReceived(state, paymentRequest.id)
      : false,
  );
  const prevIsMarkingThirdPartyReceived = usePrevious(
    isMarkingThirdPartyReceived,
  );

  React.useEffect(() => {
    if (!isMarkingThirdPartyReceived && prevIsMarkingThirdPartyReceived) {
      onClose(true);
    }
  }, [isMarkingThirdPartyReceived, onClose, prevIsMarkingThirdPartyReceived]);

  // Event listeners
  const handleThirdPartyAccountModalSubmit = (id: number) => () => {
    dispatch(
      paymentActions.markThirdPartyReceivedPaymentRequest.actions.start({
        paymentRequestId: id,
      }),
    );
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal
      onClose={handleClose}
      width="medium"
      heading={ts.paymentRequestThirdPartyAccountModalHeading()}
      actions={[
        {
          content: ts.system("cancel"),
          appearance: "outline",
          onClick: handleClose,
        },
        {
          content: ts.paymentRequestThirdPartyAccountModalAction(),
          appearance: "primary",
          onClick: handleThirdPartyAccountModalSubmit(paymentRequest.id),
        },
      ]}
    >
      {ts.paymentRequestThirdPartyAccountModalContent({
        values: {
          amount: formatCurrency(
            paymentRequest.status === EPaymentRequestStatus.Partial
              ? paymentRequest.amount
              : paymentRequest.originalAmount,
          ),
        },
      })}
    </Modal>
  );
};
