import { Box } from "@rebass/grid";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import { ESpacings, Select } from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { IOption } from "@rentiohq/web-shared/dist/components/OptionList/OptionList.types";

interface ISelectEmployeeDropdownProps {
  accountId?: number;
  employeeAccounts: IAccount[];
  hideDropdown: (accountId: number) => void;
  onSelectEmployee: (selected: IOption) => void;
}

export function SelectEmployeeDropdown({
  accountId,
  employeeAccounts,
  hideDropdown,
  onSelectEmployee,
}: ISelectEmployeeDropdownProps) {
  return (
    <Box mt={ESpacings.base}>
      <Select
        id={`case-manager-${accountId ?? "empty"}`}
        label="_"
        labelHidden={true}
        options={employeeAccounts.map(employeeAccount => {
          return {
            label: getName(employeeAccount),
            value: employeeAccount.id,
          };
        })}
        onChange={onSelectEmployee}
      />

      <Box mt={1}>
        <Button appearance="link" onClick={hideDropdown}>
          {getLocalizedText("system.cancel")}
        </Button>
      </Box>
    </Box>
  );
}
