import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { IDocumentCategory } from "@rentiohq/shared/dist/types/document.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";

export interface IExtraData {
  property: IProperty;
  contract: IContract;
  contactMeMaster?: IContact;
  legalContact?: IContact;
  isBroker?: boolean;
  documentCategories: IDocumentCategory[];
}

export enum EField {
  Owners = "owners",
  SignAsBroker = "signAsBroker",
  Tenants = "tenants",

  RentContractDocumentToUpload = "rentContractDocumentToUpload",
  RentContractDestination = "rentContractDestination",
  RentContractSignedAt = "rentContractSignedAt",
  RentContractPropertyTaxPaidByTenant = "rentContractPropertyTaxPaidByTenant",

  LocationDescriptionShouldUpload = "locationDescriptionShouldUpload",
  LocationDescriptionDocumentToUpload = "locationDescriptionDocumentToUpload",
  LocationDescriptionSignedAt = "locationDescriptionSignedAt",
}
