import { IPaymentOrder } from "@rentiohq/shared/dist/types/payment.types";

export interface IPaymentOrderContainerProps {
  paymentOrderId: number;
  onDrawerClose: () => void;
}

export interface IPaymentOrderProps extends IPaymentOrderContainerProps {
  paymentOrder: IPaymentOrder;
}

export enum EPaymentOrderTabs {
  Info,
  History,
}
