import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { NoResults } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";

export const AccountContact: React.FC<{}> = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const dispatch = useDispatch();
  const contact = useSelector((state: IRootStore) =>
    contactSelectors.getContact(state, undefined, Number(accountId)),
  );

  React.useEffect(() => {
    if (contact) {
      return;
    }
    dispatch(
      contactActions.getContactsByAccountIds.actions.start({
        accountIds: accountId ? [+accountId] : [],
      }),
    );
  }, [accountId]);

  const renderContent = () => {
    if (!accountId) {
      return <Navigate to="/contacts" replace={true} />;
    }

    if (contact) {
      return <Navigate to={`/contacts/${contact.id}`} replace={true} />;
    }

    return (
      <NoResults
        iconColor="red"
        icon="profile"
        heading={getLocalizedText("contact.by_account.not_found")}
      />
    );
  };

  return renderContent();
};
