import * as actions from "./report.beneficiary.actions";
import * as api from "./report.beneficiary.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY, } from "./report.beneficiary.constants";
import * as hooks from "./report.beneficiary.hooks";
import * as promises from "./report.beneficiary.promises";
import reducer from "./report.beneficiary.reducer";
import { saga } from "./report.beneficiary.saga";
import * as selectors from "./report.beneficiary.selectors";
export var REDUCER_KEY_REPORT_BENEFICIARY = REDUCER_KEY;
export var reportBeneficiaryModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
