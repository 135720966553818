import activitySaga from "@rentiohq/shared-frontend/dist/redux/activity/activity.saga";
import authSaga from "@rentiohq/shared-frontend/dist/redux/auth/auth.saga";
import brokerSaga from "@rentiohq/shared-frontend/dist/redux/broker/broker.saga";
import cardSaga from "@rentiohq/shared-frontend/dist/redux/card/card.saga";
import commentSaga from "@rentiohq/shared-frontend/dist/redux/comment/comment.saga";
import contactSaga from "@rentiohq/shared-frontend/dist/redux/contact/contact.saga";
import contractSaga from "@rentiohq/shared-frontend/dist/redux/contract/contract.saga";
import countSaga from "@rentiohq/shared-frontend/dist/redux/count/count.saga";
import documentPackageSaga from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.saga";
import employeeAccountSaga from "@rentiohq/shared-frontend/dist/redux/employeeAccount/employeeAccount.saga";
import expertInquirySaga from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.saga";
import featureFlagSaga from "@rentiohq/shared-frontend/dist/redux/featureFlag/featureFlag.saga";
import insuranceSaga from "@rentiohq/shared-frontend/dist/redux/insurance/insurance.saga";
import invitesSaga from "@rentiohq/shared-frontend/dist/redux/invites/invites.saga";
import invoiceSaga from "@rentiohq/shared-frontend/dist/redux/invoice/invoice.saga";
import invoiceItemsSaga from "@rentiohq/shared-frontend/dist/redux/invoiceItem/invoiceItem.saga";
import moveRequestSaga from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.saga";
import notificationSaga from "@rentiohq/shared-frontend/dist/redux/notification/notification.saga";
import paymentSaga from "@rentiohq/shared-frontend/dist/redux/payment/payment.saga";
import pricingSaga from "@rentiohq/shared-frontend/dist/redux/pricing/pricing.saga";
import propertySaga from "@rentiohq/shared-frontend/dist/redux/property/property.saga";
import registrationSaga from "@rentiohq/shared-frontend/dist/redux/registration/registration.saga";
import registrationDocumentSaga from "@rentiohq/shared-frontend/dist/redux/registrationDocument/registrationDocument.saga";
import rentDepositSaga from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.saga";
import requirementsSaga from "@rentiohq/shared-frontend/dist/redux/requirements/requirements.saga";
import socketSaga from "@rentiohq/shared-frontend/dist/redux/socket/socket.saga";
import subscriptionSaga from "@rentiohq/shared-frontend/dist/redux/subscription/subscription.saga";
import templateSaga from "@rentiohq/shared-frontend/dist/redux/template/template.saga";
import templateDocumentSaga from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.saga";
import { applicationModule } from "@rentiohq/shared-frontend/dist/reduxV2/applications";
import { bankAccountModule } from "@rentiohq/shared-frontend/dist/reduxV2/bankAccount";
import { chatModule } from "@rentiohq/shared-frontend/dist/reduxV2/chats";
import { contractModule } from "@rentiohq/shared-frontend/dist/reduxV2/contract";
import { documentModule } from "@rentiohq/shared-frontend/dist/reduxV2/documents";
import { exportFileModule } from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { legalCaseModule } from "@rentiohq/shared-frontend/dist/reduxV2/legalCase";
import { noteModule } from "@rentiohq/shared-frontend/dist/reduxV2/note";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import { propertyModule } from "@rentiohq/shared-frontend/dist/reduxV2/property";
import { reportBeneficiaryModule } from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import featureFlagLocalSaga from "@rentiohq/web-shared/dist/redux/featureFlag/featureFlag.saga";
import requirementsLocalSaga from "@rentiohq/web-shared/dist/redux/requirements/requirements.saga";
import socketLocalSaga from "@rentiohq/web-shared/dist/redux/socket/socket.saga";
import statsSaga from "@rentiohq/web-shared/dist/redux/stats/stats.saga";
import systemLocalSaga from "@rentiohq/web-shared/dist/redux/system/system.saga";
import { all, fork } from "redux-saga/effects";

export const rootSaga = function* () {
  yield all([
    fork(activitySaga),
    fork(applicationModule.saga),
    fork(authSaga),
    fork(bankAccountModule.saga),
    fork(brokerSaga),
    fork(cardSaga),
    fork(chatModule.saga),
    fork(commentSaga),
    fork(contactSaga),
    fork(contractModule.saga),
    fork(contractSaga),
    fork(countSaga),
    fork(documentModule.saga),
    fork(documentPackageSaga),
    fork(employeeAccountSaga),
    fork(expertInquirySaga),
    fork(exportFileModule.saga),
    fork(featureFlagLocalSaga),
    fork(featureFlagSaga),
    fork(insuranceSaga),
    fork(invitesSaga),
    fork(invoiceItemsSaga),
    fork(invoiceSaga),
    fork(invoiceSaga),
    fork(legalCaseModule.saga),
    fork(moveRequestSaga),
    fork(noteModule.saga),
    fork(notificationSaga),
    fork(payInModule.saga),
    fork(paymentSaga),
    fork(pricingSaga),
    fork(propertyModule.saga),
    fork(propertySaga),
    fork(registrationDocumentSaga),
    fork(registrationSaga),
    fork(rentDepositSaga),
    fork(reportBeneficiaryModule.saga),
    fork(requirementsLocalSaga),
    fork(requirementsSaga),
    fork(socketLocalSaga),
    fork(socketSaga),
    fork(statsSaga),
    fork(subscriptionSaga),
    fork(systemLocalSaga),
    fork(taskModule.saga),
    fork(templateDocumentSaga),
    fork(templateSaga),
  ]);
};
