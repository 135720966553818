import { Box } from "@rebass/grid";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IApplication } from "@rentiohq/shared/dist/types/application.types";
import { DisplayText, ESpacings } from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { useState } from "react";
import { DragItem } from "./DragItem";

interface IProps {
  applications: IApplication[];
}

const getArchivedApplications = (applications: IApplication[]) =>
  applications.filter(application => !!application.archivedAt);

export const ArchivedApplications = ({ applications }: IProps) => {
  const [showArchivedApplications, setShowArchivedApplications] =
    useState<boolean>(false);

  const archivedApplications = getArchivedApplications(applications);
  if (archivedApplications.length === 0) {
    return null;
  }

  const handleClickToggleShowArchivedApplications = () => {
    setShowArchivedApplications(!showArchivedApplications);
  };

  const toggleText = `applications.cta.archived_${
    showArchivedApplications ? "hide" : "show"
  }`;

  return (
    <Box mt={ESpacings.loose}>
      {showArchivedApplications && (
        <>
          <DisplayText size="medium">
            {getLocalizedText("applications.section.archived")}
          </DisplayText>

          {archivedApplications.map(archivedApplication => (
            <DragItem
              item={archivedApplication}
              renderAsDragItem={false}
              isSelected={false}
            />
          ))}
        </>
      )}

      <Box mt={ESpacings.loose}>
        <Button
          appearance="link"
          onClick={handleClickToggleShowArchivedApplications}
        >
          {getLocalizedText(toggleText)}
        </Button>
      </Box>
    </Box>
  );
};
