var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
var PaymentRequestFetch = /** @class */ (function (_super) {
    __extends(PaymentRequestFetch, _super);
    // Initialize
    function PaymentRequestFetch(props) {
        var _this = _super.call(this, props) || this;
        // Data
        _this.fetchData = function () {
            _this.props.getPaymentRequest({
                id: _this.props.id,
                publicId: _this.props.publicId,
            });
        };
        _this.fetchData();
        return _this;
    }
    // Render
    PaymentRequestFetch.prototype.render = function () {
        var _a = this.props, isFetchingPaymentRequest = _a.isFetchingPaymentRequest, fetchErrorPaymentRequest = _a.fetchErrorPaymentRequest, paymentRequest = _a.paymentRequest, renderLoading = _a.renderLoading, renderError = _a.renderError, renderNotLoaded = _a.renderNotLoaded, renderPaymentRequest = _a.renderPaymentRequest;
        if (paymentRequest) {
            return renderPaymentRequest ? renderPaymentRequest(paymentRequest) : null;
        }
        if (isFetchingPaymentRequest) {
            return renderLoading ? renderLoading() : null;
        }
        if (fetchErrorPaymentRequest) {
            return renderError
                ? renderError(this.fetchData, fetchErrorPaymentRequest)
                : null;
        }
        return renderNotLoaded ? renderNotLoaded() : null;
    };
    return PaymentRequestFetch;
}(React.Component));
export default PaymentRequestFetch;
