import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countUtils from "@rentiohq/shared-frontend/dist/redux/count/count.utils";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as applicationHooks from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.hooks";
import * as applicationUtils from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as documentApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import { EDocumentCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.types";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import * as propertyActions from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { EPropertyFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.types";
import * as propertyUtils from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import * as tasksHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import { ETaskCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { canAddPaymentOrders } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  capitalizeString,
  join,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { EIntakeStep } from "@rentiohq/shared/dist/types/application.types";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";
import {
  EPropertyMemberTypes,
  EPropertyTypeId,
} from "@rentiohq/shared/dist/types/property.types";
import { ETaskMemberType } from "@rentiohq/shared/dist/types/task.types";
import { getMembersWithRole } from "@rentiohq/shared/dist/utils/roles.utils";
import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import {
  Error,
  Loading,
  MultiStepForm,
  Page,
  TextStyle,
  check,
} from "@rentiohq/web-shared/dist/components";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import utils from "@rentiohq/web-shared/dist/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { IRootStore } from "redux/reducers";
import createEditPropertyInfoForm from "../../../../forms/editPropertyInfo";
import * as t from "../../../../services/translationService";
import { PropertyArchivedCard } from "./components/PropertyArchivedCard";
import { PropertyMeta } from "./components/PropertyMeta";

const formId = generateFormId();

export const PropertyDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  // State
  const [showPropertyInfoForm, setShowPropertyInfoForm] = useState(false);

  // Redux
  const {
    detail: property,
    isFetching,
    fetchError,
    refetch,
  } = propertyHooks.useDetail({
    id: propertyId,
    shouldRefetch: true,
  });

  const contractsExtraFilterData = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Active)
    : undefined;

  const { items: currentContracts } = contractHooks.useGetAll({
    // TODO: should we add refetch?
    //refetch: true,
    query: { filter: contractsExtraFilterData },
  });

  const { user, isBroker } = authHooks.useSelf();

  const hasMoveServiceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.MoveService,
  );

  const extraFilterData = { propertyId: { eq: propertyId } };

  const { count: documentsForProperty = 0 } = documentHooks.useCount(
    documentUtils.getDocumentPagedQuery({
      extraFilterData,
      customFilters: [
        EDocumentCustomFilter.Active,
        EDocumentCustomFilter.ExcludeInternalDocuments,
      ],
    }),
  );

  const countIdentifierTemplateDocumentsForProperty = propertyId
    ? countUtils.getCountIdentifierTemplateDocumentsForProperty(propertyId)
    : undefined;
  const templateDocumentsForPropertyCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, countIdentifierTemplateDocumentsForProperty),
  );
  const isFetchingTemplateDocumentsForPropertyCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getIsFetchingCount(
        state,
        countIdentifierTemplateDocumentsForProperty,
      ),
  );

  const { update } = propertyHooks.useUpdate({
    id: propertyId,
  });

  const { archive } = propertyHooks.useArchive({
    id: propertyId,
  });

  const { unarchive } = propertyHooks.useUnarchive({
    id: propertyId,
  });

  const isGroup = property?.typeId === EPropertyTypeId.Group;

  const propertiesInGroupCount = useSelector((state: IRootStore) => {
    if (!isGroup) {
      return;
    }

    return propertySelectors.getCount(
      state,
      propertyId
        ? propertyUtils.getFilter(EPropertyFetchType.InGroup, propertyId)
        : undefined,
    );
  });
  const isFetchingPropertiesInGroupCount = useSelector((state: IRootStore) => {
    if (!isGroup) {
      return false;
    }

    return countSelectors.getIsFetchingCount(
      state,
      propertyId
        ? countUtils.getCountIdentifierPropertiesInGroup(propertyId)
        : undefined,
    );
  });

  const { countSteps } = applicationHooks.useCountSteps({
    query: isGroup
      ? undefined
      : applicationUtils.getApplicationsQuery({
          filter: {
            archivedAt: { is: null },
            propertyId: { eq: propertyId },
          },
        }),
  });
  let activeApplicationsCount: number | undefined;
  if (countSteps) {
    Object.keys(countSteps.steps).forEach(key => {
      if (+key !== EIntakeStep.notEligible) {
        activeApplicationsCount = activeApplicationsCount || 0;
        activeApplicationsCount += countSteps.steps[+key];
      }
    });
  }

  const activePaymentOrdersCountIdentifier = propertyId
    ? countUtils.getCountIdentifierActivePaymentOrdersForProperty(propertyId)
    : undefined;
  const activePaymentOrdersCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, activePaymentOrdersCountIdentifier),
  );
  const isFetchingActivePaymentOrdersCount = useSelector((state: IRootStore) =>
    countSelectors.getIsFetchingCount(
      state,
      activePaymentOrdersCountIdentifier,
    ),
  );

  const { count: allForThisProperty } = tasksHooks.useCount({
    query: {
      filter: {
        propertyId: { eq: propertyId },
        finishedAt: { is: null },
      },
      customFilters: [ETaskCustomFilter.NotArchived],
      rolesFilter: [ETaskMemberType.Executor, ETaskMemberType.Follower],
    },
  });

  // Active tab
  const generalIsActive = !!matchPath(
    "/properties/:propertyId/",
    location.pathname,
  );
  const groupIsActive = !!matchPath(
    "/properties/:propertyId/group-info",
    location.pathname,
  );
  const applicationsIsActive = !!matchPath(
    "/properties/:propertyId/applications",
    location.pathname,
  );
  const contractsIsActive = !!matchPath(
    "/properties/:propertyId/contracts",
    location.pathname,
  );
  const paymentsIsActive = !!matchPath(
    "/properties/:propertyId/payments",
    location.pathname,
  );
  const documentsIsActive = !!matchPath(
    "/properties/:propertyId/documents",
    location.pathname,
  );
  const extraIsActive = !!matchPath(
    "/properties/:propertyId/extra",
    location.pathname,
  );
  const historyIsActive = !!matchPath(
    "/properties/:propertyId/history",
    location.pathname,
  );
  const tasksIsActive = !!matchPath(
    "/properties/:propertyId/tasks",
    location.pathname,
  );

  useEffect(() => {
    if (!isGroup) {
      return;
    }

    if (isFetchingPropertiesInGroupCount) {
      return;
    }

    if (!propertyId) {
      return;
    }

    dispatch(
      propertyActions.getCountStart.getAction({
        query: propertyUtils.getFilter(EPropertyFetchType.InGroup, propertyId),
      }),
    );
  }, [propertyId, isGroup]);

  useEffect(() => {
    if (isFetchingActivePaymentOrdersCount) {
      return;
    }

    if (!propertyId) {
      return;
    }

    dispatch(
      countActions.getCount.actions.start(
        countUtils.getActionPayloadActivePaymentOrdersForProperty(propertyId),
      ),
    );
  }, [propertyId]);

  useEffect(() => {
    if (isFetchingTemplateDocumentsForPropertyCount) {
      return;
    }

    if (!propertyId) {
      return;
    }

    dispatch(
      countActions.getCount.actions.start(
        countUtils.getActionPayloadTemplateDocumentsForProperty(propertyId),
      ),
    );
  }, [propertyId]);

  // Render
  if (!property && isFetching) {
    return <Loading />;
  }

  if (!property && fetchError) {
    console.error(fetchError);
    return <Error errors={[fetchError]} backLink="/properties" />;
  }

  if (!property || !user) {
    return null;
  }

  const { archivedAt, roles } = property;

  let documentsCount = undefined;
  if (templateDocumentsForPropertyCount !== undefined) {
    documentsCount = documentsForProperty + templateDocumentsForPropertyCount;
  }

  let tabs = [
    {
      name: "dashboard",
      permission: "propertyDashboard:visit",
      content: t.propertyDetailTabsInformation(),
      url: `/properties/${propertyId}/`,
      isActive: generalIsActive,
    },
    {
      name: "groupInfo",
      permission: "propertyDashboard:visit",
      content: getLocalizedText("property.detail.tabs.group_info"),
      url: `/properties/${propertyId}/group-info`,
      isActive: groupIsActive,
      count: propertiesInGroupCount,
      showTab: isGroup,
    },
    {
      name: "applications",
      permission: "propertyApplications:visit",
      content: t.propertyDetailTabsApplications(),
      url: `/properties/${propertyId}/applications`,
      isActive: applicationsIsActive,
      count: activeApplicationsCount,
      showTab: !isGroup,
    },
    {
      name: "contracts",
      permission: "propertyContracts:visit",
      content: t.propertyDetailTabsContracts(),
      url: `/properties/${propertyId}/contracts`,
      isActive: contractsIsActive,
      count: currentContracts?.length,
      showTab: !isGroup,
    },
    {
      name: "payments",
      permission: "propertyPayments:visit",
      content: t.propertyDetailTabsPayments(),
      url: `/properties/${propertyId}/payments`,
      isActive: paymentsIsActive,
      count: activePaymentOrdersCount,
      showTab: !isGroup,
    },
    {
      name: "tasks",
      permission: "propertyDashboard:visit",
      content: t.propertyDetailTabsTasks(),
      url: `/properties/${propertyId}/tasks`,
      isActive: tasksIsActive,
      count: allForThisProperty,
    },
    {
      name: "documents",
      permission: "propertyDocuments:visit",
      content: t.propertyDetailTabsDocuments(),
      url: `/properties/${propertyId}/documents`,
      isActive: documentsIsActive,
      count: documentsCount,
    },
    {
      name: "extra",
      permission: "propertyDashboard:visit",
      content: getLocalizedText("property.detail.tabs.extra"),
      url: `/properties/${propertyId}/extra`,
      isActive: extraIsActive,
      showTab: !isGroup,
    },
    {
      name: "history",
      permission: "propertyHistory:visit",
      content: t.propertyDetailTabsHistory(),
      url: `/properties/${propertyId}/history`,
      isActive: historyIsActive,
    },
  ].filter(({ showTab = true }) => showTab);

  tabs = tabs.filter((tab: any) => check(roles || [], tab.permission));

  const subtitle = property?.reference
    ? `${property.reference} | ${property.name}`
    : property.name;
  const metadata = <PropertyMeta property={property} />;

  const editProperty = () => {
    navigate(`/properties/${property.id}/edit`);
  };

  const handleClickArchiveProperty = () => {
    if (!propertyId) {
      return;
    }

    if (archivedAt) {
      unarchive({});
    } else {
      archive({});
    }
  };

  const handleCreateDocumentPackageClick = () => {
    navigate(
      appendQueryParams({
        path: "/forms/document-package",
        queryParams: {
          propertyId,
        },
      }),
    );
  };

  const handleAddTaskClick = () => {
    navigate(
      appendQueryParams({
        path: "/tasks/add",
        queryParams: {
          propertyId,
        },
      }),
    );
  };

  const handlePressRequestMoveContractForEmptyProperty = () => {
    if (!hasMoveServiceForBroker) {
      return;
    }

    const tenantNames = join(
      getMembersWithRole(property.members, EPropertyMemberTypes.Tenant).map(x =>
        getName(x.account),
      ),
    );
    const ownerNames = join(
      getMembersWithRole(property.members, EPropertyMemberTypes.Owner).map(x =>
        getName(x.account),
      ),
    );

    confirm({
      title: capitalizeString(
        getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.confirm.title",
        ),
      ),
      info: capitalizeString(
        getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.confirm.info",
          {
            email: user?.email || "?",
          },
        ),
      ),
      inputValueProps: {
        type: "string",
        asMultiline: true,
        label: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.remarks.label",
        ),
        initialValue: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.remarks.initial_value",
          {
            tenantName: tenantNames,
            tenantAddress: formatAddress(property),
            tenantVat: "",
            tenantDateOfBirth: "",

            ownerName: ownerNames,
          },
        ),
        optional: true,
      },
      primaryActions: [
        {
          title: getLocalizedText("system.request"),
          onPress: async ({ inputValue } = {}) => {
            try {
              await api.post(
                `/properties/${propertyId}/request-move-request-contract`,
                {
                  data: {
                    extraRemarks: inputValue,
                  },
                },
              );

              showAlert({
                type: "success",
                message: getLocalizedText(
                  "contract.move.request_move_contract_for_empty_property.alert.success",
                ),
              });
            } catch (unknownError) {
              const error = unknownError as any;
              showAlert({
                type: "error",
                error,
                message: getLocalizedText(
                  "contract.move.request_move_contract_for_empty_property.alert.failure",
                ),
              });
            }
          },
        },
      ],
    });
  };

  const pageVariation = archivedAt ? "error" : undefined;
  const dropdownLabel = archivedAt ? t.unarchive() : t.archive();
  const dropdownLabelVariation = archivedAt ? undefined : "negative";
  const dropdown = [];
  if (!archivedAt) {
    dropdown.push({
      content: <TextStyle>{t.propertyEditDropdownLabel()}</TextStyle>,
      onClick: () => editProperty(),
    });

    if (property?.roles.includes(EPropertyMemberTypes.Admin)) {
      dropdown.push({
        content: <TextStyle>{t.propertyInfoEditDropdownLabel()}</TextStyle>,
        onClick: () => {
          setShowPropertyInfoForm(true);
        },
      });
    }

    dropdown.push({
      content: (
        <TextStyle variation={dropdownLabelVariation}>
          {dropdownLabel}
        </TextStyle>
      ),
      onClick: handleClickArchiveProperty,
    });

    dropdown.push({
      content: getLocalizedText("properties.duplicate"),
      onClick: () => {
        navigate(`/properties/${property.id}/duplicate`);
      },
    });

    if (hasMoveServiceForBroker) {
      dropdown.push({
        content: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.cta.title",
        ),
        onClick: handlePressRequestMoveContractForEmptyProperty,
      });
    }
  }

  const hasOwners =
    getMembersWithRole(property.members, EPropertyMemberTypes.Owner).length > 0;
  const secondaryActions = archivedAt
    ? undefined
    : [
        ...(hasOwners
          ? [
              {
                content: (
                  <TextStyle>
                    {t.propertyDetailAddBasicContractInfoAction()}
                  </TextStyle>
                ),
                url: `/properties/${propertyId}/contracts/add`,
              },
            ]
          : []),
        ...(canAddPaymentOrders({
          property,
          isBroker,
        })
          ? [
              {
                content: (
                  <TextStyle>
                    {t.propertyDetailAddPaymentOrderAction()}
                  </TextStyle>
                ),
                url: `/properties/${propertyId}/payments/add`,
              },
            ]
          : []),
        {
          content: <TextStyle>{t.propertyDetailAddTaskAction()}</TextStyle>,
          onClick: handleAddTaskClick,
        },
        {
          content: <TextStyle>{t.documentPackageCreateAction()}</TextStyle>,
          onClick: handleCreateDocumentPackageClick,
        },
      ];

  const handleImageChange = async (file: any) => {
    try {
      if (!propertyId) {
        return;
      }

      const preSignedUrl = await documentApi.uploadDocumentToS3(file);

      await api.post(`/properties/${property.id}/upload-image`, {
        data: {
          preSignedUrl,
          filename:
            file.name ||
            `${property.name.replace(/[^a-z0-9]/gi, "_").toLowerCase()}`,
        },
      });
      refetch();
    } catch (unknownError) {
      const error = unknownError as any;
      console.error(error);

      toast({
        heading: t.propertyInfoEditToastFailedHeading(),
        content: t.propertyInfoEditToastFailedContent(),
        variation: "error",
      });
    }
  };

  const handleEditPropertyInfoSuccess = async (values: any) => {
    update({
      data: values,
    });

    setShowPropertyInfoForm(false);
  };

  return (
    <Page
      title={formatAddress(property, true, true, false)}
      subtitle={subtitle}
      image={utils.properties.getImageForProperty(property)}
      onImageChange={handleImageChange}
      loading={false}
      error={undefined}
      breadcrumbs={{ to: "/properties", content: t.propertyDetailBreadcrumb() }}
      metadata={metadata}
      dropdown={dropdown}
      tabs={tabs}
      secondaryActions={secondaryActions}
      variation={pageVariation}
    >
      {property.archivedAt ? (
        <PropertyArchivedCard property={property} />
      ) : (
        <Outlet />
      )}

      {showPropertyInfoForm && property ? (
        <MultiStepForm
          formId={`property-info-${formId}`}
          schemas={createEditPropertyInfoForm({
            property,
          })}
          withAside={false}
          asModal={true}
          onSuccess={handleEditPropertyInfoSuccess}
          modalProps={{
            shouldCloseOnOverlayClick: true,
            onClose: () => {
              setShowPropertyInfoForm(false);
            },
          }}
          submitLabel={t.system("save")}
          isLoading={false}
        />
      ) : null}
    </Page>
  );
};
