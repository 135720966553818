import { EPaymentOrderType } from "@rentiohq/shared/dist/types/payment.types";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle } from "./schema.createPayment.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header + "7"] = {
                    default: undefined,
                },
                _a[EField.Info] = {
                    default: undefined,
                },
                _a[EField.IncludeCommission] = {
                    default: getValue(EField.IncludeCommission, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        {
                            id: "1",
                            value: getLocalizedText("system.yes"),
                        },
                        {
                            id: "0",
                            value: getLocalizedText("system.no"),
                        },
                    ],
                    // @ts-ignore
                    showIf: function (formData) {
                        return [
                            EPaymentOrderType.RentAndCosts,
                            EPaymentOrderType.Rent,
                        ].includes(formData[EField.TypeRecurring]);
                    },
                },
                _a[EField.IncludeManagementFee] = {
                    default: getValue(EField.IncludeManagementFee, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        {
                            id: "1",
                            value: getLocalizedText("system.yes"),
                        },
                        {
                            id: "0",
                            value: getLocalizedText("system.no"),
                        },
                    ],
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payment.payment_order.costs_selector.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "7"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.costs_selector.heading");
            },
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("payment.payment_order.costs_selector.info_heading"),
            subtitle: getLocalizedText("payment.payment_order.costs_selector.info"),
        },
        _a[EField.IncludeCommission] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("payment.payment_order.costs_selector.option.commission"),
            formatSummary: function (input) {
                return getLocalizedText("system.".concat(input === "1" ? "yes" : "no"));
            },
        },
        _a[EField.IncludeManagementFee] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("payment.payment_order.costs_selector.option.management"),
            formatSummary: function (input) {
                return getLocalizedText("system.".concat(input === "1" ? "yes" : "no"));
            },
        },
        _a);
};
