var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import uniq from "lodash/uniq";
import { ERentioFormField } from "../../components/components/Form";
import { getName } from "../../redux/contact/contact.utils";
import { getValue } from "../../redux/form/form.utils";
import { checkValidMemberRoles } from "../../reduxV2/property/property.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createProperty.types";
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.Roles],
            properties: (_a = {},
                _a[EField.Header + 1] = {
                    default: undefined,
                },
                _a[EField.Roles] = {
                    default: getValue(EField.Roles, initialValues, defaultValues),
                    // @ts-ignore
                    getOptions: function (formData) {
                        if (!formData.owners) {
                            return [];
                        }
                        var accountIds = __spreadArray([], (formData.owners || []), true);
                        if (extraData.brokerContact) {
                            accountIds.push(extraData.brokerContact.accountId);
                        }
                        return uniq(accountIds)
                            .map(function (accountId) { return [
                            {
                                id: "".concat(accountId),
                                shouldShowSwitch: false,
                                getLabel: function (contact) { return getName(contact); },
                                accountId: accountId,
                            },
                            {
                                id: "".concat(accountId, "-").concat(EPropertyMemberTypes.IndexationManager),
                                shouldShowSwitch: true,
                                label: getLocalizedText("role.indexation_manager"),
                                accountId: accountId,
                            },
                            {
                                id: "".concat(accountId, "-").concat(EPropertyMemberTypes.FinancialManager),
                                shouldShowSwitch: true,
                                label: getLocalizedText("role.financial_manager"),
                                accountId: accountId,
                            },
                            {
                                id: "".concat(accountId, "-").concat(EPropertyMemberTypes.TechnicalManager),
                                shouldShowSwitch: true,
                                label: getLocalizedText("role.technical_manager"),
                                accountId: accountId,
                            },
                        ]; })
                            .flat();
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("property.add_roles.title")
        },
        _a[EField.Header + 1] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("property.add_roles.title"),
        },
        _a[EField.Roles] = {
            "ui:field": ERentioFormField.SwitchContactSelect,
        },
        _a;
};
export var getValidate = function (_) { return function (formData, errors) {
    if (!formData[EField.Roles] ||
        !checkValidMemberRoles(formData[EField.Roles])) {
        errors[EField.Roles].addError(getLocalizedText("property.add_roles.required"));
    }
    return errors;
}; };
