import { EField } from "./schema.createProperty.types";
import { ERentioFormField } from "../../components/components/Form";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { Platform } from "react-native";
import { getValue } from "../../redux/form/form.utils";
import { EContactInitialType } from "@rentiohq/shared/dist/types/contact.types";
export var getSchema = function (_, initialValues) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.Owners],
            properties: (_a = {},
                _a[EField.Header + 1] = {
                    default: undefined,
                    //@ts-ignore
                    showIf: function () { return Platform.OS === "web"; },
                },
                _a[EField.Info] = {
                    default: undefined,
                },
                _a[EField.Owners] = {
                    default: getValue(EField.Owners, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, _) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("property.owner.add.label"),
            "rentio:navigationTitle": getLocalizedText(extraData.propertyId ? "properties.edit" : "properties.new")
        },
        _a[EField.Header + 1] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("properties.owners"),
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("property.owners_info.content.heading"),
            subtitle: getLocalizedText("property.owners_info.content"),
        },
        _a[EField.Owners] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("properties.owners"),
            selectContactFromListCtaTitle: getLocalizedText("properties.add.owner"),
            addCtaLabel: getLocalizedText("properties.add.owner"),
            emptySelectCtaLabel: getLocalizedText("property.owners.add_select.label"),
            isMultiSelect: true,
            asOptionList: true,
            initialType: EContactInitialType.Owner,
            // Custom selectors & actions
            customGetSubtitleSelector: function () { return undefined; },
            customExtraAccountsSelector: function () { return []; },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a;
};
