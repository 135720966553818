var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import throttle from "lodash/throttle";
import React from "react";
import { Platform } from "react-native";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import * as S from "./ChatSendBar.styles";
var ChatSendBar = /** @class */ (function (_super) {
    __extends(ChatSendBar, _super);
    function ChatSendBar(props) {
        var _this = _super.call(this, props) || this;
        // Public
        _this.reset = function () {
            _this.setState({
                text: "",
            }, Platform.OS === "web"
                ? function () {
                    var _a, _b;
                    // @ts-ignore
                    (_b = (_a = _this.textInputRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.focus();
                }
                : undefined);
        };
        _this.renderAttachment = function () {
            var _a = _this.props, onPressSelectAttachment = _a.onPressSelectAttachment, onPressRemoveAttachment = _a.onPressRemoveAttachment, attachmentPreviewText = _a.attachmentPreviewText, attachmentPreviewData = _a.attachmentPreviewData;
            if (attachmentPreviewData) {
                return (_jsxs(S.AttachmentWrap, __assign({ onPress: onPressRemoveAttachment }, { children: [_jsx(S.AttachmentImage, { source: attachmentPreviewData }), _jsx(S.AttachmentCloseIcon, {})] })));
            }
            if (attachmentPreviewText) {
                return (_jsxs(S.AttachmentWrap, __assign({ onPress: onPressRemoveAttachment }, { children: [_jsx(S.AttachmentText, { children: attachmentPreviewText }), _jsx(S.AttachmentCloseIcon, {})] })));
            }
            return (_jsx(S.Button, __assign({ onPress: onPressSelectAttachment }, { children: _jsx(S.AttachmentButtonImage, {}) })));
        };
        // Event handlers
        _this.handleChangeText = function (text) {
            _this.setState({ text: text });
            _this.sendTyping();
        };
        _this.handlePressSend = function () {
            var _a = _this.props, attachmentPreviewText = _a.attachmentPreviewText, attachmentPreviewData = _a.attachmentPreviewData;
            var text = _this.state.text;
            var hasAttachment = !!attachmentPreviewText || !!attachmentPreviewData;
            if (text.length > 0 || hasAttachment) {
                _this.props.onPressSend(text);
            }
        };
        // Helpers
        _this.sendTyping = function () {
            var _a = _this.props, sendTyping = _a.sendTyping, chatId = _a.chatId;
            sendTyping({ chatId: chatId });
        };
        _this.state = {
            text: "",
        };
        _this.sendTyping = throttle(_this.sendTyping, 1500);
        _this.textInputRef = React.createRef();
        return _this;
    }
    // Render
    ChatSendBar.prototype.render = function () {
        var _a = this.props, isSending = _a.isSending, attachmentPreviewText = _a.attachmentPreviewText, attachmentPreviewData = _a.attachmentPreviewData, _b = _a.textInputProps, textInputProps = _b === void 0 ? {} : _b;
        var text = this.state.text;
        var hasAttachment = !!attachmentPreviewText || !!attachmentPreviewData;
        return (_jsxs(S.Wrap, { children: [this.renderAttachment(), _jsx(S.TextInputStyled
                // @ts-ignore
                , __assign({ 
                    // @ts-ignore
                    ref: this.textInputRef, placeholder: getLocalizedText("message.text_input.placeholder"), onSubmitEditing: this.handlePressSend, onChangeText: this.handleChangeText, numberOfLines: this.state.text.split("\n").length }, textInputProps, { value: text })), isSending ? (_jsx(S.ActivityIndicatorStyled, {})) : (_jsx(S.Button, __assign({ disabled: text.length === 0 && !hasAttachment, onPress: this.handlePressSend }, { children: _jsx(S.SendButtonImage, {}) })))] }));
    };
    return ChatSendBar;
}(React.PureComponent));
export default ChatSendBar;
