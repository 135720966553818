import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";

export interface IExtraData {
  property: IProperty;
  contract: IContract;
}

export enum EField {
  Info = "_info",
  ContractRentPrice = "contractRentPrice",
  BuildingSurfaceArea = "buildingSurfaceArea",
  // BuildingFloor = "buildingFloor",
  BuildingLayers = "buildingLayers",
  BuildingAdjoined = "buildingAdjoined",
  BuildingHasHardMaterials = "buildingHasHardMaterials",
}
