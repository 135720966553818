import {
  isToday,
  isYesterday,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  formatDate,
  formatTime,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";

export const getFormattedCreatedAt = (date: Date) => {
  const items: string[] = [];

  if (isToday(date)) {
    items.push(`${getLocalizedText("system.today")} - `);
  }
  if (isYesterday(date)) {
    items.push(`${getLocalizedText("system.yesterday")} - `);
  }

  items.push(formatDate(date));

  items.push(formatTime(date));

  return items.join(" ");
};
