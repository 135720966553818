import { EContractPaydate, EContractPaymentRepetitionType, } from "@rentiohq/shared/dist/types/contract.types";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EContractDuration, EField, } from "./schema.createContract.types";
import { getNavigationTitle, getPayDateLabel, } from "./schema.createContract.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.PaymentRepetition, EField.PayDate],
            properties: (_a = {},
                _a[EField.Header + "4b"] = {
                    default: undefined,
                },
                _a[EField.PaymentRepetition] = {
                    default: getValue(EField.PaymentRepetition, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values([
                        EContractPaymentRepetitionType.Monthly,
                        EContractPaymentRepetitionType.Quarterly,
                        EContractPaymentRepetitionType.Yearly,
                    ]).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("contract.payment_repetition.option.".concat(id).toLowerCase()),
                    }); }),
                },
                _a[EField.PayDate] = {
                    default: getValue(EField.PayDate, initialValues, defaultValues),
                    // @ts-ignore
                    options: [EContractPaydate.Calendar, EContractPaydate.Contract].map(function (id) { return ({
                        id: id,
                        value: getPayDateLabel(id),
                    }); }),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("contract_info.create.payment_repetition.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "4b"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.create.payment_repetition.heading"),
        },
        _a[EField.PaymentRepetition] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("contract.payment_repetition.option.label"),
            shouldShowOnSummary: function (formData) {
                return formData[EField.PaymentRepetition] !== EContractDuration.Other;
            },
            formatSummary: function (value) {
                if (value === void 0) { value = EContractPaymentRepetitionType.Monthly; }
                return getLocalizedText("contract.payment_repetition.option.".concat(value).toLowerCase());
            },
        },
        _a[EField.PayDate] = {
            "ui:field": ERentioFormField.InlineSelect,
            formatSummary: function (input) { return input && getPayDateLabel(input); },
            title: getLocalizedText("contract.paydate.label"),
            extraInfoUrl: "contract.paydate.url",
        },
        _a);
};
