var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, Image, View } from "react-native";
import styled from "../../theme/styled-components.native";
export var Wrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var AttachmentImage = styled(Image)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.isSender
        ? props.theme.colors.neutral2
        : props.theme.colors.global5,
}); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
