import * as contractActions from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.actions";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { Modal, TextStyle } from "@rentiohq/web-shared/dist/components";
import RentioInternalRenderer from "@rentiohq/web-shared/dist/components/RentioInternalRenderer";
import { FORCED_DELAY } from "components/IndexContractModal/Index.constants";
import { compact } from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ProgressBar } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ProgressBar";

export interface IIndexContractBulkSkipIndexModalProps {
  contractIds: string[];
  onClose: (documentId?: string) => void;
}

export const IndexContractBulkSkipIndexModal = (
  props: IIndexContractBulkSkipIndexModalProps,
) => {
  const { contractIds, onClose } = props;

  const dispatch = useDispatch();

  const [isPending, setIsPending] = useState(false);
  const [itemsToIndex, setItemsToIndex] = useState<string[]>();
  const [indexedItems, setIndexedItems] = useState<string[]>();
  const [failedItems, setFailedItems] = useState<string[]>();

  // Actions
  const startBulkSkipIndex = async () => {
    if (isPending) {
      return;
    }

    setIsPending(true);

    setItemsToIndex(contractIds);
    setIndexedItems([]);
    setFailedItems([]);

    let itemsToIndexLocal = [...contractIds];
    const indexedItemsLocal = [];
    const failedItemsLocal = [];

    for await (const contractId of contractIds) {
      await new Promise(resolve => setTimeout(resolve, FORCED_DELAY));

      try {
        await new Promise((resolve, reject) => {
          dispatch(
            contractActions.indexSkipStart.getAction({
              id: contractId,
              onSuccess: () => {
                resolve(contractId);
              },
              onFailure: () => {
                reject();
              },
            }),
          );
        });

        indexedItemsLocal.push(contractId);
        setIndexedItems(indexedItemsLocal);
      } catch (error) {
        failedItemsLocal.push(contractId);
        setFailedItems(failedItemsLocal);
      }

      itemsToIndexLocal = itemsToIndexLocal.filter(x => x !== contractId);
      setItemsToIndex(itemsToIndexLocal);
    }

    setIsPending(false);
  };

  React.useEffect(() => {
    startBulkSkipIndex();
  }, []);

  // Event handlers
  const handleCloseWithConfirm = () => {
    if (isPending) {
      confirm({
        title: getLocalizedText("indexation.confirm.progress_cancel.title"),
        info: getLocalizedText("indexation.confirm.progress_cancel.info"),
        type: "warning",
        primaryActions: [
          {
            title: getLocalizedText("system.stop"),
            onPress: () => {
              onClose();
            },
          },
        ],
      });

      return;
    }

    onClose();
  };

  // Render
  const completedItemsCount = (indexedItems || []).length;
  const failedItemsCount = (failedItems || []).length;
  const totalItemsCount =
    (itemsToIndex || []).length + completedItemsCount + failedItemsCount;

  return (
    <Modal
      heading={getLocalizedText("properties.bulk_index_skip_index", {
        value: `${contractIds.length}`,
      })}
      // width="small"
      shouldCloseOnOverlayClick={false}
      hasDismiss={false}
      actions={compact([
        isPending
          ? {
              content: getLocalizedText("system.cancel"),
              onClick: () => {
                handleCloseWithConfirm();
              },
              appearance: "outline",
            }
          : undefined,
        !isPending
          ? {
              content: getLocalizedText("system.close"),
              onClick: () => {
                handleCloseWithConfirm();
              },
              appearance: "primary",
            }
          : undefined,
      ])}
      onClose={() => {
        handleCloseWithConfirm();
      }}
    >
      <ProgressBar
        total={totalItemsCount}
        completed={completedItemsCount}
        pending={failedItemsCount}
      />

      <TextStyle>
        {completedItemsCount + failedItemsCount}/{totalItemsCount}
      </TextStyle>

      {failedItemsCount > 0 && (
        <TextStyle variation="negative" element="div">
          {getLocalizedText(
            `indexation.bulk_action.failed_items_info`,
            {
              value: `${failedItemsCount}`,
            },
            failedItemsCount,
          )}
        </TextStyle>
      )}

      <RentioInternalRenderer
        items={{
          Failed: failedItems ? join(failedItems) : undefined,
          Indexed: indexedItems ? join(indexedItems) : undefined,
          "To do": itemsToIndex ? join(itemsToIndex) : undefined,
        }}
      />
    </Modal>
  );
};
