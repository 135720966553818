import styled from "styled-components";

export const InnerWrap = styled.div`
  min-height: 48px;
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderWrap = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.neutral30};
`;

export const HeaderInnerWrap = styled.div`
  margin-left: 24px;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PropertyListItemItem = styled.div`
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PropertyListItemSpacer = styled.div`
  background-color: ${props => props.theme.colors.global3};
  align-self: stretch;
  width: 1px;
  margin: 0 12px;
`;

export type Alignment = "start" | "space-between" | "center" | "end";
export const ChildrenWrap = styled.div<{
  alignment?: Alignment;
}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: ${({ alignment }) => alignment ?? "space-between"};
  align-items: center;
  min-width: 0;
`;

export const IconWrapper = styled.div<{ inGroup: boolean }>`
  display: flex;
  align-items: center;
  border: ${props =>
    props.inGroup ? `1px solid ${props.theme.getColor("gray", 400)}` : "none"};
  border-radius: 4px;
`;

export const Icon = styled.div`
  margin-left: 0px;
`;
