export var ESocketType;
(function (ESocketType) {
    ESocketType["OnOpen"] = "ON_OPEN";
    ESocketType["OnMessage"] = "ON_MESSAGE";
    ESocketType["OnError"] = "ON_ERROR";
    ESocketType["OnClose"] = "ON_CLOSE";
    ESocketType["OnPoll"] = "ON_POLL";
})(ESocketType || (ESocketType = {}));
export var ISocketReadyState;
(function (ISocketReadyState) {
    ISocketReadyState[ISocketReadyState["Connecting"] = 0] = "Connecting";
    ISocketReadyState[ISocketReadyState["Open"] = 1] = "Open";
    ISocketReadyState[ISocketReadyState["Closing"] = 2] = "Closing";
    ISocketReadyState[ISocketReadyState["Closed"] = 3] = "Closed";
})(ISocketReadyState || (ISocketReadyState = {}));
