import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { IDocumentCategory } from "@rentiohq/shared/dist/types/document.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import {
  ERegistrationDocumentType,
  IRegistration,
} from "@rentiohq/shared/dist/types/registration.types";

export interface IExtraData {
  documentType: ERegistrationDocumentType;
  registrationId: number;
  registration: IRegistration;
  existingDocumentId?: number;
  property: IProperty;
  contract: IContract;

  contactMeMaster?: IContact;
  legalContact?: IContact;
  isBroker?: boolean;
  documentCategories: IDocumentCategory[];
}

export enum EField {
  Owners = "owners",
  SignAsBroker = "signAsBroker",
  Tenants = "tenants",

  DocumentToUpload = "rentContractDocumentToUpload",
  Type = "rentContractType",
  SignedAt = "rentContractSignedAt",
}
