var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { logError } from "@rentiohq/shared/dist/logger/logger";
import orderBy from "lodash/orderBy";
import { conditionIsTrue } from "../EditorPreview/components/Condition/Condition.utils";
import { EVariableType, } from "./Editor.types";
export var variableDataIsFilledIn = function (variableData) {
    if (variableData === undefined) {
        return false;
    }
    if (variableData === null) {
        return false;
    }
    if (typeof variableData === "string" && variableData.length === 0) {
        return false;
    }
    return true;
};
export var getVariablesForGroup = function (params) {
    var groupId = params.groupId, fields = params.fields, variablesData = params.variablesData, _a = params.skipCondition, skipCondition = _a === void 0 ? false : _a, _b = params.instanceIndex, instanceIndex = _b === void 0 ? 0 : _b;
    var result = fields.variables.filter(function (x) {
        var _a;
        // Group doesn't match
        if (x.groupId !== groupId) {
            return false;
        }
        if (skipCondition) {
            return true;
        }
        if (!x.sidebarConditionConfig) {
            return true;
        }
        var fieldsForConditionVariable = getFieldsForVariable({
            fields: fields,
            variableId: x.sidebarConditionConfig.variableId,
        });
        if (!(fieldsForConditionVariable === null || fieldsForConditionVariable === void 0 ? void 0 : fieldsForConditionVariable.group)) {
            return true;
        }
        var conditionOnVariableInOtherGroup = x.groupId !== fieldsForConditionVariable.group.id;
        return conditionIsTrue({
            variableData: (_a = variablesData[x.sidebarConditionConfig.variableId]) === null || _a === void 0 ? void 0 : _a[conditionOnVariableInOtherGroup ? 0 : instanceIndex],
            condition: x.sidebarConditionConfig,
        });
    });
    return orderBy(result, [function (x) { return x.sortWeight || 0; }, "name"], ["desc", "asc"]);
};
export var groupIsLooped = function (params) {
    var groupId = params.groupId, fields = params.fields;
    return !!fields.loops.find(function (x) { return x.groupId === groupId; });
};
export var variableIsLooped = function (params) {
    var variableId = params.variableId, fields = params.fields;
    return !!fields.loops.find(function (x) { return x.variableId === variableId; });
};
export var getFieldsForGroup = function (params) {
    var groupId = params.groupId, fields = params.fields;
    var group = fields.groups.find(function (group) { return group.id === groupId; });
    if (!group) {
        return undefined;
    }
    var loop = fields.loops.find(function (loop) { return loop.groupId === groupId; });
    return {
        group: group,
        loop: loop,
    };
};
export var getFieldsForVariable = function (params) {
    var variableId = params.variableId, fields = params.fields;
    var variable = fields.variables.find(function (variable) { return variable.id === variableId; });
    if (!variable) {
        return undefined;
    }
    var group = fields.groups.find(function (group) { return group.id; });
    if (!group) {
        return undefined;
    }
    var loopVariable = fields.loops.find(function (loop) { return loop.variableId === variableId; });
    var loopGroup = fields.loops.find(function (loop) { return loop.groupId === variable.groupId; });
    return {
        variable: variable,
        group: group,
        loopVariable: loopVariable,
        loopGroup: loopGroup,
    };
};
export var getNewVariablesDataForVariableUpdate = function (params) {
    var _a;
    var instanceIndexGroup = params.instanceIndexGroup, instanceIndexVariable = params.instanceIndexVariable, variablesData = params.variablesData, variableId = params.variableId, value = params.value;
    var fields = getFieldsForVariable({ variableId: variableId, fields: params.fields });
    if (!fields) {
        return variablesData;
    }
    var variable = fields.variable, group = fields.group, loopVariable = fields.loopVariable, loopGroup = fields.loopGroup;
    if (!variable || !group) {
        return variablesData;
    }
    // Get instance index (loop group or loop variable)
    var instanceIndex = 0;
    if (loopGroup) {
        instanceIndex = instanceIndexGroup || 0;
    }
    else if (loopVariable) {
        instanceIndex = instanceIndexVariable || 0;
    }
    // Set new value
    var values = variablesData[variableId] || [];
    while (values.length < instanceIndex) {
        values.push(undefined);
    }
    values[instanceIndex] = value;
    // Remove trailing empty values
    for (var index = values.length - 1; index >= 0; index--) {
        var variableData = values[index];
        if (variableDataIsFilledIn(variableData)) {
            break;
        }
        values.pop();
    }
    return __assign(__assign({}, variablesData), (_a = {}, _a[variableId] = values, _a));
};
export var getNewVariablesDataForVariableRemoved = function (params) {
    var _a;
    var instanceIndexVariable = params.instanceIndexVariable, variablesData = params.variablesData, variableId = params.variableId;
    var values = variablesData[variableId] || [];
    values.splice(instanceIndexVariable, 1);
    return __assign(__assign({}, variablesData), (_a = {}, _a[variableId] = values, _a));
};
export var getNewVariablesDataForGroupRemoved = function (params) {
    var instanceIndexGroup = params.instanceIndexGroup, variablesData = params.variablesData, groupId = params.groupId, fields = params.fields;
    var result = __assign({}, variablesData);
    getVariablesForGroup({ groupId: groupId, fields: fields, variablesData: variablesData }).forEach(function (variable) {
        var values = variablesData[variable.id] || [];
        values.splice(instanceIndexGroup, 1);
        result[variable.id] = values;
    });
    return result;
};
export var groupInstancesToRender = function (params) {
    var groupId = params.groupId, fields = params.fields, variablesData = params.variablesData, withTrailingDummyInstance = params.withTrailingDummyInstance;
    if (!groupIsLooped({ groupId: groupId, fields: fields })) {
        return 1;
    }
    var result = 0;
    var isFilledIn = true;
    var instanceIndex = 0;
    var _loop_1 = function () {
        var variablesForGroupInstance = getVariablesForGroup({
            groupId: groupId,
            fields: fields,
            variablesData: variablesData,
            skipCondition: false,
            instanceIndex: instanceIndex,
        });
        var instanceIndexSafe = instanceIndex;
        isFilledIn = !!variablesForGroupInstance.find(function (variableConfig) {
            var _a;
            if (variableConfig.type === EVariableType.Boolean) {
                return false;
            }
            return variableDataIsFilledIn((_a = variablesData[variableConfig.id]) === null || _a === void 0 ? void 0 : _a[instanceIndexSafe]);
        });
        if (isFilledIn) {
            result += 1;
        }
        instanceIndex += 1;
    };
    while (isFilledIn) {
        _loop_1();
    }
    var hasSignature = !!getVariablesForGroup({
        groupId: groupId,
        fields: fields,
        variablesData: variablesData,
        skipCondition: true,
        instanceIndex: 0,
    }).find(function (x) { return x.type === EVariableType.Signature; });
    if (withTrailingDummyInstance && !hasSignature) {
        result += 1;
    }
    return Math.max(1, result);
};
export var getGroupCompletionInfo = function (params) {
    var groupId = params.groupId, fields = params.fields, variablesData = params.variablesData;
    var fieldsForGroup = getFieldsForGroup({ groupId: groupId, fields: fields });
    if (!fieldsForGroup) {
        return { total: -1, completed: -1 };
    }
    var total = 0;
    var completed = 0;
    if (fieldsForGroup.loop) {
        var instancesToRender = groupInstancesToRender({
            groupId: groupId,
            fields: fields,
            variablesData: variablesData,
            withTrailingDummyInstance: false,
        });
        for (var instanceIndex = 0; instanceIndex < instancesToRender; instanceIndex++) {
            var variables = getVariablesForGroup({
                groupId: groupId,
                fields: fields,
                variablesData: variablesData,
                instanceIndex: instanceIndex,
                skipCondition: false,
            }).filter(function (_a) {
                var type = _a.type, _b = _a.isRequired, isRequired = _b === void 0 ? true : _b;
                return type !== EVariableType.Signature && isRequired;
            });
            for (var _i = 0, variables_1 = variables; _i < variables_1.length; _i++) {
                var variable = variables_1[_i];
                var variableData = (variablesData[variable.id] || [])[instanceIndex];
                var isFilledIn = variable.type === EVariableType.Boolean
                    ? true
                    : variableDataIsFilledIn(variableData);
                total += 1;
                if (isFilledIn) {
                    completed += 1;
                }
            }
        }
    }
    else {
        var variables = getVariablesForGroup({
            groupId: groupId,
            fields: fields,
            variablesData: variablesData,
        }).filter(function (_a) {
            var type = _a.type, _b = _a.isRequired, isRequired = _b === void 0 ? true : _b;
            return type !== EVariableType.Signature && isRequired;
        });
        variables.forEach(function (variable) {
            var variableData = variablesData[variable.id] || [];
            if (variableData.length === 0) {
                variableData.push(undefined);
            }
            // Get count of complete variables
            var variableDataFiltered = variableData;
            if (variable.type !== EVariableType.Boolean) {
                variableDataFiltered = variableData.filter(variableDataIsFilledIn);
            }
            total += Math.max(1, variableData.length);
            completed += variableDataFiltered.length;
        });
    }
    return {
        total: total,
        completed: completed,
    };
};
export var getCompletionInfo = function (params) {
    var fields = params.fields, variablesData = params.variablesData;
    var total = 0;
    var completed = 0;
    if (fields.groups && fields.groups.length > 0) {
        fields.groups.forEach(function (group) {
            var groupCompletionInfo = getGroupCompletionInfo({
                groupId: group.id,
                fields: fields,
                variablesData: variablesData,
            });
            total += groupCompletionInfo.total;
            completed += groupCompletionInfo.completed;
        });
    }
    return { total: total, completed: completed };
};
export var getIsVariablesDataValid = function (params) {
    var completionInfo = getCompletionInfo(params);
    return completionInfo.completed === completionInfo.total;
};
var emptyFields = { groups: [], variables: [], loops: [], conditions: [] };
export var parseFields = function (fields) {
    try {
        if (!fields) {
            return emptyFields;
        }
        var parsedFields = typeof fields === "string" ? JSON.parse(fields) : fields;
        return __assign(__assign({}, emptyFields), parsedFields);
    }
    catch (unknownError) {
        var error = unknownError;
        // TODO: What to do if parsing failed?
        logError({ error: error });
        return emptyFields;
    }
};
var emptyData = {
    blocks: [],
    entityMap: {},
};
export var parseData = function (data) {
    try {
        if (!data) {
            return emptyData;
        }
        var parsedData = typeof data === "string" ? JSON.parse(data) : data;
        return __assign(__assign({}, emptyData), parsedData);
    }
    catch (unknownError) {
        var error = unknownError;
        // TODO: What to do if parsing failed?
        logError({ error: error });
        return emptyData;
    }
};
export var parseVariablesData = function (variablesData) {
    try {
        if (!variablesData) {
            return {};
        }
        var parsedVariablesData = typeof variablesData === "string"
            ? JSON.parse(variablesData)
            : variablesData;
        return __assign({}, parsedVariablesData);
    }
    catch (unknownError) {
        var error = unknownError;
        // TODO: What to do if parsing failed?
        logError({ error: error });
        return {};
    }
};
