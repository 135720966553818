var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { generateReducer } from "../generators/generator.reducer";
import { documentCategoriesFailed, documentCategoriesStart, documentCategoriesSucceeded, } from "./document.actions";
import { GENERATOR_PARAMS, REDUCER_KEY } from "./document.constants";
export var REDUCER_KEY_DOCUMENT = REDUCER_KEY;
var getExtraDefaultState = function (state) { return ({
    categories: state === null || state === void 0 ? void 0 : state.categories,
    isFetchingDocumentCategories: false,
}); };
var _reducer = generateReducer(GENERATOR_PARAMS, getExtraDefaultState);
var reducer = _reducer;
reducer.on(documentCategoriesStart.type, function (state, action) { return (__assign(__assign({}, state), { isFetchingDocumentCategories: true, getDocumentCategoriesError: undefined })); });
reducer.on(documentCategoriesSucceeded.type, function (state, _a) {
    var result = _a.result;
    return (__assign(__assign({}, state), { categories: result.data, isFetchingDocumentCategories: false, getDocumentCategoriesError: undefined }));
});
reducer.on(documentCategoriesFailed.type, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetchingDocumentCategories: false, getDocumentCategoriesError: error }));
});
export default reducer;
