var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DisplayTypeProvider } from "./contexts/DisplayType.context";
import { DocumentConfigProvider } from "./contexts/DocumentConfig.context";
import { DocumentIdsProvider } from "./contexts/DocumentIds.context";
import ExitPromptProvider from "./contexts/ExitPrompt.context";
import FieldsProvider from "./contexts/Fields.context";
import { GroupSidebarProvider } from "./contexts/GroupSidebar.context";
import IndexCountProvider from "./contexts/IndexCount.context";
import ReadOnlyProvider from "./contexts/ReadOnly.context";
import VariablesData from "./contexts/VariablesData.context";
import Editor from "./Editor";
var EditorContainer = function (props) { return (_jsx(DisplayTypeProvider, __assign({ displayType: props.displayType }, { children: _jsx(DocumentIdsProvider, __assign({ documentIds: props.documentIds, templateId: props.templateId, templateDocumentId: props.templateDocumentId }, { children: _jsx(GroupSidebarProvider, { children: _jsx(DocumentConfigProvider, __assign({ documentConfig: props.documentConfig }, { children: _jsx(IndexCountProvider, { children: _jsx(FieldsProvider, __assign({ defaultConfig: props.fields || {
                            groups: [],
                            variables: [],
                            loops: [],
                            conditions: [],
                        } }, { children: _jsx(VariablesData, __assign({ variablesData: props.variablesData || {} }, { children: _jsx(ReadOnlyProvider, { children: _jsx(ExitPromptProvider, { children: _jsx(Editor, __assign({}, props)) }) }) })) })) }) })) }) })) }))); };
export default EditorContainer;
