import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  ERevenuesInvoicedStatus,
  ERevenuesPaymentStatus,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EClientType } from "@rentiohq/shared/dist/types/broker.types";
import { JSONSchema6 } from "json-schema";
import { capitalize } from "lodash";
import { UiSchema } from "react-native-jsonschema-form-test";
import {
  revenuesInvoicedLabel,
  revenuesPaymentStatusLabel,
} from "services/translationService";
import { ts } from "../../services";
import { EField, IExtraData } from "./schema.revenuesExport.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.PaymentStatus, EField.Invoiced],
      properties: {
        [EField.PaymentStatus]: {
          default: getValue(EField.PaymentStatus, initialValues, defaultValues),
          // @ts-ignore
          options: Object.values(ERevenuesPaymentStatus).map(status => ({
            id: status,
            value: revenuesPaymentStatusLabel({
              extra: { isBroker: !!extraData.broker, key: status },
            }),
          })),
        },
        [EField.Invoiced]: {
          default: getValue(EField.Invoiced, initialValues, defaultValues),
          // @ts-ignore
          options: Object.values(ERevenuesInvoicedStatus).map(status => ({
            id: status,
            value: revenuesInvoicedLabel({
              extra: { isBroker: !!extraData.broker, key: status },
            }),
          })),
        },
        [EField.From]: {
          default: getValue(EField.From, initialValues, defaultValues),
        },
        [EField.To]: {
          default: getValue(EField.To, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): UiSchema => ({
  "rentio:navigationTitle": getLocalizedText(
    `revenues${
      extraData.broker?.type === EClientType.Broker ? "_broker" : ""
    }.form.export.title`,
  ),
  [EField.PaymentStatus]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: ts.revenuesFilterLabel({
      extra: { isBroker: !!extraData.broker, key: "payment_status" },
    }),
    isMultiSelect: true,
    asOptionList: true,
  },
  [EField.Invoiced]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: ts.revenuesFilterLabel({
      extra: { isBroker: !!extraData.broker, key: "invoiced" },
    }),
    isMultiSelect: true,
    asOptionList: true,
  },
  [EField.From]: {
    "ui:field": ERentioFormField.Date,
    title: capitalize(getLocalizedText("system.from")),
  },
  [EField.To]: {
    "ui:field": ERentioFormField.Date,
    title: capitalize(getLocalizedText("system.to")),
  },
});
