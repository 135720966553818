var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Truncate from "react-truncate";
import { Tooltip } from "../Tooltip";
var TruncateTooltip = function (props) {
    var width = props.width, fontSize = props.fontSize, children = props.children;
    if (!children) {
        return null;
    }
    return (_jsx(Tooltip, __assign({ tooltipContent: children }, { children: _jsx(Truncate, __assign({ width: width, trimWhitespace: true, style: { width: width, fontSize: fontSize } }, { children: children })) })));
};
export default TruncateTooltip;
