import { getMonthsPerView } from "./PropertyListItemFinancial.utils";
import {
  addMonths,
  endOfMonth,
} from "@rentiohq/shared/dist/utils/date-fns.utils";

export const WIDTH_PAYERS = 140;
export const WIDTH_PAYERS_TOTAL = 110;
export const WIDTH_NEXT_PREV = 40;

export const PREFERENCE_KEY_PROPERTIES_FINANCIAL_TAB_DATE =
  "PREFERENCE_KEY_PROPERTIES_FINANCIAL_TAB_DATE";

const NOW = new Date();
export const START_DATE = endOfMonth(addMonths(NOW, 1));
export const MONTHS_PER_VIEW = getMonthsPerView();
