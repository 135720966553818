import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";

export interface IExtraData {
  property: IProperty;
  contract: IContract;
}

export enum EField {
  Info = "_info",
  StartDate = "startDate",
  CurrentEndDate = "currentEndDate",
  Duration = "duration",
  NewEndDate = "newEndDate",
  TacitRenewal = "tacitRenewal",
  NonDefaultRenewalWarning = "nonDefaultRenewalWarning",
  MoreInfo = "moreInfo",
}

export enum ERenewContractDuration {
  AddOneYear = "add_one_year",
  AddTwoYears = "add_two_years",
  AddThreeYears = "add_three_years",
  ConvertToNineYears = "convert_to_nine_years",
  Other = "other",
}
