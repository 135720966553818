import * as actions from "./contract.actions";
import * as api from "./contract.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./contract.constants";
import * as hooks from "./contract.hooks";
import * as promises from "./contract.promises";
import reducer from "./contract.reducer";
import { saga } from "./contract.saga";
import * as selectors from "./contract.selectors";
import * as utils from "./contract.utils";
export var REDUCER_KEY_CONTRACT = REDUCER_KEY;
export var contractModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY_CONTRACT,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    // @ts-ignore
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: utils,
};
