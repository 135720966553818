import { Box } from "@rebass/grid";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { EPropertyForRentStatus } from "@rentiohq/shared/dist/types/property.types";
import {
  Icon,
  Modal,
  SegmentedControl,
  TAssetSource,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import * as S from "./ForRentSwitch.styles";
interface IForRentSwitchProps {
  propertyId: number;
  forRentStatus?: EPropertyForRentStatus;
}

export const ForRentSwitch = (props: IForRentSwitchProps) => {
  const { propertyId } = props;
  const forRentStatus = props.forRentStatus || EPropertyForRentStatus.None;

  const { update } = propertyHooks.useUpdate({
    id: propertyId,
  });

  const [showModal, onShowModal] = React.useState(false);

  return (
    <>
      <S.ForRentSwitch
        forRentStatus={forRentStatus}
        onClick={() => {
          onShowModal(true);
        }}
      >
        <TextStyle size="extraSmall" variation="strong">
          {getLocalizedText(
            `property.for_rent_status.${stringToSnakeCase(forRentStatus)}`,
          ).toUpperCase()}
        </TextStyle>
      </S.ForRentSwitch>
      {showModal && (
        <Modal
          width="medium"
          heading={getLocalizedText("property.modal.change_for_rent_status")}
          onClose={() => {
            onShowModal(false);
          }}
        >
          <SegmentedControl
            name="_"
            selected={forRentStatus}
            onChange={(newValue: EPropertyForRentStatus) => {
              update({
                data: { forRentStatus: newValue },
              });
            }}
            options={Object.values(EPropertyForRentStatus).map(status => ({
              id: status,
              value: getLocalizedText(
                `property.for_rent_status.${stringToSnakeCase(status)}`,
              ),
              renderLeadingIcon: () => {
                const iconSource = ((): TAssetSource | undefined => {
                  switch (status) {
                    case EPropertyForRentStatus.ForRent:
                      return "forRentSign";

                    case EPropertyForRentStatus.InOption:
                      return "inOption";

                    case EPropertyForRentStatus.None:
                    default:
                      return;
                  }
                })();

                if (!iconSource) {
                  return undefined;
                }

                return (
                  <Box mr={2}>
                    <Icon source={iconSource} />
                  </Box>
                );
              },
            }))}
          />
        </Modal>
      )}
    </>
  );
};
