import {
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import React from "react";
import { ts } from "../../../../../services";
import { documentPackageUtils } from "../../../../../utils";
import { IDocumentPackageTabProps } from "../DocumentPackage.types";

export const DocumentPackageHistory: React.FC<IDocumentPackageTabProps> = ({
  documentPackage,
}) => {
  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`document-package-${documentPackage.id}`}
        filter={documentPackageUtils.getDocumentPackageActivityFilter(
          documentPackage,
        )}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};
