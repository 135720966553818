import { IPaymentRequest } from "@rentiohq/shared/dist/types/payment.types";
import {
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import React from "react";
import { ts } from "../../../../../../services";
import { getPaymentRequestActivityFilter } from "../../../../../../utils/payment";

interface IProps {
  paymentRequest: IPaymentRequest;
  setTab?: (tab: any) => void;
}

export const PaymentRequestHistory: React.FC<IProps> = ({ paymentRequest }) => {
  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`payment-request-${paymentRequest.id}`}
        filter={getPaymentRequestActivityFilter(paymentRequest)}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};
