import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.propertyInviteApplication.1";

export default () => {
  return [
    {
      schema: getSchema1(),
      uiSchema: uiSchema1(),
    },
  ];
};
