import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EMoveServiceStatus,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import React from "react";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  contract: IContract;
  hasFeature: boolean;
}

const StatusDotMove: React.FC<IProps> = props => {
  const { contract, hasFeature } = props;

  // Helpers
  const getStatus = () => {
    if (contract.manuallyCompletedMove) {
      return EStatus.Success;
    }

    if (!hasFeature) {
      return EStatus.Error;
    }

    switch (contract.moveServiceStatus || EMoveServiceStatus.None) {
      default:
      case EMoveServiceStatus.None:
        return EStatus.Error;

      case EMoveServiceStatus.Sent:
      // return EStatus.Warning;

      case EMoveServiceStatus.PendingByBroker:
      // return EStatus.Warning;

      case EMoveServiceStatus.Pending:
      case EMoveServiceStatus.Completed:
        return EStatus.Success;
    }
  };

  // Render
  const renderTooltipContent = () => {
    if (contract.manuallyCompletedMove) {
      return getLocalizedText(contract.manuallyCompletedMove);
    }

    if (!hasFeature) {
      return getLocalizedText("system.unchecked");
    }

    if (!contract) {
      return;
    }

    const { moveServiceStatus = EMoveServiceStatus.None } = contract;
    return getLocalizedText(
      `move_service.status.${moveServiceStatus}`.toLowerCase(),
    );
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

export default StatusDotMove;
