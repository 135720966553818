var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
var IndexCountRenderer = function (props) {
    // const { entityKey, contentState } = props;
    // const entity = contentState.getEntity(entityKey);
    // const data = entity.getData() as IIndexData;
    // const indexId = data.id;
    // Render
    return (_jsx("span", __assign({ contentEditable: false, 
        // @ts-ignore
        readOnly: true }, { children: _jsx("button", __assign({ 
            // TODO: Modal
            // onClick={handleShowModal}
            // onMouseEnter={togglePopover}
            // onMouseLeave={togglePopover}
            style: {
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "powderblue",
            } }, { children: getLocalizedText("editor.insert_custom.insert_index_count") })) })));
};
export default IndexCountRenderer;
