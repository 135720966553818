export var hasEmailContent = function (activity) {
    return activity.emailActivity;
};
export var hasSmsContent = function (activity) {
    return activity.smsContent || activity.smsNumber;
};
export var hasPhoneContent = function (activity) {
    return activity.phone ||
        activity.phoneHasAnswered ||
        activity.phoneSummary ||
        activity.phonePaymentPromisedAt;
};
export var hasChatContent = function (activity) {
    return activity.chatMessageId || activity.message;
};
export var hasContent = function (activity) {
    return [
        hasEmailContent(activity),
        hasSmsContent(activity),
        hasPhoneContent(activity),
        hasChatContent(activity),
    ].some(Boolean);
};
