import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { getTitle } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import { IChat } from "@rentiohq/shared/dist/types/chat.types";
import { compareAsc } from "@rentiohq/shared/dist/utils/date-fns.utils";
import {
  DisplayText,
  Grid,
  Icon,
  ResourceList,
  ResourceListItem,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { NewMessageIndicator } from "../../scenes/Chats/Chats.styled";

const sortByLatestMessageDate = (a: IChat, b: IChat) => {
  const messageA: any = a.lastMessage || {};
  const messageB: any = b.lastMessage || {};

  const sendAtA = messageA.sendAt || new Date();
  const sendAtB = messageB.sendAt || new Date();

  return compareAsc(sendAtB, sendAtA);
};

interface IProps {
  chats: IChat[];
}

export const ChatOverview: React.FC<IProps> = ({ chats }) => {
  const renderLastMessageLine = (message: any) => {
    const meta = message.account
      ? [
          utils.date.diffForHumans(message.sendAt, { todayAsTime: true }),
          getName(message.account),
        ].join(": ")
      : false;

    return [meta, message.text].filter(Boolean).join(" · ");
  };

  const renderChatItem = (chat: IChat) => {
    const { members, lastMessage, unreadCounter } = chat;

    const title = chat && getTitle({ chat });

    const isGroupChat = members.length > 2;
    const hasNewMessage = unreadCounter > 0;

    return (
      <ResourceListItem
        media={
          <Icon
            source={isGroupChat ? "multipleUsersCircle" : "profile"}
            color={hasNewMessage ? "primary" : undefined}
          />
        }
        link={`/chats/${chat.id}`}
        item={chat}
      >
        <Grid alignItems="center" justifyContent="space-between">
          <Grid.Item>
            <DisplayText size="small" space="extraTight">
              {title}
            </DisplayText>
            {lastMessage && (
              <TextStyle variation={hasNewMessage ? "strong" : "subdued"}>
                {renderLastMessageLine(lastMessage)}
              </TextStyle>
            )}
          </Grid.Item>
          <Grid.Item>
            <Stack spacing="tight" alignment="center">
              {lastMessage && (
                <Stack.Item>
                  <TextStyle variation={hasNewMessage ? "positive" : undefined}>
                    {utils.date.diffForHumans(lastMessage.sendAt, {
                      todayAsTime: true,
                    })}
                  </TextStyle>
                </Stack.Item>
              )}
              {hasNewMessage && (
                <Stack.Item>
                  <NewMessageIndicator />
                </Stack.Item>
              )}
            </Stack>
          </Grid.Item>
        </Grid>
      </ResourceListItem>
    );
  };
  return (
    <ResourceList
      items={chats.sort(sortByLatestMessageDate)}
      renderItem={renderChatItem}
    />
  );
};
