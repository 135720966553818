var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing } from "@rentiohq/web-theme";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", " ", ";\n  max-width: 90%;\n  background-color: ", ";\n"], ["\n  padding: ", " ", ";\n  max-width: 90%;\n  background-color: ", ";\n"])), spacing("loose"), spacing("base"), function (_a) {
    var theme = _a.theme;
    return theme.colors.gray[0];
});
export var MessageWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: ", " 0;\n  white-space: pre-wrap;\n"], ["\n  padding: ", " 0;\n  white-space: pre-wrap;\n"])), spacing("tight"));
export var ContentTitle = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: ", " 0;\n  font-weight: bold;\n"], ["\n  margin: ", " 0;\n  font-weight: bold;\n"])), spacing("tight"));
export var ValueCell = styled.td(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-right: ", ";\n  font-weight: bold;\n  vertical-align: top;\n"], ["\n  padding-right: ", ";\n  font-weight: bold;\n  vertical-align: top;\n"])), spacing("base"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
