import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { UiSchema } from "react-native-jsonschema-form-test";
import {
  EField,
  ELocationDescriptionType,
  EPropertySituation,
  IExtraData,
} from "./schema.orderLocationDescription.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.PropertySituation],
      properties: {
        [EField.LocationDescriptionType]: {
          default: getValue(
            EField.LocationDescriptionType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: Object.values(ELocationDescriptionType).map(id => ({
            id,
            value: utils.i18n.t(
              `location_description.form.location_description_type.option.${id.toLowerCase()}.label`,
            ),
          })),
        },
        [EField.PropertySituation]: {
          default: getValue(
            EField.PropertySituation,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            EPropertySituation.Inhabited,
            EPropertySituation.Uninhabited,
          ].map(id => ({
            id,
            value: utils.i18n.t(
              `location_description.form.property_situation.option.${id.toLowerCase()}.label`,
            ),
          })),
        },
        [EField.PaymentDetail]: {
          default: getValue(EField.PaymentDetail, initialValues, defaultValues),
          // @ts-ignore
          options: [
            EPropertyMemberTypes.Owner,
            EPropertyMemberTypes.Broker,
          ].map(id => ({
            id,
            value: utils.i18n.t(
              `location_description.form.payment_detail.option.${id.toLowerCase()}.label`,
            ),
          })),
          // @ts-ignore
          showIf: () =>
            extraData.property.roles.includes(EPropertyMemberTypes.Broker),
          // @ts-ignore
          requiredIf: () =>
            extraData.property.roles.includes(EPropertyMemberTypes.Broker),
        },
        [EField.NotesForPartner]: {
          default: getValue(
            EField.NotesForPartner,
            initialValues,
            defaultValues,
          ),
        },
        [EField.Document]: {
          default: getValue(EField.Document, initialValues, defaultValues),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.LocationDescriptionType] ===
            ELocationDescriptionType.Out,
          // @ts-ignore
          requiredIf: (formData: any) =>
            formData[EField.LocationDescriptionType] ===
            ELocationDescriptionType.Out,
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): UiSchema => ({
  [EField.LocationDescriptionType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: utils.i18n.t(
      "location_description.form.location_description_type.label",
    ),
    formatSummary: (id: any) =>
      utils.i18n.t(
        `location_description.form.location_description_type.option.${id.toLowerCase()}.label`,
      ),
    hideOnSummaryIfEmpty: true,
  },
  [EField.PropertySituation]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: utils.i18n.t("location_description.form.property_situation.label"),
    formatSummary: (id: any) =>
      utils.i18n.t(
        `location_description.form.property_situation.option.${id.toLowerCase()}.label`,
      ),
    hideOnSummaryIfEmpty: true,
  },
  [EField.PaymentDetail]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: utils.i18n.t("location_description.form.payment_detail.label"),
    formatSummary: (id: any) =>
      utils.i18n.t(
        `location_description.form.payment_detail.option.${id.toLowerCase()}.label`,
      ),
    hideOnSummaryIfEmpty: true,
  },
  [EField.NotesForPartner]: {
    "ui:field": ERentioFormField.Text,
    title: utils.i18n.t("location_description.form.notes_for_partner.label"),
    // getInfo: () => utils.i18n.t('location_description.form.notes_for_partner.description'),
    multiline: true,
  },
  [EField.Document]: {
    "ui:field": ERentioFormField.DocumentSelectInline,
    formatSummary: (formData?: any[]) =>
      formData?.map(doc => doc.name).join(", "),
    accept: ["application/pdf"],
    multiple: false,
    title: utils.i18n.t("location_description.form.document.label"),
    info: utils.i18n.t("location_description.form.document.info"),
  },
});
