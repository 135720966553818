var _a;
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateHooks } from "../generators/generator.hooks";
import { documentCategoriesStart } from "./document.actions";
import { GENERATOR_PARAMS } from "./document.constants";
import { documentCategoriesError, getDocumentCategories, isFetchingDocumentCategories, } from "./document.selectors";
export var useDetail = (_a = generateHooks(GENERATOR_PARAMS), _a.useDetail), usePaged = _a.usePaged, useUpdate = _a.useUpdate, useCount = _a.useCount, useInfiniteLoad = _a.useInfiniteLoad, useGetAll = _a.useGetAll, useRemove = _a.useRemove, useArchive = _a.useArchive, useUnarchive = _a.useUnarchive;
export var useDocumentCategories = function (params) {
    var _a = params.shouldRefetch, shouldRefetch = _a === void 0 ? false : _a;
    var dispatch = useDispatch();
    var categories = useSelector(function (state) { return getDocumentCategories(state); });
    var isFetching = useSelector(function (state) {
        return isFetchingDocumentCategories(state);
    });
    var fetchError = useSelector(function (state) {
        return documentCategoriesError(state);
    });
    // Data
    var startFetching = function () {
        if (isFetching) {
            return;
        }
        if (!shouldRefetch && !!categories) {
            return;
        }
        dispatch(documentCategoriesStart.getAction({}));
    };
    var refetch = function () {
        if (isFetching) {
            return;
        }
        dispatch(documentCategoriesStart.getAction({}));
    };
    // Lifecycle
    useEffect(startFetching, []);
    return {
        categories: categories,
        isFetching: isFetching,
        fetchError: fetchError,
        refetch: refetch,
    };
};
