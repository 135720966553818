import { canHaveRentioLegalForProperty } from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import StatusDot from "../StatusDot/StatusDot";
import { EStatus } from "../StatusDot/StatusDot.types";

export interface IStatusDotLegalProps {
  property: IProperty;
  contract: IContract;
  hasLegalFeature: boolean;
}

export const StatusDotLegal = (props: IStatusDotLegalProps) => {
  const { property, contract, hasLegalFeature } = props;

  if (!hasLegalFeature) {
    return (
      <StatusDot
        tooltipContent={getLocalizedText(
          "rentio_legal.overview.activation_state.error",
        )}
        status={EStatus.Error}
      />
    );
  }

  if (!canHaveRentioLegalForProperty({ property })) {
    return <StatusDot status={EStatus.None} />;
  }

  if (contract.legalLicenseActivatedAt) {
    return (
      <StatusDot
        tooltipContent={getLocalizedText(
          "rentio_legal.overview.activation_state.warning",
        )}
        status={EStatus.Warning}
      />
    );
  }

  return (
    <StatusDot
      tooltipContent={getLocalizedText(
        "rentio_legal.overview.activation_state.success",
      )}
      status={EStatus.Success}
    />
  );
};
