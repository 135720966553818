import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EMoveServiceManager,
  EMoveServiceService,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EElectricityMeterType,
  EElectricityType,
  ENaturalGasAvailable,
  ENaturalGasMeterType,
} from "@rentiohq/shared/dist/types/property.types";
import { EAN_LENGTH, EAN_PREFIX } from "./schema.requestMoveService.config";
import { EField, IExtraData } from "./schema.requestMoveService.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { property } = extraData;

  const {
    electricityType,
    electricityEan,
    electricityMeterNumberBoth,
    electricityMeterType,
    naturalGasAvailable,
    naturalGasEan,
    naturalGasMeterNumber,
    naturalGasMeterType,
  } = property;

  const result: { [key: string]: any } = {
    [EField.Services]: [
      EMoveServiceService.Energy,
      EMoveServiceService.Telco,
      EMoveServiceService.Water,
    ],
    [EField.Manager]: Object.values(EMoveServiceManager)[0],
  };

  result[EField.ElectricityType] = electricityType;
  result[EField.ElectricityEan] = electricityEan?.startsWith(EAN_PREFIX)
    ? electricityEan.replace(EAN_PREFIX, "")
    : electricityEan;

  if (electricityType === EElectricityType.Single) {
    result[EField.ElectricityMeterNumberBoth] = electricityMeterNumberBoth;

    result[EField.ElectricityMeterType] = electricityMeterType;
  } else if (electricityType === EElectricityType.Double) {
    result[EField.ElectricityMeterNumberBoth] = electricityMeterNumberBoth;

    result[EField.ElectricityMeterType] = electricityMeterType;
  } else {
    result[EField.ElectricityType] = EElectricityType.Single;
    result[EField.ElectricityMeterType] = EElectricityMeterType.Standard;
  }

  const hasNaturalGas = naturalGasAvailable === ENaturalGasAvailable.Available;
  if (hasNaturalGas) {
    result[EField.NaturalGasAvailable] = naturalGasAvailable;

    if (naturalGasAvailable === ENaturalGasAvailable.Available) {
      result[EField.NaturalGasEan] = naturalGasEan?.startsWith(EAN_PREFIX)
        ? naturalGasEan.replace(EAN_PREFIX, "")
        : naturalGasEan;
      result[EField.NaturalGasMeterNumber] = naturalGasMeterNumber;
      result[EField.NaturalGasMeterType] = naturalGasMeterType;
    }
  } else {
    result[EField.NaturalGasAvailable] = ENaturalGasAvailable.Available;
    result[EField.NaturalGasMeterType] = ENaturalGasMeterType.Standard;
  }

  return result;
};

export const validationErrorsForEan = (ean = "") => {
  const result: string[] = [];

  if (ean === null || ean === "" || ean === EAN_PREFIX) {
    return result;
  }

  const lengthWithoutPrefix = EAN_LENGTH - EAN_PREFIX.length;

  if (ean.length > lengthWithoutPrefix) {
    result.push(
      getLocalizedText("system.form.error.text.exact_length", {
        value: `${EAN_LENGTH}`,
      }),
    );
  }

  if (ean.length < lengthWithoutPrefix) {
    result.push(
      getLocalizedText("system.form.error.text.exact_length", {
        value: `${EAN_LENGTH}`,
      }),
    );
  }

  return result;
};
