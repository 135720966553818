var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import * as S from "./Washout.styled";
var preventClick = function (e) {
    var _a, _b, _c;
    (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
    (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
    (_c = e.stopImmediatePropagation) === null || _c === void 0 ? void 0 : _c.call(e);
    return false;
};
var Washout = function (_a) {
    var _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, children = _a.children, _c = _a.variation, variation = _c === void 0 ? "light" : _c;
    var ref = React.createRef();
    React.useEffect(function () {
        var _a, _b;
        if (!isDisabled) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("click", preventClick, false);
            return;
        }
        (_b = ref.current) === null || _b === void 0 ? void 0 : _b.addEventListener("click", preventClick, false);
        return function () {
            var _a;
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("click", preventClick, false);
        };
    }, [ref]);
    return (_jsx(S.Washout, __assign({ isDisabled: isDisabled, variation: variation, ref: ref }, { children: children })));
};
export default Washout;
