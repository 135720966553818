export const PREFERENCE_NOT_ELIGIBLE_ENABLED =
  "tenant_profile:notification:not_eligible:enabled";
export const PREFERENCE_NOT_ELIGIBLE_NL = "notEligible_nl_BE";
export const PREFERENCE_NOT_ELIGIBLE_FR = "notEligible_fr_FR";
export const PREFERENCE_NOT_ELIGIBLE_EN = "notEligible_en_UK";

export const PREFERENCE_VISITOR_ENABLED =
  "tenant_profile:notification:visitor:enabled";
export const PREFERENCE_VISITOR_NL =
  "tenant_profile:notification:visitor:message:nl";
export const PREFERENCE_VISITOR_FR =
  "tenant_profile:notification:visitor:message:fr";
export const PREFERENCE_VISITOR_EN =
  "tenant_profile:notification:visitor:message:en";

export const PREFERENCE_PLAN_FOR_VISIT_ENABLED =
  "tenant_profile:notification:plan_for_visit:enabled";
export const PREFERENCE_PLAN_FOR_VISIT_NL =
  "tenant_profile:notification:plan_for_visit:message:nl";
export const PREFERENCE_PLAN_FOR_VISIT_FR =
  "tenant_profile:notification:plan_for_visit:message:fr";
export const PREFERENCE_PLAN_FOR_VISIT_EN =
  "tenant_profile:notification:plan_for_visit:message:en";

export const PREFERENCE_WAITING_LIST_ENABLED =
  "tenant_profile:notification:waiting_list:enabled";
export const PREFERENCE_WAITING_LIST_NL =
  "tenant_profile:notification:waiting_list:message:nl";
export const PREFERENCE_WAITING_LIST_FR =
  "tenant_profile:notification:waiting_list:message:fr";
export const PREFERENCE_WAITING_LIST_EN =
  "tenant_profile:notification:waiting_list:message:en";
