import {
  default as ButtonPrimary,
  EButtonSize,
} from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import FormInputBase from "@rentiohq/shared-frontend/dist/components/components/FormInputBase";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  SegmentedControl,
  TextField,
} from "@rentiohq/web-shared/dist/components";
import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";

interface IProps {
  enabledKey: string;
  enabledByDefault: boolean;
  preferencesConfig: {
    label: string;
    key: string;
    defaultValue: string;
  }[];
}

const TenantProfileNotificationPreferenceGroup: React.FC<IProps> = props => {
  const { enabledKey, preferencesConfig, enabledByDefault = true } = props;

  // Redux
  const dispatch = useDispatch();

  const isEnabled = useSelector((state: IRootStore) =>
    systemSelectors.getPreference(state, enabledKey, enabledByDefault),
  );

  const preferences = useSelector((state: IRootStore) =>
    systemSelectors.getPreferences(
      state,
      preferencesConfig.map(preferenceConfig => ({
        key: preferenceConfig.key,
        defaultValue: preferenceConfig.defaultValue,
      })),
    ),
  );

  // State
  const [cachedPreferences, setCachedPreferences] = React.useState<
    typeof preferences
  >([]);

  // Lifecycle
  React.useEffect(() => {
    setCachedPreferences(preferences);
  }, [preferencesConfig]);

  // Event handlers
  const handleSwitchEnabled = (newValue: boolean) => {
    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.RemoteBroker,
        preferences: {
          [enabledKey]: !newValue,
        },
      }),
    );

    showAlert({
      type: "success",
      message: getLocalizedText("preference.updated.alert.title"),
    });
  };

  const handlePressSave = () => {
    const preferencesToSet: { [key: string]: string | undefined } = {};

    cachedPreferences.forEach(cachedPreference => {
      preferencesToSet[cachedPreference.key] = cachedPreference.value;
    });

    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.RemoteBroker,
        preferences: preferencesToSet,
      }),
    );

    showAlert({
      type: "success",
      message: getLocalizedText("preference.updated.alert.title"),
    });
  };

  // Render
  return (
    <>
      <FormInputBase
        title={getLocalizedText("tenant_profile.notification.enabled.title")}
        onPressExtraInfo={() => {}}
        showBorder={false}
      >
        <div style={{ maxWidth: "600px" }}>
          <Spacer weight={ESpacerWeight.W08} />

          <SegmentedControl
            name={""}
            options={[
              {
                id: 1,
                value: getLocalizedText("system.enabled"),
              },
              {
                id: 0,
                value: getLocalizedText("system.disabled"),
              },
            ]}
            selected={isEnabled ? 1 : 0}
            onChange={(value: number) => {
              handleSwitchEnabled(!value);
            }}
          />
        </div>
      </FormInputBase>

      {isEnabled && (
        <>
          <Spacer weight={ESpacerWeight.W16} />

          {cachedPreferences.map((preference, index) => {
            const preferenceConfig = props.preferencesConfig.find(
              randomPreferenceConfig =>
                randomPreferenceConfig.key === preference.key,
            );
            if (!preferenceConfig) {
              return null;
            }

            return (
              <>
                {index > 0 && <Spacer weight={ESpacerWeight.W32} />}

                <TextField
                  name={preferenceConfig.label}
                  label={preferenceConfig.label}
                  value={`${preference.value || ""}`}
                  type={"textarea"}
                  multiline
                  onChange={(e: any) => {
                    const { value } = e.target;

                    setCachedPreferences(
                      cachedPreferences.map(cachedPreference => {
                        if (cachedPreference.key !== preference.key) {
                          return cachedPreference;
                        }

                        return {
                          ...cachedPreference,
                          value,
                        };
                      }),
                    );
                  }}
                />
              </>
            );
          })}

          <Spacer weight={ESpacerWeight.W24} />

          <ButtonPrimary
            title={getLocalizedText("system.save")}
            onPress={handlePressSave}
            buttonSize={EButtonSize.Medium}
          />
        </>
      )}
    </>
  );
};

export default TenantProfileNotificationPreferenceGroup;
