import { connect } from "react-redux";
import PaymentRequestFetch from "./PaymentRequestFetch";
import * as paymentActions from "../../../redux/payment/payment.actions";
import * as paymentSelectors from "../../../redux/payment/payment.selectors";
var mapStateToProps = function (state, _a) {
    var id = _a.id;
    return ({
        paymentRequest: paymentSelectors.getPaymentRequest(state, id),
        isFetchingPaymentRequest: paymentSelectors.getIsFetchingPaymentRequest(state, id),
        fetchErrorPaymentRequest: paymentSelectors.getFetchErrorPaymentRequest(state, id),
    });
};
var mapDispatchToProps = {
    getPaymentRequest: paymentActions.getPaymentRequest.actions.start,
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentRequestFetch);
