import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EEnvironment } from "@rentiohq/shared/dist/config/app.config.types";
import { NavigateFunction } from "react-router-dom";
import parse from "url-parse";

const showUnsupportedDeeplinkAlert = () => {
  showAlert({
    type: "info",
    message: getLocalizedText("card.alert.action_not_supported.dashboard"),
  });
};

export const openDeeplink = (params: {
  url: string;
  navigate: NavigateFunction;
  setQueryParamValue: (params: { [key: string]: any }) => void;
}) => {
  const { url, navigate, setQueryParamValue } = params;

  const parsedUrl = parse(url, true);
  const { pathname, hostname, query = {} } = parsedUrl;
  const pathComponents = (
    pathname.startsWith("/") ? pathname.substr(1) : pathname
  ).split("/");
  const scope = pathComponents[0];

  if (!hostname.includes("rentio") && pathComponents.length === 0) {
    return;
  }

  const id = query.id;
  switch (scope) {
    case "task":
      if (id) {
        navigate(`/tasks/${id}`);
      }
      break;

    case "index_contract":
      navigate(
        `/follow-up/indexable-contracts?stage=indexable&contractId=${query.contractId}`,
      );
      break;

    case "document":
      if (id) {
        setQueryParamValue({
          documentId: id,
        });
        break;
      }

      showUnsupportedDeeplinkAlert();
      break;

    case "payment":
      if (id) {
        let paymentUrl = `https://pay.rentio.be/payment/${id}`;
        if (CONFIG.ENVIRONMENT !== EEnvironment.Prod) {
          paymentUrl = `https://pay.${CONFIG.ENVIRONMENT}.rentio.be/payment/${id}`;
        }
        window.open(paymentUrl, "_blank");
        break;
      }

      showUnsupportedDeeplinkAlert();
      break;

    // TODO: KYC
    default:
      showUnsupportedDeeplinkAlert();
      break;
  }
};
