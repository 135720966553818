var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { EContactType } from "@rentiohq/shared/dist/types/contact.types";
import { ts } from "../../services";
import { Tooltip } from "../Tooltip";
import * as S from "./ContactConnectionIndicator.styled";
export var ContactConnectionIndicator = function (props) {
    var contact = props.contact, _a = props.showTooltip, showTooltip = _a === void 0 ? true : _a, _b = props.forceDisplay, forceDisplay = _b === void 0 ? false : _b;
    if (!(contact === null || contact === void 0 ? void 0 : contact.hasOwnProperty("inviteAcceptedAt")) && !forceDisplay) {
        return null;
    }
    var hasAccepted = false;
    if (contact === null || contact === void 0 ? void 0 : contact.inviteAcceptedAt) {
        hasAccepted = true;
    }
    if ((contact === null || contact === void 0 ? void 0 : contact.type) === EContactType.Personal) {
        hasAccepted = true;
    }
    var variation = hasAccepted ? "success" : "error";
    if (showTooltip) {
        return (_jsx(Tooltip, __assign({ tooltipContent: hasAccepted
                ? ts.contactConnectionSuccess()
                : ts.contactConnectionPending(), placement: "right" }, { children: _jsx(S.Dot, { variation: variation }) })));
    }
    return _jsx(S.Dot, { variation: variation });
};
