import { isAfter, isBefore, isEqual, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { isDate } from "lodash";
import { CONDITIONS_DATE, CONDITIONS_DEFAULT, CONDITIONS_NUMBER, CONDITIONS_TEXT, CONDITIONS_BOOLEAN, } from "../../../Editor/decorators/ConditionRenderer/condition.utils";
import { EConditionType, } from "../../../Editor/Editor.types";
export var conditionIsTrue = function (params) {
    var variableData = params.variableData, condition = params.condition;
    if (CONDITIONS_DEFAULT.includes(condition.type)) {
        switch (condition.type) {
            case EConditionType.IsEmpty: {
                return (variableData === null ||
                    variableData === undefined ||
                    variableData === "");
            }
            case EConditionType.IsNotEmpty: {
                return (variableData !== null &&
                    variableData !== undefined &&
                    variableData !== "");
            }
            default:
                return false;
        }
    }
    if (CONDITIONS_TEXT.includes(condition.type)) {
        if (!condition.contentText) {
            return false;
        }
        if (!variableData || typeof variableData !== "string") {
            return false;
        }
        switch (condition.type) {
            case EConditionType.TextContains: {
                return variableData.includes(condition.contentText);
            }
            case EConditionType.TextDoesNotContain: {
                return !variableData.includes(condition.contentText);
            }
            case EConditionType.TextStartsWith: {
                return variableData.startsWith(condition.contentText);
            }
            case EConditionType.TextEndsWith: {
                return variableData.endsWith(condition.contentText);
            }
            case EConditionType.TextIsExactly: {
                return variableData === condition.contentText;
            }
            default:
                return false;
        }
    }
    if (CONDITIONS_NUMBER.includes(condition.type)) {
        if (!condition.contentNumber) {
            return false;
        }
        if (!variableData || !["string", "number"].includes(typeof variableData)) {
            return false;
        }
        var variableDataParsed = variableData;
        if (typeof variableData === "string") {
            variableDataParsed = +variableData;
        }
        switch (condition.type) {
            case EConditionType.GreaterThan: {
                return variableDataParsed > condition.contentNumber;
            }
            case EConditionType.GreaterThanOrEqualTo: {
                return variableDataParsed >= condition.contentNumber;
            }
            case EConditionType.LessThan: {
                return variableDataParsed < condition.contentNumber;
            }
            case EConditionType.LessThanOrEqualTo: {
                return variableDataParsed <= condition.contentNumber;
            }
            case EConditionType.IsEqualTo: {
                return variableDataParsed === condition.contentNumber;
            }
            case EConditionType.IsNotEqualTo: {
                return variableDataParsed !== condition.contentNumber;
            }
            default:
                return false;
        }
    }
    if (CONDITIONS_DATE.includes(condition.type)) {
        if (!condition.contentDate) {
            return false;
        }
        if (!variableData ||
            !(isDate(variableData) || typeof variableData === "string")) {
            return false;
        }
        var date = new Date(variableData);
        if (!date) {
            return false;
        }
        switch (condition.type) {
            case EConditionType.DateIs: {
                return isEqual(date, condition.contentDate);
            }
            case EConditionType.DateIsBefore: {
                return isBefore(date, condition.contentDate);
            }
            case EConditionType.DateIsAfter: {
                return isAfter(date, condition.contentDate);
            }
            default:
                return false;
        }
    }
    if (CONDITIONS_BOOLEAN.includes(condition.type)) {
        switch (condition.type) {
            case EConditionType.IsTrue: {
                return !!variableData;
            }
            case EConditionType.IsFalse: {
                return !variableData;
            }
            default:
                return false;
        }
    }
    return false;
};
