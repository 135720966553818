var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, } from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 100%;\n  width: 100%;\n"], ["\n  position: relative;\n  height: 100%;\n  width: 100%;\n"])));
var InnerWrapNonPrint = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  overflow: scroll;\n  height: 100%;\n  width: 100%;\n"], ["\n  position: absolute;\n  overflow: scroll;\n  height: 100%;\n  width: 100%;\n"])));
var InnerWrapPrint = css(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var InnerWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return (props.isPrint ? InnerWrapPrint : InnerWrapNonPrint); });
export var JsxWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), function (props) { return (props.isPrint ? "0px" : "80px 40px 100px"); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
