export var VARIABLE_DATE_FORMATS = [
    "d/M/yyyy",
    "d MMM yyyy",
    "d MMMM yyyy",
    "iii, d MMMM yyyy",
    "iiii, d MMMM yyyy",
    "d MMMM",
    "MMMM yyyy",
    "MMMM",
    "yyyy",
];
