import { EPaymentOrderType } from "@rentiohq/shared/dist/types/payment.types";
import { ERentioFormField } from "../../components/components/Form";
import { getIbans } from "../../redux/contact/contact.actions";
import * as contactSelectors from "../../redux/contact/contact.selectors";
import { getValue, ibanSelectInlineValueSelector, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createContract.types";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.PaymentOrderCreateIban],
            properties: (_a = {},
                _a[EField.Header + "12"] = {
                    default: undefined,
                },
                _a[EField.PaymentOrderCreateIban] = {
                    default: getValue(EField.PaymentOrderCreateIban, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("contract_info.add.payment_orders.payee_iban.heading.summary")
        },
        _a[EField.Header + "12"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function (_a) {
                var otherDescription = _a.otherDescription, typeRecurring = _a.typeRecurring, typeOnce = _a.typeOnce;
                return getLocalizedText("payments.create.payee_iban.heading", {
                    type: getLocalizedText("payment.payment_order.type.".concat(EPaymentOrderType.Rent).toLowerCase()),
                });
            },
        },
        _a[EField.PaymentOrderCreateIban] = {
            "ui:field": ERentioFormField.IbanSelectInline,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.payee_iban.heading");
            },
            addIbanCtaTitle: getLocalizedText("payment.payment_order.iban.add.cta"),
            // Custom selectors & actions
            valueSelector: function (state, input) {
                return ibanSelectInlineValueSelector(state, input);
            },
            accountIdToAddIban: function (formData) {
                return formData[EField.PaymentOrderCreatePayee][0];
            },
            customIsFetchingSelector: function (state, formData) {
                return contactSelectors.isFetchingIbans(state, undefined, formData[EField.PaymentOrderCreatePayee][0]);
            },
            customIbansSelector: function (state, formData) {
                return contactSelectors.getIbans(state, undefined, formData[EField.PaymentOrderCreatePayee][0]);
            },
            customGetIbansActions: function (formData) { return [
                function () {
                    return getIbans.actions.start({
                        accountId: formData[EField.PaymentOrderCreatePayee][0],
                    });
                },
            ]; },
        },
        _a);
};
