import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { ILegalCase } from "@rentiohq/shared/dist/types/legalCase.types";
import { Lozenge } from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button/Button";
import { useNavigate } from "react-router-dom";

interface IProps {
  contract: IContract;
  openCase?: ILegalCase;
}

export const LegalCaseActionButton = (props: IProps) => {
  const { contract, openCase } = props;

  const navigate = useNavigate();

  if (openCase) {
    return (
      <Lozenge appearance="warning">
        {getLocalizedText("rentio_legal.status.ongoing_case")}
      </Lozenge>
    );
  }

  return (
    <Button
      appearance="outline"
      color="primary"
      size="extraSmall"
      isDisabled={!contract.legalLicenseActivatedAt}
      onClick={() => navigate(`/rentio-legal/add?contractId=${contract.id}`)}
    >
      {getLocalizedText("rentio_legal.overview.actions.start_new_case")}
    </Button>
  );
};
