import { Box } from "@rebass/grid";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import {
  EPropertyMemberTypes,
  TPropertyMember,
} from "@rentiohq/shared/dist/types/property.types";
import {
  Can,
  Card,
  DisplayText,
  ESpacings,
  Grid,
  Loading,
  ResourceList,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import isEmpty from "ramda/es/isEmpty";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { rs } from "../../../../services";
import * as t from "../../../../services/translationService";
import NoContractCard from "../Detail/components/NoContractCard";
import { PropertyOwnersCard } from "../Detail/components/PropertyOwnersCard";
import { PropertyRolesModal } from "../Detail/components/PropertyRolesModal";
import { ContractCard } from "./components/ContractCard/ContractCard";
import { ContractListItem } from "./components/ContractListItem";

export const ContractsOverview = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectors.getDetail(state, propertyId) : undefined,
  );

  const activeContractsForPropertyFilter = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Active)
    : undefined;
  const endedContractsForPropertyFilter = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Ended)
    : undefined;

  const {
    items: activeContracts = [],
    isFetching: isFetchingActiveContracts,
    refetch: refetchActive,
  } = contractHooks.useGetAll({
    query: { filter: activeContractsForPropertyFilter },
  });

  const {
    items: endedContracts = [],
    isFetching: isFetchingEndedContracts,
    refetch: refetchEnding,
  } = contractHooks.useGetAll({
    query: { filter: endedContractsForPropertyFilter },
  });

  const refetchAllContractForProperty = () => {
    refetchEnding();
    refetchActive();
  };

  const navigate = useNavigate();
  const [showInitialPropertyRolesModal, setShowInitialPropertyRolesModal] =
    useState(false);

  if (!property) {
    return null;
  }

  const { members = [] } = property;

  const propertyOwners = members.filter(
    (member: TPropertyMember) =>
      member.roles?.includes(EPropertyMemberTypes.Owner),
  );
  const hasPropertyOwners = propertyOwners.length > 0;

  if (!hasPropertyOwners) {
    return (
      <PropertyOwnersCard
        heading={t.propertyAddOwnerTitle()}
        property={property}
        owners={propertyOwners}
        onSelectOwner={() => {
          setShowInitialPropertyRolesModal(true);
        }}
      >
        {t.contractAddOwnerBody()}
      </PropertyOwnersCard>
    );
  }

  if (propertyId && showInitialPropertyRolesModal) {
    return (
      <PropertyRolesModal
        propertyId={+propertyId}
        onClose={() => {
          setShowInitialPropertyRolesModal(false);
        }}
      />
    );
  }

  // let contractCardActions: IAction[] = [];

  /*   if (propertyId) {
    contractCardActions.push({
      content: t.contractsOverviewCreateBasicContractInfoLabel(),
      permission: "propertyContractBasicInfo:create",
      url: rs.createBasicContractInfoRoute(propertyId),
    });
  } */

  // {
  //   content: t.contractsOverviewCreateRentalAgreementLabel(),
  //   permission: 'propertyContractRentalAgreement:create',
  //   url: `/properties/${propertyId}/rental-agreement`,
  // },

  const renderActiveContracts = () => {
    if (isFetchingActiveContracts && isEmpty(activeContracts)) {
      return (
        <Box mb={ESpacings.base}>
          <Loading />
        </Box>
      );
    }

    if (isEmpty(activeContracts)) {
      return <NoContractCard property={property} propertyId={property.id} />;
    }

    return (
      <>
        <Grid
          alignItems="center"
          justifyContent="space-between"
          mb={ESpacings.loose}
        >
          <Grid.Item>
            <DisplayText>{t.contractsOverviewCurrentContracts()}</DisplayText>
          </Grid.Item>
          <Grid.Item>
            <Button
              appearance="primary"
              onClick={() =>
                navigate(rs.createBasicContractInfoRoute(propertyId!))
              }
            >
              {t.contractsOverviewCreateRentalAgreementLabel()}
            </Button>
          </Grid.Item>
        </Grid>
        {activeContracts.map((contract: IContract) => (
          <ContractCard
            key={contract.id}
            contract={contract}
            property={property}
            onCancelContract={refetchAllContractForProperty}
          />
        ))}
      </>
    );
  };

  const renderEndedContracts = () => {
    // Only show one spinner, don't show this spinner when active contracts are being fetched
    if (
      isFetchingEndedContracts &&
      isEmpty(endedContracts) &&
      !isFetchingActiveContracts
    ) {
      return (
        <Box mb={ESpacings.base}>
          <Loading />
        </Box>
      );
    }

    if (isEmpty(endedContracts)) {
      return null;
    }

    return (
      <Box mt={ESpacings.extraLoose}>
        <DisplayText>{t.contractsOverviewFinishedContracts()}</DisplayText>
        <Card>
          <ResourceList
            items={endedContracts}
            renderItem={(contract: IContract) => (
              <ContractListItem contract={contract} />
            )}
          />
        </Card>
      </Box>
    );
  };

  const renderContent = () => (
    <>
      {renderActiveContracts()}
      {renderEndedContracts()}
    </>
  );

  return (
    <Can
      role={property.roles}
      perform="propertyContracts:visit"
      yes={renderContent}
      no={() => <Navigate to={`/properties/${propertyId}`} />}
    />
  );
};
