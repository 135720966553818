import { ELeaseType } from "@rentiohq/shared/dist/types/contract.types";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createContract.types";
import { getNavigationTitle } from "./schema.createContract.utils";
export var getSchema = function (initialValues, _a) {
    var contract = _a.contract, property = _a.property;
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.LeaseType],
            properties: (_a = {},
                _a[EField.Header + "1"] = {
                    default: undefined,
                },
                _a[EField.LeaseTypeInfo] = {
                    default: undefined,
                },
                _a[EField.LeaseType] = {
                    default: getValue(EField.LeaseType, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(ELeaseType).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("contract.leasetype.option.".concat(id)),
                    }); }),
                    readOnly: !!contract,
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("contract_info.create.leasetype.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "1"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.create.leasetype.heading"),
        },
        _a[EField.LeaseTypeInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("contract_info.lease_type_info.content"),
        },
        _a[EField.LeaseType] = {
            "ui:field": ERentioFormField.InlineSelect,
            extraInfoUrl: "contract.duration.option.info.url",
            title: getLocalizedText("contract.leasetype.option.label"),
            formatSummary: function (value) {
                return getLocalizedText("contract.leasetype.option.".concat(value));
            },
            isDisabled: function (formData) { return !!extraData.contract; },
        },
        _a;
};
