import styled from "@rentiohq/web-theme";

export const ShowAllButtonWrap = styled.div`
  width: 100%;
  margin-top: 8px;
  padding-right: 16px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;
