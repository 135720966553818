import { Route } from "react-router-dom";
import Reports from "./Reports";

export const getReportsScene = () => {
  return (
    <Route path="reports">
      <Route path="*" element={<Reports />} />
    </Route>
  );
};
