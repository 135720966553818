import { IBroker } from "@rentiohq/shared/dist/types/broker.types";

export interface IExtraData {
  broker: IBroker;
}

export enum EField {
  IntakeMail = "intakeMail",
  IntakeType = "intakeType",
}
