import { getSignatureReferenceForAccountId } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.utils";
import { EDefaultGroupId, EDefaultVariableId, } from "../TemplateEditor/components/Editor/Editor.config";
import { EVariableType, } from "../TemplateEditor/components/Editor/Editor.types";
export var getSignerFields = function (params) {
    var _a;
    var fields = params.fields, variablesData = params.variablesData;
    var filterOutBrokerSignature = false;
    var filterOutOwnerSignatures = false;
    var brokerIsSignerOnBehalfOfOwnersVariable = fields.variables.find(function (x) { return x.id === EDefaultVariableId.BrokerIsSignerOnBehalfOfOwners; });
    if (brokerIsSignerOnBehalfOfOwnersVariable) {
        var variableData = (_a = variablesData[brokerIsSignerOnBehalfOfOwnersVariable.id]) === null || _a === void 0 ? void 0 : _a[0];
        var brokerIsSignerOnBehalfOfOwners = variableData === true;
        filterOutBrokerSignature = !brokerIsSignerOnBehalfOfOwners;
        filterOutOwnerSignatures = brokerIsSignerOnBehalfOfOwners;
    }
    var variablesAsSignature = fields.variables.filter(function (x) { return x.type === EVariableType.Signature; });
    var result = {};
    variablesAsSignature.forEach(function (variable) {
        // variableData should be array of account ID's
        var variableData = variablesData[variable.id];
        // Filter owner signature if broker signs on behalf of owners
        if (filterOutOwnerSignatures) {
            var group = fields.groups.find(function (x) { return variable.groupId === x.id; });
            if ((group === null || group === void 0 ? void 0 : group.id) === EDefaultGroupId.Owner) {
                return;
            }
        }
        // Filter broker signature if broker does not sign on behalf of owners
        if (filterOutBrokerSignature) {
            var group = fields.groups.find(function (x) { return variable.groupId === x.id; });
            if ((group === null || group === void 0 ? void 0 : group.id) === EDefaultGroupId.Broker) {
                return;
            }
        }
        variableData.forEach(function (accountId) {
            if (typeof accountId === "number") {
                result[accountId] = getSignatureReferenceForAccountId(accountId);
            }
        });
    });
    return result;
};
