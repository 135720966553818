import { getGetPagedAction } from "../generic/paged.actions";
import { createRoutine } from "../utils/routine.utils";
import CONFIG from "./registration.config";
export var getRegistration = createRoutine("REGISTRATION_GET");
export var getPaged = getGetPagedAction({
    entityName: CONFIG.ENTITY_NAME,
});
export var createRegistration = createRoutine("REGISTRATION_CREATE");
export var updateRegistration = createRoutine("REGISTRATION_UPDATE");
export var deleteRegistration = createRoutine("REGISTRATION_DELETE");
export var registrationAddDocuments = createRoutine("REGISTRATION_ADD_DOCUMENTS");
export var registrationUpdateDocument = createRoutine("REGISTRATION_UPDATE_DOCUMENT");
