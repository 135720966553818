var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var PrintWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  page-break-after: always;\n  page-break-inside: avoid;\n"], ["\n  page-break-after: always;\n  page-break-inside: avoid;\n"])));
export var Wrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  padding: 60px 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  flex: 1;\n  padding: 60px 0;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var Line = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  height: 2px;\n  background-color: ", ";\n"], ["\n  flex: 1;\n  height: 2px;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global3; });
export var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0 12px;\n  color: ", ";\n"], ["\n  padding: 0 12px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global2; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
