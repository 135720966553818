import { Box } from "@rebass/grid";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  DisplayText,
  Error,
  ESpacings,
  Loading,
  Modal,
  MultiStepForm,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import kycVerificationSchemas from "../../../../../../forms/kycVerification";
import { submitActionCreator } from "../../../../../../forms/kycVerification/schema.kycVerification.actions";
import * as t from "../../../../../../services/translationService";

interface IProps {
  contactId?: number;
  asBroker?: boolean;
  onSuccess: () => void;
  onClose: () => void;
}

const CollectionFollowUpKycModal: React.FC<IProps> = props => {
  const { contactId, asBroker = false, onSuccess, onClose } = props;

  // Redux
  const dispatch = useDispatch();

  const isFetching = useSelector((state: IRootStore) =>
    contactId ? contactSelectors.getContactIsFetching(state, contactId) : false,
  );
  const fetchError = useSelector((state: IRootStore) =>
    contactId
      ? contactSelectors.getContactFetchError(state, contactId)
      : undefined,
  );
  const contact = useSelector((state: IRootStore) =>
    contactId ? contactSelectors.getContact(state, contactId) : undefined,
  );
  const meMaster = useSelector((state: IRootStore) =>
    contactId ? contactSelectors.getContactMeMaster(state) : undefined,
  );

  React.useEffect(() => {
    if (!contactId) {
      return;
    }

    dispatch(
      contactActions.getContact.actions.start({
        contactId,
      }),
    );
  }, [contactId]);

  // Form
  const [formId] = React.useState<number>(generateFormId());

  // Render
  if (!contactId) {
    return null;
  }

  if (!contact) {
    if (isFetching) {
      return (
        <Modal onClose={onClose}>
          <Loading />
        </Modal>
      );
    }

    if (fetchError) {
      return (
        <Modal onClose={onClose}>
          <Error errors={[fetchError]} />
        </Modal>
      );
    }

    return null;
  }

  if (
    contact.id === meMaster?.id &&
    (!meMaster.firstname || !meMaster.lastname)
  ) {
    return (
      <Modal
        onClose={onClose}
        actions={[
          {
            content: getLocalizedText("system.cancel"),
            onClick: onClose,
            appearance: "outline",
            size: "small",
          },
          {
            content: getLocalizedText(
              "form.kyc_receiver_as_broker.incomplete_broker.alert.cta.complete",
            ),
            url: "/company/contact",
            appearance: "primary",
            size: "small",
          },
        ]}
        components={{
          Header: () => (
            <Box pt={ESpacings.loose} px={ESpacings.loose}>
              <DisplayText size="medium">
                {getLocalizedText(
                  "form.kyc_receiver_as_broker.incomplete_broker.alert.title",
                  {
                    name: meMaster.company || getLocalizedText("system.broker"),
                  },
                )}
              </DisplayText>
            </Box>
          ),
        }}
        hasDismiss={true}
        shouldCloseOnOverlayClick={true}
        width="small"
      >
        {getLocalizedText(
          "form.kyc_receiver_as_broker.incomplete_broker.alert.info",
        )}
      </Modal>
    );
  }

  return (
    <MultiStepForm
      formId={`kyc-${formId}`}
      schemas={kycVerificationSchemas({ contact })}
      asModal={true}
      withAside={false}
      onSuccess={onSuccess}
      modalProps={{
        onClose,
      }}
      isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
        contact.id,
      )}
      performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
        contact.id,
      )}
      submitActionCreator={(formData: any) => {
        return submitActionCreator(contact.id, asBroker)(formData);
      }}
      submitLabel={t.system("save_send")}
    />
  );
};

export default CollectionFollowUpKycModal;
