import { Box } from "@rebass/grid";
import { getAccountContactInfo } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.api";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IAccount, IMember } from "@rentiohq/shared/dist/types/auth.types";
import {
  EContactInitialType,
  IContact,
} from "@rentiohq/shared/dist/types/contact.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import {
  Card,
  ContactSearch,
  ESpacings,
  Icon,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { AccountList } from "@rentiohq/web-shared/dist/components/AccountList";
import Button from "@rentiohq/web-shared/dist/components/Button";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import * as t from "../../../../../services/translationService";
import { PropertyRolesModal } from "./PropertyRolesModal";

interface IProps {
  children?: React.ReactNode;
  property: IProperty;
  owners: IMember<EPropertyMemberTypes>[];
  onUpdate?: () => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  heading?: React.ReactNode;
  space?: ESpacings;
  body?: React.ReactNode;
  showActions?: boolean;
  onSelectOwner?: () => void;
  onRemoveOwner?: () => void;
  onAddOwner?: () => void;
}

export const PropertyOwnersCard = (props: IProps) => {
  const {
    property,
    owners,
    heading,
    space = ESpacings.extraLoose,
    showActions = true,
    onSelectOwner,
    onRemoveOwner,
    onAddOwner,
  } = props;

  const { id: propertyId } = property;

  const [showInput, setShowInput] = React.useState(false);
  const [showRoles, setShowRoles] = React.useState(false);

  const { updateMembers } = propertyHooks.useUpdate({ id: propertyId });

  React.useEffect(() => {
    setShowInput(owners.length === 0);
  }, [owners.length]);

  const removeOwner = async (accountId: number) => {
    try {
      updateMembers({
        membersToAdd: [],
        membersToRemove: [{ accountId, roles: [EPropertyMemberTypes.Owner] }],
        onSuccess: onRemoveOwner,
      });
    } catch (err) {
      console.warn(err);
    }
  };

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  const addOwner = async (owner: IContact) => {
    try {
      updateMembers({
        membersToAdd: [
          { accountId: owner.accountId, roles: [EPropertyMemberTypes.Owner] },
        ],
        membersToRemove: [],
        onSuccess: () => {
          onAddOwner?.();

          // Timeout to make it less busy to user
          setTimeout(() => {
            setShowRoles(true);
          }, 100);
        },
      });

      setShowInput(false);

      onSelectOwner?.();
    } catch (err) {
      console.warn(err);
    }
  };

  const actions = [
    {
      content: (
        <TextStyle variation={"negative"}>
          {t.contactRemoveContact("owners")}
        </TextStyle>
      ),
      media: <Icon source="bin" color="red" />,
      onClick: (accountId: number) => {
        removeOwner(accountId);
      },
      isContactAction: false,
    },
  ];

  const disallowedAccountIds = property.members
    ? property.members
        .filter(
          member =>
            member.roles!.includes(EPropertyMemberTypes.Tenant) ||
            member.roles!.includes(EPropertyMemberTypes.Parent) ||
            member.roles!.includes(EPropertyMemberTypes.Owner),
        )
        .map(member => member.account.id!)
    : [];

  return (
    <>
      <Card
        heading={heading}
        space={space}
        actions={
          showActions
            ? [
                {
                  content: t.propertyAddOwnerLabel(),
                  onClick: toggleInput,
                  appearance: "link",
                },
              ]
            : undefined
        }
      >
        <>
          <AccountList
            accounts={owners.map(member => member.account) as IAccount[]}
            getContactInfoForAccount={async (account: IAccount) =>
              (
                await getAccountContactInfo({
                  propertyId: property.id,
                  accountId: account.id,
                })
              ).data.data
            }
            initialContactType={EContactInitialType.Owner}
            boxProps={{ py: ESpacings.base }}
            actions={actions}
          />
          {showInput && (
            <Box mt={ESpacings.base}>
              {showActions ? (
                <>
                  <ContactSearch
                    onContactSelect={addOwner}
                    disallowedAccountIds={disallowedAccountIds}
                    initialType={EContactInitialType.Owner}
                  />
                  {!isEmpty(owners) && (
                    <Box mt={1}>
                      <Button appearance="link" onClick={toggleInput}>
                        {t.system("cancel")}
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <p>{t.propertyDashboardEmptyContent()}</p>
              )}
            </Box>
          )}
        </>
      </Card>

      {showRoles && (
        <PropertyRolesModal
          propertyId={property.id}
          onClose={() => {
            setShowRoles(false);
          }}
        />
      )}
    </>
  );
};
