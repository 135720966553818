import { EField } from "./schema.followUpRegisteredMail.types";
import { IExtraData } from "./schema.followUpRegisteredMail.types";
import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { addBusinessDays } from "@rentiohq/shared/dist/utils/date-fns.utils";

export const getInitialValues = ({
  subject,
  content,
  contact,
  property,
}: IExtraData): IValues => {
  const initialValues = {
    [EField.Subject]: subject,
    [EField.Content]: content,
    [EField.FirstName]: contact.firstname || "",
    [EField.LastName]: contact.lastname || "",
    [EField.Email]: contact.email || "",
    [EField.CutOffDate]: addBusinessDays(new Date(), 5),
    [EField.Address]: {
      street: property.street,
      number: property.number,
      box: property.box,
      zip: property.zip,
      city: property.city,
      country: property.countryId,
    },
  };

  return initialValues;
};
