import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";

export const ColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColorCircle = styled.div<{ brandColor?: string }>`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid ${props => props.theme.colors.global3};
  background-color: ${props => props.brandColor || props.theme.colors.neutral2};
  margin-right: 10px;
`;

export const ChromePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
