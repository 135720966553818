import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";

export enum EField {
  PaymentRequestId = "paymentRequestId",
  Content = "content",
  Subject = "subject",
  FirstName = "firstname",
  LastName = "lastname",
  Email = "email",
  Address = "address",
  CutOffDate = "cutOffDate",
}

export interface IExtraData {
  subject: string;
  content: string;
  contact: IContact;
  property: IProperty;
}
