import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import * as propertyHooks from "../../../reduxV2/property/property.hooks";
import * as S from "./PropertyFetchListItem.styles";
var PropertyFetchListItem = function (_a) {
    var propertyId = _a.propertyId, property = _a.property, renderAlways = _a.renderAlways, renderLoading = _a.renderLoading, renderError = _a.renderError, renderProperty = _a.renderProperty, shouldRefetch = _a.shouldRefetch;
    var _b = propertyHooks.useDetail({ id: propertyId, shouldRefetch: shouldRefetch }), fetchedProperty = _b.detail, fetchError = _b.fetchError, isFetching = _b.isFetching, refetch = _b.refetch;
    // Lifecycle methods
    useEffect(function () {
        fetchData();
    }, []);
    var renderLocalLoading = function () { return _jsx(S.ActivityIndicatorStyled, {}); };
    // Data
    var fetchData = function () {
        var property = getPropertyFromProps();
        if (property)
            return;
        if (!propertyId)
            return;
        refetch();
    };
    // Helpers
    var getPropertyFromProps = function () {
        if (fetchedProperty) {
            return fetchedProperty;
        }
        return property;
    };
    var prop = getPropertyFromProps();
    // Render
    if (renderAlways)
        return renderAlways(prop);
    if (prop)
        return renderProperty ? renderProperty(prop) : null;
    if (isFetching) {
        return renderLoading ? renderLoading() : renderLocalLoading();
    }
    // TODO: Render default error
    if (fetchError) {
        return renderError ? renderError(fetchError) : null;
    }
    return null;
};
export default PropertyFetchListItem;
