var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EContractMemberTypes } from "@rentiohq/shared/dist/types/contract.types";
import { formatCurrency, subtractWithDecimalPrecision, } from "@rentiohq/shared/dist/utils/number.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getName } from "../../redux/contact/contact.utils";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.releaseRentDeposit.types";
import { getNavigationTitle } from "./schema.releaseRentDeposit.utils";
export var getSchema = function (initialValues, _a) {
    var relatedContract = _a.relatedContract, internalModeEnabled = _a.internalModeEnabled;
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.TenantAmount],
            properties: (_a = {},
                _a[EField.Header + "1"] = {
                    default: undefined,
                },
                _a[EField.Info] = {
                    default: undefined,
                },
                _a[EField.TenantAmount] = {
                    default: getValue(EField.TenantAmount, initialValues, defaultValues),
                    minimum: 0,
                    maximum: relatedContract.amount,
                },
                _a[EField.OwnerAmount] = {
                    default: getValue(EField.OwnerAmount, initialValues, defaultValues),
                    minimum: 0,
                    maximum: relatedContract.amount,
                    // @ts-ignore
                    handleChange: function (fieldName, fieldValue, _, formData) {
                        if (fieldValue === void 0) { fieldValue = 0; }
                        if (fieldName === EField.TenantAmount) {
                            var newOwnerAmount = Math.max(0, subtractWithDecimalPrecision(relatedContract.amount || 0, fieldValue));
                            return { shouldSetNewValue: true, newValue: newOwnerAmount };
                        }
                        if (fieldName === EField.BrokerAmount) {
                            var tenantAmount = formData[EField.TenantAmount];
                            var newOwnerAmount = Math.max(0, subtractWithDecimalPrecision(relatedContract.amount || 0, tenantAmount + fieldValue));
                            return { shouldSetNewValue: true, newValue: newOwnerAmount };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.BrokerAmount] = {
                    default: getValue(EField.BrokerAmount, initialValues, defaultValues),
                    minimum: 0,
                    maximum: relatedContract.amount,
                    // @ts-ignore
                    handleChange: function (fieldName, fieldValue, _, formData) {
                        if (fieldValue === void 0) { fieldValue = 0; }
                        if (fieldName === EField.OwnerAmount ||
                            fieldName === EField.TenantAmount) {
                            var tenantAmount = fieldName === EField.TenantAmount
                                ? fieldValue
                                : formData[EField.TenantAmount];
                            var ownerAmount = fieldName === EField.OwnerAmount
                                ? fieldValue
                                : formData[EField.OwnerAmount];
                            var newBrokerAmount = Math.max(0, subtractWithDecimalPrecision(relatedContract.amount || 0, tenantAmount + ownerAmount));
                            return { shouldSetNewValue: true, newValue: newBrokerAmount };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.JudicialRelease] = {
                    type: "boolean",
                    default: getValue(EField.JudicialRelease, initialValues, defaultValues),
                    //@ts-ignore
                    showIf: function () { return internalModeEnabled; },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    var tenants = extraData.relatedContract.members.filter(function (member) {
        return member.roles.includes(EContractMemberTypes.Tenant) ||
            member.roles.includes(EContractMemberTypes.Parent);
    });
    var owners = extraData.relatedContract.members.filter(function (member) {
        return member.roles.includes(EContractMemberTypes.Owner);
    });
    return _a = {
            "rentio:title": getLocalizedText("rent_deposit_release.split.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "1"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit_release.split.heading"),
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("rent_deposit_release.contract_info.heading"),
            subtitle: (function () {
                return getLocalizedText("rent_deposit_release.contract_info.content", {
                    startDate: formatDate(extraData.relatedContract.startDate),
                    amount: formatCurrency(extraData.relatedContract.amount),
                    tenants: tenants.map(function (member) { return getName(member.account); }).join(", "),
                    owners: owners.map(function (member) { return getName(member.account); }).join(", "),
                });
            })(),
            // TODO: Icon
            // icon: images.ICO_CHAT_SEND
        },
        _a[EField.TenantAmount] = {
            "ui:field": ERentioFormField.Number,
            formatSummary: function (formData) {
                return formData && formatCurrency(formData);
            },
            title: getLocalizedText("rent_deposit_release.tenant_amount.label"),
            suffixText: "EUR",
            isFloat: true,
        },
        _a[EField.OwnerAmount] = {
            "ui:field": ERentioFormField.Number,
            formatSummary: function (formData) {
                return formData && formatCurrency(formData);
            },
            placeholder: 0,
            title: getLocalizedText("rent_deposit_release.owner_amount.label"),
            suffixText: "EUR",
            isFloat: true,
        },
        _a[EField.BrokerAmount] = {
            "ui:field": ERentioFormField.Number,
            formatSummary: function (formData) {
                return formData && formatCurrency(formData);
            },
            placeholder: 0,
            title: getLocalizedText("rent_deposit_release.broker_amount.label"),
            suffixText: "EUR",
            isFloat: true,
        },
        _a[EField.JudicialRelease] = __assign({ "ui:field": ERentioFormField.Switch, title: getLocalizedText("rent_deposit_release.release_type"), label: getLocalizedText("rent_deposit_release.via_judgement.label") }, (extraData.internalModeEnabled
            ? {
                formatSummary: function (formData) {
                    if (formData && extraData.internalModeEnabled)
                        return getLocalizedText("rent_deposit_release.via_judgement.label");
                    return null;
                },
            }
            : {})),
        _a;
};
export var getValidate = function (extraData) { return function (formData, errors) {
    if (formData[EField.OwnerAmount] +
        formData[EField.TenantAmount] +
        formData[EField.BrokerAmount] !==
        extraData.relatedContract.amount) {
        var amountError = getLocalizedText("rent_deposit_release.sum_amounts.error", {
            amount: formatCurrency(extraData.relatedContract.amount),
        });
        errors[EField.OwnerAmount].addError(amountError);
        errors[EField.TenantAmount].addError(amountError);
        errors[EField.BrokerAmount].addError(amountError);
    }
    return errors;
}; };
