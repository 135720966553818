import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { Error, Loading } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { PaymentOrder } from "./PaymentOrder";
import { IPaymentOrderContainerProps } from "./PaymentOrder.types";

export const PaymentOrderContainer: React.FC<IPaymentOrderContainerProps> = ({
  paymentOrderId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const paymentOrder = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentOrder(state, paymentOrderId),
  );
  const isFetching = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentOrder(state, paymentOrderId),
  );
  const error = useSelector((state: IRootStore) =>
    paymentSelectors.fetchPaymentOrderError(state, paymentOrderId),
  );

  const isDeletingPaymentOrder = useSelector(
    (state: IRootStore) =>
      state.payment.deletePaymentOrders[paymentOrderId]?.isDeleting || false,
  );
  const prevIsDeletingPaymentOrder = usePrevious(isDeletingPaymentOrder);

  React.useEffect(() => {
    if (!!prevIsDeletingPaymentOrder && !isDeletingPaymentOrder) {
      rest.onDrawerClose?.();
    }
  }, [isDeletingPaymentOrder, prevIsDeletingPaymentOrder]);

  React.useEffect(() => {
    dispatch(paymentActions.getPaymentOrder.actions.start({ paymentOrderId }));
  }, [paymentOrderId]);

  const renderContent = () => {
    if (paymentOrder) {
      return (
        <PaymentOrder
          paymentOrderId={paymentOrderId}
          paymentOrder={paymentOrder}
          {...rest}
        />
      );
    }

    if (isFetching) {
      return <Loading />;
    }
    if (error) {
      return <Error errors={[error]} />;
    }
    return null;
  };

  return renderContent();
};
