import * as authUtils from "@rentiohq/shared-frontend/dist/redux/auth/auth.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { isBefore } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { Modal } from "@rentiohq/web-shared/dist/components";
import React from "react";

const INIT_DATE = new Date();

interface IProps {}

const EnabledAccountsSelectForceRefresh: React.FC<IProps> = props => {
  // State
  const [showModal, setShowModal] = React.useState(false);

  const handleFocus = React.useCallback(async () => {
    // Already showing modal
    if (showModal) {
      return;
    }

    const accessTokenLastAccountSelectDate =
      await authUtils.getAccessTokenLastAccountSelectDate();
    // Never switched
    if (!accessTokenLastAccountSelectDate) {
      return;
    }

    if (isBefore(INIT_DATE, accessTokenLastAccountSelectDate)) {
      setShowModal(true);
    }
  }, [setShowModal]);

  // Lifecycle
  React.useEffect(() => {
    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [handleFocus]);

  // Event handlers
  const reload = () => {
    setShowModal(false);
    window.location.href = window.location.origin;
  };

  // Render
  if (!showModal) {
    return null;
  }

  return (
    <Modal
      width="medium"
      shouldCloseOnOverlayClick={true}
      hasDismiss={true}
      onClose={reload}
      heading={getLocalizedText("modal.enabled_accounts_select.confirm.title")}
      actions={[
        {
          content: getLocalizedText("system.reload"),
          onClick: reload,
          appearance: "primary",
        },
      ]}
    >
      {getLocalizedText("modal.enabled_accounts_select.confirm.info")}
    </Modal>
  );
};

export default EnabledAccountsSelectForceRefresh;
