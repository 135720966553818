import { EPropertyMemberTypes, EPropertyTypeId, } from "@rentiohq/shared/dist/types/property.types";
import { iHaveOneOfRoles } from "@rentiohq/shared/dist/utils/roles.utils";
import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
export var canHaveRentioLegalForProperty = function (params) {
    var property = params.property;
    var isBelgianProperty = property.countryId === "BE";
    if (!isBelgianProperty) {
        return false;
    }
    var isHouseOrApartment = property.typeId &&
        [EPropertyTypeId.Apartment, EPropertyTypeId.House].includes(property.typeId);
    if (!isHouseOrApartment) {
        return false;
    }
    var isManager = iHaveOneOfRoles(property, [
        EPropertyMemberTypes.IndexationManager,
        EPropertyMemberTypes.FinancialManager,
        EPropertyMemberTypes.TechnicalManager,
    ]);
    if (!isManager) {
        return false;
    }
    return true;
};
export var activateRentioLegalFeature = function (broker) {
    var variables = {
        company: broker.name,
    };
    var url = appendQueryParams({
        path: "mailto:".concat(getLocalizedText("rentio_legal.actions.activate_feature.mail.to", variables)),
        queryParams: {
            subject: getLocalizedText("rentio_legal.actions.activate_feature.mail.subject", variables),
            body: getLocalizedText("rentio_legal.actions.activate_feature.mail.body", variables),
        },
    });
    window.location.href = getLocalizedText(url);
};
