import { Icon, TAssetSource } from "@rentiohq/web-shared/dist/components";
import { Colors } from "@rentiohq/web-theme";
import * as S from "./SortableResourceListHeader.styles";
import {
  ESortOrder,
  OrderChangeFunction,
} from "./SortableResourceListHeader.types";

export interface ISortableResourceListHeaderProps {
  order: ESortOrder | undefined;
  onOrderChange: OrderChangeFunction;
}

const getIcon = (
  currentOrder: ESortOrder | undefined,
): { icon: TAssetSource; color: Colors } => {
  if (currentOrder === ESortOrder.Asc) {
    return { icon: "chevronUp", color: "primary" };
  }
  if (currentOrder === ESortOrder.Desc) {
    return { icon: "chevronDown", color: "primary" };
  }
  return { icon: "chevronDown", color: "neutral50" };
};

export const getNextOrder = (currentOrder: ESortOrder | undefined) => {
  if (currentOrder === ESortOrder.Desc) {
    return ESortOrder.Asc;
  }
  return ESortOrder.Desc;
};

export const SortableResourceListHeader = (
  props: ISortableResourceListHeaderProps,
) => {
  const { order, onOrderChange } = props;

  const changeOrder = () => {
    const next = getNextOrder(order);
    onOrderChange(next);
  };

  const { icon, color } = getIcon(order);

  return (
    <S.SortableHeader onClick={changeOrder}>
      <Icon source={icon} color={color} size="small" />
    </S.SortableHeader>
  );
};
