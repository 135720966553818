var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Select } from "../";
import Button from "../Button";
export var SelectEmployeeDropdown = function (props) {
    var selectedEmployeeId = props.selectedEmployeeId, onSelectEmployee = props.onSelectEmployee, onCancel = props.onCancel, boxProps = props.boxProps;
    var employees = brokerHooks.useGetBrokerEmployees().employees;
    return (_jsxs(Box, __assign({}, boxProps, { children: [_jsx(Select, { id: "select-employee-dropdown", label: "_", labelHidden: true, options: employees
                    .filter(function (account) { return !selectedEmployeeId || selectedEmployeeId !== account.id; })
                    .map(function (account) {
                    return {
                        label: getName(account),
                        value: account.id,
                    };
                }), onChange: function (item) {
                    if (typeof item.value === "number") {
                        onSelectEmployee(item.value);
                    }
                } }), _jsx(Box, __assign({ mt: 1 }, { children: _jsx(Button, __assign({ appearance: "link", onClick: onCancel }, { children: getLocalizedText("system.cancel") })) }))] })));
};
