import { EConditionType, EVariableType } from "../../Editor.types";
export var CONDITIONS_DEFAULT = [
    EConditionType.IsEmpty,
    EConditionType.IsNotEmpty,
];
export var CONDITIONS_TEXT = [
    EConditionType.TextContains,
    EConditionType.TextDoesNotContain,
    EConditionType.TextStartsWith,
    EConditionType.TextEndsWith,
    EConditionType.TextIsExactly,
];
export var CONDITIONS_NUMBER = [
    EConditionType.GreaterThan,
    EConditionType.GreaterThanOrEqualTo,
    EConditionType.LessThan,
    EConditionType.LessThanOrEqualTo,
    EConditionType.IsEqualTo,
    EConditionType.IsNotEqualTo,
    // EConditionType.IsBetween,
    // EConditionType.IsNotBetween,
];
export var CONDITIONS_DATE = [
    EConditionType.DateIs,
    EConditionType.DateIsBefore,
    EConditionType.DateIsAfter,
];
export var CONDITIONS_BOOLEAN = [
    EConditionType.IsTrue,
    EConditionType.IsFalse,
];
export var getConditionsForVariableType = function (type) {
    var result = CONDITIONS_DEFAULT;
    switch (type) {
        case EVariableType.String:
        case EVariableType.Dropdown:
            result.push.apply(result, CONDITIONS_TEXT);
            break;
        case EVariableType.Number:
            result.push.apply(result, CONDITIONS_NUMBER);
            break;
        case EVariableType.Date:
            result.push.apply(result, CONDITIONS_DATE);
            break;
        case EVariableType.Boolean:
            result.push.apply(result, CONDITIONS_BOOLEAN);
            break;
        case EVariableType.Image:
        default:
            break;
    }
    return result;
};
export var showContentText = function (type) {
    return CONDITIONS_TEXT.includes(type);
};
export var showContentNumber = function (type) {
    return CONDITIONS_NUMBER.includes(type);
};
export var showContentDate = function (type) {
    return CONDITIONS_DATE.includes(type);
};
