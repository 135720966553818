var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, Image, Platform, Text, TextInput, TouchableOpacity, View, } from "react-native";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
export var Wrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  flex-direction: row;\n  align-items: flex-end;\n"], ["\n  width: 100%;\n  flex-direction: row;\n  align-items: flex-end;\n"])));
export var TextInputStyled = styled(TextInput)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  :focus {\n    outline: none;\n  }\n  flex: 1;\n  padding: 16px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n  font-size: 14px;\n  color: ", ";\n  border-radius: 4px;\n  border-color: ", ";\n  border-width: 1px;\n  margin-left: 8px;\n  margin-right: 8px;\n  ", "\n"], ["\n  :focus {\n    outline: none;\n  }\n  flex: 1;\n  padding: 16px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n  font-size: 14px;\n  color: ", ";\n  border-radius: 4px;\n  border-color: ", ";\n  border-width: 1px;\n  margin-left: 8px;\n  margin-right: 8px;\n  ", "\n"])), function (props) { return props.theme.colors.neutral1; }, function (props) { return props.theme.colors.global3; }, function (props) {
    return Platform.select({
        web: "\n        placeholder-text-color: ".concat(props.theme.colors.global2, ";\n        caret-color: ").concat(props.theme.colors.neutral1, ";\n      "),
        ios: "\n        min-height: 44px;\n        max-height: 100px;\n      ",
        android: "\n        min-height: 44px;\n        max-height: 100px;\n      ",
    });
});
var buttonImage = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  margin-bottom: 8px;\n"], ["\n  width: 24px;\n  height: 24px;\n  margin-bottom: 8px;\n"])));
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), buttonImage);
export var Button = styled(TouchableOpacity)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  opacity: ", ";\n"], ["\n  opacity: ", ";\n"])), function (props) { return (props.disabled ? 0.5 : 1); });
export var AttachmentButtonImage = styled(Image).attrs(function () { return ({
    source: images.ICO_CHAT_ADD_ATTACHMENT,
}); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), buttonImage);
export var SendButtonImage = styled(Image).attrs(function () { return ({
    source: images.ICO_CHAT_SEND,
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), buttonImage);
export var AttachmentWrap = styled(TouchableOpacity)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: 4px;\n  border-color: ", ";\n  border-width: 1px;\n  align-items: center;\n  justify-content: center;\n  width: 44px;\n  height: 44px;\n"], ["\n  border-radius: 4px;\n  border-color: ", ";\n  border-width: 1px;\n  align-items: center;\n  justify-content: center;\n  width: 44px;\n  height: 44px;\n"])), function (props) { return props.theme.colors.global3; });
export var AttachmentCloseIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_CLOSE_ROUND_DARK,
}); })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  position: absolute;\n  top: 1px;\n  right: 1px;\n  z-index: 9999;\n"], ["\n  width: 16px;\n  height: 16px;\n  position: absolute;\n  top: 1px;\n  right: 1px;\n  z-index: 9999;\n"])));
export var AttachmentImage = styled(Image)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border-radius: 4px;\n  width: 100%;\n  height: 100%;\n"], ["\n  border-radius: 4px;\n  width: 100%;\n  height: 100%;\n"])));
export var AttachmentText = styled(Text)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  text-align: center;\n  color: ", ";\n  line-height: 14px;\n  font-size: 14px;\n"], ["\n  text-align: center;\n  color: ", ";\n  line-height: 14px;\n  font-size: 14px;\n"])), function (props) { return props.theme.colors.neutral1; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
