import styled from "@rentiohq/web-theme";

export const OverviewAsidePreview = styled.div`
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px;
  background-color: rgba(0, 0, 0, 0.04);
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  img {
    /* object-fit: cover; */
    /* width: 100%; */
    height: 100%;
  }
`;
