import { ts } from "../../services";

export const getBreadcrumb = (params: {
  propertyId?: number;
  documentId?: string;
}) => {
  const { propertyId, documentId } = params;

  if (propertyId && documentId) {
    return {
      content: ts.documentPackageFormBreadcrumbDocument(),
      to: `/properties/${propertyId}/documents?documentId=${documentId}`,
    };
  }

  if (propertyId) {
    return {
      content: ts.documentPackageFormBreadcrumbProperty(),
      to: `/properties/${propertyId}`,
    };
  }

  return {
    content: ts.documentPackageFormBreadcrumbFollowUp(),
    to: "/follow-up/signatures?page=1",
  };
};
