var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var ExitPromptContext = React.createContext({
    exitPromptEnabled: false,
    setExitPromptEnabled: function (newValue) { },
});
// Provider
var ExitPrompt = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(true), exitPromptEnabled = _a[0], setExitPromptEnabled = _a[1];
    // Render
    return (_jsx(ExitPromptContext.Provider, __assign({ value: {
            exitPromptEnabled: exitPromptEnabled,
            setExitPromptEnabled: setExitPromptEnabled,
        } }, { children: children })));
};
export default ExitPrompt;
