var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EDocumentPackageStatus, } from "@rentiohq/shared/dist/types/documentPackage.types";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import * as documentPackageActions from "./documentPackage.actions";
export var REDUCER_KEY_DOCUMENT_PACKAGE = "documentPackage";
var getDefaultState = function () { return ({
    // All Document packages
    documentPackages: {},
    // Document packages by identifier
    byIdentifier: {},
    // Packages by ID
    documentPackagesById: {},
    // Document package create
    isCreatingDocumentPackage: false,
    createDocumentPackageError: undefined,
    createdDocumentPackageId: undefined,
    // Document package revoke
    isRevokingDocumentPackage: false,
    revokeDocumentPackageError: undefined,
    revokedDocumentPackageId: undefined,
    // Document packages paged
    pagedDocumentPackages: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(documentPackageActions.createDocumentPackage.types.START, function (state) { return (__assign(__assign({}, state), { isCreatingDocumentPackage: true, createDocumentPackageError: undefined, createdDocumentPackageId: undefined })); });
reducer.on(documentPackageActions.createDocumentPackage.types.SUCCESS, function (state, documentPackage) {
    var _a;
    return __assign(__assign({}, state), { documentPackages: __assign(__assign({}, state.documentPackages), (_a = {}, _a[documentPackage.id] = documentPackage, _a)), isCreatingDocumentPackage: false, createDocumentPackageError: undefined, createdDocumentPackageId: documentPackage.id });
});
reducer.on(documentPackageActions.createDocumentPackage.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingDocumentPackage: false, createDocumentPackageError: error, createdDocumentPackageId: undefined }));
});
reducer.on(documentPackageActions.revokeDocumentPackage.types.START, function (state) { return (__assign(__assign({}, state), { isRevokingDocumentPackage: true, revokeDocumentPackageError: undefined, revokedDocumentPackageId: undefined })); });
reducer.on(documentPackageActions.revokeDocumentPackage.types.SUCCESS, function (state, _a) {
    var _b;
    var documentPackageId = _a.documentPackageId;
    var documentPackages = __assign({}, state.documentPackages);
    return __assign(__assign({}, state), { documentPackages: __assign(__assign({}, documentPackages), (_b = {}, _b[documentPackageId] = __assign(__assign({}, documentPackages[documentPackageId]), { status: EDocumentPackageStatus.Revoked }), _b)), isRevokingDocumentPackage: false, revokeDocumentPackageError: undefined, revokedDocumentPackageId: documentPackageId });
});
reducer.on(documentPackageActions.revokeDocumentPackage.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isRevokingDocumentPackage: false, revokeDocumentPackageError: error, revokedDocumentPackageId: undefined }));
});
reducer.on(documentPackageActions.getDocumentPackage.types.START, function (state, _a) {
    var _b;
    var documentPackageId = _a.documentPackageId;
    return (__assign(__assign({}, state), { documentPackagesById: (_b = {},
            _b[documentPackageId] = __assign(__assign({}, state.documentPackagesById[documentPackageId]), { isFetching: true, fetchError: undefined }),
            _b) }));
});
reducer.on(documentPackageActions.getDocumentPackage.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var documentPackageId = _a.documentPackageId, documentPackage = _a.documentPackage;
    return __assign(__assign({}, state), { documentPackages: __assign(__assign({}, state.documentPackages), (_b = {}, _b[documentPackageId] = documentPackage, _b)), documentPackagesById: (_c = {},
            _c[documentPackageId] = __assign(__assign({}, state.documentPackagesById[documentPackageId]), { isFetching: false, fetchError: undefined }),
            _c) });
});
reducer.on(documentPackageActions.getDocumentPackage.types.FAILURE, function (state, _a) {
    var _b;
    var documentPackageId = _a.documentPackageId, error = _a.error;
    return (__assign(__assign({}, state), { documentPackagesById: (_b = {},
            _b[documentPackageId] = __assign(__assign({}, state.documentPackagesById[documentPackageId]), { isFetching: false, fetchError: error }),
            _b) }));
});
reducer.on(documentPackageActions.getDocumentPackages.types.START, function (state, _a) {
    var _b;
    var identifier = _a.identifier, filterData = _a.filterData;
    var prevStateForIdentifier = state.byIdentifier[identifier] || {};
    return __assign(__assign({}, state), { byIdentifier: __assign(__assign({}, state.byIdentifier), (_b = {}, _b[identifier] = __assign(__assign({ ids: undefined, isExhausted: false }, prevStateForIdentifier), { filterData: filterData, isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(documentPackageActions.getDocumentPackages.types.SUCCESS, function (state, _a) {
    var _b;
    var identifier = _a.identifier, refetch = _a.refetch, documentPackages = _a.documentPackages, isExhausted = _a.isExhausted;
    var prevStateForIdentifier = state.byIdentifier[identifier] || {};
    var ids = documentPackages.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return __assign(__assign({}, state), { documentPackages: __assign(__assign({}, state.documentPackages), keyBy(documentPackages, "id")), byIdentifier: __assign(__assign({}, state.byIdentifier), (_b = {}, _b[identifier] = __assign(__assign({ extraFilterData: undefined }, prevStateForIdentifier), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, ids: refetch ? ids : __spreadArray(__spreadArray([], (prevStateForIdentifier.ids || []), true), ids, true) }), _b)) });
});
reducer.on(documentPackageActions.getDocumentPackages.types.FAILURE, function (state, _a) {
    var _b;
    var identifier = _a.identifier, error = _a.error;
    var prevStateForIdentifier = state.byIdentifier[identifier] || {};
    return __assign(__assign({}, state), { byIdentifier: __assign(__assign({}, state.byIdentifier), (_b = {}, _b[identifier] = __assign(__assign({ extraFilterData: undefined, ids: undefined, isExhausted: false }, prevStateForIdentifier), { isFetching: false, fetchError: error }), _b)) });
});
reducer.on(documentPackageActions.getDocumentPackagesPaged.types.START, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, _d = _a.page, page = _d === void 0 ? 0 : _d, filterData = _a.filterData;
    return merge({}, state, {
        pagedDocumentPackages: (_b = {},
            _b[identifier] = {
                filterData: filterData,
                pages: (_c = {},
                    _c[page] = {
                        isFetching: true,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(documentPackageActions.getDocumentPackagesPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, _d = _a.page, page = _d === void 0 ? 0 : _d, documentPackages = _a.documentPackages;
    return merge({}, state, {
        documentPackages: __assign({}, keyBy(documentPackages, "id")),
        pagedDocumentPackages: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        documentPackageIds: documentPackages.map(function (documentPackage) { return documentPackage.id; }),
                        isFetching: false,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(documentPackageActions.getDocumentPackagesPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, _d = _a.page, page = _d === void 0 ? 0 : _d, error = _a.error;
    return merge({}, state, {
        pagedDocumentPackages: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        isFetching: false,
                        fetchError: error,
                    },
                    _c),
            },
            _b),
    });
});
export default reducer;
