var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { generateReducer, } from "../generators/generator.reducer";
import { getQueryHash } from "../utils/hash.utils";
import { getCountStepsFailed, getCountStepsStart, getCountStepsSucceeded, updateApplicationStepSucceeded, } from "./application.actions";
import { GENERATOR_PARAMS } from "./application.constants";
var getExtraDefaultState = function () { return ({
    countSteps: {},
}); };
var _reducer = generateReducer(GENERATOR_PARAMS, getExtraDefaultState);
var reducer = _reducer;
reducer.on(updateApplicationStepSucceeded.type, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a["".concat(payload.id)] = {
            pending: false,
            entity: payload.result,
        }, _a)) }));
});
reducer.on(getCountStepsStart.type, function (state, payload) {
    var _a;
    var countStepsKey = getQueryHash(payload.query);
    return __assign(__assign({}, state), { countSteps: __assign(__assign({}, state.countSteps), (_a = {}, _a[countStepsKey] = __assign(__assign({}, state.countSteps[countStepsKey]), { pending: true, error: undefined }), _a)) });
});
reducer.on(getCountStepsSucceeded.type, function (state, payload) {
    var _a;
    var countStepsKey = getQueryHash(payload.query);
    return __assign(__assign({}, state), { countSteps: __assign(__assign({}, state.countSteps), (_a = {}, _a[countStepsKey] = __assign(__assign({}, state.countSteps[countStepsKey]), { pending: false, result: payload.result, error: undefined }), _a)) });
});
reducer.on(getCountStepsFailed.type, function (state, payload) {
    var _a;
    var countStepsKey = getQueryHash(payload.query);
    return __assign(__assign({}, state), { countSteps: __assign(__assign({}, state.countSteps), (_a = {}, _a[countStepsKey] = __assign(__assign({}, state.countSteps[countStepsKey]), { pending: false, error: payload.error }), _a)) });
});
export default reducer;
