import { getAccountContactInfo } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.api";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IAccount, IMember } from "@rentiohq/shared/dist/types/auth.types";
import { EContactInitialType } from "@rentiohq/shared/dist/types/contact.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import { getMembersWithOneOfRoles } from "@rentiohq/shared/dist/utils/roles.utils";
import {
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import { AccountList } from "@rentiohq/web-shared/dist/components/AccountList";
import Button from "@rentiohq/web-shared/dist/components/Button";
import React from "react";
import { ts } from "../../../../../../services";
import { PropertyRolesModal } from "../PropertyRolesModal";
import { ManagersInput } from "./ManagersInput";

interface IProps {
  property: IProperty;
  members: IMember<EPropertyMemberTypes>[];
  showActions?: boolean;
}

type modals = "ROLES";

export const adminRoles = [
  EPropertyMemberTypes.IndexationManager,
  EPropertyMemberTypes.FinancialManager,
  EPropertyMemberTypes.TechnicalManager,
];

export const PropertyManagersCard = (props: IProps) => {
  const { property, members, showActions } = props;

  const [modalVisibility, setModalVisibility] = React.useState<modals | null>(
    null,
  );
  const [showInput, setShowInput] = React.useState(false);

  const propertyAdmins = getMembersWithOneOfRoles(members, adminRoles);

  const handleModalClose = () => setModalVisibility(null);
  const showRolesModal = () => setModalVisibility("ROLES");

  const toggleInput = () => {
    setShowInput(showInput => !showInput);
  };

  const createSubtitle = (accountIds: number[]) => {
    const member = propertyAdmins.find(member =>
      accountIds.includes(member.account.id!),
    );
    if (!member) {
      return "";
    }

    return member
      .roles!.filter((role: any) => adminRoles.includes(role))
      .map(role => ts.role(role))
      .join(", ");
  };

  const hasManagers = propertyAdmins.length > 0;

  return (
    <>
      <Card
        heading={
          <DisplayText size="extraSmall" space="tight">
            {getLocalizedText("dashboard.card.property_management")}
          </DisplayText>
        }
        space={ESpacings.loose}
        actions={
          showActions
            ? [
                {
                  content: ts.propertyDashboardManagersPrimaryCTA(),
                  onClick: showRolesModal,
                },
                {
                  content: ts.propertyDashboardManagersAddContactAction(),
                  onClick: toggleInput,
                },
              ]
            : undefined
        }
      >
        <AccountList
          accounts={propertyAdmins.map(admin => admin.account)}
          getContactInfoForAccount={async (account: IAccount) =>
            (
              await getAccountContactInfo({
                propertyId: property.id,
                accountId: account.id,
              })
            ).data.data
          }
          initialContactType={EContactInitialType.Manager}
          getSubtitle={createSubtitle}
          mediaSize="medium"
          boxProps={{ py: 3 }}
        />
        {showInput && (
          <ManagersInput
            showActions={showActions}
            hasManagers={hasManagers}
            propertyAdmins={propertyAdmins}
            toggleInput={toggleInput}
          />
        )}
        {!hasManagers && (
          <Button onClick={showRolesModal} appearance="outline" isFullWidth>
            {ts.propertyDashboardManagersPrimaryCTA()}
          </Button>
        )}
      </Card>

      {modalVisibility === "ROLES" && (
        <PropertyRolesModal
          propertyId={property.id}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};
