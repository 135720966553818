import { EField } from "./schema.createChat.types";
export var getInitialValues = function (_a) {
    var _b, _c;
    var accountIds = _a.accountIds, chat = _a.chat, propertyId = _a.propertyId;
    if (chat) {
        return _b = {},
            _b[EField.Members] = chat.members.map(function (randomMember) { return randomMember.account.id; }),
            _b[EField.Name] = chat.members.length > 2 ? chat.name : undefined,
            _b[EField.Property] = chat.propertyId,
            _b;
    }
    return _c = {},
        _c[EField.Members] = accountIds,
        _c[EField.Property] = propertyId,
        _c;
};
