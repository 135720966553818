import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";
import { IPaymentOrder } from "@rentiohq/shared/dist/types/payment.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { OpenPaymentRequests } from "components/OpenPaymentRequests";
import { round } from "lodash";
import React from "react";
import { PaymentHistory } from "scenes/PaymentsOverview/PaymentHistory";
import CollectionFollowUp from "../components/CollectionFollowUp";
import { PayInsFollowUp } from "../components/PayInsFollowUp";
import { PaymentRequests } from "../components/PaymentRequests";
import { PropertyPaymentOrders } from "./components/PropertyPaymentOrders";

interface IProps {
  property: IProperty;
}

export const getAmounts = (paymentOrder: IPaymentOrder) => {
  const { isRepetitionDelayed, proRataAmount, amount, vat = 0 } = paymentOrder;
  const vatPercentage = vat / 100;

  let firstAmount: number | undefined = undefined;
  if (proRataAmount > 0) {
    if (isRepetitionDelayed) {
      firstAmount = +amount + +proRataAmount;
    } else {
      firstAmount = +proRataAmount;
    }
  }

  const amountWithVat = round(amount * (1 + vatPercentage), 2);

  const firstAmountWithVat = firstAmount
    ? round(firstAmount * (1 + vatPercentage), 2)
    : undefined;

  return {
    vat: paymentOrder.vat,
    amount,
    amountVat: amountWithVat - amount,
    amountWithVat,
    firstAmount,
    firstAmountVat:
      firstAmount && firstAmountWithVat
        ? firstAmountWithVat - firstAmount
        : undefined,
    firstAmountWithVat,
  };
};

export const PaymentsOverview: React.FC<IProps> = ({ property }) => {
  // Redux
  const hasIbanisationForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Ibanisation,
  );

  // Render
  return (
    <>
      <PropertyPaymentOrders propertyId={property.id} />

      <CollectionFollowUp propertyId={property.id} />

      {hasIbanisationForBroker && <PayInsFollowUp propertyId={property.id} />}

      <OpenPaymentRequests propertyId={property.id} />

      <PaymentHistory propertyId={property.id} />

      <PaymentRequests property={property} />
    </>
  );
};
