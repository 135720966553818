var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { uiSchema as epcUiSchema, getSchema as getEpcSchema, } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo";
import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EPropertyEPCLabel } from "@rentiohq/shared/dist/types/property.types";
import { ts } from "../../services";
import { EField } from "./schema.indexContract.types";
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a, _b;
        var _c, _d;
        var epcSchema = getEpcSchema(extraData, initialValues)(defaultValues);
        var epcRequiredFields = (_c = epcSchema.required) !== null && _c !== void 0 ? _c : [];
        var epcProperties = (_d = epcSchema.properties) !== null && _d !== void 0 ? _d : {};
        return {
            required: __spreadArray([EField.CurrentPrice], epcRequiredFields, true),
            properties: __assign(__assign((_a = {}, _a[EField.CurrentPrice] = {
                default: getValue(EField.CurrentPrice, initialValues, defaultValues),
            }, _a), epcProperties), (_b = {}, _b[EField.ForceFullIndexation] = {
                type: "boolean",
                default: getValue(EField.ForceFullIndexation, initialValues, defaultValues),
                // @ts-ignore
                showIf: function (formData) {
                    return !!formData[EEpcField.Label] &&
                        [
                            EPropertyEPCLabel.D,
                            EPropertyEPCLabel.E,
                            EPropertyEPCLabel.F,
                            EPropertyEPCLabel.G,
                            EPropertyEPCLabel.Unknown,
                            EPropertyEPCLabel.NoEPCAvailable,
                        ].includes(formData[EEpcField.Label]);
                },
            }, _b)),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a, _b;
    var epcSchema = epcUiSchema(extraData, initialValues);
    return __assign(__assign((_a = { "ui:field": ERentioFormField.Grid, grid: {
                row: [
                    { col: { width: 1, children: [EField.CurrentPrice] } },
                    {
                        col: {
                            width: [1, 1 / 3],
                            children: [EEpcField.Label],
                        },
                    },
                    {
                        col: {
                            width: [1, 1 / 3],
                            children: [EEpcField.Value],
                        },
                    },
                    {
                        col: {
                            width: [1, 1 / 3],
                            children: [EEpcField.ExpirationDate],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.ForceFullIndexation],
                        },
                    },
                ],
            } }, _a[EField.CurrentPrice] = {
        "ui:field": ERentioFormField.Number,
        title: ts.contractIndexPriceCurrent(),
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        readOnly: true,
    }, _a), epcSchema), (_b = {}, _b[EField.ForceFullIndexation] = {
        "ui:field": ERentioFormField.Switch,
        label: getLocalizedText("contract.index.force_full"),
    }, _b));
};
export var getValidate = function (_) { return function (formData, errors) {
    return errors;
}; };
