import ChatSendBar from "@rentiohq/shared-frontend/dist/components/components/ChatSendBar";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { openSocket } from "@rentiohq/shared-frontend/dist/redux/socket/socket.actions";
import { ISocketReadyState } from "@rentiohq/shared-frontend/dist/redux/socket/socket.types";
import { generateRef } from "@rentiohq/shared-frontend/dist/redux/socket/socket.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types/types";
import {
  getDetailStart,
  getMessagesStart,
  sendMessageStart,
} from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { Loading } from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { showDropErrors } from "@rentiohq/web-shared/dist/utils/file";
import { triggerLiveChat } from "@rentiohq/web-shared/dist/utils/zendesk.utils";
import React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  attachmentToBase64,
  canPreviewAttachment,
  getAttachmentIcon,
} from "../../utils/attachment.utils";
import * as S from "./ChatConversation.styled";
import { ChatArchivedCard } from "./components/ChatArchivedCard/ChatArchivedCard";
import { ChatMessages } from "./components/ChatMessages";
import { ConversationHeader } from "./components/ConversationHeader";

interface IProps {
  chatId: number;
  actions?: IAction[];
}

export const ChatConversation: React.FC<IProps> = props => {
  const { chatId, actions } = props;

  let openSocketInterval: any | undefined;
  let chatSendBar = React.useRef();
  let messagesContainer =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const [attachment, setAttachment] = React.useState<any>();
  const [base64, setBase64] = React.useState<string>();
  const [sendingMessageRef, setSendingMessageRef] = React.useState<any>();
  const [forceIsSending, setForceIsSending] = React.useState<boolean>(false);

  // Selector hooks

  const chat = useSelector((state: IPartialRootState) =>
    chatSelectors.getDetail(state, props.chatId),
  );

  const { readyState: socketReadyState, pendingRefs = [] } = useSelector(
    (state: IPartialRootState) => state.socket,
  );

  const prevPendingRefs = usePrevious(pendingRefs);
  const dispatch = useDispatch();

  let location = useLocation();

  React.useEffect(() => {
    if (location.state) {
      const state = location.state as any;

      const keys = Object.keys(state);

      if (
        keys.includes("newChat") &&
        socketReadyState &&
        [ISocketReadyState.Closing, ISocketReadyState.Closed].includes(
          socketReadyState,
        )
      ) {
        dispatch(openSocket.actions.start({}));
      } else if (keys.includes("updateChat") && props.chatId) {
        dispatch(getDetailStart.getAction({ id: props.chatId }));
      }
    }
  }, [location]);

  React.useEffect(() => {
    openSocketInterval = setInterval(() => {
      if (
        socketReadyState &&
        [ISocketReadyState.Open, ISocketReadyState.Connecting].includes(
          socketReadyState,
        )
      ) {
        return;
      }

      dispatch(openSocket.actions.start({}));
    }, 3000);

    return () => {
      clearInterval(openSocketInterval);
    };
  }, [socketReadyState]);

  React.useEffect(() => {
    if (socketReadyState !== ISocketReadyState.Open) {
      return;
    }

    dispatch(
      getMessagesStart.getAction({
        chatId,
        shouldReset: true,
        ref: generateRef(),
      }),
    );
  }, [chatId, socketReadyState]);

  React.useEffect(() => {
    if (!chat) {
      dispatch(getDetailStart.getAction({ id: chatId }));
    }

    clearNewMessage();
  }, [chatId]);

  React.useEffect(() => {
    const wasSending = getIsSending(prevPendingRefs);
    const isSending = getIsSending(pendingRefs);

    if (wasSending && !isSending) {
      clearNewMessage();
    }
  }, [pendingRefs, prevPendingRefs]);

  const clearNewMessage = () => {
    if (!chatSendBar.current) {
      return;
    }

    // @ts-ignore
    chatSendBar.current.reset();

    setForceIsSending(false);
    setSendingMessageRef(undefined);
    setAttachment(undefined);
  };

  const handlePressMessage = ({ attachment }: any) => {
    if (attachment) {
      utils.general.openWindow(attachment.url);
    }
  };

  const handlePressSendMessage = async (text: string) => {
    const ref = generateRef();

    setSendingMessageRef(ref);
    setForceIsSending(true);

    dispatch(
      sendMessageStart.getAction({
        message: {
          chatId,
          text: text.trim(),
          attachment,
        },
        ref,
      }),
    );

    setForceIsSending(false);
  };

  const handlePressRemoveAttachment = () => {
    setAttachment(undefined);
  };

  const getIsSending: any = (pRefs: number[] = []) =>
    forceIsSending || pRefs.includes(sendingMessageRef || -1);

  const handleDrop = async (
    acceptedFiles: any[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    showDropErrors({ acceptedFiles, fileRejections, event });

    if (acceptedFiles.length === 0) {
      return;
    }

    try {
      const fileBase64 = (await attachmentToBase64(acceptedFiles[0])) as string;

      setBase64(fileBase64);
      setAttachment(acceptedFiles[0]);
    } catch (unknownError) {
      const error = unknownError as any;
      throw new Error(error);
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    maxSize: CONFIG.UPLOAD_MAX_FILE_SIZE,
  });
  const {
    onClick: _,
    onKeyDown: __,
    ...rootPropsWithoutKeyDown
  } = getRootProps();

  const handlePressUrl = (url: string) => {
    utils.general.openWindow(url);
  };

  if (!chat?.id) {
    return <Loading />;
  }

  const renderChatSendBar = () => {
    const hasSupportContact = !!chat.members.find(
      (member: { account: { firstname: any; lastname: any } }) =>
        `${member.account.firstname}`.toLowerCase() === "support" &&
        `${member.account.lastname}`.toLowerCase() === "rentio",
    );

    if (hasSupportContact) {
      return (
        <S.ChatSupportBar>
          <div>{getLocalizedText("chat.member.support_disabled")}</div>

          <Spacer weight={ESpacerWeight.W08} />

          <Button appearance="link" onClick={triggerLiveChat}>
            {getLocalizedText("help_menu.action.chat")}
          </Button>
        </S.ChatSupportBar>
      );
    }

    const isDeletedFromChat = chat.roles.length === 0;
    if (isDeletedFromChat) {
      return (
        <S.ChatRemovedBar>
          {getLocalizedText("chat.member.removed_self")}
        </S.ChatRemovedBar>
      );
    }

    return (
      <S.ChatSendBar>
        <ChatSendBar
          // @ts-ignore
          ref={chatSendBar}
          chatId={chatId}
          isSending={getIsSending(pendingRefs)}
          onPressSend={handlePressSendMessage}
          textInputProps={{
            multiline: true,
            blurOnSubmit: true,
          }}
          onPressSelectAttachment={open}
          onPressRemoveAttachment={handlePressRemoveAttachment}
          attachmentPreviewText={
            attachment ? getAttachmentIcon(attachment) : undefined
          }
          attachmentPreviewData={
            attachment && canPreviewAttachment(attachment)
              ? { uri: base64 }
              : undefined
          }
        />
      </S.ChatSendBar>
    );
  };

  const isActive = !chat.archivedAt;

  return (
    <ChatArchivedCard chat={chat}>
      <S.Wrapper
        {...rootPropsWithoutKeyDown}
        isDragActive={isActive && isDragActive}
      >
        {isActive && <input {...getInputProps()} />}

        {chat && <ConversationHeader chat={chat} actions={actions} />}

        {chat && (
          <S.ChatMessages ref={messagesContainer}>
            <ChatMessages
              chatId={chatId}
              chat={chat}
              onPressMessage={handlePressMessage}
              onPressUrl={handlePressUrl}
            />
          </S.ChatMessages>
        )}

        {!chat.archivedAt && renderChatSendBar()}
      </S.Wrapper>
    </ChatArchivedCard>
  );
};
