import {
  EPaymentRequestStatus,
  IPaymentOrder,
} from "@rentiohq/shared/dist/types/payment.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import {
  DisplayText,
  ESpacings,
  Grid,
  Icon,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { getPaymentOrderTypeById } from "../../../../../../../scenes/Properties/scenes/Payments/helpers/paymentOrder";
import { getAmounts } from "../../../../../../../scenes/Properties/scenes/Payments/scenes/PaymentsOverview";
import { ts } from "../../../../../../../services";
import { createTitle } from "../../../../../../../utils/payment";

export const PaymentOrderRow: React.FC<{ paymentOrder: IPaymentOrder }> = ({
  paymentOrder,
}) => {
  const { paymentRequests } = paymentOrder;

  const paymentOrderType = getPaymentOrderTypeById(paymentOrder.type!);
  const paymentRequest = paymentRequests?.[0];

  if (!paymentRequest) {
    const amounts = getAmounts(paymentOrder);
    return (
      <ResourceListItem
        boxProps={{ p: ESpacings.tight }}
        item={paymentOrder}
        media={
          <Icon source={paymentOrderType?.icon || "billStack"} size="large" />
        }
        mediaSize="medium"
      >
        <Grid alignItems="center" justifyContent="space-between">
          <Grid.Item>
            <div>
              {createTitle({ paymentOrders: [paymentOrder], paymentRequest })}
            </div>
            <TextStyle variation="subdued" element="div">
              {ts.paymentOverviewPaymentRequestMeta({
                paymentOrder,
              })}
            </TextStyle>
          </Grid.Item>
          <Grid.Item>
            <DisplayText size="medium" element="div">
              <TextStyle
                variation="code"
                element="div"
                style={{ textAlign: "right" }}
              >
                {formatCurrency(amounts.amountWithVat)}
              </TextStyle>
            </DisplayText>
          </Grid.Item>
        </Grid>
      </ResourceListItem>
    );
  }

  return (
    <ResourceListItem
      boxProps={{ p: ESpacings.tight }}
      item={paymentRequest}
      media={
        <Icon source={paymentOrderType?.icon || "billStack"} size="large" />
      }
      mediaSize="medium"
    >
      <Grid alignItems="center" justifyContent="space-between">
        <Grid.Item>
          <div>
            {createTitle({ paymentOrders: [paymentOrder], paymentRequest })}
          </div>
          <TextStyle variation="subdued" element="div">
            {ts.paymentOverviewPaymentRequestMeta({
              paymentOrder,
            })}
          </TextStyle>
          {paymentRequest.status === EPaymentRequestStatus.Paid ? (
            <TextStyle variation="positive" element="div">
              {ts.paymentRequestPaidOnTimeLabel()}
            </TextStyle>
          ) : (
            <TextStyle variation="warn" element="div">
              {ts.paymentRequestNotYetPaidLabel()}
            </TextStyle>
          )}
        </Grid.Item>
        <Grid.Item>
          <DisplayText size="medium" element="div">
            <TextStyle
              variation="code"
              element="div"
              style={{ textAlign: "right" }}
            >
              {formatCurrency(paymentRequest.originalAmount)}
            </TextStyle>
          </DisplayText>
        </Grid.Item>
      </Grid>
    </ResourceListItem>
  );
};
