import { EPaymentOrderType } from "@rentiohq/shared/dist/types/payment.types";
import { ERentioFormField } from "../../components/components/Form";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField as EFieldCreatePayment } from "../createPayment/schema.createPayment.types";
import { customContactsValueSelector, getDefaultPayerPayeeValue, } from "../createPayment/schema.createPayment.utils";
import { EField } from "./schema.createContract.types";
import { customContactsSelectorPayments, getCreateContractCompanyFieldsSchema, getCreateContractContactFieldsSchema, getNavigationTitle, getSubtitlePayments, } from "./schema.createContract.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.PaymentOrderCreatePayer],
            properties: (_a = {},
                _a[EField.Header + "10"] = {
                    default: undefined,
                },
                _a[EField.PaymentOrderCreatePayer] = {
                    default: getDefaultPayerPayeeValue({
                        key: EFieldCreatePayment.Payer,
                        initialValues: initialValues,
                        defaultValues: defaultValues,
                        type: EPaymentOrderType.Rent,
                    }),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("contract_info.add.payment_orders.payer.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "10"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function () {
                return getLocalizedText("payments.create.payer.heading", {
                    type: getLocalizedText("payment.payment_order.type.".concat(EPaymentOrderType.Rent).toLowerCase()),
                });
            },
        },
        _a[EField.PaymentOrderCreatePayer] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.payer.heading", {
                    type: getLocalizedText("payment.payment_order.type.".concat(EPaymentOrderType.Rent).toLowerCase()),
                });
            },
            selectContactFromListCtaTitle: getLocalizedText("payment.payment_order.payer.select_other.cta"),
            addCtaLabel: getLocalizedText("payment.payment_order.payer.other.label"),
            isMultiSelect: false,
            asOptionList: true,
            getRequiredContactFieldsSchema: getCreateContractContactFieldsSchema,
            getRequiredCompanyFieldsSchema: getCreateContractCompanyFieldsSchema,
            // Custom selectors & actions
            valueSelector: function (state, selectedAccountIds) {
                return customContactsValueSelector({
                    state: state,
                    propertyId: extraData.property.id,
                    selectedAccountIds: selectedAccountIds,
                });
            },
            customGetSubtitleSelector: function (state, formData, item) {
                if (formData === void 0) { formData = {}; }
                return getSubtitlePayments({
                    state: state,
                    property: extraData.property,
                    item: item,
                    customOwners: formData[EField.Owners],
                    customTenants: formData[EField.Tenants],
                    customParents: formData[EField.Parents],
                });
            },
            customExtraAccountsSelector: function (state, formData) {
                if (formData === void 0) { formData = {}; }
                return customContactsSelectorPayments({
                    payerType: "payer",
                    state: state,
                    property: extraData.property,
                    customOwners: formData[EField.Owners],
                    customTenants: formData[EField.Tenants],
                    customParents: formData[EField.Parents],
                });
            },
            customIsFetchingSelector: function (state) { return false; },
            customGetContactsActions: [],
        },
        _a);
};
