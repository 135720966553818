import api from "../../utils/api/api.utils";
export var FETCH_LIMIT = 20;
export var requestInsurance = function (insuranceExternalAccessCode) {
    return api.post("/insurance/".concat(insuranceExternalAccessCode, "/publish"));
};
export var requestMoveService = function (data) {
    return api.post("/move-requests", {
        data: data,
        customTimeout: 60000,
    });
};
