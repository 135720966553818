import { Box } from "@rebass/grid";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import {
  formatDate,
  formatDateAndTime,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ITask } from "@rentiohq/shared/dist/types/task.types";
import { Card } from "@rentiohq/web-shared/dist/components";
import RepairLozenge from "@rentiohq/web-shared/dist/components/RepairLozenge/RepairLozenge";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ts from "../../../../../../services/translationService";
import { RepairInfoItem } from "./RepairInfoItem";
import { RepairTrackingLink } from "./RepairTrackingLink";

export interface IRepairInfo {
  task: ITask;
}

export const RepairInfo = ({ task }: IRepairInfo) => {
  const dispatch = useDispatch();
  const {
    payerContactId,
    repairContactId,
    incidentDate,
    damageType,
    status,
    createdAt,
  } = task;

  const payerContact = useSelector((state: IPartialRootState) =>
    contactSelectors.getContact(state, payerContactId),
  );

  const repairContact = useSelector((state: IPartialRootState) =>
    contactSelectors.getContact(state, repairContactId),
  );

  useEffect(() => {
    if (payerContactId && repairContactId) {
      dispatch(
        contactActions.getContacts.actions.start({
          refetch: false,
          filterData: {
            where: {
              id: { inq: [payerContactId, repairContactId] },
            },
          },
        }),
      );
    }
  }, [payerContactId, repairContactId]);

  return (
    <Card
      heading={getLocalizedText("task.repair.service.keypoint.status")}
      subHeading={getLocalizedText("system.created_at", {
        date: formatDateAndTime(createdAt),
      })}
      actions={[
        {
          content: ts.system("edit"),
          url: `/tasks/${task.id}/edit?keypoint=1`,
        },
      ]}
    >
      {status && (
        <RepairLozenge
          text={getLocalizedText(
            `task.repair.keypoint.status.${status.toLowerCase()}`,
          )}
          status={status}
        />
      )}

      <Box style={{ display: "flex", flexDirection: "column" }}>
        <RepairInfoItem
          title={getLocalizedText("task.repair.keypoint.incident_date")}
          content={incidentDate ? formatDate(incidentDate) : undefined}
        />
        <RepairInfoItem
          title={getLocalizedText("task.repair.keypoint.damage_type")}
          content={
            damageType
              ? getLocalizedText(`task.damage_type.${damageType.toLowerCase()}`)
              : undefined
          }
        />
        <RepairInfoItem
          title={getLocalizedText("task.repair.keypoint.contact_person")}
          content={repairContact ? getName(repairContact) : undefined}
        />
        <RepairInfoItem
          title={getLocalizedText("task.repair.keypoint.payer")}
          content={payerContact ? getName(payerContact) : undefined}
        />
        {task.keypointCaseId && (
          <RepairTrackingLink taskCaseId={task.keypointCaseId} />
        )}
      </Box>
    </Card>
  );
};
