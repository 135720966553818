import { Box } from "@rebass/grid";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import {
  addMonths,
  startOfDay,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { Lozenge } from "@rentiohq/web-shared/dist/components";
import { spacing } from "@rentiohq/web-theme";
import { memo } from "react";
import { STATUS_IS_ENDING_WHEN_IN_MONTHS } from "../../ContractCard.utils";
import {
  ChipAppearance,
  EContractChipColors,
  EContractStatus,
} from "./contractStatusChip.types";

interface IProps {
  contract?: IContract;
}

const NOW = new Date();
const START_OF_DAY = startOfDay(NOW);

const getAppearance = (status: EContractStatus): ChipAppearance => {
  const common = {
    statusText: getLocalizedText(`contract.status.${status}`),
    textColor: "white",
  };

  switch (status) {
    case EContractStatus.Ending:
    case EContractStatus.Finished:
      return {
        ...common,
        color: EContractChipColors.Gray,
      };
    case EContractStatus.Running:
      return {
        ...common,
        color: EContractChipColors.Green,
      };
    case EContractStatus.Upcoming:
      return {
        ...common,
        color: EContractChipColors.Blue,
      };
    case EContractStatus.NoContract:
      return {
        ...common,
        color: EContractChipColors.Red,
      };
  }
};

const getContractStatus = (contract?: IContract): EContractStatus => {
  if (!contract) return EContractStatus.NoContract;
  if (contract.stopDate && new Date(contract.stopDate) <= NOW)
    return EContractStatus.Finished;
  if (new Date(contract.startDate) > NOW) return EContractStatus.Upcoming;

  const isEnding =
    contract.stopDate &&
    new Date(contract.stopDate) >= NOW &&
    new Date(contract.stopDate) <=
      addMonths(START_OF_DAY, STATUS_IS_ENDING_WHEN_IN_MONTHS);

  return isEnding ? EContractStatus.Ending : EContractStatus.Running;
};

const ContractStatusChip = ({ contract }: IProps) => {
  const status = getContractStatus(contract);
  const appearance = getAppearance(status);
  return (
    <>
      <Box padding={spacing("extraTight")}>
        <Lozenge
          customTextColor={appearance.textColor}
          customBackgroundColor={appearance.color}
        >
          {appearance.statusText}
        </Lozenge>
      </Box>
    </>
  );
};

export const MemoizedContractStatusChip = memo(ContractStatusChip);
