export var EDocumentPackageStatus;
(function (EDocumentPackageStatus) {
    // Custom statuses
    EDocumentPackageStatus["New"] = "NEW";
    EDocumentPackageStatus["PDFReady"] = "PDF_READY";
    EDocumentPackageStatus["WorkerFailed"] = "WORKER_FAILED";
    // Connective eSignatures statuses
    EDocumentPackageStatus["Draft"] = "Draft";
    EDocumentPackageStatus["Pending"] = "Pending";
    EDocumentPackageStatus["Finished"] = "Finished";
    EDocumentPackageStatus["Rejected"] = "Rejected";
    EDocumentPackageStatus["Revoked"] = "Revoked";
    EDocumentPackageStatus["Expired"] = "Expired";
    EDocumentPackageStatus["Failed"] = "Failed";
})(EDocumentPackageStatus || (EDocumentPackageStatus = {}));
export var EDocumentPackageSignerStatus;
(function (EDocumentPackageSignerStatus) {
    EDocumentPackageSignerStatus["Draft"] = "Draft";
    EDocumentPackageSignerStatus["InProgress"] = "Inprogress";
    EDocumentPackageSignerStatus["Available"] = "Available";
    EDocumentPackageSignerStatus["Finished"] = "Finished";
    EDocumentPackageSignerStatus["Rejected"] = "Rejected";
    EDocumentPackageSignerStatus["Failed"] = "Failed";
})(EDocumentPackageSignerStatus || (EDocumentPackageSignerStatus = {}));
export var EDocumentPackageRoles;
(function (EDocumentPackageRoles) {
    EDocumentPackageRoles["Admin"] = "ADMIN";
    EDocumentPackageRoles["Viewer"] = "VIEWER";
})(EDocumentPackageRoles || (EDocumentPackageRoles = {}));
export var EDocumentPackageTypes;
(function (EDocumentPackageTypes) {
    // TODO: Add property inspection report & addendum (backend needs to allow this)
    EDocumentPackageTypes["Contract"] = "CONTRACT";
    EDocumentPackageTypes["RentDeposit"] = "RENT_DEPOSIT";
    EDocumentPackageTypes["RentDepositRelease"] = "RENT_DEPOSIT_RELEASE";
    EDocumentPackageTypes["RentalAssignment"] = "RENTAL_ASSIGNMENT";
    EDocumentPackageTypes["Document"] = "DOCUMENT";
})(EDocumentPackageTypes || (EDocumentPackageTypes = {}));
