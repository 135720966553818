import { ACTIVITY_SUBTYPES } from "@rentiohq/shared/dist/types/activity.types";
import { ILegalCase } from "@rentiohq/shared/dist/types/legalCase.types";
import {
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import { memo } from "react";
import { ts } from "../../../../../../services";

interface ILegalCaseHistoryProps {
  legalCase: ILegalCase;
}
const LegalCaseHistory = ({ legalCase }: ILegalCaseHistoryProps) => {
  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`legal-case-history-${legalCase.id}`}
        filter={{
          where: {
            legalCaseId: legalCase.id,
            contractId: legalCase.contractId,
            subtype: {
              inq: [
                ACTIVITY_SUBTYPES.LEGAL_CASE.ACTIVATED,
                ACTIVITY_SUBTYPES.LEGAL_CASE.CLOSED,
                ACTIVITY_SUBTYPES.LEGAL_CASE.CREATED,
                ACTIVITY_SUBTYPES.LEGAL_CASE.DELETED,
                ACTIVITY_SUBTYPES.LEGAL_CASE.PDF_GENERATED,
                ACTIVITY_SUBTYPES.LEGAL_CASE.SEND_TO_LAWYER,
                ACTIVITY_SUBTYPES.LEGAL_CASE.UPDATED,
              ],
            },
          },
        }}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};

export default memo(LegalCaseHistory);
