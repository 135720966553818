import { EField } from "./schema.createNote.types";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { ERentioFormField } from "../../components/components/Form";
import { Platform } from "react-native";
export var getSchema = function (_, initialValues) {
    return function (defaultValues) {
        var _a;
        return ({
            required: _.noteId ? [] : [EField.Content],
            properties: (_a = {},
                _a[EField.Content] = {
                    default: getValue(EField.Content, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:navigationTitle": extraData.noteId
                ? getLocalizedText("task.note_edit")
                : getLocalizedText("task.note_add")
        },
        _a[EField.Content] = {
            "ui:field": ERentioFormField.Text,
            placeholder: getLocalizedText("task.note_add.placeholder"),
            title: Platform.OS !== "web"
                ? getLocalizedText("template.content.label")
                : undefined,
            multiline: true,
            autoFocus: true,
        },
        _a;
};
