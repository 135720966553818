var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { renderActions } from "../Button";
import { ESpacings } from "../Grid";
import * as S from "./Card.styled";
import Header from "./components/Header";
var Card = /** @class */ (function (_super) {
    __extends(Card, _super);
    function Card() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Card.prototype.render = function () {
        var _a = this.props, heading = _a.heading, subHeading = _a.subHeading, children = _a.children, actions = _a.actions, primaryActions = _a.primaryActions, footerActions = _a.footerActions, appearance = _a.appearance, _b = _a.variation, variation = _b === void 0 ? "default" : _b, align = _a.align, _c = _a.hasBorder, hasBorder = _c === void 0 ? true : _c, _d = _a.hasSections, hasSections = _d === void 0 ? false : _d, _e = _a.space, space = _e === void 0 ? ESpacings.loose : _e, footerContent = _a.footerContent, icon = _a.icon, iconPosition = _a.iconPosition, iconSize = _a.iconSize, onClick = _a.onClick;
        var content = hasSections
            ? children
            : children && _jsx(Card.Section, __assign({ space: space }, { children: children }));
        var headerMarkup = heading ? (_jsx(Header, { space: space, heading: heading, subHeading: subHeading, actions: actions, primaryActions: primaryActions, icon: icon, iconPosition: iconPosition, iconSize: iconSize })) : null;
        var footerMarkup = footerActions && !isEmpty(footerActions) ? (_jsx(Box, __assign({ px: space, pb: space }, { children: renderActions(footerActions) }))) : null;
        return (_jsxs(S.StyledCard, __assign({ appearance: appearance, variation: variation, align: align, hasBorder: hasBorder, onClick: onClick }, { children: [headerMarkup, children ? (content) : (_jsx(Box, { mb: footerContent ? ESpacings.tight : ESpacings.base })), footerMarkup, footerContent && (_jsx(S.Section, __assign({ px: space, py: ESpacings.base }, { children: footerContent })))] })));
    };
    Card.Section = function (_a) {
        var children = _a.children, _b = _a.space, space = _b === void 0 ? ESpacings.loose : _b, boxProps = _a.boxProps, _c = _a.hasBorder, hasBorder = _c === void 0 ? true : _c;
        var getTopSpace = function () {
            if (!hasBorder) {
                return 0;
            }
            return space;
        };
        return (_jsx(S.Section, __assign({}, (boxProps || { p: space, pt: getTopSpace() }), { className: hasBorder ? "" : "no-border" }, { children: children })));
    };
    return Card;
}(React.PureComponent));
export default Card;
