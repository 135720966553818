var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// https://github.com/auth0-blog/react-rbac/blob/master/src/components/Can.js#L17
import { rules } from "../../services/rules";
export var check = function (roles, action) {
    var permissions = Object.keys(rules).some(function (role) { return roles.includes(role); });
    if (!permissions) {
        return false;
    }
    var staticPermissions = roles.reduce(function (currentPermissions, role) {
        return __spreadArray(__spreadArray([], currentPermissions, true), (rules[role] || []), true);
    }, []);
    return staticPermissions === null || staticPermissions === void 0 ? void 0 : staticPermissions.includes(action);
};
