var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useNavigate } from "react-router-dom";
import * as t from "../../services/translationService";
import Button from "../Button";
import NoResults from "../NoResults";
import RentioInternalRenderer from "../RentioInternalRenderer";
var Error = function (props) {
    var children = props.children, _a = props.errors, errors = _a === void 0 ? [] : _a, backLink = props.backLink, errorHeading = props.errorHeading, _b = props.showBackLink, showBackLink = _b === void 0 ? true : _b;
    var navigate = useNavigate();
    // Event handlers
    var handleGoBack = function () {
        if (backLink) {
            navigate(backLink, { replace: true });
            return;
        }
        navigate(-1);
    };
    // Render
    if (errors.length === 0) {
        return null;
    }
    var axiosError = errors[0];
    if (axiosError.isAxiosError) {
        return (_jsxs(NoResults, __assign({ iconColor: "red", 
            // TODO: Get icon per response code
            icon: "questionMark", heading: getMessageForError(axiosError) }, { children: [_jsx(RentioInternalRenderer, { items: { error: errors[0] } }), showBackLink && (_jsx(Button, __assign({ appearance: "plain", onClick: handleGoBack }, { children: t.system("back_to_previous") }))), children] })));
    }
    return (_jsxs(NoResults, __assign({ iconColor: "red", icon: "questionMark", heading: errorHeading ? errorHeading : getLocalizedText("general.error") }, { children: [_jsx(RentioInternalRenderer, { items: { error: errors[0] } }), showBackLink && (_jsx(Button, __assign({ appearance: "plain", onClick: handleGoBack }, { children: t.system("back_to_previous") }))), children] })));
};
export default Error;
