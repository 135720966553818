export var EField;
(function (EField) {
    EField["AccessScope"] = "accessScope";
    EField["Name"] = "name";
    EField["Type"] = "type";
    EField["Tags"] = "tags";
    EField["Country"] = "country";
    EField["Region"] = "region";
    EField["AvailableFrom"] = "availableFrom";
    EField["AvailableTo"] = "availableTo";
})(EField || (EField = {}));
