import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ERentDepositStatus } from "@rentiohq/shared/dist/types/rentDeposit.types";
import {
  Error,
  ESpacings,
  Grid,
  Icon,
  Loading,
  Lozenge,
  Page,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { ERentDepositStageSimple } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import {
  getSimpleStage,
  getStatusVariation,
} from "@rentiohq/web-shared/dist/utils/rentDeposit";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { showJudicialReleaseModal } from "scenes/FollowUp/RentDeposits/RentDeposits";
import { ts } from "../../../../services";
import { RentDepositHistory } from "./components/RentDepositHistory";
import { RentDepositInfo } from "./components/RentDepositInfo";
import { ERentDepositTabs, IRentDepositDetailProps } from "./RentDeposit.types";
import {
  canCancelRentalDeposit,
  canCancelRentalDepositRelease,
} from "./RentDeposit.util";
const RentDeposit: React.FC<IRentDepositDetailProps> = ({
  rentDepositId,
  onDrawerClose,
}) => {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState<ERentDepositTabs>(ERentDepositTabs.Info);
  const isCancellingRentDeposit = useSelector(
    (state: IRootStore) => state.rentDeposit.isCancellingRentDeposit,
  );
  const cancelRentDepositErrors = useSelector(
    (state: IRootStore) => state.rentDeposit.cancelRentDepositError,
  );
  const prevIsCancellingRentDeposit = usePrevious(isCancellingRentDeposit);
  const rentDeposit = useSelector((state: IRootStore) => {
    if (
      !state.rentDeposit.rentDepositsById[rentDepositId] ||
      state.rentDeposit.rentDepositsById[rentDepositId].isFetching
    ) {
      return;
    }

    return state.rentDeposit.rentDeposits[rentDepositId];
  });
  const rentDepositFetchError = useSelector(
    (state: IRootStore) =>
      state.rentDeposit.rentDepositsById[rentDepositId]?.fetchError,
  );
  const hasCancelledRentDeposit =
    !isCancellingRentDeposit &&
    !!prevIsCancellingRentDeposit &&
    !cancelRentDepositErrors;

  React.useEffect(() => {
    if (hasCancelledRentDeposit) {
      onDrawerClose();
    }
  }, [hasCancelledRentDeposit, onDrawerClose]);

  const handleCancelDepositClick = () => {
    showCancelConfirm({
      title: getLocalizedText("rent_deposit.cancel.action.confirm.title"),
      primaryActionTitle: ts.rentDepositCancelAction(),
    });
  };

  const handleCancelDepositReleaseClick = () => {
    showCancelConfirm({
      title: getLocalizedText(
        "rent_deposit.cancel.release.action.confirm.title",
      ),
      primaryActionTitle: ts.rentDepositCancelReleaseAction(),
    });
  };

  const showCancelConfirm = ({
    title,
    primaryActionTitle,
  }: {
    title: string;
    primaryActionTitle: string;
  }) => {
    confirm({
      title,
      primaryActions: [
        {
          title: primaryActionTitle,
          onPress: () => {
            dispatch(
              rentDepositActions.cancelRentDeposit.actions.start({
                rentDepositId,
              }),
            );
          },
        },
      ],
    });
  };

  React.useEffect(() => {
    dispatch(
      rentDepositActions.getRentDeposit.actions.start({
        rentDepositId,
      }),
    );
  }, []);

  if (rentDepositFetchError) {
    console.error({ error: rentDepositFetchError });
    return <Error errors={[rentDepositFetchError]} />;
  }

  if (!rentDeposit) {
    return <Loading />;
  }

  const canCancelDeposit = canCancelRentalDeposit(rentDeposit);
  const canCancelRelease = canCancelRentalDepositRelease(rentDeposit);

  const simpleStage = getSimpleStage(rentDeposit.status);
  const signerCounts =
    simpleStage !== ERentDepositStageSimple.Out
      ? `${rentDeposit.signerInSigned}/${rentDeposit.signerInTotal}`
      : `${rentDeposit.signerOutSigned}/${rentDeposit.signerOutTotal}`;
  const signingStatuses = [
    ERentDepositStatus.OpenToSign,
    ERentDepositStatus.ReleaseToSign,
    ERentDepositStatus.New,
  ];

  // const subtitle = (
  //   <Stack spacing="tight">
  //     <Stack.Item>
  //       <Lozenge isBold key={`${rentDeposit.id}-signature-status`} appearance={getSignatureStatusVariation(signatureStatus)}>
  //         {ts.followUpRentDepositsFilterSignatureStatusLabel({ extra: { key: signatureStatus } })}:
  //         {simpleStage !== ERentDepositStageSimple.Out
  //           ? `
  //                     ${rentDeposit.signerInSigned}/${rentDeposit.signerInTotal}
  //                     `
  //           : `${rentDeposit.signerOutSigned}/${rentDeposit.signerOutTotal}`}
  //       </Lozenge>
  //     </Stack.Item>
  //     <Stack.Item>
  //       <Lozenge isBold key={`${rentDeposit.id}-payment-status`} appearance={getPaymentStatusVariation(paymentStatus)}>
  //         {ts.followUpRentDepositsFilterPaymentStatusLabel({ extra: { key: paymentStatus } })}
  //       </Lozenge>
  //     </Stack.Item>
  //   </Stack>
  // )
  const subtitle = (
    <Lozenge
      isBold
      key={`${rentDeposit.id}-payment-status`}
      appearance={getStatusVariation(rentDeposit.status)}
    >
      {ts.followUpRentDepositsFilterStatusLabel({
        extra: { key: rentDeposit.status },
      })}
      {signingStatuses.includes(rentDeposit.status) && `: ${signerCounts}`}
    </Lozenge>
  );

  const metadata = (
    <div>
      {rentDeposit.property && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon
                source={utils.properties.getIcon(
                  rentDeposit.property.typeId || 0,
                )}
                size="small"
              />
            </Grid.Item>
            <Grid.Item>
              <Button
                appearance="link"
                url={`/properties/${rentDeposit.property.id}`}
              >
                {formatAddress(rentDeposit.property)}
              </Button>
            </Grid.Item>
          </Grid>
        </>
      )}

      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="calendar" size="small" />
        </Grid.Item>
        <Grid.Item>
          {ts.system("drawn_up_at")}:{" "}
          {utils.date.format(rentDeposit.createdAt, "dd/MM/yyyy")}
        </Grid.Item>
      </Grid>
    </div>
  );

  const handleInfoTabClick = () => {
    setTab(ERentDepositTabs.Info);
  };

  const handleHistoryTabClick = () => {
    setTab(ERentDepositTabs.History);
  };

  let tabs = [
    {
      name: "dashboard",
      permission: "propertyDashboard:visit",
      content: ts.paymentRequestDetailTabsInfo(),
      onClick: handleInfoTabClick,
      isActive: tab === ERentDepositTabs.Info,
    },
    {
      name: "payments",
      permission: "propertyPayments:visit",
      content: ts.paymentRequestDetailTabsHistory(),
      onClick: handleHistoryTabClick,
      isActive: tab === ERentDepositTabs.History,
    },
  ];

  const renderInfo = () => (
    <React.Fragment>
      <RentDepositInfo rentDeposit={rentDeposit} setTab={setTab} />
      {canCancelDeposit && (
        <Box mt={ESpacings.extraLoose}>
          <Button
            appearance="outline"
            color="red"
            onClick={handleCancelDepositClick}
            isSubmitting={isCancellingRentDeposit}
          >
            {ts.rentDepositCancelAction()}
          </Button>
        </Box>
      )}
      {canCancelRelease && (
        <Box mt={ESpacings.extraLoose}>
          <Button
            appearance="outline"
            color="red"
            onClick={handleCancelDepositReleaseClick}
            isSubmitting={isCancellingRentDeposit}
          >
            {ts.rentDepositCancelReleaseAction()}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
  const renderHistory = () => (
    <RentDepositHistory rentDeposit={rentDeposit} setTab={setTab} />
  );

  return (
    <Page
      title={ts.modelType("rent_deposit")}
      subtitle={subtitle}
      metadata={metadata}
      tabs={tabs}
      setDocumentTitle={false}
    >
      {tab === ERentDepositTabs.Info && renderInfo()}
      {tab === ERentDepositTabs.History && renderHistory()}

      {rentDeposit.status === ERentDepositStatus.Established && (
        <>
          <Box mt={ESpacings.loose}>
            <Button
              appearance="primary"
              url={`/forms/rent-deposit/${rentDeposit.id}/release`}
            >
              {ts.rentDepositReleaseAction()}
            </Button>
          </Box>
          <Box mt={ESpacings.loose}>
            <Button appearance="outline" onClick={showJudicialReleaseModal}>
              {ts.rentDepositJudicialReleaseAction()}
            </Button>
          </Box>
        </>
      )}
    </Page>
  );
};

export default RentDeposit;
