import { EConditionType } from "../../Editor/Editor.types";
import { EField } from "./schema.insertCondition.types";
export var getInitialValues = function (extraData) {
    var _a, _b;
    var condition = extraData.condition;
    if (condition) {
        return _a = {},
            _a[EField.Name] = condition.variableId,
            // [EField.Group]: undefined,
            _a[EField.Type] = condition.type,
            _a[EField.ContentText] = condition.contentText,
            _a[EField.ContentNumber] = condition.contentNumber,
            _a[EField.ContentDate] = condition.contentDate,
            _a;
    }
    return _b = {},
        _b[EField.Name] = undefined,
        // [EField.Group]: undefined,
        _b[EField.Type] = Object.values(EConditionType)[0],
        _b[EField.ContentText] = undefined,
        _b[EField.ContentNumber] = undefined,
        _b[EField.ContentDate] = undefined,
        _b;
};
export var getCleanFormData = function (formData) {
    var variableId = formData[EField.Name];
    var type = formData[EField.Type];
    var contentText = formData[EField.ContentText];
    var contentDate = formData[EField.ContentDate];
    var contentNumber = formData[EField.ContentNumber];
    return {
        variableId: variableId,
        type: type,
        contentText: contentText,
        contentNumber: contentNumber,
        contentDate: contentDate,
    };
};
