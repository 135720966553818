import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ButtonBasic from "../../components/components/ButtonBasic";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import * as S from "./ScreenDisabled.styles";
export var EScreenDisabledType;
(function (EScreenDisabledType) {
    EScreenDisabledType["ForcedUpdate"] = "forced_update";
    EScreenDisabledType["Downtime"] = "downtime";
})(EScreenDisabledType || (EScreenDisabledType = {}));
export var ScreenDisabled = function (props) {
    var type = props.type, message = props.message;
    return (_jsx(S.Wrap, { children: _jsxs(S.Content, { children: [_jsx(S.Title, { children: getLocalizedText("system.".concat(type, ".notice.heading")) }), _jsx(S.Message, { children: message || getLocalizedText("system.".concat(type, ".notice.content")) }), _jsx(ButtonBasic, { titleProps: {
                        style: { color: "white", textDecorationLine: "underline" },
                    }, onPress: function () {
                        setTimeout(function () {
                            window.location.reload();
                        }, 300);
                    }, title: getLocalizedText("system.".concat(type, ".notice.action")) })] }) }));
};
