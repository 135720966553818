import * as authApi from "@rentiohq/shared-frontend/dist/redux/auth/auth.api";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import logger from "@rentiohq/shared/dist/logger";
import React from "react";
import Markdown from "react-markdown";
import { ts } from "../../../services";

export const ProfileTac = () => {
  const { user } = authHooks.useSelf();

  const [tac, setTac] = React.useState<authApi.IGetTacResponse>();

  React.useEffect(() => {
    if (!user?.tac?.dashboard?.id) {
      return;
    }

    const fetchTac = async () => {
      if (!user?.tac?.dashboard?.id) {
        return;
      }

      try {
        const { data } = await authApi.getTacById(user.tac.dashboard.id);

        setTac(data);
      } catch (unknownError) {
        const error = unknownError as any;
        logger.logError({ error });
      }
    };

    fetchTac();
  }, [user]);

  if (!user) {
    return null;
  }

  if (!user?.tac?.dashboard?.id) {
    return <div>{ts.tacNothingAccepted()}</div>;
  }

  if (!tac) {
    return <p>{getLocalizedText("fetch.loading")}</p>;
  }

  if (!tac.content) {
    return <p>{getLocalizedText("tac.empty_content")}</p>;
  }

  return <Markdown children={tac.content} />;
};
