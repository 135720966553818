import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ActivityContent } from "../ActivityContent";
import { hasContent } from "../ActivityContent/ActivityContent.util";
import * as S from "./ReadMoreToggle.styled";
export var ReadMoreToggle = function (_a) {
    var activity = _a.activity, isOpen = _a.isOpen, toggleOpen = _a.toggleOpen;
    var shouldShow = hasContent(activity);
    if (!shouldShow) {
        return null;
    }
    return (_jsxs(S.Wrap, { children: [isOpen && (_jsx(S.ActivityContentWrap, { children: _jsx(ActivityContent, { activity: activity }) })), _jsx(ButtonBasic, { title: getLocalizedText(isOpen ? "activity.content.read_less" : "activity.content.read_more"), onPress: function () {
                    toggleOpen(activity.id);
                } })] }));
};
