export var EChatMemberTypes;
(function (EChatMemberTypes) {
    EChatMemberTypes["Admin"] = "ADMIN";
    EChatMemberTypes["Participant"] = "PARTICIPANT";
})(EChatMemberTypes || (EChatMemberTypes = {}));
export var EChatMemberUpdateStatus;
(function (EChatMemberUpdateStatus) {
    EChatMemberUpdateStatus["Added"] = "ADDED";
    EChatMemberUpdateStatus["Removed"] = "REMOVED";
})(EChatMemberUpdateStatus || (EChatMemberUpdateStatus = {}));
