import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { JSONSchema6 } from "json-schema";
import { UiSchema } from "react-native-jsonschema-form-test";
import { ts } from "../../services";
import { EField, IExtraData } from "./schema.editPropertyInfo.types";

export const getSchema =
  (initialValues: { [key: string]: any }) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [],
    properties: {
      [EField.Price]: {
        default: getValue(EField.Price, initialValues, defaultValues),
      },
      [EField.Costs]: {
        default: getValue(EField.Costs, initialValues, defaultValues),
      },
    },
  });

export const uiSchema = (_extraData: IExtraData): UiSchema => ({
  "rentio:navigationTitle": ts.propertyInfoFormModalTitle(),
  [EField.Price]: {
    "ui:field": ERentioFormField.Number,
    formatSummary: (formData?: number) => formData && formatCurrency(formData),
    title: ts.propertyInfoFormPriceLabel(),
    placeholder: "0",
    suffixText: "EUR",
    isFloat: true,
  },
  [EField.Costs]: {
    "ui:field": ERentioFormField.Number,
    formatSummary: (formData?: number) => formData && formatCurrency(formData),
    title: ts.propertyInfoFormCostsLabel(),
    placeholder: "0",
    suffixText: "EUR",
    isFloat: true,
  },
});
