import PrivateRoute from "@rentiohq/web-shared/dist/components/PrivateRoute";
import { getAuthScene } from "@rentiohq/web-shared/dist/scenes/Auth/Auth.scene";
import { getTemplateDocumentEditorScene } from "@rentiohq/web-shared/dist/scenes/TemplateDocumentEditor";
import { getTemplatesScene } from "@rentiohq/web-shared/dist/scenes/Templates";
import { App } from "App";
import { Route, Routes } from "react-router-dom";
import { getChatsScene } from "scenes/Chats";
import { getCompanyScene } from "scenes/Company";
import { getContactsScene } from "scenes/Contacts";
import { getDashboardScene } from "scenes/Dashboard";
import { getFollowUpScene } from "scenes/FollowUp";
import { getFormsScene } from "scenes/Forms";
import { NotFound } from "scenes/NotFound";
import { getPaymentsOverviewScene } from "scenes/PaymentsOverview";
import { getProfileScene } from "scenes/Profile";
import { getPropertiesScene } from "scenes/Properties";
import { getRentioLegalScene } from "scenes/RentioLegal/RentioLegal.scene";
import { getReportsScene } from "scenes/Reports";
import { getRevenuesScene } from "scenes/Revenues";
import { getSettingsScene } from "scenes/Settings";
import { getTasksScene } from "scenes/Tasks";
import { RentioInternal } from "./scenes/RentioInternal";

export const AppRouter = () => (
  <Routes>
    {getAuthScene()}

    <Route path="/rentio-internal" element={<RentioInternal />} />

    <Route element={<PrivateRoute />}>
      <Route path="*" element={<App />}>
        {getDashboardScene()}

        {getTasksScene()}

        {getFormsScene()}

        {getRevenuesScene()}

        {getChatsScene()}

        {getTemplateDocumentEditorScene()}

        {getFollowUpScene()}

        {getPaymentsOverviewScene()}

        {getContactsScene()}

        {getProfileScene()}

        {getCompanyScene()}

        {getSettingsScene()}

        {getPropertiesScene()}

        {getReportsScene()}

        {getRentioLegalScene()}

        {getTemplatesScene({ internal: false })}

        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
);
