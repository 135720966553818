import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as moveRequestActions from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.actions";
import * as moveRequestSelectors from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.selectors";
import { getExternalUrl } from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EMoveServiceStatus } from "@rentiohq/shared/dist/types/contract.types";
import {
  Error,
  Grid,
  Icon,
  Loading,
  Page,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import MoveRequestInfo from "./components/MoveRequestInfo";

interface IProps {
  moveRequestId: number;
  onDrawerClose?: () => void;
}

const MoveRequest: React.FC<IProps> = props => {
  const { moveRequestId, onDrawerClose } = props;

  // Redux
  const dispatch = useDispatch();

  const { internalModeEnabled } = useInternalMode();

  const { isBroker } = authHooks.useSelf();

  const moveRequest = useSelector((state: IRootStore) =>
    moveRequestSelectors.moveRequestById(state, moveRequestId),
  );
  const isFetchingMoveRequest = useSelector((state: IRootStore) =>
    moveRequestSelectors.isFetchingMoveRequest(state, moveRequestId),
  );
  const moveRequestFetchError = useSelector((state: IRootStore) =>
    moveRequestSelectors.fetchError(state, moveRequestId),
  );

  const propertyId = moveRequest?.property.id;
  const {
    detail: property,
    isFetching: isFetchingProperty,
    fetchError: propertyFetchError,
  } = propertyHooks.useDetail({
    id: propertyId,
  });

  // Lifecycle
  React.useEffect(() => {
    if (isFetchingMoveRequest) {
      return;
    }

    dispatch(
      moveRequestActions.getMoveRequest.actions.start({
        id: moveRequestId,
      }),
    );
  }, []);

  // Render
  if (!moveRequest || !property) {
    if (isFetchingProperty || isFetchingMoveRequest) {
      return <Loading />;
    }

    if (moveRequestFetchError) {
      return <Error errors={[moveRequestFetchError]} />;
    }

    if (propertyFetchError) {
      return <Error errors={[propertyFetchError]} />;
    }

    return null;
  }

  const metadata = (
    <>
      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon
            source={utils.properties.getIcon(property.typeId || 0)}
            size="small"
          />
        </Grid.Item>
        <Grid.Item>
          <Button appearance="link" url={`/properties/${property.id}`}>
            {formatAddress(property)}
          </Button>
        </Grid.Item>
      </Grid>

      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="calendar" size="small" />
        </Grid.Item>
        <Grid.Item>
          {getLocalizedText("system.created_at", {
            date: formatDate(moveRequest.createdAt),
          })}
        </Grid.Item>
      </Grid>
    </>
  );

  const actions: IAction[] = [];

  if (
    moveRequest.externalStatus === EMoveServiceStatus.PendingByBroker ||
    internalModeEnabled
  ) {
    actions.push({
      content: getLocalizedText(
        "contract_card.checklist.move_service.info_modal.cta.complete_request",
      ),
      onClick: () => {
        setTimeout(() => {
          window.open(getExternalUrl(moveRequest, isBroker), "_blank");
        }, 100);
      },
    });
  }

  if (moveRequest.externalUrl) {
    actions.push({
      content: getLocalizedText(
        "contract_card.checklist.move_service.info_modal.cta.external_status",
      ),
      onClick: () => {
        setTimeout(() => {
          window.open(moveRequest.externalUrl, "_blank");
        }, 100);
      },
    });
  }

  return (
    <Page
      title={getLocalizedText("move_request.detail.title", {
        address: formatAddress(property, false, false),
      })}
      metadata={metadata}
      setDocumentTitle={false}
      dropdown={actions}
    >
      <MoveRequestInfo
        moveRequest={moveRequest}
        property={property}
        onDrawerClose={onDrawerClose}
      />
    </Page>
  );
};

export default MoveRequest;
