import { Navigate, Route } from "react-router-dom";
import Communication from "./scenes/Communication/Communication";
import TemplatePreferences from "./scenes/TemplatePreferences";
import TenantProfileNotificationPreferences from "./scenes/TenantProfileNotificationPreferences";
import TenantProfilePreferences from "./scenes/TenantProfilePreferences";
import { Settings } from "./Settings";
import { ESettingsPaths } from "./Settings.types";

export const getSettingsScene = () => (
  <Route path={ESettingsPaths.Base} element={<Settings />}>
    <Route
      path={ESettingsPaths.TenantProfile}
      element={<TenantProfilePreferences />}
    />
    <Route
      path={ESettingsPaths.TenantProfileNotifications}
      element={<TenantProfileNotificationPreferences />}
    />
    <Route
      path={ESettingsPaths.TemplatePreferences}
      element={<TemplatePreferences />}
    />
    <Route path={ESettingsPaths.Communication} element={<Communication />} />
    <Route index element={<Navigate to="tenant-profile" replace={true} />} />
  </Route>
);
