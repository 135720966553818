import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.createContract.1";
import { getSchema as getSchema10, uiSchema as uiSchema10, } from "./schema.createContract.10";
import { getSchema as getSchema11, uiSchema as uiSchema11, } from "./schema.createContract.11";
import { getSchema as getSchema12, uiSchema as uiSchema12, } from "./schema.createContract.12";
import { getSchema as getSchema2, uiSchema as uiSchema2, } from "./schema.createContract.2";
import { getSchema as getSchema3, getValidate as getValidate3, uiSchema as uiSchema3, } from "./schema.createContract.3";
import { getSchema as getSchema3b, uiSchema as uiSchema3b, } from "./schema.createContract.3b";
import { getSchema as getSchema4, getValidate as getValidate4, uiSchema as uiSchema4, } from "./schema.createContract.4";
import { getSchema as getSchema4b, uiSchema as uiSchema4b, } from "./schema.createContract.4b";
import { getSchema as getSchema5, uiSchema as uiSchema5, } from "./schema.createContract.5";
import { getSchema as getSchema6, getValidate as getValidate6, uiSchema as uiSchema6, } from "./schema.createContract.6";
import { getSchema as getSchema6b, uiSchema as uiSchema6b, } from "./schema.createContract.6b";
import { getSchema as getSchema7, uiSchema as uiSchema7, } from "./schema.createContract.7";
import { getSchema as getSchema8, uiSchema as uiSchema8, } from "./schema.createContract.8";
import { EField, EPaymentOrderCreateOption, } from "./schema.createContract.types";
import { getInitialValues, isExistingContract, } from "./schema.createContract.utils";
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: uiSchema1(extraData),
        },
        {
            schema: getSchema2(initialValues, extraData),
            uiSchema: uiSchema2(extraData),
        },
        {
            schema: getSchema3(initialValues, extraData),
            uiSchema: uiSchema3(extraData),
            validate: getValidate3(extraData),
        },
        {
            schema: getSchema3b(initialValues, extraData),
            uiSchema: uiSchema3b(extraData),
            showIf: function () {
                if (extraData.property.typeId !== 4) {
                    return false;
                }
                return true;
            },
        },
        {
            schema: getSchema4(initialValues, extraData),
            uiSchema: uiSchema4(extraData),
            validate: getValidate4(extraData),
        },
        {
            schema: getSchema4b(initialValues, extraData),
            uiSchema: uiSchema4b(extraData),
        },
        {
            schema: getSchema5(initialValues, extraData),
            uiSchema: uiSchema5(extraData),
            showIf: isExistingContract,
        },
        {
            schema: getSchema6(initialValues, extraData),
            uiSchema: uiSchema6(extraData),
            validate: getValidate6(extraData),
        },
        {
            schema: getSchema6b(initialValues, extraData),
            uiSchema: uiSchema6b(extraData),
            validate: getValidate6(extraData),
            showIf: function () { return !!extraData.ibanisationEnabled; },
        },
        {
            schema: getSchema7(initialValues, extraData),
            uiSchema: uiSchema7(extraData),
            showIf: function () {
                var _a;
                if (!extraData.completeFlowEnabled) {
                    return false;
                }
                // Don't show for existing contract
                if (extraData.contract) {
                    return false;
                }
                // Don't show if no templates are available
                if (!extraData.templates || ((_a = extraData.templates) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                    return false;
                }
                return true;
            },
        },
        {
            schema: getSchema8(initialValues, extraData),
            uiSchema: uiSchema8(extraData),
            showIf: function () {
                if (!extraData.completeFlowEnabled) {
                    return false;
                }
                // Don't show for existing contract
                if (extraData.contract) {
                    return false;
                }
                return true;
            },
        },
        {
            schema: getSchema10(initialValues, extraData),
            uiSchema: uiSchema10(extraData),
            showIf: function (formData) {
                return formData[EField.PaymentOrderCreateOption] ===
                    EPaymentOrderCreateOption.Create;
            },
        },
        {
            schema: getSchema11(initialValues, extraData),
            uiSchema: uiSchema11(extraData),
            showIf: function (formData) {
                return formData[EField.PaymentOrderCreateOption] ===
                    EPaymentOrderCreateOption.Create;
            },
        },
        {
            schema: getSchema12(initialValues, extraData),
            uiSchema: uiSchema12(extraData),
            showIf: function (formData) {
                return formData[EField.PaymentOrderCreateOption] ===
                    EPaymentOrderCreateOption.Create;
            },
        },
    ];
});
