import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import PropertyCard from "@rentiohq/web-shared/dist/components/Cards/PropertyCard";
import React from "react";

interface IProps {
  property: IProperty;
}

export const LinkedPropertiesCard: React.FC<IProps> = props => {
  const { property } = props;

  // Render
  if (!property.parentId) {
    return null;
  }

  return (
    <PropertyCard
      propertyId={property.parentId}
      title={getLocalizedText("property.dashboard.group.heading")}
      detailCtaTitle={getLocalizedText("property.view_property_group.action")}
    />
  );
};

export default LinkedPropertiesCard;
