import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as chatHooks from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.hooks";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ContactDashboard } from "./ContactDashboard";

export const ContactDashboardContainer = () => {
  const params = useParams<{ contactId: string }>();
  const contactId = params.contactId ? +params.contactId : undefined;

  const contact = useSelector((state: IRootStore) =>
    contactId ? contactSelectors.getContact(state, contactId) : undefined,
  );

  const {
    items: chats,
    isFetching: isFetchingChats,
    fetchError: chatsFetchError,
  } = chatHooks.usePaged(
    contactId
      ? {
          query: {
            limit: 100,
            page: 1,
          },
          customPath: `/contacts/${contactId}/chats`,
        }
      : {},
  );

  if (!contact) {
    return null;
  }

  return (
    <ContactDashboard
      contact={contact}
      chats={chats}
      isFetchingChats={isFetchingChats}
      chatsFetchError={chatsFetchError}
    />
  );
};
