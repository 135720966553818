import * as actions from "./property.actions";
import * as api from "./property.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./property.constants";
import * as hooks from "./property.hooks";
import * as promises from "./property.promises";
import reducer from "./property.reducer";
import { saga } from "./property.saga";
import * as selectors from "./property.selectors";
import * as utils from "./property.utils";
export * from "./property.types";
export var REDUCER_KEY_PROPERTY = REDUCER_KEY;
export var propertyModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: utils,
};
