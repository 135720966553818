var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import * as S from "./ChatAttachmentPreview.styles";
var ChatAttachmentPreview = /** @class */ (function (_super) {
    __extends(ChatAttachmentPreview, _super);
    function ChatAttachmentPreview(props) {
        var _this = _super.call(this, props) || this;
        _this.retryTimeoutDuration = 1000;
        // Event handlers
        _this.handleLoadStart = function () {
            _this.setState({
                isLoading: true,
            });
        };
        _this.handleLoad = function () {
            _this.setState({
                isLoading: false,
                loadingFailed: false,
            });
        };
        _this.handleError = function () {
            _this.setState({
                loadingFailed: true,
            });
            // Schedule retry
            _this.retryTimeout = setTimeout(function () {
                _this.retryTimeoutDuration = _this.retryTimeoutDuration * 1.5;
                _this.setState({
                    loadingFailed: false,
                });
            }, _this.retryTimeoutDuration);
        };
        _this.state = {
            isLoading: false,
            loadingFailed: false,
        };
        return _this;
    }
    // Lifecycle methods
    ChatAttachmentPreview.prototype.componentWillUnmount = function () {
        if (this.retryTimeout) {
            clearTimeout(this.retryTimeout);
        }
    };
    // Render
    ChatAttachmentPreview.prototype.render = function () {
        var _a = this.props, isSender = _a.isSender, image = _a.image;
        var _b = this.state, isLoading = _b.isLoading, loadingFailed = _b.loadingFailed;
        return (_jsxs(S.Wrap, { children: [isLoading && _jsx(S.ActivityIndicatorStyled, { isSender: isSender }), !loadingFailed && image && (_jsx(S.AttachmentImage, { source: {
                        uri: image,
                    }, onLoadStart: this.handleLoadStart, onLoad: this.handleLoad, onError: this.handleError }))] }));
    };
    return ChatAttachmentPreview;
}(React.PureComponent));
export default ChatAttachmentPreview;
