import { ERegistrationContractStatus } from "@rentiohq/shared/dist/types/registration.types";
// Contracts
export var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT = [
    ERegistrationContractStatus.Registering,
    ERegistrationContractStatus.Incomplete,
];
export var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT = [
    ERegistrationContractStatus.NeedPayment,
    ERegistrationContractStatus.Rejected,
    ERegistrationContractStatus.Failed,
];
export var DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT = [
    ERegistrationContractStatus.Registered,
    ERegistrationContractStatus.RegisteredManually,
    ERegistrationContractStatus.NotApplicable,
];
// Addenda
export var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_ADDENDA = [
    ERegistrationContractStatus.Registering,
    ERegistrationContractStatus.Incomplete,
];
export var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_ADDENDA = [
    ERegistrationContractStatus.NeedPayment,
    ERegistrationContractStatus.Rejected,
    ERegistrationContractStatus.Failed,
];
export var DEFAULT_STATUSES_REGISTRATION_REGISTERED_ADDENDA = [
    ERegistrationContractStatus.Registered,
    ERegistrationContractStatus.RegisteredManually,
    ERegistrationContractStatus.NotApplicable,
];
// Location description
export var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION = [
    ERegistrationContractStatus.None,
    ERegistrationContractStatus.Registering,
    ERegistrationContractStatus.Incomplete,
];
export var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION = [
    ERegistrationContractStatus.NeedPayment,
    ERegistrationContractStatus.Rejected,
    ERegistrationContractStatus.Failed,
];
export var DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION = [
    ERegistrationContractStatus.Registered,
    ERegistrationContractStatus.RegisteredManually,
    ERegistrationContractStatus.NotApplicable,
];
