var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spacer, { ESpacerDirection, ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { DropdownMenu, Icon } from "../../../../components";
import * as S from "./ToolbarButton.styles";
export var ToolbarButton = function (props) {
    var icon = props.icon, iconSource = props.iconSource, title = props.title, dropdownActions = props.dropdownActions, onClick = props.onClick, isActive = props.isActive, isDisabled = props.isDisabled, _a = props.showDropdownIcon, showDropdownIcon = _a === void 0 ? true : _a;
    // Render
    var content = (_jsxs(S.Wrap, __assign({ onClick: isDisabled ? undefined : onClick, isActive: isActive, isDisabled: isDisabled }, { children: [(icon || iconSource) && title && (_jsx(Spacer, { weight: ESpacerWeight.W12, direction: ESpacerDirection.Horizontal })), icon, iconSource && _jsx(Icon, { source: iconSource }), title && (_jsx(S.Title, __assign({ style: {
                    paddingLeft: icon || iconSource || dropdownActions ? 8 : 16,
                    paddingRight: dropdownActions ? 4 : 16,
                } }, { children: title }))), showDropdownIcon && dropdownActions && (_jsx(S.DropdownIconWrap, { children: _jsx(Icon, { source: "chevronDown", size: "small" }) }))] })));
    if (dropdownActions) {
        return (_jsx(_Fragment, { children: _jsx(DropdownMenu, { triggerProps: {
                    // trigger: "hover",
                    placement: "bottom-start",
                }, children: content, actions: dropdownActions }) }));
    }
    return content;
};
export default ToolbarButton;
