import { Box, Flex } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as paymentApi from "@rentiohq/shared-frontend/dist/redux/payment/payment.api";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import logger from "@rentiohq/shared/dist/logger";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  EMissingKYCStatus,
  IMissingKYC,
} from "@rentiohq/shared/dist/types/payment.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import {
  Card,
  DisplayText,
  ESpacings,
  Grid,
  Icon,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import ActionCard from "@rentiohq/web-shared/dist/components/ActionCard";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { useToggle } from "@rentiohq/web-shared/dist/hooks/useToggle";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import { TAppearance } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import sortBy from "lodash/sortBy";
import React from "react";
import * as t from "../../../../../../services/translationService";
import CollectionFollowUpKycModal from "./CollectionFollowUpKycModal";

const KYCS_TO_SHOW_WHEN_COLLAPSED = 6;

interface IProps {
  items: IMissingKYC[];
  refetch: () => void;
}

const CollectionFollowUp: React.FC<IProps> = props => {
  const { items, refetch } = props;
  const { user, isBroker } = authHooks.useSelf();

  // State
  const [showAllMissingKycs, toggleShowAllMissingKycs] = useToggle(false);
  const [kycContactIdToPerform, setKycContactIdToPerform] =
    React.useState<number>();

  // Event handlers
  const resendKYCReminder = async ({ contact }: { contact: IContact }) => {
    try {
      await paymentApi.sendKYCReminder(contact.id);
      toast({
        heading: t.contactResendKYCRegistrationSentSuccess({
          name: contact.firstname,
        }),
      });
    } catch (unknownError) {
      const error = unknownError as any;
      logger.logError({ error });

      toast({
        heading: t.contactResendKYCRegistrationSentFail({
          name: contact.firstname,
        }),
        variation: "error",
      });
    }
  };

  const handleExtraInfoClick = () => {
    utils.general.openWindow(
      utils.i18n.t("payments.collection_follow_up.url.dashboard"),
    );
  };

  const handleClose = () => {
    setKycContactIdToPerform(undefined);
  };

  const handleSuccess = () => {
    setKycContactIdToPerform(undefined);
    refetch();
  };

  // Render
  return (
    <>
      <Card>
        <Flex mb={ESpacings.base} alignItems="center">
          <DisplayText space="none" size="medium">
            {t.paymentsCollectionFollowUpHeading()}{" "}
          </DisplayText>

          <Box ml={ESpacings.tight}>
            <Button onClick={handleExtraInfoClick} appearance="plain">
              <Icon source="informationCircle" size="small" />
            </Button>
          </Box>
        </Flex>

        <Grid>
          {sortBy(items, "status")
            .slice(
              0,
              showAllMissingKycs ? items.length : KYCS_TO_SHOW_WHEN_COLLAPSED,
            )
            .map((item, index) => {
              const handleKYCReminderClick = () => {
                resendKYCReminder({ contact: item.contact });
              };

              const canPerform = [
                EMissingKYCStatus.None,
                EMissingKYCStatus.New,
                EMissingKYCStatus.Failed,
              ].includes(item.status);

              const isCurrentUser = user?.accountIds.includes(
                item.contact.accountId,
              );

              const actions = [];
              if (canPerform) {
                actions.push([
                  {
                    content: t.contactResendKYCRegistrationAction(),
                    onClick: handleKYCReminderClick,
                  },
                ]);
              }
              if (item.contact) {
                actions.push([
                  {
                    content: t.contactViewContactAction(),
                    url: `/contacts/${item.contact.id}`,
                  },
                ]);
              }

              const primaryActions = [];
              if (canPerform) {
                if (isBroker) {
                  primaryActions.push({
                    content: getLocalizedText(
                      "form.kyc_receiver_as_broker.cta.start",
                    ),
                    onClick: () => {
                      setKycContactIdToPerform(item.contact.id);
                    },
                    color: "red",
                    appearance: "outline",
                  });
                } else if (isCurrentUser) {
                  primaryActions.push({
                    content: getLocalizedText(
                      "form.kyc_receiver_as_broker.cta.start",
                    ),
                    onClick: () => {
                      setKycContactIdToPerform(user?.personalContactId);
                    },
                    color: "red",
                    appearance: "outline",
                  });
                }
              }

              const renderReason = () => {
                if (
                  item.status === EMissingKYCStatus.Failed &&
                  item.errorMessage
                ) {
                  const errorKey =
                    `payments.kyc_failed.reason.${item.errorMessage}`.toLowerCase();
                  const ctaKeyTitle =
                    `payments.kyc_failed.reason.${item.errorMessage}.cta.title`.toLowerCase();
                  const ctaKeyUrl =
                    `payments.kyc_failed.reason.${item.errorMessage}.cta.url`.toLowerCase();

                  const hasLocalizedUrl =
                    getLocalizedText(ctaKeyUrl) !== ctaKeyUrl;

                  return (
                    <>
                      {getLocalizedText(errorKey)}
                      {hasLocalizedUrl && (
                        <div>
                          <Spacer weight={ESpacerWeight.W02} />

                          <Button
                            appearance="link"
                            onClick={() => {
                              setTimeout(() => {
                                window.open(
                                  getLocalizedText(ctaKeyUrl),
                                  "_blank",
                                );
                              }, 0);
                            }}
                          >
                            {getLocalizedText(ctaKeyTitle)}
                          </Button>
                        </div>
                      )}
                    </>
                  );
                }

                return t.paymentsCollectionFollowUpReason({
                  reason: item.status,
                });
              };

              let appearance: TAppearance = "error";
              switch (item.status) {
                case EMissingKYCStatus.Requested:
                  appearance = "info";
                  break;

                case EMissingKYCStatus.Approved:
                  appearance = "success";
                  break;

                default:
                  break;
              }

              return (
                <Grid.Item key={index} width={[1, 1, 1 / 3]}>
                  <ActionCard
                    appearance={appearance}
                    heading={
                      <Flex alignItems="center">
                        {item.contact && (
                          <Box flex={1} mr={ESpacings.tight}>
                            <DisplayText size="small">
                              {getName(item.contact)}
                            </DisplayText>
                          </Box>
                        )}
                        {item.amount > 0 ? (
                          <TextStyle variation="code">
                            {formatCurrency(item.amount)}
                          </TextStyle>
                        ) : null}
                      </Flex>
                    }
                    actions={actions}
                    reason={renderReason()}
                    // @ts-ignore
                    primaryActions={primaryActions}
                  />
                </Grid.Item>
              );
            })}
        </Grid>

        {items.length > KYCS_TO_SHOW_WHEN_COLLAPSED && (
          <>
            <Spacer weight={ESpacerWeight.W12} />

            {!showAllMissingKycs && (
              <Button appearance="link" onClick={toggleShowAllMissingKycs}>
                {`${getLocalizedText("system.show_all")} (${items.length})`}
              </Button>
            )}

            {showAllMissingKycs && (
              <Button appearance="link" onClick={toggleShowAllMissingKycs}>
                {getLocalizedText("system.show_less")}
              </Button>
            )}
          </>
        )}
      </Card>

      <CollectionFollowUpKycModal
        contactId={kycContactIdToPerform}
        onSuccess={handleSuccess}
        onClose={handleClose}
        asBroker={isBroker}
      />
    </>
  );
};

export default React.memo(CollectionFollowUp);
