var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { updateContact } from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import React from "react";
import { useDispatch } from "react-redux";
import { AccountInfoListItem } from "../AccountInfoListItem/AccountInfoListItem";
import AddContactModal from "../AddContactModal";
import ContactEditModal from "../ContactEditModal";
import ContactFetchListItem from "../ContactFetchListItem";
import { ContactInfoListItem } from "../ContactInfoListItem";
import { ESpacings } from "../Grid";
import { ResourceList } from "../ResourceList";
export var AccountList = function (props) {
    var _a = props.accounts, accountsRaw = _a === void 0 ? [] : _a, membersForAdditionalAccountInfo = props.membersForAdditionalAccountInfo, _b = props.actions, actions = _b === void 0 ? [] : _b, _c = props.getActions, getActions = _c === void 0 ? function () { return []; } : _c, getContactInfoForAccount = props.getContactInfoForAccount, initialContactType = props.initialContactType, getSubtitle = props.getSubtitle, getExtraSubtitle = props.getExtraSubtitle, _d = props.mediaSize, mediaSize = _d === void 0 ? "medium" : _d, _e = props.boxProps, boxProps = _e === void 0 ? { p: ESpacings.tight } : _e;
    var accounts = accountsRaw.map(function (account) {
        var member = membersForAdditionalAccountInfo === null || membersForAdditionalAccountInfo === void 0 ? void 0 : membersForAdditionalAccountInfo.find(function (member) { return member.account.id === account.id; });
        // Merge member & account contact info to account contact info
        return __assign(__assign({}, account), { contactInfo: (member === null || member === void 0 ? void 0 : member.contactInfo) || (account === null || account === void 0 ? void 0 : account.contactInfo) });
    });
    var resourceListItemProps = {
        boxProps: __assign({}, boxProps),
        mediaSize: mediaSize,
    };
    var dispatch = useDispatch();
    var _f = React.useState(), contactToEdit = _f[0], setContactToEdit = _f[1];
    var _g = React.useState(), accountToAdd = _g[0], setAccountToAdd = _g[1];
    // Event handlers
    var handleEditModalClose = function () {
        setContactToEdit(undefined);
    };
    var handleAddAccountClose = function () {
        setAccountToAdd(undefined);
    };
    var handleEditSubmit = function (contact) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setContactToEdit(undefined);
            dispatch(updateContact.actions.start({ contactId: contact.id, contact: contact }));
            return [2 /*return*/];
        });
    }); };
    var handleAddSubmit = function (contact) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setAccountToAdd(undefined);
            return [2 /*return*/];
        });
    }); };
    // Render
    var renderListItem = function (item) { return (_jsx(ContactFetchListItem, { account: item, accountId: item.id, renderAccount: renderAccount, renderContact: renderContact })); };
    var renderAccount = function (account) { return (_jsx(AccountInfoListItem, { account: account, actions: actions, getActions: function (accountId) { return getActions({ accountIds: [accountId] }); }, getContactInfoForAccount: getContactInfoForAccount, resourceListItemProps: resourceListItemProps, onAddAccount: function (account, accountContactInfo) {
            setAccountToAdd({
                account: account,
                accountContactInfo: accountContactInfo || account.contactInfo,
            });
        }, getSubtitle: getSubtitle, getExtraSubtitle: getExtraSubtitle })); };
    var renderContact = function (contact) { return (_jsx(ContactInfoListItem, { accounts: accounts, contact: contact, actions: actions, getActions: function (contact) {
            return getActions({
                accountIds: __spreadArray([
                    contact.accountId,
                    contact.personalAccountId
                ], contact.accountIds, true),
                contact: contact,
                employeeAccount: undefined,
            });
        }, resourceListItemProps: resourceListItemProps, setContactToEdit: setContactToEdit, getSubtitle: getSubtitle
            ? function () {
                return getSubtitle === null || getSubtitle === void 0 ? void 0 : getSubtitle(__spreadArray([
                    contact.accountId,
                    contact.personalAccountId
                ], contact.accountIds, true), contact);
            }
            : undefined, getExtraSubtitle: getExtraSubtitle
            ? function () {
                return getExtraSubtitle === null || getExtraSubtitle === void 0 ? void 0 : getExtraSubtitle(__spreadArray([
                    contact.accountId,
                    contact.personalAccountId
                ], contact.accountIds, true), contact);
            }
            : undefined })); };
    return (_jsxs(_Fragment, { children: [_jsx(ResourceList, { items: accounts, renderItem: renderListItem }), !!contactToEdit && (_jsx(ContactEditModal, { contactId: contactToEdit.id, onClose: handleEditModalClose, onSubmit: handleEditSubmit })), !!accountToAdd && (_jsx(AddContactModal, { type: !!accountToAdd.account.company ? "company" : "contact", contact: __assign(__assign(__assign({ initialType: initialContactType }, accountToAdd.account), accountToAdd.accountContactInfo), { firstname: accountToAdd.account.firstname, lastname: accountToAdd.account.lastname, company: accountToAdd.account.company }), baseAccountId: accountToAdd.account.id, onClose: handleAddAccountClose, onSubmit: handleAddSubmit }))] }));
};
