import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EField } from "./schema.updateGroup.types";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.Name],
            properties: (_a = {},
                _a[EField.Name] = {
                    default: getValue(EField.Name, initialValues, defaultValues),
                },
                _a[EField.SortWeight] = {
                    default: getValue(EField.SortWeight, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {},
        _a[EField.Name] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_group.field.name"),
        },
        _a[EField.SortWeight] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("editor.insert_custom.insert_group.field.sort_weight"),
        },
        _a);
};
