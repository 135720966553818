import { IRentDeposit } from "@rentiohq/shared/dist/types/rentDeposit.types";
import {
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import { getRentDepositActivityFilter } from "@rentiohq/web-shared/dist/utils/rentDeposit";
import React from "react";
import { ts } from "../../../../../../services";

interface IProps {
  rentDeposit: IRentDeposit;
  setTab?: (tab: any) => void;
}
export const RentDepositHistory: React.FC<IProps> = ({ rentDeposit }) => {
  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`rent-deposit-${rentDeposit.id}`}
        filter={getRentDepositActivityFilter(rentDeposit)}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};
