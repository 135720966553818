import { Route } from "react-router-dom";
import { DocumentPackageForm } from "scenes/DocumentPackageForm";
import { RentDepositForm } from "scenes/RentDepositForm";
import { RentDepositReleaseForm } from "scenes/RentDepositReleaseForm";

export const getFormsScene = () => (
  <Route path="forms">
    <Route path="document-package" element={<DocumentPackageForm />} />

    <Route path="rent-deposit">
      <Route
        path=":rentDepositId/release"
        element={<RentDepositReleaseForm />}
      />

      <Route path="open" element={<RentDepositForm />} />
    </Route>
  </Route>
);
