import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IMember } from "@rentiohq/shared/dist/types/auth.types";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import {
  getMembersWithOneOfRoles,
  memberHasOneOfRoles,
  memberHasRole,
} from "@rentiohq/shared/dist/utils/roles.utils";
import { Modal } from "@rentiohq/web-shared/dist/components";
import { compact, flatten, orderBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { AssignRoles } from "../../../../../components";
import * as t from "../../../../../services/translationService";

const ALLOWED_ROLES = [
  EPropertyMemberTypes.Admin,
  EPropertyMemberTypes.Owner,
  EPropertyMemberTypes.Broker,
  EPropertyMemberTypes.IndexationManager,
  EPropertyMemberTypes.FinancialManager,
  EPropertyMemberTypes.TechnicalManager,
];

const ROLES_TO_MANAGE = [
  EPropertyMemberTypes.IndexationManager,
  EPropertyMemberTypes.FinancialManager,
  EPropertyMemberTypes.TechnicalManager,
];

const REQUIRED_ROLES = [
  EPropertyMemberTypes.FinancialManager,
  EPropertyMemberTypes.TechnicalManager,
];

interface IProps {
  propertyId: number;
  onClose: (members?: IMember<EPropertyMemberTypes>[]) => void;
}

export const PropertyRolesModal = (props: IProps) => {
  const { propertyId, onClose } = props;

  // Redux
  const { detail: property } = propertyHooks.useDetail({
    id: propertyId,
  });

  const { updateMembers, isUpdating } = propertyHooks.useUpdate({
    id: propertyId,
  });

  const members = useMemo(() => {
    if (!property) {
      return undefined;
    }

    return orderBy(
      getMembersWithOneOfRoles(property.members, ALLOWED_ROLES),
      [
        member => memberHasRole(member, EPropertyMemberTypes.Broker),
        member =>
          memberHasOneOfRoles(member, [
            EPropertyMemberTypes.IndexationManager,
            EPropertyMemberTypes.FinancialManager,
            EPropertyMemberTypes.TechnicalManager,
          ]),
        member => getName(member.account),
      ],
      ["desc", "desc", "asc"],
    );
  }, [property]);

  // State
  const [values, setValues] = React.useState<{
    [accountId: number]: EPropertyMemberTypes[] | undefined;
  }>({});

  // Hooks
  useEffect(() => {
    if (!members) {
      setValues({});
      return;
    }

    const result: { [accountId: number]: EPropertyMemberTypes[] | undefined } =
      {};

    members.forEach(member => {
      result[member.account.id] = member.roles;
    });

    setValues(result);
  }, [members]);

  if (!property || !members) {
    return null;
  }

  // Needs
  const isValid = (() => {
    const allRoles = compact(flatten(Object.values(values)));

    const firstInvalidRole = REQUIRED_ROLES.find(
      role => !allRoles.includes(role),
    );

    return !firstInvalidRole;
  })();

  // Event handlers
  const handleModalSubmit = () => {
    if (!isValid) {
      return;
    }

    const membersToAdd: { [accountId: number]: EPropertyMemberTypes[] } = {};
    const membersToRemove: { [accountId: number]: EPropertyMemberTypes[] } = {};

    members.forEach(member => {
      const currentRoles = member.roles;
      const newRoles = values[member.account.id] || [];

      const rolesToAdd = newRoles.filter(role => !currentRoles.includes(role));
      const rolesToRemove = currentRoles.filter(
        role => !newRoles.includes(role),
      );

      if (rolesToAdd.length) {
        membersToAdd[member.account.id] = rolesToAdd;
      }

      if (rolesToRemove.length) {
        membersToRemove[member.account.id] = rolesToRemove;
      }
    });

    updateMembers({
      membersToAdd: Object.keys(membersToAdd).map(accountId => ({
        accountId: parseInt(accountId, 10),
        roles: membersToAdd[parseInt(accountId, 10)],
      })),
      membersToRemove: Object.keys(membersToRemove).map(accountId => ({
        accountId: parseInt(accountId, 10),
        roles: membersToRemove[parseInt(accountId, 10)],
      })),
      onSuccess: () => {
        onClose();
      },
    });
  };

  // Render
  return (
    <Modal
      onClose={onClose}
      heading={t.propertyAddRolesTitle()}
      actions={[
        {
          content: t.system("cancel"),
          onClick: onClose,
          appearance: "outline",
        },
        {
          content: t.system("save"),
          isDisabled: !isValid,
          isSubmitting: isUpdating,
          onClick: handleModalSubmit,
          appearance: "primary",
        },
      ]}
      width="large"
      fullBody={true}
    >
      <AssignRoles
        members={members}
        roles={ROLES_TO_MANAGE}
        values={values}
        onChange={setValues}
      />
    </Modal>
  );
};
