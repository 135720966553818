import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";

export const useIsRentioLegalActivated = () => {
  const { broker } = authHooks.useSelf();

  const hasRentioLegalBrokerFeature = brokerHooks.useBrokerFeature(
    EBrokerFeature.RentioLegal,
  );

  if (!broker) {
    return false;
  }

  if (!broker.legalLicenseActivatedAt) {
    return false;
  }

  return hasRentioLegalBrokerFeature;
};
