export var findManagerOrAssignedEmployee = function (members, role, broker) {
    var _a, _b;
    if (!broker)
        return undefined;
    var manager = members.find(function (m) { return m.roles.includes(role) && m.account.id === broker.accountId; });
    if (!manager)
        return undefined;
    var assignedEmployee = (_b = (_a = manager === null || manager === void 0 ? void 0 : manager.employeeRoles) === null || _a === void 0 ? void 0 : _a[role]) === null || _b === void 0 ? void 0 : _b[0];
    return assignedEmployee !== null && assignedEmployee !== void 0 ? assignedEmployee : manager.account;
};
