import { spacing } from "@rentiohq/web-theme";
import styled from "styled-components";

export const ContractRowWrapper = styled.div`
  display: flex;
  padding-top: ${spacing("base")};
  padding-bottom: ${spacing("base")};
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral20};
    cursor: pointer;
  }
`;
