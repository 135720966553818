import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { isAfter } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import React from "react";
import { connect } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import * as t from "../../services/translationService";

interface IProps {
  renderTime: Date;
}

interface IStateProps {
  mostRecentMessage: ReturnType<
    typeof chatSelectors.getMostRecentMessageGeneral
  >;
}

const ChatListener = ({ mostRecentMessage }: IProps & IStateProps) => {
  const navigate = useNavigate();

  const sentToasts = React.useRef<number[]>([]);

  const match = useMatch(`/chats/:chatId`);

  React.useEffect(() => {
    // @ts-ignore
    if (mostRecentMessage) {
      // @ts-ignore
      const windowDate = window.pageDate;
      const toastAlreadySent = sentToasts.current.includes(
        mostRecentMessage.id,
      );

      if (match?.params.chatId === `${mostRecentMessage.chatId}`) {
        // Don't send a toast when the message is from the current active chat
        sentToasts.current.push(mostRecentMessage.id);
        return;
      }

      if (
        isAfter(new Date(mostRecentMessage.sendAt), windowDate) &&
        !toastAlreadySent
      ) {
        const handleClick = () => {
          navigate(`/chats/${mostRecentMessage.chatId}`);
        };

        toast({
          heading: [
            mostRecentMessage.account && getName(mostRecentMessage.account),
            mostRecentMessage.text,
          ]
            .filter(Boolean)
            .join(": "),
          action: {
            content: t.chatsToastGoToChatAction(),
            onClick: handleClick,
          },
        });
      }

      sentToasts.current.push(mostRecentMessage.id);
    }
  }, [history, match, mostRecentMessage]);

  return null;
};

const mapStateToProps = (state: IPartialRootState): any => ({
  mostRecentMessage: chatSelectors.getMostRecentMessageGeneral(state),
});

export default connect<IStateProps, {}, IProps, IPartialRootState>(
  mapStateToProps,
)(ChatListener);
