import * as expertInquiryActions from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.actions";
import { ELocationDescriptionType } from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.types";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.orderLocationDescription.types";

export const isPerformingSubmitActionSelector = () => (state: IRootStore) => {
  return state.expertInquiry.isRequestingLocationDescription;
};

export const performingSubmitActionResultSelector =
  () => (state: IRootStore) => {
    return state.expertInquiry.requestedLocationDescriptionTaskId;
  };

export const performingSubmitActionErrorSelector =
  () => (state: IRootStore) => {
    return state.expertInquiry.requestLocationDescriptionError;
  };

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { partnership, property, newContract, oldContract } = extraData;

    const locationDescriptionType =
      formData[EField.LocationDescriptionType] || ELocationDescriptionType.Out;

    let documentId: string | undefined = undefined;
    let document: File | undefined = undefined;
    if (typeof formData[EField.Document] === "string") {
      documentId = formData[EField.Document];
    } else if (formData[EField.Document]) {
      document = formData[EField.Document][0];
    }

    return expertInquiryActions.requestLocationDescription.actions.start({
      document,
      request: {
        partnerAccountId: partnership?.partnerAccountId,
        propertyId: property.id,
        propertySituation: formData[EField.PropertySituation],
        locationDescriptionType,
        paymentDetail: formData[EField.PaymentDetail],
        newContractId: newContract?.id || oldContract?.id,
        oldContractId: oldContract?.id || newContract?.id,
        notesForPartner: formData[EField.NotesForPartner],
        documentId,
      },
    });
  };
