import activityReducer, {
  IActivityState,
  REDUCER_KEY_ACTIVITY,
} from "@rentiohq/shared-frontend/dist/redux/activity/activity.reducer";
import {
  IAuthState,
  REDUCER_KEY_AUTH,
} from "@rentiohq/shared-frontend/dist/redux/auth/auth.reducer";
import authReducer from "@rentiohq/shared-frontend/dist/redux/auth/auth.reducerPersist";
import brokerReducer, {
  IBrokerState,
  REDUCER_KEY_BROKER,
} from "@rentiohq/shared-frontend/dist/redux/broker/broker.reducer";
import cardReducer, {
  ICardState,
  REDUCER_KEY_CARD,
} from "@rentiohq/shared-frontend/dist/redux/card/card.reducer";
import commentReducer, {
  ICommentState,
  REDUCER_KEY_COMMENT,
} from "@rentiohq/shared-frontend/dist/redux/comment/comment.reducer";
import contactReducer, {
  IContactState,
  REDUCER_KEY_CONTACT,
} from "@rentiohq/shared-frontend/dist/redux/contact/contact.reducer";
import countReducer, {
  ICountState,
  REDUCER_KEY_COUNT,
} from "@rentiohq/shared-frontend/dist/redux/count/count.reducer";
import documentPackageReducer, {
  IDocumentPackageState,
  REDUCER_KEY_DOCUMENT_PACKAGE,
} from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.reducer";
import employeeAccountReducer, {
  IEmployeeAccountState,
  REDUCER_KEY_EMPLOYEE_ACCOUNT,
} from "@rentiohq/shared-frontend/dist/redux/employeeAccount/employeeAccount.reducer";
import expertInquiryReducer, {
  IExpertInquiryState,
  REDUCER_KEY_EXPERT_INQUIRY,
} from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.reducer";
import featureFlagReducer, {
  IFeatureFlagState,
  REDUCER_KEY_FEATURE_FLAG,
} from "@rentiohq/shared-frontend/dist/redux/featureFlag/featureFlag.reducer";
import formReducer, {
  IFormState,
  REDUCER_KEY_FORM,
} from "@rentiohq/shared-frontend/dist/redux/form/form.reducer";
import insuranceReducer, {
  IInsuranceState,
  REDUCER_KEY_INSURANCE,
} from "@rentiohq/shared-frontend/dist/redux/insurance/insurance.reducer";
import invitesReducer, {
  IInvitesState,
  REDUCER_KEY_INVITES,
} from "@rentiohq/shared-frontend/dist/redux/invites/invites.reducer";
import invoiceReducer, {
  IInvoiceState,
  REDUCER_KEY_INVOICE,
} from "@rentiohq/shared-frontend/dist/redux/invoice/invoice.reducer";
import invoiceItemReducer, {
  IInvoiceItemState,
  REDUCER_KEY_INVOICE_ITEM,
} from "@rentiohq/shared-frontend/dist/redux/invoiceItem/invoiceItem.reducer";
import moveRequestReducer, {
  IMoveRequestState,
  REDUCER_KEY_MOVE_REQUEST,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.reducer";
import paymentReducer, {
  IPaymentState,
  REDUCER_KEY_PAYMENT,
} from "@rentiohq/shared-frontend/dist/redux/payment/payment.reducer";
import propertyReducer, {
  IPropertyState,
  REDUCER_KEY_PROPERTY,
} from "@rentiohq/shared-frontend/dist/redux/property/property.reducer";
import registrationReducer, {
  IRegistrationState,
  REDUCER_KEY_REGISTRATION,
} from "@rentiohq/shared-frontend/dist/redux/registration/registration.reducer";
import registrationDocumentReducer, {
  IRegistrationDocumentState,
  REDUCER_KEY_REGISTRATION_DOCUMENT,
} from "@rentiohq/shared-frontend/dist/redux/registrationDocument/registrationDocument.reducer";
import rentDepositReducer, {
  IRentDepositState,
  REDUCER_KEY_RENT_DEPOSIT,
} from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.reducer";
import requirementsReducer, {
  IRequirementsState,
  REDUCER_KEY_REQUIREMENTS,
} from "@rentiohq/shared-frontend/dist/redux/requirements/requirements.reducer";
import socketReducer, {
  ISocketState,
  REDUCER_KEY_SOCKET,
} from "@rentiohq/shared-frontend/dist/redux/socket/socket.reducer";
import templateReducer, {
  ITemplateState,
  REDUCER_KEY_TEMPLATE,
} from "@rentiohq/shared-frontend/dist/redux/template/template.reducer";
import templateDocumentReducer, {
  ITemplateDocumentState,
  REDUCER_KEY_TEMPLATE_DOCUMENT,
} from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.reducer";
import {
  REDUCER_KEY_APPLICATIONS as REDUCER_KEY_APPLICATIONS_2,
  applicationModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/applications";
import {
  REDUCER_KEY_BANK_ACCOUNT,
  bankAccountModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/bankAccount";
import {
  REDUCER_KEY_CHATS as REDUCER_KEY_CHATS_2,
  chatModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/chats";
import {
  REDUCER_KEY_CONTRACT as REDUCER_KEY_CONTRACT_V2,
  contractModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/contract";
import {
  REDUCER_KEY_DOCUMENTS as REDUCER_KEY_DOCUMENTS_V2,
  documentModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/documents";
import {
  REDUCER_KEY_EXPORT_FILE,
  exportFileModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import {
  REDUCER_KEY_LEGAL_CASE,
  legalCaseModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/legalCase";
import {
  REDUCER_KEY_NOTE as REDUCER_KEY_NOTE_V2,
  noteModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/note";
import {
  REDUCER_KEY_PAY_IN,
  payInModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import {
  REDUCER_KEY_PROPERTY as REDUCER_KEY_PROPERTY_V2,
  propertyModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/property";
import {
  REDUCER_KEY_REPORT_BENEFICIARY,
  reportBeneficiaryModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary";
import {
  REDUCER_KEY_TASK as REDUCER_KEY_TASK_V2,
  taskModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/task";
import {
  ISystemLocalState,
  REDUCER_KEY_SYSTEM_LOCAL,
} from "@rentiohq/web-shared/dist/redux/system/system.reducer";
import persistSystemLocalReducer from "@rentiohq/web-shared/dist/redux/system/system.reducerPersist";
import { combineReducers } from "redux";

export interface IRootStore {
  [REDUCER_KEY_ACTIVITY]: IActivityState;
  [REDUCER_KEY_APPLICATIONS_2]: typeof applicationModule.reducerState;
  [REDUCER_KEY_AUTH]: IAuthState;
  [REDUCER_KEY_BANK_ACCOUNT]: typeof bankAccountModule.reducerState;
  [REDUCER_KEY_BROKER]: IBrokerState;
  [REDUCER_KEY_CARD]: ICardState;
  [REDUCER_KEY_CHATS_2]: typeof chatModule.reducerState;
  [REDUCER_KEY_COMMENT]: ICommentState;
  [REDUCER_KEY_CONTACT]: IContactState;
  [REDUCER_KEY_CONTRACT_V2]: typeof contractModule.reducerState;
  [REDUCER_KEY_COUNT]: ICountState;
  [REDUCER_KEY_DOCUMENT_PACKAGE]: IDocumentPackageState;
  [REDUCER_KEY_DOCUMENTS_V2]: typeof documentModule.reducerState;
  [REDUCER_KEY_EMPLOYEE_ACCOUNT]: IEmployeeAccountState;
  [REDUCER_KEY_EXPERT_INQUIRY]: IExpertInquiryState;
  [REDUCER_KEY_EXPORT_FILE]: typeof exportFileModule.reducerState;
  [REDUCER_KEY_FEATURE_FLAG]: IFeatureFlagState;
  [REDUCER_KEY_FORM]: IFormState;
  [REDUCER_KEY_INSURANCE]: IInsuranceState;
  [REDUCER_KEY_INVITES]: IInvitesState;
  [REDUCER_KEY_INVOICE_ITEM]: IInvoiceItemState;
  [REDUCER_KEY_INVOICE]: IInvoiceState;
  [REDUCER_KEY_LEGAL_CASE]: typeof legalCaseModule.reducerState;
  [REDUCER_KEY_MOVE_REQUEST]: IMoveRequestState;
  [REDUCER_KEY_NOTE_V2]: typeof noteModule.reducerState;
  [REDUCER_KEY_PAY_IN]: typeof payInModule.reducerState;
  [REDUCER_KEY_PAYMENT]: IPaymentState;
  [REDUCER_KEY_PROPERTY_V2]: typeof propertyModule.reducerState;
  [REDUCER_KEY_PROPERTY]: IPropertyState;
  [REDUCER_KEY_REGISTRATION_DOCUMENT]: IRegistrationDocumentState;
  [REDUCER_KEY_REGISTRATION]: IRegistrationState;
  [REDUCER_KEY_RENT_DEPOSIT]: IRentDepositState;
  [REDUCER_KEY_REPORT_BENEFICIARY]: typeof reportBeneficiaryModule.reducerState;
  [REDUCER_KEY_REQUIREMENTS]: IRequirementsState;
  [REDUCER_KEY_SOCKET]: ISocketState;
  [REDUCER_KEY_SYSTEM_LOCAL]: ISystemLocalState;
  [REDUCER_KEY_TASK_V2]: typeof taskModule.reducerState;
  [REDUCER_KEY_TEMPLATE_DOCUMENT]: ITemplateDocumentState;
  [REDUCER_KEY_TEMPLATE]: ITemplateState;
}

export const reducers = combineReducers({
  [REDUCER_KEY_ACTIVITY]: activityReducer,
  [REDUCER_KEY_APPLICATIONS_2]: applicationModule.reducer,
  [REDUCER_KEY_AUTH]: authReducer,
  [REDUCER_KEY_BANK_ACCOUNT]: bankAccountModule.reducer,
  [REDUCER_KEY_BROKER]: brokerReducer,
  [REDUCER_KEY_CARD]: cardReducer,
  [REDUCER_KEY_CHATS_2]: chatModule.reducer,
  [REDUCER_KEY_COMMENT]: commentReducer,
  [REDUCER_KEY_CONTACT]: contactReducer,
  [REDUCER_KEY_CONTRACT_V2]: contractModule.reducer,
  [REDUCER_KEY_COUNT]: countReducer,
  [REDUCER_KEY_DOCUMENT_PACKAGE]: documentPackageReducer,
  [REDUCER_KEY_DOCUMENTS_V2]: documentModule.reducer,
  [REDUCER_KEY_EMPLOYEE_ACCOUNT]: employeeAccountReducer,
  [REDUCER_KEY_EXPERT_INQUIRY]: expertInquiryReducer,
  [REDUCER_KEY_EXPORT_FILE]: exportFileModule.reducer,
  [REDUCER_KEY_FEATURE_FLAG]: featureFlagReducer,
  [REDUCER_KEY_FORM]: formReducer,
  [REDUCER_KEY_INSURANCE]: insuranceReducer,
  [REDUCER_KEY_INVITES]: invitesReducer,
  [REDUCER_KEY_INVOICE_ITEM]: invoiceItemReducer,
  [REDUCER_KEY_INVOICE]: invoiceReducer,
  [REDUCER_KEY_LEGAL_CASE]: legalCaseModule.reducer,
  [REDUCER_KEY_MOVE_REQUEST]: moveRequestReducer,
  [REDUCER_KEY_NOTE_V2]: noteModule.reducer,
  [REDUCER_KEY_PAY_IN]: payInModule.reducer,
  [REDUCER_KEY_PAYMENT]: paymentReducer,
  [REDUCER_KEY_PROPERTY_V2]: propertyModule.reducer,
  [REDUCER_KEY_PROPERTY]: propertyReducer,
  [REDUCER_KEY_REGISTRATION_DOCUMENT]: registrationDocumentReducer,
  [REDUCER_KEY_REGISTRATION]: registrationReducer,
  [REDUCER_KEY_RENT_DEPOSIT]: rentDepositReducer,
  [REDUCER_KEY_REPORT_BENEFICIARY]: reportBeneficiaryModule.reducer,
  [REDUCER_KEY_REQUIREMENTS]: requirementsReducer,
  [REDUCER_KEY_SOCKET]: socketReducer,
  [REDUCER_KEY_SYSTEM_LOCAL]: persistSystemLocalReducer,
  [REDUCER_KEY_TASK_V2]: taskModule.reducer,
  [REDUCER_KEY_TEMPLATE_DOCUMENT]: templateDocumentReducer,
  [REDUCER_KEY_TEMPLATE]: templateReducer,
});
