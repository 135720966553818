var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import DisplayText from "../DisplayText";
// 67px =  48px (time width) + 16px  (time margin) + 3px (half of dot width)
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-repeat: repeat-y;\n  background-image: ", ";\n  background-size: 2px 1px;\n  background-position: ", ";\n"], ["\n  background-repeat: repeat-y;\n  background-image: ", ";\n  background-size: 2px 1px;\n  background-position: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return "linear-gradient(to right, ".concat(theme.colors.neutral40, " 100%, ").concat(theme.colors.neutral40, " 0)");
}, function (_a) {
    var theme = _a.theme, isCompact = _a.isCompact;
    return isCompact ? "3px 0" : "".concat(rem("67px", theme.baseFontSize), " 0");
});
export var Item = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  & ~ & {\n    margin-top: ", ";\n  }\n"], ["\n  display: flex;\n  & ~ & {\n    margin-top: ", ";\n  }\n"])), spacing());
export var Time = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-width: ", ";\n  margin-right: ", ";\n"], ["\n  min-width: ", ";\n  margin-right: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("48px", theme.baseFontSize);
}, spacing());
export var Dot = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  min-width: 8px;\n  width: 8px;\n  height: 8px;\n  margin-top: 6px;\n  margin-right: ", ";\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 50%;\n"], ["\n  display: block;\n  min-width: 8px;\n  width: 8px;\n  height: 8px;\n  margin-top: 6px;\n  margin-right: ", ";\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 50%;\n"])), spacing(), function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral70;
}, function (_a) {
    var theme = _a.theme;
    return theme.getColor("white");
});
export var ContentWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n"], ["\n  display: flex;\n  flex-direction: row;\n"])));
export var ActivityContentWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var Message = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var Icon = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: ", ";\n"], ["\n  margin-right: ", ";\n"])), spacing());
export var Section = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  & + & {\n    margin-top: ", ";\n  }\n"], ["\n  & + & {\n    margin-top: ", ";\n  }\n"])), spacing("extraLoose"));
export var SectionHeading = styled(function (props) { return (_jsx(DisplayText, __assign({}, props))); })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-left: ", ";\n"], ["\n  margin-left: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return rem("88px", theme.baseFontSize);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
