export enum ETabs {
  Info = "info",
  History = "history",
}

export interface ITab {
  content: string;
  onClick: () => void;
  isActive: boolean;
}
