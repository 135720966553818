var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isToday, isYesterday, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { getName } from "../../redux/contact/contact.utils";
import { formatDate, formatLongDate, formatTime } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EChatCustomFilter } from "./chat.types";
export var getFormattedChatHeader = function (date) {
    if (isToday(date)) {
        return getLocalizedText("system.today");
    }
    if (isYesterday(date)) {
        return getLocalizedText("system.yesterday");
    }
    return formatLongDate(date);
};
export var getFormattedDate = function (date) {
    if (isToday(date)) {
        return formatTime(date);
    }
    return formatDate(date, "dd/MM/yyyy HH:mm");
};
export var getFormattedChatDate = function (_a) {
    var sendAt = _a.sendAt;
    return getFormattedDate(sendAt);
};
// 1x1 chat: name other person
// Group chat: chat name
export var getTitle = function (params) {
    var chat = params.chat;
    var name = chat.name, members = chat.members;
    if (!members) {
        return name;
    }
    var membersWithoutSelf = members.filter(function (member) { return !member.account.isSelf && !member.account.activeAccountId; });
    if (membersWithoutSelf.length === 1) {
        return getName(membersWithoutSelf[0].account);
    }
    return name;
};
// 1x1 chat: undefined
// Group chat: List of other members
export var getSubtitle = function (chat) {
    var members = chat.members;
    if (!members) {
        return undefined;
    }
    var membersWithoutSelf = members.filter(function (member) { return member.account.activeAccountId; });
    if (membersWithoutSelf.length <= 1) {
        return undefined;
    }
    var firstUser = membersWithoutSelf[0], secondUser = membersWithoutSelf[1];
    var firstUserName = getName(firstUser.account);
    var secondUserName = getName(secondUser.account);
    var rest = membersWithoutSelf.length > 2 ? " +".concat(membersWithoutSelf.length - 2) : "";
    return "".concat(firstUserName, ", ").concat(secondUserName).concat(rest);
};
export var getIdentifierForChatsByContactId = function (contactId) {
    return "contact-".concat(contactId);
};
export var getIdentifierForProperty = function (propertyId) {
    return "property-".concat(propertyId).toLowerCase();
};
export var getFilterForProperty = function (propertyId) { return ({
    propertyId: { eq: propertyId },
}); };
export var getChatsCustomFilters = function (archived) {
    return archived ? [EChatCustomFilter.Archived] : [EChatCustomFilter.Active];
};
export var getChatsQuery = function (params) {
    var _a = params.limit, limit = _a === void 0 ? 20 : _a, search = params.search, _b = params.sortField, sortField = _b === void 0 ? "lastMessageAt" : _b, _c = params.sortMethod, sortMethod = _c === void 0 ? "DESC" : _c, archived = params.archived, rest = __rest(params, ["limit", "search", "sortField", "sortMethod", "archived"]);
    var searchValue = search && search.length > 0 ? search : undefined;
    var customFilters = !searchValue
        ? getChatsCustomFilters(archived)
        : undefined;
    return __assign({ limit: limit, sort: [{ field: sortField, method: sortMethod }], search: searchValue, customFilters: customFilters }, rest);
};
