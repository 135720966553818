import styled, { spacing } from "@rentiohq/web-theme";

export const RentioLegalCardHeader = styled.div`
  display: flex;
  align-items: center;
`;

// export const RentioLegalStatusDotWrapper = styled.div`
//   padding-right: 16px;
// `;

export const RentioLegalOngoingCaseWrapper = styled.div`
  color: ${({ theme }) => theme.colors.warningText};
  display: flex;
  align-items: center;
  padding-left: ${spacing("extraLoose")};
  cursor: pointer;
`;

export const RentioLegalClosedCasesWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary[800]};
  border-top: 1px solid lightgrey;
  margin-top: ${spacing("loose")};
  padding-top: ${spacing("base")};
`;

export const RentioLegalClosedCase = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  padding-bottom: ${spacing("tight")};
`;
