import {
  EMoveServiceService,
  EMoveServiceManager,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import {
  getSchema as getSchema1,
  getValidate as getValidate1,
  uiSchema as uiSchema1,
} from "./schema.requestMoveService.1";
import {
  getSchema as getSchema2,
  getValidate as getValidate2,
  uiSchema as uiSchema2,
} from "./schema.requestMoveService.2";
import {
  getSchema as getSchema3,
  getValidate as getValidate3,
  uiSchema as uiSchema3,
} from "./schema.requestMoveService.3";
import { EField, IExtraData } from "./schema.requestMoveService.types";
import { getInitialValues } from "./schema.requestMoveService.utils";

export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);

  const showIfEnergy = (formData: any) => {
    const services = formData[EField.Services] as EMoveServiceService[];
    if (!services.includes(EMoveServiceService.Energy)) {
      return false;
    }

    const manager = formData[EField.Manager] as EMoveServiceManager;
    if (
      [
        EMoveServiceManager.TenantAndBroker,
        EMoveServiceManager.MijnVerhuis,
      ].includes(manager)
    ) {
      return false;
    }

    return true;
  };

  return [
    {
      schema: getSchema1(initialValues, extraData),
      uiSchema: uiSchema1(extraData),
      validate: getValidate1(extraData),
    },
    {
      schema: getSchema2(initialValues, extraData),
      uiSchema: uiSchema2(extraData),
      validate: getValidate2(extraData),
      showIf: showIfEnergy,
    },
    {
      schema: getSchema3(initialValues, extraData),
      uiSchema: uiSchema3(extraData),
      validate: getValidate3(extraData),
      showIf: showIfEnergy,
    },
  ];
};
