import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import {
  uiSchema as epcUiSchema,
  getSchema as getEpcSchema,
} from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EElectricityMeterType,
  EElectricityType,
  ENaturalGasAvailable,
  ENaturalGasMeterType,
  EPropertyAdjoined,
} from "@rentiohq/shared/dist/types/property.types";
import { EAN_MASK } from "forms/requestMoveService/schema.requestMoveService.config";
import { validationErrorsForEan } from "forms/requestMoveService/schema.requestMoveService.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation, UiSchema } from "react-native-jsonschema-form-test";
import {
  EExtraInfoGroup,
  EField,
  IExtraData,
} from "./schema.editPropertyExtraInfo.types";
import {
  propertyTypeCanHaveAdjoinedType,
  propertyTypeCanHaveFloors,
} from "./schema.editPropertyExtraInfo.utils";

export const getSchema =
  (
    initialValues: {
      [key: string]: any;
    },
    extraData: IExtraData,
  ) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    switch (extraData.extraInfoGroup) {
      case EExtraInfoGroup.Epc:
        return getEpcSchema(
          { property: extraData.property },
          initialValues,
        )(defaultValues);

      case EExtraInfoGroup.Bookkeeping:
        return {
          required: [],
          properties: {
            [EField.CostUnit]: {
              default: getValue(EField.CostUnit, initialValues, defaultValues),
              maxLength: 8,
            },
            [EField.BookkeepingPaidOutStartDate]: {
              default: getValue(
                EField.BookkeepingPaidOutStartDate,
                initialValues,
                defaultValues,
              ),
            },
          },
        };

      case EExtraInfoGroup.Electricity:
        return {
          required: [EField.ElectricityType],
          properties: {
            [EField.ElectricityType]: {
              default: getValue(
                EField.ElectricityType,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                EElectricityType.Single,
                EElectricityType.Double,
                EElectricityType.Unknown,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.electricity_type.${id}`.toLowerCase(),
                ),
              })),
            },

            [EField.ElectricityEan]: {
              default: getValue(
                EField.ElectricityEan,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
            },

            [EField.ElectricityMeterNumberBoth]: {
              default: getValue(
                EField.ElectricityMeterNumberBoth,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
            },
            [EField.ElectricityMeterType]: {
              default: getValue(
                EField.ElectricityMeterType,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                EElectricityMeterType.Standard,
                EElectricityMeterType.Opening,
                EElectricityMeterType.Budget,
                EElectricityMeterType.Empty,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.electricity_meter_type.${id}`.toLowerCase(),
                ),
              })),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
              requiredIf: (formData: any) =>
                formData[EField.ElectricityType] &&
                formData[EField.ElectricityType] !== EElectricityType.Unknown,
            },
          },
        };

      case EExtraInfoGroup.NaturalGas:
        return {
          required: [EField.NaturalGasAvailable],
          properties: {
            [EField.NaturalGasAvailable]: {
              default: getValue(
                EField.NaturalGasAvailable,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                ENaturalGasAvailable.Available,
                ENaturalGasAvailable.Unavailable,
                ENaturalGasAvailable.Unknown,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.natural_gas_type.${id}`.toLowerCase(),
                ),
              })),
            },
            [EField.NaturalGasEan]: {
              default: getValue(
                EField.NaturalGasEan,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
            },
            [EField.NaturalGasMeterNumber]: {
              default: getValue(
                EField.NaturalGasMeterNumber,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
            },
            [EField.NaturalGasMeterType]: {
              default: getValue(
                EField.NaturalGasMeterType,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                ENaturalGasMeterType.Standard,
                ENaturalGasMeterType.Opening,
                ENaturalGasMeterType.Budget,
                ENaturalGasMeterType.Empty,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `move.option.natural_gas_meter_type.${id}`.toLowerCase(),
                ),
              })),
              // @ts-ignore
              showIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
              requiredIf: (formData: any) =>
                formData[EField.NaturalGasAvailable] ===
                ENaturalGasAvailable.Available,
            },
          },
        };

      case EExtraInfoGroup.Water:
        return {
          required: [EField.WaterMeterNumber],
          properties: {
            [EField.WaterMeterNumber]: {
              default: getValue(
                EField.WaterMeterNumber,
                initialValues,
                defaultValues,
              ),
            },
          },
        };

      case EExtraInfoGroup.OtherProperties:
        const canHaveFloors = propertyTypeCanHaveFloors(extraData);
        const canHaveAdjoinedType = propertyTypeCanHaveAdjoinedType(extraData);

        return {
          required: [],
          properties: {
            [EField.BuildingSurfaceArea]: {
              default: getValue(
                EField.BuildingSurfaceArea,
                initialValues,
                defaultValues,
              ),
            },

            [EField.BuildingFloor]: {
              default: getValue(
                EField.BuildingFloor,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: () => canHaveFloors,
            },

            [EField.BuildingFloorCount]: {
              default: getValue(
                EField.BuildingFloorCount,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: () => canHaveFloors,
            },

            [EField.BuildingAdjoined]: {
              default: getValue(
                EField.BuildingAdjoined,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              showIf: () => canHaveAdjoinedType,
              // @ts-ignore
              options: [
                EPropertyAdjoined.Freestanding,
                EPropertyAdjoined.OneSide,
                EPropertyAdjoined.BothSides,
                EPropertyAdjoined.Unknown,
              ].map(id => ({
                id,
                value: getLocalizedText(
                  `property.adjoined.option.${id}.label`.toLowerCase(),
                ),
              })),
            },
            [EField.RoomCountBedroom]: {
              default: getValue(
                EField.RoomCountBedroom,
                initialValues,
                defaultValues,
              ),
            },
            [EField.RoomCountBathroom]: {
              default: getValue(
                EField.RoomCountBathroom,
                initialValues,
                defaultValues,
              ),
            },
            [EField.IsFurnished]: {
              default: getValue(
                EField.IsFurnished,
                initialValues,
                defaultValues,
              ),
              // @ts-ignore
              options: [
                {
                  value: getLocalizedText("system.no"),
                  id: "no",
                },
                {
                  value: getLocalizedText("system.yes"),
                  id: "yes",
                },
              ],
            },
          },
        };

      default:
        return {
          required: [],
          properties: {},
        };
    }
  };

export const uiSchema = (
  initialValues: {
    [key: string]: any;
  },
  extraData: IExtraData,
): UiSchema => ({
  "rentio:navigationTitle": getLocalizedText(
    `property.extra.${extraData.extraInfoGroup}.edit`.toLowerCase(),
  ),
  // EPC
  ...epcUiSchema({}, initialValues),

  // Bookkeeping
  [EField.CostUnit]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("property.extra.bookkeeping.item.cost_unit.title"),
  },
  [EField.BookkeepingPaidOutStartDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText(
      "property.extra.bookkeeping.item.bookkeeping_paid_out_start_date.title",
    ),
  },

  // Electricity
  [EField.ElectricityType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_type.title"),
  },
  [EField.ElectricityEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_ean.title"),
    optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
  },
  [EField.ElectricityMeterNumberBoth]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_meter_number_both.title"),
    optional: true,
  },
  [EField.ElectricityMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_meter_type.title"),
  },

  // Natural Gas
  [EField.NaturalGasAvailable]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_type.title"),
  },
  [EField.NaturalGasEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_ean.title"),
    optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
  },
  [EField.NaturalGasMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_meter_number.title"),
    optional: true,
  },
  [EField.NaturalGasMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_meter_type.title"),
  },

  // Water
  [EField.WaterMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.water_meter_number.title"),
    optional: true,
  },

  // Physical Info
  [EField.BuildingSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.other_properties.field.building_surface_area",
    ),
    suffixText: "m²",
    isFloat: true,
    optional: true,
  },

  [EField.BuildingFloor]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.other_properties.field.building_floor",
    ),
    optional: true,
  },
  [EField.BuildingFloorCount]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.other_properties.field.building_layers",
    ),
    optional: true,
  },
  [EField.BuildingAdjoined]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText(
      "property.extra.other_properties.field.building_adjoined",
    ),
    optional: true,
  },
  [EField.RoomCountBedroom]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.other_properties.field.room_count_bedroom",
    ),
    optional: true,
  },
  [EField.RoomCountBathroom]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "property.extra.other_properties.field.room_count_bathroom",
    ),
    optional: true,
  },
  [EField.IsFurnished]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText(
      "property.extra.other_properties.field.is_furnished",
    ),
    optional: true,
  },
});

export const getValidate =
  (extraData: IExtraData) => (formData: any, errors: FormValidation) => {
    switch (extraData.extraInfoGroup) {
      case EExtraInfoGroup.Electricity: {
        validationErrorsForEan(formData[EField.ElectricityEan]).forEach(
          error => {
            errors[EField.ElectricityEan].addError(error);
          },
        );

        break;
      }

      case EExtraInfoGroup.NaturalGas: {
        validationErrorsForEan(formData[EField.NaturalGasEan]).forEach(
          error => {
            errors[EField.NaturalGasEan].addError(error);
          },
        );

        break;
      }
    }

    return errors;
  };
