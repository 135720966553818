import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ts } from "../../services";
import { EField } from "./schema.addBankAccount.types";
export var getSchema = function (initialValues, _a) {
    var isBroker = _a.isBroker;
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Iban, EField.OwnerName],
            properties: (_a = {},
                _a[EField.Iban] = {
                    default: getValue(EField.Iban, initialValues, defaultValues),
                },
                _a[EField.OwnerName] = {
                    default: getValue(EField.OwnerName, initialValues, defaultValues),
                },
                _a[EField.IsThirdParty] = {
                    type: "boolean",
                    default: getValue(EField.IsThirdParty, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return isBroker; },
                },
                _a[EField.IsRevenue] = {
                    type: "boolean",
                    default: getValue(EField.IsRevenue, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return isBroker; },
                },
                _a[EField.IsInvoice] = {
                    type: "boolean",
                    default: getValue(EField.IsInvoice, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return isBroker; },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": ts.bankAccountAddModalHeading()
        },
        _a[EField.Iban] = {
            "ui:field": ERentioFormField.Iban,
            title: ts.bankAccountIbanLabel(),
            placeholder: ts.bankAccountIbanPlaceholder(),
        },
        _a[EField.OwnerName] = {
            "ui:field": ERentioFormField.Text,
            title: ts.bankAccountOwnerNameLabel(),
        },
        _a[EField.IsThirdParty] = {
            "ui:field": ERentioFormField.Switch,
            title: getLocalizedText("bank_account.type.label"),
            label: getLocalizedText("bank_account.type.is_third-party.label"),
        },
        _a[EField.IsRevenue] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("bank_account.type.is_revenue.label"),
        },
        _a[EField.IsInvoice] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("bank_account.type.is_invoice.label"),
        },
        _a);
};
export var getValidate = function (_a) {
    var isBroker = _a.isBroker;
    return function (formData, errors) {
        if (!isBroker) {
            return errors;
        }
        // You must pick one of the options.
        if (!formData[EField.IsThirdParty] &&
            !formData[EField.IsRevenue] &&
            !formData[EField.IsInvoice]) {
            // Only show error on last type.
            errors[EField.IsInvoice].addError(getLocalizedText("system.form.error.required"));
        }
        if (formData[EField.IsThirdParty] && formData[EField.IsRevenue]) {
            errors[EField.IsRevenue].addError(getLocalizedText("bank_account.type.error.third-party_account_only"));
        }
        if (formData[EField.IsThirdParty] && formData[EField.IsInvoice]) {
            errors[EField.IsInvoice].addError(getLocalizedText("bank_account.type.error.third-party_account_only"));
        }
        return errors;
    };
};
