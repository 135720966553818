var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, Text, TouchableOpacity, View, } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
import AspectRatio from "../AspectRatio";
export var OuterWrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  align-items: ", ";\n"], ["\n  width: 100%;\n  align-items: ", ";\n"])), function (props) { return (props.isSender ? "flex-end" : "flex-start"); });
export var Wrap = styled(TouchableOpacity)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: ", ";\n  max-width: 320px;\n  background-color: ", ";\n  border-radius: 4px;\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  padding: 12px;\n  ", "\n"], ["\n  width: ", ";\n  max-width: 320px;\n  background-color: ", ";\n  border-radius: 4px;\n  border-bottom-left-radius: ", ";\n  border-bottom-right-radius: ", ";\n  padding: 12px;\n  ", "\n"])), function (props) { return (props.hasAttachment ? "100%" : "auto"); }, function (props) {
    return props.isSender ? props.theme.colors.global5 : props.theme.colors.global4;
}, function (props) { return (props.isSender ? "4px" : "0px"); }, function (props) { return (props.isSender ? "0px" : "4px"); }, desktopStyles(css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    max-width: 480px;\n  "], ["\n    max-width: 480px;\n  "])))));
export var AspectRatioStyled = styled(AspectRatio)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 8px;\n"], ["\n  margin-bottom: 8px;\n"])));
export var AttachmentInnerWrap = styled(View)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.1);\n"], ["\n  width: 100%;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.1);\n"])));
export var AttachmentImage = styled(Image)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var AttachmentText = styled(Text)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  text-align: center;\n  padding-left: 24px;\n  padding-right: 24px;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  text-align: center;\n  padding-left: 24px;\n  padding-right: 24px;\n  color: ", ";\n"])), function (props) {
    return props.isSender ? props.theme.colors.neutral2 : props.theme.colors.global2;
});
export var SenderName = styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 2px;\n  color: ", ";\n  font-size: 14px;\n  font-style: italic;\n"], ["\n  margin-bottom: 2px;\n  color: ", ";\n  font-size: 14px;\n  font-style: italic;\n"])), function (props) {
    return props.isSender ? props.theme.colors.neutral2 : props.theme.colors.neutral1;
});
export var Message = styled(Text)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
    return props.isSender ? props.theme.colors.neutral2 : props.theme.colors.global2;
}, function (props) { return (props.isOnlyEmojis ? "40px" : "14px"); });
export var StatusWrap = styled(View)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex-direction: row;\n  justify-content: ", ";\n  margin-top: 6px;\n"], ["\n  flex-direction: row;\n  justify-content: ", ";\n  margin-top: 6px;\n"])), function (props) { return (props.isSender ? "flex-end" : "flex-start"); });
export var Date = styled(Text)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n"], ["\n  color: ", ";\n  font-size: 14px;\n"])), function (props) {
    return props.isSender ? props.theme.colors.neutral2 : props.theme.colors.global2;
});
var statusIcons = css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  height: 12px;\n  margin-left: 6px;\n  margin-top: 2px;\n"], ["\n  height: 12px;\n  margin-left: 6px;\n  margin-top: 2px;\n"])));
export var SentIcon = styled(Image).attrs(function () { return ({
    resizeMode: "contain",
}); })(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n  width: 15px;\n"], ["\n  ", ";\n  width: 15px;\n"])), statusIcons);
export var DeliveredIcon = styled(Image).attrs(function () { return ({
    resizeMode: "contain",
}); })(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", ";\n  width: 25px;\n"], ["\n  ", ";\n  width: 25px;\n"])), statusIcons);
export var ReadIcon = styled(Image).attrs(function () { return ({
    resizeMode: "contain",
}); })(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ", ";\n  width: 25px;\n"], ["\n  ", ";\n  width: 25px;\n"])), statusIcons);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
