import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ESpacings } from "@rentiohq/web-shared/dist/components";
import Banner from "@rentiohq/web-shared/dist/components/Banner";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import BrandColorPicker from "../components/SettingsColorPicker";
import SettingsImageUpload from "../components/SettingsImageUpload";
import CommunicationPreview from "./Communication/CommunicationPreview";

const PREFERENCE_KEY_BRAND_COLOR = "BRAND_COLOR";
const PREFERENCE_KEY_BRAND_LOGO = "BRAND_LOGO";

const TemplatePreferences = () => {
  const { broker } = authHooks.useSelf();

  if (!broker) return null;

  return (
    <>
      <Box mb={ESpacings.loose}>
        <Banner
          hasDismiss={false}
          icon="leaseContract"
          title={getLocalizedText("broker.template_preferences.info.heading")}
        />

        <BrandColorPicker
          preferenceKey={PREFERENCE_KEY_BRAND_COLOR}
          preferencePersistScope={EPreferencePersistScope.RemoteBroker}
          modalHeading={getLocalizedText(
            "editor.template_document.document_config.brand_color.title",
          )}
          title={getLocalizedText(
            "editor.template_document.document_config.brand_color.info",
          )}
        />

        <Spacer weight={ESpacerWeight.W32} />

        <SettingsImageUpload
          preferenceKey={PREFERENCE_KEY_BRAND_LOGO}
          preferencePersistScope={EPreferencePersistScope.RemoteBroker}
          title={getLocalizedText(
            "editor.template_document.document_config.brand_logo.info",
          )}
          fileNamePrefix="Logo"
          tags={["templateDocument"]}
        />

        <Spacer weight={ESpacerWeight.W32} />

        <CommunicationPreview
          title={getLocalizedText(
            "editor.template_document.document_config.communication_preview.title",
          )}
          logoPreferenceKey={PREFERENCE_KEY_BRAND_LOGO}
          textColorPreferenceKey={PREFERENCE_KEY_BRAND_COLOR}
        />
      </Box>
    </>
  );
};

export default TemplatePreferences;
