import { EDamageType } from "@rentiohq/shared/dist/types/task.types";
import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createTask.types";
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        return {
            required: [
                EField.IncidentDate,
                EField.DamageType,
                EField.ContactPerson,
                EField.Payer,
            ],
            properties: (_a = {},
                _a[EField.IncidentDate] = {
                    default: getValue(EField.IncidentDate, initialValues, defaultValues),
                },
                _a[EField.DamageType] = {
                    default: getValue(EField.DamageType, initialValues, defaultValues),
                    //@ts-ignore
                    options: Object.values(EDamageType).map(function (damageType) { return ({
                        value: getLocalizedText("task.damage_type.".concat(damageType.toLowerCase())),
                        id: damageType,
                    }); }),
                },
                _a[EField.ContactPerson] = {
                    default: getValue(EField.ContactPerson, initialValues, defaultValues),
                },
                _a[EField.Payer] = {
                    default: getValue(EField.Payer, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, defaultValues) {
    var _a;
    return _a = {
            "rentio:navigationTitle": getLocalizedText("task.repair.keypoint_task"),
            "rentio:title": getLocalizedText("task.repair.keypoint_task")
        },
        _a[EField.IncidentDate] = {
            "ui:field": ERentioFormField.Date,
            maxDate: new Date(),
            title: getLocalizedText("task.repair.keypoint.incident_date"),
            formatSummary: function (formData) { return formData && formatDate(formData); },
            optional: false,
        },
        _a[EField.DamageType] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("task.repair.keypoint.damage_type"),
            formatSummary: function (formData) {
                return formData
                    ? getLocalizedText("task.damage_type.".concat(formData).toLowerCase())
                    : "";
            },
            optional: false,
        },
        _a[EField.ContactPerson] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("task.repair.keypoint.contact_person"),
            selectContactFromListCtaTitle: getLocalizedText("task.repair.keypoint.contact_person.select"),
            addCtaLabel: getLocalizedText("task.repair.keypoint.add_contact_person"),
            isMultiSelect: false,
            asOptionList: true,
            customGetSubtitleSelector: function () { return undefined; },
            valueSelector: function (state, selectedAccountIds) {
                if (selectedAccountIds === void 0) { selectedAccountIds = []; }
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds);
            },
            customExtraAccountsSelector: function () { return []; },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a[EField.Payer] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("task.repair.keypoint.payer"),
            selectContactFromListCtaTitle: getLocalizedText("task.repair.keypoint.payer.select"),
            addCtaLabel: getLocalizedText("task.repair.keypoint.add_payer"),
            isMultiSelect: false,
            asOptionList: true,
            customGetSubtitleSelector: function () { return undefined; },
            valueSelector: function (state, selectedAccountIds) {
                if (selectedAccountIds === void 0) { selectedAccountIds = []; }
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds);
            },
            customExtraAccountsSelector: function () { return []; },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a;
};
