import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registration.types";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    extraData: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    const showIfShouldUpload = (formData: any) =>
      formData[EField.LocationDescriptionShouldUpload] === "now";

    return {
      required: [],
      properties: {
        title1: {
          default: undefined,
        },
        info1: {
          default: undefined,
        },
        [EField.LocationDescriptionShouldUpload]: {
          default: getValue(
            EField.LocationDescriptionShouldUpload,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: ["now", "later"].map(id => ({
            id,
            value: getLocalizedText(`system.${id.toLowerCase()}`),
          })),
        },
        [EField.LocationDescriptionDocumentToUpload]: {
          default: getValue(
            EField.LocationDescriptionDocumentToUpload,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: showIfShouldUpload,
          // @ts-ignore
          requiredIf: showIfShouldUpload,
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData) => {
  return {
    "rentio:title": utils.i18n.t(
      "registration.create.form.step.location_description_select",
    ),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t(
        "registration.create.form.field.location_description_title",
      ),
    },
    info1: {
      "ui:field": ERentioFormField.HelpInfo,
      subtitle: getLocalizedText(
        "registration.create.form.field.location_description_info",
      ),
    },
    [EField.LocationDescriptionShouldUpload]: {
      "ui:field": ERentioFormField.SegmentedControl,
      title: getLocalizedText(
        "registration.create.form.field.location_description_should_upload",
      ),
      formatSummary: (formData: any) => getLocalizedText(`system.${formData}`),
      shouldShowOnSummary: (formData: any) =>
        formData[EField.LocationDescriptionShouldUpload] === "later",
    },
    [EField.LocationDescriptionDocumentToUpload]: {
      "ui:field": ERentioFormField.DocumentSelectInline,
      valueSelector: (state: IPartialRootState, formData: any[] = []) =>
        (formData || [])
          .map(item => {
            if (typeof item === "string") {
              const document = state.DOCUMENTS_V2.detail[item]?.entity;
              if (document) {
                return document.filename;
              }

              return getLocalizedText("summary.view.document");
            }

            return item.name;
          })
          .join(", "),
      accept: ["application/pdf"],
      mimeTypes: ["application/pdf"],
      multiple: false,
      showOptionsOnFirstFocus: true,
      useExistingDocuments: true,
      getExistingDocumentsWhereFilter: () => {
        if (!extraData.property) {
          return {};
        }

        return {
          propertyId: { eq: extraData.property.id },
        };
      },
      title: getLocalizedText(
        "registration.create.form.field.location_description_upload_document.title",
      ),
      info: getLocalizedText(
        "registration.create.form.field.location_description_upload_document.info",
      ),
      hideOnSummaryIfEmpty: true,
    },
  };
};
