var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import images from "@rentiohq/shared-frontend/dist/components/assets";
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import styledNative from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import { Image, TouchableOpacity } from "react-native";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  /* background-color: green; */\n  margin: 24px 0;\n"], ["\n  /* background-color: green; */\n  margin: 24px 0;\n"])));
export var TitleButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var TitleWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 24px 0 12px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 24px 0 12px;\n"])));
export var TitleIconWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var InfoButton = styledNative(TouchableOpacity)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  padding-top: 2px;\n  padding-left: 8px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 24px;\n  height: 24px;\n  padding-top: 2px;\n  padding-left: 8px;\n  align-items: center;\n  justify-content: center;\n"])));
export var InfoIcon = styledNative(Image).attrs(function () { return ({
    source: images.ICO_INFO_CIRCLE_GREEN,
}); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n"], ["\n  width: 16px;\n  height: 16px;\n"])));
export var CompletedBadge = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 3px 8px 4px;\n  border-radius: 20px;\n  font-size: 11px;\n  font-weight: bold;\n  color: ", ";\n  background-color: ", ";\n"], ["\n  padding: 3px 8px 4px;\n  border-radius: 20px;\n  font-size: 11px;\n  font-weight: bold;\n  color: ", ";\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.neutral2; }, function (props) {
    if (props.isDone) {
        return props.theme.colors.successText;
    }
    // if (props.isEmpty) {
    //   return props.theme.colors.global2;
    // }
    return props.theme.colors.errorText;
});
export var InfoRemoveWrap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-left: 44px;\n  display: flex;\n  flex-direction: row;\n"], ["\n  padding-left: 44px;\n  display: flex;\n  flex-direction: row;\n"])));
export var RemoveWrap = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: none;\n  margin-left: 6px;\n"], ["\n  display: none;\n  margin-left: 6px;\n"])));
export var InstanceWrap = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  &:hover {\n    ", " {\n      display: inherit;\n    }\n  }\n"], ["\n  &:hover {\n    ", " {\n      display: inherit;\n    }\n  }\n"])), RemoveWrap);
export var VariableWrap = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 0 24px 0 0;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  padding: 0 24px 0 0;\n  flex-direction: row;\n  align-items: center;\n"])));
export var NextPreviousWrap = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding: 0 24px 0 48px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 100%;\n"], ["\n  padding: 0 24px 0 48px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
