import { IPagedQuery } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import { EContractIndexationStatus } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import { endOfDay } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { TAppearance } from "@rentiohq/web-shared/dist/types";

export const ORDER_MAP = ["createdAt", "", "", "", ""];

export const DEFAULT_FILTER: Partial<IPagedQuery> = {
  filter: {
    and: [
      {
        stopDate: {
          gt: endOfDay(new Date()),
        },
      },
    ],
  },
  propertyRolesFilter: [EPropertyMemberTypes.IndexationManager],
  sort: [{ field: "indexableAt" }],
};

export enum EAlreadyIndexed {
  AlreadyIndexed = "ALREADY_INDEXED",
  NeverIndexed = "NEVER_INDEXED",
}

export const getFilterQuery = (queryParams: {
  page?: number;
  query?: string;
  indexationStatuses?: EContractIndexationStatus[];
  alreadyIndexed?: EAlreadyIndexed;
}): IPagedQuery => {
  const { page = 1, query, indexationStatuses, alreadyIndexed } = queryParams;
  let newFilter: IPagedQuery = {
    page,
    limit: 20,
    propertyRolesFilter: [EPropertyMemberTypes.IndexationManager],
    sort: [{ field: "indexableAt" }],
  };

  const and: { [key: string]: any }[] = [
    {
      stopDate: {
        gt: endOfDay(new Date()),
      },
    },
  ];

  if (indexationStatuses?.length) {
    and.push({
      indexationStatus: {
        in: indexationStatuses,
      },
    });
  }

  if (alreadyIndexed === EAlreadyIndexed.AlreadyIndexed) {
    and.push({
      lastIndexedAt: {
        isNot: null,
      },
    });
  } else if (alreadyIndexed === EAlreadyIndexed.NeverIndexed) {
    and.push({
      lastIndexedAt: {
        is: null,
      },
    });
  }

  if (and.length > 0) {
    newFilter = {
      ...newFilter,
      filter: {
        and,
      },
    };
  }

  if (query && query.length > 0) {
    newFilter = { ...newFilter, search: query };
  }

  return newFilter;
};

export const getIndexationStatusVariation = (
  indexationStatus?: EContractIndexationStatus,
): TAppearance => {
  switch (indexationStatus) {
    case EContractIndexationStatus.OwnerApproved:
    case EContractIndexationStatus.OwnerDeclined:
      return "error";

    case EContractIndexationStatus.Ready:
    case EContractIndexationStatus.Muted:
    case EContractIndexationStatus.OwnerAsked:
      return "warning";

    case EContractIndexationStatus.NotReady:
    default:
      return "success";
  }
};
