var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import sortBy from "lodash/sortBy";
export var sortFields = function (fields) {
    var result = __assign({}, fields);
    result.groups = sortBy(fields.groups, function (x) { return getLocalizedText(x.name); });
    result.variables = sortBy(fields.variables, function (x) { return getLocalizedText(x.name); });
    return result;
};
