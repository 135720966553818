var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { animationEasing, animationSpeed, FontWeights, spacing, } from "@rentiohq/web-theme";
import { rem, transparentize } from "polished";
import media from "styled-media-query";
import { centerContent } from "../Page/Page.styled";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  padding: ", " 0 0;\n"], ["\n  ", "\n  padding: ", " 0 0;\n"])), centerContent, spacing("extraLoose"));
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0 ", ";\n  ", "\n  ", "\n"], ["\n  margin: 0 ", ";\n  ", "\n  ", "\n"])), spacing("extraLoose"), function (_a) {
    var hasTabs = _a.hasTabs, theme = _a.theme;
    return hasTabs ? "border-bottom: 1px solid ".concat(theme.getColor("gray", 200), ";") : "";
}, function (_a) {
    var hasTabs = _a.hasTabs, hasBorder = _a.hasBorder, theme = _a.theme;
    return hasBorder
        ? "\n    padding-bottom: ".concat(spacing("loose"), "\n    border-bottom: 1px solid ").concat(theme.getColor("gray", 200), ";\n  ")
        : "";
});
export var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1 0 auto;\n  /* padding: 0 ", "; */\n  /* display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  flex: 1 1 auto; */\n"], ["\n  flex: 1 0 auto;\n  /* padding: 0 ", "; */\n  /* display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  flex: 1 1 auto; */\n"])), spacing("extraLoose"));
export var ContentMain = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1 1 auto;\n  &:first-child {\n    margin-left: 0;\n  }\n"], ["\n  flex: 1 1 auto;\n  &:first-child {\n    margin-left: 0;\n  }\n"])));
export var IconWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: ", ";\n  margin-right: ", ";\n  flex-shrink: 0;\n  display: none;\n  ", "\n"], ["\n  margin-top: ", ";\n  margin-right: ", ";\n  flex-shrink: 0;\n  display: none;\n  ", "\n"])), spacing(), spacing("loose"), media.greaterThan(
// @ts-ignore
"1464px")(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: block;  \n  "], ["\n    display: block;  \n  "]))));
export var ImageWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: relative;\n  flex-shrink: 0;\n  overflow: hidden;\n  height: ", ";\n  margin-right: ", ";\n  border-radius: 50%;\n  display: none;\n  ", "\n  img {\n    height: 100%;\n  }\n  @supports (object-fit: cover) {\n    width: ", ";\n    img {\n      width: 100%;\n      object-fit: cover;\n    }\n  }\n"], ["\n  position: relative;\n  flex-shrink: 0;\n  overflow: hidden;\n  height: ", ";\n  margin-right: ", ";\n  border-radius: 50%;\n  display: none;\n  ", "\n  img {\n    height: 100%;\n  }\n  @supports (object-fit: cover) {\n    width: ", ";\n    img {\n      width: 100%;\n      object-fit: cover;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return rem(100, theme.baseFontSize);
}, spacing("loose"), media.greaterThan(
// @ts-ignore
"756px")(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: block;  \n  "], ["\n    display: block;  \n  "]))), function (_a) {
    var theme = _a.theme;
    return rem(100, theme.baseFontSize);
});
export var UploadIconWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  opacity: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: ", ";\n  background: ", ";\n  transition: opacity ", " ", ";\n"], ["\n  position: absolute;\n  opacity: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: ", ";\n  background: ", ";\n  transition: opacity ", " ", ";\n"])), function (props) { return props.theme.colors.neutral10; }, function (props) {
    return transparentize(0.35, props.theme.colors.neutral100);
}, animationSpeed("fast"), animationEasing("easeInOut"));
export var ImageUploadWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  cursor: pointer;\n  height: ", ";\n  ", "\n  &:hover {\n    ", " {\n      opacity: 1;\n    }\n  }\n"], ["\n  cursor: pointer;\n  height: ", ";\n  ", "\n  &:hover {\n    ", " {\n      opacity: 1;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return rem(100, theme.baseFontSize);
}, function (_a) {
    var isDragActive = _a.isDragActive;
    return isDragActive
        ? "opacity: .2;\n  ".concat(UploadIconWrapper, " {\n    opacity: 1;\n  }\n  ")
        : "";
}, UploadIconWrapper);
export var SubTitle = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.getColor("gray", 400);
}, function (_a) {
    var theme = _a.theme;
    return rem("16px", theme.baseFontSize);
});
export var Breadcrumbs = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: ", ";\n  font-weight: ", ";\n  ", ";\n"], ["\n  width: 100%;\n  margin-bottom: ", ";\n  font-weight: ", ";\n  ", ";\n"])), spacing("loose"), FontWeights.SemiBold, function (_a) {
    var theme = _a.theme;
    return "\n    color: ".concat(theme.getColor("primary"), "\n  ");
});
export var ContentAside = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-left: ", ";\n  margin-top: ", ";\n  display: flex;\n"], ["\n  margin-left: ", ";\n  margin-top: ", ";\n  display: flex;\n"])), spacing(), spacing("tight"));
export var Tabs = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject([""], [""])));
export var Actions = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
