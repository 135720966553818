var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EContractMemberTypes, EContractType, } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyMemberTypes, } from "@rentiohq/shared/dist/types/property.types";
import compact from "lodash/compact";
import { createRentDeposit } from "../../redux/rentDeposit/rentDeposit.actions";
import { EField } from "./schema.createRentDeposit.types";
import { getTenants, showInsurance } from "./schema.createRentDeposit.utils";
export var isPerformingSubmitActionSelector = function () { return function (state) {
    return !!state.rentDeposit.isCreatingRentDeposit;
}; };
export var performingSubmitActionResultSelector = function () { return function (state) {
    return state.rentDeposit.createdRentDepositId;
}; };
export var performingSubmitActionErrorSelector = function () { return function (state) {
    return state.rentDeposit.createRentDepositError;
}; };
export var submitActionCreator = function (extraData) { return function (formData) {
    var property = extraData.property, contract = extraData.contract;
    var tenants = getTenants(formData, extraData);
    var broker = property.members.find(function (member) {
        return member.roles.includes(EPropertyMemberTypes.Broker);
    });
    var signers = __spreadArray([], (formData[EField.Signers] || []), true);
    var signAsBroker = formData[EField.SignAsBroker] || [];
    if (signAsBroker.length > 0) {
        signers.push(+signAsBroker[0]);
    }
    var mappedSigners = compact(signers.map(function (accountId) {
        var _a;
        if (!contract) {
            return undefined;
        }
        var contractMember = contract.members.find(function (member) { return member.account.id === accountId; });
        var roles = contractMember
            ? __spreadArray(__spreadArray([], contractMember.roles.filter(function (role) {
                return [
                    EContractMemberTypes.Tenant,
                    EContractMemberTypes.Parent,
                    EContractMemberTypes.Owner,
                ].includes(role);
            }), true), [
                EContractMemberTypes.Signer,
            ], false) : [EContractMemberTypes.Signer];
        if ((_a = formData[EField.Payer]) === null || _a === void 0 ? void 0 : _a.includes(accountId)) {
            roles.push(EContractMemberTypes.Payer);
        }
        if (tenants.length === 1 &&
            ((contractMember === null || contractMember === void 0 ? void 0 : contractMember.roles.includes(EContractMemberTypes.Tenant)) ||
                (contractMember === null || contractMember === void 0 ? void 0 : contractMember.roles.includes(EContractMemberTypes.Parent)))) {
            roles.push(EContractMemberTypes.Payer);
        }
        // Is the signer also a broker?
        var isBroker = property.members.find(function (member) {
            return member.account.id === accountId &&
                member.roles.includes(EPropertyMemberTypes.Broker);
        });
        if (isBroker) {
            roles.push(EContractMemberTypes.Broker);
        }
        return {
            roles: roles,
            accountId: accountId,
        };
    }));
    if (broker) {
        var brokerIsSigner = signers.find(function (signer) { return signer.accountId === broker.account.id; });
        if (!brokerIsSigner) {
            signers.push({
                accountId: broker.account.id,
                roles: [EContractMemberTypes.Broker],
            });
        }
    }
    var rentDepositContract = {
        propertyId: property.id,
        relatedContractId: contract.id,
        contractType: EContractType.RentDeposit,
        startDate: formData[EField.StartDate],
        dueDate: formData[EField.DueDate],
        amount: formData[EField.Amount],
        members: mappedSigners,
        locale: formData[EField.Locale],
        rentDepositValidations: formData[EField.IdentityDocuments].map(function (item) {
            return item;
        }),
    };
    var updatedProperty;
    var requestInsurance = showInsurance(extraData) &&
        formData[EField.InsuranceSendProposal] === "yes";
    if (requestInsurance) {
        updatedProperty = {
            buildingSurfaceArea: formData[EField.InsuranceBuildingSurfaceArea],
            buildingHasHardMaterials: formData[EField.InsuranceBuildingHasHardMaterials],
        };
        var buildingFloorCount = formData[EField.InsuranceBuildingLayers];
        if (buildingFloorCount) {
            updatedProperty.buildingFloorCount = buildingFloorCount;
        }
        var buildingAdjoined = formData[EField.InsuranceBuildingAdjoined];
        if (buildingAdjoined) {
            updatedProperty.buildingAdjoined = buildingAdjoined;
        }
    }
    return createRentDeposit.actions.start({
        data: rentDepositContract,
        updatedProperty: updatedProperty,
        requestInsurance: requestInsurance ? { contract: contract } : undefined,
    });
}; };
