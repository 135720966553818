import ChatMessage from "@rentiohq/shared-frontend/dist/components/components/ChatMessage";
import { generateRef } from "@rentiohq/shared-frontend/dist/redux/socket/socket.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types/types";
import { getMessagesStart } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import {
  IMessagesDataSource,
  getIsChatExhausted,
  getMessagesSelector,
  makeGetMessagesDataSource,
} from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { IChat, IMessage } from "@rentiohq/shared/dist/types/chat.types";
import { subDays } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { Loading, TextStyle } from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button/Button";
import utils from "@rentiohq/web-shared/dist/utils";
import { spacing } from "@rentiohq/web-theme";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ts from "../../../../../../services/translationService";
import * as S from "./ChatMessages.styled";
import { TypingIndicator } from "./components/TypingIndicator";

interface IProps {
  chatId: number;
  chat: IChat;
  onPressMessage: (message: IMessage) => void;
  onLongPressMessage?: (message: IMessage) => void;
  onPressUrl?: (url: string, message: IMessage) => void;
}

export const ChatMessages: React.FC<IProps> = ({
  chatId,
  chat,
  onPressMessage,
  onLongPressMessage,
  onPressUrl,
}) => {
  const containerRef: any = React.useRef();

  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("wheel", invertedWheelEvent);
    }
    return () => {};
  }, [containerRef]);

  const getMessagesDataSource = makeGetMessagesDataSource();

  const messages = useSelector((state: IPartialRootState) =>
    getMessagesSelector(state, chatId),
  );
  const messagesDataSource: IMessagesDataSource[] =
    useSelector((state: IPartialRootState) =>
      getMessagesDataSource(state, chatId),
    ) || [];

  const isExhausted = useSelector(
    (state: IPartialRootState) => getIsChatExhausted(state, chatId) || false,
  );

  const invertedWheelEvent = (e: any) => {
    e.preventDefault?.();
    e.stopPropagation?.();
    e.stopImmediatePropagation?.();
    e.nativeEvent?.stopImmediatePropagation?.();

    containerRef.current.scrollTop -= e.deltaY;
  };

  const handlePress = (message: IMessage) => () => {
    onPressMessage(message);
  };

  const handleLongPress = (message: IMessage) => () => {
    onLongPressMessage?.(message);
  };

  const handlePressUrl = (message: IMessage) => (url: string) => {
    onPressUrl?.(url, message);
  };

  const renderMessage = (item: string | IMessage, index: number) => {
    if (typeof item === "string") {
      if (item === "typing") {
        return (
          <S.ItemWrap key={`chat-message-${index}`}>
            <TypingIndicator />
          </S.ItemWrap>
        );
      }
    }

    const message = item as IMessage;
    const { sendBy, text } = message;

    if (!sendBy) {
      if (!text) {
        return null;
      }

      return (
        <S.System key={`chat-message-${index}`}>
          <TextStyle variation="subdued" size="small">
            {text}
          </TextStyle>
        </S.System>
      );
    }

    return (
      <S.ItemWrap key={`chat-message-${index}`}>
        <ChatMessage
          chat={chat}
          message={message}
          onPress={handlePress(message)}
          onLongPress={handleLongPress(message)}
          onPressUrl={handlePressUrl(message)}
          conversationIsActive={!document.hidden}
        />
      </S.ItemWrap>
    );
  };

  return (
    <S.Wrapper ref={containerRef}>
      {!messages ? (
        <Loading />
      ) : (
        <>
          {messagesDataSource.map((section, index) => {
            return (
              <React.Fragment key={index}>
                {section.data.map(renderMessage)}
                {section.key && (
                  <S.System>
                    <TextStyle variation={["strong", "subdued"]}>
                      {utils.date.diffForHumans(new Date(section.key), {
                        to: subDays(new Date(), 2),
                      })}
                    </TextStyle>
                  </S.System>
                )}
              </React.Fragment>
            );
          })}
          {!isExhausted && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: spacing("loose"),
              }}
            >
              <Button
                size="slim"
                onClick={() => {
                  const ref = generateRef();
                  dispatch(
                    getMessagesStart.getAction({
                      chatId,
                      shouldReset: false,
                      ref,
                    }),
                  );
                }}
              >
                {ts.chatsListShowMore()}
              </Button>
            </div>
          )}
        </>
      )}
    </S.Wrapper>
  );
};
