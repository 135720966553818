var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { compact } from "lodash";
import { useNavigate } from "react-router";
import { ts } from "../../services";
import { ContactConnectionIndicator } from "../ContactConnectionIndicator";
import Icon from "../Icon";
import { ResourceListItem } from "../ResourceList";
import TextStyle from "../TextStyle";
export var ContactInfoListItem = function (props) {
    var contact = props.contact, _a = props.accounts, accounts = _a === void 0 ? [] : _a, employeeAccount = props.employeeAccount, _b = props.actions, actions = _b === void 0 ? [] : _b, _c = props.getActions, getActions = _c === void 0 ? function () { return []; } : _c, setContactToEdit = props.setContactToEdit, getSubtitle = props.getSubtitle, getExtraSubtitle = props.getExtraSubtitle, resourceListItemProps = props.resourceListItemProps;
    var navigate = useNavigate();
    var iconSource = contact.isCompany ? "briefcase" : "profile";
    var subtitle = getSubtitle === null || getSubtitle === void 0 ? void 0 : getSubtitle(contact);
    var extraSubtitle = getExtraSubtitle === null || getExtraSubtitle === void 0 ? void 0 : getExtraSubtitle(contact);
    var originalAccount = accounts.find(function (randomAccount) {
        return contact.accountIds.includes(randomAccount.id);
    });
    var handleEditContactClick = function () {
        setContactToEdit(contact);
    };
    var mergedActions = __spreadArray(__spreadArray(__spreadArray([], getActions(contact).map(function (action) { return (__assign(__assign({}, action), { onClick: function () {
            var _a;
            return (_a = action.onClick) === null || _a === void 0 ? void 0 : _a.call(action, contact.accountId, originalAccount === null || originalAccount === void 0 ? void 0 : originalAccount.id);
        } })); }), true), actions.map(function (action) { return (__assign(__assign({}, action), { onClick: function () {
            var _a;
            return (_a = action.onClick) === null || _a === void 0 ? void 0 : _a.call(action, contact.accountId, originalAccount === null || originalAccount === void 0 ? void 0 : originalAccount.id);
        } })); }), true), [
        {
            content: ts.contactEditContact(),
            media: _jsx(Icon, { source: "edit" }),
            onClick: handleEditContactClick,
        },
        {
            content: ts.contactViewContactAction(),
            media: _jsx(Icon, { source: "contact" }),
            isContactAction: true,
            onClick: function () {
                navigate("/contacts/".concat(contact.id));
            },
        },
    ], false);
    var renderName = function () {
        if (employeeAccount) {
            return (_jsxs("div", { children: [getName(employeeAccount), " (", getName(contact), ")"] }));
        }
        return _jsx("div", { children: getName(contact) });
    };
    var renderContactInfoSubtitle = function () { return (_jsxs("div", __assign({ style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        } }, { children: [_jsx(ContactConnectionIndicator, { contact: contact }), _jsx(TextStyle, __assign({ variation: "subdued", size: "small", element: "div" }, { children: compact([
                    contact.phone ? formatPhone(contact.phone) : undefined,
                    contact.email,
                ]).join(" | ") }))] }))); };
    var renderSubtitle = function () {
        if (!subtitle) {
            return null;
        }
        if (subtitle === null || subtitle === void 0 ? void 0 : subtitle.hasOwnProperty("type")) {
            return subtitle;
        }
        return (_jsx(TextStyle, __assign({ variation: "subdued", size: "small" }, { children: subtitle })));
    };
    return (_jsxs(ResourceListItem, __assign({ media: _jsx(Icon, { source: iconSource }), item: contact, actions: mergedActions }, resourceListItemProps, { children: [renderName(), renderContactInfoSubtitle(), renderSubtitle(), !!extraSubtitle && _jsx("div", { children: extraSubtitle })] })));
};
