import { EContactInitialType } from "@rentiohq/shared/dist/types/contact.types";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import { memberHasOneOfRoles } from "@rentiohq/shared/dist/utils/roles.utils";
import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { formatAddress } from "../../utils/address.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createContract.types";
import { getNavigationTitle } from "./schema.createContract.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Owners],
            properties: (_a = {},
                _a[EField.Header + "2"] = {
                    default: undefined,
                },
                _a[EField.Owners] = {
                    default: getValue(EField.Owners, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("contract_info.owners.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData),
            "rentio:navigationSubTitle": formatAddress(extraData.property, false)
        },
        _a[EField.Header + "2"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.owners.heading"),
        },
        _a[EField.Owners] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("contract_info.owners.field"),
            selectContactFromListCtaTitle: getLocalizedText("contract_info.owners.select_other.cta"),
            isMultiSelect: true,
            prefetchDefault: true,
            initialType: EContactInitialType.Owner,
            // Custom selectors & actions
            valueSelector: function (state, selectedAccountIds) {
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, extraData.property.members.map(function (member) { return member.account; }));
            },
            customGetSubtitleSelector: function (state, formData, item) {
                var accountId = item.accountId, contact = item.contact;
                var member = extraData.property.members.find(function (member) {
                    if (accountId && accountId === member.account.id) {
                        return true;
                    }
                    if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
                        return true;
                    }
                    return false;
                });
                if (!member) {
                    return;
                }
                return member.roles
                    .filter(function (role) {
                    return ![
                        EPropertyMemberTypes.ContractMember,
                        EPropertyMemberTypes.TaskMember,
                    ].includes(role);
                })
                    .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
                    .join(", ");
            },
            customExtraAccountsSelector: function () {
                return extraData.property.members
                    .filter(function (member) {
                    var isOwnerOrManager = memberHasOneOfRoles(member, [
                        EPropertyMemberTypes.Owner,
                        EPropertyMemberTypes.TechnicalManager,
                        EPropertyMemberTypes.FinancialManager,
                    ]);
                    if (isOwnerOrManager) {
                        return true;
                    }
                    return false;
                })
                    .map(function (member) { return member.account; });
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a);
};
