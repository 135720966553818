var _a, _b, _c;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./application.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["UpdateApplicationStep"] = "UPDATE_APPLICATION_STEP";
    EActionCustom["DuplicateApplication"] = "DUPLICATE_APPLICATION";
    EActionCustom["getCountSteps"] = "COUNT_APPLICATION_STEPS";
})(EActionCustom || (EActionCustom = {}));
var generateUpdateApplicationStepActions = function () { return ({
    updateApplicationStepStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UpdateApplicationStep,
        state: EActionState.Start,
    }),
    updateApplicationStepSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UpdateApplicationStep,
        state: EActionState.Success,
    }),
}); };
export var duplicateApplicationStart = createCustomAction({
    entity: GENERATOR_PARAMS.entity,
    custom: EActionCustom.DuplicateApplication,
    state: EActionState.Start,
});
var generateGetCountStepsActions = function () { return ({
    getCountStepsStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.getCountSteps,
        state: EActionState.Start,
    }),
    getCountStepsSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.getCountSteps,
        state: EActionState.Success,
    }),
    getCountStepsFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.getCountSteps,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadFailed = _a.getInfiniteLoadFailed, getInfiniteLoadSucceeded = _a.getInfiniteLoadSucceeded, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed, archiveStart = _a.archiveStart, archiveSucceeded = _a.archiveSucceeded, archiveFailed = _a.archiveFailed, unarchiveStart = _a.unarchiveStart, unarchiveSucceeded = _a.unarchiveSucceeded, unarchiveFailed = _a.unarchiveFailed;
export var updateApplicationStepStart = (_b = generateUpdateApplicationStepActions(), _b.updateApplicationStepStart), updateApplicationStepSucceeded = _b.updateApplicationStepSucceeded;
export var getCountStepsStart = (_c = generateGetCountStepsActions(), _c.getCountStepsStart), getCountStepsSucceeded = _c.getCountStepsSucceeded, getCountStepsFailed = _c.getCountStepsFailed;
