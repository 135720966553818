import AsyncStorage from "@react-native-async-storage/async-storage";
import { getItem } from "../../utils/asyncStorage.utils";
import { EUserType } from "./card.types";
export var getSelectedUserType = function () {
    return getItem("be.rentio.app.user_type", EUserType.Unknown);
};
export var setSelectedUserType = function (userType) {
    return AsyncStorage.setItem("be.rentio.app.user_type", userType);
};
export var clearSelectedUserType = function () {
    return AsyncStorage.removeItem("be.rentio.app.user_type");
};
