interface IAttachment {
  type: string;
  path: string;
  filename: string;
}

const isImage = (type: any = "") => type.includes("image/");
const isVideo = (type: any = "") => type.includes("video/");

export const canPreviewAttachment = ({ type }: IAttachment) => isImage(type);

export const getAttachmentIcon = ({ type }: IAttachment) => {
  if (isImage(type)) {
    return "📸";
  }

  if (isVideo(type)) {
    return "🎬";
  }

  return "📑";
};

export const attachmentToBase64 = async (file: any) => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onload = (ev: any) => {
      resolve(ev.target.result);
    };
    reader.readAsDataURL(file);
  });
};
