export enum EInvoiceStatus {
  New = "NEW",
  Generated = "GENERATED",
  Failed = "FAILED",
}

export enum ECreditNoteStatus {
  None = "NONE",
  New = "NEW",
  Generated = "GENERATED",
  Failed = "FAILED",
}

export interface IExternalInvoice {
  id: string;

  statusInvoice: EInvoiceStatus;
  statusCreditNote: ECreditNoteStatus;

  createdAt: Date;
  createdByAccountId: number;

  externalInvoiceId?: number;
  externalInvoiceUrl?: string;

  externalCreditNoteId?: number;
  externalCreditNoteUrl?: string;
}
