var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createReducer } from "redux-act";
import * as socketActions from "./socket.actions";
import { socketMessageReceived, socketMessageSend, socketMessageStartPending, socketMessageStopPending, socketUpdateReadyState, } from "./socket.actions";
export var REDUCER_KEY_SOCKET = "socket";
var getDefaultState = function () { return ({
    readyState: undefined,
    pendingRefs: [],
    pendingMessages: {},
    autoReopenAfterClose: false,
    sendPendingMessagesOnNextOpen: false,
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(socketActions.openSocket.types.SUCCESS, function (state) { return (__assign(__assign({}, state), { pendingRefs: [], pendingMessages: {}, autoReopenAfterClose: false, sendPendingMessagesOnNextOpen: false })); });
reducer.on(socketActions.closeSocket.types.START, function (state, _a) {
    var sendPendingMessagesOnNextOpen = _a.sendPendingMessagesOnNextOpen;
    return (__assign(__assign({}, state), { sendPendingMessagesOnNextOpen: sendPendingMessagesOnNextOpen }));
});
reducer.on(socketUpdateReadyState, function (state, readyState) { return (__assign(__assign({}, state), { readyState: readyState })); });
reducer.on(socketMessageStartPending, function (state, _a) {
    var ref = _a.ref;
    return (__assign(__assign({}, state), { pendingRefs: __spreadArray(__spreadArray([], state.pendingRefs, true), [ref], false) }));
});
reducer.on(socketMessageStopPending, function (state, _a) {
    var ref = _a.ref;
    return (__assign(__assign({}, state), { pendingRefs: state.pendingRefs.filter(function (randomRef) { return randomRef !== ref; }) }));
});
reducer.on(socketMessageSend, function (state, message) {
    var _a;
    var ref = message.ref;
    if (!ref) {
        return state;
    }
    return __assign(__assign({}, state), { pendingRefs: __spreadArray(__spreadArray([], state.pendingRefs, true), [ref], false), pendingMessages: __assign(__assign({}, state.pendingMessages), (_a = {}, _a[ref] = message, _a)) });
});
reducer.on(socketMessageReceived, function (state, message) {
    var _a;
    try {
        var data = message.data;
        var jsonData = JSON.parse(data);
        if (!jsonData) {
            throw new Error("Could not parse socket message in socketMessageReceived reducer");
        }
        var ref_1 = jsonData.ref;
        if (!ref_1) {
            return state;
        }
        var newPendingRefs = __spreadArray([], state.pendingRefs.filter(function (randomRef) { return randomRef !== ref_1; }), true);
        var newPendingMessages = __assign(__assign({}, state.pendingMessages), (_a = {}, _a[ref_1] = undefined, _a));
        delete newPendingMessages[ref_1];
        return __assign(__assign({}, state), { pendingRefs: newPendingRefs, pendingMessages: newPendingMessages });
    }
    catch (unknownError) {
        return state;
    }
});
export default reducer;
