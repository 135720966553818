import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { addYears, subYears } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { lighten } from "polished";
import { EVariableType, } from "./Editor.types";
export var getTextStylesTextColor = function (config) {
    return {
        default: {
            label: getLocalizedText("editor.text_styles.text_color.default"),
            styles: {
                color: "black",
            },
        },
        brand: {
            label: getLocalizedText("editor.text_styles.text_color.brand"),
            styles: {
                color: config.brandColor || "#17A680",
            },
        },
        gray: {
            label: getLocalizedText("editor.text_styles.text_color.gray"),
            styles: {
                color: "rgb(135, 134, 131)",
            },
        },
        brown: {
            label: getLocalizedText("editor.text_styles.text_color.brown"),
            styles: {
                color: "rgb(84, 53, 34)",
            },
        },
        orange: {
            label: getLocalizedText("editor.text_styles.text_color.orange"),
            styles: {
                color: "rgb(201, 109, 14)",
            },
        },
        yellow: {
            label: getLocalizedText("editor.text_styles.text_color.yellow"),
            styles: {
                color: "rgb(216, 171, 56)",
            },
        },
        green: {
            label: getLocalizedText("editor.text_styles.text_color.green"),
            styles: {
                color: "rgb(16, 111, 96)",
            },
        },
        blue: {
            label: getLocalizedText("editor.text_styles.text_color.blue"),
            styles: {
                color: "rgb(47, 109, 149)",
            },
        },
        purple: {
            label: getLocalizedText("editor.text_styles.text_color.purple"),
            styles: {
                color: "rgb(98, 69, 159)",
            },
        },
        pink: {
            label: getLocalizedText("editor.text_styles.text_color.pink"),
            styles: {
                color: "rgb(158, 44, 111)",
            },
        },
        red: {
            label: getLocalizedText("editor.text_styles.text_color.red"),
            styles: {
                color: "rgb(206, 72, 66)",
            },
        },
    };
};
export var getTextStylesBackgroundColor = function (config) {
    return {
        bgDefault: {
            label: getLocalizedText("editor.text_styles.background_color.default"),
            styles: {
                backgroundColor: "transparent",
            },
        },
        bgBrand: {
            label: getLocalizedText("editor.text_styles.background_color.brand"),
            styles: {
                backgroundColor: config.brandColor || "#17A680",
            },
        },
        bgGray: {
            label: getLocalizedText("editor.text_styles.background_color.gray"),
            styles: {
                backgroundColor: lighten(0.4, "rgb(135, 134, 131)"),
            },
        },
        bgBrown: {
            label: getLocalizedText("editor.text_styles.background_color.brown"),
            styles: {
                backgroundColor: lighten(0.4, "rgb(84, 53, 34)"),
            },
        },
        bgOrange: {
            label: getLocalizedText("editor.text_styles.background_color.orange"),
            styles: {
                backgroundColor: lighten(0.4, "rgb(201, 109, 14)"),
            },
        },
        bgYellow: {
            label: getLocalizedText("editor.text_styles.background_color.yellow"),
            styles: {
                backgroundColor: lighten(0.35, "rgb(216, 171, 56)"),
            },
        },
        bgGreen: {
            label: getLocalizedText("editor.text_styles.background_color.green"),
            styles: {
                backgroundColor: lighten(0.5, "rgb(16, 111, 96)"),
            },
        },
        bgBlue: {
            label: getLocalizedText("editor.text_styles.background_color.blue"),
            styles: {
                backgroundColor: lighten(0.4, "rgb(47, 109, 149)"),
            },
        },
        bgPurple: {
            label: getLocalizedText("editor.text_styles.background_color.purple"),
            styles: {
                backgroundColor: lighten(0.4, "rgb(98, 69, 159)"),
            },
        },
        bgPink: {
            label: getLocalizedText("editor.text_styles.background_color.pink"),
            styles: {
                backgroundColor: lighten(0.4, "rgb(158, 44, 111)"),
            },
        },
        bgRed: {
            label: getLocalizedText("editor.text_styles.background_color.red"),
            styles: {
                backgroundColor: lighten(0.2, "rgb(206, 72, 66)"),
            },
        },
    };
};
export var EDefaultGroupId;
(function (EDefaultGroupId) {
    EDefaultGroupId["General"] = "general";
    EDefaultGroupId["Broker"] = "broker";
    EDefaultGroupId["Owner"] = "owner";
    EDefaultGroupId["Tenant"] = "tenant";
    EDefaultGroupId["Parent"] = "parent";
    EDefaultGroupId["Property"] = "property";
    EDefaultGroupId["Contract"] = "contract";
})(EDefaultGroupId || (EDefaultGroupId = {}));
export var DEFAULT_GROUPS = [
    {
        id: EDefaultGroupId.General,
        name: "system.general",
    },
    {
        id: EDefaultGroupId.Broker,
        name: "system.broker",
    },
    {
        id: EDefaultGroupId.Owner,
        name: "system.owner",
    },
    {
        id: EDefaultGroupId.Tenant,
        name: "system.tenant",
    },
    {
        id: EDefaultGroupId.Parent,
        name: "system.parent",
    },
    {
        id: EDefaultGroupId.Property,
        name: "system.property",
    },
    {
        id: EDefaultGroupId.Contract,
        name: "system.contract",
    },
];
export var EDefaultVariableId;
(function (EDefaultVariableId) {
    EDefaultVariableId["Now"] = "now";
    EDefaultVariableId["RentDepositPrice"] = "rentDepositPrice";
    EDefaultVariableId["RentDepositMonths"] = "rentDepositMonths";
    EDefaultVariableId["RentDepositWithRentio"] = "rentDepositWithRentio";
    EDefaultVariableId["RentDepositBankAccountNumber"] = "rentDepositBankAccountNumber";
    EDefaultVariableId["LocationDescriptionPerson"] = "locationDescriptionPerson";
    EDefaultVariableId["BrokerEnabled"] = "brokerEnabled";
    EDefaultVariableId["BrokerBiv"] = "brokerBiv";
    EDefaultVariableId["BrokerOfficeName"] = "brokerOfficeName";
    EDefaultVariableId["BrokerFullName"] = "brokerFullName";
    EDefaultVariableId["BrokerFirstName"] = "brokerFirstName";
    EDefaultVariableId["BrokerLastName"] = "brokerLastName";
    EDefaultVariableId["BrokerBirthPlace"] = "brokerBirthPlace";
    EDefaultVariableId["BrokerBirthDate"] = "brokerBirthDate";
    EDefaultVariableId["BrokerAddressFull"] = "brokerAddressFull";
    EDefaultVariableId["BrokerAddressStreet"] = "brokerAddressStreet";
    EDefaultVariableId["BrokerAddressNumber"] = "brokerAddressNumber";
    EDefaultVariableId["BrokerAddressBox"] = "brokerAddressBox";
    EDefaultVariableId["BrokerAddressZip"] = "brokerAddressZip";
    EDefaultVariableId["BrokerAddressCity"] = "brokerAddressCity";
    EDefaultVariableId["BrokerAddressCountry"] = "brokerAddressCountry";
    EDefaultVariableId["BrokerLogo"] = "brokerLogo";
    EDefaultVariableId["BrokerIsSignerOnBehalfOfOwners"] = "brokerIsSignerOnBehalfOfOwners";
    EDefaultVariableId["BrokerSignature"] = "brokerSignature";
    EDefaultVariableId["BrokerCivilLiabilityAtCompany"] = "brokerCivilLiabilityAtCompany";
    EDefaultVariableId["BrokerCivilLiabilityPolicyNumber"] = "brokerCivilLiabilityPolicyNumber";
    EDefaultVariableId["OwnerIsCompany"] = "ownerIsCompany";
    EDefaultVariableId["OwnerFullName"] = "ownerFullName";
    EDefaultVariableId["OwnerFirstName"] = "ownerFirstName";
    EDefaultVariableId["OwnerLastName"] = "ownerLastName";
    EDefaultVariableId["OwnerBirthPlace"] = "ownerBirthPlace";
    EDefaultVariableId["OwnerBirthDate"] = "ownerBirthDate";
    EDefaultVariableId["OwnerCompanyName"] = "ownerCompanyName";
    EDefaultVariableId["OwnerCompanyVat"] = "ownerCompanyVat";
    EDefaultVariableId["OwnerCompanyRole"] = "ownerCompanyRole";
    EDefaultVariableId["OwnerAddressFull"] = "ownerAddressFull";
    EDefaultVariableId["OwnerAddressStreet"] = "ownerAddressStreet";
    EDefaultVariableId["OwnerAddressNumber"] = "ownerAddressNumber";
    EDefaultVariableId["OwnerAddressBox"] = "ownerAddressBox";
    EDefaultVariableId["OwnerAddressZip"] = "ownerAddressZip";
    EDefaultVariableId["OwnerAddressCity"] = "ownerAddressCity";
    EDefaultVariableId["OwnerAddressCountry"] = "ownerAddressCountry";
    EDefaultVariableId["OwnerCompanyAddressFull"] = "ownerCompanyAddressFull";
    EDefaultVariableId["OwnerSignature"] = "ownerSignature";
    EDefaultVariableId["OwnerEmail"] = "ownerEmail";
    EDefaultVariableId["OwnerPhone"] = "ownerPhone";
    EDefaultVariableId["OwnerNationality"] = "ownerNationality";
    EDefaultVariableId["OwnerNationalRegisterNumber"] = "ownerNationalRegisterNumber";
    EDefaultVariableId["OwnerMaritalStatus"] = "ownerMaritalStatus";
    EDefaultVariableId["TenantIsCompany"] = "tenantIsCompany";
    EDefaultVariableId["TenantFullName"] = "tenantFullName";
    EDefaultVariableId["TenantFirstName"] = "tenantFirstName";
    EDefaultVariableId["TenantLastName"] = "tenantLastName";
    EDefaultVariableId["TenantBirthPlace"] = "tenantBirthPlace";
    EDefaultVariableId["TenantBirthDate"] = "tenantBirthDate";
    EDefaultVariableId["TenantCompanyName"] = "tenantCompanyName";
    EDefaultVariableId["TenantCompanyVat"] = "tenantCompanyVat";
    EDefaultVariableId["TenantCompanyRole"] = "tenantCompanyRole";
    EDefaultVariableId["TenantAddressFull"] = "tenantAddressFull";
    EDefaultVariableId["TenantAddressStreet"] = "tenantAddressStreet";
    EDefaultVariableId["TenantAddressNumber"] = "tenantAddressNumber";
    EDefaultVariableId["TenantAddressBox"] = "tenantAddressBox";
    EDefaultVariableId["TenantAddressZip"] = "tenantAddressZip";
    EDefaultVariableId["TenantAddressCity"] = "tenantAddressCity";
    EDefaultVariableId["TenantAddressCountry"] = "tenantAddressCountry";
    EDefaultVariableId["TenantCompanyAddressFull"] = "tenantCompanyAddressFull";
    EDefaultVariableId["TenantSignature"] = "tenantSignature";
    EDefaultVariableId["TenantEmail"] = "tenantEmail";
    EDefaultVariableId["TenantPhone"] = "tenantPhone";
    EDefaultVariableId["TenantNationality"] = "tenantNationality";
    EDefaultVariableId["TenantNationalRegisterNumber"] = "tenantNationalRegisterNumber";
    EDefaultVariableId["TenantMaritalStatus"] = "tenantMaritalStatus";
    EDefaultVariableId["ParentIsCompany"] = "parentIsCompany";
    EDefaultVariableId["ParentFullName"] = "parentFullName";
    EDefaultVariableId["ParentFirstName"] = "parentFirstName";
    EDefaultVariableId["ParentLastName"] = "parentLastName";
    EDefaultVariableId["ParentBirthPlace"] = "parentBirthPlace";
    EDefaultVariableId["ParentBirthDate"] = "parentBirthDate";
    EDefaultVariableId["ParentCompanyName"] = "parentCompanyName";
    EDefaultVariableId["ParentCompanyVat"] = "parentCompanyVat";
    EDefaultVariableId["ParentCompanyRole"] = "parentCompanyRole";
    EDefaultVariableId["ParentAddressFull"] = "parentAddressFull";
    EDefaultVariableId["ParentAddressStreet"] = "parentAddressStreet";
    EDefaultVariableId["ParentAddressNumber"] = "parentAddressNumber";
    EDefaultVariableId["ParentAddressBox"] = "parentAddressBox";
    EDefaultVariableId["ParentAddressZip"] = "parentAddressZip";
    EDefaultVariableId["ParentAddressCity"] = "parentAddressCity";
    EDefaultVariableId["ParentAddressCountry"] = "parentAddressCountry";
    EDefaultVariableId["ParentCompanyAddressFull"] = "parentCompanyAddressFull";
    EDefaultVariableId["ParentSignature"] = "parentSignature";
    EDefaultVariableId["ParentEmail"] = "parentEmail";
    EDefaultVariableId["ParentPhone"] = "parentPhone";
    EDefaultVariableId["ParentNationality"] = "parentNationality";
    EDefaultVariableId["ParentNationalRegisterNumber"] = "parentNationalRegisterNumber";
    EDefaultVariableId["ParentMaritalStatus"] = "parentMaritalStatus";
    EDefaultVariableId["PropertyType"] = "propertyType";
    EDefaultVariableId["PropertyAddressFull"] = "propertyAddressFull";
    EDefaultVariableId["PropertyAddressStreet"] = "propertyAddressStreet";
    EDefaultVariableId["PropertyAddressNumber"] = "propertyAddressNumber";
    EDefaultVariableId["PropertyAddressBox"] = "propertyAddressBox";
    EDefaultVariableId["PropertyAddressZip"] = "propertyAddressZip";
    EDefaultVariableId["PropertyAddressCity"] = "propertyAddressCity";
    EDefaultVariableId["PropertyAddressCountry"] = "propertyAddressCountry";
    EDefaultVariableId["PropertyDescription"] = "propertyDescription";
    EDefaultVariableId["PropertyName"] = "propertyName";
    EDefaultVariableId["PropertyReference"] = "propertyReference";
    EDefaultVariableId["PropertySyndic"] = "propertySyndic";
    EDefaultVariableId["PropertyHasOilTank"] = "propertyHasOilTank";
    EDefaultVariableId["PropertyOilTankIsBuried"] = "propertyOilTankIsBuried";
    EDefaultVariableId["PropertyOilTankVolume"] = "propertyOilTankVolume";
    EDefaultVariableId["PropertySmokeDetectorCount"] = "propertySmokeDetectorCount";
    EDefaultVariableId["PropertyEpcLabel"] = "propertyEpcLabel";
    EDefaultVariableId["PropertyEpcValue"] = "propertyEpcValue";
    EDefaultVariableId["PropertyEpcExpirationDate"] = "propertyEpcExpirationDate";
    EDefaultVariableId["PropertyUtilityCounterWaterAvailable"] = "propertyUtilityCounterWaterAvailable";
    EDefaultVariableId["PropertyUtilityCounterWaterIsCollective"] = "propertyUtilityCounterWaterIsCollective";
    EDefaultVariableId["PropertyUtilityCounterNaturalGasAvailable"] = "propertyUtilityCounterNaturalGasAvailable";
    EDefaultVariableId["PropertyUtilityCounterNaturalGasIsCollective"] = "propertyUtilityCounterNaturalGasIsCollective";
    EDefaultVariableId["PropertyUtilityCounterElectricityAvailable"] = "propertyUtilityCounterElectricityAvailable";
    EDefaultVariableId["PropertyUtilityCounterElectricityIsCollective"] = "propertyUtilityCounterElectricityIsCollective";
    EDefaultVariableId["PropertyUsagePrivatePercentage"] = "propertyUsagePrivatePercentage";
    EDefaultVariableId["PropertyUsageProfessionalPercentage"] = "propertyUsageProfessionalPercentage";
    EDefaultVariableId["PropertyTechnicalManagers"] = "propertyTechnicalManagers";
    EDefaultVariableId["PropertyFinancialManagers"] = "propertyFinancialManagers";
    EDefaultVariableId["ContractStartDate"] = "contractStartDate";
    EDefaultVariableId["ContractStopDate"] = "contractStopDate";
    EDefaultVariableId["ContractPaymentRepetition"] = "contractPaymentRepetition";
    EDefaultVariableId["ContractPriceVat"] = "contractPriceVat";
    EDefaultVariableId["ContractStartPrice"] = "contractStartPrice";
    EDefaultVariableId["ContractStartPriceIncludingVat"] = "contractStartPriceIncludingVat";
    EDefaultVariableId["ContractCostsCommonVat"] = "contractCostsCommonVat";
    EDefaultVariableId["ContractStartCostsCommonFixed"] = "contractStartCostsCommonFixed";
    EDefaultVariableId["ContractStartCostsCommonFixedIncludingVat"] = "contractStartCostsCommonFixedIncludingVat";
    EDefaultVariableId["ContractStartCostsCommonProvision"] = "contractStartCostsCommonProvision";
    EDefaultVariableId["ContractStartCostsCommonProvisionIncludingVat"] = "contractStartCostsCommonProvisionIncludingVat";
    EDefaultVariableId["ContractCostsNonCommonVat"] = "contractCostsNonCommonVat";
    EDefaultVariableId["ContractStartCostsNonCommonFixed"] = "contractStartCostsNonCommonFixed";
    EDefaultVariableId["ContractStartCostsNonCommonFixedIncludingVat"] = "contractStartCostsNonCommonFixedIncludingVat";
    EDefaultVariableId["ContractStartCostsNonCommonProvision"] = "contractStartCostsNonCommonProvision";
    EDefaultVariableId["ContractStartCostsNonCommonProvisionIncludingVat"] = "contractStartCostsNonCommonProvisionIncludingVat";
    EDefaultVariableId["ContractCurrentPrice"] = "contractCurrentPrice";
    EDefaultVariableId["ContractCurrentPriceIncludingVat"] = "contractCurrentPriceIncludingVat";
    EDefaultVariableId["ContractCurrentCostsCommonFixed"] = "contractCurrentCostsCommonFixed";
    EDefaultVariableId["ContractCurrentCostsCommonFixedIncludingVat"] = "contractCurrentCostsCommonFixedIncludingVat";
    EDefaultVariableId["ContractCurrentCostsCommonProvision"] = "contractCurrentCostsCommonProvision";
    EDefaultVariableId["ContractCurrentCostsCommonProvisionIncludingVat"] = "contractCurrentCostsCommonProvisionIncludingVat";
    EDefaultVariableId["ContractCurrentCostsNonCommonFixed"] = "contractCurrentCostsNonCommonFixed";
    EDefaultVariableId["ContractCurrentCostsNonCommonFixedIncludingVat"] = "contractCurrentCostsNonCommonFixedIncludingVat";
    EDefaultVariableId["ContractCurrentCostsNonCommonProvision"] = "contractCurrentCostsNonCommonProvision";
    EDefaultVariableId["ContractCurrentCostsNonCommonProvisionIncludingVat"] = "contractCurrentCostsNonCommonProvisionIncludingVat";
    EDefaultVariableId["ContractPriceTypeCostsCommon"] = "contractPriceTypeCostsCommon";
    EDefaultVariableId["ContractPriceTypeCostsNonCommon"] = "contractPriceTypeCostsNonCommon";
    EDefaultVariableId["ContractStartIndexDate"] = "contractStartIndexDate";
    EDefaultVariableId["ContractStartIndexNumber"] = "contractStartIndexNumber";
    EDefaultVariableId["ContractDurationYears"] = "contractDurationYears";
    EDefaultVariableId["ContractIndexStartIndex"] = "contractIndexStartIndex";
    EDefaultVariableId["ContractIndexStartNextPaymentPeriod"] = "ContractIndexStartNextPaymentPeriod";
    EDefaultVariableId["ContractIndexNewIndex"] = "contractIndexNewIndex";
    EDefaultVariableId["ContractIndexCorrectionFactor"] = "contractIndexCorrectionFactor";
    EDefaultVariableId["ContractIndexEpcBasedIndexType"] = "contractIndexEpcBasedIndexType";
    EDefaultVariableId["ContractIndexNewPriceWasAdjusted"] = "contractIndexNewPriceWasAdjusted";
    EDefaultVariableId["ContractIndexNewPriceOriginal"] = "contractIndexNewPriceOriginal";
    EDefaultVariableId["ContractIndexNewPriceAdjusted"] = "contractIndexNewPrice";
    EDefaultVariableId["ContractIndexForceFullIndexation"] = "contractIndexForceFullIndexation";
    EDefaultVariableId["ContractIndexRetroactivePayments"] = "contractIndexRetroActivePayment";
    EDefaultVariableId["ContractRetroactivityPeriodStart"] = "contractRetroactivityPeriodStart";
    EDefaultVariableId["ContractRetroactivityPeriodEnd"] = "contractRetroactivityPeriodEnd";
    EDefaultVariableId["ContractRetroactivityPeriodPrice"] = "contractRetroactivityPeriodPrice";
    EDefaultVariableId["ContractIndexPaymentsWithRentio"] = "contractIndexPaymentsWithRentio";
    EDefaultVariableId["ContractIndexPaymentOrderNewDueDate"] = "contractIndexPaymentOrderNewDueDate";
    EDefaultVariableId["ContractIbanisationBankAccountIban"] = "contractIbanisationBankAccountIban";
    EDefaultVariableId["ContractIbanisationBankAccountBic"] = "contractIbanisationBankAccountBic";
    EDefaultVariableId["ContractIbanisationBankAccountReference"] = "contractIbanisationBankAccountReference";
    EDefaultVariableId["ContractIndexCorrectionPeriodStart"] = "contractIndexCorrectionPeriodStart";
    EDefaultVariableId["ContractIndexCorrectionPeriodEnd"] = "contractIndexCorrectionPeriodEnd";
    EDefaultVariableId["ContractIndexCorrectionPeriodPrice"] = "contractIndexCorrectionPeriodPrice";
    EDefaultVariableId["ContractIndexCorrectionAndRetroSum"] = "contractIndexCorrectionAndRetroSum";
})(EDefaultVariableId || (EDefaultVariableId = {}));
export var DEFAULT_VARIABLES = [
    // General
    {
        id: EDefaultVariableId.Now,
        name: "editor.variable.now",
        groupId: EDefaultGroupId.General,
        type: EVariableType.Date,
    },
    {
        id: EDefaultVariableId.RentDepositPrice,
        name: "editor.variable.rent_deposit_price",
        groupId: EDefaultGroupId.General,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.RentDepositMonths,
        name: "editor.variable.rent_deposit_months",
        groupId: EDefaultGroupId.General,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.RentDepositWithRentio,
        name: "editor.variable.rent_deposit_with_rentio",
        groupId: EDefaultGroupId.General,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.RentDepositBankAccountNumber,
        name: "editor.variable.rent_deposit_bank_account_number",
        groupId: EDefaultGroupId.General,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.LocationDescriptionPerson,
        name: "editor.variable.location_description_person",
        groupId: EDefaultGroupId.General,
        type: EVariableType.String,
    },
    // Broker
    {
        id: EDefaultVariableId.BrokerEnabled,
        name: "editor.variable.broker_enabled",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.BrokerBiv,
        name: "editor.variable.broker_biv_number",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerOfficeName,
        name: "editor.variable.broker_office_name",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerFullName,
        name: "editor.variable.broker_full_name",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerFirstName,
        name: "editor.variable.broker_first_name",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerLastName,
        name: "editor.variable.broker_last_name",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerBirthPlace,
        name: "editor.variable.broker_birth_place",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerBirthDate,
        name: "editor.variable.broker_birth_date",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.Date,
        minDate: subYears(new Date(), 100),
        maxDate: new Date(),
    },
    {
        id: EDefaultVariableId.BrokerAddressFull,
        name: "editor.variable.broker_address_full",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.BrokerAddressStreet,
        name: "editor.variable.broker_address_street",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerAddressNumber,
        name: "editor.variable.broker_address_number",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerAddressBox,
        name: "editor.variable.broker_address_box",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.BrokerAddressZip,
        name: "editor.variable.broker_address_zip",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerAddressCity,
        name: "editor.variable.broker_address_city",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerAddressCountry,
        name: "editor.variable.broker_address_country",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerLogo,
        name: "editor.variable.broker_logo",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.Image,
    },
    {
        id: EDefaultVariableId.BrokerIsSignerOnBehalfOfOwners,
        name: "editor.variable.broker_is_signer_on_behalf_of_owners",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.BrokerSignature,
        name: "editor.variable.broker_signature",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.Signature,
    },
    {
        id: EDefaultVariableId.BrokerCivilLiabilityAtCompany,
        name: "editor.variable.broker_civil_liability_at_company",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.BrokerCivilLiabilityPolicyNumber,
        name: "editor.variable.broker_civil_liability_policy_number",
        groupId: EDefaultGroupId.Broker,
        type: EVariableType.String,
    },
    // Owner
    {
        id: EDefaultVariableId.OwnerIsCompany,
        name: "editor.variable.owner_is_company",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.OwnerFullName,
        name: "editor.variable.owner_full_name",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerFirstName,
        name: "editor.variable.owner_first_name",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerLastName,
        name: "editor.variable.owner_last_name",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerBirthPlace,
        name: "editor.variable.owner_birth_place",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerBirthDate,
        name: "editor.variable.owner_birth_date",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.Date,
        minDate: subYears(new Date(), 100),
        maxDate: new Date(),
    },
    {
        id: EDefaultVariableId.OwnerCompanyName,
        name: "editor.variable.owner_company_name",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.OwnerCompanyVat,
        name: "editor.variable.owner_company_vat",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.OwnerCompanyRole,
        name: "editor.variable.owner_company_role",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.OwnerAddressFull,
        name: "editor.variable.owner_address_full",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.OwnerAddressStreet,
        name: "editor.variable.owner_address_street",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerAddressNumber,
        name: "editor.variable.owner_address_number",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerAddressBox,
        name: "editor.variable.owner_address_box",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerAddressZip,
        name: "editor.variable.owner_address_zip",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerAddressCity,
        name: "editor.variable.owner_address_city",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerAddressCountry,
        name: "editor.variable.owner_address_country",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerCompanyAddressFull,
        name: "editor.variable.owner_company_address_full",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.OwnerSignature,
        name: "editor.variable.owner_signature",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.Signature,
    },
    {
        id: EDefaultVariableId.OwnerEmail,
        name: "editor.variable.owner_email",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerPhone,
        name: "editor.variable.owner_phone",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerNationality,
        name: "editor.variable.owner_nationality",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerNationalRegisterNumber,
        name: "editor.variable.owner_national_register_number",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.OwnerMaritalStatus,
        name: "editor.variable.owner_marital_status",
        groupId: EDefaultGroupId.Owner,
        type: EVariableType.String,
    },
    // Tenant
    {
        id: EDefaultVariableId.TenantIsCompany,
        name: "editor.variable.tenant_is_company",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.TenantFullName,
        name: "editor.variable.tenant_full_name",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantFirstName,
        name: "editor.variable.tenant_first_name",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantLastName,
        name: "editor.variable.tenant_last_name",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantBirthPlace,
        name: "editor.variable.tenant_birth_place",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantBirthDate,
        name: "editor.variable.tenant_birth_date",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.Date,
        minDate: subYears(new Date(), 100),
        maxDate: new Date(),
    },
    {
        id: EDefaultVariableId.TenantCompanyName,
        name: "editor.variable.tenant_company_name",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.TenantCompanyVat,
        name: "editor.variable.tenant_company_vat",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.TenantCompanyRole,
        name: "editor.variable.tenant_company_role",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.TenantAddressFull,
        name: "editor.variable.tenant_address_full",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantAddressStreet,
        name: "editor.variable.tenant_address_street",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantAddressNumber,
        name: "editor.variable.tenant_address_number",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantAddressBox,
        name: "editor.variable.tenant_address_box",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantAddressZip,
        name: "editor.variable.tenant_address_zip",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantAddressCity,
        name: "editor.variable.tenant_address_city",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantAddressCountry,
        name: "editor.variable.tenant_address_country",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantCompanyAddressFull,
        name: "editor.variable.tenant_company_address_full",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.TenantSignature,
        name: "editor.variable.tenant_signature",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.Signature,
    },
    {
        id: EDefaultVariableId.TenantEmail,
        name: "editor.variable.tenant_email",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantPhone,
        name: "editor.variable.tenant_phone",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantNationality,
        name: "editor.variable.tenant_nationality",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantNationalRegisterNumber,
        name: "editor.variable.tenant_national_register_number",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.TenantMaritalStatus,
        name: "editor.variable.tenant_marital_status",
        groupId: EDefaultGroupId.Tenant,
        type: EVariableType.String,
    },
    // Parent
    {
        id: EDefaultVariableId.ParentIsCompany,
        name: "editor.variable.parent_is_company",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.ParentFullName,
        name: "editor.variable.parent_full_name",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentFirstName,
        name: "editor.variable.parent_first_name",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentLastName,
        name: "editor.variable.parent_last_name",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentBirthPlace,
        name: "editor.variable.parent_birth_place",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentBirthDate,
        name: "editor.variable.parent_birth_date",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.Date,
        minDate: subYears(new Date(), 100),
        maxDate: new Date(),
    },
    {
        id: EDefaultVariableId.ParentCompanyName,
        name: "editor.variable.parent_company_name",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.ParentCompanyVat,
        name: "editor.variable.parent_company_vat",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.ParentCompanyRole,
        name: "editor.variable.parent_company_role",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.ParentAddressFull,
        name: "editor.variable.parent_address_full",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentAddressStreet,
        name: "editor.variable.parent_address_street",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentAddressNumber,
        name: "editor.variable.parent_address_number",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentAddressBox,
        name: "editor.variable.parent_address_box",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentAddressZip,
        name: "editor.variable.parent_address_zip",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentAddressCity,
        name: "editor.variable.parent_address_city",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentAddressCountry,
        name: "editor.variable.parent_address_country",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentCompanyAddressFull,
        name: "editor.variable.parent_company_address_full",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.ParentSignature,
        name: "editor.variable.parent_signature",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.Signature,
    },
    {
        id: EDefaultVariableId.ParentEmail,
        name: "editor.variable.parent_email",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentPhone,
        name: "editor.variable.parent_phone",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentNationality,
        name: "editor.variable.parent_nationality",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentNationalRegisterNumber,
        name: "editor.variable.parent_national_register_number",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ParentMaritalStatus,
        name: "editor.variable.parent_marital_status",
        groupId: EDefaultGroupId.Parent,
        type: EVariableType.String,
    },
    // Property
    {
        id: EDefaultVariableId.PropertyType,
        name: "editor.variable.property_type",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyAddressFull,
        name: "editor.variable.property_address_full",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyAddressStreet,
        name: "editor.variable.property_address_street",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyAddressNumber,
        name: "editor.variable.property_address_number",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyAddressBox,
        name: "editor.variable.property_address_box",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyAddressZip,
        name: "editor.variable.property_address_zip",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyAddressCity,
        name: "editor.variable.property_address_city",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyAddressCountry,
        name: "editor.variable.property_address_country",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.PropertyDescription,
        name: "editor.variable.property_description",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyName,
        name: "editor.variable.property_name",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyReference,
        name: "editor.variable.property_reference",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    // {
    //   id: EDefaultVariableId.PropertyCollectiveUtilityCounters,
    //   name: "editor.variable.property_collective_utility_counters",
    //   groupId: EDefaultGroupId.Property,
    //   type: EVariableType.Boolean,
    // },
    {
        id: EDefaultVariableId.PropertySyndic,
        name: "editor.variable.property_syndic",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyHasOilTank,
        name: "editor.variable.property_has_oil_tank",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyOilTankIsBuried,
        name: "editor.variable.property_oil_tank_is_buried",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
        // isRequired: false,
    },
    {
        id: EDefaultVariableId.PropertyOilTankVolume,
        name: "editor.variable.property_oil_tank_volume",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Number,
        minNumber: 0,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.PropertySmokeDetectorCount,
        name: "editor.variable.property_smoke_detector_count",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Number,
        minNumber: 0,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.PropertyEpcLabel,
        name: "editor.variable.property_epc_label",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.PropertyEpcValue,
        name: "editor.variable.property_epc_value",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Number,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.PropertyEpcExpirationDate,
        name: "editor.variable.property_epc_expiration_date",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Date,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.PropertyUtilityCounterWaterAvailable,
        name: "editor.variable.property_utility_counter_water_available",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyUtilityCounterWaterIsCollective,
        name: "editor.variable.property_utility_counter_water_is_collective",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyUtilityCounterNaturalGasAvailable,
        name: "editor.variable.property_utility_counter_natural_gas_available",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyUtilityCounterNaturalGasIsCollective,
        name: "editor.variable.property_utility_counter_natural_gas_is_collective",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyUtilityCounterElectricityAvailable,
        name: "editor.variable.property_utility_counter_electricity_available",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyUtilityCounterElectricityIsCollective,
        name: "editor.variable.property_utility_counter_electricity_is_collective",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.PropertyUsagePrivatePercentage,
        name: "editor.variable.property_usage_private_percentage",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Number,
        minNumber: 0,
        maxNumber: 100,
    },
    {
        id: EDefaultVariableId.PropertyUsageProfessionalPercentage,
        name: "editor.variable.property_usage_professional_percentage",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.Number,
        minNumber: 0,
        maxNumber: 100,
    },
    {
        id: EDefaultVariableId.PropertyTechnicalManagers,
        name: "editor.variable.property_technical_managers",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.PropertyFinancialManagers,
        name: "editor.variable.property_financial_managers",
        groupId: EDefaultGroupId.Property,
        type: EVariableType.String,
    },
    // Contract
    {
        id: EDefaultVariableId.ContractStartDate,
        name: "editor.variable.contract_start_date",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
        minDate: subYears(new Date(), 50),
        maxDate: addYears(new Date(), 50),
    },
    {
        id: EDefaultVariableId.ContractStopDate,
        name: "editor.variable.contract_stop_date",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
        minDate: subYears(new Date(), 50),
        maxDate: addYears(new Date(), 50),
    },
    {
        id: EDefaultVariableId.ContractPaymentRepetition,
        name: "editor.variable.contract_payment_repetition",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.String,
        isRequired: true,
    },
    {
        id: EDefaultVariableId.ContractPriceVat,
        name: "editor.variable.contract_price_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartPrice,
        name: "editor.variable.contract_start_price",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartPriceIncludingVat,
        name: "editor.variable.contract_start_price_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCostsCommonVat,
        name: "editor.variable.contract_costs_common_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsCommonFixed,
        name: "editor.variable.contract_start_costs_common_fixed",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsCommonFixedIncludingVat,
        name: "editor.variable.contract_start_costs_common_fixed_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsCommonProvision,
        name: "editor.variable.contract_start_costs_common_provision",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsCommonProvisionIncludingVat,
        name: "editor.variable.contract_start_costs_common_provision_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCostsNonCommonVat,
        name: "editor.variable.contract_costs_non_common_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsNonCommonFixed,
        name: "editor.variable.contract_start_costs_non_common_fixed",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsNonCommonFixedIncludingVat,
        name: "editor.variable.contract_start_costs_non_common_fixed_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsNonCommonProvision,
        name: "editor.variable.contract_start_costs_non_common_provision",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractStartCostsNonCommonProvisionIncludingVat,
        name: "editor.variable.contract_start_costs_non_common_provision_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentPrice,
        name: "editor.variable.contract_current_price",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentPriceIncludingVat,
        name: "editor.variable.contract_current_price_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsCommonFixed,
        name: "editor.variable.contract_current_costs_common_fixed",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsCommonFixedIncludingVat,
        name: "editor.variable.contract_current_costs_common_fixed_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsCommonProvision,
        name: "editor.variable.contract_current_costs_common_provision",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsCommonProvisionIncludingVat,
        name: "editor.variable.contract_current_costs_common_provision_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsNonCommonFixed,
        name: "editor.variable.contract_current_costs_non_common_fixed",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsNonCommonFixedIncludingVat,
        name: "editor.variable.contract_current_costs_non_common_fixed_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsNonCommonProvision,
        name: "editor.variable.contract_current_costs_non_common_provision",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractCurrentCostsNonCommonProvisionIncludingVat,
        name: "editor.variable.contract_current_costs_non_common_provision_including_vat",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    {
        id: EDefaultVariableId.ContractPriceTypeCostsCommon,
        name: "editor.variable.contract_price_type_costs_common",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.String, // Fixed, provision
    },
    {
        id: EDefaultVariableId.ContractPriceTypeCostsNonCommon,
        name: "editor.variable.contract_price_type_costs_non_common",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.String, // Fixed, provision
    },
    {
        id: EDefaultVariableId.ContractStartIndexDate,
        name: "editor.variable.contract_start_index_date",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
        defaultDateFormatting: "MMMM yyyy",
    },
    {
        id: EDefaultVariableId.ContractStartIndexNumber,
        name: "editor.variable.contract_start_index_number",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ContractDurationYears,
        name: "editor.variable.contract_duration_years",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        minNumber: 0,
    },
    // Contract index
    {
        id: EDefaultVariableId.ContractIndexStartIndex,
        name: "editor.variable.contract_index_start_index",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
    },
    {
        id: EDefaultVariableId.ContractIndexNewIndex,
        name: "editor.variable.contract_index_new_index",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
    },
    {
        id: EDefaultVariableId.ContractIndexCorrectionFactor,
        name: "editor.variable.contract_index_correction_factor",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.ContractIndexEpcBasedIndexType,
        name: "editor.variable.contract_index_epc_based_index_type",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
        isRequired: false,
    },
    {
        id: EDefaultVariableId.ContractIndexForceFullIndexation,
        name: "editor.variable.contract_index_force_full_indexation",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.ContractIndexNewPriceWasAdjusted,
        name: "editor.variable.contract_index_new_price_was_adjusted",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.ContractIndexNewPriceOriginal,
        name: "editor.variable.contract_index_new_price_original",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
    },
    {
        id: EDefaultVariableId.ContractIndexNewPriceAdjusted,
        name: "editor.variable.contract_index_new_price_adjusted",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
    },
    {
        id: EDefaultVariableId.ContractIndexForceFullIndexation,
        name: "editor.variable.contract_index_force_full_indexation",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.ContractIndexStartNextPaymentPeriod,
        name: "editor.variable.contract_index_start_next_payment_period",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
    },
    {
        id: EDefaultVariableId.ContractRetroactivityPeriodStart,
        name: "editor.variable.contract_retroactivity_period_start",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
    },
    {
        id: EDefaultVariableId.ContractRetroactivityPeriodEnd,
        name: "editor.variable.contract_retroactivity_period_end",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
    },
    {
        id: EDefaultVariableId.ContractRetroactivityPeriodPrice,
        name: "editor.variable.contract_retroactivity_period_price",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
    },
    {
        id: EDefaultVariableId.ContractIndexRetroactivePayments,
        name: "editor.variable.contract_retroactivity",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.ContractIndexPaymentsWithRentio,
        name: "editor.variable.contract_index_payments_with_rentio",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Boolean,
    },
    {
        id: EDefaultVariableId.ContractIndexPaymentOrderNewDueDate,
        name: "editor.variable.contract_index_payment_order_new_due_date",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
    },
    // Contract ibanisation
    {
        id: EDefaultVariableId.ContractIbanisationBankAccountIban,
        name: "editor.variable.contract_ibanisation_bank_account_iban",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ContractIbanisationBankAccountBic,
        name: "editor.variable.contract_ibanisation_bank_account_bic",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.String,
    },
    {
        id: EDefaultVariableId.ContractIbanisationBankAccountReference,
        name: "editor.variable.contract_ibanisation_bank_account_reference",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.String,
    },
    //Contract correction period
    {
        id: EDefaultVariableId.ContractIndexCorrectionPeriodStart,
        name: "editor.variable.contract_index_correction_period_start",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
    },
    {
        id: EDefaultVariableId.ContractIndexCorrectionPeriodEnd,
        name: "editor.variable.contract_index_correction_period_end",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Date,
    },
    {
        id: EDefaultVariableId.ContractIndexCorrectionPeriodPrice,
        name: "editor.variable.contract_index_correction_period_price",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
    },
    {
        id: EDefaultVariableId.ContractIndexCorrectionAndRetroSum,
        name: "editor.variable.contract_index_correction_retro_sum",
        groupId: EDefaultGroupId.Contract,
        type: EVariableType.Number,
    },
];
