import { EditorState, Entity, Modifier } from "draft-js";
import { insertAtomicBlock } from "../../utils/draftjs.internal.utils";
import { getSelectionAsCursorAtEnd, getSelectionAsCursorAtStart, performActions, } from "../../utils/draftjs.utils";
import { LOOP_RENDERER_TYPE, } from "../Editor/decorators/LoopRenderer";
var insertLoopPart = function (params) {
    var editorState = params.editorState, loop = params.loop, originalEditorState = params.originalEditorState, instancePosition = params.instancePosition;
    var isStart = instancePosition === "LEADING";
    var contentState = editorState.getCurrentContent();
    var originalSelection = originalEditorState.getSelection();
    var selection = isStart
        ? getSelectionAsCursorAtStart(originalEditorState)
        : getSelectionAsCursorAtEnd(originalEditorState);
    var selectionRemainsWithinSingleBlock = originalSelection.getAnchorKey() === originalSelection.getFocusKey();
    var loopData = {
        id: loop.id,
        instancePosition: instancePosition,
        renderType: selectionRemainsWithinSingleBlock ? "INLINE" : "BLOCK",
    };
    if (selectionRemainsWithinSingleBlock) {
        var entityKey_1 = Entity.create(LOOP_RENDERER_TYPE, "IMMUTABLE", loopData);
        var newContentState = performActions(contentState, [
            function (x) { return Modifier.insertText(x, selection, " "); },
            function (x) {
                return Modifier.insertText(x, selection, "_", undefined, entityKey_1);
            },
            function (x) { return Modifier.insertText(x, selection, " "); },
        ]);
        return EditorState.push(editorState, newContentState, "insert-characters");
    }
    var afterSplit = Modifier.splitBlock(contentState, selection);
    var contentStateWithEntity = afterSplit.createEntity(LOOP_RENDERER_TYPE, "IMMUTABLE", loopData);
    var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    var contentStateWithEntityApplied = Modifier.applyEntity(contentStateWithEntity, selection, entityKey);
    var newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntityApplied,
    });
    return insertAtomicBlock({
        editorState: newEditorState,
        entityKey: entityKey,
        character: "_",
        targetSelection: isStart
            ? contentStateWithEntityApplied.getSelectionBefore()
            : contentStateWithEntityApplied.getSelectionAfter(),
        appendUnstyled: !isStart,
    });
};
export var insertLoop = function (editorState, loop) {
    return performActions(editorState, [
        function (x) {
            return insertLoopPart({
                editorState: x,
                originalEditorState: editorState,
                loop: loop,
                instancePosition: "TRAILING",
            });
        },
        function (x) {
            return insertLoopPart({
                editorState: x,
                originalEditorState: editorState,
                loop: loop,
                instancePosition: "LEADING",
            });
        },
    ]);
};
