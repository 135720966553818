import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { ISuccessResponse } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDateAndTime } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Loading,
  Lozenge,
  Modal,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import {
  ECreditNoteStatus,
  EInvoiceStatus,
  IExternalInvoice,
} from "./externalInvoice.types";

interface IProps {
  externalInvoiceId: string;
  onClose: () => void;
}

export const ExternalInvoiceDetailModal = (props: IProps) => {
  const { externalInvoiceId, onClose } = props;

  const [externalInvoice, setExternalInvoice] =
    React.useState<IExternalInvoice>();
  const [isCreatingCreditNote, setIsCreatingCreditNote] = React.useState(false);

  // Data
  const fetchData = async () => {
    try {
      const response = await api.get<ISuccessResponse<IExternalInvoice>>(
        `/external-invoice/${externalInvoiceId}`,
        {
          isV2: true,
        },
      );

      setExternalInvoice(response.data.data);
    } catch (error) {
      showAlert({
        error,
        type: "error",
      });

      onClose();
    }
  };

  // Lifecycle
  React.useEffect(() => {
    fetchData();
  }, [externalInvoiceId]);

  // Event handlers
  const handleCreateCreditNoteWithConfirm = () => {
    confirm({
      title: getLocalizedText("revenues.create_credit_note.confirm.title"),
      primaryActions: [
        {
          title: getLocalizedText(
            "revenues.create_credit_note.cta.without_email",
          ),
          onPress: () => {
            handleCreateCreditNote({
              automaticallySendToClient: false,
            });
          },
        },
        {
          title: getLocalizedText("revenues.create_credit_note.cta.with_email"),
          onPress: () => {
            handleCreateCreditNote({
              automaticallySendToClient: true,
            });
          },
        },
      ],
    });
  };

  const handleCreateCreditNote = async (params: {
    automaticallySendToClient: boolean;
  }) => {
    const { automaticallySendToClient } = params;
    try {
      setIsCreatingCreditNote(true);

      await api.post(`/external-invoice/${externalInvoiceId}/credit-invoice`, {
        isV2: true,
        data: {
          automaticallySendToClient,
        },
      });

      await fetchData();

      showAlert({
        type: "success",
        message: getLocalizedText("revenues.create_credit_note.success.title"),
        content: getLocalizedText(
          "revenues.create_credit_note.success.message",
        ),
      });

      setIsCreatingCreditNote(false);
    } catch (error) {
      setIsCreatingCreditNote(false);

      showAlert({ error, type: "error" });
    }
  };

  // Render
  const actions: IAction[] = [];
  if (externalInvoice) {
    if (externalInvoice.externalInvoiceUrl) {
      actions.push({
        content: getLocalizedText(
          "external_invoice.modal.actions.view_invoice",
        ),
        onClick: () => {
          window.open(externalInvoice.externalInvoiceUrl, "_blank");
        },
        appearance: "primary",
      });
    }

    if (externalInvoice.externalCreditNoteUrl) {
      actions.push({
        content: getLocalizedText(
          "external_invoice.modal.actions.view_credit_note",
        ),
        onClick: () => {
          window.open(externalInvoice.externalCreditNoteUrl, "_blank");
        },
        appearance: "outline",
      });
    }
  }

  const renderError = () => {
    const errors: string[] = [];
    if (externalInvoice?.statusInvoice === EInvoiceStatus.Failed) {
      errors.push(getLocalizedText("external_invoice.invoice.status.failed"));
    }

    if (externalInvoice?.statusCreditNote === ECreditNoteStatus.Failed) {
      errors.push(
        getLocalizedText("external_invoice.credit_note.status.failed"),
      );
    }

    if (errors.length === 0) {
      return null;
    }

    return (
      <>
        <Spacer weight={ESpacerWeight.W24} />

        <Stack spacing="tight">
          {errors.map(error => (
            <Lozenge appearance="error" key={error}>
              {error}
            </Lozenge>
          ))}
        </Stack>
      </>
    );
  };

  const renderContent = () => {
    if (!externalInvoice || isCreatingCreditNote) {
      return <Loading asDots={true} />;
    }

    return (
      <>
        {externalInvoice.externalInvoiceId && (
          <TextStyle>
            {getLocalizedText("external_invoice.modal.info.message.invoice", {
              createdAt: formatDateAndTime(externalInvoice.createdAt),
              externalId: `${externalInvoice.externalInvoiceId}`,
            })}
          </TextStyle>
        )}

        {externalInvoice?.externalCreditNoteId && (
          <>
            <Spacer weight={ESpacerWeight.W12} />

            <TextStyle variation="subdued">
              {getLocalizedText(
                "external_invoice.modal.info.message.credited",
                {
                  externalId: `${externalInvoice.externalCreditNoteId}`,
                },
              )}
            </TextStyle>
          </>
        )}

        {externalInvoice.statusCreditNote === ECreditNoteStatus.None && (
          <>
            <Spacer weight={ESpacerWeight.W24} />

            <TextStyle variation="subdued">
              {getLocalizedText(
                "external_invoice.modal.info.message.credit_info",
              )}
            </TextStyle>

            <Spacer weight={ESpacerWeight.W08} />

            <Button
              onClick={handleCreateCreditNoteWithConfirm}
              isDisabled={isCreatingCreditNote}
              appearance="link"
              color="yellow"
            >
              {getLocalizedText(
                "external_invoice.modal.actions.create_credit_note",
              )}
            </Button>
          </>
        )}

        {renderError()}
      </>
    );
  };

  return (
    <>
      <Modal
        width="medium"
        heading={getLocalizedText("external_invoice.modal.info.title")}
        onClose={onClose}
        hasDismiss={true}
        shouldCloseOnOverlayClick={true}
        actions={actions}
      >
        {renderContent()}
      </Modal>
    </>
  );
};
