import { useGetInline } from "@rentiohq/shared-frontend/dist/hooks/api.hooks";
import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authUtils from "@rentiohq/shared-frontend/dist/redux/auth/auth.utils";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IUser } from "@rentiohq/shared/dist/types/auth.types";
import { Loading, Modal, Select } from "@rentiohq/web-shared/dist/components";
import { IOption } from "@rentiohq/web-shared/dist/components/Select/createSelect";
import EnabledAccountsSelectForceRefresh from "components/EnabledAccountsSelectForceRefresh";
import { sortBy } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";

export interface IEnabledAccount {
  firstname?: string;
  lastname?: string;
  company?: string;
  id: number;
  userId: number;
  baseAccountId?: number;
}

interface IProps {
  showModal: boolean;
  onClose: () => void;
}

const getDisplayName = (enabledAccount: IEnabledAccount, user: IUser) => {
  const isPersonal = user.personalAccountId === enabledAccount.id;

  const name = getName(enabledAccount, false);
  if (isPersonal) {
    return `${name} (${getLocalizedText("system.personal")})`;
  }

  return name;
};

const EnabledAccountsSelect: React.FC<IProps> = props => {
  const { showModal, onClose } = props;

  // State
  const [selectedAccountId, setSelectedAccountId] = React.useState<
    number | undefined
  >();

  // Redux
  const dispatch = useDispatch();
  const { user } = authHooks.useSelf();

  const { data: enabledAccountsUnsorted, isFetching } = useGetInline<
    IEnabledAccount[]
  >({
    path: showModal && user ? `/users/${user.id}/enabled-accounts` : undefined,
  });

  let enabledAccounts: IEnabledAccount[] | undefined;
  if (user && enabledAccountsUnsorted) {
    enabledAccounts = sortBy(enabledAccountsUnsorted, enabledAccount => {
      const isPersonal = user.personalAccountId === enabledAccount.id;
      if (isPersonal) {
        return "11111";
      }
      return getDisplayName(enabledAccount, user);
    });
  }

  // Event handlers
  const handleSelectAccount = (option: IOption) => {
    if (typeof option.value !== "number") {
      return;
    }

    setSelectedAccountId(option.value);
  };

  const handleUseSelectedAccount = async () => {
    if (!user) {
      return;
    }

    const accountId = selectedAccountId || user.activeAccountId;
    if (!accountId) {
      return;
    }

    try {
      const newAccessTokenObject = await api.post<{
        id: /* accessToken */ string;
        refreshToken: string;
      }>(`/users/${user.id}/enabled-accounts/${accountId}/use`);

      onClose();

      dispatch(
        authActions.setAccessTokensWithoutSideEffects({
          accessToken: newAccessTokenObject.data.id,
          refreshToken: newAccessTokenObject.data.refreshToken,
        }),
      );

      await authUtils.setAccessTokenLastAccountSelectDate(new Date());

      window.location.href = window.location.origin;
    } catch (unknownError) {
      const error = unknownError as any;
      showAlert({
        type: "error",
        error,
      });
    }
  };

  // Render
  if (!user) {
    return null;
  }

  return (
    <>
      {showModal && (
        <Modal
          width="medium"
          shouldCloseOnOverlayClick={true}
          hasDismiss={true}
          onClose={onClose}
          heading={getLocalizedText(
            "modal.enabled_accounts_select.select.title",
          )}
          actions={
            !!enabledAccounts
              ? [
                  {
                    content: getLocalizedText("system.switch"),
                    onClick: handleUseSelectedAccount,
                    appearance: "primary",
                  },
                ]
              : []
          }
        >
          <Select
            id={"user-select"}
            label={getLocalizedText(
              "modal.enabled_accounts_select.select.dropdown_label",
            )}
            value={selectedAccountId || user.activeAccountId}
            onChange={handleSelectAccount}
            options={enabledAccounts?.map(item => ({
              label: getDisplayName(item, user),
              value: item.id,
            }))}
          />

          {isFetching && <Loading />}
        </Modal>
      )}
      <EnabledAccountsSelectForceRefresh />
    </>
  );
};

export default EnabledAccountsSelect;
