var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import * as insuranceActions from "./insurance.actions";
export var REDUCER_KEY_INSURANCE = "insurance";
var getDefaultState = function () { return ({
    insurance: {},
    insuranceDetail: {},
    insuranceDetailByContract: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(insuranceActions.getInsurance.types.START, function (state, _a) {
    var _b, _c;
    var insuranceId = _a.insuranceId, contractId = _a.contractId;
    return (__assign(__assign(__assign({}, state), (insuranceId
        ? {
            insuranceDetail: __assign(__assign({}, state.insuranceDetail), (_b = {}, _b[insuranceId] = {
                isFetching: true,
                fetchError: undefined,
            }, _b)),
        }
        : {})), (contractId
        ? __assign(__assign({}, state), { insuranceDetailByContract: __assign(__assign({}, state.insuranceDetailByContract), (_c = {}, _c[contractId] = {
                isFetching: true,
                fetchError: undefined,
            }, _c)) }) : {})));
});
reducer.on(insuranceActions.getInsurance.types.SUCCESS, function (state, _a) {
    var _b, _c, _d;
    var insuranceId = _a.insuranceId, contractId = _a.contractId, insurance = _a.insurance;
    return (__assign(__assign({}, state), { insurance: __assign(__assign({}, state.insurance), (_b = {}, _b[insuranceId] = insurance, _b)), insuranceDetail: __assign(__assign({}, state.insuranceDetail), (_c = {}, _c[insuranceId] = {
            isFetching: false,
            fetchError: undefined,
        }, _c)), insuranceDetailByContract: __assign(__assign({}, state.insuranceDetailByContract), (_d = {}, _d[contractId] = {
            isFetching: false,
            fetchError: undefined,
        }, _d)) }));
});
reducer.on(insuranceActions.getInsurance.types.FAILURE, function (state, _a) {
    var _b, _c;
    var insuranceId = _a.insuranceId, contractId = _a.contractId, error = _a.error;
    return (__assign(__assign(__assign({}, state), (insuranceId
        ? {
            insuranceDetail: __assign(__assign({}, state.insuranceDetail), (_b = {}, _b[insuranceId] = {
                isFetching: false,
                fetchError: error,
            }, _b)),
        }
        : {})), (contractId
        ? __assign(__assign({}, state), { insuranceDetailByContract: __assign(__assign({}, state.insuranceDetailByContract), (_c = {}, _c[contractId] = {
                isFetching: false,
                fetchError: error,
            }, _c)) }) : {})));
});
export default reducer;
