import { EditorState, Entity, Modifier, } from "draft-js";
import { getSelectionAsCursorAtStart, performActions, } from "../../../../utils/draftjs.utils";
import { INDEX_COUNT_RENDERER_TYPE } from "./IndexCountRenderer.strategy";
export var insertIndexCountAtStartOfSelection = function (editorState, data, mutability) {
    if (mutability === void 0) { mutability = "IMMUTABLE"; }
    var contentState = editorState.getCurrentContent();
    var selection = getSelectionAsCursorAtStart(editorState);
    var entityKey = Entity.create(INDEX_COUNT_RENDERER_TYPE, mutability, data);
    var newContentState = performActions(contentState, [
        function (x) { return Modifier.insertText(x, selection, " "); },
        function (x) {
            return Modifier.insertText(x, selection, "_", undefined, entityKey);
        },
        function (x) { return Modifier.insertText(x, selection, " "); },
    ]);
    return EditorState.push(editorState, newContentState, "insert-characters");
};
