var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { generateActions } from "./generator.actions";
var generateGetDetail = function (params) {
    var getDetailStart = generateActions(params).getDetailStart;
    var getDetail = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(getDetailStart.getAction(__assign(__assign({}, payload), { onSuccess: function (result) {
                    resolve(result);
                }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return getDetail;
};
var generateGetPaged = function (params) {
    var getPagedStart = generateActions(params).getPagedStart;
    var getPaged = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(getPagedStart.getAction(__assign(__assign({}, payload), { onSuccess: function (result) {
                    resolve(result);
                }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return getPaged;
};
var generateGetInfiniteLoad = function (params) {
    var getInfiniteLoadStart = generateActions(params).getInfiniteLoadStart;
    var getInfiniteLoad = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(getInfiniteLoadStart.getAction(__assign(__assign({}, payload), { onSuccess: function (result) {
                    resolve(result);
                }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return getInfiniteLoad;
};
var generateGetAllWhere = function (params) {
    var getAllWhereStart = generateActions(params).getAllWhereStart;
    var getAllWhere = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(getAllWhereStart.getAction(__assign(__assign({}, payload), { onSuccess: function (result) {
                    resolve(result);
                }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return getAllWhere;
};
var generateGetCount = function (params) {
    var getCountStart = generateActions(params).getCountStart;
    var getCount = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(getCountStart.getAction(__assign(__assign({}, payload), { onSuccess: resolve, onFailure: reject })));
        });
    };
    return getCount;
};
var generateCreate = function (params) {
    var createStart = generateActions(params).createStart;
    var create = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(createStart.getAction(__assign(__assign({}, payload), { onSuccess: function (result) {
                    resolve(result);
                }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return create;
};
var generateUpdate = function (params) {
    var updateStart = generateActions(params).updateStart;
    var update = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(updateStart.getAction(__assign(__assign({}, payload), { onSuccess: function (result) {
                    var _a;
                    (_a = payload.onSuccess) === null || _a === void 0 ? void 0 : _a.call(payload, result);
                    resolve(result);
                }, onFailure: function (error) {
                    var _a;
                    (_a = payload.onFailure) === null || _a === void 0 ? void 0 : _a.call(payload, error);
                    reject(error);
                } })));
        });
    };
    return update;
};
var generateUpdateMembers = function (params) {
    var updateMembersStart = generateActions(params).updateMembersStart;
    var updateMembers = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(updateMembersStart.getAction(__assign(__assign({}, payload), { onSuccess: function (result) {
                    var _a;
                    (_a = payload.onSuccess) === null || _a === void 0 ? void 0 : _a.call(payload, result);
                    resolve(result);
                }, onFailure: function (error) {
                    var _a;
                    (_a = payload.onFailure) === null || _a === void 0 ? void 0 : _a.call(payload, error);
                    reject(error);
                } })));
        });
    };
    return updateMembers;
};
var generateRemove = function (params) {
    var removeStart = generateActions(params).removeStart;
    var remove = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(removeStart.getAction(__assign(__assign({}, payload), { onSuccess: function () { return resolve(); }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return remove;
};
var generateArchive = function (params) {
    var archiveStart = generateActions(params).archiveStart;
    var archive = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(archiveStart.getAction(__assign(__assign({}, payload), { onSuccess: function () { return resolve(); }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return archive;
};
var generateUnarchive = function (params) {
    var unarchiveStart = generateActions(params).unarchiveStart;
    var unarchive = function (dispatch, payload) {
        return new Promise(function (resolve, reject) {
            dispatch(unarchiveStart.getAction(__assign(__assign({}, payload), { onSuccess: function () { return resolve(); }, onFailure: function (error) {
                    reject(error);
                } })));
        });
    };
    return unarchive;
};
export var generatePromises = function (params) {
    var getDetail = generateGetDetail(params);
    var getPaged = generateGetPaged(params);
    var getCount = generateGetCount(params);
    var getInfiniteLoad = generateGetInfiniteLoad(params);
    var getAllWhere = generateGetAllWhere(params);
    var create = generateCreate(params);
    var update = generateUpdate(params);
    var updateMembers = generateUpdateMembers(params);
    var remove = generateRemove(params);
    var archive = generateArchive(params);
    var unarchive = generateUnarchive(params);
    return {
        getDetail: getDetail,
        getPaged: getPaged,
        getInfiniteLoad: getInfiniteLoad,
        getCount: getCount,
        getAllWhere: getAllWhere,
        create: create,
        update: update,
        remove: remove,
        updateMembers: updateMembers,
        archive: archive,
        unarchive: unarchive,
    };
};
