import { isEqual } from "lodash";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../utils/hooks.utils";
import * as countActions from "./count.actions";
import * as countSelectors from "./count.selectors";
export var useCount = function (params) {
    var countBase = params.countBase, shouldRefetch = params.shouldRefetch;
    var countIdentifier = countBase
        ? "count_hook-".concat(hash(countBase))
        : undefined;
    var prevCountBase = usePrevious(countBase);
    // Redux
    var dispatch = useDispatch();
    var count = useSelector(function (state) {
        return countSelectors.getCount(state, countIdentifier);
    });
    var isFetching = useSelector(function (state) {
        return countSelectors.getIsFetchingCount(state, countIdentifier);
    });
    var fetchError = useSelector(function (state) {
        return countSelectors.getFetchCountError(state, countIdentifier);
    });
    // Data
    var startFetching = function () {
        if (!countIdentifier || !countBase) {
            return;
        }
        if (isFetching) {
            return;
        }
        if (!shouldRefetch && count !== undefined) {
            return;
        }
        dispatch(countActions.getCount.actions.start({
            countIdentifier: countIdentifier,
            countBase: countBase,
        }));
    };
    // Lifecycle
    React.useEffect(function () {
        if (!countIdentifier || !countBase) {
            return;
        }
        // TODO: Prevent deep equal check
        if (isEqual(countBase, prevCountBase)) {
            return;
        }
        startFetching();
    }, [countBase]);
    return {
        count: count,
        isFetching: isFetching,
        fetchError: fetchError,
        // startFetching,
    };
};
