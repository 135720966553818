import { REDUCER_KEY_MOVE_REQUEST, } from "./moveRequest.reducer";
var getStateForIdOrPublicId = function (state, idOrPublicId) {
    if (idOrPublicId === undefined) {
        return;
    }
    if (typeof idOrPublicId === "string") {
        return state.moveRequest.moveRequestsByPublicId[idOrPublicId];
    }
    return state.moveRequest.moveRequestsById[idOrPublicId];
};
export var isFetchingMoveRequest = function (state, idOrPublicId) { var _a; return ((_a = getStateForIdOrPublicId(state, idOrPublicId)) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchError = function (state, idOrPublicId) { var _a; return (_a = getStateForIdOrPublicId(state, idOrPublicId)) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var isUpdating = function (state, idOrPublicId) { var _a; return ((_a = getStateForIdOrPublicId(state, idOrPublicId)) === null || _a === void 0 ? void 0 : _a.isUpdating) || false; };
export var updateError = function (state, idOrPublicId) { var _a; return (_a = getStateForIdOrPublicId(state, idOrPublicId)) === null || _a === void 0 ? void 0 : _a.updateError; };
export var isRequestingCall = function (state, idOrPublicId) { var _a; return ((_a = getStateForIdOrPublicId(state, idOrPublicId)) === null || _a === void 0 ? void 0 : _a.isRequestingCall) || false; };
export var requestCallError = function (state, idOrPublicId) { var _a; return (_a = getStateForIdOrPublicId(state, idOrPublicId)) === null || _a === void 0 ? void 0 : _a.requestCallError; };
export var moveRequestById = function (state, idOrPublicId) {
    if (idOrPublicId === undefined) {
        return;
    }
    if (typeof idOrPublicId === "string") {
        return Object.values(state.moveRequest.moveRequests).find(function (moveRequest) { return moveRequest.publicId === idOrPublicId; });
    }
    return state.moveRequest.moveRequests[idOrPublicId];
};
