import styled from "@rentiohq/web-theme";
import { rem } from "polished";

export const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
  background-color: white;
`;

export const Sidebar = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.getColor("gray", 200)};
  overflow-y: scroll;
  padding-bottom: 24px;
`;

export const SidebarHeader = styled.div`
  padding: ${({ theme }) => `${theme.spacing()} ${theme.spacing("loose")}`};
`;

export const SidebarFooter = styled.div`
  padding: ${({ theme }) => `${theme.spacing()} ${theme.spacing("loose")}`};
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 8px;
`;

export const Content = styled.div`
  flex: 1;
`;

export const NewMessageIndicator = styled.div`
  width: ${props => rem("8px", props.theme.baseFontSize)};
  height: ${props => rem("8px", props.theme.baseFontSize)};
  border-radius: 50%;
  background-color: ${props => props.theme.getColor("primary")};
`;

export const ShowAllButtonWrap = styled.div`
  justify-content: flex-end;
`;

export const ArchivedButtonWrap = styled.div`
  justify-content: flex-start;
`;
