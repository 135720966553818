import styled from "@rentiohq/web-theme";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChildrenWrap = styled.div`
  flex: 1;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.getColor("red", 0)};
`;

export const Card = styled.div`
  padding: ${({ theme }) => `${theme.spacing()} ${theme.spacing("loose")}`};
  border-top: 1px solid ${({ theme }) => theme.getColor("gray", 200)};
  background-color: ${({ theme }) => theme.getColor("gray", 0)};
`;

export const Button = styled.div`
  padding: ${({ theme }) => `${theme.spacing()} ${theme.spacing("loose")}`};
`;
