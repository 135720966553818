import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { TPreferenceValue } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { SharedSettingsProps } from "../components/Settings.types";

interface UsePreferenceProps<T> extends SharedSettingsProps {}

export default function usePreference<T extends TPreferenceValue = string>({
  preferenceKey,
  preferencePersistScope,
}: UsePreferenceProps<T>): [T | undefined, (value: T) => void, () => void] {
  const dispatch = useDispatch();

  const preferenceValue = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<T>(state, preferenceKey),
  );

  const savePreference = (newPreferenceValue: T | null) => {
    dispatch(
      systemActions.setPreferences({
        persistScope: preferencePersistScope,
        preferences: {
          [preferenceKey]: newPreferenceValue,
        },
      }),
    );
  };

  const clearPreference = () => savePreference(null);

  return [preferenceValue, savePreference, clearPreference];
}
