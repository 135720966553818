import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import { ECountIdentifier } from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { append } from "@rentiohq/shared/dist/utils/api.utils";
import {
  Card,
  ESpacings,
  Filters,
  Icon,
  Loading,
  Page,
  Pagination,
  ResourceList,
} from "@rentiohq/web-shared/dist/components";
import AddContactModal from "@rentiohq/web-shared/dist/components/AddContactModal";
import { TContactType } from "@rentiohq/web-shared/dist/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { NumberParam, StringParam, withDefault } from "serialize-query-params";
import { useDebouncedCallback } from "use-debounce";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ts } from "../../../../services";
import { ContactOverviewRow } from "../../components/ContactOverviewRow";
import {
  DEFAULT_FILTER,
  contactsOverviewWhereFilter,
  getFilterQuery,
} from "./ContactsOverview.utils";

export const ContactsOverview = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [contactType, setContactType] = useState<TContactType>("contact");

  const [queryParams, setQueryParamValue] = useQueryParams({
    query: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1),
  });

  const [queryInput, setQueryInput] = useState<string>(queryParams.query || "");

  // Helpers
  const contactsIdentifier = useMemo(() => {
    if (queryParams.query.length > 0) {
      return `all-contacts-query-${queryParams.query}`;
    }
    return "all-contacts";
  }, [queryParams.query]);

  const countIdentifier = useMemo(() => {
    return `${ECountIdentifier.ContactsOverviewPaged}-${contactsIdentifier}`;
  }, [contactsIdentifier]);

  const countBase = useMemo(() => {
    let countFilter: { [key: string]: any } = {
      where: contactsOverviewWhereFilter,
    };

    if (queryParams.query) {
      countFilter.search = queryParams.query;
    }

    return append("/contacts/count", countFilter);
  }, [queryParams.query]);

  const [setDebouncedValue, cancel] = useDebouncedCallback(
    // to memoize debouncedFunction we use useCallback hook.
    // In this case all linters work correctly
    useCallback(
      (values: { [key: string]: any }) => {
        setQueryParamValue(values);
      },
      [setQueryParamValue],
    ),
    250,
  );

  const dispatch = useDispatch();
  const contactsPagedByPage = useSelector((state: IRootStore) =>
    contactSelectors.getContactsPaged(
      state,
      contactsIdentifier,
      queryParams.page || 1,
    ),
  );
  const contactsPagedTotalCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, countIdentifier),
  );
  const contactsPagedIsFetching = useSelector((state: IRootStore) =>
    contactSelectors.isFetchingContactsForPage(
      state,
      contactsIdentifier,
      queryParams.page || 1,
    ),
  );

  useEffect(() => cancel, [cancel]);

  useEffect(() => {
    if (queryInput === queryParams.query) {
      return;
    }
    setDebouncedValue({ query: queryInput, page: 1 });
  }, [queryInput, queryParams.query]);

  const fetchData = () => {
    const filterData = getFilterQuery(queryParams, DEFAULT_FILTER);
    dispatch(
      contactActions.getContactsPaged.actions.start({
        identifier: contactsIdentifier,
        page: queryParams.page || 1,
        filterData,
      }),
    );
  };

  useDeepCompareEffect(() => {
    fetchData();
  }, [queryParams]);

  useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier,
        countBase,
      }),
    );
  }, [queryParams.query]);

  // Events
  const handleCreateContact = (contactType: TContactType) => () => {
    setModalOpen(true);
    setContactType(contactType);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalSubmit = (x: any) => {
    setModalOpen(false);
    setQueryParamValue({ page: 1, query: undefined });
    fetchData();
  };

  const handleFiltersQueryChange = useCallback((value: any) => {
    setQueryInput(value);
  }, []);

  const handleFilterQueryValueRemove = useCallback(() => setQueryInput(""), []);

  const handlePageClick = ({ selected }: any) => {
    setQueryParamValue({ page: selected + 1 });
  };

  const initialPage = queryParams.page ? queryParams.page - 1 || 0 : 0;
  const pageCount = contactsPagedTotalCount
    ? Math.ceil(contactsPagedTotalCount / 20)
    : 1;

  // Render functions
  const renderContactItem = (item: any) => {
    return <ContactOverviewRow contact={item} />;
  };

  const renderContent = () => {
    if (contactsPagedIsFetching && !contactsPagedByPage) {
      return <Loading />;
    }

    return (
      <ResourceList
        items={contactsPagedByPage || []}
        renderItem={renderContactItem}
      />
    );
  };

  return (
    <Page
      title={ts.contactOverviewTitle()}
      // loading={initialLoading}
      // error={error}
      actions={[
        {
          appearance: "primary",
          content: ts.addContact(),
          dropdown: [
            {
              content: ts.addContact(),
              onClick: handleCreateContact("contact"),
              media: <Icon source="add" />,
            },
            {
              content: ts.addCompany(),
              onClick: handleCreateContact("company"),
              media: <Icon source="add" />,
            },
          ],
        },
      ]}
    >
      <Card>
        <Box mb={ESpacings.loose}>
          <Filters
            queryValue={queryInput}
            queryPlaceholder={ts.contactsFilterQueryPlaceholder()}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleFilterQueryValueRemove}
          />
        </Box>
        {renderContent()}
        {!!contactsPagedTotalCount && pageCount > 1 && (
          <Pagination
            initialPage={initialPage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />
        )}
      </Card>
      {modalOpen && (
        <AddContactModal
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
          type={contactType}
        />
      )}
    </Page>
  );
};
