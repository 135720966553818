var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, } from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { ActivityIndicator } from "react-native";
import { EAtomicBlockImageAlignment } from "./AtomicBlockImage.types";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n"], ["\n  width: 100%;\n  position: relative;\n"])));
export var ToolbarWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 4px;\n  margin-left: 4px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: none;\n"], ["\n  margin-top: 4px;\n  margin-left: 4px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: none;\n"])));
export var ToolbarInnerWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  border: 1px solid rgb(220, 220, 220);\n  border-radius: 2px;\n  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.04);\n  background-color: white;\n"], ["\n  display: flex;\n  flex-direction: row;\n  border: 1px solid rgb(220, 220, 220);\n  border-radius: 2px;\n  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.04);\n  background-color: white;\n"])));
export var ToolbarButtonSeparator = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 1px;\n  min-height: 100%;\n  background-color: rgb(220, 220, 220);\n"], ["\n  width: 1px;\n  min-height: 100%;\n  background-color: rgb(220, 220, 220);\n"])));
var ResizeableHandler = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 4px;\n  top: 4px;\n  bottom: 4px;\n  position: absolute;\n  cursor: col-resize;\n  border-radius: 2px;\n  background-color: ", ";\n"], ["\n  width: 4px;\n  top: 4px;\n  bottom: 4px;\n  position: absolute;\n  cursor: col-resize;\n  border-radius: 2px;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global3; });
export var ResizeableHandlerLeft = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  left: 4px;\n  display: none;\n"], ["\n  ", ";\n  left: 4px;\n  display: none;\n"])), ResizeableHandler);
export var ResizeableHandlerRight = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", ";\n  right: 4px;\n  display: none;\n"], ["\n  ", ";\n  right: 4px;\n  display: none;\n"])), ResizeableHandler);
export var InnerWrap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: ", ";\n  width: 100%;\n\n  &:hover ", " {\n    display: inherit;\n  }\n\n  &:hover ", " {\n    display: inherit;\n  }\n  &:hover ", " {\n    display: inherit;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: ", ";\n  width: 100%;\n\n  &:hover ", " {\n    display: inherit;\n  }\n\n  &:hover ", " {\n    display: inherit;\n  }\n  &:hover ", " {\n    display: inherit;\n  }\n"])), function (props) {
    switch (props.alignment) {
        case EAtomicBlockImageAlignment.Right:
            return "flex-end";
        case EAtomicBlockImageAlignment.Center:
            return "center";
        case EAtomicBlockImageAlignment.Left:
        default:
            return "flex-start;";
    }
}, ToolbarWrap, ResizeableHandlerLeft, ResizeableHandlerRight);
export var ResizeableWrap = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  min-width: 60px;\n  max-width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  background-color: ", ";\n"], ["\n  position: relative;\n  min-width: 60px;\n  max-width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  background-color: ", ";\n"])), function (props) { return (props.asVariable ? "yellow" : "transparent"); });
export var ImageVariablePlaceholder = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var Image = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n"], ["\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n"])));
export var DropWrap = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  height: 100px;\n  border-radius: 3px;\n  border: 2px solid gray;\n"], ["\n  width: 100%;\n  height: 100px;\n  border-radius: 3px;\n  border: 2px solid gray;\n"])));
export var DropText = styled.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var DropzoneWrap = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var FullActivityIndicatorWrap = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var FullActivityIndicator = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
}); })(templateObject_16 || (templateObject_16 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
