import { Route } from "react-router-dom";
import { AccountContact } from "./scenes/AccountContact";
import { getContactDetailScene } from "./scenes/ContactDetail";
import { ContactsOverview } from "./scenes/ContactsOverview";

export const getContactsScene = () => (
  <Route path="contacts">
    <Route path="by-account/:accountId" element={<AccountContact />} />

    {getContactDetailScene()}

    <Route index element={<ContactsOverview />} />
  </Route>
);
