var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CSSTransition } from "react-transition-group";
import * as S from "./SwitchFlickerAnimation.styles";
var SwitchFlickerAnimation = function (props) {
    return (_jsx(S.Wrap, { children: _jsx(CSSTransition, __assign({ in: props.value, timeout: 4000, classNames: "fade-in-condition" }, { children: props.children })) }));
};
export default SwitchFlickerAnimation;
