import { EChatMemberTypes, } from "@rentiohq/shared/dist/types/chat.types";
import * as chatActions from "../../reduxV2/chats/chat.actions";
import * as chatSelectors from "../../reduxV2/chats/chat.selectors";
import { EField } from "./schema.createChat.types";
export var isPerformingSubmitActionSelector = function (chatId) { return function (state) {
    if (chatId) {
        return chatSelectors.isUpdating(state, chatId);
    }
    return chatSelectors.isCreating(state);
}; };
export var performingSubmitActionResultSelector = function (chatId) { return function (state) {
    if (!state.CHATS_V2.detail) {
        return;
    }
    if (chatId) {
        return chatSelectors.getDetail(state, chatId);
    }
    return chatSelectors.getCreatedDetail(state);
}; };
export var performingSubmitActionErrorSelector = function (chatId) { return function (state) {
    if (chatId) {
        return chatSelectors.updateError(state, chatId);
    }
    return chatSelectors.createError(state);
}; };
export var submitActionCreator = function (chatId, members) { return function (formData) {
    if (chatId) {
        return chatActions.updateChatStart.getAction({
            id: chatId,
            data: {
                name: formData[EField.Name] || "Chat",
                propertyId: formData[EField.Property],
            },
            members: members,
        });
    }
    var newMembers = formData[EField.Members].map(function (accountId) { return ({
        accountId: accountId,
        roles: [EChatMemberTypes.Participant],
    }); }) || [];
    return chatActions.createStart.getAction({
        data: {
            name: formData[EField.Name] || "Chat",
            propertyId: formData[EField.Property],
            members: newMembers,
        },
    });
}; };
