var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as OLDcontractActionsV1 from "../../redux/contract/contract.actions";
import { onMultiple } from "../../redux/utils/reducer.utils";
import { generateReducer } from "../generators/generator.reducer";
import { activateLegalFailed, activateLegalStart, activateLegalSucceeded, cancelFailed, cancelStart, cancelSucceeded, indexAskOwnerFailed, indexAskOwnerStart, indexAskOwnerSucceeded, indexFailed, indexSkipFailed, indexSkipStart, indexSkipSucceeded, indexStart, indexSucceeded, renewFailed, renewStart, renewSucceeded, } from "./contract.actions";
import { GENERATOR_PARAMS } from "./contract.constants";
var getExtraDefaultState = function () { return ({
    indexContract: {},
    renewContract: {},
    requestMoveService: {},
    requestInsurance: {},
    activateLegal: {},
    cancelContract: {},
    deleteCancellation: {},
}); };
var _reducer = generateReducer(GENERATOR_PARAMS, getExtraDefaultState);
var reducer = _reducer;
onMultiple(reducer, [indexAskOwnerStart.type, indexSkipStart.type, indexStart.type], function (state, action) {
    var _a;
    var id = action.id;
    return __assign(__assign({}, state), { indexContract: __assign(__assign({}, state.indexContract), (_a = {}, _a[id] = __assign(__assign({ 
            // @ts-ignore TODO: Typescript upgrade
            didIndexContract: false }, state.indexContract[id]), { isIndexingContract: true, indexContractError: undefined }), _a)) });
});
onMultiple(reducer, [indexAskOwnerFailed.type, indexSkipFailed.type, indexFailed.type], function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return __assign(__assign({}, state), { indexContract: __assign(__assign({}, state.indexContract), (_b = {}, _b[id] = {
            isIndexingContract: false,
            indexContractError: error,
            didIndexContract: false,
        }, _b)) });
});
reducer.on(cancelStart.type, function (state, _a) {
    var _b;
    var id = _a.id;
    return __assign(__assign({}, state), { cancelContract: __assign(__assign({}, state.cancelContract), (_b = {}, _b[id] = {
            isCancelingContract: true,
            cancelContractError: undefined,
        }, _b)) });
});
reducer.on(cancelFailed.type, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return __assign(__assign({}, state), { cancelContract: __assign(__assign({}, state.cancelContract), (_b = {}, _b[id] = {
            isCancelingContract: false,
            cancelContractError: error,
        }, _b)) });
});
reducer.on(cancelSucceeded.type, function (state, _a) {
    var _b, _c;
    var _d;
    var id = _a.id, result = _a.result;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_b = {}, _b[id] = __assign(__assign({}, state.detail[id]), { entity: __assign(__assign({}, (_d = state.detail[id]) === null || _d === void 0 ? void 0 : _d.entity), result.data) }), _b)), cancelContract: __assign(__assign({}, state.cancelContract), (_c = {}, _c[id] = {
            isCancelingContract: false,
            cancelContractError: undefined,
        }, _c)) });
});
reducer.on(renewStart.type, function (state, _a) {
    var _b;
    var id = _a.id;
    return __assign(__assign({}, state), { renewContract: __assign(__assign({}, state.renewContract), (_b = {}, _b[id] = {
            isRenewing: true,
            renewError: undefined,
        }, _b)) });
});
reducer.on(renewSucceeded.type, function (state, _a) {
    var _b;
    var id = _a.id;
    return __assign(__assign({}, state), { renewContract: __assign(__assign({}, state.renewContract), (_b = {}, _b[id] = {
            isRenewing: false,
            renewError: undefined,
        }, _b)) });
});
reducer.on(renewFailed.type, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return __assign(__assign({}, state), { renewContract: __assign(__assign({}, state.renewContract), (_b = {}, _b[id] = {
            isRenewing: false,
            renewError: error,
        }, _b)) });
});
reducer.on(indexSucceeded.type, function (state, payload) {
    var _a, _b;
    var id = payload.id, result = payload.result;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[id] = __assign(__assign({}, state.detail[id]), { entity: result.data }), _a)), indexContract: __assign(__assign({}, state.indexContract), (_b = {}, _b[id] = {
            isIndexingContract: false,
            indexContractError: undefined,
            didIndexContract: true,
        }, _b)) });
});
reducer.on(indexSkipSucceeded.type, function (state, _a) {
    var _b, _c;
    var id = _a.id, result = _a.result;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_b = {}, _b[id] = __assign(__assign({}, state.detail[id]), { entity: result.data }), _b)), indexContract: __assign(__assign({}, state.indexContract), (_c = {}, _c[id] = {
            isIndexingContract: false,
            indexContractError: undefined,
            didIndexContract: true,
        }, _c)) });
});
reducer.on(indexAskOwnerSucceeded.type, function (state, _a) {
    var _b, _c;
    var id = _a.id, result = _a.result;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_b = {}, _b[id] = __assign(__assign({}, state.detail[id]), { entity: result.data }), _b)), indexContract: __assign(__assign({}, state.indexContract), (_c = {}, _c[id] = {
            isIndexingContract: false,
            indexContractError: undefined,
            didIndexContract: true,
        }, _c)) });
});
// TODO: when move requests have been migrated to V2, also update the next three
reducer.on(OLDcontractActionsV1.requestMoveService.types.START, function (state, _a) {
    var _b;
    var contractId = _a.contractId;
    return __assign(__assign({}, state), { requestMoveService: __assign(__assign({}, state.requestMoveService), (_b = {}, _b[contractId] = {
            isRequestingMoveService: true,
            requestMoveServiceError: undefined,
        }, _b)) });
});
reducer.on(OLDcontractActionsV1.requestMoveService.types.SUCCESS, function (state, payload) {
    var _a, _b;
    var contractId = payload.contractId, contract = payload.contract;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[contractId] = __assign(__assign({}, state.detail[contractId]), { entity: contract }), _a)), requestMoveService: __assign(__assign({}, state.requestMoveService), (_b = {}, _b[contractId] = {
            isRequestingMoveService: false,
            requestMoveServiceError: undefined,
        }, _b)) });
});
reducer.on(OLDcontractActionsV1.requestMoveService.types.FAILURE, function (state, _a) {
    var _b;
    var contractId = _a.contractId, error = _a.error;
    return __assign(__assign({}, state), { requestMoveService: __assign(__assign({}, state.requestMoveService), (_b = {}, _b[contractId] = {
            isRequestingMoveService: false,
            requestMoveServiceError: error,
        }, _b)) });
});
// INSURANCE
// TODO: when insurance requests have been migrated to V2, also update the next three
reducer.on(OLDcontractActionsV1.requestInsurance.types.START, function (state, _a) {
    var _b;
    var contractId = _a.contractId;
    return __assign(__assign({}, state), { requestInsurance: __assign(__assign({}, state.requestInsurance), (_b = {}, _b[contractId] = {
            isRequestingInsurance: true,
            requestInsuranceError: undefined,
        }, _b)) });
});
reducer.on(OLDcontractActionsV1.requestInsurance.types.SUCCESS, function (state, payload) {
    var _a, _b;
    var contractId = payload.contractId, contract = payload.contract;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[contractId] = __assign(__assign({}, state.detail[contractId]), { entity: contract }), _a)), requestInsurance: __assign(__assign({}, state.requestInsurance), (_b = {}, _b[contractId] = {
            isRequestingInsurance: false,
            requestInsuranceError: undefined,
        }, _b)) });
});
reducer.on(OLDcontractActionsV1.requestInsurance.types.FAILURE, function (state, _a) {
    var _b;
    var contractId = _a.contractId, error = _a.error;
    return __assign(__assign({}, state), { requestInsurance: __assign(__assign({}, state.requestInsurance), (_b = {}, _b[contractId] = {
            isRequestingInsurance: false,
            requestInsuranceError: error,
        }, _b)) });
});
reducer.on(activateLegalStart.type, function (state, payload) {
    var _a;
    var id = payload.id;
    return __assign(__assign({}, state), { activateLegal: __assign(__assign({}, state.activateLegal), (_a = {}, _a[id] = {
            isActivatingLegal: true,
            activationError: undefined,
        }, _a)) });
});
reducer.on(activateLegalSucceeded.type, function (state, _a) {
    var _b, _c;
    var _d;
    var id = _a.id, result = _a.result;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_b = {}, _b[id] = __assign(__assign({}, state.detail[id]), { entity: __assign(__assign({}, (_d = state.detail[id]) === null || _d === void 0 ? void 0 : _d.entity), result.data) }), _b)), activateLegal: __assign(__assign({}, state.activateLegal), (_c = {}, _c[id] = {
            isActivatingLegal: false,
            activationError: undefined,
        }, _c)) });
});
reducer.on(activateLegalFailed.type, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return __assign(__assign({}, state), { activateLegal: __assign(__assign({}, state.activateLegal), (_b = {}, _b[id] = {
            isActivatingLegal: false,
            activationError: error,
        }, _b)) });
});
reducer.on(activateLegalStart.type, function (state, payload) {
    var _a;
    var id = payload.id;
    return __assign(__assign({}, state), { deleteCancellation: __assign(__assign({}, state.deleteCancellation), (_a = {}, _a[id] = {
            isDeletingCanceling: true,
            deleteCancellationError: undefined,
        }, _a)) });
});
reducer.on(activateLegalSucceeded.type, function (state, _a) {
    var _b, _c;
    var _d;
    var id = _a.id, result = _a.result;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_b = {}, _b[id] = __assign(__assign({}, state.detail[id]), { entity: __assign(__assign({}, (_d = state.detail[id]) === null || _d === void 0 ? void 0 : _d.entity), result.data) }), _b)), deleteCancellation: __assign(__assign({}, state.deleteCancellation), (_c = {}, _c[id] = {
            isDeletingCanceling: false,
            deleteCancellationError: undefined,
        }, _c)) });
});
reducer.on(activateLegalFailed.type, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return __assign(__assign({}, state), { deleteCancellation: __assign(__assign({}, state.deleteCancellation), (_b = {}, _b[id] = {
            isDeletingCanceling: false,
            deleteCancellationError: error,
        }, _b)) });
});
/*
// TODO: use the three below when new structure has been implemented.
reducer.on<typeof requestMoveServiceStart.payloadType>(
  requestMoveServiceStart.type,
  (state, { id }) => {
    return {
      ...state,
      requestMoveService: {
        ...state.requestMoveService,
        [id]: {
          isRequestingMoveService: true,
          requestMoveServiceError: undefined,
        },
      },
    };
  },
);

reducer.on<typeof requestMoveServiceSucceeded.payloadType>(
  requestMoveServiceSucceeded.type,
  (state, { id, result }) => {
    return {
      ...state,
      detail: {
        ...state.detail,
        [id]: {
          ...state.detail[id],
          entity: result.data,
        },
      },
      requestMoveService: {
        ...state.requestMoveService,
        [id]: {
          isRequestingMoveService: false,
          requestMoveServiceError: undefined,
        },
      },
    };
  },
);

reducer.on<typeof requestMoveServiceFailed.payloadType>(
  requestMoveServiceFailed.type,
  (state, { id, error }) => {
    return {
      ...state,
      requestMoveService: {
        ...state.requestMoveService,
        [id]: {
          isRequestingMoveService: false,
          requestMoveServiceError: error,
        },
      },
    };
  },
); */
export default reducer;
