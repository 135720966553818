var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
export var RemoveButton = styled(ButtonBasic).attrs(function (props) { return ({
    titleProps: {
        style: { color: props.theme.colors.errorText },
    },
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var templateObject_1;
