import { EDocumentPackageTypes } from "@rentiohq/shared/dist/types/documentPackage.types";
import { EDocumentPackageStageSimple } from "@rentiohq/web-shared/dist/types";
import isEmpty from "ramda/es/isEmpty";
import isNil from "ramda/es/isNil";
import mergeDeepLeft from "ramda/es/mergeDeepLeft";
import { documentPackageUtils } from "../../../utils";

export const ORDER_MAP = ["createdAt", "", "", "", ""];

export const DEFAULT_FILTER = {
  filter: {
    where: {},
    include: ["property", "contract"],
    order: "createdAt ASC",
  },
};

export const getFilterQuery = (values: any, defaultFilter: any) => {
  let newFilter = defaultFilter;

  if (values.stage && values.stage.length > 0) {
    let statuses: any = [];

    values.stage.forEach((simpleStage: EDocumentPackageStageSimple) => {
      statuses = [
        ...statuses,
        ...documentPackageUtils.getAdvancedStages(simpleStage),
      ];
    });
    newFilter = mergeDeepLeft(
      { filter: { where: { status: { inq: statuses } } } },
      newFilter,
    );
  }

  if (values.documentPackageType && values.documentPackageType.length > 0) {
    let statuses: any = [];
    values.documentPackageType.forEach((status: EDocumentPackageTypes) => {
      statuses = [...statuses, status];
    });
    newFilter = mergeDeepLeft(
      { filter: { where: { type: { inq: statuses } } } },
      newFilter,
    );
  }

  if (values.order) {
    newFilter = mergeDeepLeft({ filter: { order: values.order } }, newFilter);
  }

  if (values.query) {
    newFilter = mergeDeepLeft({ filter: { search: values.query } }, newFilter);
  }

  return newFilter;
};

export const getHasActiveFilter = (filter: any) => {
  const hasQuery = !isEmpty(filter.query);
  const hasStage = !isEmpty(filter.stage) && !isNil(filter.stage);
  const hasContractType =
    !isEmpty(filter.documentPackageType) && !isNil(filter.documentPackageType);

  const hasFilter = [hasQuery, hasStage, hasContractType];

  return hasFilter.filter(Boolean).length > 0;
};
