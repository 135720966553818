import styled, { spacing } from "@rentiohq/web-theme";

export const DragCountStyled = styled.div`
  top: -${spacing("tight")};
  left: -${spacing("tight")};
  position: absolute;
  z-index: 100;
  width: ${spacing("extraLoose")};
  height: ${spacing("extraLoose")};
  border-radius: 50%;
  font-size: 16px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.white[800]};
  background-color: ${({ theme }) => theme.colors.primary[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
