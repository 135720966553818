var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { Grid } from "../";
export function QuickActionCardCollection(_a) {
    var children = _a.children;
    var width = useMemo(function () {
        if (!children || !Array.isArray(children))
            return "100%";
        return "".concat(100 / (children || []).filter(Boolean).length - 0.5, "%");
    }, [children]);
    if (!children || !Array.isArray(children))
        return _jsx(_Fragment, { children: children });
    return (_jsx(Grid, __assign({ justifyContent: "space-between", spacing: "tight" }, { children: children === null || children === void 0 ? void 0 : children.map(function (child, i) { return (_jsx(Grid.Item, __assign({ width: width }, { children: child }), i)); }) })));
}
