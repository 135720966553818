var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EContractMemberTypes, EContractPaymentRepetitionType, EInsuranceExternalStatus, } from "@rentiohq/shared/dist/types/contract.types";
import { ELocale } from "@rentiohq/shared/dist/types/i18n.types";
import { EPropertyMemberTypes, } from "@rentiohq/shared/dist/types/property.types";
import { getMembersWithOneOfRoles } from "@rentiohq/shared/dist/utils/roles.utils";
import { getLocale, getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getStore } from "../../utils/redux/redux.utils";
import { EField } from "./schema.createRentDeposit.types";
import { customGetForeignerContactsForAccountIds } from "./schema.createRentDeposit.utils.fetchContracts";
var NOW = new Date();
export var getNavigationTitle = function (_) {
    return getLocalizedText("new.rent_deposit.contract");
};
export var getMonthlyPrice = function (contract) {
    switch (contract.repetitionType) {
        case EContractPaymentRepetitionType.Yearly:
            return contract.currentPrice / 12;
        case EContractPaymentRepetitionType.Quarterly:
            return contract.currentPrice / 3;
        default:
            return contract.currentPrice;
    }
};
export var getInitialValues = function (extraData) {
    var _a;
    var contract = extraData.contract;
    // Only support for NL & FR contracts
    var locale = ELocale.NL;
    if (getLocale() === ELocale.FR) {
        locale = ELocale.FR;
    }
    return _a = {},
        _a[EField.StartDate] = contract.startDate,
        _a[EField.DueDate] = NOW,
        _a[EField.Amount] = contract.rentDepositAmount,
        _a[EField.Locale] = locale,
        _a;
};
export var getMergedMembers = function (contract, property) {
    return __spreadArray(__spreadArray([], contract.members.filter(function (member) {
        return member.roles.some(function (role) {
            return [
                EContractMemberTypes.Owner,
                EContractMemberTypes.Tenant,
                EContractMemberTypes.Parent,
            ].includes(role);
        });
    }), true), property.members.filter(function (member) {
        return member.roles.includes(EPropertyMemberTypes.FinancialManager);
    }), true);
};
export var getForeigners = function (accountIds) {
    var _a;
    if (!accountIds) {
        return;
    }
    var state = (_a = getStore()) === null || _a === void 0 ? void 0 : _a.getState();
    if (!state) {
        return;
    }
    return customGetForeignerContactsForAccountIds(state, accountIds);
};
export var getTenants = function (formData, extraData) {
    var contract = extraData.contract;
    return getMembersWithOneOfRoles(contract.members, [
        EContractMemberTypes.Tenant,
        EContractMemberTypes.Parent,
    ])
        .filter(function (member) {
        return (formData[EField.Signers] || []).includes(member.account.id);
    })
        .map(function (member) { return member.account; });
};
export var showInsurance = function (extraData) {
    return extraData.contract.insuranceExternalStatus ===
        EInsuranceExternalStatus.None && extraData.withInsuranceRequest;
};
