var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue, getValueOrExtraValue, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getVatFieldsForSchema, getVatFieldsForUiSchema, } from "../createContract/schema.createContract.utils.vat";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle } from "./schema.createPayment.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.CommissionPrice],
            properties: __assign((_a = {}, _a[EField.Header + "8"] = {
                default: undefined,
            }, _a[EField.CommissionPrice] = {
                default: getValue(EField.CommissionPrice, initialValues, defaultValues),
            }, _a), getVatFieldsForSchema({
                defaultValues: defaultValues,
                initialValues: initialValues,
                fieldKey: EField.CommissionVat,
                infoFieldKey: EField.CommissionVatHelpInfo,
                baseAmountFieldKey: EField.CommissionPrice,
                defaultValue: getValueOrExtraValue(EField.CommissionVat, defaultValues, 21),
                showInfoIfEmpty: true,
            })),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (__assign((_a = { "rentio:title": getLocalizedText("payment.payment_order.commission.heading.summary"), "rentio:navigationTitle": getNavigationTitle(extraData), "ui:field": ERentioFormField.Grid, grid: {
                row: [
                    {
                        col: {
                            width: 1,
                            children: [EField.Header + "8"],
                        },
                    },
                    {
                        col: {
                            width: [1, 3 / 5],
                            children: [EField.CommissionPrice],
                        },
                    },
                    {
                        col: {
                            width: [1, 2 / 5],
                            children: [EField.CommissionVat],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.CommissionVatHelpInfo],
                        },
                    },
                ],
            } }, _a[EField.Header + "8"] = {
        "ui:field": ERentioFormField.SectionHeader,
        getTitle: function () {
            return getLocalizedText("payment.payment_order.commission.heading", {});
        },
    }, _a[EField.CommissionPrice] = {
        "ui:field": ERentioFormField.Number,
        title: getLocalizedText("payment.payment_order.commission.field"),
        formatSummary: function (formData) { return formData && formatCurrency(formData); },
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        autoFocus: true,
    }, _a), getVatFieldsForUiSchema({
        fieldKey: EField.CommissionVat,
        infoFieldKey: EField.CommissionVatHelpInfo,
        baseAmountFieldKey: EField.CommissionPrice,
    })));
};
