var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { round } from "lodash";
import { Card, DisplayText, ESpacings } from "../../../";
import ContractCard from "../../../Cards/ContractCard";
import PropertyCard from "../../../Cards/PropertyCard";
import { ResourceList, ResourceListItem } from "../../../ResourceList";
import TextStyle from "../../../TextStyle";
var InvoiceItemInfo = function (props) {
    var invoiceItem = props.invoiceItem;
    // Render
    var renderCardHeader = function (heading) { return (_jsx(DisplayText, __assign({ size: "extraSmall", space: "tight" }, { children: heading }))); };
    return (_jsxs(_Fragment, { children: [_jsx(Card, __assign({ heading: renderCardHeader(getLocalizedText("invoice_item.header.price")), space: ESpacings.base, actions: [] }, { children: _jsx(ResourceList, { items: [
                        {
                            title: getLocalizedText("invoice.item.prepaid"),
                            value: getLocalizedText("system.yes"),
                            show: invoiceItem.isPrepaid,
                        },
                        {
                            title: getLocalizedText("invoices.detail.quantity.heading"),
                            value: invoiceItem.amount,
                            show: invoiceItem.amount > 1,
                        },
                        {
                            title: getLocalizedText("invoices.detail.unit_price.heading"),
                            value: formatCurrency(invoiceItem.price),
                            show: invoiceItem.amount > 1,
                        },
                        {
                            title: getLocalizedText("invoices.detail.total_excl.heading"),
                            value: formatCurrency(invoiceItem.amount * invoiceItem.price),
                        },
                        {
                            title: getLocalizedText("invoices.detail.vat_percentage.heading"),
                            value: "".concat(invoiceItem.VATPercentage * 100, "%"),
                        },
                        {
                            title: getLocalizedText("invoices.detail.total_incl.heading"),
                            value: formatCurrency(round(invoiceItem.amount *
                                invoiceItem.price *
                                (1 + invoiceItem.VATPercentage), 2)),
                        },
                    ].filter(function (_a) {
                        var _b = _a.show, show = _b === void 0 ? true : _b;
                        return show;
                    }), renderItem: function (item) { return (_jsxs(ResourceListItem, __assign({ mediaSize: "medium", boxProps: { p: 2 } }, { children: [_jsx(TextStyle, __assign({ variation: "subdued", element: "div" }, { children: item.title })), _jsx("div", { children: item.value })] }))); }, hasSeparator: false }) })), invoiceItem.propertyId && (_jsx(PropertyCard, { propertyId: invoiceItem.propertyId })), invoiceItem.contractId && (_jsx(ContractCard, { contractId: invoiceItem.contractId }))] }));
};
export default InvoiceItemInfo;
