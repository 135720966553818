import styled, { spacing } from "@rentiohq/web-theme";

export const Mail = styled.div`
  margin-bottom: ${spacing("tight")};
  padding: ${spacing()};
  border: 1px solid ${({ theme }) => theme.colors.neutral40};
`;
export const Header = styled.div`
  margin-bottom: ${spacing()};
  padding-bottom: ${spacing()};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
`;
export const Content = styled.div`
  text-align: center;
`;
