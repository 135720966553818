import { QuickActionCard } from "@rentiohq/web-shared/dist/components";
import { IQuickAction } from "@rentiohq/web-shared/dist/components/QuickActionCard/QuickActionCard";

export interface IFeatureSpecificQuickActionCard extends IQuickAction {
  count: number;
  iconColor?: string;
  emptyStateUrl?: string;
  emptyDescription?: string;
  emptySubheading?: string;
  emptyOnClick?: () => void;
  featureEnabled: boolean;
}

export const FeatureSpecificQuickActionCard = ({
  count,
  icon,
  iconColor,
  url,
  actions,
  heading,
  subheading,
  countDescription,
  emptyDescription,
  emptyStateUrl,
  emptyText,
  emptySubheading,
  emptyOnClick,
  featureEnabled,
}: IFeatureSpecificQuickActionCard) => {
  if (count > 0 && featureEnabled) {
    return (
      <QuickActionCard
        heading={heading}
        subheading={subheading}
        url={url}
        icon={icon}
        count={count}
        countDescription={countDescription}
        actions={actions}
        iconColor={iconColor}
      />
    );
  }

  return (
    <QuickActionCard
      heading={heading}
      subheading={emptySubheading}
      countDescription={emptyDescription}
      url={emptyStateUrl}
      icon={icon}
      iconColor={iconColor}
      emptyText={emptyText}
      onClick={emptyOnClick}
    />
  );
};
