var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import logger from "@rentiohq/shared/dist/logger";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import api from "../../utils/api/api.utils";
import * as invitesActions from "./invites.actions";
import * as invitesApi from "./invites.api";
import * as invitesSelectors from "./invites.selectors";
function getInvitesStartFlow(_a) {
    var onSuccess, invites, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                onSuccess = payload.onSuccess;
                return [4 /*yield*/, call(invitesApi.getInvites)];
            case 1:
                invites = (_b.sent()).data;
                return [4 /*yield*/, put(invitesActions.getInvites.actions.success({
                        invites: invites,
                    }))];
            case 2:
                _b.sent();
                if (onSuccess) {
                    onSuccess(invites);
                }
                return [3 /*break*/, 5];
            case 3:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(invitesActions.getInvites.actions.failure({ error: error }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function acceptAllInvitesStartFlow() {
    var invites, _a, _b, unknownError_2, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 6, , 8]);
                _b = (_a = invitesSelectors).invites;
                return [4 /*yield*/, select()];
            case 1:
                invites = _b.apply(_a, [_c.sent()]) || [];
                if (!(invites.length === 0)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(invitesActions.acceptAllInvites.actions.success({}))];
            case 2:
                _c.sent();
                return [2 /*return*/];
            case 3: return [4 /*yield*/, all(invites.map(function (invite) {
                    return call(api.post, invite.accept);
                }))];
            case 4:
                _c.sent();
                return [4 /*yield*/, put(invitesActions.acceptAllInvites.actions.success({}))];
            case 5:
                _c.sent();
                return [3 /*break*/, 8];
            case 6:
                unknownError_2 = _c.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(invitesActions.acceptAllInvites.actions.failure({ error: error }))];
            case 7:
                _c.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function acceptInviteStartFlow(_a) {
    var invite, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                invite = payload.invite;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(api.post, invite.accept)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(invitesActions.acceptInvite.actions.success({
                        invite: invite,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(invitesActions.acceptInvite.actions.failure({ error: error, invite: invite }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function declineInviteStartFlow(_a) {
    var invite, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                invite = payload.invite;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(api.post, invite.decline)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(invitesActions.declineInvite.actions.success({
                        invite: invite,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(invitesActions.declineInvite.actions.failure({ error: error, invite: invite }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export default function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(invitesActions.getInvites.types.START, getInvitesStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(invitesActions.acceptAllInvites.types.START, acceptAllInvitesStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(invitesActions.acceptInvite.types.START, acceptInviteStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(invitesActions.declineInvite.types.START, declineInviteStartFlow)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
