import * as actions from "./application.actions";
import * as api from "./application.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./application.constants";
import * as hooks from "./application.hooks";
import * as promises from "./application.promises";
import reducer from "./application.reducer";
import { saga } from "./application.saga";
import * as selectors from "./application.selectors";
export var REDUCER_KEY_APPLICATIONS = REDUCER_KEY;
export var applicationModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
