import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.indexContractBulkIndex.1";
import { getInitialValues } from "./schema.indexContractBulkIndex.utils";
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(extraData, initialValues),
            uiSchema: uiSchema1(extraData, initialValues),
        },
    ];
});
