var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var Wrap = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: 2;\n  padding-right: 2;\n  background-color: ", ";\n  display: inline;\n"], ["\n  padding-left: 2;\n  padding-right: 2;\n  background-color: ", ";\n  display: inline;\n"])), function (props) {
    // @ts-ignore
    return props.filledIn ? "palegreen" : props.theme.getColor("red", 200);
});
export var ContentWrap = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  white-space: pre-line;\n"], ["\n  white-space: pre-line;\n"])));
var templateObject_1, templateObject_2;
