var _a;
import { getAllSelectors } from "../generic/all.selectors";
import CONFIG from "./templateDocument.config";
export var create = (_a = getAllSelectors(function (state) { return state[CONFIG.REDUCER_KEY]; }), _a.create), update = _a.update, deleteObject = _a.deleteObject, detail = _a.detail, paged = _a.paged;
export var templateDocumentDetail = function (state, id) {
    return (id && state[CONFIG.REDUCER_KEY].dataTemplateDocumentDetail[id]) || undefined;
};
export var isFetchingTemplateDocumentDetail = function (state, id) {
    var _a;
    return (id && ((_a = state[CONFIG.REDUCER_KEY].templateDocumentDetail[id]) === null || _a === void 0 ? void 0 : _a.isFetching)) ||
        false;
};
export var templateDocumentDetailFetchError = function (state, id) {
    var _a;
    return (id && ((_a = state[CONFIG.REDUCER_KEY].templateDocumentDetail[id]) === null || _a === void 0 ? void 0 : _a.fetchError)) ||
        undefined;
};
export var isUpdatingTemplateDocumentDetail = function (state, id) {
    var _a;
    return (id && ((_a = state[CONFIG.REDUCER_KEY].templateDocumentDetail[id]) === null || _a === void 0 ? void 0 : _a.isUpdating)) ||
        false;
};
export var templateDocumentDetailUpdateError = function (state, id) {
    var _a;
    return (id && ((_a = state[CONFIG.REDUCER_KEY].templateDocumentDetail[id]) === null || _a === void 0 ? void 0 : _a.updateError)) ||
        undefined;
};
