import { Route } from "react-router-dom";
import { TaskDetailsDashboard } from "scenes/Tasks/components/TaskDetailsDashboard";
import TaskForm from "./components/TaskForm";
import { Tasks } from "./Tasks";

export const getTasksScene = () => (
  <Route path="tasks">
    <Route path="add" element={<TaskForm />} />

    <Route path=":taskId">
      <Route path="edit" element={<TaskForm />} />

      <Route path="*" element={<TaskDetailsDashboard />} />
    </Route>

    <Route index element={<Tasks />} />
  </Route>
);
