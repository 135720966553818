import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getContractAmountAndTenants } from "../../utils/contract.utils";
import { getTerm } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { ETypeSelect } from "./schema.createPayment.0";
import { EField } from "./schema.createPayment.types";
import { getContract, getNavigationTitle } from "./schema.createPayment.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var paymentOrder = extraData.paymentOrder, _b = extraData.activeContracts, activeContracts = _b === void 0 ? [] : _b, _c = extraData.endedContracts, endedContracts = _c === void 0 ? [] : _c;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header + "1"] = {
                    default: undefined,
                },
                _a[EField.ActiveContract] = {
                    default: getValue(EField.ActiveContract, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return paymentOrder || activeContracts.length > 0; },
                    options: activeContracts.map(function (contract) { return ({
                        id: contract.id,
                        getValue: function () { return getTerm(contract); },
                        getSubValue: function () { return getContractAmountAndTenants(contract); },
                    }); }),
                    handleChange: function (fieldName) {
                        if (fieldName === EField.EndedContract ||
                            fieldName === EField.WithoutContract) {
                            return { shouldSetNewValue: true, newValue: undefined };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.WithoutContract] = {
                    default: getValue(EField.WithoutContract, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return defaultValues[EField.TypeSelect] === ETypeSelect.Once; },
                    options: [
                        {
                            id: "1",
                            getValue: function () {
                                return getLocalizedText("payments.create.contract.heading.unspecified");
                            },
                            getSubValue: function () {
                                return getLocalizedText("payments.create.contract.heading.unspecified_message");
                            },
                        },
                    ],
                    handleChange: function (fieldName) {
                        if (fieldName === EField.ActiveContract ||
                            fieldName === EField.EndedContract) {
                            return { shouldSetNewValue: true, newValue: undefined };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.EndedContract] = {
                    default: getValue(EField.EndedContract, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () {
                        return endedContracts.length > 0 &&
                            defaultValues[EField.TypeSelect] !== ETypeSelect.Recurring;
                    },
                    options: endedContracts.map(function (contract) {
                        return {
                            id: contract.id,
                            getValue: function () { return getTerm(contract); },
                            getSubValue: function () { return getContractAmountAndTenants(contract); },
                        };
                    }),
                    handleChange: function (fieldName) {
                        if (fieldName === EField.ActiveContract ||
                            fieldName === EField.WithoutContract) {
                            return { shouldSetNewValue: true, newValue: undefined };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("payments.create.contract.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData),
            disabledOnSummary: true
        },
        _a[EField.Header + "1"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("payments.create.contract.heading"),
        },
        _a[EField.ActiveContract] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("payments.create.contract.heading.active_contracts"),
            summaryTitle: getLocalizedText("system.contract"),
            formatSummary: function (value) {
                if (!value) {
                    return;
                }
                var contract = getContract(value, extraData);
                if (!contract) {
                    return;
                }
                return getTerm(contract);
            },
            hideOnSummaryIfEmpty: true,
        },
        _a[EField.WithoutContract] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("system.other"),
            summaryTitle: getLocalizedText("system.contract"),
            formatSummary: function (value) {
                return getLocalizedText("payments.create.contract.heading.without_contract");
            },
            shouldShowOnSummary: function (formData) { return formData[EField.WithoutContract]; },
        },
        _a[EField.EndedContract] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("payments.create.contract.heading.finished_contracts"),
            summaryTitle: getLocalizedText("system.contract"),
            formatSummary: function (value) {
                if (!value) {
                    return;
                }
                var contract = getContract(value, extraData);
                if (!contract) {
                    return;
                }
                return getTerm(contract);
            },
            hideOnSummaryIfEmpty: true,
        },
        _a;
};
export var getValidate = function (extraData) { return function (formData, errors) {
    var _a, _b, _c;
    var _d = extraData.activeContracts, activeContracts = _d === void 0 ? [] : _d, _e = extraData.endedContracts, endedContracts = _e === void 0 ? [] : _e;
    var hasSelected = formData[EField.ActiveContract] ||
        formData[EField.WithoutContract] ||
        formData[EField.EndedContract];
    if (!hasSelected) {
        if (!formData[EField.ActiveContract] && activeContracts.length > 0) {
            (_a = errors[EField.ActiveContract]) === null || _a === void 0 ? void 0 : _a.addError(getLocalizedText("payments.create.contract.no_contract", {
                type: getLocalizedText("system.active_contract").toLowerCase(),
            }));
        }
        if (!formData[EField.EndedContract] && endedContracts.length > 0) {
            (_b = errors[EField.EndedContract]) === null || _b === void 0 ? void 0 : _b.addError(activeContracts.length > 0
                ? getLocalizedText("payments.create.contract.no_finished_contract")
                : getLocalizedText("payments.create.contract.no_contract", {
                    type: getLocalizedText("system.finished_contract"),
                }));
        }
        if (formData.hasOwnProperty(EField.WithoutContract)) {
            (_c = errors[EField.WithoutContract]) === null || _c === void 0 ? void 0 : _c.addError(getLocalizedText("payments.create.contract.no_other_contract"));
        }
    }
    return errors;
}; };
