import { BoxProps, Flex } from "@rebass/grid";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { ContractDurationChip } from "./ContractDurationChip";
import { ContractStatusChip } from "./ContractStatusChip";

interface IProps {
  contract: IContract;
  boxProps?: BoxProps;
}

export const ContractStatusChips = ({ contract, boxProps }: IProps) => {
  return (
    <Flex alignItems="center" {...boxProps}>
      <ContractStatusChip contract={contract} />
      <ContractDurationChip contract={contract} />
    </Flex>
  );
};
