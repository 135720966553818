var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getPagedId as getPagedIdBase, } from "../generic/paged.utils";
export var getPagedId = function (params) {
    return getPagedIdBase(__assign(__assign({}, params), { entityName: "templateDocument" }));
};
export var getSignatureReferenceForAccountId = function (accountId) {
    return "#REN".concat(accountId, "_").concat(100, "_").concat(200, "#");
};
