import { createGenerator } from "./create.api";
import { deleteGenerator } from "./delete.api";
import { getDetailGenerator } from "./detail.api";
import { getPagedGenerator } from "./paged.api";
import { updateGenerator } from "./update.api";
export var apiGenerator = function (params) { return ({
    create: createGenerator(params),
    deleteObject: deleteGenerator(params),
    getDetail: getDetailGenerator(params),
    getPaged: getPagedGenerator(params),
    update: updateGenerator(params),
}); };
