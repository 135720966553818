import { addYears } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createRentDeposit.types";
import { getNavigationTitle } from "./schema.createRentDeposit.utils";
var NOW = new Date();
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.StartDate, EField.Amount, EField.DueDate],
            properties: (_a = {},
                _a[EField.Header + "1"] = {
                    default: undefined,
                },
                _a[EField.Info] = {
                    default: undefined,
                },
                _a[EField.StartDate] = {
                    default: getValue(EField.StartDate, initialValues, defaultValues),
                },
                _a[EField.Amount] = {
                    default: getValue(EField.Amount, initialValues, defaultValues),
                },
                _a[EField.DueDate] = {
                    default: getValue(EField.DueDate, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("rent_deposit.info.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "1"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit.info.heading"),
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("rent_deposit.contract_info.heading"),
            getSubtitle: function (state, formData) {
                return getLocalizedText("rent_deposit.contract_info.content", {
                    startDate: formatDate(extraData.contract.startDate),
                    amount: "\u20AC ".concat(extraData.contract.currentPrice || 0),
                });
            },
            // TODO: Icon
            // icon: images.ICO_CHAT_SEND
        },
        _a[EField.StartDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) { return (input ? formatDate(input) : ""); },
            title: getLocalizedText("rent_deposit.start_date.label"),
            isDisabled: function () { return true; },
            // minDate: NOW,
            maxDate: addYears(NOW, 5),
        },
        _a[EField.Amount] = {
            "ui:field": ERentioFormField.Number,
            formatSummary: function (formData) { return formData && formatCurrency(formData); },
            title: getLocalizedText("rent_deposit.amount.label"),
            suffixText: "EUR",
            isFloat: true,
        },
        _a[EField.DueDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) { return (input ? formatDate(input) : ""); },
            title: getLocalizedText("rent_deposit.due_date.label"),
            minDate: NOW,
            maxDate: addYears(NOW, 5),
        },
        _a);
};
