var _a, _b, _c;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./document.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["DocumentCategories"] = "DOCUMENT_CATEGORIES";
    EActionCustom["UploadDocument"] = "UPLOAD_DOCUMENT";
})(EActionCustom || (EActionCustom = {}));
var generateDocumentCategoriesActions = function () { return ({
    documentCategoriesStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.DocumentCategories,
        state: EActionState.Start,
    }),
    documentCategoriesSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.DocumentCategories,
        state: EActionState.Success,
    }),
    documentCategoriesFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.DocumentCategories,
        state: EActionState.Fail,
    }),
}); };
var generateUploadDocumentActions = function () { return ({
    uploadDocumentStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UploadDocument,
        state: EActionState.Start,
    }),
    uploadDocumentSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UploadDocument,
        state: EActionState.Success,
    }),
    uploadDocumentFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UploadDocument,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getAllWhereStart = _a.getAllWhereStart, getAllWhereFailed = _a.getAllWhereFailed, getAllWhereSucceeded = _a.getAllWhereSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadFailed = _a.getInfiniteLoadFailed, getInfiniteLoadSucceeded = _a.getInfiniteLoadSucceeded, updateMembersFailed = _a.updateMembersFailed, updateMembersStart = _a.updateMembersStart, updateMembersSucceeded = _a.updateMembersSucceeded, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed, archiveStart = _a.archiveStart, archiveSucceeded = _a.archiveSucceeded, archiveFailed = _a.archiveFailed, unarchiveStart = _a.unarchiveStart, unarchiveSucceeded = _a.unarchiveSucceeded, unarchiveFailed = _a.unarchiveFailed;
export var documentCategoriesStart = (_b = generateDocumentCategoriesActions(), _b.documentCategoriesStart), documentCategoriesSucceeded = _b.documentCategoriesSucceeded, documentCategoriesFailed = _b.documentCategoriesFailed;
export var uploadDocumentStart = (_c = generateUploadDocumentActions(), _c.uploadDocumentStart), uploadDocumentSucceeded = _c.uploadDocumentSucceeded, uploadDocumentFailed = _c.uploadDocumentFailed;
