import Spacer, {
  ESpacerDirection,
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EPropertyForRentStatus } from "@rentiohq/shared/dist/types/property.types";
import {
  ResourceListItem,
  TextStyle,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { ForRentCell } from "../ForRentCell";
import {
  ESortOrder,
  OrderChangeFunction,
  SortableResourceListHeader,
} from "../SortableResourceListHeader";
import {
  ISortableResourceListHeaderProps,
  getNextOrder,
} from "../SortableResourceListHeader/SortableResourceListHeader";
import { WIDTH_ADDRESS, WIDTH_ICON } from "./PropertyListItem.constants";
import * as S from "./PropertyListItem.styles";

interface IPropertyListItemItem {
  children?: React.ReactNode;
  text?: string;
  tooltipContent?: React.ReactNode;
  sortable?: ISortableResourceListHeaderProps;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

export const PropertyListItemItem = (props: IPropertyListItemItem) => {
  const {
    text,
    tooltipContent,
    sortable,
    style = {},
    contentStyle = {},
    children,
    ...rest
  } = props;

  const renderContent = () => (
    <>
      {children}
      {(text || sortable) && (
        <div style={{ display: "flex", ...contentStyle }}>
          <div>
            {text && <TextStyle variation={"strong"}>{text}</TextStyle>}
          </div>
          {sortable && <SortableResourceListHeader {...sortable} />}
        </div>
      )}
    </>
  );

  return (
    <S.PropertyListItemItem
      style={style}
      {...rest}
      onClick={() => {
        if (sortable) {
          sortable.onOrderChange(getNextOrder(sortable.order));
        }
      }}
    >
      {tooltipContent ? (
        <Tooltip
          tooltipContent={tooltipContent}
          style={{
            width: "100%",
          }}
        >
          {renderContent()}
        </Tooltip>
      ) : (
        renderContent()
      )}
    </S.PropertyListItemItem>
  );
};

export const PropertyListItemSpacer = () => <S.PropertyListItemSpacer />;

export interface IPropertyListAddressSortProps {
  addressOrder: ESortOrder | undefined;
  addressOnChangeOrder: OrderChangeFunction;
}

export interface IProps extends IPropertyListAddressSortProps {
  alignment?: S.Alignment;
  children?: React.ReactNode;
}

const PropertyListItemHeader = ({
  alignment = "space-between",
  children,
  addressOrder,
  addressOnChangeOrder,
}: IProps) => {
  // Render
  return (
    <S.HeaderWrap>
      <ResourceListItem>
        <S.HeaderInnerWrap>
          <PropertyListItemItem style={{ width: WIDTH_ICON }} />

          <PropertyListItemItem
            text={getLocalizedText("system.address")}
            style={{ width: WIDTH_ADDRESS }}
            sortable={{
              order: addressOrder,
              onOrderChange: addressOnChangeOrder,
            }}
          />

          <ForRentCell forRentStatus={EPropertyForRentStatus.None} />

          <Spacer
            weight={ESpacerWeight.W10}
            direction={ESpacerDirection.Horizontal}
          />

          <S.ChildrenWrap alignment={alignment}>{children}</S.ChildrenWrap>
        </S.HeaderInnerWrap>
      </ResourceListItem>
    </S.HeaderWrap>
  );
};

export default PropertyListItemHeader;
