import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IDocumentCategory } from "@rentiohq/shared/dist/types/document.types";
import { sortBy } from "lodash";
import { EField, IExtraData } from "./schema.createDocument.types";

export const getInitialValues = ({ document, files }: IExtraData): IValues => {
  if (document) {
    return {
      [EField.Name]: document.filename,
      [EField.Category]: document.categoryId,
    };
  }

  if (files) {
    return {
      [EField.Name]: files[0].name,
    };
  }

  return {};
};

export const getSortedCategories = (
  categories: IDocumentCategory[],
): IDocumentCategory[] => {
  const translatedCategories = Object.values(categories).map(data => ({
    [getLocalizedText(`document.category.${data.label.toLowerCase()}`)]:
      data.id,
  }));

  return sortBy(translatedCategories, [
    category => Object.keys(category)[0],
  ]).map(category => ({
    label: Object.keys(category)[0],
    id: Object.values(category)[0],
  }));
};
