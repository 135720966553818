import * as actions from "./task.actions";
import * as api from "./task.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./task.constants";
import * as hooks from "./task.hooks";
import * as promises from "./task.promises";
import reducer from "./task.reducer";
import { saga } from "./task.saga";
import * as selectors from "./task.selectors";
import * as utils from "./task.utils";
export * from "./task.types";
export var REDUCER_KEY_TASK = REDUCER_KEY;
export var taskModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: utils,
};
