import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import ButtonPrimary, {
  EButtonSize,
} from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import Spacer, {
  ESpacerDirection,
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import * as cardActions from "@rentiohq/shared-frontend/dist/redux/card/card.actions";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as invitesActions from "@rentiohq/shared-frontend/dist/redux/invites/invites.actions";
import * as invitesSelectors from "@rentiohq/shared-frontend/dist/redux/invites/invites.selectors";
import { IInvite } from "@rentiohq/shared-frontend/dist/redux/invites/invites.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EEnvironment } from "@rentiohq/shared/dist/config/app.config.types";
import { Modal, TextStyle } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as S from "./InvitesModal.styles";
import useDocumentVisibility from "./hooks/useDocumentVisibility";

const InvitesModal = () => {
  let location = useLocation();

  // Redux
  const dispatch = useDispatch();
  const invites = useSelector((store: IRootStore) =>
    invitesSelectors.invites(store),
  );
  const previousInvites = usePrevious(invites);

  const isHidden = useDocumentVisibility();
  const wasHidden = usePrevious(isHidden);

  const { user } = authHooks.useSelf();
  const hasAccessToken = useSelector((store: IRootStore) =>
    authSelectors.hasAccessToken(store),
  );
  const previousUser = usePrevious(user);

  const { internalModeEnabled } = useInternalMode();

  const [preventModal, setPreventModal] = React.useState(false);

  // Data
  const fetchData = () => {
    dispatch(invitesActions.getInvites.actions.start({}));
  };

  // Lifecycle
  React.useEffect(() => {
    if (!user?.email) {
      return;
    }

    if (!hasAccessToken) {
      return;
    }

    if (isHidden) {
      return;
    }

    if (
      isHidden === wasHidden &&
      user.id === previousUser?.id &&
      user.email === previousUser?.email
    ) {
      return;
    }

    fetchData();
  }, [user, hasAccessToken, previousUser, isHidden]);

  React.useEffect(() => {
    if ((invites || []).length === 0 && (previousInvites || []).length !== 0) {
      dispatch(cardActions.getCards.actions.start({}));
    }
  }, [invites, previousInvites]);

  // Event handlers
  const handleClose = () => {
    setPreventModal(true);
  };

  const handlePressAcceptAll = () => {
    dispatch(invitesActions.acceptAllInvites.actions.start({}));
  };

  const handlePressAccept = (invite: IInvite) => () => {
    dispatch(invitesActions.acceptInvite.actions.start({ invite }));
  };

  const handlePressDecline = (invite: IInvite) => () => {
    confirm({
      title: getLocalizedText(`contact.button.decline.confirm_alert.title`),
      info: getLocalizedText(
        `contact.button.decline.confirm_alert.description`,
        {
          value: getName(invite.account),
        },
      ),
      primaryActions: [
        {
          title: getLocalizedText("contact.button.accept"),
          onPress: () => {
            dispatch(invitesActions.acceptInvite.actions.start({ invite }));
          },
        },
      ],
      secondaryAction: {
        title: getLocalizedText("contact.button.decline"),
        onPress: () => {
          dispatch(invitesActions.declineInvite.actions.start({ invite }));
        },
      },
    });
  };

  // Render
  if (!invites || invites.length === 0) {
    return null;
  }

  // Don't show if internal mode is on
  if (internalModeEnabled) {
    return null;
  }

  // Never show on rentio internal screen
  if (location.pathname.includes("/rentio-internal")) {
    return null;
  }

  // Never show on authentication screens
  if (location.pathname.startsWith("/auth/")) {
    return null;
  }

  // Don't show if hidden
  if (preventModal) {
    return null;
  }

  return (
    <Modal
      onClose={handleClose}
      hasDismiss={[
        EEnvironment.Dev,
        EEnvironment.Stag,
        EEnvironment.Demo,
      ].includes(CONFIG.ENVIRONMENT)}
      heading={getLocalizedText("open_invites.title")}
    >
      <S.Wrap>
        <TextStyle variation={"subdued"} size={"medium"}>
          {getLocalizedText("open_invites.body")}
        </TextStyle>

        {invites.length > 1 && (
          <>
            <Spacer weight={ESpacerWeight.W08} />

            <ButtonBasic
              title={getLocalizedText("contact.button.accept_all")}
              onPress={handlePressAcceptAll}
            />
          </>
        )}

        <Spacer weight={ESpacerWeight.W16} />

        <S.InviteCardsWrap>
          {invites.map((invite: IInvite, index: number) => (
            <S.InviteCardOuterWrap
              key={invite.account.id}
              isLeft={index % 2 === 0}
            >
              <S.InviteCardWrap>
                <S.InviteCardTitle>{getName(invite.account)}</S.InviteCardTitle>
                <S.InviteCardInfo>
                  {getLocalizedText("open_invites.invite_info", {
                    value: getName(invite.account),
                  })}
                </S.InviteCardInfo>
                <S.InviteCardButtons>
                  <S.InviteCardButtonWrap>
                    <ButtonPrimary
                      buttonSize={EButtonSize.Full}
                      title={getLocalizedText("contact.button.decline")}
                      isInverted={true}
                      tintColor={"gray"}
                      style={{ borderColor: "gray" }}
                      onPress={handlePressDecline(invite)}
                    />
                  </S.InviteCardButtonWrap>
                  <Spacer
                    weight={ESpacerWeight.W16}
                    direction={ESpacerDirection.Horizontal}
                  />
                  <S.InviteCardButtonWrap>
                    <ButtonPrimary
                      buttonSize={EButtonSize.Full}
                      title={getLocalizedText("contact.button.accept")}
                      onPress={handlePressAccept(invite)}
                    />
                  </S.InviteCardButtonWrap>
                </S.InviteCardButtons>
              </S.InviteCardWrap>
            </S.InviteCardOuterWrap>
          ))}
        </S.InviteCardsWrap>
      </S.Wrap>
    </Modal>
  );
};

export default InvitesModal;
