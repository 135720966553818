var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appendFilter } from "@rentiohq/shared/dist/utils/api.utils";
import api from "../../utils/api/api.utils";
var FETCH_LIMIT = 20;
export var getPagedGenerator = function (paramsGenerator) {
    var resource = paramsGenerator.resource, defaultInclude = paramsGenerator.defaultInclude;
    return function (params) {
        var customResource = params.customResource, order = params.order, skip = params.skip, _a = params.limit, limit = _a === void 0 ? FETCH_LIMIT : _a, filter = params.filter;
        var filterFinal = __assign({ order: order || ["createdAt DESC"], skip: skip, limit: limit, include: defaultInclude }, filter);
        return api.get(appendFilter("/".concat(customResource || resource), filterFinal));
    };
};
