var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getIcon } from "../../utils/properties";
import Button, { ButtonActions } from "../Button";
import DropdownMenu from "../DropdownMenu";
import { ESpacings } from "../Grid";
import Icon from "../Icon";
import * as S from "./ActionCard.styled";
var Item = function (_a) {
    var icon = _a.icon, appearance = _a.appearance, children = _a.children;
    return (_jsxs(Flex, __assign({ mt: ESpacings.tight, alignItems: "center" }, { children: [_jsx(S.ItemIcon, __assign({ appearance: appearance || "default" }, { children: icon })), _jsx(Box, { children: children })] })));
};
var ActionCard = function (props) {
    var _a = props.appearance, appearance = _a === void 0 ? "default" : _a, isDetailView = props.isDetailView, heading = props.heading, subheading = props.subheading, actions = props.actions, _b = props.primaryActions, primaryActions = _b === void 0 ? [] : _b, reason = props.reason, property = props.property, children = props.children;
    return (_jsxs(S.Wrapper, __assign({ appearance: appearance }, { children: [_jsxs(S.Header, __assign({ appearance: appearance }, { children: [_jsxs(S.Heading, { children: [_jsx(Box, __assign({ flex: 1, mr: ESpacings.tight }, { children: heading })), actions && (_jsx(S.Actions, { children: _jsx(DropdownMenu, { children: _jsx(S.ActionTrigger, { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: actions }) }))] }), subheading && (_jsx(Box, __assign({ mt: ESpacings.tight }, { children: _jsx(S.Subheading, { children: subheading }) })))] })), _jsxs(S.Content, { children: [children, reason && (_jsx(Item, __assign({ appearance: appearance, icon: _jsx(Icon, { source: "alertDiamond" }) }, { children: reason }))), primaryActions.map(function (primaryAction, index) { return (_jsx(Box, __assign({ mt: ESpacings.base }, { children: _jsx(ButtonActions, { actions: [primaryAction], options: { overrides: { isFullWidth: true } } }) }), "index")); })] }), property && !isDetailView && (_jsxs(S.Property, __assign({ as: Flex }, { children: [!!property.typeId && (_jsx(Box, __assign({ mr: ESpacings.tight }, { children: _jsx(Icon, { source: getIcon(property.typeId), size: "small" }) }))), _jsx(S.PropertyContent, { children: _jsxs(Button, __assign({ appearance: "reset", url: "/properties/".concat(property.id, "/payments") }, { children: [formatAddress(property), " (", property.name, ")"] })) })] })))] })));
};
export default ActionCard;
