import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EPropertyAdjoined,
  EPropertyTypeId,
} from "@rentiohq/shared/dist/types/property.types";
import { JSONSchema6 } from "json-schema";
import { FormValidation, UiSchema } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.requestInsurance.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    const isHouse = extraData.property.typeId === EPropertyTypeId.House;
    const isApartment = extraData.property.typeId === EPropertyTypeId.Apartment;

    return {
      required: [EField.ContractRentPrice, EField.BuildingHasHardMaterials],
      properties: {
        [EField.Info]: {
          default: undefined,
        },

        [EField.ContractRentPrice]: {
          default: getValue(
            EField.ContractRentPrice,
            initialValues,
            defaultValues,
          ),
        },

        [EField.BuildingSurfaceArea]: {
          default: getValue(
            EField.BuildingSurfaceArea,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => isApartment,
          requiredIf: () => isApartment,
        },

        // [EField.BuildingFloor]: {
        //   default: getValue(EField.BuildingFloor, initialValues, defaultValues),
        //   // @ts-ignore
        //   showIf: () => isApartment,
        // },

        [EField.BuildingLayers]: {
          default: getValue(
            EField.BuildingLayers,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => isApartment,
        },

        [EField.BuildingAdjoined]: {
          default: getValue(
            EField.BuildingAdjoined,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => isHouse,
          // @ts-ignore
          options: [
            EPropertyAdjoined.Freestanding,
            EPropertyAdjoined.OneSide,
            EPropertyAdjoined.BothSides,
            // EPropertyAdjoined.Unknown,
            // EPropertyAdjoined.NotApplicable,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `property.adjoined.option.${id}.label`.toLowerCase(),
            ),
          })),
        },

        [EField.BuildingHasHardMaterials]: {
          default: getValue(
            EField.BuildingHasHardMaterials,
            initialValues,
            defaultValues,
          ),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): UiSchema => ({
  [EField.Info]: {
    "ui:field": ERentioFormField.HelpInfo,
    title: formatAddress(extraData.property),
    subtitle: extraData.property.name,
  },

  [EField.BuildingSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "form.request_insurance.field.building_surface_area",
    ),
    placeholder: "0",
    suffixText: "m²",
    isFloat: true,
  },

  [EField.ContractRentPrice]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("form.request_insurance.field.contract_rent_price"),
    placeholder: "0",
    suffixText: "EUR",
    isFloat: true,
    readOnly: true,
  },

  // [EField.BuildingFloor]: {
  //   "ui:field": ERentioFormField.Number,
  //   title: getLocalizedText("form.request_insurance.field.building_floor"),
  //   placeholder: "0",
  // },

  [EField.BuildingLayers]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("form.request_insurance.field.building_layers"),
    placeholder: "0",
    optional: true,
  },

  [EField.BuildingAdjoined]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("form.request_insurance.field.building_adjoined"),
    optional: true,
  },

  [EField.BuildingHasHardMaterials]: {
    "ui:field": ERentioFormField.Switch,
    label: getLocalizedText(
      "form.request_insurance.field.building_has_hard_materials",
    ),
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    if (!formData[EField.BuildingHasHardMaterials]) {
      errors[EField.BuildingHasHardMaterials].addError(
        getLocalizedText(
          `form.request_insurance.field.building_has_hard_materials.error.required`,
        ),
      );
    }

    return errors;
  };
