var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ERecurrenceType } from "@rentiohq/shared/dist/types/task.types";
import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.createTask.1";
import { getSchema as getSchema2, uiSchema as uiSchema2, } from "./schema.createTask.2";
import { getSchema as getSchema3, getValidate as getValidate3, uiSchema as uiSchema3, } from "./schema.createTask.3";
import { getSchema as getSchema4, uiSchema as uiSchema4, } from "./schema.createTask.4";
import { getSchema as getSchema5, uiSchema as uiSchema5, } from "./schema.createTask.5";
import { getInitialValues } from "./schema.createTask.utils";
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return __spreadArray(__spreadArray(__spreadArray([
        {
            schema: getSchema1(extraData, initialValues),
            uiSchema: uiSchema1(extraData, initialValues),
        }
    ], (extraData.taskId
        ? []
        : [
            {
                schema: getSchema2(extraData, initialValues),
                uiSchema: uiSchema2(extraData, initialValues),
            },
        ]), true), (extraData.keypoint
        ? [
            {
                schema: getSchema5(extraData, initialValues),
                uiSchema: uiSchema5(extraData, initialValues),
            },
        ]
        : []), true), [
        {
            schema: getSchema3(extraData, initialValues),
            uiSchema: uiSchema3(extraData, initialValues),
            validate: getValidate3(),
        },
        {
            schema: getSchema4(extraData, initialValues),
            uiSchema: uiSchema4(extraData, initialValues),
            showIf: function (formData) {
                return formData.recurrenceSelection !== ERecurrenceType.Once;
            },
        },
    ], false);
});
