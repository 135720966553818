import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IMoveRequest } from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";
import moveRequestUpdateSchemas from "forms/moveRequestUpdate";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "forms/moveRequestUpdate/schema.moveRequestUpdate.actions";
import { EStep } from "forms/moveRequestUpdate/schema.moveRequestUpdate.types";
import React from "react";

interface IProps {
  step: EStep;
  moveRequest: IMoveRequest;
  property: IProperty;
  onClose: () => void;
}

const MoveRequestUpdateModal: React.FC<IProps> = props => {
  const { step, moveRequest, property, onClose } = props;

  // State
  const [formId] = React.useState(generateFormId());

  // Render
  return (
    <MultiStepForm
      formId={`move_request_update-${formId}`}
      preventSubmitThroughEnter={true}
      schemas={moveRequestUpdateSchemas({
        step,
        moveRequest,
        property,
      })}
      renderModalFooter={
        step === EStep.WaterHandover
          ? () => <div style={{ minHeight: "180px" }} />
          : undefined
      }
      asModal={true}
      withAside={false}
      modalProps={{
        onClose,
        heading: getLocalizedText(
          `move_request.update.modal.${stringToSnakeCase(step)}.title`,
        ),
        width: "medium",
      }}
      performingSubmitActionResultSelector={performingSubmitActionResultSelector(
        moveRequest.id,
      )}
      isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
        moveRequest.id,
      )}
      performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
        moveRequest.id,
      )}
      submitActionCreator={submitActionCreator({
        step,
        moveRequest,
        property,
      })}
      onSuccess={(moveRequest?: IMoveRequest) => {
        onClose();
      }}
      submitLabel={getLocalizedText("system.update")}
    />
  );
};

export default MoveRequestUpdateModal;
