import styled, { spacing } from "@rentiohq/web-theme";

export const DropArea = styled.div`
  &:not(:last-child) {
    margin-bottom: ${spacing("loose")};
  }
`;

export const PhaseHeader = styled.h1`
  margin-bottom: ${spacing("loose")};
  margin-top: ${spacing("loose")};
`;
