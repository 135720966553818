import * as actions from "./payin.actions";
import * as api from "./payin.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./payin.constants";
import * as hooks from "./payin.hooks";
import * as promises from "./payin.promises";
import reducer from "./payin.reducer";
import { saga } from "./payin.saga";
import * as selectors from "./payin.selectors";
import * as utils from "./payin.utils";
export var REDUCER_KEY_PAY_IN = REDUCER_KEY;
export var payInModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: utils,
};
