import { createPaymentOrder, updatePaymentOrder, } from "../../redux/payment/payment.actions";
import { getCommissionPaymentOrder, getCommonCostsPaymentOrder, getManagementFeePaymentOrder, getNonCommonCostsPaymentOrder, getPaymentOrder, } from "./schema.createPayment.actions.utils";
import { getRelatedContract } from "./schema.createPayment.utils";
export var isPerformingSubmitActionSelector = function (paymentOrder) { return function (state) {
    if (paymentOrder) {
        var updateState = state.payment.updatePaymentOrders[paymentOrder.id];
        if (updateState) {
            return updateState.isUpdatingPaymentOrder;
        }
        return false;
    }
    return !!state.payment.isCreatingPaymentOrder;
}; };
export var performingSubmitActionErrorSelector = function (paymentOrder) { return function (state) {
    if (paymentOrder) {
        var updateState = state.payment.updatePaymentOrders[paymentOrder.id];
        if (updateState) {
            return updateState.fetchPaymentOrdersError;
        }
        return;
    }
    return state.payment.createPaymentOrderError;
}; };
export var submitActionCreator = function (extraData, propertyId, paymentOrderId, paymentOrder) {
    return function (formData) {
        var contract = getRelatedContract(extraData, formData);
        var contractId = contract === null || contract === void 0 ? void 0 : contract.id;
        if (paymentOrderId) {
            var updatedPaymentOrder = getPaymentOrder(formData, propertyId, extraData, paymentOrder);
            return updatePaymentOrder.actions.start({
                paymentOrderId: paymentOrderId,
                paymentOrder: updatedPaymentOrder,
            });
        }
        var paymentOrders = [];
        var mainPaymentOrder = getPaymentOrder(formData, propertyId, extraData);
        paymentOrders.push(mainPaymentOrder);
        var commonCostsPaymentOrder = getCommonCostsPaymentOrder(formData, propertyId, contractId, contract);
        if (commonCostsPaymentOrder) {
            paymentOrders.push(commonCostsPaymentOrder);
        }
        var nonCommonCostsPaymentOrder = getNonCommonCostsPaymentOrder(formData, propertyId, contractId, contract);
        if (nonCommonCostsPaymentOrder) {
            paymentOrders.push(nonCommonCostsPaymentOrder);
        }
        var commissionPaymentOrder = getCommissionPaymentOrder(formData, propertyId, contractId, contract);
        if (commissionPaymentOrder) {
            paymentOrders.push(commissionPaymentOrder);
        }
        var managementFeePaymentOrder = getManagementFeePaymentOrder(formData, propertyId, contractId, contract);
        if (managementFeePaymentOrder) {
            paymentOrders.push(managementFeePaymentOrder);
        }
        return createPaymentOrder.actions.start(paymentOrders);
    };
};
