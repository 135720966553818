import { Box, Flex } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { canHaveRentioLegalForProperty } from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import {
  formatDate,
  getTerm,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  capitalizeString,
  join,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";
import {
  EContractMemberTypes,
  EContractType,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import { isAfter } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { getMembersWithRole } from "@rentiohq/shared/dist/utils/roles.utils";
import {
  DisplayText,
  DropdownMenu,
  ESpacings,
  Icon,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { TDropdownActions } from "@rentiohq/web-shared/dist/components/DropdownMenu/DropdownMenu";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { spacing } from "@rentiohq/web-theme";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import RenewContractModal from "scenes/FollowUp/EndingContracts/components/RenewContractModal";
import {
  getContractCost,
  getContractEditRoute,
  renderContractNames,
} from "utils/contract";
import { rs, ts } from "../../../../../../../services";
import * as S from "../ContractCard.styled";
import { EActionStatus, IChecklistItem } from "./ChecklistItem";
import { ContractStatusChips } from "./ContractStatusChips";
import { ProgressBar } from "./ProgressBar";

interface IProps {
  contract: IContract;
  property: IProperty;
  showContractType: boolean;
  checklistItems: (IChecklistItem | undefined)[];
  setShowCancelContractModal: (show: boolean) => void;
}

const NOW = new Date();

export const ContractMetaData = ({
  contract,
  property,
  showContractType,
  checklistItems,
  setShowCancelContractModal,
}: IProps) => {
  const navigate = useNavigate();
  const [showRenewContractModal, setShowRenewContractModal] =
    useState<boolean>(false);
  const { user } = authHooks.useSelf();

  const hasMoveServiceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.MoveService,
  );
  const { remove: removeContract } = contractHooks.useRemove({
    id: contract.id,
  });
  const { update } = contractHooks.useUpdate({
    id: contract.id,
  });

  const { renewalInfo, contractCost, heading } = useMemo(() => {
    const renewalInfo = contractUtils.getRenewalInfo(contract);
    const isActive = !isAfter(contract.startDate, NOW);
    const contractCost = getContractCost(contract);

    let heading = utils.contract.getDisplayName(contract);
    if (!showContractType) {
      if (isActive) {
        heading = ts.propertyDashboardActiveContractHeading();
      } else {
        heading = ts.propertyDashboardUpcomingContractHeading();
      }
    }
    return {
      renewalInfo,
      contractCost,
      heading,
    };
  }, [contract, showContractType]);

  const handlePressRequestMoveContractForEmptyProperty = useCallback(() => {
    if (!hasMoveServiceForBroker) {
      return;
    }

    const tenantNames = join(
      getMembersWithRole(contract.members, EContractMemberTypes.Tenant).map(x =>
        getName(x.account),
      ),
    );
    const ownerNames = join(
      getMembersWithRole(property.members, EPropertyMemberTypes.Owner).map(x =>
        getName(x.account),
      ),
    );

    confirm({
      title: capitalizeString(
        getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.confirm.title",
        ),
      ),
      info: capitalizeString(
        getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.confirm.info",
          {
            email: user?.email || "?",
          },
        ),
      ),
      inputValueProps: {
        type: "string",
        asMultiline: true,
        label: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.remarks.label",
        ),
        initialValue: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.remarks.initial_value",
          {
            tenantName: tenantNames,
            tenantAddress: formatAddress(property),
            tenantVat: "",
            tenantDateOfBirth: "",

            ownerName: ownerNames,
          },
        ),
        optional: true,
      },
      primaryActions: [
        {
          title: getLocalizedText("system.request"),
          onPress: async ({ inputValue } = {}) => {
            try {
              await api.post(
                `/properties/${contract.propertyId}/request-move-request-contract`,
                {
                  data: {
                    extraRemarks: inputValue,
                  },
                },
              );

              showAlert({
                type: "success",
                message: getLocalizedText(
                  "contract.move.request_move_contract_for_empty_property.alert.success",
                ),
              });
            } catch (unknownError) {
              const error = unknownError as any;
              showAlert({
                type: "error",
                error,
                message: getLocalizedText(
                  "contract.move.request_move_contract_for_empty_property.alert.failure",
                ),
              });
            }
          },
        },
      ],
    });
  }, [hasMoveServiceForBroker, user, contract]);

  const getActions = (): TDropdownActions => {
    const isCancelled = !!contract.dateOfCancellation;

    const allActions: TDropdownActions = [];

    // Main actions: edit, renew & cancel
    const mainActions: IAction[] = [
      {
        content: ts.contractEdit(),
        url: getContractEditRoute(contract, property.id),
      },
    ];

    if (!isCancelled && contract.stopDate) {
      mainActions.push({
        content: ts.contractRenew(),
        onClick: () => {
          setShowRenewContractModal(true);
        },
      });
    }

    mainActions.push({
      content: isCancelled
        ? ts.contractShowCancellation()
        : ts.contractCancel(),
      onClick: () => {
        isCancelled
          ? navigate(
              `/properties/${property.id}/contracts?cancelledContractId=${contract.id}`,
            )
          : setShowCancelContractModal(true);
      },
    });

    allActions.push(mainActions);

    // Section two actions
    if (contract.tacitRenewal) {
      allActions.push([
        {
          content: (
            <TextStyle variation={"warn"}>
              {ts.contractTurnOffTacitRenewal()}
            </TextStyle>
          ),
          onClick: () => {
            update({
              data: {
                tacitRenewal: false,
              },
            });
          },
        },
      ]);
    }

    // Rent deposit
    if (contract.contractType === EContractType.Basic) {
      allActions.push({
        content: getLocalizedText("contract.open_rent_deposit.action"),
        url: rs.createRentDepositRoute(property.id, contract.id),
      });
    }

    // Move service empty contract
    if (hasMoveServiceForBroker) {
      allActions.push({
        content: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.cta.title",
        ),
        onClick: handlePressRequestMoveContractForEmptyProperty,
      });
    }

    // Delete contract
    allActions.push({
      content: (
        <TextStyle variation="negative">
          {getLocalizedText("contract.delete.cta")}
        </TextStyle>
      ),
      onClick: () => {
        confirm({
          title: capitalizeString(
            getLocalizedText("contract.delete.confirm.title"),
          ),
          info: capitalizeString(
            getLocalizedText("contract.delete.confirm.info"),
          ),
          primaryActions: [
            {
              title: getLocalizedText("system.delete"),
              onPress: () => {
                removeContract({});
              },
            },
          ],
        });
      },
    });

    return allActions;
  };

  let checklistTotal = 0;
  let checklistPending = 0;
  let checklistCompleted = 0;
  checklistItems.forEach(message => {
    if (!message) {
      return;
    }

    checklistTotal += 1;

    switch (message.actionStatus) {
      case EActionStatus.PendingRentio:
        checklistPending += 1;
        break;

      case EActionStatus.CompletedInRentio:
      case EActionStatus.CompletedManually:
        checklistCompleted += 1;
        break;

      default:
        break;
    }
  });

  return (
    <Flex justifyContent="space-between" flexWrap="nowrap">
      <Box>
        <Box>
          <Flex alignItems="center" justifyContent="flex-start">
            <DisplayText size="medium">{heading}</DisplayText>
            <ContractStatusChips
              contract={contract}
              boxProps={{
                paddingTop: "2px",
                marginBottom: spacing("base"),
                marginLeft: spacing("base"),
              }}
            />
          </Flex>
        </Box>
        <Box color="neutral60">
          <Flex alignItems="center" mb={ESpacings.tight}>
            <Icon size="small" source="calendar" />
            <Box flex="1" ml={ESpacings.tight}>
              {getTerm(contract)}
            </Box>
          </Flex>

          {renewalInfo && (
            <Flex alignItems="center" mb={ESpacings.tight}>
              <Icon size="small" source="calendar" />
              <Box flex="1" ml={ESpacings.tight}>
                {renewalInfo}
              </Box>
            </Flex>
          )}

          {canHaveRentioLegalForProperty({ property }) &&
            contract.legalLicenseActivatedAt && (
              <Flex alignItems="center" mb={ESpacings.tight}>
                <Icon size="small" source="legalHammer" />
                <Box flex="1" ml={ESpacings.tight}>
                  {getLocalizedText("rentio_legal.status.activated_on", {
                    activation_date: formatDate(
                      contract.legalLicenseActivatedAt,
                    ),
                  })}
                </Box>
              </Flex>
            )}

          <Flex alignItems="center" mb={ESpacings.tight}>
            <Icon size="small" source="contact" />
            <Box flex="1" ml={ESpacings.tight}>
              {renderContractNames({
                contract,
                withAdditionalContactMetaData: true,
                vertical: true,
              })}
            </Box>
          </Flex>

          <Flex alignItems="center">
            <Icon size="small" source="tagEuro" />
            <Box flex="1" ml={ESpacings.tight}>
              {contractCost}
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
          style={{ height: "100%" }}
        >
          <DropdownMenu
            children={
              <div>
                <Icon source="navigationMenuVertical" />
              </div>
            }
            actions={getActions()}
          />

          <S.ChecklistProgressWrap>
            <ProgressBar
              completed={checklistCompleted}
              pending={checklistPending}
              total={checklistTotal}
            />

            <TextStyle variation="subdued">
              {getLocalizedText("contract.checklist.completed_count", {
                checklistCompleted: `${checklistCompleted}`,
                checklistTotal: `${checklistTotal}`,
              })}
            </TextStyle>
          </S.ChecklistProgressWrap>
        </Flex>
      </Box>
      {showRenewContractModal && contract.stopDate && (
        <RenewContractModal
          contractId={contract.id}
          onClose={() => setShowRenewContractModal(false)}
          onSuccess={() => {
            setShowRenewContractModal(false);
          }}
        />
      )}
    </Flex>
  );
};
