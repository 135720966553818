import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ERegistrationContractType } from "@rentiohq/shared/dist/types/registration.types";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    const contractTypes = Object.values(ERegistrationContractType).filter(
      contractType => contractType !== ERegistrationContractType.Lease,
    );

    return {
      required: [EField.Type, EField.SignedAt],
      properties: {
        title1: {
          default: undefined,
        },
        [EField.Type]: {
          default: getValue(EField.Type, initialValues, defaultValues),
          // @ts-ignore
          options: contractTypes.map(id => ({
            id,
            value: getLocalizedText(
              `registration.contract_type.${id}`.toLowerCase(),
            ),
          })),
        },
        [EField.SignedAt]: {
          default: getValue(EField.SignedAt, initialValues, defaultValues),
        },
        [EField.SignedAt]: {
          default: getValue(EField.SignedAt, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (_: IExtraData) => {
  return {
    "rentio:title": utils.i18n.t(
      "registration_addendum.create.form.step.addendum_details",
    ),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t("registration.create.form.field.contract_meta_title"),
    },
    [EField.Type]: {
      "ui:field": ERentioFormField.DropdownSelect,
      title: getLocalizedText("registration.create.form.field.contract_type"),
      formatSummary: (formData: ERegistrationContractType) =>
        getLocalizedText(
          `registration.contract_type.${formData}`.toLowerCase(),
        ),
    },
    [EField.SignedAt]: {
      "ui:field": ERentioFormField.Date,
      title: getLocalizedText(
        "registration.create.form.field.contract_signed_at",
      ),
      maxDate: new Date(),
      formatSummary: (formData: Date) => formData && formatDate(formData),
    },
  };
};
