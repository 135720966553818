import { Route } from "react-router-dom";
import { Chats } from "./Chats";
import { CreateNewChat } from "./scenes/CreateNewChat";
import { EditChat } from "./scenes/EditChat";
import { InfoChat } from "./scenes/InfoChat";

export const getChatsScene = () => (
  <Route path="chats">
    <Route path=":chatId">
      <Route path="info" element={<InfoChat />} />

      <Route path="edit" element={<EditChat />} />

      <Route index element={<Chats />} />
    </Route>

    <Route path="add" element={<CreateNewChat />} />

    <Route index element={<Chats />} />
  </Route>
);
