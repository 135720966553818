import { getSchema as getSchema1, getValidate as getValidate1, uiSchema as uiSchema1, } from "./schema.indexContract.1";
import { getSchema as getSchema2, uiSchema as uiSchema2, } from "./schema.indexContract.2";
import { getInitialValues } from "./schema.indexContract.utils";
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(extraData, initialValues),
            uiSchema: uiSchema1(extraData, initialValues),
            validate: getValidate1(extraData),
        },
        {
            schema: getSchema2(extraData, initialValues),
            uiSchema: uiSchema2(extraData, initialValues),
        },
    ];
});
