var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Error } from "../..";
import { Card, DisplayText, ESpacings, Grid, Icon, Loading, TextStyle, } from "../../";
import utils from "../../../utils";
export var PropertyCard = function (props) {
    var passedProperty = props.property, propertyId = props.propertyId, title = props.title, detailCtaTitle = props.detailCtaTitle;
    var _a = propertyHooks.useDetail({
        id: propertyId,
    }), propertyFromStore = _a.detail, isFetching = _a.isFetching, fetchError = _a.fetchError;
    // Render
    var property = propertyFromStore || passedProperty;
    return (_jsxs(Card, __assign({ heading: _jsx(DisplayText, __assign({ size: "extraSmall", space: "tight" }, { children: title || getLocalizedText("system.model.property") })), space: ESpacings.loose, actions: property
            ? [
                {
                    content: detailCtaTitle ||
                        getLocalizedText("property.view_property.action"),
                    url: "/properties/".concat(property.id),
                },
            ]
            : undefined }, { children: [!property && isFetching && _jsx(Loading, {}), !property && fetchError && _jsx(Error, { errors: [fetchError] }), property && (_jsx(_Fragment, { children: _jsxs(Grid, __assign({ alignItems: "center" }, { children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: utils.properties.getIcon(property.typeId || 0), size: "large" }) }), _jsxs(Grid.Item, { children: [_jsx("div", { children: _jsx(TextStyle, { children: formatAddress(property) }) }), _jsx("div", { children: _jsx(TextStyle, __assign({ variation: "subdued" }, { children: property.name })) })] })] })) }))] })));
};
export default PropertyCard;
