import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { ITemplate } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { ITemplateDocument } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import { IBankAccount } from "@rentiohq/shared-frontend/dist/reduxV2/bankAccount";
import { IIndexInfoSimulation } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.api";
import { getIndexInfoAvailable } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { PROPERTY_TYPES } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatIban } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import { IBroker } from "@rentiohq/shared/dist/types/broker.types";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  EContractCostsPriceType,
  EContractIbanisationStatus,
  EContractIndexationStatus,
  EContractMemberTypes,
  EEpcBasedIndexationType,
  IContract,
  IContractIndexInfo,
} from "@rentiohq/shared/dist/types/contract.types";
import { IPaymentOrder } from "@rentiohq/shared/dist/types/payment.types";
import {
  EPropertyEPCLabel,
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import {
  addDays,
  differenceInDays,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { getAmountWithVat } from "@rentiohq/shared/dist/utils/number.utils";
import {
  getMembersWithOneOfRoles,
  getMembersWithRole,
} from "@rentiohq/shared/dist/utils/roles.utils";
import { IPreferences } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { EDefaultVariableId } from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.config";
import {
  IDocumentConfig,
  IFields,
} from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.types";
import { floor, round } from "lodash";

export const getDefaultFilename = (params: {
  type: "contract" | string;
  template?: ITemplate;
  templateDocument?: ITemplateDocument;
  property: IProperty;
}) => {
  const { type, template, templateDocument, property } = params;

  if (type === "contract") {
    return getLocalizedText(
      "create_template_document.modal.name_template.default_filename_contract",
      {
        address: formatAddress(property),
      },
    );
  }

  return template?.name || templateDocument?.name || "File";
};

export const getDefaultDocumentConfig = (params: { brandColor?: string }) => {
  const { brandColor } = params;

  const result: IDocumentConfig = {
    brandColor,
  };

  return JSON.stringify(result);
};

export const getDefaultVariablesData = (params: {
  legalContact?: IContact;
  meMaster?: IContact;
  broker?: IBroker;
  brokerLogoDocumentId?: string;
  property: IProperty;
  contract?: IContract;
  indexInfo?: IContractIndexInfo;
  indexInfoSimulation?: IIndexInfoSimulation;
  indexInfoRequest?: {
    newPrice: number;
    isRetroactive: boolean;
    forceFullIndexation: boolean;
  };
  contacts: IContact[];
  fields: IFields;
  preferences: IPreferences;
  rentPaymentOrder?: IPaymentOrder;

  ibanisationBankAccount?: IBankAccount;
  variablesDataToOverride?: Partial<
    Record<
      EDefaultVariableId,
      (string | number | boolean | Date | undefined | null)[]
    >
  >;
}) => {
  const {
    legalContact,
    meMaster,
    broker,
    brokerLogoDocumentId,
    property,
    contract,
    indexInfo,
    indexInfoSimulation,
    indexInfoRequest,
    contacts,
    fields,
    preferences,
    rentPaymentOrder,
    ibanisationBankAccount,
    variablesDataToOverride,
  } = params;
  const indexInfoAvailable = getIndexInfoAvailable(indexInfo);

  const result: Record<
    EDefaultVariableId,
    (string | number | boolean | Date | undefined | null)[]
  > = {
    [EDefaultVariableId.Now]: [new Date()],
    [EDefaultVariableId.RentDepositPrice]: [],
    [EDefaultVariableId.RentDepositMonths]: [],
    [EDefaultVariableId.RentDepositWithRentio]: [],
    [EDefaultVariableId.RentDepositBankAccountNumber]: [],
    [EDefaultVariableId.LocationDescriptionPerson]: [],
    [EDefaultVariableId.BrokerEnabled]: [],
    [EDefaultVariableId.BrokerBiv]: [],
    [EDefaultVariableId.BrokerOfficeName]: [],
    [EDefaultVariableId.BrokerFullName]: [],
    [EDefaultVariableId.BrokerFirstName]: [],
    [EDefaultVariableId.BrokerLastName]: [],
    [EDefaultVariableId.BrokerBirthPlace]: [],
    [EDefaultVariableId.BrokerBirthDate]: [],
    [EDefaultVariableId.BrokerAddressFull]: [],
    [EDefaultVariableId.BrokerAddressStreet]: [],
    [EDefaultVariableId.BrokerAddressNumber]: [],
    [EDefaultVariableId.BrokerAddressBox]: [],
    [EDefaultVariableId.BrokerAddressZip]: [],
    [EDefaultVariableId.BrokerAddressCity]: [],
    [EDefaultVariableId.BrokerAddressCountry]: [],
    [EDefaultVariableId.BrokerLogo]: [],
    [EDefaultVariableId.BrokerIsSignerOnBehalfOfOwners]: [],
    [EDefaultVariableId.BrokerSignature]: [],
    [EDefaultVariableId.BrokerCivilLiabilityAtCompany]: [],
    [EDefaultVariableId.BrokerCivilLiabilityPolicyNumber]: [],
    [EDefaultVariableId.OwnerIsCompany]: [],
    [EDefaultVariableId.OwnerFullName]: [],
    [EDefaultVariableId.OwnerFirstName]: [],
    [EDefaultVariableId.OwnerLastName]: [],
    [EDefaultVariableId.OwnerBirthPlace]: [],
    [EDefaultVariableId.OwnerBirthDate]: [],
    [EDefaultVariableId.OwnerCompanyName]: [],
    [EDefaultVariableId.OwnerCompanyVat]: [],
    [EDefaultVariableId.OwnerCompanyRole]: [],
    [EDefaultVariableId.OwnerAddressFull]: [],
    [EDefaultVariableId.OwnerAddressStreet]: [],
    [EDefaultVariableId.OwnerAddressNumber]: [],
    [EDefaultVariableId.OwnerAddressBox]: [],
    [EDefaultVariableId.OwnerAddressZip]: [],
    [EDefaultVariableId.OwnerAddressCity]: [],
    [EDefaultVariableId.OwnerAddressCountry]: [],
    [EDefaultVariableId.OwnerCompanyAddressFull]: [],
    [EDefaultVariableId.OwnerSignature]: [],
    [EDefaultVariableId.OwnerEmail]: [],
    [EDefaultVariableId.OwnerPhone]: [],
    [EDefaultVariableId.OwnerNationality]: [],
    [EDefaultVariableId.OwnerNationalRegisterNumber]: [],
    [EDefaultVariableId.OwnerMaritalStatus]: [],
    [EDefaultVariableId.TenantIsCompany]: [],
    [EDefaultVariableId.TenantFullName]: [],
    [EDefaultVariableId.TenantFirstName]: [],
    [EDefaultVariableId.TenantLastName]: [],
    [EDefaultVariableId.TenantBirthPlace]: [],
    [EDefaultVariableId.TenantBirthDate]: [],
    [EDefaultVariableId.TenantCompanyName]: [],
    [EDefaultVariableId.TenantCompanyVat]: [],
    [EDefaultVariableId.TenantCompanyRole]: [],
    [EDefaultVariableId.TenantAddressFull]: [],
    [EDefaultVariableId.TenantAddressStreet]: [],
    [EDefaultVariableId.TenantAddressNumber]: [],
    [EDefaultVariableId.TenantAddressBox]: [],
    [EDefaultVariableId.TenantAddressZip]: [],
    [EDefaultVariableId.TenantAddressCity]: [],
    [EDefaultVariableId.TenantAddressCountry]: [],
    [EDefaultVariableId.TenantCompanyAddressFull]: [],
    [EDefaultVariableId.TenantSignature]: [],
    [EDefaultVariableId.TenantEmail]: [],
    [EDefaultVariableId.TenantPhone]: [],
    [EDefaultVariableId.TenantNationality]: [],
    [EDefaultVariableId.TenantNationalRegisterNumber]: [],
    [EDefaultVariableId.TenantMaritalStatus]: [],
    [EDefaultVariableId.ParentIsCompany]: [],
    [EDefaultVariableId.ParentFullName]: [],
    [EDefaultVariableId.ParentFirstName]: [],
    [EDefaultVariableId.ParentLastName]: [],
    [EDefaultVariableId.ParentBirthPlace]: [],
    [EDefaultVariableId.ParentBirthDate]: [],
    [EDefaultVariableId.ParentCompanyName]: [],
    [EDefaultVariableId.ParentCompanyVat]: [],
    [EDefaultVariableId.ParentCompanyRole]: [],
    [EDefaultVariableId.ParentAddressFull]: [],
    [EDefaultVariableId.ParentAddressStreet]: [],
    [EDefaultVariableId.ParentAddressNumber]: [],
    [EDefaultVariableId.ParentAddressBox]: [],
    [EDefaultVariableId.ParentAddressZip]: [],
    [EDefaultVariableId.ParentAddressCity]: [],
    [EDefaultVariableId.ParentAddressCountry]: [],
    [EDefaultVariableId.ParentCompanyAddressFull]: [],
    [EDefaultVariableId.ParentSignature]: [],
    [EDefaultVariableId.ParentEmail]: [],
    [EDefaultVariableId.ParentPhone]: [],
    [EDefaultVariableId.ParentNationality]: [],
    [EDefaultVariableId.ParentNationalRegisterNumber]: [],
    [EDefaultVariableId.ParentMaritalStatus]: [],
    [EDefaultVariableId.PropertyType]: [],
    [EDefaultVariableId.PropertyAddressFull]: [],
    [EDefaultVariableId.PropertyAddressStreet]: [],
    [EDefaultVariableId.PropertyAddressNumber]: [],
    [EDefaultVariableId.PropertyAddressBox]: [],
    [EDefaultVariableId.PropertyAddressZip]: [],
    [EDefaultVariableId.PropertyAddressCity]: [],
    [EDefaultVariableId.PropertyAddressCountry]: [],
    [EDefaultVariableId.PropertyDescription]: [],
    [EDefaultVariableId.PropertyName]: [],
    [EDefaultVariableId.PropertyReference]: [],
    [EDefaultVariableId.PropertySyndic]: [],
    [EDefaultVariableId.PropertyHasOilTank]: [],
    [EDefaultVariableId.PropertyOilTankIsBuried]: [],
    [EDefaultVariableId.PropertyOilTankVolume]: [],
    [EDefaultVariableId.PropertySmokeDetectorCount]: [],
    [EDefaultVariableId.PropertyEpcLabel]: [],
    [EDefaultVariableId.PropertyEpcValue]: [],
    [EDefaultVariableId.PropertyEpcExpirationDate]: [],
    [EDefaultVariableId.PropertyUtilityCounterWaterAvailable]: [],
    [EDefaultVariableId.PropertyUtilityCounterWaterIsCollective]: [],
    [EDefaultVariableId.PropertyUtilityCounterNaturalGasAvailable]: [],
    [EDefaultVariableId.PropertyUtilityCounterNaturalGasIsCollective]: [],
    [EDefaultVariableId.PropertyUtilityCounterElectricityAvailable]: [],
    [EDefaultVariableId.PropertyUtilityCounterElectricityIsCollective]: [],
    [EDefaultVariableId.PropertyUsagePrivatePercentage]: [],
    [EDefaultVariableId.PropertyUsageProfessionalPercentage]: [],
    [EDefaultVariableId.PropertyTechnicalManagers]: [],
    [EDefaultVariableId.PropertyFinancialManagers]: [],
    [EDefaultVariableId.ContractStartDate]: [],
    [EDefaultVariableId.ContractStopDate]: [],
    [EDefaultVariableId.ContractPaymentRepetition]: [],
    [EDefaultVariableId.ContractPriceVat]: [],
    [EDefaultVariableId.ContractStartPrice]: [],
    [EDefaultVariableId.ContractStartPriceIncludingVat]: [],
    [EDefaultVariableId.ContractCostsCommonVat]: [],
    [EDefaultVariableId.ContractStartCostsCommonFixed]: [],
    [EDefaultVariableId.ContractStartCostsCommonFixedIncludingVat]: [],
    [EDefaultVariableId.ContractStartCostsCommonProvision]: [],
    [EDefaultVariableId.ContractStartCostsCommonProvisionIncludingVat]: [],
    [EDefaultVariableId.ContractCostsNonCommonVat]: [],
    [EDefaultVariableId.ContractStartCostsNonCommonFixed]: [],
    [EDefaultVariableId.ContractStartCostsNonCommonFixedIncludingVat]: [],
    [EDefaultVariableId.ContractStartCostsNonCommonProvision]: [],
    [EDefaultVariableId.ContractStartCostsNonCommonProvisionIncludingVat]: [],
    [EDefaultVariableId.ContractCurrentPrice]: [],
    [EDefaultVariableId.ContractCurrentPriceIncludingVat]: [],
    [EDefaultVariableId.ContractCurrentCostsCommonFixed]: [],
    [EDefaultVariableId.ContractCurrentCostsCommonFixedIncludingVat]: [],
    [EDefaultVariableId.ContractCurrentCostsCommonProvision]: [],
    [EDefaultVariableId.ContractCurrentCostsCommonProvisionIncludingVat]: [],
    [EDefaultVariableId.ContractCurrentCostsNonCommonFixed]: [],
    [EDefaultVariableId.ContractCurrentCostsNonCommonFixedIncludingVat]: [],
    [EDefaultVariableId.ContractCurrentCostsNonCommonProvision]: [],
    [EDefaultVariableId.ContractCurrentCostsNonCommonProvisionIncludingVat]: [],
    [EDefaultVariableId.ContractPriceTypeCostsCommon]: [],
    [EDefaultVariableId.ContractPriceTypeCostsNonCommon]: [],
    [EDefaultVariableId.ContractStartIndexDate]: [],
    [EDefaultVariableId.ContractStartIndexNumber]: [],
    [EDefaultVariableId.ContractDurationYears]: [],
    [EDefaultVariableId.ContractIndexStartIndex]: [],
    [EDefaultVariableId.ContractIndexNewIndex]: [],
    [EDefaultVariableId.ContractIndexCorrectionFactor]: [],
    [EDefaultVariableId.ContractIndexEpcBasedIndexType]: [],
    [EDefaultVariableId.ContractIndexStartNextPaymentPeriod]: [],
    [EDefaultVariableId.ContractIndexNewPriceWasAdjusted]: [],
    [EDefaultVariableId.ContractIndexNewPriceOriginal]: [],
    [EDefaultVariableId.ContractIndexNewPriceAdjusted]: [],
    [EDefaultVariableId.ContractIndexForceFullIndexation]: [],
    [EDefaultVariableId.ContractRetroactivityPeriodStart]: [],
    [EDefaultVariableId.ContractRetroactivityPeriodEnd]: [],
    [EDefaultVariableId.ContractRetroactivityPeriodPrice]: [],
    [EDefaultVariableId.ContractIndexPaymentsWithRentio]: [],
    [EDefaultVariableId.ContractIndexPaymentOrderNewDueDate]: [],
    [EDefaultVariableId.ContractIbanisationBankAccountIban]: [],
    [EDefaultVariableId.ContractIbanisationBankAccountBic]: [],
    [EDefaultVariableId.ContractIbanisationBankAccountReference]: [],
    [EDefaultVariableId.ContractIndexCorrectionPeriodStart]: [],
    [EDefaultVariableId.ContractIndexCorrectionPeriodEnd]: [],
    [EDefaultVariableId.ContractIndexCorrectionPeriodPrice]: [],
    [EDefaultVariableId.ContractIndexCorrectionAndRetroSum]: [],
    [EDefaultVariableId.ContractIndexRetroactivePayments]: [],
  };

  const defaultVariableIds = Object.values(EDefaultVariableId) as string[];

  fields.variables.forEach(variable => {
    if (defaultVariableIds.includes(variable.id)) {
      return;
    }

    result[variable.id as EDefaultVariableId] = [
      variable.defaultDate ||
        variable.defaultNumber ||
        variable.defaultText ||
        variable.defaultToggle,
    ];
  });

  // TODO: Legal or financial contact?
  // Broker (legal contact & broker objects)
  if (legalContact) {
    result[EDefaultVariableId.BrokerEnabled] = [true];
    result[EDefaultVariableId.BrokerBiv] = [legalContact.bivNumber];
    result[EDefaultVariableId.BrokerFullName] = [
      [legalContact.firstname, legalContact.lastname].filter(Boolean).join(" "),
    ];
    result[EDefaultVariableId.BrokerFirstName] = [legalContact.firstname];
    result[EDefaultVariableId.BrokerLastName] = [legalContact.lastname];
    result[EDefaultVariableId.BrokerBirthPlace] = [legalContact.placeOfBirth];
    result[EDefaultVariableId.BrokerBirthDate] = [legalContact.dateOfBirth];
    result[EDefaultVariableId.BrokerAddressFull] = [
      formatAddress(legalContact),
    ];
    result[EDefaultVariableId.BrokerAddressStreet] = [legalContact.street];
    result[EDefaultVariableId.BrokerAddressNumber] = [legalContact.number];
    result[EDefaultVariableId.BrokerAddressBox] = [legalContact.box];
    result[EDefaultVariableId.BrokerAddressZip] = [legalContact.zip];
    result[EDefaultVariableId.BrokerAddressCity] = [legalContact.city];

    const country = getLocalizedText(
      `system.country.${legalContact.countryId}`.toLowerCase(),
    );
    result[EDefaultVariableId.BrokerAddressCountry] = [country];
  }

  if (broker) {
    result[EDefaultVariableId.BrokerOfficeName] = [broker.name];
    result[EDefaultVariableId.BrokerLogo] = [brokerLogoDocumentId];
  }

  if (meMaster) {
    result[EDefaultVariableId.BrokerSignature] = [meMaster.accountId];
  }

  // Owners
  const ownerAccounts = contract
    ? getMembersWithRole(
        contract?.members || [],
        EContractMemberTypes.Owner,
      ).map(owner => owner.account)
    : getMembersWithRole(
        property?.members || [],
        EPropertyMemberTypes.Owner,
      ).map(owner => owner.account);

  ownerAccounts.forEach(account => {
    const contact = contacts.find(contact =>
      contact.accountIds.includes(account.id),
    );

    const x = contact || account;

    result[EDefaultVariableId.OwnerIsCompany].push(!!x.company);
    result[EDefaultVariableId.OwnerFullName].push(
      [x.firstname, x.lastname].filter(Boolean).join(" "),
    );
    result[EDefaultVariableId.OwnerFirstName].push(x.firstname);
    result[EDefaultVariableId.OwnerLastName].push(x.lastname);
    result[EDefaultVariableId.OwnerBirthPlace].push(contact?.placeOfBirth);
    result[EDefaultVariableId.OwnerBirthDate].push(contact?.dateOfBirth);
    result[EDefaultVariableId.OwnerCompanyName].push(x.company);
    result[EDefaultVariableId.OwnerCompanyVat].push(contact?.VATNumber);
    result[EDefaultVariableId.OwnerCompanyRole].push(undefined);
    result[EDefaultVariableId.OwnerAddressFull].push(
      contact ? formatAddress(contact) : undefined,
    );
    result[EDefaultVariableId.OwnerAddressStreet].push(contact?.street);
    result[EDefaultVariableId.OwnerAddressNumber].push(contact?.number);
    result[EDefaultVariableId.OwnerAddressBox].push(contact?.box);
    result[EDefaultVariableId.OwnerAddressZip].push(contact?.zip);
    result[EDefaultVariableId.OwnerAddressCity].push(contact?.city);

    const country = contact
      ? getLocalizedText(`system.country.${contact.countryId}`.toLowerCase())
      : undefined;
    result[EDefaultVariableId.OwnerAddressCountry].push(country);
    result[EDefaultVariableId.OwnerCompanyAddressFull].push(
      contact ? formatAddress(contact) : undefined,
    );

    result[EDefaultVariableId.OwnerSignature].push(account.id);
    result[EDefaultVariableId.OwnerEmail].push(contact?.email);
    result[EDefaultVariableId.OwnerPhone].push(contact?.phone);
    result[EDefaultVariableId.OwnerNationality].push(
      contact?.nationality
        ? getLocalizedText(
            `system.nationality.${contact.nationality}`.toLowerCase(),
          )
        : undefined,
    );
    result[EDefaultVariableId.OwnerNationalRegisterNumber].push(
      contact?.nationalRegisterNumber,
    );
  });

  // Tenants
  const tenantAccounts = getMembersWithOneOfRoles(contract?.members || [], [
    EContractMemberTypes.Tenant,
  ]).map(owner => owner.account);

  tenantAccounts.forEach(account => {
    const contact = contacts.find(contact =>
      contact.accountIds.includes(account.id),
    );

    const x = contact || account;

    result[EDefaultVariableId.TenantIsCompany].push(!!x.company);
    result[EDefaultVariableId.TenantFullName].push(
      [x.firstname, x.lastname].filter(Boolean).join(" "),
    );
    result[EDefaultVariableId.TenantFirstName].push(x.firstname);
    result[EDefaultVariableId.TenantLastName].push(x.lastname);
    result[EDefaultVariableId.TenantBirthPlace].push(contact?.placeOfBirth);
    result[EDefaultVariableId.TenantBirthDate].push(contact?.dateOfBirth);
    result[EDefaultVariableId.TenantCompanyName].push(x.company);
    result[EDefaultVariableId.TenantCompanyVat].push(contact?.VATNumber);
    result[EDefaultVariableId.TenantCompanyRole].push(undefined);
    result[EDefaultVariableId.TenantAddressFull].push(
      contact ? formatAddress(contact) : undefined,
    );
    result[EDefaultVariableId.TenantAddressStreet].push(contact?.street);
    result[EDefaultVariableId.TenantAddressNumber].push(contact?.number);
    result[EDefaultVariableId.TenantAddressBox].push(contact?.box);
    result[EDefaultVariableId.TenantAddressZip].push(contact?.zip);
    result[EDefaultVariableId.TenantAddressCity].push(contact?.city);
    result[EDefaultVariableId.TenantAddressCountry].push(contact?.countryId);
    result[EDefaultVariableId.TenantCompanyAddressFull].push(
      contact ? formatAddress(contact) : undefined,
    );
    result[EDefaultVariableId.TenantSignature].push(account.id);
    result[EDefaultVariableId.TenantEmail].push(contact?.email);
    result[EDefaultVariableId.TenantPhone].push(contact?.phone);
    result[EDefaultVariableId.TenantNationality].push(
      contact?.nationality
        ? getLocalizedText(
            `system.nationality.${contact.nationality}`.toLowerCase(),
          )
        : undefined,
    );
    result[EDefaultVariableId.TenantNationalRegisterNumber].push(
      contact?.nationalRegisterNumber,
    );
  });

  // Parents
  const parentAccounts = getMembersWithOneOfRoles(contract?.members || [], [
    EContractMemberTypes.Parent,
  ]).map(owner => owner.account);

  parentAccounts.forEach(account => {
    const contact = contacts.find(contact =>
      contact.accountIds.includes(account.id),
    );

    const x = contact || account;

    result[EDefaultVariableId.ParentIsCompany].push(!!x.company);
    result[EDefaultVariableId.ParentFullName].push(
      [x.firstname, x.lastname].filter(Boolean).join(" "),
    );
    result[EDefaultVariableId.ParentFirstName].push(x.firstname);
    result[EDefaultVariableId.ParentLastName].push(x.lastname);
    result[EDefaultVariableId.ParentBirthPlace].push(contact?.placeOfBirth);
    result[EDefaultVariableId.ParentBirthDate].push(contact?.dateOfBirth);
    result[EDefaultVariableId.ParentCompanyName].push(x.company);
    result[EDefaultVariableId.ParentCompanyVat].push(contact?.VATNumber);
    result[EDefaultVariableId.ParentCompanyRole].push(undefined);
    result[EDefaultVariableId.ParentAddressFull].push(
      contact ? formatAddress(contact) : undefined,
    );
    result[EDefaultVariableId.ParentAddressStreet].push(contact?.street);
    result[EDefaultVariableId.ParentAddressNumber].push(contact?.number);
    result[EDefaultVariableId.ParentAddressBox].push(contact?.box);
    result[EDefaultVariableId.ParentAddressZip].push(contact?.zip);
    result[EDefaultVariableId.ParentAddressCity].push(contact?.city);
    result[EDefaultVariableId.ParentAddressCountry].push(contact?.countryId);
    result[EDefaultVariableId.ParentCompanyAddressFull].push(
      contact ? formatAddress(contact) : undefined,
    );
    result[EDefaultVariableId.ParentSignature].push(account.id);
    result[EDefaultVariableId.ParentEmail].push(contact?.email);
    result[EDefaultVariableId.ParentPhone].push(contact?.phone);
    result[EDefaultVariableId.ParentNationality].push(
      contact?.nationality
        ? getLocalizedText(
            `system.nationality.${contact.nationality}`.toLowerCase(),
          )
        : undefined,
    );
    result[EDefaultVariableId.ParentNationalRegisterNumber].push(
      contact?.nationalRegisterNumber,
    );
  });

  // Property
  const propertyType = PROPERTY_TYPES.find(type => type.id === property.typeId);
  result[EDefaultVariableId.PropertyType].push(
    propertyType
      ? getLocalizedText(`property.type.${propertyType.label}`)
      : undefined,
  );

  result[EDefaultVariableId.PropertyAddressFull].push(formatAddress(property));
  result[EDefaultVariableId.PropertyAddressStreet].push(property.street);
  result[EDefaultVariableId.PropertyAddressNumber].push(property.number);
  result[EDefaultVariableId.PropertyAddressBox].push(property.box);
  result[EDefaultVariableId.PropertyAddressZip].push(property.zip);
  result[EDefaultVariableId.PropertyAddressCity].push(property.city);
  result[EDefaultVariableId.PropertyName].push(property.name);
  result[EDefaultVariableId.PropertyReference].push(property.reference);

  const { epcLabel } = property;

  result[EDefaultVariableId.PropertyEpcLabel].push(
    getLocalizedText(
      `epc.label.${(epcLabel || EPropertyEPCLabel.Unknown).toLowerCase()}`,
    ),
  );

  const hasEpc =
    epcLabel &&
    ![EPropertyEPCLabel.Unknown, EPropertyEPCLabel.NoEPCAvailable].includes(
      epcLabel,
    );
  if (hasEpc) {
    result[EDefaultVariableId.PropertyEpcValue].push(property.epcValue);
    result[EDefaultVariableId.PropertyEpcExpirationDate].push(
      property.epcExpirationDate,
    );
  } else {
    result[EDefaultVariableId.PropertyEpcValue].push(undefined);
    result[EDefaultVariableId.PropertyEpcExpirationDate].push(undefined);
  }

  const country = getLocalizedText(
    `system.country.${property.countryId || "BE"}`.toLowerCase(),
  );
  result[EDefaultVariableId.PropertyAddressCountry].push(country);

  const technicalManagers = getMembersWithRole(
    property?.members || [],
    EPropertyMemberTypes.TechnicalManager,
  )
    .map(member => member.account)
    .map(account =>
      contacts.find(contact => contact.accountIds.includes(account.id)),
    )
    .filter(Boolean)
    .map(x => (x ? getName(x) : ""))
    .join(", ");

  const financialManagers = getMembersWithRole(
    property?.members || [],
    EPropertyMemberTypes.FinancialManager,
  )
    .map(member => member.account)
    .map(account =>
      contacts.find(contact => contact.accountIds.includes(account.id)),
    )
    .filter(Boolean)
    .map(x => (x ? getName(x) : ""))
    .join(", ");

  result[EDefaultVariableId.PropertyTechnicalManagers].push(technicalManagers);
  result[EDefaultVariableId.PropertyFinancialManagers].push(financialManagers);

  // Contract
  if (contract) {
    result[EDefaultVariableId.RentDepositPrice].push(
      contract?.rentDepositAmount,
    );
    const rentDepositMonths =
      (contract.rentDepositAmount || 0) /
      getAmountWithVat(
        contract.currentPrice || 0,
        (contract.priceVat || 0) / 100,
      );
    if (round(rentDepositMonths) === rentDepositMonths) {
      result[EDefaultVariableId.RentDepositMonths].push(rentDepositMonths);
    }

    result[EDefaultVariableId.ContractStartDate].push(contract.startDate);
    result[EDefaultVariableId.ContractStopDate].push(contract.stopDate);
    result[EDefaultVariableId.ContractPaymentRepetition].push(
      getLocalizedText(
        `contract.payment_repetition.option.${contract.repetitionType}`.toLowerCase(),
      ).toLowerCase(),
    );

    result[EDefaultVariableId.ContractPriceVat].push(contract.priceVat);
    result[EDefaultVariableId.ContractStartPrice].push(contract.startPrice);
    result[EDefaultVariableId.ContractStartPriceIncludingVat].push(
      getAmountWithVat(contract.startPrice, (contract.priceVat || 0) / 100),
    );

    result[EDefaultVariableId.ContractCostsCommonVat].push(
      contract.costsCommonVat,
    );
    result[EDefaultVariableId.ContractCostsNonCommonVat].push(
      contract.costsNonCommonVat,
    );

    result[EDefaultVariableId.ContractStartCostsCommonFixed].push(
      getAmountWithVat(
        contract.startCostsCommon,
        (contract.costsCommonVat || 0) / 100,
      ),
    );
    result[EDefaultVariableId.ContractStartCostsCommonFixedIncludingVat].push(
      contract.startCostsCommon,
    );
    result[EDefaultVariableId.ContractStartCostsCommonProvision].push(
      contract.startCostsCommon,
    );
    result[
      EDefaultVariableId.ContractStartCostsCommonProvisionIncludingVat
    ].push(
      getAmountWithVat(
        contract.startCostsCommon,
        (contract.costsCommonVat || 0) / 100,
      ),
    );

    result[EDefaultVariableId.ContractStartCostsNonCommonFixed].push(
      contract.startCostsNonCommon,
    );
    result[
      EDefaultVariableId.ContractStartCostsNonCommonFixedIncludingVat
    ].push(
      round(
        contract.startCostsNonCommon *
          (1 + (contract.costsNonCommonVat || 0) / 100),
        2,
      ),
    );
    result[EDefaultVariableId.ContractStartCostsNonCommonProvision].push(
      contract.startCostsNonCommon,
    );
    result[
      EDefaultVariableId.ContractStartCostsNonCommonProvisionIncludingVat
    ].push(
      round(
        contract.startCostsNonCommon *
          (1 + (contract.costsNonCommonVat || 0) / 100),
        2,
      ),
    );

    result[EDefaultVariableId.ContractCurrentPrice].push(contract.currentPrice);
    result[EDefaultVariableId.ContractCurrentPriceIncludingVat].push(
      getAmountWithVat(contract.currentPrice, (contract.priceVat || 0) / 100),
    );

    result[EDefaultVariableId.ContractCurrentCostsCommonFixed].push(
      contract.currentCostsCommon,
    );
    result[EDefaultVariableId.ContractCurrentCostsCommonFixedIncludingVat].push(
      round(
        contract.currentCostsCommon *
          (1 + (contract.costsCommonVat || 0) / 100),
        2,
      ),
    );
    result[EDefaultVariableId.ContractCurrentCostsCommonProvision].push(
      contract.currentCostsCommon,
    );
    result[
      EDefaultVariableId.ContractCurrentCostsCommonProvisionIncludingVat
    ].push(
      round(
        contract.currentCostsCommon *
          (1 + (contract.costsCommonVat || 0) / 100),
        2,
      ),
    );

    result[EDefaultVariableId.ContractCurrentCostsNonCommonFixed].push(
      contract.currentCostsNonCommon,
    );
    result[
      EDefaultVariableId.ContractCurrentCostsNonCommonFixedIncludingVat
    ].push(
      round(
        contract.currentCostsNonCommon *
          (1 + (contract.costsNonCommonVat || 0) / 100),
        2,
      ),
    );
    result[EDefaultVariableId.ContractCurrentCostsNonCommonProvision].push(
      contract.costsNonCommonVat,
    );
    result[
      EDefaultVariableId.ContractCurrentCostsNonCommonProvisionIncludingVat
    ].push(
      round(
        contract.currentCostsNonCommon *
          (1 + (contract.costsNonCommonVat || 0) / 100),
        2,
      ),
    );

    const {
      priceTypeCommonCosts = EContractCostsPriceType.Fixed,
      priceTypeNonCommonCosts = EContractCostsPriceType.Provision,
    } = contract;

    const priceTypeCostsCommon =
      contract.currentCostsCommon > 0
        ? getLocalizedText(
            `contract_info.costs.price_type.value.${priceTypeCommonCosts.toLowerCase()}`,
          )
        : getLocalizedText(`contract_info.costs.price_type.value.none`);
    result[EDefaultVariableId.ContractPriceTypeCostsCommon].push(
      priceTypeCostsCommon,
    );
    const priceTypeCostsNonCommon =
      contract.currentCostsNonCommon > 0
        ? getLocalizedText(
            `contract_info.costs.price_type.value.${priceTypeNonCommonCosts.toLowerCase()}`,
          )
        : getLocalizedText(`contract_info.costs.price_type.value.none`);

    result[EDefaultVariableId.ContractPriceTypeCostsNonCommon].push(
      priceTypeCostsNonCommon,
    );
    result[EDefaultVariableId.ContractStartIndexDate].push(
      new Date(
        contract.startIndexYear,
        contract.startIndexMonth - 1, // Zero-based
        1,
      ),
    );
    // Get start index
    result[EDefaultVariableId.ContractStartIndexNumber].push(
      undefined,
      // contract.startIndexId,
    );
    result[EDefaultVariableId.ContractDurationYears].push(
      contract.stopDate
        ? floor(
            differenceInDays(
              addDays(contract.stopDate, 1),
              contract.startDate,
            ) / 365,
          )
        : undefined,
    );

    if (
      (contract.indexationStatus === EContractIndexationStatus.Ready ||
        contract.indexationStatus === EContractIndexationStatus.OwnerAsked) &&
      indexInfoAvailable &&
      indexInfoSimulation &&
      indexInfoRequest
    ) {
      const epcLabel = property.epcLabel || EPropertyEPCLabel.Unknown;

      // New price as filled in by the user
      const newPrice = indexInfoRequest.newPrice;
      const newPriceWithVat = getAmountWithVat(
        indexInfoRequest.newPrice,
        (contract.priceVat || 0) / 100,
      );

      // New price as proposed by Rentio (max. allowed increase)
      let proposedNewPrice = indexInfoAvailable.newPrice;
      let proposedCorrectionFactor = 1;
      if (
        indexInfoAvailable.epcBasedIndexationType ===
          EEpcBasedIndexationType.Corrected &&
        !indexInfoRequest.forceFullIndexation
      ) {
        if (epcLabel === EPropertyEPCLabel.D) {
          proposedNewPrice = indexInfoAvailable.newPriceEpcD;
          proposedCorrectionFactor = indexInfoAvailable.correctionFactorEpcD;
        } else if (epcLabel === EPropertyEPCLabel.E) {
          proposedNewPrice = indexInfoAvailable.newPriceEpcE;
          proposedCorrectionFactor = indexInfoAvailable.correctionFactorEpcE;
        } else if (epcLabel === EPropertyEPCLabel.F) {
          proposedNewPrice = indexInfoAvailable.newPriceEpcF;
          proposedCorrectionFactor = indexInfoAvailable.correctionFactorEpcF;
        } else if (epcLabel === EPropertyEPCLabel.G) {
          proposedNewPrice = indexInfoAvailable.newPriceEpcG;
          proposedCorrectionFactor = indexInfoAvailable.correctionFactorEpcG;
        } else if (
          epcLabel === EPropertyEPCLabel.NoEPCAvailable ||
          epcLabel === EPropertyEPCLabel.Unknown
        ) {
          proposedNewPrice = indexInfoAvailable.newPriceNoEpc;
          proposedCorrectionFactor = indexInfoAvailable.correctionFactorNoEpc;
        }
      }

      const proposedNewPriceWithVat = getAmountWithVat(
        proposedNewPrice,
        (contract.priceVat || 0) / 100,
      );

      result[EDefaultVariableId.ContractIndexStartIndex].push(
        indexInfoAvailable.startIndex,
      );
      result[EDefaultVariableId.ContractIndexNewIndex].push(
        indexInfoAvailable.newIndex,
      );
      result[EDefaultVariableId.ContractIndexCorrectionFactor].push(
        proposedCorrectionFactor !== 1 ? proposedCorrectionFactor : undefined,
      );
      result[EDefaultVariableId.ContractIndexEpcBasedIndexType].push(
        indexInfoAvailable.epcBasedIndexationType,
      );

      result[EDefaultVariableId.ContractIndexNewPriceWasAdjusted].push(
        proposedNewPrice !== newPrice,
      );

      result[EDefaultVariableId.ContractIndexNewPriceOriginal].push(
        round(proposedNewPriceWithVat, 2),
      );
      result[EDefaultVariableId.ContractIndexNewPriceAdjusted].push(
        round(newPriceWithVat, 2),
      );

      result[EDefaultVariableId.ContractIndexForceFullIndexation].push(
        indexInfoRequest.forceFullIndexation,
      );

      const startNextPaymentPeriod =
        indexInfoAvailable.retroactivityPeriodStart || new Date();
      result[EDefaultVariableId.ContractIndexStartNextPaymentPeriod].push(
        startNextPaymentPeriod,
      );

      const now = new Date();

      result[EDefaultVariableId.ContractIndexRetroactivePayments].push(
        indexInfoRequest.isRetroactive,
      );

      result[EDefaultVariableId.ContractIndexCorrectionPeriodStart].push(
        indexInfoSimulation.correctionPeriodStart,
      );

      result[EDefaultVariableId.ContractIndexCorrectionPeriodEnd].push(
        indexInfoSimulation.correctionPeriodEnd,
      );

      result[EDefaultVariableId.ContractRetroactivityPeriodStart].push(
        indexInfoSimulation.retroactivityPeriodStart,
      );

      result[EDefaultVariableId.ContractRetroactivityPeriodEnd].push(
        indexInfoSimulation.retroactivityPeriodEnd,
      );

      const retroactivityPriceWithVat = getAmountWithVat(
        indexInfoSimulation.retroactivityPrice,
        (contract.priceVat || 0) / 100,
      );
      const correctionPriceWithVat = getAmountWithVat(
        indexInfoSimulation.correctionPrice,
        (contract.priceVat || 0) / 100,
      );

      result[EDefaultVariableId.ContractRetroactivityPeriodPrice].push(
        round(retroactivityPriceWithVat, 2),
      );

      result[EDefaultVariableId.ContractIndexCorrectionPeriodPrice].push(
        round(correctionPriceWithVat, 2),
      );
      result[EDefaultVariableId.ContractIndexCorrectionAndRetroSum].push(
        round(retroactivityPriceWithVat + correctionPriceWithVat, 2),
      );

      result[EDefaultVariableId.ContractIndexPaymentsWithRentio].push(
        !!rentPaymentOrder,
      );

      const day = new Date(
        indexInfoSimulation.retroactivityPeriodStart ||
          indexInfoSimulation.correctionPeriodStart,
      ).getDate();

      const indexationStartAt = new Date(
        now.getFullYear(),
        now.getMonth(),
        day,
      );

      result[EDefaultVariableId.ContractIndexPaymentOrderNewDueDate].push(
        indexationStartAt,
      );
    } else {
      result[EDefaultVariableId.ContractIndexStartIndex].push(undefined);
      result[EDefaultVariableId.ContractIndexNewIndex].push(undefined);
      result[EDefaultVariableId.ContractIndexCorrectionFactor].push(undefined);
      result[EDefaultVariableId.ContractIndexEpcBasedIndexType].push(undefined);
      result[EDefaultVariableId.ContractIndexNewPriceWasAdjusted].push(
        undefined,
      );
      result[EDefaultVariableId.ContractIndexNewPriceOriginal].push(undefined);
      result[EDefaultVariableId.ContractIndexNewPriceAdjusted].push(undefined);
      result[EDefaultVariableId.ContractIndexForceFullIndexation].push(
        undefined,
      );
      result[EDefaultVariableId.ContractIndexStartNextPaymentPeriod].push(
        undefined,
      );
      result[EDefaultVariableId.ContractRetroactivityPeriodStart].push(
        undefined,
      );
      result[EDefaultVariableId.ContractRetroactivityPeriodEnd].push(undefined);
      result[EDefaultVariableId.ContractRetroactivityPeriodPrice].push(
        undefined,
      );
      result[EDefaultVariableId.ContractIndexPaymentsWithRentio].push(
        undefined,
      );
      result[EDefaultVariableId.ContractIndexPaymentOrderNewDueDate].push(
        undefined,
      );
    }

    if (
      contract.ibanisationStatus === EContractIbanisationStatus.Enabled &&
      ibanisationBankAccount
    ) {
      result[EDefaultVariableId.ContractIbanisationBankAccountIban].push(
        formatIban(ibanisationBankAccount.iban),
      );
      result[EDefaultVariableId.ContractIbanisationBankAccountBic].push(
        ibanisationBankAccount.mangoWalletIbanBic,
      );
      result[EDefaultVariableId.ContractIbanisationBankAccountReference].push(
        contract.ibanisationReference,
      );
    }
  }

  Object.keys(preferences).forEach(preferenceKey => {
    if (!preferenceKey.startsWith("template_document:field:")) {
      return;
    }

    const variableId = preferenceKey.replace("template_document:field:", "");
    const variable = fields.variables.find(x => x.id === variableId);
    if (!variable?.persisted) {
      return;
    }

    const data = preferences[preferenceKey];
    if (!data || !Array.isArray(data)) {
      return;
    }

    // @ts-ignore
    result[variableId] = data;
  });

  return JSON.stringify({
    ...result,
    ...variablesDataToOverride,
  });
};

export const contactIsValidForTemplate = (contact: IContact) => {
  if (!contact.placeOfBirth) {
    return false;
  }

  if (!contact.dateOfBirth) {
    return false;
  }

  if (!contact.nationality) {
    return false;
  }

  if (contact.nationality !== "BE") {
    if (!contact.nationalRegisterNumber && !contact.foreignIdentityNumber) {
      return false;
    }
  }

  if (contact.nationality === "BE" && !contact.nationalRegisterNumber) {
    return false;
  }

  return true;
};
