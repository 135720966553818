import mergeDeepLeft from "ramda/es/mergeDeepLeft";

export const contactsOverviewWhereFilter = {
  or: [
    {
      type: { neq: "F" },
    },
    { type: null },
  ],
};

export const DEFAULT_FILTER = {
  where: contactsOverviewWhereFilter,
  order: "lastname ASC",
};

export const getFilterQuery = (values: any, defaultFilter: any) => {
  let newFilter = defaultFilter;

  if (values.query) {
    newFilter = mergeDeepLeft({ search: values.query }, newFilter);
  }

  return newFilter;
};
