import {
  Card,
  DisplayText,
  EmptyState,
  ESpacings,
  Grid,
} from "@rentiohq/web-shared/dist/components";
import { memo } from "react";
import * as t from "../../../../../services/translationService";

const PropertyDetailApplicationEmptyState = () => {
  return (
    <Grid spacing="loose">
      <Grid.Item width={1}>
        <Card hasSections>
          <EmptyState
            size="large"
            backgroundColor="white"
            heading={t.applicationsOverviewEmptyStateTitle()}
          >
            <p>{t.applicationsOverviewEmptyStateContent()}</p>
          </EmptyState>
        </Card>
      </Grid.Item>
      <Grid.Item width={1}>
        <Card>
          <Grid spacing="loose">
            {[...Array(6)].map((node, index) => (
              <Grid.Item
                width={[1, 1, 1 / 2]}
                key={index}
                mb={ESpacings.superLoose}
              >
                <DisplayText size="medium" element="h3">
                  {t.applicationsOverviewEmptyStateBlockTitle(index)}
                </DisplayText>
                <p>{t.applicationsOverviewEmptyStateBlockBody(index)}</p>
              </Grid.Item>
            ))}
          </Grid>
        </Card>
      </Grid.Item>
    </Grid>
  );
};

export const PropertyDetailApplicationEmptyStateMemo = memo(
  PropertyDetailApplicationEmptyState,
);
