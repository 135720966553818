export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["StartDate"] = "startDate";
    EField["Amount"] = "amount";
    EField["Info"] = "info";
    EField["DueDate"] = "dueDate";
    EField["Signers"] = "signers";
    EField["Payer"] = "payer";
    EField["Broker"] = "broker";
    EField["IdentityDocuments"] = "identityDocuments";
    EField["IdentityDocumentAccountId"] = "accountId";
    EField["IdentityDocumentIdentityFrontDocumentId"] = "identityFrontDocument";
    EField["IdentityDocumentIdentityBackDocumentId"] = "identityBackDocument";
    EField["LegalContactInfo"] = "legalContactInfo";
    EField["SignAsBroker"] = "signAsBroker";
    EField["Locale"] = "locale";
    EField["InsuranceSendProposal"] = "insuranceSendProposal";
    EField["InsuranceDataSharing"] = "insuranceDataSharing";
    EField["InsuranceContractRentPrice"] = "insuranceContractRentPrice";
    EField["InsuranceBuildingSurfaceArea"] = "insuranceBuildingSurfaceArea";
    EField["InsuranceBuildingLayers"] = "insuranceBuildingLayers";
    EField["InsuranceBuildingAdjoined"] = "insuranceBuildingAdjoined";
    EField["InsuranceBuildingHasHardMaterials"] = "insuranceBuildingHasHardMaterials";
})(EField || (EField = {}));
