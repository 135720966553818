var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { ActivityIndicator } from "react-native";
import { EAtomicBlockImageAlignment } from "../../../Editor/components/EditorData/AtomicBlockImage/AtomicBlockImage.types";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n"], ["\n  width: 100%;\n  position: relative;\n"])));
export var InnerWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: ", ";\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: ", ";\n  width: 100%;\n"])), function (props) {
    switch (props.alignment) {
        case EAtomicBlockImageAlignment.Right:
            return "flex-end";
        case EAtomicBlockImageAlignment.Center:
            return "center";
        case EAtomicBlockImageAlignment.Left:
        default:
            return "flex-start;";
    }
});
export var ResizeableWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  max-width: 100%;\n  width: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  background-color: ", ";\n"], ["\n  position: relative;\n  max-width: 100%;\n  width: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  background-color: ", ";\n"])), function (props) { return "".concat(props.width || 200, "px"); }, function (props) { return (props.asVariable ? "yellow" : "transparent"); });
export var ImageVariablePlaceholder = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  background-color: yellow;\n  text-align: center;\n"], ["\n  width: 100%;\n  background-color: yellow;\n  text-align: center;\n"])));
export var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n"], ["\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n"])));
export var FullActivityIndicatorWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  /* position: absolute; */\n  /* top: 0;\n  left: 0; */\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  /* position: absolute; */\n  /* top: 0;\n  left: 0; */\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var FullActivityIndicator = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
