var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { EDisplayType } from "../Editor.types";
// Context
export var DisplayTypeContext = React.createContext({
    displayType: EDisplayType.Template,
    setDisplayType: function (newValue) { },
    isTemplate: true,
    isTemplateDocument: false,
    isTemplateDocumentEditor: false,
    isPrint: false,
});
// Provider
export var DisplayTypeProvider = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(props.displayType), displayType = _a[0], setDisplayType = _a[1];
    var isTemplate = displayType === EDisplayType.Template;
    var isTemplateDocument = displayType === EDisplayType.TemplateDocument;
    var isTemplateDocumentEditor = displayType === EDisplayType.TemplateDocumentEditor;
    var isPrint = displayType === EDisplayType.Print;
    // Render
    return (_jsx(DisplayTypeContext.Provider, __assign({ value: {
            displayType: displayType,
            setDisplayType: setDisplayType,
            isTemplate: isTemplate,
            isTemplateDocument: isTemplateDocument,
            isTemplateDocumentEditor: isTemplateDocumentEditor,
            isPrint: isPrint,
        } }, { children: children })));
};
