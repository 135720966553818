var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var RemoveWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: none;\n  margin-right: 12px;\n  margin-top: ", "px;\n"], ["\n  display: none;\n  margin-right: 12px;\n  margin-top: ", "px;\n"])), function (props) { return (props.isFirst ? 16 : 0); });
export var RemovePlaceholder = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 16px;\n"], ["\n  width: 16px;\n"])));
export var InstanceWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var InstanceRemoveWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 0 24px 0 44px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin: 0 24px 0 44px;\n"])));
export var Wrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 8px 0;\n  &:hover {\n    ", " {\n      margin-left: 16px;\n    }\n\n    ", " {\n      display: inherit;\n    }\n  }\n"], ["\n  margin: 8px 0;\n  &:hover {\n    ", " {\n      margin-left: 16px;\n    }\n\n    ", " {\n      display: inherit;\n    }\n  }\n"])), InstanceRemoveWrap, RemoveWrap);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
