import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { isResidential } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import {
  addYears,
  isBefore,
  startOfDay,
  subDays,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { getAmountWithVat } from "@rentiohq/shared/dist/utils/number.utils";
import { EField, IExtraData } from "./schema.requestInsurance.types";

const NOW = new Date();

export const requestInsuranceValidationErrors = (params: {
  property: IProperty;
  contract: IContract;
}) => {
  const { property, contract } = params;

  const errors = [];

  // Only house or apartment is valid
  if (!isResidential(property)) {
    errors.push(getLocalizedText("insurance.validation.error.property_type"));
  }

  // Contract where end date is longer than a year from now is valid
  if (
    contract?.stopDate &&
    isBefore(
      startOfDay(contract.stopDate),
      startOfDay(subDays(addYears(NOW, 1), 1)),
    ) &&
    !contract.tacitRenewal
  ) {
    errors.push(getLocalizedText("insurance.validation.error.contract_length"));
  }

  return errors;
};

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { property, contract } = extraData;

  return {
    [EField.ContractRentPrice]: getAmountWithVat(
      contract.currentPrice,
      (contract.priceVat || 0) / 100,
    ),
    [EField.BuildingSurfaceArea]: property.buildingSurfaceArea,
    // [EField.BuildingFloor]: property.buildingFloor,
    [EField.BuildingLayers]: property.buildingFloorCount,
    [EField.BuildingAdjoined]: property.buildingAdjoined,
    [EField.BuildingHasHardMaterials]:
      property.buildingHasHardMaterials || false,
  };
};
