import { useGetInline } from "@rentiohq/shared-frontend/dist/hooks/api.hooks";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  Loading,
  ResourceList,
  ResourceListItem,
} from "@rentiohq/web-shared/dist/components";
import { IEnabledAccount } from "components/EnabledAccountsSelect/EnabledAccountsSelect";

export const Employees = () => {
  const { broker } = authHooks.useSelf();

  const { data, isFetching } = useGetInline<IEnabledAccount[]>({
    path: broker ? `/brokers/${broker.id}/employee-accounts` : undefined,
  });

  // render
  const renderItem = (item: IEnabledAccount) => (
    <ResourceListItem item={item}>
      <div>{getName(item)}</div>
    </ResourceListItem>
  );

  if (isFetching && !data) {
    return <Loading />;
  }

  return <ResourceList items={data || []} renderItem={renderItem} />;
};

export default Employees;
