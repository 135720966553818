import { REDUCER_KEY_DOCUMENT_PACKAGE, } from "./documentPackage.reducer";
export var getDocumentPackage = function (state, documentPackageId) {
    return state.documentPackage.documentPackages[documentPackageId];
};
export var getDocumentPackages = function (state, identifier) {
    var _a, _b;
    return (_b = (_a = state.documentPackage.byIdentifier[identifier]) === null || _a === void 0 ? void 0 : _a.ids) === null || _b === void 0 ? void 0 : _b.map(function (id) { return state.documentPackage.documentPackages[id]; }).filter(function (documentPackage) { return !!documentPackage; });
};
export var isFetchingDocumentPackages = function (state, identifier) { var _a; return ((_a = state.documentPackage.byIdentifier[identifier]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var isFetchingDocumentPackage = function (state, documentPackageId) {
    var propertiesState = state.documentPackage.documentPackagesById[documentPackageId];
    if (!propertiesState) {
        return false;
    }
    return propertiesState.isFetching || false;
};
export var fetchDocumentPackageError = function (state, documentPackageId) {
    var propertiesState = state.documentPackage.documentPackagesById[documentPackageId];
    if (!propertiesState) {
        return;
    }
    return propertiesState.fetchError;
};
export var getDocumentPackagesForPage = function (state, identifier, page) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.documentPackage.pagedDocumentPackages[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.documentPackageIds) === null || _c === void 0 ? void 0 : _c.map(function (documentPackageId) {
        return state.documentPackage.documentPackages[documentPackageId];
    }).filter(function (documentPackage) { return !!documentPackage; });
};
export var isFetchingDocumentPackagesForPage = function (state, identifier, page) {
    var _a, _b;
    return ((_b = (_a = state.documentPackage.pagedDocumentPackages[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching) || false;
};
export var documentPackagesForPageFetchError = function (state, identifier, page) {
    var _a, _b;
    return (_b = (_a = state.documentPackage.pagedDocumentPackages[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError;
};
