import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer/Spacer";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as legalCaseHooks from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EContractMemberTypes } from "@rentiohq/shared/dist/types/contract.types";
import {
  ELegalCaseStatus,
  ILegalCase,
} from "@rentiohq/shared/dist/types/legalCase.types";
import {
  ESpacings,
  Grid,
  Icon,
  Loading,
  Lozenge,
  Page,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button/Button";
import utils from "@rentiohq/web-shared/dist/utils";
import { useCallback, useMemo, useState } from "react";
import { ETabs } from "utils/tabs.utils";
import LegalCaseHistory from "./components/LegalCaseHistory";
import LegalCaseInfo from "./components/LegalCaseInfo/LegalCaseInfo";
interface ILegalCaseDrawerProps {
  legalCaseId: string;
}

const renderHistory = (legalCase: ILegalCase) => {
  return <LegalCaseHistory legalCase={legalCase} />;
};

const LegalCase = ({ legalCaseId }: ILegalCaseDrawerProps) => {
  const [tab, setTab] = useState<ETabs>(ETabs.Info);
  const { detail: legalCase, isFetching } = legalCaseHooks.useDetail({
    id: legalCaseId,
  });

  const { update, isUpdating } = legalCaseHooks.useUpdate({
    id: legalCaseId,
  });

  const { detail: property, isFetching: isFetchingProperty } =
    propertyHooks.useDetail({
      id: legalCase?.propertyId,
    });

  const { detail: contract, isFetching: isFetchingContract } =
    contractHooks.useDetail({
      id: legalCase?.contractId,
    });

  const canCloseCase = legalCase?.status === ELegalCaseStatus.Open;

  let tabs = [
    {
      name: "dashboard",
      permission: "propertyDashboard:visit",
      content: getLocalizedText("system.info"),
      onClick: () => setTab(ETabs.Info),
      isActive: tab === ETabs.Info,
    },
    {
      name: "payments",
      permission: "propertyPayments:visit",
      content: getLocalizedText("system.history"),
      onClick: () => setTab(ETabs.History),
      isActive: tab === ETabs.History,
    },
  ];

  const subtitle = useMemo(() => {
    if (!legalCase) return null;

    return (
      <Lozenge
        isBold
        key={`${legalCase.id}-status`}
        appearance={
          legalCase.status === ELegalCaseStatus.Open ? "warning" : "success"
        }
      >
        {getLocalizedText(`rentio_legal.case.status.${legalCase.status}`)}
      </Lozenge>
    );
  }, [legalCase]);

  const metadata = useMemo(() => {
    if (!legalCase) return null;
    return (
      <div>
        <Spacer weight={ESpacerWeight.W08} />
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item>
            <Icon source="calendar" size="small" />
          </Grid.Item>
          <Grid.Item>
            {getLocalizedText("system.sent_on", {
              date: utils.date.format(legalCase.startDate, "dd/MM/yyyy"),
            })}
          </Grid.Item>
        </Grid>
        {property && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="house" size="small" />
              </Grid.Item>
              <Grid.Item>
                <Button
                  appearance="link"
                  url={`/properties/${legalCase!.propertyId}`}
                >
                  {formatAddress(property)}
                </Button>
              </Grid.Item>
            </Grid>
          </>
        )}
      </div>
    );
  }, [legalCase, property]);

  const renderInfo = useCallback(
    () => (
      <>
        <LegalCaseInfo legalCase={legalCase!} /* setTab={setTab}  */ />
        {canCloseCase && (
          <Box mt={ESpacings.extraLoose}>
            <Button
              appearance="outline"
              color="red"
              onClick={() =>
                update({
                  data: { status: ELegalCaseStatus.Closed },
                })
              }
              isSubmitting={isUpdating}
            >
              {getLocalizedText("rentio_legal.actions.close_case")}
            </Button>
          </Box>
        )}
      </>
    ),
    [legalCase, canCloseCase, update, isUpdating],
  );

  if (isFetching) return <Loading />;
  if (!legalCase) return null;
  if (isFetchingProperty || isFetchingContract) return <Loading />;

  return (
    <Page
      title={getLocalizedText("rentio_legal.case.name", {
        name_tenant: contract
          ? contract.members
              .filter(m => m.roles.includes(EContractMemberTypes.Tenant))
              .map(({ account }) => account.lastname)
              .join(", ")
          : "",
      })}
      subtitle={subtitle}
      metadata={metadata}
      tabs={tabs}
      setDocumentTitle={false}
    >
      {tab === ETabs.Info && renderInfo()}
      {tab === ETabs.History && renderHistory(legalCase)}
    </Page>
  );
};

export default LegalCase;
