import {
  IDocument,
  IDocumentCategory,
} from "@rentiohq/shared/dist/types/document.types";

export interface IExtraData {
  categories: IDocumentCategory[];
  files?: File[];
  document?: IDocument;
}

export enum EField {
  Name = "filename",
  Category = "categoryId",
}
