import { IContact } from "@rentiohq/shared/dist/types/contact.types";

export enum EField {
  PaymentRequestId = "paymentRequestId",
  SmsContent = "smsContent",
  SmsNumber = "smsNumber",
}

export interface IExtraData {
  contact: IContact;
}
