var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createReducer } from "redux-act";
import * as rentDepositActions from "./rentDeposit.actions";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
export var REDUCER_KEY_RENT_DEPOSIT = "rentDeposit";
var getDefaultState = function () { return ({
    // All properties
    rentDeposits: {},
    // Rent deposits by ID
    rentDepositsById: {},
    // Rent deposits by identifier
    byIdentifier: {},
    // Rent deposit create
    isCreatingRentDeposit: false,
    createRentDepositError: undefined,
    createdRentDepositId: undefined,
    // Rent deposit release
    isReleasingRentDeposit: false,
    releaseRentDepositError: undefined,
    releasedRentDepositId: undefined,
    // Rent deposit cancel
    isCancellingRentDeposit: false,
    cancelRentDepositError: undefined,
    cancelledRentDepositId: undefined,
    pagedRentDeposits: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(rentDepositActions.getRentDeposit.types.START, function (state, _a) {
    var _b;
    var rentDepositId = _a.rentDepositId;
    return (__assign(__assign({}, state), { rentDepositsById: __assign(__assign({}, state.rentDepositsById), (_b = {}, _b[rentDepositId] = {
            isFetching: true,
            fetchError: undefined,
        }, _b)) }));
});
reducer.on(rentDepositActions.getRentDeposit.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var rentDepositId = _a.rentDepositId, rentDeposit = _a.rentDeposit;
    return __assign(__assign({}, state), { rentDeposits: __assign(__assign({}, state.rentDeposits), (_b = {}, _b[rentDepositId] = rentDeposit, _b)), rentDepositsById: __assign(__assign({}, state.rentDepositsById), (_c = {}, _c[rentDepositId] = {
            isFetching: false,
            fetchError: undefined,
        }, _c)) });
});
reducer.on(rentDepositActions.getRentDeposit.types.FAILURE, function (state, _a) {
    var _b;
    var rentDepositId = _a.rentDepositId, error = _a.error;
    return (__assign(__assign({}, state), { rentDepositsById: __assign(__assign({}, state.rentDepositsById), (_b = {}, _b[rentDepositId] = {
            isFetching: false,
            fetchError: error,
        }, _b)) }));
});
reducer.on(rentDepositActions.getRentDeposits.types.START, function (state, _a) {
    var _b;
    var identifier = _a.identifier, extraFilterData = _a.extraFilterData;
    var prevStateForIdentifier = state.byIdentifier[identifier] || {};
    return __assign(__assign({}, state), { byIdentifier: __assign(__assign({}, state.byIdentifier), (_b = {}, _b[identifier] = __assign(__assign({ ids: undefined, isExhausted: false }, prevStateForIdentifier), { extraFilterData: extraFilterData, isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(rentDepositActions.getRentDeposits.types.SUCCESS, function (state, _a) {
    var _b;
    var identifier = _a.identifier, refetch = _a.refetch, rentDeposits = _a.rentDeposits, isExhausted = _a.isExhausted;
    var prevStateForIdentifier = state.byIdentifier[identifier] || {};
    var ids = rentDeposits.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return __assign(__assign({}, state), { rentDeposits: __assign(__assign({}, state.rentDeposits), keyBy(rentDeposits, "id")), byIdentifier: __assign(__assign({}, state.byIdentifier), (_b = {}, _b[identifier] = __assign(__assign({ extraFilterData: undefined }, prevStateForIdentifier), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, ids: refetch ? ids : __spreadArray(__spreadArray([], (prevStateForIdentifier.ids || []), true), ids, true) }), _b)) });
});
reducer.on(rentDepositActions.getRentDeposits.types.FAILURE, function (state, _a) {
    var _b;
    var identifier = _a.identifier, error = _a.error;
    var prevStateForIdentifier = state.byIdentifier[identifier] || {};
    return __assign(__assign({}, state), { byIdentifier: __assign(__assign({}, state.byIdentifier), (_b = {}, _b[identifier] = __assign(__assign({ extraFilterData: undefined, ids: undefined, isExhausted: false }, prevStateForIdentifier), { isFetching: false, fetchError: error }), _b)) });
});
reducer.on(rentDepositActions.getRentDepositsPaged.types.START, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, extraFilterData = _a.extraFilterData;
    return merge({}, state, {
        pagedRentDeposits: (_b = {},
            _b[identifier] = {
                filterData: extraFilterData,
                pages: (_c = {},
                    _c[page] = {
                        isFetching: true,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(rentDepositActions.getRentDepositsPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, rentDeposits = _a.rentDeposits;
    return merge({}, state, {
        rentDeposits: __assign({}, keyBy(rentDeposits, "id")),
        pagedRentDeposits: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        rentDepositIds: rentDeposits.map(function (rentDeposit) { return rentDeposit.id; }),
                        isFetching: false,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(rentDepositActions.getRentDepositsPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, error = _a.error;
    return merge({}, state, {
        pagedRentDeposits: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        isFetching: false,
                        fetchError: error,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(rentDepositActions.createRentDeposit.types.START, function (state) { return (__assign(__assign({}, state), { isCreatingRentDeposit: true, createRentDepositError: undefined, createdRentDepositId: undefined })); });
reducer.on(rentDepositActions.createRentDeposit.types.SUCCESS, function (state, rentDeposit) {
    var _a;
    return __assign(__assign({}, state), { rentDeposits: __assign(__assign({}, state.rentDeposits), (_a = {}, _a[rentDeposit.id] = rentDeposit, _a)), isCreatingRentDeposit: false, createRentDepositError: undefined, createdRentDepositId: rentDeposit.id });
});
reducer.on(rentDepositActions.createRentDeposit.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingRentDeposit: false, createRentDepositError: error, createdRentDepositId: undefined }));
});
reducer.on(rentDepositActions.releaseRentDeposit.types.START, function (state) { return (__assign(__assign({}, state), { isReleasingRentDeposit: true, releaseRentDepositError: undefined, releasedRentDepositId: undefined })); });
reducer.on(rentDepositActions.releaseRentDeposit.types.SUCCESS, function (state, rentDeposit) {
    var _a;
    return __assign(__assign({}, state), { rentDeposits: __assign(__assign({}, state.rentDeposits), (_a = {}, _a[rentDeposit.id] = rentDeposit, _a)), isReleasingRentDeposit: false, releaseRentDepositError: undefined, releasedRentDepositId: rentDeposit.id });
});
reducer.on(rentDepositActions.releaseRentDeposit.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isReleasingRentDeposit: false, releaseRentDepositError: error, releasedRentDepositId: undefined }));
});
reducer.on(rentDepositActions.cancelRentDeposit.types.START, function (state, _a) {
    var _b;
    var rentDepositId = _a.rentDepositId;
    return (__assign(__assign({}, state), { rentDeposits: __assign(__assign({}, state.rentDeposits), (_b = {}, _b[rentDepositId] = __assign(__assign({}, state.rentDeposits[rentDepositId]), { isCancelling: true, cancelError: undefined }), _b)), isCancellingRentDeposit: true, cancelRentDepositError: undefined, cancelledRentDepositId: undefined }));
});
reducer.on(rentDepositActions.cancelRentDeposit.types.SUCCESS, function (state, _a) {
    var _b;
    var rentDepositId = _a.rentDepositId;
    var rentDeposits = __assign({}, state.rentDeposits);
    delete rentDeposits[rentDepositId];
    return __assign(__assign({}, state), { rentDeposits: __assign(__assign({}, state.rentDeposits), (_b = {}, _b[rentDepositId] = __assign(__assign({}, state.rentDeposits[rentDepositId]), { isCancelling: false, cancelError: undefined }), _b)), isCancellingRentDeposit: false, cancelRentDepositError: undefined, cancelledRentDepositId: rentDepositId });
});
reducer.on(rentDepositActions.cancelRentDeposit.types.FAILURE, function (state, _a) {
    var _b;
    var rentDepositId = _a.rentDepositId, error = _a.error;
    return (__assign(__assign({}, state), { rentDeposits: __assign(__assign({}, state.rentDeposits), (_b = {}, _b[rentDepositId] = __assign(__assign({}, state.rentDeposits[rentDepositId]), { isCancelling: false, cancelError: error }), _b)), isCancellingRentDeposit: false, cancelRentDepositError: error, cancelledRentDepositId: undefined }));
});
export default reducer;
