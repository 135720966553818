var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ProgressBarWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 24px;\n  position: relative;\n  height: 10px;\n  border-radius: 100px;\n  background-color: ", ";\n"], ["\n  margin: 24px;\n  position: relative;\n  height: 10px;\n  border-radius: 100px;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global3; });
export var ProgressBarCompleted = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: ", "%;\n  height: 100%;\n  border-radius: 100px;\n  background-color: ", ";\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: ", "%;\n  height: 100%;\n  border-radius: 100px;\n  background-color: ", ";\n"])), function (props) { return Math.max(3, Math.min(100, props.amount * 100)); }, function (_a) {
    var theme = _a.theme, amount = _a.amount;
    if (amount === 1) {
        return theme.colors.successText;
    }
    return theme.colors.errorText;
});
export var DocumentConfigWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var DocumentConfigTitleWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  margin: 24px 24px 24px 12px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  margin: 24px 24px 24px 12px;\n"])));
export var DocumentConfigIconWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var BrandColorWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 24px 24px 24px 40px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  margin: 24px 24px 24px 40px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n"])));
export var ColorCircle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 28px;\n  height: 28px;\n  border-radius: 100px;\n  border: 1px solid ", ";\n  background-color: ", ";\n  margin-right: 10px;\n"], ["\n  width: 28px;\n  height: 28px;\n  border-radius: 100px;\n  border: 1px solid ", ";\n  background-color: ", ";\n  margin-right: 10px;\n"])), function (props) { return props.theme.colors.global3; }, function (props) { return props.brandColor || props.theme.colors.neutral2; });
export var ChromePickerWrap = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
