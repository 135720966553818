import { IInsurance } from "@rentiohq/shared-frontend/dist/redux/insurance/insurance.types";
import { ACTIVITY_TYPES } from "@rentiohq/shared/dist/types/activity.types";
import {
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import React from "react";
import { ETabs } from "utils/tabs.utils";
import { ts } from "../../../../../../services";

interface IProps {
  setTab: (tab: ETabs) => void;
  insurance: IInsurance;
}

const InsuranceHistory: React.FC<IProps> = props => {
  const { insurance } = props;

  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`insurance-${insurance.id}`}
        filter={{
          where: {
            insuranceId: insurance.id,
            type: ACTIVITY_TYPES.INSURANCE_DASHBOARD,
          },
        }}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};

export default InsuranceHistory;
