import {
  ERentDepositStatus,
  IRentDeposit,
} from "@rentiohq/shared/dist/types/rentDeposit.types";

/**
 * In these statuses, the rental deposit can be cancelled
 * "NEW",
 * "OPEN_TO_SIGN",
 * "PAYING_IN_PARTIALLY_SIGNED",
 * "PAID_IN_PARTIALLY_SIGNED",
 * "PAYING_IN"
 */

export const canCancelRentalDeposit = (rentDeposit: IRentDeposit) => {
  const cancellable = [
    ERentDepositStatus.New,
    ERentDepositStatus.OpenToSign,
    ERentDepositStatus.PayingInPartiallySigned,
    ERentDepositStatus.PaidInPartiallySigned,
    ERentDepositStatus.PayingIn,
  ];

  return cancellable.includes(rentDeposit.status);
};

/**
 * In these statuses, the release of the rental deposit can be cancelled
 * "RELEASE_NEW",
 * "WAITING_FOR_IBAN",
 * "RELEASE_TO_SIGN",
 * "WAITING_FOR_VALIDATIONS
 */
export const canCancelRentalDepositRelease = (rentDeposit: IRentDeposit) => {
  const releaseCancellable = [
    ERentDepositStatus.ReleaseNew,
    ERentDepositStatus.WaitingForIBAN,
    ERentDepositStatus.WaitingForValidations,
    ERentDepositStatus.ReleaseToSign,
    ERentDepositStatus.ManualVerification,
  ];
  return releaseCancellable.includes(rentDeposit.status);
};
