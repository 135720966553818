import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import {
  DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT,
  DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION,
  DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT,
  DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION,
  DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT,
  DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION,
} from "@rentiohq/shared-frontend/dist/redux/count/count.config";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { EContractMemberTypes } from "@rentiohq/shared/dist/types/contract.types";
import { IDocument } from "@rentiohq/shared/dist/types/document.types";
import {
  ERegistrationContractStatus,
  ERegistrationDocumentType,
  IRegistration,
} from "@rentiohq/shared/dist/types/registration.types";
import {
  Card,
  ESpacings,
  Filters,
  Page,
  Registrations as RegistrationsBase,
  Stages,
} from "@rentiohq/web-shared/dist/components";
import { EFilterType } from "@rentiohq/web-shared/dist/components/Filters/Filters.types";
import { MultipleStagesWrapper } from "@rentiohq/web-shared/dist/components/Stages";
import { IAction } from "@rentiohq/web-shared/dist/types";
import {
  renderCellContractRoles,
  renderCellProperty,
  renderCellStatus,
} from "@rentiohq/web-shared/dist/utils/registration";
import { getLocationDescriptionActions } from "components/DetailDrawers/Drawers/Registration/Registration.utils";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { NumberParam, createEnumParam } from "serialize-query-params";
const CONTRACT = "contract";
const LOC_DESCRIPTION = "location_description";

const HEADINGS = [
  getLocalizedText("system.created"),
  getLocalizedText("system.contract_start_date"),
  getLocalizedText("system.address"),
  getLocalizedText("system.tenants"),
  getLocalizedText("system.owners"),
  getLocalizedText("registration.filter.status", {
    type: getLocalizedText(`system.contract`.toLowerCase()).toLowerCase(),
  }),
  getLocalizedText("registration.filter.status", {
    type: getLocalizedText(
      `system.location_description`.toLowerCase(),
    ).toLowerCase(),
  }),
  "",
];

const ORDER_MAP = [
  "createdAt",
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];

const getStatusesFilter = (
  stage: string | undefined,
  type: "contract" | "locationDescription",
): ERegistrationContractStatus[] | undefined => {
  let filter: ERegistrationContractStatus[] | undefined;
  switch (stage) {
    case "in_progress":
      filter =
        type === "contract"
          ? DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT
          : DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION;
      break;
    case "action_required":
      filter =
        type === "contract"
          ? DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT
          : DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION;
      break;
    case "registered":
      filter =
        type === "contract"
          ? DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT
          : DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION;
      break;
    default:
      filter = undefined;
      break;
  }
  return filter;
};

const Registrations: FC<{}> = () => {
  const navigate = useNavigate();

  const [queryParams, setQueryParamValue] = useQueryParams({
    stage: createEnumParam(["in_progress", "action_required", "registered"]),
    registrationId: NumberParam,
  });

  // State
  const [query, setQuery] = useState<string | undefined>();
  const [contractStatuses, setContractStatuses] = useState<
    ERegistrationContractStatus[] | undefined
  >(getStatusesFilter(queryParams.stage, "contract"));
  const [locationDescriptionStatuses, setLocationDescriptionStatuses] =
    useState<ERegistrationContractStatus[] | undefined>(
      getStatusesFilter(queryParams.stage, "locationDescription"),
    );

  const [filter, setFilter] = useState({});

  // Redux
  const dispatch = useDispatch();

  const registrationContractsInProgressCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RegistrationsContractsInProgress,
      ) || 0,
  );

  const registrationContractsActionRequiredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RegistrationsContractsActionRequired,
      ) || 0,
  );

  const registrationContractsRegisteredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RegistrationsContractsRegistered,
      ) || 0,
  );

  const registrationLocationDescriptionInProgressCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RegistrationLocationDescriptionInProgress,
      ) || 0,
  );
  const registrationLocationDescriptionActionRequiredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier
          .RegistrationLocationDescriptionActionRequired,
      ) || 0,
  );
  const registrationLocationDescriptionRegisteredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.RegistrationLocationDescriptionRegistered,
      ) || 0,
  );

  // Data
  const fetchCounts = () => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier:
          countTypes.ECountIdentifier.RegistrationsContractsInProgress,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.RegistrationsContractsInProgress
          ],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier:
          countTypes.ECountIdentifier.RegistrationsContractsActionRequired,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.RegistrationsContractsActionRequired
          ],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier:
          countTypes.ECountIdentifier.RegistrationsContractsRegistered,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.RegistrationsContractsRegistered
          ],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier:
          countTypes.ECountIdentifier.RegistrationLocationDescriptionInProgress,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier
              .RegistrationLocationDescriptionInProgress
          ],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier:
          countTypes.ECountIdentifier
            .RegistrationLocationDescriptionActionRequired,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier
              .RegistrationLocationDescriptionActionRequired
          ],
      }),
    );
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier:
          countTypes.ECountIdentifier.RegistrationLocationDescriptionRegistered,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier
              .RegistrationLocationDescriptionRegistered
          ],
      }),
    );
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  // Set default filters
  const handleApplyContractsInProgressFilter = () => {
    setLocationDescriptionStatuses(undefined);
    setContractStatuses(DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT);
  };

  const handleApplyContractsRegisteredFilter = () => {
    setLocationDescriptionStatuses(undefined);
    setContractStatuses(DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT);
  };

  const handleApplyContractsActionRequiredFilter = () => {
    setLocationDescriptionStatuses(undefined);
    setContractStatuses(DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT);
  };

  const handleApplyLocationDescriptionInProgressFilter = () => {
    setContractStatuses(undefined);
    setLocationDescriptionStatuses(
      DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION,
    );
  };
  const handleApplyLocationDescriptionRegisteredFilter = () => {
    setContractStatuses(undefined);
    setLocationDescriptionStatuses(
      DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION,
    );
  };
  const handleApplyLocationDescriptionActionRequiredFilter = () => {
    setContractStatuses(undefined);
    setLocationDescriptionStatuses(
      DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION,
    );
  };

  useEffect(() => {
    const statuses: { [key: string]: any }[] = [];

    if (contractStatuses) {
      statuses.push({
        rentContractStatus: {
          // TODO: this might have to change to "in"
          inq: contractStatuses,
        },
      });
    }
    if (locationDescriptionStatuses) {
      statuses.push({
        locationDescriptionStatus: {
          // TODO: this might have to change to "in"
          inq: locationDescriptionStatuses,
        },
      });
    }

    const whereStatuses: Record<
      number,
      | undefined
      | { [key: string]: any }[]
      | { [key: string]: { [key: string]: any }[] }
    > = {
      [statuses.length]: { or: statuses },
      1: statuses[0],
      0: undefined,
    };

    setFilter({
      search: query,
      where: whereStatuses[statuses.length],
    });
  }, [query, contractStatuses, locationDescriptionStatuses]);

  // Event handlers
  const handleQueryChange = (value: string) => {
    if (value.length === 0) {
      setQuery(undefined);
      return;
    }

    setQuery(value);
  };

  const handleQueryClear = () => {
    setQuery(undefined);
  };

  const handleClearFilter = () => {
    setQuery(undefined);
    setContractStatuses(undefined);
    setLocationDescriptionStatuses(undefined);
  };

  const handleStatusChange =
    (key: "contract" | "location_description") =>
    (value: ERegistrationContractStatus[] | ERegistrationContractStatus) => {
      if (!Array.isArray(value)) {
        return;
      }

      if (key === CONTRACT) {
        setContractStatuses(value.length > 0 ? value : undefined);
        return;
      }

      if (key === LOC_DESCRIPTION) {
        setLocationDescriptionStatuses(value.length > 0 ? value : undefined);
        return;
      }
    };

  // Render
  const renderFilters = () => {
    const translateStatus = (text: any) =>
      getLocalizedText(
        `registration.status.${stringToSnakeCase(text)}`.toLowerCase(),
      );

    return (
      <Box mb={ESpacings.loose}>
        <Filters
          queryValue={query || ""}
          queryPlaceholder={getLocalizedText(
            "follow_up.registrations.filter.query.placeholder",
          )}
          onQueryChange={handleQueryChange}
          onQueryClear={handleQueryClear}
          onClearAll={handleClearFilter}
          filterConfigs={[
            {
              label: getLocalizedText("registration.filter.status", {
                type: getLocalizedText(
                  `system.${CONTRACT}`.toLowerCase(),
                ).toLowerCase(),
              }),
              groupKey: CONTRACT,
              filters: [
                {
                  type: EFilterType.MultiSelect,
                  values: contractStatuses,
                  options: Object.values(ERegistrationContractStatus).filter(
                    x => x !== ERegistrationContractStatus.None,
                  ),
                  onChange: handleStatusChange(CONTRACT),
                  translate: translateStatus,
                  filterKey: CONTRACT,
                  appliedFiltersPrefix: `${getLocalizedText(
                    "follow_up.registrations.contracts",
                  )}: `,
                  onRemove: key => {
                    if (contractStatuses) {
                      handleStatusChange(CONTRACT)(
                        contractStatuses.filter(status => status !== key),
                      );
                    }
                  },
                },
              ],
            },
            {
              label: getLocalizedText("registration.filter.status", {
                type: getLocalizedText(
                  `system.${LOC_DESCRIPTION}`,
                ).toLowerCase(),
              }),
              groupKey: LOC_DESCRIPTION,
              filters: [
                {
                  type: EFilterType.MultiSelect,
                  values: locationDescriptionStatuses,
                  options: Object.values(ERegistrationContractStatus),
                  onChange: handleStatusChange(LOC_DESCRIPTION),
                  translate: translateStatus,
                  filterKey: LOC_DESCRIPTION,
                  appliedFiltersPrefix: `${getLocalizedText(
                    "follow_up.registrations.location_description",
                  )}: `,
                  onRemove: key => {
                    if (locationDescriptionStatuses) {
                      handleStatusChange(LOC_DESCRIPTION)(
                        locationDescriptionStatuses.filter(
                          status => status !== key,
                        ),
                      );
                    }
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    );
  };

  const renderRowContent = (registration: IRegistration) => [
    formatDate(registration.createdAt),
    registration.contract?.startDate
      ? formatDate(registration.contract?.startDate)
      : "-",
    renderCellProperty(registration.propertyId, registration.property),
    renderCellContractRoles(registration, [
      EContractMemberTypes.Tenant,
      EContractMemberTypes.Parent,
    ]),
    renderCellContractRoles(registration, [EContractMemberTypes.Owner]),
    renderCellStatus(
      registration.rentContractStatus || ERegistrationContractStatus.None,
      // TODO: @Arno type of IRegistrationDocument is the same as IDocument?
      registration.documents[0] as unknown as IDocument,
    ),
    renderCellStatus(
      registration.locationDescriptionStatus ||
        ERegistrationContractStatus.None,
    ),
  ];

  const getRowActions = (registration: IRegistration) => {
    let result: IAction[][] = [];

    result.push([
      {
        id: "view",
        content: getLocalizedText("system.view_detail"),
        onClick: () => {
          setQueryParamValue({ registrationId: registration.id });
        },
      },
    ]);

    result.push(
      getLocationDescriptionActions({
        registration,
        property: registration.property,
        navigate,
        dispatch,
      }),
    );

    if (
      registration.rentContractStatus === ERegistrationContractStatus.Registered
    ) {
      result.push([
        {
          id: "view",
          content: getLocalizedText("registration.addendum.title"),
          onClick: () => {
            navigate(
              `/properties/${registration.propertyId}/contracts/${registration?.contractId}/registration?id=${registration.id}&documentType=${ERegistrationDocumentType.Addendum}`,
            );
          },
        },
      ]);
    }

    return result;
  };

  return (
    <Page
      fullWidth
      title={getLocalizedText("follow_up.registrations.page.title")}
      metadata={getLocalizedText("follow_up.registrations.page.description")}
      breadcrumbs={{
        to: `/`,
        content: getLocalizedText("system.back"),
      }}
    >
      <Card>
        <MultipleStagesWrapper>
          <Stages
            stages={[
              {
                heading: getLocalizedText(
                  "follow_up.registrations.stage.in_progress",
                ),
                countDescription: getLocalizedText("system.entity.contracts"),
                icon: "contentPenWrite",
                count: registrationContractsInProgressCount,
                onClick: handleApplyContractsInProgressFilter,
              },
              {
                heading: getLocalizedText(
                  "follow_up.registration_documents.stage.action_required",
                ),
                countDescription: getLocalizedText("system.entity.contracts"),
                appearance: "warning",
                icon: "contentPenWrite",
                count: registrationContractsActionRequiredCount,
                onClick: handleApplyContractsActionRequiredFilter,
              },

              {
                heading: getLocalizedText(
                  "follow_up.registrations.stage.registered",
                ),
                countDescription: getLocalizedText("system.entity.contracts"),
                icon: "billStack",
                count: registrationContractsRegisteredCount,

                onClick: handleApplyContractsRegisteredFilter,
              },
            ]}
          />
          <Stages
            stages={[
              {
                heading: getLocalizedText(
                  "follow_up.registration_documents.stage.in_progress",
                ),
                countDescription: getLocalizedText(
                  "system.location_description",
                ),
                icon: "contentPenWrite",
                count: registrationLocationDescriptionInProgressCount,
                onClick: handleApplyLocationDescriptionInProgressFilter,
              },
              {
                heading: getLocalizedText(
                  "follow_up.registration_documents.stage.action_required",
                ),
                countDescription: getLocalizedText(
                  "system.location_description",
                ),
                appearance: "warning",
                icon: "contentPenWrite",
                count: registrationLocationDescriptionActionRequiredCount,
                onClick: handleApplyLocationDescriptionActionRequiredFilter,
              },
              {
                heading: getLocalizedText(
                  "follow_up.registration_documents.stage.registered",
                ),
                countDescription: getLocalizedText(
                  "system.location_description",
                ),
                icon: "billStack",
                count: registrationLocationDescriptionRegisteredCount,
                onClick: handleApplyLocationDescriptionRegisteredFilter,
              },
            ]}
          />
        </MultipleStagesWrapper>
      </Card>

      <RegistrationsBase
        filter={filter}
        headings={HEADINGS}
        orderMap={ORDER_MAP}
        columnContentTypes={Array(ORDER_MAP.length).fill("text")}
        renderFilters={renderFilters}
        renderRowContent={renderRowContent}
        getRowActions={getRowActions}
      />
    </Page>
  );
};

export default Registrations;
