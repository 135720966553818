import { endOfDay, isSameDay, startOfDay, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import React from "react";
export var useCachedDates = function () {
    var _a = React.useState(new Date()), nowCached = _a[0], setNowCached = _a[1];
    // Check if the day of the cached date has changed
    React.useEffect(function () {
        var interval /* 1 hour */ = 1000 * 60 * 60;
        var id = setInterval(function () {
            var newNow = new Date();
            if (!isSameDay(nowCached, newNow)) {
                setNowCached(newNow);
            }
        }, interval);
        return function () {
            clearInterval(id);
        };
    }, [nowCached]);
    return {
        nowCached: nowCached,
        startOfDay: startOfDay(nowCached),
        endOfDay: endOfDay(nowCached),
    };
};
