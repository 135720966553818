var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var DocumentIdsContext = React.createContext({
    documentIds: [],
    insertDocumentId: function (newValue) { },
    extraQueryParams: {},
});
// Provider
export var DocumentIdsProvider = function (props) {
    var children = props.children, templateId = props.templateId, templateDocumentId = props.templateDocumentId;
    // State
    var _a = React.useState({
        templateId: templateId,
        templateDocumentId: templateDocumentId,
    }), extraQueryParams = _a[0], setExtraQueryParams = _a[1];
    var _b = React.useState(props.documentIds), documentIds = _b[0], setDocumentIds = _b[1];
    React.useEffect(function () {
        setExtraQueryParams({
            templateId: templateId,
            templateDocumentId: templateDocumentId,
        });
    }, [templateId, templateDocumentId]);
    // Helpers
    var insertDocumentId = function (newValue) {
        setDocumentIds(__spreadArray(__spreadArray([], documentIds, true), [newValue], false));
    };
    // Render
    return (_jsx(DocumentIdsContext.Provider, __assign({ value: {
            documentIds: documentIds,
            insertDocumentId: insertDocumentId,
            extraQueryParams: extraQueryParams,
        } }, { children: children })));
};
