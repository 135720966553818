import { EPaymentMethod, EPaymentOrderOwnerPaymentMethod, EPaymentOrderType, EPaymentRepetitionType, } from "@rentiohq/shared/dist/types/payment.types";
import { round } from "lodash";
import { getProRataDays } from "./schema.createPayment.3";
import { ECreatePaymentMethod, EField } from "./schema.createPayment.types";
import { checkIfTypeIsOther, getFirstPayDateAt, getRelatedContract, getRepetitionDay, getStartDate, hasProRata, isNewContract, isRepetitionDelayed, proRataForContract, shouldAskOwnerPaymentMethod, } from "./schema.createPayment.utils";
export var getPriceType = function (formData, contract) {
    if (!contract) {
        return;
    }
    var type = formData[EField.TypeOnce] || formData[EField.TypeRecurring];
    switch (true) {
        case type === EPaymentOrderType.CommonCosts:
            return contract.priceTypeCommonCosts;
        case type === EPaymentOrderType.NonCommonCosts:
            return contract.priceTypeNonCommonCosts;
        default:
            return;
    }
};
export var getAmount = function (formData) {
    var type = formData[EField.TypeOnce] || formData[EField.TypeRecurring];
    switch (type) {
        case EPaymentOrderType.CommonCosts:
            return formData[EField.PriceCommonCosts] || 0;
        case EPaymentOrderType.NonCommonCosts:
            return formData[EField.PriceNonCommonCosts] || 0;
        default:
            return formData[EField.Price] || 0;
    }
};
export var getVat = function (formData, contract) {
    var type = formData[EField.TypeOnce] || formData[EField.TypeRecurring];
    switch (type) {
        case EPaymentOrderType.Rent:
        case EPaymentOrderType.RentAndCosts:
            if (contract) {
                return contract.priceVat || 0;
            }
            return formData[EField.PriceVat] || 0;
        case EPaymentOrderType.CommonCosts:
            if (contract) {
                return contract.costsCommonVat || 0;
            }
            return formData[EField.PriceCommonCostsVat] || 0;
        case EPaymentOrderType.NonCommonCosts:
            if (contract) {
                return contract.costsNonCommonVat || 0;
            }
            return formData[EField.PriceNonCommonCostsVat] || 0;
        default:
            return formData[EField.PriceVat] || 0;
    }
};
var getPreferredPaymentMethods = function (formData) {
    var createPaymentMethods = formData[EField.PaymentMethods] || [];
    var result = [];
    createPaymentMethods.forEach(function (createPaymentMethod) {
        switch (createPaymentMethod) {
            case ECreatePaymentMethod.Sepa:
                result.push(EPaymentMethod.Sepa);
                break;
            case ECreatePaymentMethod.Online:
                result.push.apply(result, [EPaymentMethod.Payconiq, EPaymentMethod.Ideal]);
                break;
            case ECreatePaymentMethod.Ibanisation:
            default:
                break;
        }
    });
    return result;
};
export var getPaymentOrder = function (formData, propertyId, extraData, paymentOrder) {
    var contract = getRelatedContract(extraData, formData);
    var contractId = contract === null || contract === void 0 ? void 0 : contract.id;
    var type = formData[EField.TypeOnce] || formData[EField.TypeRecurring];
    if (type === EPaymentOrderType.RentAndCosts) {
        type = EPaymentOrderType.Rent;
    }
    var startDate = getStartDate(formData);
    var amount = getAmount(formData);
    var vat = getVat(formData, contract);
    var proRataAmount = 0;
    if (formData[EField.TypeRecurring]) {
        if (hasProRata(formData, contract)) {
            proRataAmount = formData[EField.FirstPrice] || 0;
            if (type === EPaymentOrderType.CommonCosts) {
                proRataAmount = formData[EField.FirstPriceCommonCosts];
            }
            else if (type === EPaymentOrderType.NonCommonCosts) {
                proRataAmount = formData[EField.FirstPriceNonCommonCosts];
            }
            // INFO: When repetition is delayed, subtract amount
            if (isRepetitionDelayed(formData, contract) &&
                !!formData[EField.TypeRecurring]) {
                proRataAmount = proRataAmount - amount;
            }
        }
        // Calculate pro rata for old contracts (Needed for out-going pro-rata at end contract)
        else if (contract && !isNewContract(contract)) {
            proRataAmount = proRataForContract(contract, formData[EField.Price]);
        }
    }
    var repetitionDay = getRepetitionDay(startDate, contract);
    var typePayment = getPriceType(formData, contract);
    var payerId = formData[EField.Payer]
        ? formData[EField.Payer][0]
        : undefined;
    var payeeId = formData[EField.Payee]
        ? formData[EField.Payee][0]
        : undefined;
    var payeeBankaccountId = formData[EField.Iban];
    var otherDescription = checkIfTypeIsOther(formData)
        ? formData[EField.OtherDescription]
        : undefined;
    var repetitionType = EPaymentRepetitionType.Once;
    if (formData[EField.TypeRecurring]) {
        repetitionType = EPaymentRepetitionType.Monthly;
        if (contract === null || contract === void 0 ? void 0 : contract.repetitionType) {
            repetitionType =
                contract.repetitionType;
        }
        if (formData[EField.TypeRecurring] === EPaymentOrderType.OtherRecurring) {
            repetitionType = formData[EField.PaymentRepetition];
        }
    }
    var startedAt = startDate;
    var firstPayDateAt = getFirstPayDateAt({
        repetitionType: repetitionType,
        formData: formData,
        contract: contract,
    });
    var updatePaymentRequests = false;
    if (formData[EField.UpdatePaymentRequests] &&
        formData[EField.UpdatePaymentRequests] === "UPDATE_TRUE") {
        updatePaymentRequests = true;
    }
    var ownerPaymentMethod = EPaymentOrderOwnerPaymentMethod.Direct;
    var preferredPaymentMethods = getPreferredPaymentMethods(formData);
    if (formData[EField.OwnerPaymentMethod]) {
        var shouldAsk = shouldAskOwnerPaymentMethod(formData, extraData);
        if (shouldAsk) {
            if (formData[EField.OwnerPaymentMethod] ===
                EPaymentOrderOwnerPaymentMethod.FromRent) {
                // undefined => don't update preferredPaymentMethods
                preferredPaymentMethods = undefined;
            }
            ownerPaymentMethod = formData[EField.OwnerPaymentMethod];
        }
    }
    var result = {
        amount: amount,
        vat: vat,
        proRataAmount: proRataAmount,
        isRepetitionDelayed: isRepetitionDelayed(formData, contract),
        repetitionDay: repetitionDay,
        repetitionType: repetitionType,
        type: type,
        typePayment: typePayment,
        ownerPaymentMethod: ownerPaymentMethod,
        startedAt: startedAt,
        firstPayDateAt: firstPayDateAt,
        paydateOffset: parseInt(formData[EField.PaydateOffset], 0),
        payerId: payerId,
        payeeBankaccountId: payeeBankaccountId,
        preferredPaymentMethods: preferredPaymentMethods,
        payeeId: payeeId,
        payoutReference: formData[EField.PayoutReference],
        payoutReferenceCustom: formData[EField.PayoutReferenceCustom],
        propertyId: propertyId,
        contractId: contractId,
        otherDescription: otherDescription,
        updatePaymentRequests: updatePaymentRequests,
    };
    if (extraData.hasBookkeeping) {
        result.bookkeepingPeriodStart = formData[EField.BookkeepingPeriodStart];
        result.bookkeepingPeriodEnd = formData[EField.BookkeepingPeriodEnd];
        result.bookkeepingGeneralLedgerAccount =
            formData[EField.BookkeepingGeneralLedgerAccount];
    }
    return result;
};
// INFO: Not recurring
export var getCommissionPaymentOrder = function (formData, propertyId, contractId, contract) {
    if (formData[EField.IncludeCommission] !== "1") {
        return;
    }
    var amount = formData[EField.CommissionPrice] || 0;
    var vat = formData[EField.CommissionVat] || 0;
    var startDate = getStartDate(formData);
    var repetitionDay = getRepetitionDay(startDate, contract);
    var type = EPaymentOrderType.BrokerFee;
    var parentType = formData[EField.TypeOnce] || formData[EField.TypeRecurring];
    if (parentType === EPaymentOrderType.RentAndCosts) {
        parentType = EPaymentOrderType.Rent;
    }
    var typePayment = "FIXED";
    var startedAt = startDate;
    var firstPayDateAt = getFirstPayDateAt({
        repetitionType: EPaymentRepetitionType.Once,
        formData: formData,
        contract: contract,
    });
    var payerId = formData[EField.Payee]
        ? formData[EField.Payee][0]
        : undefined;
    var payeeId = formData[EField.CommissionPayee]
        ? formData[EField.CommissionPayee][0]
        : undefined;
    var payeeBankaccountId = formData[EField.CommissionIban];
    var paymentOrder = {
        amount: amount,
        vat: vat,
        proRataAmount: 0,
        isRepetitionDelayed: isRepetitionDelayed(formData, contract),
        repetitionDay: repetitionDay,
        repetitionType: EPaymentRepetitionType.Once,
        type: type,
        parentType: parentType,
        typePayment: typePayment,
        ownerPaymentMethod: EPaymentOrderOwnerPaymentMethod.FromRent,
        startedAt: startedAt,
        firstPayDateAt: firstPayDateAt,
        paydateOffset: parseInt(formData[EField.PaydateOffset], 0),
        payerId: payerId,
        payeeBankaccountId: payeeBankaccountId,
        preferredPaymentMethods: getPreferredPaymentMethods(formData),
        payeeId: payeeId,
        payoutReference: formData[EField.CommissionPayoutReference],
        payoutReferenceCustom: formData[EField.CommissionPayoutReferenceCustom],
        propertyId: propertyId,
        contractId: contractId,
    };
    return paymentOrder;
};
// INFO: Recurring
export var getManagementFeePaymentOrder = function (formData, propertyId, contractId, contract) {
    if (formData[EField.IncludeManagementFee] !== "1") {
        return;
    }
    var amount = formData[EField.ManagementFeePrice] || 0;
    var vat = formData[EField.ManagementFeeVat] || 0;
    var isIndexable = (formData[EField.ManagementFeeShouldIndex] || "yes") === "yes";
    var startDate = getStartDate(formData);
    var repetitionDay = getRepetitionDay(startDate, contract);
    var type = EPaymentOrderType.ManagementCosts;
    var parentType = formData[EField.TypeOnce] || formData[EField.TypeRecurring];
    if (parentType === EPaymentOrderType.RentAndCosts) {
        parentType = EPaymentOrderType.Rent;
    }
    var typePayment = "FIXED";
    var payerId = formData[EField.Payee]
        ? formData[EField.Payee][0]
        : undefined;
    var payeeId = formData[EField.ManagementFeePayee]
        ? formData[EField.ManagementFeePayee][0]
        : undefined;
    var payeeBankaccountId = formData[EField.ManagementFeeIban];
    var proRataDays = getProRataDays(formData, contract);
    var proRataAmount = 0;
    if (hasProRata(formData, contract)) {
        proRataAmount = round(amount * (proRataDays / 30), 2);
    }
    // Calculate pro rata for old contracts (Needed for out-going pro-rata at end contract)
    else if (contract && !isNewContract(contract)) {
        proRataAmount = proRataForContract(contract, amount);
    }
    var repetitionType = EPaymentRepetitionType.Monthly;
    if (contract === null || contract === void 0 ? void 0 : contract.repetitionType) {
        repetitionType =
            contract.repetitionType;
    }
    var startedAt = startDate;
    var firstPayDateAt = getFirstPayDateAt({
        repetitionType: repetitionType,
        formData: formData,
        contract: contract,
    });
    var paymentOrder = {
        isIndexable: isIndexable,
        amount: amount,
        vat: vat,
        proRataAmount: proRataAmount,
        isRepetitionDelayed: isRepetitionDelayed(formData, contract),
        repetitionDay: repetitionDay,
        repetitionType: repetitionType,
        type: type,
        parentType: parentType,
        typePayment: typePayment,
        ownerPaymentMethod: EPaymentOrderOwnerPaymentMethod.FromRent,
        startedAt: startedAt,
        firstPayDateAt: firstPayDateAt,
        paydateOffset: parseInt(formData[EField.PaydateOffset], 0),
        payerId: payerId,
        payeeBankaccountId: payeeBankaccountId,
        preferredPaymentMethods: getPreferredPaymentMethods(formData),
        payeeId: payeeId,
        payoutReference: formData[EField.ManagementFeePayoutReference],
        payoutReferenceCustom: formData[EField.ManagementFeePayoutReferenceCustom],
        propertyId: propertyId,
        contractId: contractId,
    };
    return paymentOrder;
};
var getNonCommonOrCommonCostsPaymentOrder = function (formData, propertyId, contract, type, typePayment, amount, vat, contractId) {
    var startDate = getStartDate(formData);
    var repetitionDay = getRepetitionDay(startDate, contract);
    var repetitionType = EPaymentRepetitionType.Monthly;
    if (contract === null || contract === void 0 ? void 0 : contract.repetitionType) {
        repetitionType =
            contract.repetitionType;
    }
    var startedAt = startDate;
    var firstPayDateAt = getFirstPayDateAt({
        repetitionType: repetitionType,
        formData: formData,
        contract: contract,
    });
    var proRataAmount = 0;
    if (hasProRata(formData, contract)) {
        if (type === EPaymentOrderType.CommonCosts) {
            proRataAmount = formData[EField.FirstPriceCommonCosts];
        }
        else if (type === EPaymentOrderType.NonCommonCosts) {
            proRataAmount = formData[EField.FirstPriceNonCommonCosts];
        }
        // INFO: When repetition is delayed, subtract amount
        if (isRepetitionDelayed(formData, contract) &&
            !!formData[EField.TypeRecurring]) {
            proRataAmount = proRataAmount - amount;
        }
    }
    // Calculate pro rata for old contracts (Needed for out-going pro-rata at end contract)
    else if (contract && !isNewContract(contract)) {
        proRataAmount = proRataForContract(contract, amount);
    }
    var payerId = formData[EField.Payer]
        ? formData[EField.Payer][0]
        : undefined;
    var payeeId = formData[EField.Payee]
        ? formData[EField.Payee][0]
        : undefined;
    var payeeBankaccountId = formData[EField.Iban];
    var result = {
        amount: amount,
        vat: vat,
        proRataAmount: proRataAmount,
        isRepetitionDelayed: isRepetitionDelayed(formData, contract),
        repetitionDay: repetitionDay,
        repetitionType: repetitionType,
        type: type,
        typePayment: typePayment,
        ownerPaymentMethod: EPaymentOrderOwnerPaymentMethod.Direct,
        startedAt: startedAt,
        firstPayDateAt: firstPayDateAt,
        paydateOffset: parseInt(formData[EField.PaydateOffset], 0),
        payerId: payerId,
        payeeBankaccountId: payeeBankaccountId,
        preferredPaymentMethods: getPreferredPaymentMethods(formData),
        payeeId: payeeId,
        payoutReference: formData[EField.PayoutReference],
        payoutReferenceCustom: formData[EField.PayoutReferenceCustom],
        propertyId: propertyId,
        contractId: contractId,
        otherDescription: undefined,
    };
    return result;
};
export var getCommonCostsPaymentOrder = function (formData, propertyId, contractId, contract) {
    if (!contract ||
        formData[EField.TypeRecurring] !== EPaymentOrderType.RentAndCosts) {
        return;
    }
    var amount = contract.currentCostsCommon || 0;
    if (amount === 0) {
        return;
    }
    var typePayment = contract.priceTypeCommonCosts;
    return getNonCommonOrCommonCostsPaymentOrder(formData, propertyId, contract, EPaymentOrderType.CommonCosts, typePayment, amount, contract.costsCommonVat || 0, contractId);
};
export var getNonCommonCostsPaymentOrder = function (formData, propertyId, contractId, contract) {
    if (!contract ||
        formData[EField.TypeRecurring] !== EPaymentOrderType.RentAndCosts) {
        return;
    }
    var amount = contract.currentCostsNonCommon || 0;
    if (amount === 0) {
        return;
    }
    var typePayment = contract.priceTypeNonCommonCosts;
    return getNonCommonOrCommonCostsPaymentOrder(formData, propertyId, contract, EPaymentOrderType.NonCommonCosts, typePayment, amount, contract.costsNonCommonVat || 0, contractId);
};
