var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n"], ["\n  width: 100%;\n  position: relative;\n"])));
export var InnerWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n"])));
export var ResizeableWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  max-width: 100%;\n  background-color: ", ";\n  width: 200px;\n  height: 130px;\n  ", "\n"], ["\n  position: relative;\n  max-width: 100%;\n  background-color: ", ";\n  width: 200px;\n  height: 130px;\n  ", "\n"])), function (props) {
    if (props.asVariable) {
        return props.isFilledIn ? "palegreen" : "yellow";
    }
    return "transparent";
}, function (props) {
    return props.isPrint
        ? "\n          page-break-inside: avoid;\n        "
        : "\n          display: flex;\n          align-items: center;\n          justify-content: center;\n          text-align: center;\n        ";
});
export var ReferencePrintText = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  -webkit-print-color-adjust: exact;\n  color: white;\n"], ["\n  -webkit-print-color-adjust: exact;\n  color: white;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
