var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EContractCostsPriceType } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyTypeId } from "@rentiohq/shared/dist/types/property.types";
import { addYears, startOfMonth, subDays, subMonths, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { formatCurrency, getAmountWithVat, } from "@rentiohq/shared/dist/utils/number.utils";
import { round } from "lodash";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createContract.types";
import { getCurrentPriceTypeValue, getNavigationTitle, isExistingContract, isNewContract, } from "./schema.createContract.utils";
import { getVatFieldsForSchema, getVatFieldsForUiSchema, } from "./schema.createContract.utils.vat";
var NOW = new Date();
// TODO: On blur bugs
// const showLastIndexedOn = (formData: any) => {
//   // Hide if new contract
//   if (!isExistingContract(formData)) {
//     return false;
//   }
//   // Hide if current & initial price matches
//   if (formData[EField.PriceCurrent] === formData[EField.PriceInitial]) {
//     return false;
//   }
//   return true;
// };
var getOffsetValueForIndex = function (index) {
    var normalizedIndex = typeof index === "string" ? parseInt(index, 0) : index;
    return getLocalizedText("contract.discount.offset.value", {
        days: "".concat(Math.abs(normalizedIndex)),
    }, Math.abs(normalizedIndex));
};
var getDiscountOffsetDropdownOptions = function () {
    var options = [];
    for (var index = 1; index <= 14; index++) {
        options.push({
            id: "".concat(index),
            value: getOffsetValueForIndex("".concat(index)),
        });
    }
    return options;
};
export var getSchema = function (initialValues, _a) {
    var property = _a.property, discountEnabled = _a.discountEnabled, contract = _a.contract;
    return function (defaultValues) {
        var _a, _b, _c, _d, _e;
        return ({
            required: [EField.PriceCurrent],
            properties: __assign(__assign(__assign(__assign(__assign(__assign(__assign((_a = {}, _a[EField.Header + "6"] = {
                default: undefined,
            }, _a[EField.PriceCurrent] = {
                default: getValue(EField.PriceCurrent, initialValues, defaultValues),
                minimum: 0,
            }, _a), getVatFieldsForSchema({
                initialValues: initialValues,
                defaultValues: defaultValues,
                fieldKey: EField.PriceCurrentVat,
                infoFieldKey: EField.PriceCurrentVatHelpInfo,
                baseAmountFieldKey: EField.PriceCurrent,
                getForcedValue: function (params) {
                    var isExisting = isExistingContract(defaultValues);
                    var key = isExisting
                        ? EField.PriceInitialVat
                        : EField.PriceCurrentVat;
                    return key in defaultValues ? defaultValues[key] : initialValues[key];
                },
            })), (discountEnabled
                ? (_b = {},
                    _b[EField.HasDiscount] = {
                        default: getValue(EField.HasDiscount, initialValues, defaultValues),
                        // @ts-ignore
                        options: ["no", "yes"].map(function (id) { return ({
                            id: id,
                            value: getLocalizedText("system.".concat(id.toLowerCase())),
                        }); }),
                        showIf: function (formData) { return discountEnabled; },
                    },
                    _b[EField.DiscountAmount] = {
                        default: getValue(EField.DiscountAmount, initialValues, defaultValues),
                        minimum: 0,
                        // @ts-ignore
                        // getMaximum: (formData: any) => {
                        //   return formData[EField.PriceCurrent] || 1;
                        // },
                        // @ts-ignore
                        requiredIf: function (formData) {
                            return formData[EField.HasDiscount] === "yes";
                        },
                        showIf: function (formData) { return formData[EField.HasDiscount] === "yes"; },
                    },
                    _b[EField.DiscountOffset] = {
                        default: getValue(EField.DiscountOffset, initialValues, defaultValues),
                        // @ts-ignore
                        options: getDiscountOffsetDropdownOptions(),
                        // @ts-ignore
                        requiredIf: function (formData) { return formData[EField.HasDiscount] === "yes"; },
                        showIf: function (formData) { return formData[EField.HasDiscount] === "yes"; },
                    },
                    _b[EField.DiscountStartDate] = {
                        default: getValue(EField.DiscountStartDate, initialValues, defaultValues),
                        showIf: function (formData) { return formData[EField.HasDiscount] === "yes"; },
                    },
                    _b) : {})), (_c = {}, _c[EField.CostsCommonCurrent] = {
                default: getValue(EField.CostsCommonCurrent, initialValues, defaultValues),
                minimum: 0,
                // @ts-ignore
                requiredIf: function (formData) { return !!formData[EField.CostsCommonInitial]; },
            }, _c[EField.PriceTypeCommonCostsCurrent] = {
                default: getCurrentPriceTypeValue(EField.PriceTypeCommonCostsCurrent, EField.PriceTypeCommonCostsInitial, initialValues, defaultValues),
                // @ts-ignore
                options: [
                    EContractCostsPriceType.Fixed,
                    EContractCostsPriceType.Provision,
                ].map(function (id) { return ({
                    id: id,
                    value: getLocalizedText("contract_info.costs.price_type.value.".concat(id.toLowerCase())),
                }); }),
            }, _c)), getVatFieldsForSchema({
                initialValues: initialValues,
                defaultValues: defaultValues,
                fieldKey: EField.CommonCostsCurrentVat,
                infoFieldKey: EField.CommonCostsCurrentVatHelpInfo,
                baseAmountFieldKey: EField.CostsCommonCurrent,
                getForcedValue: function (params) {
                    var isExisting = isExistingContract(defaultValues);
                    var key = isExisting
                        ? EField.CommonCostsInitialVat
                        : EField.CommonCostsCurrentVat;
                    return key in defaultValues ? defaultValues[key] : initialValues[key];
                },
            })), (_d = {}, _d[EField.CostsNonCommonCurrent] = {
                default: getValue(EField.CostsNonCommonCurrent, initialValues, defaultValues),
                minimum: 0,
                // @ts-ignore
                requiredIf: function (formData) { return !!formData[EField.CostsNonCommonInitial]; },
            }, _d[EField.PriceTypeNonCommonCostsCurrent] = {
                default: getCurrentPriceTypeValue(EField.PriceTypeNonCommonCostsCurrent, EField.PriceTypeNonCommonCostsInitial, initialValues, defaultValues),
                // @ts-ignore
                options: [
                    EContractCostsPriceType.Fixed,
                    EContractCostsPriceType.Provision,
                ].map(function (id) { return ({
                    id: id,
                    value: getLocalizedText("contract_info.costs.price_type.value.".concat(id.toLowerCase())),
                }); }),
            }, _d)), getVatFieldsForSchema({
                initialValues: initialValues,
                defaultValues: defaultValues,
                fieldKey: EField.NonCommonCostsCurrentVat,
                infoFieldKey: EField.NonCommonCostsCurrentVatHelpInfo,
                baseAmountFieldKey: EField.CostsNonCommonCurrent,
                getForcedValue: function (params) {
                    var isExisting = isExistingContract(defaultValues);
                    var key = isExisting
                        ? EField.NonCommonCostsInitialVat
                        : EField.NonCommonCostsCurrentVat;
                    return key in defaultValues ? defaultValues[key] : initialValues[key];
                },
            })), (_e = {}, _e[EField.LastIndexedOn] = {
                default: getValue(EField.LastIndexedOn, initialValues, defaultValues),
                // @ts-ignore
                showIf: isExistingContract,
            }, _e[EField.IndexInitial2] = {
                default: (function () {
                    var value = getValue(EField.IndexInitial2, initialValues, defaultValues);
                    if (!value && defaultValues.startDate) {
                        return subMonths(defaultValues.startDate, 1);
                    }
                    return value;
                })(),
                // @ts-ignore
                showIf: isNewContract,
                // @ts-ignore
                requiredIf: isNewContract,
            }, _e[EField.RentDepositAmountPrefill] = {
                default: getValue(EField.RentDepositAmountPrefill, initialValues, defaultValues),
                // @ts-ignore
                options: [1, 2, 3, 0].map(function (id) { return ({
                    id: id,
                    value: getLocalizedText("contract_info.rent_deposit_prefill.value", { value: "".concat(id) }, id),
                }); }),
                showIf: function (formData) { return !contract; },
            }, _e[EField.RentDepositAmountPrefillPreview] = {
                default: getValue(EField.RentDepositAmountPrefillPreview, initialValues, defaultValues),
                // @ts-ignore
                handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                    if (fieldName === EField.PriceCurrent ||
                        fieldName === EField.PriceCurrentVat ||
                        fieldName === EField.RentDepositAmountPrefill) {
                        var price = fieldName === EField.PriceCurrent
                            ? fieldValue
                            : formData[EField.PriceCurrent] || 0;
                        var vat = fieldName === EField.PriceCurrentVat
                            ? fieldValue
                            : formData[EField.PriceCurrentVat] || 0;
                        var rentDepositAmountPrefill = fieldName === EField.RentDepositAmountPrefill
                            ? fieldValue
                            : formData[EField.RentDepositAmountPrefill] || -1;
                        if (price === 0 || rentDepositAmountPrefill === -1) {
                            return { shouldSetNewValue: false };
                        }
                        return {
                            shouldSetNewValue: true,
                            newValue: round(getAmountWithVat(price, vat / 100) * rentDepositAmountPrefill, 2),
                        };
                    }
                    return { shouldSetNewValue: false };
                },
                showIf: function (formData) {
                    return !contract && formData[EField.RentDepositAmountPrefill] !== 0;
                },
                requiredIf: function (formData) {
                    return !contract && formData[EField.RentDepositAmountPrefill] !== 0;
                },
            }, _e[EField.RentDepositAmount] = {
                default: getValue(EField.RentDepositAmount, initialValues, defaultValues),
                exclusiveMinimum: 0,
                // @ts-ignore
                getMaximum: function (formData) {
                    var maxMonths;
                    switch (property.typeId) {
                        case EPropertyTypeId.Office:
                            maxMonths = undefined;
                            break;
                        default:
                            maxMonths = 3;
                            break;
                    }
                    if (!maxMonths) {
                        return undefined;
                    }
                    return (formData[EField.PriceCurrent] || 0) * maxMonths;
                },
                showIf: function (formData) {
                    return contract || formData[EField.RentDepositAmountPrefill] === 0;
                },
                // requiredIf: (formData: any) =>
                //   formData[EField.RentDepositAmountPrefill] === 0,
            }, _e)),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a, _b, _c, _d, _e;
    var EARLIEST_BASE_YEAR = extraData.baseYears[extraData.baseYears.length - 1];
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign((_a = { "rentio:title": getLocalizedText("contract_info.add.current_data.heading.summary"), "rentio:navigationTitle": getNavigationTitle(extraData), "ui:field": ERentioFormField.Grid, grid: {
                row: __spreadArray(__spreadArray([
                    {
                        col: {
                            width: 1,
                            children: [EField.Header + "6"],
                        },
                    },
                    {
                        col: {
                            width: [1, 5 / 12],
                            children: [EField.PriceCurrent],
                        },
                    },
                    {
                        col: {
                            width: [1, 3 / 12],
                            children: [EField.PriceCurrentVat],
                        },
                    },
                    {
                        col: {
                            width: [1, 4 / 12],
                            children: [],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.PriceCurrentVatHelpInfo],
                        },
                    }
                ], (extraData.discountEnabled
                    ? [
                        {
                            col: {
                                width: 1,
                                children: [EField.HasDiscount],
                            },
                        },
                        {
                            col: {
                                width: [1, 1 / 2, 1 / 2],
                                children: [EField.DiscountAmount],
                            },
                        },
                        {
                            col: {
                                width: [1, 1 / 2, 1 / 2],
                                children: [EField.DiscountOffset],
                            },
                        },
                        {
                            col: {
                                width: 1,
                                children: [EField.DiscountStartDate],
                            },
                        },
                    ]
                    : []), true), [
                    {
                        col: {
                            width: [1, 5 / 12],
                            children: [EField.CostsCommonCurrent],
                        },
                    },
                    {
                        col: {
                            width: [1, 3 / 12],
                            children: [EField.CommonCostsCurrentVat],
                        },
                    },
                    {
                        col: {
                            width: [1, 4 / 12],
                            children: [EField.PriceTypeCommonCostsCurrent],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.CommonCostsCurrentVatHelpInfo],
                        },
                    },
                    {
                        col: {
                            width: [1, 5 / 12],
                            children: [EField.CostsNonCommonCurrent],
                        },
                    },
                    {
                        col: {
                            width: [1, 3 / 12],
                            children: [EField.NonCommonCostsCurrentVat],
                        },
                    },
                    {
                        col: {
                            width: [1, 4 / 12],
                            children: [EField.PriceTypeNonCommonCostsCurrent],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.NonCommonCostsCurrentVatHelpInfo],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.LastIndexedOn, EField.IndexInitial2],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.RentDepositAmountPrefill],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [
                                EField.RentDepositAmount,
                                EField.RentDepositAmountPrefillPreview,
                            ],
                        },
                    },
                ], false),
            } }, _a[EField.Header + "6"] = {
        "ui:field": ERentioFormField.SectionHeader,
        title: getLocalizedText("contract_info.add.current_data.heading"),
    }, _a[EField.PriceCurrent] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("contract.current_price.label"),
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        autoFocus: true,
    }, _a), getVatFieldsForUiSchema({
        fieldKey: EField.PriceCurrentVat,
        infoFieldKey: EField.PriceCurrentVatHelpInfo,
        baseAmountFieldKey: EField.PriceCurrent,
        isDisabled: function (formData) { return !isNewContract(formData); },
    })), (extraData.discountEnabled
        ? (_b = {},
            _b[EField.HasDiscount] = {
                "ui:field": ERentioFormField.SegmentedControl,
                title: getLocalizedText("contract.discount.enabled.label"),
                extraInfoUrl: "contract.discount.enabled.url",
                formatSummary: function (formData) {
                    return formData && getLocalizedText("system.".concat(formData.toLowerCase()));
                },
                shouldShowOnSummary: function (formData) {
                    return formData[EField.HasDiscount] === "no";
                },
            },
            _b[EField.DiscountAmount] = {
                "ui:field": ERentioFormField.Number,
                formatSummary: function (formData) {
                    return formData && formatCurrency(formData);
                },
                title: getLocalizedText("contract.discount.amount.label"),
                getInfo: function () { return getLocalizedText("contract.discount.amount.info"); },
                placeholder: "0",
                suffixText: "EUR",
                isFloat: true,
                hideOnSummaryIfEmpty: true,
            },
            _b[EField.DiscountOffset] = {
                "ui:field": ERentioFormField.DropdownSelect,
                title: getLocalizedText("contract.discount.offset.label"),
                getInfo: function () { return getLocalizedText("contract.discount.offset.info"); },
                formatSummary: function (formData) {
                    return formData && getOffsetValueForIndex(formData);
                },
                hideOnSummaryIfEmpty: true,
            },
            _b[EField.DiscountStartDate] = {
                "ui:field": ERentioFormField.Date,
                title: getLocalizedText("contract.discount.start_date.label"),
                getInfo: function () {
                    return getLocalizedText("contract.discount.start_date.info");
                },
                getMinDate: function (formData) { return formData[EField.StartDate]; },
                formatSummary: function (input) { return (input ? formatDate(input) : ""); },
                hideOnSummaryIfEmpty: true,
                optional: true,
            },
            _b) : {})), (_c = {}, _c[EField.CostsCommonCurrent] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("rental.contract.current_costs.common"),
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        optional: true,
    }, _c)), getVatFieldsForUiSchema({
        fieldKey: EField.CommonCostsCurrentVat,
        infoFieldKey: EField.CommonCostsCurrentVatHelpInfo,
        baseAmountFieldKey: EField.CostsCommonCurrent,
        isDisabled: function (formData) { return !isNewContract(formData); },
    })), (_d = {}, _d[EField.PriceTypeCommonCostsCurrent] = {
        "ui:field": ERentioFormField.SegmentedControl,
        title: getLocalizedText("contract_info.common_costs.price_type.label"),
        extraInfoUrl: "contract_info.price_type.url",
        isDisabled: function (formData) {
            return !isNewContract(formData) && !!formData[EField.CostsCommonInitial];
        },
        formatSummary: function (formData) {
            return formData &&
                getLocalizedText("contract_info.costs.price_type.value.".concat(formData.toLowerCase()));
        },
        shouldShowOnSummary: function (formData) {
            return !!formData[EField.CostsCommonCurrent];
        },
    }, _d[EField.CostsNonCommonCurrent] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("rental.contract.current_costs.non_common"),
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        optional: true,
    }, _d)), getVatFieldsForUiSchema({
        fieldKey: EField.NonCommonCostsCurrentVat,
        infoFieldKey: EField.NonCommonCostsCurrentVatHelpInfo,
        baseAmountFieldKey: EField.CostsNonCommonCurrent,
        isDisabled: function (formData) { return !isNewContract(formData); },
    })), (_e = {}, _e[EField.PriceTypeNonCommonCostsCurrent] = {
        "ui:field": ERentioFormField.SegmentedControl,
        title: getLocalizedText("contract_info.non_common_costs.price_type.label"),
        extraInfoUrl: "contract_info.price_type.url",
        formatSummary: function (formData) {
            return formData &&
                getLocalizedText("contract_info.costs.price_type.value.".concat(formData.toLowerCase()));
        },
        isDisabled: function (formData) {
            return !isNewContract(formData) && !!formData[EField.CostsNonCommonInitial];
        },
        shouldShowOnSummary: function (formData) {
            return !!formData[EField.CostsNonCommonCurrent];
        },
    }, _e[EField.LastIndexedOn] = {
        "ui:field": ERentioFormField.Date,
        formatSummary: function (input) { return (input ? formatDate(input) : ""); },
        title: getLocalizedText("contract.last_indexed_at_form.label"),
        getMinDate: function (formData) { return addYears(formData[EField.StartDate], 1); },
        maxDate: NOW,
        shouldShowOnSummary: isExistingContract,
        optional: true,
    }, _e[EField.IndexInitial2] = {
        "ui:field": ERentioFormField.Date,
        formatSummary: function (input) {
            return input ? formatDate(input, "M/yyyy") : "";
        },
        title: getLocalizedText("contract.current_index.label"),
        dateFormat: "M/yyyy",
        showMonthYearPicker: true,
        minDate: new Date(EARLIEST_BASE_YEAR, 0, 1),
        getMaxDate: function (formData) {
            return subDays(startOfMonth(formData[EField.StartDate]), 1);
        },
        inverseYears: true,
        extraInfoUrl: "contract.index_initial.url",
    }, _e[EField.RentDepositAmountPrefill] = {
        "ui:field": ERentioFormField.SegmentedControl,
        title: getLocalizedText("contract_info.rent_deposit_with_prefill.label"),
        shouldShowOnSummary: function (formData) { return false; },
        size: "small",
    }, _e[EField.RentDepositAmountPrefillPreview] = {
        "ui:field": ERentioFormField.Number,
        summaryTitle: getLocalizedText("contract_info.rent_deposit.label"),
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        placeholder: "0",
        suffixText: "EUR",
        isDisabled: function () { return true; },
        isFloat: true,
    }, _e[EField.RentDepositAmount] = {
        "ui:field": ERentioFormField.Number,
        title: extraData.contract
            ? getLocalizedText("contract_info.rent_deposit_with_prefill.label")
            : undefined,
        summaryTitle: getLocalizedText("contract_info.rent_deposit.label"),
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
    }, _e));
};
export var getValidate = function (_) { return function (formData, errors) {
    if (formData[EField.CostsCommonCurrent] > 0 &&
        isNewContract(formData) &&
        !formData[EField.PriceTypeCommonCostsCurrent]) {
        errors[EField.PriceTypeCommonCostsCurrent].addError(getLocalizedText("contract_info.price_type.required"));
    }
    if (formData[EField.CostsNonCommonCurrent] > 0 &&
        isNewContract(formData) &&
        !formData[EField.PriceTypeNonCommonCostsCurrent]) {
        errors[EField.PriceTypeNonCommonCostsCurrent].addError(getLocalizedText("contract_info.price_type.required"));
    }
    return errors;
}; };
