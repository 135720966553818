export var defaultContactSchema = {
    title: "",
    type: "object",
    required: ["firstname", "lastname", "email", "phone", "locale"],
    properties: {
        firstname: {
            type: "string",
            minLength: 1,
            title: "system.firstname",
            ui: { "ui:autofocus": true },
        },
        lastname: { type: "string", minLength: 1, title: "system.lastname" },
        email: {
            type: "string",
            format: "email",
            minLength: 1,
            title: "system.email",
        },
        phone: {
            type: "string",
            minLength: 1,
            title: "system.mobile_number",
            ui: { "ui:widget": "phone" },
            validPhone: true,
        },
        locale: {
            type: "string",
            title: "contact.locale.label",
            default: "nl_BE",
            action: "GET_ALL_LOCALES",
            ui: { "ui:field": "asyncSelect" },
        },
        address: {
            title: "address.full_address.label",
            type: "object",
            ui: {
                "ui:field": "address",
            },
            properties: {
                street: {
                    type: ["string", "null"],
                    title: "address.street.label",
                },
                number: {
                    type: ["string", "null"],
                    title: "address.number.label",
                },
                box: {
                    type: ["string", "null"],
                    title: "address.box.label",
                },
                zip: {
                    type: ["string", "null"],
                    title: "address.zip.label",
                },
                city: {
                    type: ["string", "null"],
                    title: "address.city.label",
                },
                state: {
                    type: ["string", "null"],
                    title: "address.state.label",
                },
                countryId: {
                    title: "address.country.label",
                    type: ["string", "null"],
                    action: "GET_ALL_COUNTRY_ISO",
                    ui: { "ui:field": "asyncSelect" },
                },
            },
        },
    },
};
export var defaultContactExtraSchema = {
    title: "",
    type: "object",
    properties: {
        dateOfBirth: {
            title: "system.date_of_birth",
            type: ["string", "null"],
            ui: {
                "ui:widget": "date",
                "ui:options": {
                    minDate: "years:-".concat(120 + 16),
                    maxDate: "years:-".concat(16),
                },
            },
        },
        dateAndPlaceOfBirth: {
            title: "system.place_of_birth",
            type: "object",
            ui: {
                "ui:field": "address",
                "ui:options": {
                    types: ["(cities)"],
                    showForm: false,
                },
            },
            properties: {
                city: {
                    type: "string",
                    title: "address.city.label",
                },
                countryId: {
                    title: "address.country.label",
                    type: "string",
                    action: "GET_ALL_COUNTRY_ISO",
                    ui: { "ui:field": "asyncSelect" },
                },
            },
        },
    },
};
export var defaultCompanyWithContactSchema = {
    title: "",
    type: "object",
    required: ["company", "phone", "email", "locale"],
    properties: {
        company: {
            type: "string",
            minLength: 1,
            title: "contact.company.name.label",
            ui: { "ui:autofocus": true },
        },
        VATNumber: {
            type: ["string", "null"],
            minLength: 1,
            title: "contact.company.vat_number.label",
        },
        address: {
            title: "address.full_address.label",
            type: "object",
            ui: {
                "ui:field": "address",
            },
            required: ["street", "number", "zip", "city", "countryId"],
            properties: {
                street: {
                    type: "string",
                    title: "address.street.label",
                },
                number: {
                    type: "string",
                    title: "address.number.label",
                },
                box: {
                    type: ["string", "null"],
                    title: "address.box.label",
                },
                zip: {
                    type: "string",
                    title: "address.zip.label",
                },
                city: {
                    type: "string",
                    title: "address.city.label",
                },
                countryId: {
                    title: "address.country.label",
                    type: "string",
                    action: "GET_ALL_COUNTRY_ISO",
                    ui: { "ui:field": "asyncSelect" },
                },
            },
        },
        personal: {
            type: "string",
            title: "contact.company.contact_data.legend",
            ui: {
                "ui:field": "subtitle",
            },
        },
        firstname: { type: "string", minLength: 1, title: "system.firstname" },
        lastname: {
            type: "string",
            minLength: 1,
            title: "system.lastname",
            ui: {
                "ui:options": {
                    contactFields: ["firstname", "lastname", "email", "phone"],
                },
            },
        },
        email: {
            type: "string",
            format: "email",
            minLength: 1,
            title: "system.email",
            ui: {
                "ui:options": {
                    contactFields: ["firstname", "lastname", "email", "phone"],
                },
            },
        },
        phone: {
            type: "string",
            minLength: 1,
            title: "system.mobile_number",
            validPhone: true,
            ui: {
                "ui:widget": "phone",
                "ui:options": {
                    contactFields: ["firstname", "lastname", "email", "phone"],
                },
            },
        },
        locale: {
            type: "string",
            title: "contact.locale.label",
            default: "nl_BE",
            action: "GET_ALL_LOCALES",
            ui: { "ui:field": "asyncSelect" },
        },
    },
};
