import { ACTIVITY_TYPES, } from "@rentiohq/shared/dist/types/activity.types";
import isEmpty from "ramda/es/isEmpty";
import isNil from "ramda/es/isNil";
export var getActivityIcon = function (_a) {
    var type = _a.type;
    switch (type) {
        case ACTIVITY_TYPES.CHAT:
            return "chat";
        case ACTIVITY_TYPES.CONTACT:
            return "contacts";
        case ACTIVITY_TYPES.CONTRACT:
            return "leaseContract";
        case ACTIVITY_TYPES.PAYMENT:
        case ACTIVITY_TYPES.BILLING:
            return "billStack";
        case ACTIVITY_TYPES.PROPERTY:
            return "properties";
        case ACTIVITY_TYPES.TASK:
            return "checklist";
        case ACTIVITY_TYPES.USER:
            return "profile";
        case ACTIVITY_TYPES.DOCUMENT:
        case ACTIVITY_TYPES.DOCUMENT_PACKAGE:
        case ACTIVITY_TYPES.TEMPLATE_DOCUMENT:
        case ACTIVITY_TYPES.RENT_REGISTRATION:
        case ACTIVITY_TYPES.EXPORT_FILE:
            return "documents";
        case ACTIVITY_TYPES.REGISTERED_MAIL:
            return "email";
        case ACTIVITY_TYPES.RENT_DEPOSIT:
            return "rentDeposit";
        case ACTIVITY_TYPES.INSURANCE_DASHBOARD:
        case ACTIVITY_TYPES.LEGAL_CASE:
            return "rentDeposit";
        default:
            return "questionMark";
    }
};
export var getHasActiveFilter = function (filter) {
    var hasQuery = !isEmpty(filter.activityQuery);
    var hasType = !isEmpty(filter.activityType) && !isNil(filter.activityType);
    var hasFilter = [hasQuery, hasType];
    return hasFilter.filter(Boolean).length > 0;
};
