var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as documentApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { logError } from "@rentiohq/shared/dist/logger/logger";
import React from "react";
import { useDropzone } from "react-dropzone";
import { DocumentFetchListItem, Dropzone, } from "../../../../../../../components";
import { ts } from "../../../../../../../services";
import { showDropErrors } from "../../../../../../../utils/file";
import ToolbarButton from "../../../../ToolbarButton";
import { DocumentIdsContext } from "../../../contexts/DocumentIds.context";
import { FieldsContext } from "../../../contexts/Fields.context";
import * as S from "./AtomicBlockImage.styles";
import { EAtomicBlockImageAlignment, } from "./AtomicBlockImage.types";
var AtomicBlockImage = function (props) {
    var contentState = props.contentState, blockProps = props.blockProps;
    var entityKey = blockProps.entityKey;
    var entity = contentState.getEntity(entityKey);
    var entityData = entity.getData();
    // Refs
    var ref = React.useRef();
    // State
    var _a = React.useState(false), isUploading = _a[0], setIsUploading = _a[1];
    var _b = React.useState(), enableDrag = _b[0], setEnableDrag = _b[1];
    var _c = React.useState(entityData.width || 200), width = _c[0], setWidth = _c[1];
    var _d = React.useState(entityData.alignment || EAtomicBlockImageAlignment.Left), alignment = _d[0], setAlignment = _d[1];
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var insertDocumentId = React.useContext(DocumentIdsContext).insertDocumentId;
    // Data
    var uploadFile = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var preSignedUrl, document_1, unknownError_1, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsUploading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, documentApi.uploadDocumentToS3(file)];
                case 2:
                    preSignedUrl = _a.sent();
                    return [4 /*yield*/, documentApi.uploadDocument(preSignedUrl, {
                            filename: "template-builder-".concat(new Date().getTime()),
                            categoryId: 1,
                        })];
                case 3:
                    document_1 = _a.sent();
                    insertDocumentId(document_1.id);
                    contentState.mergeEntityData(entityKey, {
                        width: width,
                        documentId: document_1.id,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    unknownError_1 = _a.sent();
                    error = unknownError_1;
                    logError({ error: error });
                    return [3 /*break*/, 5];
                case 5:
                    setIsUploading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Drag & drop
    var handleDrop = function (acceptedFiles, fileRejections, event) {
        showDropErrors({ acceptedFiles: acceptedFiles, fileRejections: fileRejections, event: event });
        if (acceptedFiles.length === 0) {
            return;
        }
        var file = acceptedFiles[0];
        if (!file) {
            return;
        }
        uploadFile(file);
    };
    var _e = useDropzone({
        onDrop: handleDrop,
    }), getRootProps = _e.getRootProps, getInputProps = _e.getInputProps, isDragActive = _e.isDragActive;
    // Event handlers
    var handleMouseDown = function (direction) { return function () {
        setEnableDrag(direction);
    }; };
    var handleMouseMove = function (e) {
        if (enableDrag === "left") {
            setWidth(width - e.movementX);
            return;
        }
        if (enableDrag === "right") {
            setWidth(width + e.movementX);
        }
    };
    var handleEndDrag = function () {
        if (!enableDrag) {
            return;
        }
        setEnableDrag(undefined);
        contentState.mergeEntityData(entityKey, {
            width: width,
        });
    };
    var handleAlignmentChange = function (alignment) {
        setAlignment(alignment);
        contentState.mergeEntityData(entityKey, {
            alignment: alignment,
        });
    };
    // Render
    var renderLoading = function () {
        return (_jsx(S.DropzoneWrap, { children: _jsx(Dropzone.Dropzone, __assign({ isDraggingOver: false }, { children: _jsx(S.FullActivityIndicatorWrap, { children: _jsx(S.FullActivityIndicator, {}) }) })) }));
    };
    var iconSource = (function () {
        switch (alignment) {
            case EAtomicBlockImageAlignment.Left:
                return "icoEditorAlignObjectLeft";
            case EAtomicBlockImageAlignment.Center:
                return "icoEditorAlignObjectCenter";
            case EAtomicBlockImageAlignment.Right:
                return "icoEditorAlignObjectRight";
            default:
                return;
        }
    })();
    var renderAsImage = function () {
        if (entityData.variableId) {
            return null;
        }
        if (entityData.documentId) {
            return (_jsx(DocumentFetchListItem, { documentId: entityData.documentId, renderLoading: renderLoading, renderDocument: function (document) { return (_jsxs(S.InnerWrap, __assign({ alignment: alignment }, { children: [_jsxs(S.ResizeableWrap, __assign({ style: { width: width } }, { children: [_jsx(S.Image, { draggable: false, src: document.url }), alignment !== EAtomicBlockImageAlignment.Left && (_jsx(S.ResizeableHandlerLeft, { ref: ref, onMouseDown: handleMouseDown("left"), onMouseUp: handleEndDrag })), alignment !== EAtomicBlockImageAlignment.Right && (_jsx(S.ResizeableHandlerRight, { ref: ref, onMouseDown: handleMouseDown("right"), onMouseUp: handleEndDrag }))] })), _jsx(S.ToolbarWrap, { children: _jsx(S.ToolbarInnerWrap, { children: Object.values(EAtomicBlockImageAlignment).map(function (alignment) { return (_jsx(ToolbarButton, { iconSource: iconSource, onClick: function () {
                                        handleAlignmentChange(alignment);
                                    } })); }) }) })] }))); } }));
        }
        // Dropzone
        if (isUploading) {
            return renderLoading();
        }
        return (_jsx(S.DropzoneWrap, { children: _jsxs(Dropzone.Dropzone, __assign({}, getRootProps(), { isDraggingOver: isDragActive }, { children: [_jsx("input", __assign({}, getInputProps())), _jsx(Dropzone.DropzonePlaceholder, { children: ts.fileDropZonePlaceholder() })] })) }));
    };
    var renderAsVariable = function () {
        if (!entityData.variableId) {
            return null;
        }
        var variable = fields.variables.find(function (x) { return x.id === entityData.variableId; });
        if (!variable) {
            return null;
        }
        var group = fields.groups.find(function (x) { return x.id === variable.groupId; });
        if (!group) {
            return;
        }
        return (_jsxs(S.InnerWrap, __assign({ alignment: alignment }, { children: [_jsxs(S.ResizeableWrap, __assign({ style: { width: width, height: 120 }, asVariable: true }, { children: [_jsx(S.ImageVariablePlaceholder, { children: "".concat(getLocalizedText(variable.name), " (").concat(getLocalizedText(group.name), ")") }), alignment !== EAtomicBlockImageAlignment.Left && (_jsx(S.ResizeableHandlerLeft, { ref: ref, onMouseDown: handleMouseDown("left"), onMouseUp: handleEndDrag })), alignment !== EAtomicBlockImageAlignment.Right && (_jsx(S.ResizeableHandlerRight, { ref: ref, onMouseDown: handleMouseDown("right"), onMouseUp: handleEndDrag }))] })), _jsx(S.ToolbarWrap, { children: _jsx(S.ToolbarInnerWrap, { children: Object.values(EAtomicBlockImageAlignment).map(function (alignment) { return (_jsx(ToolbarButton, { iconSource: iconSource, onClick: function () {
                                handleAlignmentChange(alignment);
                            } })); }) }) })] })));
    };
    return (_jsxs(S.Wrap, __assign({ onMouseLeave: handleEndDrag, onMouseMove: handleMouseMove, onMouseUp: handleEndDrag }, { children: [renderAsVariable(), renderAsImage()] })));
};
export default AtomicBlockImage;
