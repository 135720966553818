import { ERecurrenceType, ETaskReminder, ETaskType, TypeCategoriesMap, } from "@rentiohq/shared/dist/types/task.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createTask.types";
export var getNavigationTitle = function (_a) {
    var taskId = _a.taskId;
    if (taskId) {
        return getLocalizedText("tasks.edit");
    }
    return getLocalizedText("task.new");
};
export var REMINDER_OPTIONS = [
    ETaskReminder.DayBeforeExecuteDate,
    ETaskReminder.DayBeforeDueDate,
    ETaskReminder.WeekBeforeExecuteDate,
    ETaskReminder.WeekBeforeDueDate,
    ETaskReminder.MonthBeforeExecuteDate,
    ETaskReminder.MonthBeforeDueDate,
    ETaskReminder.Date,
];
export var FREQUENCY_OPTIONS = [
    ERecurrenceType.Daily,
    ERecurrenceType.Weekly,
    ERecurrenceType.Monthly,
    ERecurrenceType.Quarterly,
    ERecurrenceType.Yearly,
    ERecurrenceType.HalfYearly,
    ERecurrenceType.TwoYearly,
    ERecurrenceType.ThreeYearly,
    ERecurrenceType.FiveYearly,
];
export var getMappedCategories = function (type) {
    var categories = TypeCategoriesMap.get(type);
    if (!categories)
        return [];
    return categories;
};
export var getInitialValues = function (_a) {
    var _b, _c;
    var task = _a.task, executors = _a.executors, followers = _a.followers, payerAccountId = _a.payerAccountId, repairAccountId = _a.repairAccountId, propertyId = _a.propertyId, keypoint = _a.keypoint;
    if (task) {
        var isRecurrent = task.recurrenceType !== ERecurrenceType.Once;
        return _b = {},
            _b[EField.Title] = task.title,
            _b[EField.PropertySelect] = task.propertyId ? task.propertyId : undefined,
            _b[EField.ExecuteDate] = task.executeDate || undefined,
            _b[EField.RecurrenceSelection] = isRecurrent
                ? ERecurrenceType.Recurring
                : ERecurrenceType.Once,
            _b[EField.ReminderType] = task.nextReminderDate
                ? ETaskReminder.HasDate
                : ETaskReminder.None,
            _b[EField.ReminderOption] = task.reminderOption || undefined,
            _b[EField.DueDate] = task.dueDate || undefined,
            _b[EField.NextReminderDate] = task.nextReminderDate || undefined,
            _b[EField.RecurrenceType] = (isRecurrent && task.recurrenceType) || undefined,
            _b[EField.RecurrenceEndDate] = (isRecurrent &&
                task.recurrenceInfo &&
                task.recurrenceInfo.recurrenceEndDate) ||
                undefined,
            _b[EField.Description] = task.description,
            _b[EField.Type] = keypoint ? ETaskType.Repair : task.type,
            _b[EField.Category] = task.category,
            _b[EField.DamageType] = task.damageType,
            _b[EField.Location] = task.location,
            _b[EField.IncidentDate] = task.incidentDate,
            _b[EField.Payer] = payerAccountId ? [payerAccountId] : undefined,
            _b[EField.ContactPerson] = repairAccountId ? [repairAccountId] : undefined,
            _b;
    }
    return _c = {},
        _c[EField.Executors] = executors,
        _c[EField.Followers] = followers,
        _c[EField.PropertySelect] = propertyId,
        _c[EField.RecurrenceSelection] = ERecurrenceType.Once,
        _c[EField.ReminderType] = ETaskReminder.None,
        _c[EField.Type] = keypoint ? ETaskType.Repair : undefined,
        _c;
};
