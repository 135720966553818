import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EPaymentRequestStatus } from "@rentiohq/shared/dist/types/payment.types";
import { IBeneficiaryReportPayout } from "@rentiohq/shared/dist/types/report.types";
import {
  AddressCell,
  Icon,
  TruncateTooltip,
} from "@rentiohq/web-shared/dist/components";
import { IDataTableItem } from "@rentiohq/web-shared/dist/components/DataTable/DataTable.utils";
import Cost from "./components/Costs";
import { PayoutInfo } from "./components/PayoutInfo";

const getDateBestEffort = (payout: IBeneficiaryReportPayout): string => {
  let date = payout.payoutDate;
  if (!date) {
    if (payout.paidAt) date = payout.paidAt;
    else date = payout.createdAt;
  }
  return date ? formatDate(date) : "No date found";
};

export const getColumns = (): IDataTableItem<IBeneficiaryReportPayout>[] => {
  const tableSetup: IDataTableItem<IBeneficiaryReportPayout>[] = [
    {
      heading: getLocalizedText("reports.beneficiary.header.beneficiary"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => (
        <TruncateTooltip width={100}>
          {payout.requestPayeeAccount
            ? payout.requestPayeeAccount.fullName ??
              `${payout.requestPayeeAccount.firstname} ${payout.requestPayeeAccount.lastname}`
            : "No requestPayeeAccount names"}
        </TruncateTooltip>
      ),
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.payout_date"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => {
        const notPaid = payout.status !== EPaymentRequestStatus.Paid;
        if (notPaid) {
          return (
            <span style={{ color: "red" }}>
              {getLocalizedText("reports.beneficiary.payouts.not_paid")}
            </span>
          );
        } else {
          return getDateBestEffort(payout);
        }
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.rp"),
      columnContentType: "icon",
      sortable: false,
      renderContent: payout =>
        payout.paymentRequestReported ? (
          <div style={{ marginTop: -10, padding: 0 }}>
            <Icon source="checkboxMark" size="large" color="green" />
          </div>
        ) : (
          <Icon source="close" size="small" color="red" />
        ),
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.payment_ref"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => (
        <TruncateTooltip width={100}>
          {payout.payoutReference ?? "-"}
        </TruncateTooltip>
      ),
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.payout"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        return (
          <Cost costType="payout" payout={payout} isBold showZero={true} />
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.revenue"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        return <Cost costType="revenue" payout={payout} showZero={true} />;
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.commission"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        return <Cost costType="commission" payout={payout} isNegative />;
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.management_fee"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        return (
          <Cost costType="management" payout={payout} showLoader isNegative />
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.other"),
      columnContentType: "action",
      sortable: false,
      renderContent: payout => {
        return (
          <div style={{ width: 100 }}>
            <Cost costType="other" payout={payout} isNegative />
          </div>
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.property"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => {
        return (
          <AddressCell
            property={payout.property}
            address={payout.propertyAddress}
          />
        );
      },
    },
    // {
    //   heading: getLocalizedText(
    //     "reports.beneficiary.header.property_reference",
    //   ),
    //   columnContentType: "text",
    //   sortable: false,
    //   renderContent: payout => (
    //     <TruncateTooltip width={100}>
    //       {payout.propertyReference ?? "-"}
    //     </TruncateTooltip>
    //   ),
    // },
    {
      heading: getLocalizedText("reports.beneficiary.header.payment"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => {
        return <PayoutInfo payout={payout} />;
      },
    },
  ];

  return tableSetup;
};
