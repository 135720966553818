/* eslint @typescript-eslint/no-unused-vars: 0 */
/* eslint no-unused-vars: 0 */
import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { JsonForm } from "@rentiohq/web-shared/dist/components";
import { contact } from "@rentiohq/web-shared/dist/jsonSchemas";
import utils from "@rentiohq/web-shared/dist/utils";
import clone from "ramda/es/clone";
import React from "react";
import { useDispatch } from "react-redux";
import { trimAllValuesInObject } from "../profile.util";

const transformSchema = (schema: any) => {
  for (const key in schema) {
    if (key !== "properties") continue;
    const properties = schema.properties;

    for (const key in properties) {
      const hasUiOptions = properties[key].hasOwnProperty("ui");
      if (!hasUiOptions) continue;
      const uiOptions = properties[key].ui;
      const hasAutoFocus = uiOptions.hasOwnProperty("ui:autofocus");
      if (hasAutoFocus) {
        delete uiOptions["ui:autofocus"];
      }
    }
  }

  return schema;
};

export const ProfilePersonal = () => {
  const dispatch = useDispatch();

  const { user } = authHooks.useSelf();

  const defaultSchema = transformSchema(contact.defaultContactSchema);

  const [formData, setFormData] = React.useState<any>(user);
  const [schema, setSchema] = React.useState<any>(defaultSchema);

  React.useEffect(() => {
    const transformedSchema = clone(defaultSchema);
    transformSchema(transformedSchema);
    setSchema(transformedSchema);
  }, [defaultSchema]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    const currentSchema = clone(schema);
    const currentProfileData = clone(user);

    if (currentProfileData.unverifiedEmail) {
      currentProfileData.email = currentProfileData.unverifiedEmail;
      currentSchema.properties.email.description = utils.i18n.t(
        "profile.email.unverified",
      );
    }
    if (currentProfileData.unverifiedPhone) {
      currentProfileData.phone = currentProfileData.unverifiedPhone;
      currentSchema.properties.phone.description = utils.i18n.t(
        "profile.phone.unverified",
      );
    }

    const { personalContact } = user;
    if (!personalContact) {
      return;
    }

    const contactData = utils.contact.reduceContactAddress(personalContact);

    const currentData = {
      ...contactData,
      ...currentProfileData,
    };

    setFormData(currentData);
    setSchema(currentSchema);
  }, [user]);

  const handleSubmit = async (values: any) => {
    const newUserValues: any = utils.object.filterKeys(values, [
      "firstname",
      "lastname",
      "email",
      "phone",
      "locale",
    ]);
    const newContactData: any = utils.object.filterKeys(values, [
      "address",
      "locale",
    ]);
    let newUserData = clone(newUserValues);
    let renamedKeys: any = {};

    const trimmedUserValues = trimAllValuesInObject(newUserValues);

    if (newUserValues.email !== formData.email) {
      renamedKeys.email = "unverifiedEmail";
    }
    if (newUserValues.phone !== formData.phone) {
      renamedKeys.phone = "unverifiedPhone";
    }

    newUserData = utils.object.renameKeys(renamedKeys, trimmedUserValues);
    delete newContactData.address.lookupAddress;

    const userInput = {
      ...{ id: user?.id },
      ...newUserData,
    };

    const contactInput = {
      id: user?.personalContactId,
      locale: newContactData.locale,
      ...newContactData.address,
    };

    dispatch(
      authActions.updateUser.actions.start({
        user: userInput,
        contact: contactInput,
      }),
    );
  };

  return (
    <JsonForm
      id={`profile-personal`}
      schema={schema}
      onSubmit={handleSubmit}
      initialData={formData}
      autoSave={false}
    />
  );
};
