var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EEpcBasedIndexationType } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyEPCLabel } from "@rentiohq/shared/dist/types/property.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { ts } from "../../services";
import utils from "../../utils";
import { EField } from "./schema.indexContract.types";
import { getMaximumPrice as getMaximumPriceBase } from "./schema.indexContract.utils";
var canIndex = function (formData, extraData) {
    var indexInfo = extraData.indexInfo;
    var forceFullIndexation = formData[EField.ForceFullIndexation];
    if (forceFullIndexation) {
        return indexInfo.increase !== 0;
    }
    var epcLabel = (formData[EEpcField.Label] ||
        EPropertyEPCLabel.Unknown);
    switch (epcLabel) {
        case EPropertyEPCLabel.APlus:
        case EPropertyEPCLabel.A:
        case EPropertyEPCLabel.B:
        case EPropertyEPCLabel.C:
            return indexInfo.increase !== 0;
        case EPropertyEPCLabel.D:
            return indexInfo.increaseEpcD !== 0;
        case EPropertyEPCLabel.E:
            return indexInfo.increaseEpcE !== 0;
        case EPropertyEPCLabel.F:
            return indexInfo.increaseEpcF !== 0;
        case EPropertyEPCLabel.G:
            return indexInfo.increaseEpcG !== 0;
        case EPropertyEPCLabel.Unknown:
        case EPropertyEPCLabel.NoEPCAvailable:
        default:
            return indexInfo.increaseNoEpc !== 0;
    }
};
var showEpcBasedLimitationInfoSubtitle = function (formData, extraData) {
    var indexInfo = extraData.indexInfo;
    if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Regular) {
        return false;
    }
    var forceFullIndexation = formData[EField.ForceFullIndexation];
    if (forceFullIndexation) {
        return true;
    }
    var newPrice = indexInfo.newPrice;
    var epcLabel = (formData[EEpcField.Label] ||
        EPropertyEPCLabel.Unknown);
    switch (epcLabel) {
        case EPropertyEPCLabel.APlus:
        case EPropertyEPCLabel.A:
        case EPropertyEPCLabel.B:
        case EPropertyEPCLabel.C:
            break;
        case EPropertyEPCLabel.D:
            newPrice = indexInfo.newPriceEpcD;
            break;
        case EPropertyEPCLabel.E:
            newPrice = indexInfo.newPriceEpcE;
            break;
        case EPropertyEPCLabel.F:
            newPrice = indexInfo.newPriceEpcF;
            break;
        case EPropertyEPCLabel.G:
            newPrice = indexInfo.newPriceEpcG;
            break;
        case EPropertyEPCLabel.Unknown:
        case EPropertyEPCLabel.NoEPCAvailable:
        default:
            newPrice = indexInfo.newPriceNoEpc;
            break;
    }
    return newPrice !== indexInfo.newPrice;
};
var getMaximumPrice = function (params) {
    var formData = params.formData, extraData = params.extraData;
    var forceFullIndexation = formData[EField.ForceFullIndexation];
    return getMaximumPriceBase({
        indexInfo: extraData.indexInfo,
        epcLabel: formData[EEpcField.Label],
        forceFullIndexation: forceFullIndexation || false,
    });
};
var getEpcBasedLimitationInfoSubtitle = function (extraData) { return function (state, formData) {
    var forceFullIndexation = formData[EField.ForceFullIndexation];
    if (forceFullIndexation) {
        return getLocalizedText("contract.index.epc_info.force_full");
    }
    var epcKey;
    var correctionFactorForEpc;
    var newPriceForEpc;
    var increaseForEpc;
    var epcLabel = formData[EEpcField.Label];
    switch (epcLabel) {
        case EPropertyEPCLabel.APlus:
        case EPropertyEPCLabel.A:
        case EPropertyEPCLabel.B:
        case EPropertyEPCLabel.C:
            // Info banner not shown since default indexation is allowed
            break;
        case EPropertyEPCLabel.D:
            newPriceForEpc = extraData.indexInfo.newPriceEpcD;
            increaseForEpc = extraData.indexInfo.increaseEpcD;
            correctionFactorForEpc = extraData.indexInfo.correctionFactorEpcD;
            if (extraData.indexInfo.epcBasedIndexationType ===
                EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.E:
            newPriceForEpc = extraData.indexInfo.newPriceEpcE;
            increaseForEpc = extraData.indexInfo.increaseEpcE;
            correctionFactorForEpc = extraData.indexInfo.correctionFactorEpcE;
            if (extraData.indexInfo.epcBasedIndexationType ===
                EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.F:
            newPriceForEpc = extraData.indexInfo.newPriceEpcF;
            increaseForEpc = extraData.indexInfo.increaseEpcF;
            correctionFactorForEpc = extraData.indexInfo.correctionFactorEpcF;
            if (extraData.indexInfo.epcBasedIndexationType ===
                EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.G:
            newPriceForEpc = extraData.indexInfo.newPriceEpcG;
            increaseForEpc = extraData.indexInfo.increaseEpcG;
            correctionFactorForEpc = extraData.indexInfo.correctionFactorEpcG;
            if (extraData.indexInfo.epcBasedIndexationType ===
                EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.Unknown:
        case EPropertyEPCLabel.NoEPCAvailable:
        default:
            newPriceForEpc = extraData.indexInfo.newPriceNoEpc;
            increaseForEpc = extraData.indexInfo.increaseNoEpc;
            correctionFactorForEpc = extraData.indexInfo.correctionFactorNoEpc;
            if (extraData.indexInfo.epcBasedIndexationType ===
                EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc_unknown.corrected";
                break;
            }
            break;
    }
    if (epcKey) {
        return getLocalizedText(epcKey, {
            epcLabel: getLocalizedText("epc.label.".concat(epcLabel).toLowerCase()),
            correctionFactor: correctionFactorForEpc
                ? "".concat(correctionFactorForEpc)
                : "",
            newPriceBase: formatCurrency(extraData.indexInfo.newPrice),
            increaseBase: formatCurrency(extraData.indexInfo.increase),
            newPriceForEpc: newPriceForEpc ? formatCurrency(newPriceForEpc) : "",
            increaseForEpc: increaseForEpc ? formatCurrency(increaseForEpc) : "",
        });
    }
}; };
var getAdjustedRetroactiveAmountLabel = function (extraData) {
    var indexInfo = extraData.indexInfo;
    var retroactivityPrice = indexInfo.retroactivityPrice, retroactivityMonths = indexInfo.retroactivityMonths, retroactivityPeriodEnd = indexInfo.retroactivityPeriodEnd, retroactivityPeriodStart = indexInfo.retroactivityPeriodStart;
    return ts.contractIndexRetroactivity({
        values: {
            retroactiveMonths: retroactivityMonths || 0,
            retroactivePrice: formatCurrency(retroactivityPrice || 0),
            retroactivityPeriodEnd: retroactivityPeriodEnd
                ? utils.date.format(retroactivityPeriodEnd)
                : "",
            retroactivityPeriodStart: retroactivityPeriodStart
                ? utils.date.format(retroactivityPeriodStart)
                : "",
        },
    });
};
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        var _b;
        return {
            required: [EField.NewPrice],
            properties: (_a = {},
                _a[EField.EpcBasedLimitationInfo] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (formData) {
                        return showEpcBasedLimitationInfoSubtitle(formData, extraData);
                    },
                },
                _a[EField.NewPrice] = {
                    default: getValue(EField.NewPrice, initialValues, __assign(__assign({}, defaultValues), { newPrice: getMaximumPrice({
                            formData: defaultValues,
                            extraData: extraData,
                        }) })),
                    // @ts-ignore
                    showIf: function (formData) { return canIndex(formData, extraData); },
                    minimum: 0,
                    getMaximum: function (formData) {
                        return getMaximumPrice({
                            formData: formData,
                            extraData: extraData,
                        });
                    },
                },
                _a[EField.Retroactivity] = {
                    type: "boolean",
                    default: getValue(EField.Retroactivity, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return (canIndex(formData, extraData) &&
                            (extraData.indexInfo.retroactivityMonths || 0) > 0 &&
                            (extraData.indexInfo.increase || 0) > 0);
                    },
                },
                _a[EField.GenerateLetter] = {
                    type: "boolean",
                    default: getValue(EField.GenerateLetter, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return extraData.showGenerateLetter && canIndex(formData, extraData);
                    },
                },
                _a[EField.LetterType] = {
                    default: getValue(EField.LetterType, initialValues, defaultValues),
                    // @ts-ignore
                    options: (_b = extraData.letterTemplates) === null || _b === void 0 ? void 0 : _b.map(function (template) { return ({
                        id: template.id,
                        value: template.name,
                    }); }),
                    showIf: function (formData) {
                        return formData[EField.GenerateLetter] === true &&
                            canIndex(formData, extraData);
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a;
    return _a = {},
        _a[EField.NewPrice] = {
            "ui:field": ERentioFormField.Number,
            title: ts.contractIndexPriceNew(),
            suffixText: "EUR",
            isFloat: true,
            decimals: CONFIG.DECIMALS_FOR_CURRENCY,
            getInfo: function (formData) {
                var maxPrice = getMaximumPrice({
                    formData: formData,
                    extraData: extraData,
                });
                return ts.contractIndexPriceNewDescription({
                    values: {
                        maximumPrice: formatCurrency(maxPrice),
                    },
                });
            },
        },
        _a[EField.EpcBasedLimitationInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("contract.index.epc_info.title"),
            getSubtitle: getEpcBasedLimitationInfoSubtitle(extraData),
            actionTitle: getLocalizedText("contract.index.epc_info.more_info.text"),
            onPressAction: function () {
                var url = getLocalizedText("contract.index.epc_info.more_info.link");
                window.open(url, "_blank");
            },
        },
        _a[EField.Retroactivity] = {
            "ui:field": ERentioFormField.Switch,
            getLabel: function (formData) { return getAdjustedRetroactiveAmountLabel(extraData); },
        },
        _a[EField.GenerateLetter] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contract.index.generate_letter"),
        },
        _a[EField.LetterType] = {
            "ui:field": ERentioFormField.DropdownSelect,
            label: getLocalizedText("contract.index.letter_type"),
        },
        _a;
};
