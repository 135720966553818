import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  EmptyState,
  Error,
  Loading,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import CollectionFollowUp from "./CollectionFollowUp";

interface IProps {
  propertyId?: number;
}

const CollectionFollowUpContainer: React.FC<IProps> = props => {
  const { propertyId } = props;

  const dispatch = useDispatch();

  const missingKYCsIdentifier = propertyId
    ? `property-${propertyId}`
    : "all-items";

  const missingKYCs = useSelector((state: IRootStore) =>
    paymentSelectors.getMissingKYCs(state, missingKYCsIdentifier),
  );
  const isFetching = useSelector((state: IRootStore) =>
    paymentSelectors.getIsFetchingMissingKYCs(state, missingKYCsIdentifier),
  );
  const isExhausted = useSelector((state: IRootStore) =>
    paymentSelectors.getIsExhaustedMissingKYCs(state, missingKYCsIdentifier),
  );
  const error = useSelector((state: IRootStore) =>
    paymentSelectors.getFetchErrorMissingKYCs(state, missingKYCsIdentifier),
  );

  const fetchData = (refetch: boolean) => {
    if (isFetching) {
      return;
    }

    if (!refetch && isExhausted) {
      return;
    }

    dispatch(
      paymentActions.getMissingKYCs.actions.start({
        propertyId,
        refetch,
        missingKYCsIdentifier,
      }),
    );
  };

  React.useEffect(() => {
    fetchData(true);
  }, []);

  // TODO: Paging
  // React.useEffect(() => {
  //   if (!!missingKYCs && !isExhausted) {
  //     fetchData(false);
  //   }
  // }, [missingKYCs, isExhausted]);

  const handleRefetch = () => {
    fetchData(true);
  };

  // Render
  if (propertyId) {
    if (!missingKYCs || missingKYCs.length === 0) {
      if (error) {
        return <Error errors={[error]} />;
      }

      return null;
    }

    return <CollectionFollowUp items={missingKYCs} refetch={handleRefetch} />;
  }

  if (!missingKYCs) {
    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return <Error errors={[error]} />;
    }

    return null;
  }

  if (missingKYCs.length === 0) {
    return (
      <Card>
        <EmptyState
          heading={getLocalizedText(
            "payments.collection.follow_up.empty.heading",
          )}
        />
      </Card>
    );
  }

  return <CollectionFollowUp items={missingKYCs} refetch={handleRefetch} />;
};

export default CollectionFollowUpContainer;
