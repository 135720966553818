import { Box } from "@rebass/grid";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EIntakeStep } from "@rentiohq/shared/dist/types/application.types";
import { IUser } from "@rentiohq/shared/dist/types/auth.types";
import { ELocale } from "@rentiohq/shared/dist/types/i18n.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { ESpacings, MultiStepForm } from "@rentiohq/web-shared/dist/components";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as CONFIG from "scenes/Settings/scenes/TenantProfileNotificationPreferences/TenantProfileNotificationPreferences.config";
import propertyApplicationNotEligible from "../../../../../../forms/propertyApplicationNotEligible";
import * as t from "../../../../../../services/translationService";
import { IStatusChange } from "../../PropertyDetailApplicationsContainer";
import { ICustomFeedbackParams } from "./customFeedbackModal.types";
import { IPreference, preferences } from "./customFeedbackModal.utils";

interface IProps {
  property: IProperty;
  user: IUser;
  formId: number;
  customFeedbackParams: ICustomFeedbackParams;
  isLoadingInvite: boolean;
  handleChange: (statusChange: IStatusChange) => void;
  setCustomFeedbackParams: (
    customFeedbackParams?: ICustomFeedbackParams,
  ) => void;
}

const getLocalizedMessages = (
  customFeedbackParams: ICustomFeedbackParams,
  notificationPreferences: IPreference[],
) => {
  let localeMessages = {};

  const getPreferenceByKey = (key: string) =>
    notificationPreferences.find(x => x.key === key);

  if (customFeedbackParams?.statusChange.step === EIntakeStep.visitor) {
    localeMessages = {
      [ELocale.NL]: getPreferenceByKey(CONFIG.PREFERENCE_VISITOR_NL)?.value,
      [ELocale.FR]: getPreferenceByKey(CONFIG.PREFERENCE_VISITOR_FR)?.value,
      [ELocale.EN]: getPreferenceByKey(CONFIG.PREFERENCE_VISITOR_EN)?.value,
      en_US: getPreferenceByKey(CONFIG.PREFERENCE_VISITOR_EN)?.value,
    };
  } else if (
    customFeedbackParams?.statusChange.step === EIntakeStep.waitingList
  ) {
    localeMessages = {
      [ELocale.NL]: getPreferenceByKey(CONFIG.PREFERENCE_WAITING_LIST_NL)
        ?.value,
      [ELocale.FR]: getPreferenceByKey(CONFIG.PREFERENCE_WAITING_LIST_FR)
        ?.value,
      [ELocale.EN]: getPreferenceByKey(CONFIG.PREFERENCE_WAITING_LIST_EN)
        ?.value,
      en_US: getPreferenceByKey(CONFIG.PREFERENCE_WAITING_LIST_EN)?.value,
    };
  } else if (
    customFeedbackParams?.statusChange.step === EIntakeStep.planAsVisitor
  ) {
    localeMessages = {
      [ELocale.NL]: getPreferenceByKey(CONFIG.PREFERENCE_PLAN_FOR_VISIT_NL)
        ?.value,
      [ELocale.FR]: getPreferenceByKey(CONFIG.PREFERENCE_PLAN_FOR_VISIT_FR)
        ?.value,
      [ELocale.EN]: getPreferenceByKey(CONFIG.PREFERENCE_PLAN_FOR_VISIT_EN)
        ?.value,
      en_US: getPreferenceByKey(CONFIG.PREFERENCE_PLAN_FOR_VISIT_EN)?.value,
    };
  } else if (
    customFeedbackParams?.statusChange.step === EIntakeStep.notEligible
  ) {
    localeMessages = {
      [ELocale.NL]: getPreferenceByKey(CONFIG.PREFERENCE_NOT_ELIGIBLE_NL)
        ?.value,
      [ELocale.FR]: getPreferenceByKey(CONFIG.PREFERENCE_NOT_ELIGIBLE_FR)
        ?.value,
      [ELocale.EN]: getPreferenceByKey(CONFIG.PREFERENCE_NOT_ELIGIBLE_EN)
        ?.value,
      en_US: getPreferenceByKey(CONFIG.PREFERENCE_NOT_ELIGIBLE_EN)?.value,
    };
  }

  return localeMessages;
};

export const CustomFeedbackModal = ({
  property,
  user,
  formId,
  customFeedbackParams,
  isLoadingInvite,
  handleChange,
  setCustomFeedbackParams,
}: IProps) => {
  const notificationPreferences = useSelector((state: IRootStore) =>
    systemSelectors.getPreferences(state, preferences),
  );

  return (
    <MultiStepForm
      preventSubmitThroughEnter={true}
      formId={`application-mail-not_eligible-${formId}`}
      schemas={propertyApplicationNotEligible({
        property,
        application: customFeedbackParams.application,
        user,
        localeMessages: getLocalizedMessages(
          customFeedbackParams,
          notificationPreferences,
        ),
      })}
      asModal={true}
      withAside={false}
      onSuccess={(input: { tenantFeedbackText: string }) => {
        const { tenantFeedbackText } = input;

        let tenantFeedbackTextClean = undefined;
        if (tenantFeedbackText !== "") {
          tenantFeedbackTextClean = tenantFeedbackText;
        }

        handleChange({
          ...customFeedbackParams.statusChange,
          tenantFeedbackText: tenantFeedbackTextClean,
        });
        customFeedbackParams.onReadyCallback?.();
        setCustomFeedbackParams(undefined);
      }}
      submitLabel={t.system("submit")}
      modalActions={[
        {
          content: getLocalizedText(
            "property.application.not_eligible.info_modal.cta.continue_without_email",
          ),
          onClick: () => {
            handleChange(customFeedbackParams.statusChange);
            customFeedbackParams.onReadyCallback?.();
            setCustomFeedbackParams(undefined);
          },
          appearance: "outline",
        },
      ]}
      modalProps={{
        onClose: () => {
          handleChange(customFeedbackParams.statusChange);
          customFeedbackParams.onReadyCallback?.();
          setCustomFeedbackParams(undefined);
        },
        width: "medium",
        children: (
          <Box mb={ESpacings.loose}>
            {getLocalizedText(
              "property.application.not_eligible.info_modal.info",
            )}
          </Box>
        ),
      }}
      isLoading={isLoadingInvite}
    />
  );
};
