import { getName } from "../../redux/contact/contact.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { ECancellationReasons, EField, } from "./schema.cancelContract.types";
export var getInitialValues = function (extraData) {
    var _a;
    var initialValues = (_a = {},
        _a[EField.MemberThatCancels] = extraData.members.map(function (member) { return ({
            id: member.account.id,
            value: getName(member.account),
        }); }),
        _a[EField.CreateCompensationPayment] = [
            { id: "yes", value: getLocalizedText("system.yes") },
            { id: "no", value: getLocalizedText("system.no") },
        ],
        _a[EField.ReasonForCancellationTenant] = [
            {
                id: ECancellationReasons.NoMotiveNoticePeriod,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveNoticePeriod)),
            },
            {
                id: ECancellationReasons.NoMotiveCancellationFee,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveCancellationFee)),
            },
            {
                id: ECancellationReasons.Other,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.Other)),
            },
        ],
        _a[EField.ReasonForCancellationOwner] = [
            {
                id: ECancellationReasons.NoMotiveWithoutCancellationFee,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveWithoutCancellationFee)),
            },
            {
                id: ECancellationReasons.NoMotiveCancellationFee,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.NoMotiveCancellationFee)),
            },
            {
                id: ECancellationReasons.OwnUse,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.OwnUse)),
            },
            {
                id: ECancellationReasons.FamilyUse,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.FamilyUse)),
            },
            {
                id: ECancellationReasons.Renovation,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.Renovation)),
            },
            {
                id: ECancellationReasons.Other,
                value: getLocalizedText("contract.cancellation.".concat(ECancellationReasons.Other)),
            },
        ],
        _a);
    return initialValues;
};
export var selectedMemberHasRole = function (selectedMemberId, role, members) {
    var selectedMember = members.find(function (member) { return member.account.id === selectedMemberId; });
    return selectedMember === null || selectedMember === void 0 ? void 0 : selectedMember.roles.includes(role);
};
