import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import {
  EDocumentPackageStatus,
  EDocumentPackageTypes,
} from "@rentiohq/shared/dist/types/documentPackage.types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../services";
import { renderSignersTooltip } from "../../../../utils/documentPackage.utils";
import StatusDot from "../StatusDot";
import { EStatus } from "../StatusDot/StatusDot.types";

interface IProps {
  contract: IContract;
}

const StatusDotContractDocumentPackage = (props: IProps) => {
  const { contract } = props;

  // Helpers
  const documentPackagesIdentifier = `document-packages-contract-only_contracts-1-${contract.id}`;

  // Redux
  const dispatch = useDispatch();
  const documentPackages = useSelector((state: IRootStore) =>
    documentPackageSelectors.getDocumentPackages(
      state,
      documentPackagesIdentifier,
    ),
  );
  const isFetching = useSelector((state: IRootStore) =>
    documentPackageSelectors.isFetchingDocumentPackages(
      state,
      documentPackagesIdentifier,
    ),
  );

  // Lifecycle
  React.useEffect(() => {
    if (contract.manuallyCompletedDocumentSigning) {
      return;
    }

    if (isFetching) {
      return;
    }

    if (documentPackages && documentPackages.length > 0) {
      return;
    }

    dispatch(
      documentPackageActions.getDocumentPackages.actions.start({
        identifier: documentPackagesIdentifier,
        refetch: true,
        filterData: {
          where: {
            contractId: contract.id,
            type: EDocumentPackageTypes.Contract,
            status: {
              nin: [
                EDocumentPackageStatus.Revoked,
                EDocumentPackageStatus.Expired,
                EDocumentPackageStatus.Failed,
                EDocumentPackageStatus.Rejected,
                EDocumentPackageStatus.WorkerFailed,
              ],
            },
          },
        },
        limit: 1,
        order: "createdAt DESC",
      }),
    );
  }, [contract]);

  // Helpers
  const getStatus = () => {
    if (contract.manuallyCompletedDocumentSigning) {
      return EStatus.Success;
    }

    if (!documentPackages) {
      return EStatus.None;
    }

    if (documentPackages.length === 0) {
      return EStatus.Error;
    }

    const documentPackage = documentPackages[0];
    switch (documentPackage.status) {
      case EDocumentPackageStatus.Finished:
        return EStatus.Success;

      default:
        return EStatus.Warning;
    }
  };

  // Render
  const renderTooltipContent = () => {
    if (contract.manuallyCompletedDocumentSigning) {
      return getLocalizedText(contract.manuallyCompletedDocumentSigning);
    }

    if (!documentPackages) {
      return undefined;
    }

    if (documentPackages.length === 0) {
      return getLocalizedText("status.contracts.empty");
    }

    const documentPackage = documentPackages[0];
    switch (documentPackage.status) {
      case EDocumentPackageStatus.Pending:
      case EDocumentPackageStatus.Finished:
        return renderSignersTooltip(documentPackage);

      default:
        return ts.documentPackagesStatus(documentPackage.status);
    }
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

export default StatusDotContractDocumentPackage;
