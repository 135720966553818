import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { Card } from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";

export const History = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectors.getDetail(state, propertyId) : undefined,
  );

  if (!property) {
    return null;
  }

  return (
    <Card>
      <Activities
        identifier={`property-${property.id}`}
        filter={{
          where: {
            propertyId: property.id,
          },
        }}
        hasFetchMore={true}
      />
    </Card>
  );
};
