import { Tooltip as TooltipBase } from "@rentiohq/web-shared/dist/components";
import styled from "@rentiohq/web-theme";
import { EStatus } from "./StatusDot.types";

export const Wrap = styled.div`
  display: relative;
  margin: 0 auto;
  width: 16px;
  height: 16px;
`;

export const Tooltip = styled(TooltipBase).attrs<{ status: EStatus }>(
  props => ({
    tooltipContentStyle: {
      backgroundColor: (() => {
        switch (props.status) {
          case EStatus.Success:
            return props.theme.colors.successText;

          case EStatus.Warning:
            return props.theme.colors.warningText;

          case EStatus.Error:
            return props.theme.colors.errorText;

          case EStatus.None:
            return props.theme.colors.neutral30;

          default:
            return "inherit";
        }
      })(),
    },
  }),
)<{ status: EStatus }>``;

export const Status = styled.div<{ status: EStatus }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${props => {
    switch (props.status) {
      case EStatus.Success:
        return props.theme.colors.successText || "green";

      case EStatus.Warning:
        return props.theme.colors.warningText || "orange";

      case EStatus.Error:
        return props.theme.colors.errorText || "red";

      case EStatus.None:
      default:
        return props.theme.colors.neutral30;
    }
  }};
`;

export const TooltipContentWrap = styled.div<{
  status: EStatus;
}>`
  color: ${props => {
    switch (props.status) {
      case EStatus.None:
        return props.theme.colors.global1;

      case EStatus.Success:
      case EStatus.Warning:
      case EStatus.Error:
      default:
        return props.theme.colors.neutral2;
    }
  }};
`;
