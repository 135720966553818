export var EUserType;
(function (EUserType) {
    EUserType["Unknown"] = "unknown";
    EUserType["Tenant"] = "tenant";
    EUserType["Landlord"] = "landlord";
    EUserType["Broker"] = "broker";
})(EUserType || (EUserType = {}));
export var EExtraCardType;
(function (EExtraCardType) {
    EExtraCardType["SelectUserType"] = "selectUserType";
    EExtraCardType["InviteLandlord"] = "inviteLandlord";
    EExtraCardType["PropertyManagerContact"] = "propertyManagerContact";
    EExtraCardType["BrokerInfo"] = "BrokerInfo";
    EExtraCardType["CreateProperty"] = "createProperty";
    EExtraCardType["PropertyInfo"] = "propertyInfo";
    EExtraCardType["GeneralStatusInfo"] = "generalStatusInfo";
})(EExtraCardType || (EExtraCardType = {}));
export var ECardButtonActionType;
(function (ECardButtonActionType) {
    ECardButtonActionType["Deeplink"] = "deeplink";
    ECardButtonActionType["Callback"] = "callback";
    ECardButtonActionType["External"] = "external";
    ECardButtonActionType["Handler"] = "handler";
    ECardButtonActionType["Ignore"] = "ignore";
})(ECardButtonActionType || (ECardButtonActionType = {}));
export var ECardButtonsStyle;
(function (ECardButtonsStyle) {
    ECardButtonsStyle["Individual"] = "individual";
    ECardButtonsStyle["List"] = "list";
    ECardButtonsStyle["Circle"] = "circle";
})(ECardButtonsStyle || (ECardButtonsStyle = {}));
export var ECardType;
(function (ECardType) {
    ECardType["General"] = "general";
    ECardType["Chat"] = "chat";
    ECardType["Connection"] = "connection";
    ECardType["Payment"] = "payment";
    ECardType["Task"] = "task";
})(ECardType || (ECardType = {}));
