import {
  ETemplateTag,
  ETemplateType,
  ITemplate,
} from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EContractIndexationStatus,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import { differenceInYears } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { iHaveRole } from "@rentiohq/shared/dist/utils/roles.utils";
import {
  Appearance,
  renderActions,
} from "@rentiohq/web-shared/dist/components/Button";
import RentioInternalRenderer from "@rentiohq/web-shared/dist/components/RentioInternalRenderer";
import { ts } from "../../../../../../services";
import { EActionStatus, IChecklistItem } from "./components/ChecklistItem";

export const STATUS_IS_ENDING_WHEN_IN_MONTHS = 9;

export const getIndexationMessage = (params: {
  property: IProperty;
  contract: IContract;
  handleIndexContract: () => void;
  internalModeEnabled: boolean;
}): IChecklistItem | undefined => {
  const { property, contract, handleIndexContract, internalModeEnabled } =
    params;

  if (contract.indexationStatus === EContractIndexationStatus.NotReady) {
    return;
  }

  if (!iHaveRole(property, EPropertyMemberTypes.IndexationManager)) {
    if (internalModeEnabled) {
      return {
        actionStatus: EActionStatus.Incomplete,
        title: "",
        content: (
          <RentioInternalRenderer>
            Indexation is possible, but no indexation role
          </RentioInternalRenderer>
        ),
        rightContent: renderActions({
          content: "View indexation",
          onClick: handleIndexContract,
          appearance: "link",
          color: "green",
          size: "small",
        }),
      };
    }
    return;
  }

  const common = {
    title: ts.propertyDashboardChecklistIndexContractHeading(),
    onClick: handleIndexContract,
  };

  let showActionStatus = false;
  let actionStatus = EActionStatus.Incomplete;
  let buttonAppearance: Appearance | undefined;
  switch (contract.indexationStatus) {
    case EContractIndexationStatus.OwnerAsked:
      showActionStatus = true;
      actionStatus = EActionStatus.Incomplete;
      buttonAppearance = "link";
      break;

    case EContractIndexationStatus.OwnerApproved:
      showActionStatus = true;
      actionStatus = EActionStatus.UrgentActionRentio;
      break;

    case EContractIndexationStatus.OwnerDeclined:
      showActionStatus = true;
      actionStatus = EActionStatus.UrgentActionRentio;
      break;

    case EContractIndexationStatus.Muted:
      showActionStatus = true;
      actionStatus = EActionStatus.Incomplete;
      buttonAppearance = "link";
      break;

    case EContractIndexationStatus.Ready:
    default:
      break;
  }

  return {
    ...common,
    actionStatus,
    actionStatusText: showActionStatus
      ? getLocalizedText(
          `property.dashboard.checklist.index_contract.${contract.indexationStatus}.heading`.toLowerCase(),
        )
      : undefined,
    content: (
      <div>
        {getLocalizedText(
          `property.dashboard.checklist.index_contract.${contract.indexationStatus}.content`.toLowerCase(),
        )}
      </div>
    ),
    rightContent: renderActions({
      content: `${getLocalizedText(
        `property.dashboard.checklist.index_contract.${contract.indexationStatus}.action`.toLowerCase(),
      )} ›`,
      onClick: handleIndexContract,
      appearance: buttonAppearance,
      color: "green",
      size: "small",
    }),
  };
};

export const getAvailableTemplatesForType = (params: {
  type: "general" | "contract";
  isBroker: boolean;
  property: IProperty;
  contract?: IContract;
  templates?: ITemplate[];
}) => {
  const { type, isBroker, property, contract, templates } = params;

  if (!templates) {
    return;
  }

  if (type === "contract") {
    return templates.filter(template => {
      // Country
      if (template.country !== (property.countryId || "BE")) {
        return false;
      }

      // Broker
      if (isBroker && !template.tags.includes(ETemplateTag.Broker)) {
        return false;
      }

      // Consumer
      if (!isBroker && !template.tags.includes(ETemplateTag.Consumer)) {
        return false;
      }

      // Property type
      switch (property.typeId || -1) {
        // House
        case 2:
        // Apartment
        case 3:
          if (template.type === ETemplateType.ContractResidential) {
            return true;
          }

          if (
            template.type !== ETemplateType.ContractResidentialShort &&
            template.type !== ETemplateType.ContractResidentialLong
          ) {
            return false;
          }

          // Residential duration
          if (contract?.stopDate) {
            const durationInYears = differenceInYears(
              contract.stopDate,
              contract.startDate,
            );

            if (
              template.type === ETemplateType.ContractResidentialShort &&
              durationInYears > 3
            ) {
              return false;
            }

            if (
              template.type === ETemplateType.ContractResidentialLong &&
              durationInYears <= 3
            ) {
              return false;
            }
          }

          break;

        // Student housing
        case 4:
          if (template.type !== ETemplateType.ContractStudentResidence) {
            return false;
          }

          break;

        // Commercial
        case 5:
          if (template.type !== ETemplateType.ContractCommercial) {
            return false;
          }

          break;

        // Parking lot
        case 6:
          if (template.type !== ETemplateType.ContractParkingLot) {
            return false;
          }

          break;

        // Garage
        case 7:
          if (template.type !== ETemplateType.ContractGarage) {
            return false;
          }

          break;
        // NO (8) "other" here, that's the default

        case 9: // Office
        case 10: // Warehouse
        case 11: // Shed
        case 12: // Storage
          if (template.type !== ETemplateType.ContractOther) {
            return false;
          }

          break;

        default:
          break;
      }

      return true;
    });
  }

  return templates.filter(template => {
    // Broker
    if (isBroker && !template.tags.includes(ETemplateTag.Broker)) {
      return false;
    }

    // Consumer
    if (!isBroker && !template.tags.includes(ETemplateTag.Consumer)) {
      return false;
    }

    return [ETemplateType.KeyHandover, ETemplateType.Other].includes(
      template.type,
    );
  });
};
