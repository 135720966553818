var _a;
import api from "../../utils/api/api.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./exportFile.constants";
export var getPaged = (_a = generateApi(GENERATOR_PARAMS), _a.getPaged), create = _a.create;
export var getFileUrl = function (params) {
    var id = params.id, file = params.file;
    return api.get("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(id, "/file-url/").concat(file), {
        isV2: true,
    });
};
