import { jsx as _jsx } from "react/jsx-runtime";
import "draft-js/dist/Draft.css";
import React from "react";
import * as S from "./IndexCount.styles";
import { IndexCountContext } from "../../../Editor/contexts/IndexCount.context";
import { LoopContext } from "../../../Editor/contexts/Loop.context";
var IndexCount = function (props) {
    var indexCount = +(props.indexCount || -1);
    var loopConfig = React.useContext(LoopContext).loopConfig;
    // Get index count is indexer is looped
    var indexCountParsed = indexCount;
    if (loopConfig) {
        indexCountParsed += loopConfig.index / 100;
    }
    // Context
    var _a = React.useContext(IndexCountContext), getRealIndexCount = _a.getRealIndexCount, onIndexCountVisible = _a.onIndexCountVisible, onIndexCountInvisible = _a.onIndexCountInvisible;
    // Lifecycle
    React.useEffect(function () {
        onIndexCountVisible(indexCountParsed);
        return function () {
            onIndexCountInvisible(indexCountParsed);
        };
    }, []);
    // Render
    return _jsx(S.Wrap, { children: getRealIndexCount(indexCountParsed) });
};
export default IndexCount;
