var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createReducer } from "redux-act";
import { onMultiple } from "../../redux/utils/reducer.utils";
import { generateActions } from "./generator.actions";
import { generateUtils } from "./generator.utils";
export var generateReducer = function (params, addExtraDefaultState) {
    var actions = generateActions(params);
    var utils = generateUtils(params);
    var _getDefaultState = function () { return ({
        detail: {},
        paged: {},
        infiniteLoad: {},
        allWhere: {},
        count: {},
        create: {},
        update: {},
        remove: {},
        archiving: {},
    }); };
    var getDefaultState = function (state) {
        var internalDefaultState = _getDefaultState();
        var externalDefaultState = addExtraDefaultState
            ? addExtraDefaultState(state)
            : {};
        return __assign(__assign({}, internalDefaultState), externalDefaultState);
    };
    var reducer = createReducer({}, getDefaultState());
    reducer.on("LOG_OUT", function (state) { return getDefaultState(state); });
    reducer.on(actions.getDetailStartNoThrottle.type, function (state, payload) {
        var _a;
        var detailKey = utils.getDetailKey(payload);
        if (!detailKey) {
            return state;
        }
        return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[detailKey] = __assign(__assign({}, state.detail[detailKey]), { pending: true, error: undefined }), _a)) });
    });
    reducer.on(actions.getDetailSucceeded.type, function (state, payload) {
        var _a;
        var detailKey = utils.getDetailKey(payload);
        if (!detailKey) {
            return state;
        }
        return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[detailKey] = __assign(__assign({}, state.detail[detailKey]), { entity: payload.result.data, pending: false, error: undefined }), _a)) });
    });
    reducer.on(actions.getDetailFailed.type, function (state, payload) {
        var _a;
        var detailKey = utils.getDetailKey(payload);
        if (!detailKey) {
            return state;
        }
        return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[detailKey] = __assign(__assign({}, state.detail[detailKey]), { pending: false, error: payload.error }), _a)) });
    });
    var mapToDetails = function (items) {
        var result = {};
        items.forEach(function (entity) {
            var detailKey = utils.getDetailKey({ id: entity.id });
            if (detailKey) {
                result[detailKey] = {
                    entity: entity,
                };
            }
        });
        return result;
    };
    reducer.on(actions.getPagedStartNoThrottle.type, function (state, payload) {
        var _a, _b;
        var _c, _d, _e;
        var pagedKey = utils.getPagedKey(payload);
        return __assign(__assign({}, state), { paged: __assign(__assign({}, state.paged), (_a = {}, _a[pagedKey] = __assign(__assign({}, state.paged[pagedKey]), { query: payload.query, customPath: payload.customPath, isExhausted: false, pages: __assign(__assign({}, (_c = state.paged[pagedKey]) === null || _c === void 0 ? void 0 : _c.pages), (_b = {}, _b[payload.query.page] = __assign(__assign({}, (_e = (_d = state.paged[pagedKey]) === null || _d === void 0 ? void 0 : _d.pages) === null || _e === void 0 ? void 0 : _e[payload.query.page]), { pending: true, error: undefined }), _b)) }), _a)) });
    });
    reducer.on(actions.getPagedSucceeded.type, function (state, payload) {
        var _a, _b;
        var _c;
        var result = payload.result;
        var pagedKey = utils.getPagedKey(payload);
        return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), mapToDetails(result.data.items)), paged: __assign(__assign({}, state.paged), (_a = {}, _a[pagedKey] = __assign(__assign({}, state.paged[pagedKey]), { query: payload.query, customPath: payload.customPath, isExhausted: payload.isExhausted, totalItems: result.data.meta.totalItems, totalPages: result.data.meta.totalPages, pages: __assign(__assign({}, (_c = state.paged[pagedKey]) === null || _c === void 0 ? void 0 : _c.pages), (_b = {}, _b[payload.query.page] = {
                    data: {
                        links: result.data.links,
                        ids: result.data.items.map(function (x) { return x.id; }),
                    },
                    pending: false,
                    error: undefined,
                }, _b)) }), _a)) });
    });
    reducer.on(actions.getPagedFailed.type, function (state, payload) {
        var _a, _b;
        var _c, _d, _e;
        var pagedKey = utils.getPagedKey(payload);
        return __assign(__assign({}, state), { paged: __assign(__assign({}, state.paged), (_a = {}, _a[pagedKey] = __assign(__assign({}, state.paged[pagedKey]), { query: payload.query, customPath: payload.customPath, isExhausted: false, pages: __assign(__assign({}, (_c = state.paged[pagedKey]) === null || _c === void 0 ? void 0 : _c.pages), (_b = {}, _b[payload.query.page] = __assign(__assign({}, (_e = (_d = state.paged[pagedKey]) === null || _d === void 0 ? void 0 : _d.pages) === null || _e === void 0 ? void 0 : _e[payload.query.page]), { pending: false, error: payload.error }), _b)) }), _a)) });
    });
    reducer.on(actions.getAllWhereStartNoThrottle.type, function (state, payload) {
        var _a;
        var query = payload.query;
        var allWhereKey = utils.getAllWhereKey(payload);
        return __assign(__assign({}, state), { allWhere: __assign(__assign({}, state.allWhere), (_a = {}, _a[allWhereKey] = __assign(__assign({}, state.allWhere[allWhereKey]), { query: query, pending: true, isExhausted: false, error: undefined }), _a)) });
    });
    reducer.on(actions.getAllWhereSucceeded.type, function (state, payload) {
        var _a;
        var query = payload.query, result = payload.result;
        var allWhereKey = utils.getAllWhereKey(payload);
        var ids = result.data.items.map(function (x) { return x.id; });
        return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), mapToDetails(result.data.items)), allWhere: __assign(__assign({}, state.allWhere), (_a = {}, _a[allWhereKey] = __assign(__assign({}, state.allWhere[allWhereKey]), { ids: ids, query: query, pending: false, isExhausted: payload.isExhausted }), _a)) });
    });
    reducer.on(actions.getAllWhereFailed.type, function (state, payload) {
        var _a;
        var allWhereKey = utils.getAllWhereKey(payload);
        if (!allWhereKey || !state.allWhere[allWhereKey]) {
            return state;
        }
        return __assign(__assign({}, state), { allWhere: __assign(__assign({}, state.allWhere), (_a = {}, _a[allWhereKey] = __assign(__assign({}, state.allWhere[allWhereKey]), { pending: false, isExhausted: false, error: payload.error }), _a)) });
    });
    reducer.on(actions.getInfiniteLoadStart.type, function (state, payload) {
        var _a;
        var infiniteLoadKey = utils.getInfiniteLoadKey(payload);
        return __assign(__assign({}, state), { infiniteLoad: __assign(__assign({}, state.infiniteLoad), (_a = {}, _a[infiniteLoadKey] = __assign(__assign({ ids: [], query: payload.query, totalItems: 0, totalPages: 0 }, state.infiniteLoad[infiniteLoadKey]), { pending: true, isExhausted: false, error: undefined }), _a)) });
    });
    reducer.on(actions.getInfiniteLoadSucceeded.type, function (state, payload) {
        var _a;
        var _b;
        var _c = payload.refresh, refresh = _c === void 0 ? false : _c, query = payload.query, result = payload.result;
        var infiniteLoadKey = utils.getInfiniteLoadKey(payload);
        var ids = refresh
            ? result.data.items.map(function (x) { return x.id; })
            : __spreadArray(__spreadArray([], (((_b = state.infiniteLoad[infiniteLoadKey]) === null || _b === void 0 ? void 0 : _b.ids) || []), true), result.data.items.map(function (x) { return x.id; }), true);
        return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), mapToDetails(result.data.items)), infiniteLoad: __assign(__assign({}, state.infiniteLoad), (_a = {}, _a[infiniteLoadKey] = __assign(__assign({}, (refresh ? undefined : state.infiniteLoad[infiniteLoadKey])), { ids: ids, query: query, totalItems: result.data.meta.totalItems, totalPages: result.data.meta.totalPages, pending: false, isExhausted: payload.isExhausted }), _a)) });
    });
    reducer.on(actions.getInfiniteLoadUnchanged.type, function (state, payload) {
        var _a;
        var infiniteLoadKey = utils.getInfiniteLoadKey(payload);
        if (!infiniteLoadKey || !state.infiniteLoad[infiniteLoadKey]) {
            return state;
        }
        return __assign(__assign({}, state), { infiniteLoad: __assign(__assign({}, state.infiniteLoad), (_a = {}, _a[infiniteLoadKey] = __assign(__assign({}, state.infiniteLoad[infiniteLoadKey]), { pending: false, isExhausted: payload.isExhausted }), _a)) });
    });
    reducer.on(actions.getInfiniteLoadFailed.type, function (state, payload) {
        var _a;
        var infiniteLoadKey = utils.getInfiniteLoadKey(payload);
        if (!infiniteLoadKey || !state.infiniteLoad[infiniteLoadKey]) {
            return state;
        }
        return __assign(__assign({}, state), { infiniteLoad: __assign(__assign({}, state.infiniteLoad), (_a = {}, _a[infiniteLoadKey] = __assign(__assign({}, state.infiniteLoad[infiniteLoadKey]), { pending: false, isExhausted: false, error: payload.error }), _a)) });
    });
    reducer.on(actions.getCountStartNoThrottle.type, function (state, payload) {
        var _a;
        var countKey = utils.getCountKey(payload);
        return __assign(__assign({}, state), { count: __assign(__assign({}, state.count), (_a = {}, _a[countKey] = __assign(__assign({}, state.count[countKey]), { pending: true, error: undefined }), _a)) });
    });
    reducer.on(actions.getCountSucceeded.type, function (state, payload) {
        var _a;
        var countKey = utils.getCountKey(payload);
        return __assign(__assign({}, state), { count: __assign(__assign({}, state.count), (_a = {}, _a[countKey] = __assign(__assign({}, state.count[countKey]), { pending: false, count: payload.result.data.count, error: undefined }), _a)) });
    });
    reducer.on(actions.getCountFailed.type, function (state, payload) {
        var _a;
        var countKey = utils.getCountKey(payload);
        return __assign(__assign({}, state), { count: __assign(__assign({}, state.count), (_a = {}, _a[countKey] = __assign(__assign({}, state.count[countKey]), { pending: false, error: payload.error }), _a)) });
    });
    reducer.on(actions.createStart.type, function (state, payload) { return (__assign(__assign({}, state), { create: {
            pending: true,
            error: undefined,
        } })); });
    reducer.on(actions.createSucceeded.type, function (state, payload) {
        var _a;
        return (__assign(__assign({}, state), { infiniteLoad: {
            //Clear infiniteLoad when a new entity is added.
            }, detail: __assign(__assign({}, state.detail), (_a = {}, _a["".concat(payload.result.data.id)] = { entity: payload.result.data }, _a)), create: {
                id: payload.result.data.id,
                pending: false,
                error: undefined,
            } }));
    });
    reducer.on(actions.createFailed.type, function (state, payload) { return (__assign(__assign({}, state), { create: {
            pending: false,
            error: payload.error,
        } })); });
    onMultiple(reducer, [
        actions.updateStart.type,
        actions.updateMembersStart.type,
        actions.updateEmployeesStart.type,
    ], function (state, payload) {
        var _a;
        return (__assign(__assign({}, state), { update: __assign(__assign({}, state.update), (_a = {}, _a["".concat(payload.id)] = {
                pending: true,
                error: undefined,
            }, _a)) }));
    });
    onMultiple(reducer, [
        actions.updateSucceeded.type,
        actions.updateMembersSucceeded.type,
        actions.updateEmployeesSucceeded.type,
    ], function (state, payload) {
        var _a, _b;
        return (__assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a["".concat(payload.result.data.id)] = { entity: payload.result.data }, _a)), update: __assign(__assign({}, state.update), (_b = {}, _b["".concat(payload.id)] = {
                pending: false,
                error: undefined,
            }, _b)) }));
    });
    onMultiple(reducer, [
        actions.updateFailed.type,
        actions.updateMembersFailed.type,
        actions.updateEmployeesFailed.type,
    ], function (state, payload) {
        var _a;
        return (__assign(__assign({}, state), { update: __assign(__assign({}, state.update), (_a = {}, _a["".concat(payload.id)] = {
                pending: false,
                error: payload.error,
            }, _a)) }));
    });
    reducer.on(actions.removeStart.type, function (state, payload) {
        var _a;
        return (__assign(__assign({}, state), { remove: __assign(__assign({}, state.remove), (_a = {}, _a["".concat(payload.id)] = {
                pending: true,
                error: undefined,
            }, _a)) }));
    });
    reducer.on(actions.removeSucceeded.type, function (state, payload) {
        var _a, _b;
        return (__assign(__assign({}, state), { infiniteLoad: {
            // TODO: Clear infiniteLoad when a new entity is removed.
            }, detail: __assign(__assign({}, state.detail), (_a = {}, _a["".concat(payload.id)] = undefined, _a)), remove: __assign(__assign({}, state.remove), (_b = {}, _b["".concat(payload.id)] = {
                pending: false,
                error: undefined,
            }, _b)) }));
    });
    reducer.on(actions.removeFailed.type, function (state, payload) {
        var _a;
        return (__assign(__assign({}, state), { remove: __assign(__assign({}, state.remove), (_a = {}, _a["".concat(payload.id)] = {
                pending: false,
                error: payload.error,
            }, _a)) }));
    });
    onMultiple(reducer, [actions.archiveStart.type, actions.unarchiveStart.type], function (state, payload) {
        var _a;
        return (__assign(__assign({}, state), { archiving: __assign(__assign({}, state.archiving), (_a = {}, _a["".concat(payload.id)] = {
                pending: true,
                error: undefined,
            }, _a)) }));
    });
    onMultiple(reducer, [actions.archiveSucceeded.type, actions.unarchiveSucceeded.type], function (state, payload) {
        var _a, _b;
        return (__assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a["".concat(payload.id)] = __assign(__assign({}, state.detail["".concat(payload.id)]), { entity: payload.result.data }), _a)), archiving: __assign(__assign({}, state.archiving), (_b = {}, _b["".concat(payload.id)] = {
                pending: false,
                error: undefined,
            }, _b)) }));
    });
    onMultiple(reducer, [actions.archiveFailed.type, actions.unarchiveFailed.type], function (state, payload) {
        var _a;
        return (__assign(__assign({}, state), { archiving: __assign(__assign({}, state.archiving), (_a = {}, _a["".concat(payload.id)] = {
                pending: false,
                error: payload.error,
            }, _a)) }));
    });
    return reducer;
};
