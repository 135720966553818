export enum ERenewalDateWithinMonths {
  Within1Month = "within1Month",
  Within3Months = "within3Months",
}

export enum EEndDateWithinMonths {
  Within1Month = "within1Month",
  // Within3Months = "within3Months",
  Within4Months = "within4Months",
  Within7Months = "within7Months",
}
