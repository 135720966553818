import styled from "@rentiohq/web-theme";
import { PropertyListItemItem } from "../PropertyListItem/PropertyListItem.header";

export const LoadingWrap = styled.div`
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const LoadingInnerWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -25px;
`;

export const PropertyListItemItemSpacer = styled(PropertyListItemItem)`
  flex: 0.5;
  display: none;
  @media (min-width: 1300px) {
    display: inherit;
  }
`;

export const InnerItemWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const LineLeft = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  top: 50%;
  width: 50%;
  height: 2px;
  margin-top: -2px;
  background-color: ${props =>
    props.isActive ? props.theme.colors.green60 : props.theme.colors.neutral30};
  z-index: -1;
`;

export const LineRight = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 2px;
  margin-top: -2px;
  background-color: ${props =>
    props.isActive ? props.theme.colors.green60 : props.theme.colors.neutral30};
  z-index: -1;
`;

export const Step = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  font-weight: bold;
  color: ${props =>
    props.isActive ? props.theme.colors.green60 : props.theme.colors.neutral30};
  border-color: ${props =>
    props.isActive ? props.theme.colors.green60 : props.theme.colors.neutral30};
  background-color: white;
  line-height: 1;
`;
