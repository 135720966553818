var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import * as S from "./ActivityContent/ActivityContent.styled";
export var ContentMeta = memo(function (_a) {
    var meta = _a.meta;
    return (_jsx("table", { children: _jsx("tbody", { children: Array.from(meta.entries())
                .filter(function (_a) {
                var _ = _a[0], value = _a[1];
                return Boolean(value);
            })
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return (_jsxs("tr", { children: [_jsxs(S.ValueCell, { children: [key, ":"] }), _jsx("td", __assign({ style: { textAlign: "start" } }, { children: value }))] }));
            }) }) }));
});
