import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  EContractMemberTypes,
  EContractPaymentRepetitionType,
  EContractType,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import { EDocumentPackageStatus } from "@rentiohq/shared/dist/types/documentPackage.types";
import { endOfDay, isAfter } from "@rentiohq/shared/dist/utils/date-fns.utils";
import {
  formatCurrency,
  getVat,
} from "@rentiohq/shared/dist/utils/number.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared/dist/utils/roles.utils";
import {
  ContactConnectionIndicator,
  ContactFetchListItem,
  Stack,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { TLozengeAppearance } from "@rentiohq/web-shared/dist/components/Lozenge/Lozenge.types";
import utils from "@rentiohq/web-shared/dist/utils";
import { sortBy, sum } from "lodash";
import { rs, ts } from "../services";

export const getContractEditRoute = (contract: IContract, propertyId: number) =>
  rs.updateBasicContractInfoRoute(propertyId, contract.id);

export const renderContractNames = (params: {
  contract: IContract;
  withAdditionalContactMetaData?: boolean;
  vertical?: boolean;
}) => {
  const {
    contract,
    withAdditionalContactMetaData = false,
    vertical = false,
  } = params;
  const renderMemberContact = (contact: IContact) => {
    return (
      <>
        <Button appearance="link" url={`/contacts/${contact.id}`}>
          <ContactConnectionIndicator contact={contact} />
          {getName(contact)}

          {withAdditionalContactMetaData
            ? ` (${utils.contact.getMetaData({ contact })})`
            : undefined}
        </Button>
      </>
    );
  };

  const renderMemberAccount = (account: IAccount) => (
    <>
      <ContactConnectionIndicator forceDisplay={true} />
      {getName(account)}
    </>
  );

  const members = sortBy(
    getMembersWithOneOfRoles(contract.members, [
      EContractMemberTypes.Tenant,
      EContractMemberTypes.Parent,
    ]),
    element => {
      const firstRole = element.roles[0];

      var weight = {
        [`${EContractMemberTypes.Tenant}`]: 1,
        [`${EContractMemberTypes.Parent}`]: 2,
      };

      return weight[firstRole];
    },
    "account.firstname",
    "account.lastname",
    "account.company",
  );

  return (
    <Stack vertical={vertical} spacing={vertical ? "extraTight" : "tight"}>
      {members.map(tenant => (
        <Stack.Item key={tenant.account.id}>
          <ContactFetchListItem
            account={tenant.account}
            renderContact={renderMemberContact}
            renderAccount={renderMemberAccount}
          />
        </Stack.Item>
      ))}
    </Stack>
  );
};

export const getContractDates = (contract: IContract) =>
  `${utils.date.format(contract.startDate)} - ${
    contract.stopDate
      ? utils.date.format(contract.stopDate)
      : ts.contractIndefinite()
  }`;

export const getContractPrice = (contract: IContract) => {
  const { currentPrice = 0, priceVat = 0 } = contract;

  if (priceVat > 0) {
    return getLocalizedText("system.amount_with_vat", {
      amount: formatCurrency(currentPrice),
      vat: formatCurrency(getVat(currentPrice, priceVat / 100)),
    });
  }

  return formatCurrency(currentPrice);
};

export const getContractCost = (
  contract: IContract,
  showRentDiscountInfo = true,
) => {
  const {
    currentPrice = 0,
    priceVat = 0,
    currentCostsCommon = 0,
    costsCommonVat = 0,
    currentCostsNonCommon = 0,
    costsNonCommonVat = 0,
    amount = 0,
    contractType,
    repetitionType,
    rentDiscountEnabled,
    rentDiscountAmount = 0,
    rentDiscountOffset = 0,
  } = contract;

  const hasVat = priceVat > 0 || costsCommonVat > 0 || costsNonCommonVat > 0;

  switch (contractType) {
    case EContractType.RentDeposit:
      return formatCurrency(amount ?? 0);

    case EContractType.Basic:
    default: {
      let result = getLocalizedText(
        `system.amount${hasVat ? "_with_vat" : ""}.${
          repetitionType || EContractPaymentRepetitionType.Monthly
        }`.toLowerCase(),
        {
          amount: formatCurrency(
            sum([currentPrice, currentCostsCommon, currentCostsNonCommon]),
          ),
          vat: formatCurrency(
            sum([
              getVat(currentPrice, priceVat / 100),
              getVat(currentCostsCommon, costsCommonVat / 100),
              getVat(currentCostsNonCommon, costsNonCommonVat / 100),
            ]),
          ),
        },
      );

      if (currentCostsCommon > 0 || currentCostsNonCommon > 0) {
        result = `${result} (${getLocalizedText(
          "contract.rent_and_costs.info",
        )})`;
      }

      if (rentDiscountEnabled && showRentDiscountInfo) {
        let discountInfo = getLocalizedText(
          "contract.discount.info",
          {
            amount: formatCurrency(rentDiscountAmount ?? 0),
            offset: `${rentDiscountOffset}`,
          },
          rentDiscountOffset || 0,
        );

        result = `${result} (${discountInfo})`;
      }
      return result;
    }
  }
};

export const getContractStatus = (contract: IContract) => {
  switch (true) {
    case isAfter(
      endOfDay(new Date()),
      endOfDay(new Date(contract.stopDate!)),
    ) && contract.stopDate !== null:
      return "passed";
    case contract.signedAt !== null:
      return "active";
    default:
      return "draft";
  }
};

export const getValidDocumentPackage = (contract?: IContract) => {
  if (!contract?.documentPackages) {
    return;
  }

  return contract.documentPackages.filter(
    documentPackage =>
      ![
        EDocumentPackageStatus.Revoked,
        EDocumentPackageStatus.Expired,
        EDocumentPackageStatus.Failed,
        EDocumentPackageStatus.Rejected,
        EDocumentPackageStatus.WorkerFailed,
      ].includes(documentPackage.status!),
  )[0];
};

export const statusToAppearanceMap: { [key: string]: TLozengeAppearance } = {
  draft: "info",
  passed: "default",
  active: "success",
};
