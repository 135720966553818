var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from "polished";
import { Image, Text, TouchableOpacity, View } from "react-native";
import images from "../../../components/assets";
import styled, { css, } from "../../../components/theme/styled-components.native";
import { desktopStyles } from "../../../utils/style.utils";
export var Wrap = styled(TouchableOpacity)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 64px;\n"], ["\n  min-height: 64px;\n"])));
/*export const StyledLink = styled2(Link)`
  text-decoration: none;
  color: rgb(73, 73, 73);
`;*/
export var InnerWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n  opacity: ", ";\n  justify-content: space-between;\n  background-color: ", ";\n"], ["\n  flex-direction: row;\n  align-items: center;\n  opacity: ", ";\n  justify-content: space-between;\n  background-color: ", ";\n"])), function (props) { return (props.finishedAt ? 0.5 : 1); }, function (props) {
    return props.isActive
        ? transparentize(0.9, props.theme.colors.global5)
        : "transparent";
});
export var LeftSection = styled(TouchableOpacity)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 72px;\n  height: 64px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 72px;\n  height: 64px;\n  align-items: center;\n  justify-content: center;\n"])));
export var CheckBoxTouchable = styled(TouchableOpacity)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var CheckboxIconUnChecked = styled(Image).attrs(function () { return ({
    source: images.ICO_CHECKBOX_UNCHECKED,
}); })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n"], ["\n  width: 24px;\n  height: 24px;\n"])));
export var CheckboxIconChecked = styled(Image).attrs(function () { return ({
    source: images.ICO_CHECKBOX_CHECKED,
}); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n"], ["\n  width: 24px;\n  height: 24px;\n"])));
export var CheckboxIconArchived = styled(Image).attrs(function () { return ({
    source: images.ICO_ARCHIVE,
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n"], ["\n  width: 24px;\n  height: 24px;\n"])));
export var RecurrentIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_SYNCHRONIZE_ARROWS,
}); })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  margin-left: 6px;\n  margin-top: 4px;\n"], ["\n  width: 20px;\n  height: 20px;\n  margin-left: 6px;\n  margin-top: 4px;\n"])));
export var ArrowIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_DISCLOSURE,
}); })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  margin-left: 4px;\n  ", "\n"], ["\n  width: 16px;\n  height: 16px;\n  margin-left: 4px;\n  ", "\n"])), desktopStyles(css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    display: none;\n  "], ["\n    display: none;\n  "])))));
export var MidSection = styled(View)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  flex-direction: column;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  flex: 1;\n  padding-right: ", ";\n  ", "\n"], ["\n  flex-direction: column;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  flex: 1;\n  padding-right: ", ";\n  ", "\n"])), function (props) { return (props.isRecurrent ? "32px" : "8px"); }, desktopStyles(css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    padding-right: ", ";\n  "], ["\n    padding-right: ", ";\n  "])), function (props) { return (props.isRecurrent ? "16px" : "8px"); })));
export var TitleSection = styled(View)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n"], ["\n  flex-direction: row;\n  align-items: center;\n"])));
export var DateSection = styled(View)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  flex-direction: row;\n  margin-top: 4px;\n"], ["\n  flex-direction: row;\n  margin-top: 4px;\n"])));
export var BulletPoint = styled(Text)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  color: ", ";\n  margin-top: -2px;\n"], ["\n  color: ", ";\n  margin-top: -2px;\n"])), function (props) { return props.theme.colors.global2; });
export var ExecuteDateSection = styled(View)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  justify-content: space-around;\n  flex-direction: row;\n  margin-right: 4px;\n"], ["\n  justify-content: space-around;\n  flex-direction: row;\n  margin-right: 4px;\n"])));
export var RightSection = styled(View)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-left: 16px;\n  margin-right: 16px;\n  ", "\n"], ["\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-left: 16px;\n  margin-right: 16px;\n  ", "\n"])), desktopStyles(css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    margin-right: 24px;\n  "], ["\n    margin-right: 24px;\n  "])))));
// @ts-ignore
export var Title = styled(Text)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  font-size: 16px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"], ["\n  font-size: 16px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"])), function (props) { return props.theme.colors.global1; }, desktopStyles(css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    font-size: 16px;\n  "], ["\n    font-size: 16px;\n  "])))));
export var Subtitle = styled(Text)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  font-size: 12px;\n  letter-spacing: -0.07px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"], ["\n  font-size: 12px;\n  letter-spacing: -0.07px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"])), function (props) { return props.theme.colors.global2; }, desktopStyles(css(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n    font-size: 12px;\n  "], ["\n    font-size: 12px;\n  "])))));
export var ExecuteDateTitle = styled(Text)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  font-size: 12px;\n  font-weight: 300;\n  margin-right: 4px;\n  color: ", ";\n  ", ";\n"], ["\n  font-size: 12px;\n  font-weight: 300;\n  margin-right: 4px;\n  color: ", ";\n  ", ";\n"])), function (props) { return props.theme.colors.global2; }, desktopStyles(css(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    font-size: 10px;\n  "], ["\n    font-size: 10px;\n  "])))));
export var DeadlineTitle = styled(Text)(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  font-size: 12px;\n  font-weight: 300;\n  margin-right: 4px;\n  color: ", ";\n  ", ";\n"], ["\n  font-size: 12px;\n  font-weight: 300;\n  margin-right: 4px;\n  color: ", ";\n  ", ";\n"])), function (_a) {
    var days = _a.days, theme = _a.theme;
    switch (true) {
        case days <= 0 || days === 1:
            return "#EA1017";
        case days <= 5:
            return "#E69100";
        case days > 6:
        default:
            return theme.colors.global2;
    }
}, desktopStyles(css(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n    font-size: 10px;\n  "], ["\n    font-size: 10px;\n  "])))));
export var AttachedUsers = styled(View)(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  width: 22px;\n  height: 22px;\n  border-radius: 12px;\n  ", "\n  margin-left: -8px;\n  background-color: ", ";\n  border-width: 1px;\n  align-items: center;\n  justify-content: center;\n  border-color: ", ";\n"], ["\n  width: 22px;\n  height: 22px;\n  border-radius: 12px;\n  ", "\n  margin-left: -8px;\n  background-color: ", ";\n  border-width: 1px;\n  align-items: center;\n  justify-content: center;\n  border-color: ", ";\n"])), desktopStyles(css(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n    width: 30px;\n    height: 30px;\n    border-radius: 15px;\n  "], ["\n    width: 30px;\n    height: 30px;\n    border-radius: 15px;\n  "])))), function (props) {
    return props.isCompany ? props.theme.colors.global4 : props.theme.colors.global3;
}, function (props) { return props.theme.colors.neutral2; });
export var UserText = styled(Text)(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  font-size: 6px;\n  color: ", ";\n  line-height: 8px;\n  width: 100%;\n  padding-left: 1px;\n  padding-right: 1px;\n  font-weight: 400;\n  text-align: center;\n  letter-spacing: -0.07px;\n  ", "\n"], ["\n  font-size: 6px;\n  color: ", ";\n  line-height: 8px;\n  width: 100%;\n  padding-left: 1px;\n  padding-right: 1px;\n  font-weight: 400;\n  text-align: center;\n  letter-spacing: -0.07px;\n  ", "\n"])), function (props) { return props.theme.colors.global1; }, desktopStyles(css(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n    font-size: 8px;\n    width: 100%;\n    padding-left: 2px;\n    padding-right: 2px;\n  "], ["\n    font-size: 8px;\n    width: 100%;\n    padding-left: 2px;\n    padding-right: 2px;\n  "])))));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30;
