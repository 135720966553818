import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  ContactConnectionIndicator,
  Grid,
  Icon,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import * as t from "../../../services/translationService";

interface IProps {
  contact: IContact;
}

export const ContactMeta: React.FC<IProps> = ({ contact }) => {
  if (!contact) return null;

  const { email, phone, VATNumber } = contact;

  const mail = () => {
    window.location.href = `mailto:${email}`;
  };

  const call = () => {
    window.location.href = `tel:${phone}`;
  };

  return (
    <Grid alignItems="center">
      <Grid.Item>
        <ContactConnectionIndicator
          contact={contact as IContact}
          showTooltip={false}
        />
        {!!contact.inviteAcceptedAt
          ? t.contactConnectionSince({
              values: { date: utils.date.format(contact.inviteAcceptedAt) },
            })
          : t.contactConnectionPending()}
      </Grid.Item>
      {contact.isCompany && VATNumber && (
        <Grid.Item>
          {t.system("vat_number")}: {VATNumber}
        </Grid.Item>
      )}
      {phone && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="smartphone" />
            </Grid.Item>
            <Grid.Item>
              <Button appearance="reset" onClick={call}>
                {formatPhone(phone)}
              </Button>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      )}
      {email && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="email" />
            </Grid.Item>
            <Grid.Item>
              <Button appearance="reset" onClick={mail}>
                {email}
              </Button>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      )}
    </Grid>
  );
};
