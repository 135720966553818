import { Box } from "@rebass/grid";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { Lozenge } from "@rentiohq/web-shared/dist/components";
import { spacing } from "@rentiohq/web-theme";
import { memo } from "react";
import { getContractDuration } from "scenes/Properties/scenes/Contracts/Contracts.utils";
import {
  ChipAppearance,
  EContractChipColors,
  EContractEndingStatus,
} from "../ContractStatusChip/contractStatusChip.types";

interface IProps {
  contract: IContract;
}

const getAppearance = (duration: EContractEndingStatus): ChipAppearance => {
  const common = {
    statusText: getLocalizedText(`contract.duration.${duration}`),
    textColor: "white",
  };

  switch (duration) {
    case EContractEndingStatus.ShortTerm:
      return {
        ...common,
        color: EContractChipColors.Gray,
      };
    case EContractEndingStatus.LongTerm:
      return {
        ...common,
        color: EContractChipColors.Gray,
      };
    case EContractEndingStatus.AutoEnding:
      return {
        ...common,
        color: EContractChipColors.Gray,
      };
    case EContractEndingStatus.Cancelled:
      return {
        ...common,
        color: EContractChipColors.Orange,
      };
  }
};

const ContractDurationChip = ({ contract }: IProps) => {
  const duration = getContractDuration(contract);
  const appearance = getAppearance(duration);

  if (!contract) return null;
  return (
    <>
      <Box padding={spacing("extraTight")}>
        <Lozenge
          appearance="default"
          customTextColor={appearance.textColor}
          customBackgroundColor={appearance.color}
        >
          {appearance.statusText}
        </Lozenge>
      </Box>
    </>
  );
};

export const MemoizedContractDurationChip = memo(ContractDurationChip);
