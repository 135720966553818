var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { DisplayText, ESpacings, Modal, } from "../../../components";
import * as t from "../../../services/translationService";
var ModalConfirmation = function (props) {
    var heading = props.heading, _a = props.isDisabled, isDisabled = _a === void 0 ? false : _a, actionsLabels = props.actionsLabels, onCancel = props.onCancel, onConfirm = props.onConfirm, modalProps = props.modalProps, children = props.children;
    var modalActions = [
        {
            content: (actionsLabels === null || actionsLabels === void 0 ? void 0 : actionsLabels.cancel) || t.system("cancel"),
            type: "submit",
            onClick: onCancel,
            appearance: "outline",
        },
        {
            content: (actionsLabels === null || actionsLabels === void 0 ? void 0 : actionsLabels.confirm) || t.system("ok"),
            type: "submit",
            onClick: onConfirm,
            appearance: "primary",
            isDisabled: isDisabled,
        },
    ];
    return (_jsx(Modal, __assign({ width: "small", components: {
            Header: heading
                ? function () { return (_jsx(Box, __assign({ p: ESpacings.loose }, { children: _jsx(DisplayText, __assign({ size: "medium" }, { children: heading })) }))); }
                : undefined,
        }, onClose: onCancel, actions: modalActions, shouldCloseOnOverlayClick: false }, modalProps, { children: children })));
};
export default ModalConfirmation;
