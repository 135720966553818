import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { JSONSchema6 } from "json-schema";
import { UiSchema } from "react-native-jsonschema-form-test";
import { ts } from "../../services";
import { EField, IExtraData } from "./schema.createDocument.types";
import { getSortedCategories } from "./schema.createDocument.utils";

export const getSchema =
  (initialValues: { [key: string]: any }, { categories }: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [EField.Name, EField.Category],
    properties: {
      [EField.Name]: {
        default: getValue(EField.Name, initialValues, defaultValues),
      },
      [EField.Category]: {
        default: getValue(EField.Category, initialValues, defaultValues),
        // @ts-ignore
        options: getSortedCategories(categories).map(category => ({
          value: category.label,
          id: category.id,
        })),
      },
    },
  });

export const uiSchema = (_extraData: IExtraData): UiSchema => ({
  "rentio:navigationTitle": ts.addFileModalHeading(),
  [EField.Name]: {
    "ui:field": ERentioFormField.Text,
    title: ts.addFileModalNameLabel(),
  },
  [EField.Category]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: ts.addFileModalCategoryLabel(),
  },
});
