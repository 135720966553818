var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import images from "../../assets";
import * as S from "./ListItem.styles";
var ListItem = /** @class */ (function (_super) {
    __extends(ListItem, _super);
    function ListItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderLeft = function () {
            var _a = _this.props, iconLeft = _a.iconLeft, iconLeftProps = _a.iconLeftProps, _b = _a.iconLeftIsRounded, iconLeftIsRounded = _b === void 0 ? false : _b, iconLeftText = _a.iconLeftText, renderComponentLeft = _a.renderComponentLeft;
            if (renderComponentLeft) {
                return renderComponentLeft();
            }
            if (iconLeft) {
                return (_jsx(S.LeftWrap, { children: _jsx(S.Icon, __assign({ source: iconLeft, isRounded: iconLeftIsRounded }, iconLeftProps)) }));
            }
            if (iconLeftText) {
                return (_jsx(S.LeftWrap, { children: _jsx(S.IonTextWrap, __assign({ isCompany: _this.props.isCompany }, { children: _jsx(S.IconText, __assign({ isCompany: _this.props.isCompany }, { children: iconLeftText })) })) }));
            }
        };
        _this.renderCenter = function () {
            var _a = _this.props, metaInfo = _a.metaInfo, title = _a.title, extraTitleComponent = _a.extraTitleComponent, subtitle = _a.subtitle, extraSubtitle = _a.extraSubtitle, footer = _a.footer, _b = _a.titleNumberOfLines, titleNumberOfLines = _b === void 0 ? 0 : _b, _c = _a.subtitleNumberOfLines, subtitleNumberOfLines = _c === void 0 ? 0 : _c, _d = _a.showHeader, showHeader = _d === void 0 ? true : _d;
            return (_jsxs(S.CenterWrap, { children: [!!metaInfo && _jsx(S.MetaInfo, { children: metaInfo }), !!title && (_jsxs(S.TitleWrap, { children: [typeof title === "string" && (_jsx(S.Title, __assign({ showHeader: showHeader, numberOfLines: titleNumberOfLines }, { children: title }))), React.isValidElement(title) && title, extraTitleComponent] })), !!subtitle && (_jsx(S.Subtitle, __assign({ numberOfLines: subtitleNumberOfLines }, { children: subtitle }))), !!extraSubtitle && _jsx(S.ExtraSubtitle, { children: extraSubtitle }), footer] }));
        };
        _this.renderRight = function () {
            var _a = _this.props, renderComponentRight = _a.renderComponentRight, iconRight = _a.iconRight;
            if (renderComponentRight) {
                return renderComponentRight();
            }
            if (iconRight) {
                return (_jsx(S.RightWrap, { children: _jsx(S.Icon, { source: iconRight, isRounded: false }) }));
            }
            return null;
        };
        _this.renderDisclosureInfo = function () {
            var _a = _this.props, disclosureInfo = _a.disclosureInfo, onPress = _a.onPress, _b = _a.suppressTouchableStyle, suppressTouchableStyle = _b === void 0 ? false : _b, _c = _a.forceTouchableStyle, forceTouchableStyle = _c === void 0 ? false : _c, _d = _a.showDisclosureIcon, showDisclosureIcon = _d === void 0 ? false : _d;
            if (!disclosureInfo) {
                return;
            }
            return (_jsx(S.DisclosureInfoWrap, __assign({ withExtraRightMargin: showDisclosureIcon }, { children: _jsx(S.DisclosureInfo, __assign({ hasTouchableStyle: (!!onPress && !suppressTouchableStyle) || forceTouchableStyle }, { children: disclosureInfo })) })));
        };
        _this.renderDisclosure = function () {
            var showDisclosureIcon = _this.props.showDisclosureIcon;
            if (!showDisclosureIcon) {
                return null;
            }
            return (_jsx(S.IconDisclosureWrap, { children: _jsx(S.IconDisclosure, { source: images.ICO_DISCLOSURE }) }));
        };
        return _this;
    }
    // Render
    ListItem.prototype.render = function () {
        var _a = this.props, onPress = _a.onPress, _b = _a.isActive, isActive = _b === void 0 ? false : _b, _c = _a.isActiveLink, isActiveLink = _c === void 0 ? false : _c, _d = _a.isArchived, isArchived = _d === void 0 ? false : _d, _e = _a.disabled, disabled = _e === void 0 ? false : _e, rest = __rest(_a, ["onPress", "isActive", "isActiveLink", "isArchived", "disabled"]);
        if (onPress) {
            return (_jsx(S.WrapTouchable, __assign({ onPress: onPress, isActive: isActive, isArchived: isArchived, disabled: disabled }, rest, { children: this.renderContent() })));
        }
        if (isActiveLink) {
            return (_jsx(S.WrapTouchableLink, __assign({ isActiveLink: isActiveLink, disabled: disabled }, rest, { children: this.renderContent() })));
        }
        return (_jsx(S.Wrap, __assign({ isActive: isActive, isArchived: isArchived, disabled: disabled }, rest, { children: this.renderContent() })));
    };
    ListItem.prototype.renderContent = function () {
        return (_jsxs(React.Fragment, { children: [this.renderLeft(), this.renderCenter(), this.renderDisclosureInfo(), this.renderRight(), this.renderDisclosure()] }));
    };
    return ListItem;
}(React.PureComponent));
export default ListItem;
