var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToCamelCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import React from "react";
import { MultiStepForm } from "../../../../components/SharedForm/MultiStepForm";
import { insertAtomicBlock, performActions, removeSelectedText, } from "../../utils/draftjs.utils";
import { FieldsContext } from "../Editor/contexts/Fields.context";
import { DEFAULT_VARIABLES } from "../Editor/Editor.config";
import { EVariableType, } from "../Editor/Editor.types";
import { insertVariableAtStartOfSelection } from "./InsertVariableModal.utils";
import schemas from "./schemas/insertVariable";
import { EField } from "./schemas/insertVariable/schema.insertVariable.types";
import { OPTION_NEW } from "./schemas/variableForm.utils";
var InsertVariableModal = function (props) {
    var editorState = props.editorState, setEditorState = props.setEditorState, onClose = props.onClose, forceCreateNewVariable = props.forceCreateNewVariable;
    var _a = React.useContext(FieldsContext), fields = _a.fields, setFields = _a.setFields;
    // State
    var formId = React.useState(generateFormId())[0];
    // Helpers
    var getCleanFormData = function (formData) {
        var variableId = formData[EField.Name] !== OPTION_NEW ? formData[EField.Name] : undefined;
        var variableName = formData[EField.NameNew];
        var groupId = formData[EField.Group] !== OPTION_NEW
            ? formData[EField.Group]
            : undefined;
        var groupName = formData[EField.GroupNew];
        var type = formData[EField.Type];
        var isRequired = formData[EField.Required] === "yes";
        var defaultText = type && [EVariableType.String, EVariableType.Dropdown].includes(type)
            ? formData[EField.DefaultText]
            : undefined;
        var isMultilineText = type === EVariableType.String
            ? formData[EField.IsMultilineText] === "yes"
            : undefined;
        var defaultDate = type === EVariableType.Date ? formData[EField.DefaultDate] : undefined;
        var defaultDateFormatting = type === EVariableType.Date
            ? formData[EField.DefaultDateFormatting]
            : undefined;
        var minDate = type === EVariableType.Date ? formData[EField.MinDate] : undefined;
        var maxDate = type === EVariableType.Date ? formData[EField.MaxDate] : undefined;
        var defaultNumber = type === EVariableType.Date ? formData[EField.DefaultNumber] : undefined;
        var minNumber = type === EVariableType.Date ? formData[EField.MinNumber] : undefined;
        var maxNumber = type === EVariableType.Date ? formData[EField.MaxNumber] : undefined;
        var defaultToggle = type === EVariableType.Boolean
            ? formData[EField.DefaultToggle] === "yes"
            : undefined;
        var dropdownValues = type === EVariableType.Dropdown
            ? (formData[EField.DropdownValues] || "").split(",")
            : undefined;
        var persisted = formData[EField.Persisted] === "yes";
        return {
            variableId: variableId,
            variableName: variableName,
            groupId: groupId,
            groupName: groupName,
            type: type,
            isRequired: isRequired,
            defaultText: defaultText,
            isMultilineText: isMultilineText,
            defaultDate: defaultDate,
            defaultDateFormatting: defaultDateFormatting,
            minDate: minDate,
            maxDate: maxDate,
            defaultNumber: defaultNumber,
            minNumber: minNumber,
            maxNumber: maxNumber,
            defaultToggle: defaultToggle,
            dropdownValues: dropdownValues,
            persisted: persisted,
        };
    };
    var insertFields = function (formData) {
        var _a = getCleanFormData(formData), variableId = _a.variableId, variableName = _a.variableName, groupId = _a.groupId, groupName = _a.groupName, type = _a.type, newVariableConfig = __rest(_a, ["variableId", "variableName", "groupId", "groupName", "type"]);
        var existingVariable = __spreadArray(__spreadArray([], fields.variables, true), DEFAULT_VARIABLES, true).find(function (x) { return x.id === variableId; });
        // Create new group
        var newGroup = undefined;
        if (!groupId && groupName) {
            newGroup = {
                id: stringToCamelCase(groupName),
                name: groupName,
            };
        }
        // No group or ID => something went wrong
        if (!existingVariable && !groupId && !newGroup) {
            return;
        }
        // Create new variable
        var newVariable = undefined;
        if (!existingVariable && variableName) {
            newVariable = __assign({ id: stringToCamelCase(variableName), name: variableName, groupId: (newGroup === null || newGroup === void 0 ? void 0 : newGroup.id) || groupId || "-", type: type || EVariableType.String }, newVariableConfig);
        }
        // No variable or ID => something went wrong
        if (!existingVariable && !newVariable) {
            return;
        }
        setFields({
            groupId: (existingVariable === null || existingVariable === void 0 ? void 0 : existingVariable.groupId) || groupId,
            variableId: variableId,
            group: newGroup,
            variable: newVariable,
        });
        return existingVariable || newVariable;
    };
    // Event handlers
    var onSubmitInsertVariable = function (formData) {
        var variable = insertFields(formData);
        if (!variable) {
            onClose();
            return;
        }
        if (!setEditorState) {
            onClose();
            return;
        }
        if (variable.type === EVariableType.Image) {
            var data = {
                variableId: variable.id,
            };
            setEditorState(insertAtomicBlock({ editorState: editorState, type: "IMAGE", data: data }));
            onClose();
            return;
        }
        if (variable.type === EVariableType.Signature) {
            var data = {
                variableId: variable.id,
            };
            setEditorState(insertAtomicBlock({ editorState: editorState, type: "SIGNATURE", data: data }));
            onClose();
            return;
        }
        var newEditorState = performActions(editorState, [
            removeSelectedText,
            function (x) {
                return insertVariableAtStartOfSelection(x, { id: variable.id });
            },
        ]);
        setEditorState(newEditorState);
        onClose();
    };
    // Render
    return (_jsx(MultiStepForm, { formId: "insert-variable-".concat(formId), schemas: schemas({ fields: fields, forceCreateNewVariable: forceCreateNewVariable }), asModal: true, withAside: false, modalProps: {
            onClose: onClose,
            heading: getLocalizedText("editor.insert_custom.insert_variable.modal.title"),
            width: "medium",
        }, onSuccess: onSubmitInsertVariable, submitLabel: getLocalizedText("system.insert") }));
};
export default InsertVariableModal;
