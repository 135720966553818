import React from "react";
import * as S from "./TypingIndicator.styled";

export const TypingIndicator: React.FC<{}> = () => {
  return (
    <S.Indicator>
      <span></span>
      <span></span>
      <span></span>
    </S.Indicator>
  );
};
