import * as taskHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import { ITask } from "@rentiohq/shared/dist/types/task.types";
import DetailArchivedCard from "../../../../components/DetailArchivedCard";
import * as t from "../../../../services/translationService";

interface ITaskArchivedCardProps {
  task: ITask;
}

export const TaskArchivedCard = (props: ITaskArchivedCardProps) => {
  const { task } = props;

  const { unarchive } = taskHooks.useUnarchive({ id: task.id });

  const handleUnarchiveTask = () => {
    if (!task) {
      return;
    }

    unarchive({});
  };

  return (
    <DetailArchivedCard
      title={t.taskDashboardCardArchivedTitle()}
      description={t.taskDashboardCardArchivedContent()}
      ctaTitle={t.taskDashboardCardArchivedCta()}
      onClick={handleUnarchiveTask}
    ></DetailArchivedCard>
  );
};
