import { TAppearance } from "@rentiohq/web-shared/dist/types";
import styled, { spacing } from "@rentiohq/web-theme";
import { transparentize } from "polished";

export const Wrap = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: ${spacing("loose")};
`;

export const Row = styled.div<{ appearance?: TAppearance }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: ${spacing("tight")} 0;
  padding: ${spacing("loose")} ${spacing("extraLoose")};
  background-color: ${props => {
    switch (props.appearance) {
      case "info":
        return transparentize(0.8, props.theme.colors.blue[800]);

      case "success":
        return transparentize(0.8, props.theme.colors.green[800]);

      case "warning":
        return transparentize(0.8, props.theme.colors.yellow[800]);

      case "error":
        return transparentize(0.8, props.theme.colors.red[800]);

      default:
        return "inherit";
    }
  }};
`;

export const RoleCell = styled.div`
  flex: 10;
`;

export const HeaderCell = styled.div`
  flex: 8;
  text-align: center;
  padding: 0 1px;
`;

export const Cell = styled.div`
  flex: 8;
  display: flex;
  align-items: center;
  justify-content: center;
`;
