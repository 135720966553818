var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "..";
import DisplayText from "../DisplayText/DisplayText";
import * as S from "./Stages.styled";
export default function Stage(_a) {
    var stage = _a.stage, isLast = _a.isLast, index = _a.index, stageStyle = _a.stageStyle;
    var navigate = useNavigate();
    var onClick = useCallback(function () {
        if (stage.url)
            navigate(stage.url);
        if (stage.onClick)
            stage.onClick(stage);
    }, []);
    return (_jsxs(S.Stage, __assign({ appearance: stage.appearance, onClick: onClick, hasClick: stage.onClick || stage.url, isLast: isLast, isActive: stage.isActive || false, style: stageStyle }, { children: [_jsx(DisplayText, __assign({ size: "extraSmall", subdued: true, space: "tight" }, { children: stage.heading })), _jsxs(S.Body, { children: [_jsxs(S.Count, { children: [_jsx(S.CountNumber, { children: stage.count }), stage.countDescription && (_jsx(S.CountDescription, { children: stage.countDescription }))] }), _jsx(S.Icon, { children: _jsx(Icon, { source: stage.icon, size: "large" }) })] })] }), "stage-".concat(index)));
}
