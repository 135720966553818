var _a, _b;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./legalCase.constants";
var _c = generateActions(GENERATOR_PARAMS), getDetailStart = _c.getDetailStart, getDetailSucceeded = _c.getDetailSucceeded, getDetailFailed = _c.getDetailFailed, getPagedStart = _c.getPagedStart, getPagedFailed = _c.getPagedFailed, getPagedSucceeded = _c.getPagedSucceeded, getCountStart = _c.getCountStart, getCountFailed = _c.getCountFailed, getCountSucceeded = _c.getCountSucceeded, getInfiniteLoadStart = _c.getInfiniteLoadStart, getInfiniteLoadFailed = _c.getInfiniteLoadFailed, getInfiniteLoadSucceeded = _c.getInfiniteLoadSucceeded, createWithoutDocumentsStart = _c.createStart, createWithoutDocumentsSucceeded = _c.createSucceeded, createWithoutDocumentsFailed = _c.createFailed, updateWithoutDocumentsStart = _c.updateStart, updateWithoutDocumentsSucceeded = _c.updateSucceeded, updateWithoutDocumentsFailed = _c.updateFailed, removeStart = _c.removeStart, removeSucceeded = _c.removeSucceeded, removeFailed = _c.removeFailed;
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["CreateWithDocuments"] = "createWithDocuments";
    EActionCustom["UpdateWithDocuments"] = "updateWithDocuments";
})(EActionCustom || (EActionCustom = {}));
var generateCreateLegalWithDocumentCaseActions = function (params) {
    var entity = params.entity;
    var createWithDocumentsStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.CreateWithDocuments,
        state: EActionState.Start,
    });
    var createWithDocumentsSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.CreateWithDocuments,
        state: EActionState.Success,
    });
    var createWithDocumentsFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.CreateWithDocuments,
        state: EActionState.Fail,
    });
    return {
        createWithDocumentsStart: createWithDocumentsStart,
        createWithDocumentsSucceeded: createWithDocumentsSucceeded,
        createWithDocumentsFailed: createWithDocumentsFailed,
    };
};
var generateUpdateLegalWithDocumentCaseActions = function (params) {
    var entity = params.entity;
    var updateWithDocumentsStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.UpdateWithDocuments,
        state: EActionState.Start,
    });
    var updateWithDocumentsSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.UpdateWithDocuments,
        state: EActionState.Success,
    });
    var updateWithDocumentsFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.UpdateWithDocuments,
        state: EActionState.Fail,
    });
    return {
        updateWithDocumentsStart: updateWithDocumentsStart,
        updateWithDocumentsSucceeded: updateWithDocumentsSucceeded,
        updateWithDocumentsFailed: updateWithDocumentsFailed,
    };
};
export { createWithoutDocumentsFailed, createWithoutDocumentsStart, createWithoutDocumentsSucceeded, getCountFailed, getCountStart, getCountSucceeded, getDetailFailed, getDetailStart, getDetailSucceeded, getInfiniteLoadFailed, getInfiniteLoadStart, getInfiniteLoadSucceeded, getPagedFailed, getPagedStart, getPagedSucceeded, removeFailed, removeStart, removeSucceeded, updateWithoutDocumentsFailed, updateWithoutDocumentsStart, updateWithoutDocumentsSucceeded, };
export var createWithDocumentsFailed = (_a = generateCreateLegalWithDocumentCaseActions(GENERATOR_PARAMS), _a.createWithDocumentsFailed), createWithDocumentsStart = _a.createWithDocumentsStart, createWithDocumentsSucceeded = _a.createWithDocumentsSucceeded;
export var updateWithDocumentsStart = (_b = generateUpdateLegalWithDocumentCaseActions(GENERATOR_PARAMS), _b.updateWithDocumentsStart), updateWithDocumentsSucceeded = _b.updateWithDocumentsSucceeded, updateWithDocumentsFailed = _b.updateWithDocumentsFailed;
