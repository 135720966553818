var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
import React from "react";
import { DisplayText, Modal, MultiStepForm, TextStyle, } from "../../../../../../components";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { DisplayTypeContext } from "../../contexts/DisplayType.context";
import { FieldsContext } from "../../contexts/Fields.context";
import GroupInfo from "../GroupInfo";
import schemas from "./form/updateGroup";
import { EField } from "./form/updateGroup/schema.updateGroup.types";
import * as S from "./GroupModal.styles";
var GroupModal = function (props) {
    var _a;
    var groupId = props.groupId, onClose = props.onClose;
    // Context
    var isTemplate = React.useContext(DisplayTypeContext).isTemplate;
    var _b = React.useContext(FieldsContext), fields = _b.fields, setFields = _b.setFields, removeGroup = _b.removeGroup;
    var group = (_a = fields.groups) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === groupId; });
    // Refs
    var editFormRef = React.createRef();
    // State
    var formId = React.useState(generateFormId())[0];
    var _c = React.useState(0), tabIndex = _c[0], setTabIndex = _c[1];
    // Event handlers
    var handleRemoveDangerously = function () {
        confirm({
            title: getLocalizedText("editor.variable_all_instances.remove.confirm.title"),
            primaryActions: [
                {
                    title: getLocalizedText("system.remove"),
                    onPress: function () {
                        if (!group) {
                            return;
                        }
                        removeGroup(group.id);
                    },
                },
            ],
        });
    };
    var onUpdateGroup = function (formData) {
        if (!group || !groupId) {
            showAlert({
                type: "error",
                message: getLocalizedText("No group"),
            });
            return;
        }
        var updatedGroup = __assign(__assign({}, group), { name: formData[EField.Name], sortWeight: formData[EField.SortWeight] });
        setFields({ group: updatedGroup });
        showAlert({
            type: "info",
            message: getLocalizedText("Group updated"),
        });
        onClose();
    };
    // Render
    if (!group) {
        return (_jsx(Modal, __assign({ onClose: onClose, heading: "Group", shouldCloseOnOverlayClick: true, hasDismiss: true, width: "medium" }, { children: _jsx(DisplayText, __assign({ size: "small" }, { children: _jsx(TextStyle, __assign({ variation: "subdued" }, { children: "Group not found" })) })) })));
    }
    var info = _jsx(GroupInfo, { group: group });
    var edit = (_jsx(MultiStepForm, { formId: "edit-variable-".concat(formId), schemas: schemas({ fields: fields, group: group }), withAside: false, onSuccess: onUpdateGroup, showControls: false, submitLabel: getLocalizedText("system.update"), ref: editFormRef }));
    var extra = (_jsxs(_Fragment, { children: [_jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.variable.info_group.remove_dangerously.info") })), _jsx(Spacer, { weight: ESpacerWeight.W04 }), _jsx(S.RemoveButton, { title: getLocalizedText("system.remove_dangerously"), onPress: handleRemoveDangerously })] }));
    return (_jsx(Modal, { onClose: onClose, heading: getLocalizedText(group.name), shouldCloseOnOverlayClick: true, hasDismiss: true, width: "medium", onChangeTab: setTabIndex, tabs: [
            {
                content: getLocalizedText("system.info"),
                component: info,
                isShown: true,
            },
            {
                content: getLocalizedText("system.edit"),
                component: edit,
                isShown: isTemplate,
            },
            {
                content: getLocalizedText("system.extra"),
                component: extra,
                isShown: isTemplate,
            },
        ].filter(function (x) { return x.isShown; }), actions: tabIndex === 1
            ? [
                {
                    content: getLocalizedText("system.update"),
                    onClick: function () {
                        var _a;
                        (_a = editFormRef.current) === null || _a === void 0 ? void 0 : _a.handleExternalSubmit();
                    },
                    appearance: "primary",
                },
            ]
            : undefined }));
};
export default GroupModal;
