import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ILegalCasePayment } from "@rentiohq/shared/dist/types/legalCase.types";
import {
  ESpacings,
  Grid,
  Icon,
  ResourceListItem,
  TAssetSource,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { DocumentListItem } from "components/DetailDrawers/Drawers/RentDeposit/components/RentDepositInfo/components/DocumentRow";
import { memo } from "react";

export interface IManuallyAddedPaymentProps {
  manualPayment: ILegalCasePayment;
  index: number;
  icon?: TAssetSource;
  title?: string;
  documentsTitle?: string;
}

const ManuallyAddedPayment = ({
  manualPayment,
  index,
  icon = "forRentSign",
  title,
  documentsTitle,
}: IManuallyAddedPaymentProps) => {
  const no = index + 1;

  const docsHeading =
    documentsTitle ||
    getLocalizedText("rentio_legal.case.detail.documents.header");

  const heading = `#${no} - ${
    title || getLocalizedText("rentio_legal.case.detail.manual_payments.header")
  }`;

  return (
    <ResourceListItem
      boxProps={{ p: ESpacings.tight }}
      media={<Icon source={icon} />}
      mediaSize="medium"
      mediaVerticalAlign="start"
    >
      <Grid flexDirection="column">
        <Grid.Item>
          <div>{heading}</div>
          <TextStyle variation="subdued">
            <div>{manualPayment.info}</div>
          </TextStyle>
        </Grid.Item>

        {manualPayment.documentIds && manualPayment.documentIds.length > 0 && (
          <Grid.Item>
            <TextStyle variation="subdued">{docsHeading}</TextStyle>

            {manualPayment.documentIds.map(docId => (
              <DocumentListItem documentId={docId} />
            ))}
          </Grid.Item>
        )}
      </Grid>
    </ResourceListItem>
  );
};

export default memo(ManuallyAddedPayment);
