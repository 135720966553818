import { Box } from "@rebass/grid";
import {
  isPerformingSubmitActionSelector as isPerformingSubmitActionSelectorIban,
  performingSubmitActionErrorSelector as performingSubmitActionErrorSelectorIban,
  performingSubmitActionResultSelectorIban,
  submitActionCreator as submitActionCreatorIban,
} from "@rentiohq/shared-frontend/dist/forms/addIban/schema.addIban.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentApi from "@rentiohq/shared-frontend/dist/redux/payment/payment.api";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import logger from "@rentiohq/shared/dist/logger";
import { IIban } from "@rentiohq/shared/dist/types/contact.types";
import {
  DisplayText,
  ESpacings,
  Loading,
  LoadingWrapper,
  MultiStepForm,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import addBankAccountsSchemas from "@rentiohq/web-shared/dist/forms/addBankAccount";
import { IBankaccountProps } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../services";

const customContactId = EContactCustomId.MeMaster;

export const ContactBankAccounts = () => {
  const dispatch = useDispatch();

  const { isBroker } = authHooks.useSelf();

  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const [isFetchingMandate, setIsFetchingMandate] =
    React.useState<boolean>(false);
  const formId = React.useRef(generateFormId());

  const contact = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, customContactId),
  );

  const ibans = useSelector((state: IRootStore) =>
    contact ? contactSelectors.getIbans(state, contact.id) : undefined,
  );
  const ibansFetchError = useSelector((state: IRootStore) =>
    contact ? contactSelectors.fetchIbanError(state, contact.id) : undefined,
  );
  const isFetchingIbans = useSelector((state: IRootStore) =>
    contact ? contactSelectors.isFetchingIbans(state, contact.id) : false,
  );

  React.useEffect(() => {
    if (!contact) {
      dispatch(
        contactActions.getContact.actions.start({
          contactId: customContactId,
        }),
      );
      return;
    }

    dispatch(
      contactActions.getIbans.actions.start({
        contactId: contact.id,
      }),
    );
  }, [contact]);

  const requestMandate = async (iban: IIban) => {
    // Request mandate
    try {
      setIsFetchingMandate(true);
      const { data: mandateRequest } = await paymentApi.getMandate(
        iban.id,
        window.location.href,
      );
      const redirectUrl = mandateRequest.RedirectUrl;

      setIsFetchingMandate(false);
      window.open(redirectUrl, "_self");
    } catch (unknownError) {
      const error = unknownError as any;
      setIsFetchingMandate(false);
      logger.logError({ error });
    }
  };

  const handleSuccess = (iban: IIban) => {
    try {
      if (iban.isThirdpartyIban) {
        requestMandate(iban);
      }
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  // const renderReportingContent = () => {
  //   if (!contact) return;
  //   const schema = reportingSettingsSchemas({
  //     contact,
  //   });
  //   return (
  //     <LoadingWrapper isLoading={!contact}>
  //       <Box mt={5}>
  //         <MultiStepForm
  //           formId={`profile-reporting_settings-${formId.current}`}
  //           schemas={schema}
  //           asModal={false}
  //           withAside={false}
  //           submitLabel={ts.system("save")}
  //           submitActionCreator={(formData: any) => {
  //             return submitActionCreatorUpdateContact(contact.id)(formData);
  //           }}
  //         ></MultiStepForm>
  //       </Box>
  //     </LoadingWrapper>
  //   );
  // };

  const renderBankAccountContent = () => {
    if (!contact && isFetchingIbans) {
      return <Loading />;
    }
    if (ibansFetchError) {
      return <p>{getLocalizedText("fetch.error")}</p>;
    }
    if (!contact) {
      return null;
    }
    return (
      <LoadingWrapper isLoading={isFetchingMandate}>
        <DisplayText size="small" element="h4" space="tight">
          {getLocalizedText("system.entity.bankaccounts")}
        </DisplayText>
        <Box mb={ESpacings.base}>
          <ResourceList items={ibans || []} renderItem={renderBankAccount} />
        </Box>
        <Button appearance="primary" onClick={handleAddBankAccount}>
          {ts.bankAccountAddAction()}
        </Button>
      </LoadingWrapper>
    );
  };

  const renderBankAccountMeta = (bankAccount: IIban) => {
    let meta = [bankAccount.ownerName];
    let metaTypes = [];
    if (bankAccount.isInvoiceIban) {
      metaTypes.push(getLocalizedText("bank_account.type.is_invoice.label"));
    }
    if (bankAccount.isThirdpartyIban) {
      metaTypes.push(
        getLocalizedText("bank_account.type.is_third-party.label"),
      );
    }
    if (bankAccount.isRevenueIban) {
      metaTypes.push(getLocalizedText("bank_account.type.is_revenue.label"));
    }

    if (metaTypes.length > 0) {
      meta.push(metaTypes.join(", "));
    }

    if (bankAccount.mandateApprovedAt && bankAccount.isThirdpartyIban) {
      meta.push(
        getLocalizedText(`bank_account.mandate.approved_at`, {
          date: formatDate(bankAccount.mandateApprovedAt),
        }),
      );
    }

    if (bankAccount.mandateApprovedAt && !bankAccount.isThirdpartyIban) {
      meta.push(getLocalizedText(`bank_account.mandate.not_approved`));
    }

    return meta.join(" | ");
  };

  const renderBankAccount = (bankAccount: IBankaccountProps) => {
    return (
      <ResourceListItem boxProps={{ py: ESpacings.base }} item={bankAccount}>
        <TextStyle variation="strong" element="div">
          {bankAccount.iban}
        </TextStyle>
        <TextStyle variation="subdued" size="small">
          {renderBankAccountMeta(bankAccount)}
        </TextStyle>
      </ResourceListItem>
    );
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleAddBankAccount = () => {
    setModalOpen(true);
  };

  const renderBankAccountsForm = () => {
    if (!contact || !isModalOpen) {
      return null;
    }

    const bankAccountSchemas = addBankAccountsSchemas({
      contact,
      isBroker,
    });

    return (
      <MultiStepForm
        formId={`profile-bank_accounts-${formId.current}`}
        schemas={bankAccountSchemas}
        asModal={true}
        withAside={false}
        onSuccess={handleSuccess}
        modalProps={{ onClose: handleClose }}
        submitLabel={ts.system("save")}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelectorIban()}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelectorIban()}
        performingSubmitActionResultSelector={performingSubmitActionResultSelectorIban(
          undefined,
          contact.id,
        )}
        submitActionCreator={(formData: any) => {
          return submitActionCreatorIban(
            undefined,
            contact.id,
          )({
            ...formData,
            resourceContactId: contact.id,
          });
        }}
      />
    );
  };

  return (
    <>
      {renderBankAccountContent()}
      {renderBankAccountsForm()}
      {/* {renderReportingContent()} */}
    </>
  );
};
