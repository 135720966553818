export var EField;
(function (EField) {
    EField["Info"] = "info";
    EField["MemberThatCancels"] = "memberThatCancels";
    EField["CancellationDate"] = "cancellationDate";
    EField["StopDate"] = "stopDate";
    EField["ReasonForCancellationTenant"] = "reasonForCancellationTenant";
    EField["ReasonForCancellationOwner"] = "reasonForCancellationOwner";
    EField["ReasonForCancellationOther"] = "reasonForCancellationOther";
    EField["CreateCompensationPayment"] = "createCompensationPayment";
    EField["CancellationDocuments"] = "cancellationDocuments";
})(EField || (EField = {}));
export var ECancellationReasons;
(function (ECancellationReasons) {
    ECancellationReasons["NoMotiveNoticePeriod"] = "no_motive_notice_period";
    ECancellationReasons["NoMotiveCancellationFee"] = "no_motive_cancellation_fee";
    ECancellationReasons["NoMotiveWithoutCancellationFee"] = "no_motive_without_cancellation_fee";
    ECancellationReasons["OwnUse"] = "own_use";
    ECancellationReasons["FamilyUse"] = "family_use";
    ECancellationReasons["Renovation"] = "renovation";
    ECancellationReasons["Other"] = "other";
})(ECancellationReasons || (ECancellationReasons = {}));
