import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerDirection,
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import {
  ESpacings,
  Icon,
  ResourceListItem,
  TextStyle,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import utils from "@rentiohq/web-shared/dist/utils";
import React, { ReactNode } from "react";
import { AddressCell } from "../AddressCell";
import { ForRentCell } from "../ForRentCell";
import { WIDTH_ADDRESS } from "./PropertyListItem.constants";
import { PropertyListItemItem } from "./PropertyListItem.header";
import * as S from "./PropertyListItem.styles";

export interface IPropertyListItemProps {
  property: IProperty;
  link?: string;
  selectedParentId?: number;
  onSetParentId: (parentId?: number) => void;
  children?: ReactNode | ReactNode[];
}

const PropertyListItem: React.FC<IPropertyListItemProps> = ({
  property,
  link,
  selectedParentId,
  onSetParentId,
  children,
}: IPropertyListItemProps) => {
  const isGroup = property.typeId === EPropertyTypeId.Group;
  const inGroup = !!property.parentId;

  const { count: propertiesInGroupCount } = propertyHooks.useCount(
    isGroup
      ? {
          query: {
            filter: {
              and: [
                { archivedAt: { is: null } },
                { parentId: { eq: property.id } },
              ],
            },
          },
          shouldRefetch: true,
        }
      : {},
  );

  const { detail: parentProperty } = propertyHooks.useDetail({
    id: inGroup ? property.parentId : undefined,
  });

  // Event handlers
  const handleGoToGroup = (groupPropertyId?: number) => (e: any) => {
    if (!groupPropertyId) {
      return;
    }

    e.preventDefault?.();
    e.stopPropagation?.();
    e.stopImmediatePropagation?.();
    e.nativeEvent?.stopImmediatePropagation?.();

    onSetParentId(groupPropertyId);
  };

  const mappedLink = isGroup
    ? `/properties/${property.id}/group-info`
    : link || `/properties/${property.id}`;

  // Render
  const renderTooltipContent = () => {
    if (!inGroup) {
      return;
    }

    return (
      <>
        <TextStyle>
          {getLocalizedText("property.part_of_group_info", {
            group: parentProperty
              ? formatAddress(parentProperty)
              : `${property.parentId}`,
          })}
        </TextStyle>
        {selectedParentId !== property.parentId && (
          <>
            <Box mb={ESpacings.extraTight} />
            <TextStyle variation="subdued">
              {getLocalizedText(
                "property.view_properties_in_group_as_child.action",
              )}
            </TextStyle>
          </>
        )}
      </>
    );
  };

  const renderMedia = () => (
    <Tooltip tooltipContent={renderTooltipContent()}>
      <S.IconWrapper
        onClick={inGroup ? handleGoToGroup(property.parentId) : undefined}
        inGroup={inGroup}
      >
        <S.Icon>
          <Icon
            source={
              property.typeId
                ? utils.properties.getIcon(property.typeId)
                : "house"
            }
            size="mediumLarge"
            badge={
              isGroup && propertiesInGroupCount !== undefined
                ? {
                    content: `${propertiesInGroupCount}`,
                    appearance: "success",
                  }
                : undefined
            }
          />
        </S.Icon>
      </S.IconWrapper>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <ResourceListItem
        item={property}
        mediaSize="mediumLarge"
        media={renderMedia()}
        link={mappedLink}
      >
        <S.InnerWrap>
          <div>
            <PropertyListItemItem
              style={{ width: WIDTH_ADDRESS }}
              tooltipContent={
                <>
                  <TextStyle>{formatAddress(property)}</TextStyle>
                  <Box mb={ESpacings.extraTight} />
                  <TextStyle variation="subdued">
                    {property.reference && <>{property.reference} | </>}
                    {property.name}
                  </TextStyle>
                </>
              }
            >
              <div style={{ width: WIDTH_ADDRESS }}>
                <AddressCell property={property} />
              </div>
            </PropertyListItemItem>

            {property.typeId === EPropertyTypeId.Group && (
              <>
                <Box mb={ESpacings.extraTight} />
                <Button
                  appearance="link"
                  onClick={handleGoToGroup(property.id)}
                >
                  {getLocalizedText(
                    "property.view_properties_in_group.action",
                    {
                      count: `${propertiesInGroupCount || 0}`,
                    },
                    propertiesInGroupCount || 0,
                  )}
                </Button>
              </>
            )}
          </div>

          <ForRentCell forRentStatus={property.forRentStatus} />

          <Spacer
            weight={ESpacerWeight.W10}
            direction={ESpacerDirection.Horizontal}
          />

          <S.ChildrenWrap>{children}</S.ChildrenWrap>
        </S.InnerWrap>
      </ResourceListItem>
    </React.Fragment>
  );
};

export default PropertyListItem;
