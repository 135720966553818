var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EProduct } from "@rentiohq/shared/dist/config/app.config.types";
import "draft-js/dist/Draft.css";
import { TextStyle } from "../../../../../../components";
import { useInternalMode } from "../../../../../../redux/system/system.hooks";
import { EVariableType, } from "../../Editor.types";
import { DefaultVariableLozenge } from "../DefaultVariableLozenge";
var NOW = new Date();
var VariableInfo = function (props) {
    var variable = props.variable, group = props.group;
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    var type = variable.type, _a = variable.isRequired, isRequired = _a === void 0 ? true : _a, defaultText = variable.defaultText, isMultilineText = variable.isMultilineText, defaultNumber = variable.defaultNumber, minNumber = variable.minNumber, maxNumber = variable.maxNumber, defaultDate = variable.defaultDate, defaultDateFormatting = variable.defaultDateFormatting, minDate = variable.minDate, maxDate = variable.maxDate, defaultToggle = variable.defaultToggle, dropdownValues = variable.dropdownValues, persisted = variable.persisted;
    // Render
    return (_jsxs(_Fragment, { children: [_jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.variable.info_group") })), _jsx("div", { children: getLocalizedText(group.name) }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), (CONFIG.PRODUCT === EProduct.Backoffice || internalModeEnabled) && (_jsxs(_Fragment, { children: [_jsx(TextStyle, __assign({ variation: "subdued" }, { children: "Variable ID (Used for internal mapping to values)" })), _jsxs("div", { children: [variable.id, " ", _jsx(DefaultVariableLozenge, { variable: variable })] }), _jsx(Spacer, { weight: ESpacerWeight.W12 })] })), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.type") })), _jsx("div", { children: getLocalizedText("editor.variable.type.".concat(type)) }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.required") })), _jsx("div", { children: isRequired
                    ? getLocalizedText("system.required")
                    : getLocalizedText("system.optional") }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), variable.sortWeight !== undefined && (_jsxs(_Fragment, { children: [_jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.variable.sort_weight") })), _jsx("div", { children: variable.sortWeight })] })), [EVariableType.String, EVariableType.Dropdown].includes(type) && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.default_text") })), _jsx("div", { children: defaultText || "-" })] })), type === EVariableType.String && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.is_multiline_text") })), _jsx("div", { children: isMultilineText
                            ? getLocalizedText("system.yes")
                            : getLocalizedText("system.no") })] })), type === EVariableType.Number && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.default_number") })), _jsx("div", { children: defaultNumber || "-" }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.variable.info_range.number") })), _jsx("div", { children: !minNumber && !maxNumber
                            ? "-"
                            : "".concat(minNumber || "-").concat(" - ").concat(maxNumber || "-") })] })), type === EVariableType.Date && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.default_date_formatting") })), _jsx("div", { children: formatDate(NOW, defaultDateFormatting) }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.default_date") })), _jsx("div", { children: defaultDate ? formatDate(defaultDate) : "-" }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.variable.info_range.date") })), _jsx("div", { children: !minDate && !maxDate
                            ? "-"
                            : "".concat(minDate ? formatDate(minDate) : "-").concat(" - ").concat(maxDate ? formatDate(maxDate) : "-") })] })), type === EVariableType.Boolean && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.default_toggle") })), _jsx("div", { children: defaultToggle !== undefined
                            ? getLocalizedText("system.".concat(defaultToggle ? "yes" : "no"))
                            : "-" })] })), type === EVariableType.Dropdown && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.dropdown_values") })), _jsx("div", { children: dropdownValues && dropdownValues.length > 0
                            ? dropdownValues.join(", ")
                            : "-" })] })), _jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.insert_custom.insert_variable.field.is_persisted") })), _jsx("div", { children: persisted
                    ? getLocalizedText("system.yes")
                    : getLocalizedText("system.no") })] }));
};
export default VariableInfo;
