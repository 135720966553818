import { Box, Flex } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import * as expertInquirySelectors from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.selectors";
import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import * as rentDepositSelectors from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.selectors";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentSelectors from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.selectors";
import { ETemplateDocumentStatus } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as templateDocumentUtils from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { canAddPaymentOrders } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  capitalizeString,
  stringToSnakeCase,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";
import {
  EContractType,
  EInsuranceExternalStatus,
  EMoveServiceStatus,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import {
  EDocumentPackageStatus,
  EDocumentPackageTypes,
} from "@rentiohq/shared/dist/types/documentPackage.types";
import { EPaymentOrderType } from "@rentiohq/shared/dist/types/payment.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { ERegistrationContractStatus } from "@rentiohq/shared/dist/types/registration.types";
import { ERentDepositStatus } from "@rentiohq/shared/dist/types/rentDeposit.types";
import { appendWhere } from "@rentiohq/shared/dist/utils/api.utils";
import {
  addMonths,
  isAfter,
  isBefore,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import {
  Card,
  ESpacings,
  Grid,
  Icon,
  ResourceList,
  TextStyle,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import { renderActions } from "@rentiohq/web-shared/dist/components/Button";
import RentioInternalRenderer from "@rentiohq/web-shared/dist/components/RentioInternalRenderer";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { CancelContractModal } from "components/CancelContractModal";
import { requestInsuranceValidationErrors } from "forms/requestInsurance/schema.requestInsurance.utils";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { NumberParam, StringParam } from "serialize-query-params";
import { IndexContractModal } from "../../../../../../components/IndexContractModal";
import { LocationDescriptionModal } from "../../../../../../components/LocationDescriptionModal";
import RequestInsuranceModal from "../../../../../../components/RequestInsuranceModal";
import RequestMoveServiceModal from "../../../../../../components/RequestMoveServiceModal";
import TemplateDocumentContractCreateModal from "../../../../../../components/TemplateDocumentContractCreateModal";
import { rs, ts } from "../../../../../../services";
import { getContractStatus } from "../../../../../../utils/contract";
import {
  getSignerInfo,
  renderSignersTooltip,
} from "../../../../../../utils/documentPackage.utils";
import {
  getAvailableTemplatesForType,
  getIndexationMessage,
} from "./ContractCard.utils";
import {
  ChecklistItem,
  EActionStatus,
  IChecklistItem,
} from "./components/ChecklistItem";
import { ContractMetaData } from "./components/ContractMetaData";

interface IProps {
  contract: IContract;
  property: IProperty;
  showContractType?: boolean;
  onCancelContract?: () => void;
}

export const ContractCard = ({
  contract,
  showContractType = true,
  property,
  onCancelContract,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let location = useLocation();

  const {
    manuallyCompletedTemplateDocumentContract,
    manuallyCompletedDocumentSigning,
    manuallyCompletedRentDeposit,
    manuallyCompletedLocationDescription,
    manuallyCompletedPaymentRent,
    manuallyCompletedMove,
    manuallyCompletedInsurance,
    manuallyCompletedRegistration,
  } = contract;

  const CONTRACTS_PAYMENT_ORDERS_COUNT_IDENTIFIER = `CONTRACTS_PAYMENT_ORDERS_COUNT_IDENTIFIER_${contract.id}`;

  const [queryParams, setQueryParamValue] = useQueryParams({
    documentId: StringParam,
    rentDepositId: NumberParam,
    registrationId: NumberParam,
    insuranceContractId: StringParam,
    requestInsuranceContractId: StringParam,
    templateDocumentId: StringParam,
    moveRequestId: NumberParam,
  });

  const { update } = contractHooks.useUpdate({
    id: contract.id,
  });

  // Helpers vars
  const rentDepositsIdentifier = `rent-deposits-contract-${contract.id}`;
  const documentPackagesIdentifier = `document-packages-contract-${contract.id}`;

  // Local state
  const [showIndexContractModal, setShowIndexContractModal] = useState(false);
  const [
    showTemplateDocumentContractCreate,
    setShowTemplateDocumentContractCreate,
  ] = useState(false);
  // const [
  //   showTemplateDocumentContractInfo,
  //   setShowTemplateDocumentContractInfo,
  // ] = useState(false);
  const [showLocationDescriptionModal, setShowLocationDescriptionModal] =
    useState(false);
  const [showRequestInsuranceModal, setShowRequestInsuranceModal] =
    useState(false);
  const [didShowAutomaticInsuranceModal, setDidShowAutomaticInsuranceModal] =
    useState(false);
  const [showRequestMoveService, setShowRequestMoveServiceModal] =
    useState(false);
  const [showCancelContractModal, setShowCancelContractModal] = useState(false);

  // Redux selectors
  const { internalModeEnabled } = useInternalMode();
  const { isBroker } = authHooks.useSelf();
  const hasMoveServiceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.MoveService,
  );
  const hasInsuranceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Insurance,
  );

  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );
  const availableTemplatesForContract = getAvailableTemplatesForType({
    type: "contract",
    isBroker,
    templates,
    contract,
    property,
  });
  const isFetchingTemplates = useSelector((state: IRootStore) =>
    templateSelectors.paged.isFetchingPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );

  const contractTemplateDocumentsFilter = {
    where: {
      contractId: contract.id,
      type: { like: "%contract%", options: "i" },
    },
  };
  const contractTemplateDocuments = useSelector((state: IRootStore) =>
    templateDocumentSelectors.paged.dataForPage(state, {
      id: templateDocumentUtils.getPagedId({
        filter: contractTemplateDocumentsFilter,
      }),
      page: 0,
    }),
  );
  const isFetchingContractTemplateDocuments = useSelector((state: IRootStore) =>
    templateDocumentSelectors.paged.isFetchingPage(state, {
      id: templateDocumentUtils.getPagedId({
        filter: contractTemplateDocumentsFilter,
      }),
      page: 0,
    }),
  );

  const isFetchingRentDeposits = useSelector((state: IRootStore) =>
    rentDepositSelectors.isFetchingRentDeposits(state, rentDepositsIdentifier),
  );
  const rentDeposits =
    useSelector((state: IRootStore) =>
      rentDepositSelectors.getRentDeposits(state, rentDepositsIdentifier),
    ) || [];

  const isFetchingDocumentPackage = useSelector((state: IRootStore) =>
    documentPackageSelectors.isFetchingDocumentPackage(
      state,
      documentPackagesIdentifier,
    ),
  );
  const documentPackages =
    useSelector((state: IRootStore) =>
      documentPackageSelectors.getDocumentPackages(
        state,
        documentPackagesIdentifier,
      ),
    ) || [];

  const isFetchingPaymentOrdersCount =
    useSelector((state: IRootStore) =>
      countSelectors.getIsFetchingCount(
        state,
        CONTRACTS_PAYMENT_ORDERS_COUNT_IDENTIFIER,
      ),
    ) || 0;
  const paymentOrdersCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(state, CONTRACTS_PAYMENT_ORDERS_COUNT_IDENTIFIER),
    ) || 0;

  const partnerships = useSelector((state: IRootStore) =>
    expertInquirySelectors.partnerships(state),
  );
  // const hasPartnershipsFeature = useFeatureFlag(
  //   "contract:location_description:partners",
  //   false,
  // );

  const status = getContractStatus(contract);
  const documentPackage =
    documentPackages.length > 0 ? documentPackages[0] : undefined;
  const { signerCount = 0, signedCount = 0 } = documentPackage
    ? getSignerInfo(documentPackage)
    : {};
  const isSignableDraft =
    [EContractType.Basic, null].includes(contract.contractType!) &&
    status === "draft";

  // Remove time restrictions
  const isContractStarting =
    isBefore(new Date(), addMonths(contract.startDate, 1)) &&
    !contract.locationDescriptionInRequestedAt;
  const isContractEnding =
    isAfter(new Date(), addMonths(contract.startDate, 1)) &&
    !contract.locationDescriptionOutRequestedAt;

  useEffect(() => {
    if (isFetchingContractTemplateDocuments) {
      return;
    }

    dispatch(
      templateDocumentActions.getPaged.actions.start({
        page: 0,
        // limit: 100,
        filter: contractTemplateDocumentsFilter,
      }),
    );
  }, []);

  useEffect(() => {
    if (isBroker && !hasInsuranceForBroker) {
      return;
    }

    if (didShowAutomaticInsuranceModal) {
      return;
    }

    if (!queryParams.requestInsuranceContractId) {
      return;
    }

    if (contract.id !== queryParams.requestInsuranceContractId) {
      return;
    }

    if (contract.insuranceExternalStatus !== EInsuranceExternalStatus.None) {
      return;
    }

    const validationErrors = requestInsuranceValidationErrors({
      property,
      contract,
    });

    if (validationErrors.length > 0) {
      return;
    }

    setShowRequestInsuranceModal(true);
    setDidShowAutomaticInsuranceModal(true);
  }, [contract, hasInsuranceForBroker, queryParams.requestInsuranceContractId]);

  useEffect(() => {
    dispatch(
      rentDepositActions.getRentDeposits.actions.start({
        identifier: rentDepositsIdentifier,
        refetch: true,
        extraFilterData: {
          where: {
            basicContractId: contract.id,
            status: { neq: ERentDepositStatus.Cancelled },
          },
          order: "createdAt DESC",
        },
        limit: 1,
      }),
    );

    dispatch(
      documentPackageActions.getDocumentPackages.actions.start({
        identifier: documentPackagesIdentifier,
        refetch: true,
        filterData: {
          where: {
            contractId: contract.id,
            type: EDocumentPackageTypes.Contract,
            status: {
              nin: [
                EDocumentPackageStatus.Revoked,
                EDocumentPackageStatus.Expired,
                EDocumentPackageStatus.Failed,
                EDocumentPackageStatus.Rejected,
                EDocumentPackageStatus.WorkerFailed,
              ],
            },
          },
          order: "createdAt DESC",
        },
        limit: 1,
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: CONTRACTS_PAYMENT_ORDERS_COUNT_IDENTIFIER,
        countBase: appendWhere("/payment-orders/count", {
          and: [{ contractId: contract.id }, { type: EPaymentOrderType.Rent }],
        }),
      }),
    );
  }, []);

  // Event handlers
  const handleViewRentDepositClick = () => {
    if (rentDeposits.length === 0) {
      return;
    }

    setQueryParamValue({ rentDepositId: rentDeposits[0].id });
  };

  const handleIndexContract = () => {
    setShowIndexContractModal(true);
  };

  const handleOrderLocationDescription = () => {
    setShowLocationDescriptionModal(true);
  };

  const handleClickRequestInsurance = () => {
    setShowRequestInsuranceModal(true);
  };

  const handleClickRequestMoveService = () => {
    setShowRequestMoveServiceModal(true);
  };

  const handleIndexModalClose = (documentId?: string) => {
    setShowIndexContractModal(false);
    setQueryParamValue({
      documentId,
    });
  };

  const handleLocationDescriptionModalClose = () => {
    setShowLocationDescriptionModal(false);
  };

  const handleClickRequestInsuranceModalClose = () => {
    setShowRequestInsuranceModal(false);
    setQueryParamValue({
      requestInsuranceContractId: undefined,
    });
  };

  const handleClickRequestMoveServiceModalClose = () => {
    setShowRequestMoveServiceModal(false);
  };

  const handleClickViewInsurance = () => {
    setQueryParamValue({
      insuranceContractId: contract.id,
    });
  };

  const handleChangeActionStatus = (params: {
    key:
      | "manuallyCompletedTemplateDocumentContract"
      | "manuallyCompletedDocumentSigning"
      | "manuallyCompletedRentDeposit"
      | "manuallyCompletedLocationDescription"
      | "manuallyCompletedPaymentRent"
      | "manuallyCompletedMove"
      | "manuallyCompletedInsurance"
      | "manuallyCompletedRegistration";
    entityKey: string;
    shouldCheck: boolean;
  }) => {
    const { key, entityKey, shouldCheck } = params;

    const entity = getLocalizedText(`system.${entityKey}`).toLowerCase();

    if (shouldCheck) {
      confirm({
        title: capitalizeString(
          getLocalizedText("contract_action.manual.check.confirm.title", {
            entity,
          }),
        ),
        info: capitalizeString(
          getLocalizedText("contract_action.manual.check.confirm.info", {
            entity,
          }),
        ),
        inputValueProps: {
          type: "string",
          initialValue: getLocalizedText("contract_action.manual"),
          extraInfoValue: getLocalizedText(
            `contract_action.manual.check_example.${entityKey}`,
          ),
        },
        primaryActions: [
          {
            title: getLocalizedText("system.check"),
            onPress: (params?: { inputValue?: string | Date }) => {
              const { inputValue = "" } = params || {};

              if (typeof inputValue !== "string") {
                return;
              }

              let reason = inputValue;
              if (
                reason.length === 0 ||
                reason === getLocalizedText("contract_action.manual")
              ) {
                reason = "contract_action.manual";
              }

              update({
                data: {
                  [key]: reason,
                },
                customSuccessMessage: capitalizeString(
                  getLocalizedText(
                    "contract_action.manual.check.toast.success.title",
                    { entity, reason: getLocalizedText(reason) },
                  ),
                ),
                customFailureMessage: capitalizeString(
                  getLocalizedText(
                    "contract_action.manual.check.toast.failure.title",
                    { entity },
                  ),
                ),
              });
            },
          },
        ],
      });

      return;
    }

    confirm({
      title: capitalizeString(
        getLocalizedText("contract_action.manual.uncheck.confirm.title", {
          entity,
        }),
      ),
      info: capitalizeString(
        getLocalizedText("contract_action.manual.uncheck.confirm.info", {
          entity,
        }),
      ),
      primaryActions: [
        {
          title: getLocalizedText("system.uncheck"),
          onPress: () => {
            update({
              data: {
                [key]: null,
              },
              customSuccessMessage: capitalizeString(
                getLocalizedText(
                  "contract_action.manual.uncheck.toast.success.title",
                  { entity },
                ),
              ),
              customFailureMessage: capitalizeString(
                getLocalizedText(
                  "contract_action.manual.uncheck.toast.failure.title",
                  { entity },
                ),
              ),
            });
          },
        },
      ],
    });

    return;
  };

  // Render
  const renderDocumentPackageInfo = () => {
    if (!documentPackage) {
      return null;
    }

    if (
      [
        EDocumentPackageStatus.Pending,
        EDocumentPackageStatus.Finished,
      ].includes(documentPackage.status!)
    ) {
      return (
        <Grid spacing="tight" alignItems="center" justifyContent="flex-end">
          <Grid.Item>
            <Icon source="contentPenWrite" size="small" />
          </Grid.Item>
          <Grid.Item>
            <Tooltip tooltipContent={renderSignersTooltip(documentPackage)}>
              <TextStyle
                variation={[
                  "code",
                  signedCount >= signerCount ? "positive" : "negative",
                ]}
              >
                {signedCount}/{signerCount}
              </TextStyle>
            </Tooltip>
          </Grid.Item>
        </Grid>
      );
    }

    return ts.documentPackagesStatus(documentPackage.status);
  };

  const getTemplateDocumentContractMessage = () => {
    const title = getLocalizedText(
      "property.dashboard.checklist.template_document_contract.heading",
    );
    const entityKey = "template_document_contract";

    if (manuallyCompletedTemplateDocumentContract) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedTemplateDocumentContract",
            entityKey,
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(
          manuallyCompletedTemplateDocumentContract,
        ),
        title,
        content: (
          <div>
            {getLocalizedText(
              "property.dashboard.checklist.template_document_contract.empty_content",
            )}
          </div>
        ),
        rightContent: undefined,
      };
    }

    // No available templates & no existing template documents
    const templateDocument = contractTemplateDocuments?.[0];
    if (
      availableTemplatesForContract &&
      availableTemplatesForContract.length === 0 &&
      !templateDocument
    ) {
      return;
    }

    const showLoading =
      (!availableTemplatesForContract && isFetchingTemplates) ||
      (!contractTemplateDocuments && isFetchingContractTemplateDocuments);
    if (showLoading) {
      return {
        actionStatus: EActionStatus.Loading,
        title,
        content: null, // <div>&nbsp;</div>,
      };
    }

    // Template document
    if (templateDocument) {
      const { status, documentId } = templateDocument;

      let showActions = true;
      let actionStatus = EActionStatus.Incomplete;
      switch (status) {
        case ETemplateDocumentStatus.Generating:
          actionStatus = EActionStatus.PendingRentio;
          break;

        case ETemplateDocumentStatus.Generated:
          actionStatus = EActionStatus.CompletedInRentio;
          break;

        // TODO: Failed
        case ETemplateDocumentStatus.Failed:
        case ETemplateDocumentStatus.New:
        default:
          break;
      }

      const onClick = () => {
        switch (status) {
          case ETemplateDocumentStatus.Generated:
          case ETemplateDocumentStatus.Generating:
            if (documentId) {
              setQueryParamValue({ documentId });
            }
            break;

          case ETemplateDocumentStatus.Failed:
          case ETemplateDocumentStatus.New:
          default: {
            setQueryParamValue({
              templateDocumentId: templateDocument.id,
            });
            break;
          }
        }
      };

      return {
        actionStatus,
        title,
        content: (
          <div>
            {getLocalizedText(
              `property.dashboard.checklist.template_document_contract.status.${status}`.toLowerCase(),
            )}
          </div>
        ),
        onClick,
        rightContent:
          showActions &&
          renderActions({
            content: `${getLocalizedText(
              `property.dashboard.checklist.template_document_contract.info_action.${status}`.toLowerCase(),
            )} ›`,
            onClick,
            appearance: "link",
          }),
      };
    }

    // Empty state
    return {
      actionStatus: EActionStatus.Incomplete,
      title,
      content: (
        <div>
          {getLocalizedText(
            "property.dashboard.checklist.template_document_contract.content",
          )}
        </div>
      ),
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          key: "manuallyCompletedTemplateDocumentContract",
          entityKey,
          shouldCheck: true,
        });
      },
      onClick: () => {
        setShowTemplateDocumentContractCreate(true);
      },
      rightContent: renderActions({
        content: `${getLocalizedText(
          "property.dashboard.checklist.template_document_contract.empty_action",
        )} ›`,
        onClick: () => {
          setShowTemplateDocumentContractCreate(true);
        },
        color: "green",
        size: "small",
      }),
    };
  };

  const getDocumentPackageMessage = (): IChecklistItem | undefined => {
    if (manuallyCompletedDocumentSigning) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedDocumentSigning",
            entityKey: "contract_signing",
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(manuallyCompletedDocumentSigning),
        title: ts.propertyDashboardChecklistSignContractHeading(),
        content: (
          <div>{ts.propertyDashboardChecklistSignContractEmptyContent()}</div>
        ),
        rightContent: undefined,
      };
    }

    if (!documentPackage && isFetchingDocumentPackage) {
      return {
        actionStatus: EActionStatus.Loading,
        title: ts.propertyDashboardChecklistSignContractHeading(),
        content: null, // <div>&nbsp;</div>,
      };
    }

    if (documentPackage) {
      const actionStatus =
        documentPackage.status === EDocumentPackageStatus.Finished
          ? EActionStatus.CompletedInRentio
          : EActionStatus.PendingRentio;

      const onClickDetail = () => {
        navigate(
          appendQueryParams({
            path: location.pathname,
            queryParams: {
              ...queryString.parse(location.search),
              documentPackageId: documentPackage.id,
            },
          }),
        );
      };

      return {
        actionStatus,
        // onChangeActionStatus:
        //   actionStatus === EActionStatus.PendingRentio
        //     ? (_: EActionStatus) => {
        //         // TODO: Confirm modal where document package will be removed
        //         dispatch(
        //           contractActions.updateContract.actions.start({
        //             contractId: contract.id,
        //             contract: {
        //               manuallyCompletedDocumentSigning: "contract_action.manual",
        //             },
        //           }),
        //         );
        //       }
        //     : undefined,
        title: ts.propertyDashboardChecklistSignContractHeading(),
        content: (
          <div>{ts.propertyDashboardChecklistSignContractContent()}</div>
        ),
        onClick: onClickDetail,
        rightContent: (
          <Flex>
            {renderDocumentPackageInfo()}
            <Box ml={ESpacings.tight}>
              {renderActions({
                content: `${ts.propertyDashboardChecklistViewDocumentPackageAction()} ›`,
                onClick: onClickDetail,
                appearance: "link",
              })}
            </Box>
          </Flex>
        ),
      };
    }

    if (isSignableDraft) {
      const link = appendQueryParams({
        path: `/forms/document-package`,
        queryParams: {
          propertyId: property.id,
          contractId: contract.id,
          documentPackageType: EDocumentPackageTypes.Contract,
        },
      });

      return {
        actionStatus: EActionStatus.Incomplete,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedDocumentSigning",
            entityKey: "contract_signing",
            shouldCheck: true,
          });
        },
        title: ts.propertyDashboardChecklistSignContractHeading(),
        content: (
          <div>{ts.propertyDashboardChecklistSignContractEmptyContent()}</div>
        ),
        link,
        rightContent: renderActions({
          content: `${ts.propertyDashboardChecklistSignContractAction()} ›`,
          url: link,
          // onClick: handlePrepareContract,
          color: "green",
          size: "small",
        }),
      };
    }
  };

  const getRentDepositMessage = (): IChecklistItem | undefined => {
    if (contract.contractType !== EContractType.Basic) {
      return;
    }

    if (manuallyCompletedRentDeposit) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedRentDeposit",
            entityKey: "rent_deposit",
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(manuallyCompletedRentDeposit),
        title: ts.propertyDashboardChecklistRentDepositHeading(),
        content: (
          <div>{ts.propertyDashboardChecklistRentDepositEmptyContent()}</div>
        ),
        rightContent: undefined,
      };
    }

    if (rentDeposits.length === 0 && isFetchingRentDeposits) {
      return {
        actionStatus: EActionStatus.Loading,
        title: ts.propertyDashboardChecklistRentDepositHeading(),
        content: null, // <div>&nbsp;</div>,
      };
    }

    const rentDeposit = rentDeposits[0];
    if (!rentDeposit || rentDeposit.status === ERentDepositStatus.Cancelled) {
      return {
        actionStatus: EActionStatus.Incomplete,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedRentDeposit",
            entityKey: "rent_deposit",
            shouldCheck: true,
          });
        },
        title: ts.propertyDashboardChecklistRentDepositHeading(),
        content: (
          <div>{ts.propertyDashboardChecklistRentDepositEmptyContent()}</div>
        ),
        link: rs.createRentDepositRoute(property.id, contract.id),
        rightContent: renderActions({
          content: `${ts.propertyDashboardChecklistRentDepositEmptyAction()} ›`,
          url: rs.createRentDepositRoute(property.id, contract.id),
          color: "green",
          size: "small",
        }),
      };
    }

    let actionStatus = EActionStatus.CompletedInRentio;
    if (
      [
        ERentDepositStatus.New,
        ERentDepositStatus.OpenToSign,
        ERentDepositStatus.OpenSigned,
        ERentDepositStatus.PayingIn,
        ERentDepositStatus.PayingInPartiallySigned,
        ERentDepositStatus.PaidInPartiallySigned,
      ].includes(rentDeposit.status)
    ) {
      actionStatus = EActionStatus.PendingRentio;
    }

    const content =
      rentDeposit.status === ERentDepositStatus.Established
        ? getLocalizedText(
            "property.dashboard.checklist.rent_deposit.content.established",
            {
              value: formatCurrency(rentDeposit.amount),
            },
          )
        : getLocalizedText(
            "property.dashboard.checklist.rent_deposit.content",
            {
              value: formatCurrency(rentDeposit.amount),
            },
          );

    return {
      actionStatus,
      // onChangeActionStatus: (_: EActionStatus) => {},
      title: ts.propertyDashboardChecklistRentDepositHeading(),
      content,
      onClick: handleViewRentDepositClick,
      rightContent: renderActions({
        content: `${ts.propertyDashboardChecklistRentDepositAction()} ›`,
        appearance: "link",
        onClick: handleViewRentDepositClick,
      }),
    };
  };

  const getPaymentOrderMessage = (): IChecklistItem | undefined => {
    if (contract.contractType !== EContractType.Basic) {
      return;
    }

    if (manuallyCompletedPaymentRent) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedPaymentRent",
            entityKey: "payment_orders",
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(manuallyCompletedPaymentRent),
        title: ts.propertyDashboardChecklistPaymentOrderHeading(),
        content: (
          <div>{ts.propertyDashboardChecklistPaymentOrderEmptyContent()}</div>
        ),
        rightContent: undefined,
      };
    }

    if (paymentOrdersCount === 0 && isFetchingPaymentOrdersCount) {
      return {
        actionStatus: EActionStatus.Loading,
        title: ts.propertyDashboardChecklistPaymentOrderHeading(),
        content: null, // <div>&nbsp;</div>,
      };
    }

    // Don't show if payments are already added
    if (paymentOrdersCount > 0) {
      return {
        actionStatus: EActionStatus.CompletedInRentio,
        title: ts.propertyDashboardChecklistPaymentOrderHeading(),
        content: (
          <div>{ts.propertyDashboardChecklistPaymentOrderEmptyContent()}</div>
        ),
        link: `/properties/${property.id}/payments`,
        rightContent: renderActions({
          // content: `${getLocalizedText(
          //   "contract_card.checklist.move_service.list_action.detail",
          // )} ›`,
          content: `${getLocalizedText("system.view")} ›`,
          appearance: "link",
          url: `/properties/${property.id}/payments`,
        }),
      };
    }

    if (
      !canAddPaymentOrders({
        property,
        isBroker,
      })
    ) {
      return undefined;
    }

    return {
      actionStatus: EActionStatus.Incomplete,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          key: "manuallyCompletedPaymentRent",
          entityKey: "payment_orders",
          shouldCheck: true,
        });
      },
      title: ts.propertyDashboardChecklistPaymentOrderHeading(),
      content: (
        <div>{ts.propertyDashboardChecklistPaymentOrderEmptyContent()}</div>
      ),
      link: `/properties/${property.id}/payments/add?contractId=${contract.id}`,
      rightContent: renderActions({
        content: `${ts.propertyDashboardChecklistPaymentOrderEmptyAction()} ›`,
        url: `/properties/${property.id}/payments/add?contractId=${contract.id}`,
        color: "green",
        size: "small",
      }),
    };
  };

  const getLocationDescriptionMessage = (): IChecklistItem | undefined => {
    if (manuallyCompletedLocationDescription) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedLocationDescription",
            entityKey: "location_description",
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(
          manuallyCompletedLocationDescription,
        ),
        title: ts.contractCardChecklistLocationDescriptionHeading(),
        content: (
          <div>
            {getLocalizedText(
              "contract_card.checklist.location_description.manually",
            )}
          </div>
        ),
        rightContent: undefined,
      };
    }

    if (
      contract.locationDescriptionInRequestedAt &&
      contract.locationDescriptionOutRequestedAt
    ) {
      return {
        actionStatus: EActionStatus.CompletedInRentio,
        title: ts.contractCardChecklistLocationDescriptionHeading(),
        content: (
          <div>
            {ts.contractCardChecklistLocationDescriptionBothContent({
              values: {
                date: formatDate(contract.locationDescriptionOutRequestedAt!),
              },
            })}
          </div>
        ),
      };
    }

    if (contract.locationDescriptionInRequestedAt) {
      return {
        actionStatus: EActionStatus.CompletedInRentio,
        title: ts.contractCardChecklistLocationDescriptionHeading(),
        content: (
          <div>
            {ts.contractCardChecklistLocationDescriptionInContent({
              values: {
                date: formatDate(contract.locationDescriptionInRequestedAt!),
              },
            })}
          </div>
        ),
      };
    }

    if (contract.locationDescriptionOutRequestedAt) {
      return {
        actionStatus: EActionStatus.CompletedInRentio,
        title: ts.contractCardChecklistLocationDescriptionHeading(),
        content: (
          <div>
            {ts.contractCardChecklistLocationDescriptionOutContent({
              values: {
                date: formatDate(contract.locationDescriptionOutRequestedAt!),
              },
            })}
          </div>
        ),
      };
    }

    // Only hide if feature enabled & there are no partnerships
    if (!partnerships) {
      return undefined;
    }

    let isOnlyMexp = false;
    if (
      (partnerships || []).length === 1 &&
      (partnerships || [])[0].locationDescriptorKey === "MEXP"
    ) {
      isOnlyMexp = true;
    }

    if (isContractStarting || isContractEnding) {
      return {
        actionStatus: EActionStatus.Incomplete,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedLocationDescription",
            entityKey: "location_description",
            shouldCheck: true,
          });
        },
        title: ts.contractCardChecklistLocationDescriptionHeading(),
        content: (
          <div>
            {isOnlyMexp
              ? getLocalizedText(
                  "contract_card.checklist.location_description.empty_content",
                )
              : getLocalizedText(
                  "contract_card.checklist.location_description.empty_content_generic",
                )}
          </div>
        ),
        onClick: handleOrderLocationDescription,
        rightContent: renderActions({
          content: `${ts.contractCardChecklistLocationDescriptionEmptyAction()} ›`,
          onClick: handleOrderLocationDescription,
          color: "green",
          size: "small",
        }),
      };
    }
  };

  const getInsuranceMessage = (): IChecklistItem | undefined => {
    if (isBroker && !hasInsuranceForBroker && !internalModeEnabled) {
      return {
        actionStatus: manuallyCompletedInsurance
          ? EActionStatus.CompletedManually
          : EActionStatus.Incomplete,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedInsurance",
            entityKey: "insurance",
            shouldCheck: !manuallyCompletedInsurance,
          });
        },
        actionStatusText: manuallyCompletedInsurance
          ? getLocalizedText(manuallyCompletedInsurance)
          : undefined,
        title: getLocalizedText(
          "contract_card.checklist.insurance.heading.manual",
        ),
        content: getLocalizedText(
          "contract_card.checklist.insurance.content.manual",
        ),
        rightContent: undefined,
      };
    }

    let title = getLocalizedText("contract_card.checklist.insurance.heading");

    if (manuallyCompletedInsurance) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedInsurance",
            entityKey: "insurance",
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(manuallyCompletedInsurance),
        title,
        content: getLocalizedText(
          "contract_card.checklist.insurance.content.none",
        ),
        rightContent: undefined,
      };
    }

    const { insuranceExternalStatus, insuranceLeadCompletedDate } = contract;

    let content = getLocalizedText(
      `contract_card.checklist.insurance.content.${insuranceExternalStatus}`.toLowerCase(),
      {
        date: insuranceLeadCompletedDate
          ? formatDate(insuranceLeadCompletedDate)
          : "",
      },
    );

    let actionStatus = EActionStatus.Incomplete;
    let rightContent = null;
    let onClick = undefined;

    switch (insuranceExternalStatus) {
      case EInsuranceExternalStatus.None: {
        actionStatus = EActionStatus.Incomplete;

        onClick = handleClickRequestInsurance;

        rightContent = renderActions({
          content: `${getLocalizedText(
            "contract_card.checklist.insurance.list_action",
          )} ›`,
          onClick,
          color: "green",
          size: "small",
        });

        break;
      }

      case EInsuranceExternalStatus.Created:
      case EInsuranceExternalStatus.Pending:
      case EInsuranceExternalStatus.Failed: {
        actionStatus = EActionStatus.PendingRentio;

        onClick = handleClickViewInsurance;

        rightContent = renderActions({
          content: `${getLocalizedText(
            "contract_card.checklist.insurance.list_action.detail",
          )} ›`,
          onClick,
          appearance: "link",
        });

        break;
      }

      case EInsuranceExternalStatus.Completed:
        actionStatus = EActionStatus.CompletedInRentio;

        onClick = handleClickViewInsurance;

        rightContent = renderActions({
          content: `${getLocalizedText(
            "contract_card.checklist.insurance.list_action.detail",
          )} ›`,
          onClick,
          appearance: "link",
        });
        break;

      default:
        break;
    }

    return {
      actionStatus,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          key: "manuallyCompletedInsurance",
          entityKey: "insurance",
          shouldCheck: true,
        });
      },
      title,
      content,
      onClick,
      rightContent,
    };
  };

  const getMoveServiceMessage = (): IChecklistItem | undefined => {
    if (isBroker && !hasMoveServiceForBroker && !internalModeEnabled) {
      return {
        actionStatus: manuallyCompletedMove
          ? EActionStatus.CompletedManually
          : EActionStatus.Incomplete,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedMove",
            entityKey: "move_service",
            shouldCheck: !manuallyCompletedMove,
          });
        },
        actionStatusText: manuallyCompletedMove
          ? getLocalizedText(manuallyCompletedMove)
          : undefined,
        title: getLocalizedText(
          "contract_card.checklist.move_service.heading.manual",
        ),
        content: getLocalizedText(
          "contract_card.checklist.move_service.content.manual",
        ),
        rightContent: undefined,
      };
    }

    const { moveServiceStatus = EMoveServiceStatus.None } = contract;

    let title = getLocalizedText(
      "contract_card.checklist.move_service.heading",
    );
    let content = getLocalizedText(
      `contract_card.checklist.move_service.content.${moveServiceStatus}`.toLowerCase(),
    );

    if (contract.manuallyCompletedMove) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedMove",
            entityKey: "move_service",
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(contract.manuallyCompletedMove),
        title,
        content,
        rightContent: undefined,
      };
    }

    if (contract.moveRequestId) {
      return {
        actionStatus: EActionStatus.CompletedInRentio,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedMove",
            entityKey: "move_service",
            shouldCheck: true,
          });
        },
        title,
        content,
        onClick: () => {
          setQueryParamValue({
            moveRequestId: contract.moveRequestId,
          });
        },
        rightContent: renderActions({
          content: `${getLocalizedText(
            "contract_card.checklist.move_service.list_action.detail",
          )} ›`,
          onClick: () => {
            setQueryParamValue({
              moveRequestId: contract.moveRequestId,
            });
          },
          appearance: "link",
        }),
      };
    }

    return {
      actionStatus: EActionStatus.Incomplete,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          key: "manuallyCompletedMove",
          entityKey: "move_service",
          shouldCheck: true,
        });
      },
      title,
      content,
      onClick: handleClickRequestMoveService,
      rightContent: renderActions({
        content: `${getLocalizedText(
          "contract_card.checklist.move_service.list_action.start",
        )} ›`,
        onClick: handleClickRequestMoveService,
        color: "green",
        size: "small",
      }),
    };
  };

  const getRegistrationMessage = (): IChecklistItem | undefined => {
    const {
      rentRegistrationRentContractStatus = ERegistrationContractStatus.None,
      rentRegistrationLocationDescriptionStatus = ERegistrationContractStatus.None,
      rentRegistrationId,
    } = contract;

    let title = getLocalizedText(
      "contract_card.checklist.registration.heading",
    );

    if (manuallyCompletedRegistration) {
      return {
        actionStatus: EActionStatus.CompletedManually,
        onChangeActionStatus: (_: EActionStatus) => {
          handleChangeActionStatus({
            key: "manuallyCompletedRegistration",
            entityKey: "registration",
            shouldCheck: false,
          });
        },
        actionStatusText: getLocalizedText(manuallyCompletedRegistration),
        title,
        content: getLocalizedText(
          "contract_card.checklist.registration.content.empty",
        ),
        rightContent: undefined,
      };
    }

    let content = getLocalizedText(
      `contract_card.checklist.registration.content.${
        rentRegistrationRentContractStatus === ERegistrationContractStatus.None
          ? "empty"
          : "other"
      }`,
      {
        rentContractStatus: getLocalizedText(
          `registration.status.${stringToSnakeCase(
            rentRegistrationRentContractStatus,
          )}`,
        ).toLowerCase(),
        locationDescriptionStatus: getLocalizedText(
          `registration.status.${stringToSnakeCase(
            rentRegistrationLocationDescriptionStatus,
          )}`,
        ).toLowerCase(),
      },
    );

    let actionStatus = EActionStatus.Incomplete;
    let rightContent = null;
    let link = undefined;
    let onClick = undefined;

    if (
      [
        ERegistrationContractStatus.Registered,
        ERegistrationContractStatus.RegisteredManually,
      ].includes(rentRegistrationRentContractStatus) &&
      [
        ERegistrationContractStatus.Registered,
        ERegistrationContractStatus.RegisteredManually,
        ERegistrationContractStatus.None,
      ].includes(rentRegistrationLocationDescriptionStatus)
    ) {
      actionStatus = EActionStatus.CompletedInRentio;
    } else if (
      rentRegistrationRentContractStatus !== ERegistrationContractStatus.None
    ) {
      actionStatus = EActionStatus.PendingRentio;
    }

    if (
      rentRegistrationRentContractStatus === ERegistrationContractStatus.None
    ) {
      link = `/properties/${property.id}/contracts/${contract.id}/registration`;
      rightContent = renderActions({
        content: `${getLocalizedText(
          "contract_card.checklist.registration.list_action.start",
        )} ›`,
        url: link,
        color: "green",
        size: "small",
      });
    } else {
      onClick = () => {
        setQueryParamValue({ registrationId: rentRegistrationId });
      };
      rightContent = renderActions({
        content: `${getLocalizedText(
          "contract_card.checklist.registration.list_action.detail",
        )} ›`,
        onClick,
        appearance: "link",
      });
    }

    return {
      actionStatus,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          key: "manuallyCompletedRegistration",
          entityKey: "registration",
          shouldCheck: true,
        });
      },
      title,
      content,
      link,
      onClick,
      rightContent,
    };
  };

  const checklistItems = [
    getIndexationMessage({
      property,
      contract,
      handleIndexContract,
      internalModeEnabled,
    }),
    getTemplateDocumentContractMessage(),
    getDocumentPackageMessage(),
    getRentDepositMessage(),
    getPaymentOrderMessage(),
    getLocationDescriptionMessage(),
    getInsuranceMessage(),
    getMoveServiceMessage(),
    getRegistrationMessage(),
  ];

  return (
    <>
      <Card key={contract.id} hasSections={true}>
        <Card.Section>
          <ContractMetaData
            contract={contract}
            property={property}
            showContractType={showContractType}
            checklistItems={checklistItems}
            setShowCancelContractModal={setShowCancelContractModal}
          />
        </Card.Section>

        <RentioInternalRenderer
          items={{
            ibanisationStatus: contract.ibanisationStatus,
          }}
        />

        <Card.Section boxProps={{ p: ESpacings.none }}>
          <ResourceList
            renderItem={(item: IChecklistItem) => <ChecklistItem item={item} />}
            items={checklistItems.filter(Boolean)}
          />
        </Card.Section>
      </Card>
      {showIndexContractModal && (
        <IndexContractModal
          contractId={contract.id}
          onClose={handleIndexModalClose}
        />
      )}
      {showTemplateDocumentContractCreate && (
        <TemplateDocumentContractCreateModal
          property={property}
          contract={contract}
          onClose={() => {
            setShowTemplateDocumentContractCreate(false);
          }}
          type={"contract"}
        />
      )}
      {/* {showTemplateDocumentContractInfo &&
        templateDocuments &&
        templateDocuments.length > 0 && (
          <TemplateDocumentContractInfoModal
            id={templateDocuments[0].id}
            property={property}
            contract={contract}
            onClose={() => {
              setShowTemplateDocumentContractInfo(false);
            }}
          />
        )} */}
      {showLocationDescriptionModal && (
        <LocationDescriptionModal
          property={property}
          newContract={isContractStarting ? contract : undefined}
          oldContract={isContractEnding ? contract : undefined}
          onClose={handleLocationDescriptionModalClose}
        />
      )}
      {showRequestInsuranceModal && (
        <RequestInsuranceModal
          property={property}
          contract={contract}
          onClose={handleClickRequestInsuranceModalClose}
        />
      )}
      {showRequestMoveService && (
        <RequestMoveServiceModal
          property={property}
          contract={contract}
          onClose={handleClickRequestMoveServiceModalClose}
        />
      )}

      {showCancelContractModal && (
        <CancelContractModal
          contract={contract}
          onSuccess={() => {
            onCancelContract?.();
            navigate(
              `/properties/${property.id}/contracts?cancelledContractId=${contract.id}`,
            );
          }}
          onClose={() => {
            setShowCancelContractModal(false);
          }}
        />
      )}
    </>
  );
};
