import styled from "@rentiohq/web-theme";

export const LoadingWrap = styled.div`
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const LoadingInnerWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: -25px;
`;

export const MonthsWrap = styled.div`
  /* background-color: orange; */
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PayerRow = styled.div`
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-top: 1px;
  margin-bottom: 1px;
`;

export const ErrorWrap = styled.div`
  flex: 1;
  text-align: center;
`;
