var _a;
import api from "../../utils/api/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "../../utils/date.utils";
import { apiGenerator } from "../generic/all.api";
import CONFIG from "./invoice.config";
export var getDetail = (_a = apiGenerator({
    resource: CONFIG.RESOURCE,
}), _a.getDetail), getPaged = _a.getPaged;
export var markInvoicePaid = function (id, data) {
    return api.post("/invoices/".concat(id, "/mark-paid"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var markInvoiceUnpaid = function (id) {
    return api.post("/invoices/".concat(id, "/mark-unpaid"));
};
