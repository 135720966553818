import * as brokerActions from "@rentiohq/shared-frontend/dist/redux/broker/broker.actions";
import * as brokerSelectors from "@rentiohq/shared-frontend/dist/redux/broker/broker.selectors";
import { IRootStore } from "redux/reducers";
import { EField } from "./schema.tenantProfileBroker.types";

export const isPerformingSubmitActionSelector =
  (brokerId: number) => (state: IRootStore) =>
    brokerSelectors.update.isUpdating(state, brokerId);

export const performingSubmitActionErrorSelector =
  (brokerId: number) => (state: IRootStore) =>
    brokerSelectors.update.updateError(state, brokerId);

export const performingSubmitActionResultSelector =
  (brokerId: number) => (state: IRootStore) =>
    brokerSelectors.detail.detail(state, brokerId);

export const submitActionCreator = (brokerId: number) => (formData: any) =>
  brokerActions.update.actions.start({
    id: brokerId,
    data: {
      intakeMail: formData[EField.IntakeMail],
      intakeType: formData[EField.IntakeType],
    },
  });
