import { EContactInitialType } from "@rentiohq/shared/dist/types/contact.types";
import { EContractIbanisationStatus } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatIban } from "../../utils/iban.utils";
import { stringToSnakeCase } from "../../utils/string.utils";
import { EEnableIbanisation, EField, } from "./schema.createContract.types";
import { getCreateContractCompanyFieldsSchema, getCreateContractContactFieldsSchema, getNavigationTitle, } from "./schema.createContract.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.EnableIbanisation],
            properties: (_a = {},
                _a[EField.Header + "6b"] = {
                    default: undefined,
                },
                _a[EField.EnableIbanisation] = {
                    default: getValue(EField.EnableIbanisation, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(EEnableIbanisation).map(function (enableIbanisation) { return ({
                        id: enableIbanisation,
                        value: getLocalizedText("contract.enable_ibanisation.option.label.".concat(enableIbanisation)),
                        subValue: getLocalizedText("contract.enable_ibanisation.option.info.".concat(enableIbanisation)),
                    }); }),
                },
                _a[EField.Tenants + "Validation"] = {
                    default: getValue(EField.Tenants, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.EnableIbanisation] === EEnableIbanisation.Enable;
                    },
                    // TODO: Reset on change original field
                    // handleChange: (
                    //   fieldName: string,
                    //   fieldValue: any,
                    //   myFieldValue: string,
                    //   formData: any,
                    // ) => {
                    //   if (fieldName === EField.Tenants) {
                    //     return { shouldSetNewValue: false };
                    //   }
                    //   if (
                    //     formData[EField.Tenants] === formData[EField.Tenants + "Validation"]
                    //   ) {
                    //     return { shouldSetNewValue: false };
                    //   }
                    //   return {
                    //     shouldSetNewValue: true,
                    //     newValue: formData[EField.Tenants],
                    //   };
                    // },
                },
                _a[EField.EnableIbanisationInfo] = {},
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("contract_info.add.enable_ibanisation.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "6b"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.add.enable_ibanisation.heading"),
        },
        _a[EField.EnableIbanisation] = {
            "ui:field": ERentioFormField.InlineSelect,
            isDisabled: function () {
                if (!extraData.contract) {
                    return false;
                }
                var ibanisationStatus = extraData.contract.ibanisationStatus;
                if ([
                    EContractIbanisationStatus.None,
                    EContractIbanisationStatus.EnablingFailed,
                ].includes(ibanisationStatus)) {
                    return false;
                }
                // If ibanisation was already enabled in the past/it is disabled, it can not be turned off anymore
                return true;
            },
            summaryTitle: getLocalizedText("contract.enable_ibanisation.option.title.summary"),
            formatSummary: function (formData) {
                return getLocalizedText("contract.enable_ibanisation.option.label.".concat(formData));
            },
        },
        _a[EField.Tenants + "Validation"] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            readOnly: true,
            isDisabled: function () { return true; },
            title: getLocalizedText("contract_info.add.enable_ibanisation.tenant_info"),
            selectContactFromListCtaTitle: getLocalizedText("contract_info.tenants.select_other.cta"),
            isMultiSelect: true,
            prefetchDefault: true,
            initialType: EContactInitialType.Tenant,
            prefilledAddress: (function () {
                var property = extraData.property;
                return {
                    street: property.street,
                    number: property.number,
                    box: property.box,
                    zip: property.zip,
                    city: property.city,
                    state: property.state,
                    countryId: property.countryId,
                };
            })(),
            getRequiredContactFieldsSchema: getCreateContractContactFieldsSchema,
            getRequiredCompanyFieldsSchema: getCreateContractCompanyFieldsSchema,
            // Custom selectors & actions
            getDisallowedAccountIds: function (formData) { return formData[EField.Owners] || []; },
            valueSelector: function (state, selectedAccountIds) {
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, extraData.property.members.map(function (member) { return member.account; }));
            },
            customGetSubtitleSelector: function (state, formData, item) {
                var accountId = item.accountId, contact = item.contact;
                var member = extraData.property.members.find(function (member) {
                    if (accountId && accountId === member.account.id) {
                        return true;
                    }
                    if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
                        return true;
                    }
                    return false;
                });
                if (!member) {
                    return;
                }
                return member.roles
                    .filter(function (role) {
                    return ![
                        EPropertyMemberTypes.ContractMember,
                        EPropertyMemberTypes.TaskMember,
                    ].includes(role);
                })
                    .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
                    .join(", ");
            },
            customExtraAccountsSelector: function () {
                return extraData.property.members
                    .filter(function (_a) {
                    var roles = _a.roles;
                    return roles.includes(EPropertyMemberTypes.Tenant);
                })
                    .map(function (member) { return member.account; });
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a[EField.EnableIbanisationInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            getSubtitle: function () {
                var contract = extraData.contract, ibanisationBankAccount = extraData.ibanisationBankAccount;
                var ibanisationStatus = (contract === null || contract === void 0 ? void 0 : contract.ibanisationStatus) || EContractIbanisationStatus.None;
                var values = {};
                if (ibanisationStatus === EContractIbanisationStatus.Enabled &&
                    ibanisationBankAccount) {
                    values = {
                        iban: formatIban(ibanisationBankAccount.iban),
                        bic: ibanisationBankAccount.mangoWalletIbanBic || "unknown bic",
                    };
                }
                return getLocalizedText("contract.enable_ibanisation.option.info.status.".concat(stringToSnakeCase(ibanisationStatus)), values);
            },
        },
        _a;
};
