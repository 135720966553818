import styled from "@rentiohq/web-theme";

export const CloseButtonWrap = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 4px;
`;

export const CloseButton = styled.a`
  padding: 12px;
  padding-top: 16px;
  cursor: pointer;
  display: none;
`;

export const TextWrap = styled.div`
  flex: 1;
`;

export const Wrap = styled.div`
  position: relative;
  flex: 1;
  flex-direction: row;
  display: flex;
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:hover ${CloseButton} {
    display: inherit;
  }
`;

export const CardButtonsWrap = styled.div`
  flex: 1;
  flex-direction: row;
  display: flex;
`;
