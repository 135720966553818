import { HEADER_TYPES_CONTENT_BLOCK } from "./EditorData.constants";
export var blockStyler = function (contentBlock) {
    var type = contentBlock.getType();
    var data = contentBlock.getData();
    var result = [];
    var isHeaderType = HEADER_TYPES_CONTENT_BLOCK.includes(type);
    if (isHeaderType) {
        result.push("".concat(type, "-rentio"));
    }
    var textAlignmentClass = data.get("textAlignmentClass");
    if (textAlignmentClass) {
        result.push(textAlignmentClass);
    }
    return result.join(" ");
};
