import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  isRequestingInsurance,
  requestInsuranceError,
} from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { ASSETS } from "@rentiohq/web-assets";
import {
  IModalHeaderProps,
  Icon,
  Modal,
  MultiStepForm,
  OptionListItem,
} from "@rentiohq/web-shared/dist/components";
import Banner from "@rentiohq/web-shared/dist/components/Banner";
import Button from "@rentiohq/web-shared/dist/components/Button";
import utils from "@rentiohq/web-shared/dist/utils";
import requestInsuranceSchemas from "forms/requestInsurance";
import { submitActionCreator } from "forms/requestInsurance/schema.requestInsurance.actions";
import { requestInsuranceValidationErrors } from "forms/requestInsurance/schema.requestInsurance.utils";
import React from "react";
import { StringParam } from "serialize-query-params";
import { ts } from "../../services";
import * as S from "../LocationDescriptionModal/LocationDescriptionModal.styled";

const { i18n } = utils;

enum EStep {
  Intro,
  Form,
  Outro,
}

interface IProps {
  property: IProperty;
  contract: IContract;
  onClose: () => void;
}

const RequestInsuranceModal: React.FC<IProps> = props => {
  const { property, contract, onClose } = props;

  // State
  const [, setQueryParamValue] = useQueryParams({
    insuranceContractId: StringParam,
  });

  const [didConfirmDataSharing, setDidConfirmDataSharing] =
    React.useState(false);
  const [showDidConfirmDataSharingError, setShowDidConfirmDataSharingError] =
    React.useState(false);
  const [formId] = React.useState(generateFormId());
  const [step, setStep] = React.useState<EStep>(EStep.Intro);

  // Event handlers
  const handleClickTerms = () => {
    utils.general.openWindow(
      getLocalizedText("request_insurance.modal.intro.cta.terms.url"),
    );
  };

  // Render
  const renderIntro = () => {
    const errors = requestInsuranceValidationErrors({
      property,
      contract,
    });

    return (
      <>
        <Modal
          width="medium"
          onClose={onClose}
          heading={getLocalizedText("request_insurance.modal.heading")}
          actions={[
            {
              content: ts.system("cancel"),
              onClick: onClose,
              appearance: "outline",
            },
            {
              content: ts.system("next"),
              onClick: () => {
                if (!didConfirmDataSharing) {
                  setShowDidConfirmDataSharingError(true);
                  return;
                }

                setShowDidConfirmDataSharingError(false);
                setStep(EStep.Form);
              },
              appearance: "primary",
              isDisabled: errors.length > 0,
            },
          ]}
        >
          {errors.length > 0 && (
            <Banner
              hasDismiss={false}
              icon="alertDiamond"
              variation="error"
              title={getLocalizedText("request_insurance.modal.intro.invalid")}
              children={
                <span>
                  {errors.map((error, index) => (
                    <>
                      {index !== 0 && <br />}- {error}
                    </>
                  ))}
                </span>
              }
            />
          )}
          {i18n.tm("request_insurance.modal.intro.content", {
            markdownProps: { listAsChecklist: true },
          })}

          <Button onClick={handleClickTerms} appearance="link">
            {getLocalizedText("request_insurance.modal.intro.cta.terms.title")}
          </Button>

          <Spacer weight={ESpacerWeight.W16} />

          <OptionListItem
            type={"checkbox"}
            title={getLocalizedText(
              "request_insurance.modal.intro.checkbox.data_sharing.title",
            )}
            error={showDidConfirmDataSharingError ? "_" : undefined}
            selected={didConfirmDataSharing}
            onChange={() => {
              setDidConfirmDataSharing(!didConfirmDataSharing);
            }}
          />
        </Modal>
      </>
    );
  };

  const renderForm = () => (
    <MultiStepForm
      formId={`request-insurance-${formId}`}
      schemas={requestInsuranceSchemas({
        property,
        contract,
      })}
      asModal={true}
      withAside={false}
      modalProps={{
        onClose,
        heading: getLocalizedText("request_insurance.modal.heading"),
        width: "medium",
      }}
      modalActions={[
        {
          content: getLocalizedText("system.previous"),
          onClick: () => {
            setStep(EStep.Intro);
          },
          appearance: "outline",
        },
      ]}
      isPerformingSubmitActionSelector={state =>
        isRequestingInsurance(state, contract.id)
      }
      performingSubmitActionErrorSelector={state =>
        requestInsuranceError(state, contract.id)
      }
      submitActionCreator={submitActionCreator({
        property,
        contract,
      })}
      onSuccess={() => {
        setStep(EStep.Outro);
      }}
      submitLabel={getLocalizedText("system.submit")}
    />
  );

  const renderOutro = () => (
    <Modal
      width="medium"
      onClose={onClose}
      actions={[
        {
          content: ts.system("close"),
          onClick: () => {
            setQueryParamValue({ insuranceContractId: contract.id });

            onClose();
          },
          appearance: "primary",
        },
      ]}
      components={{
        Header: ({ onClose }: IModalHeaderProps) => (
          <S.ModalHeader>
            <S.CloseModal>
              <Button appearance="plain" onClick={onClose}>
                <Icon source="close" />
              </Button>
            </S.CloseModal>
            <img src={ASSETS.successStar} alt="success" />
          </S.ModalHeader>
        ),
      }}
    >
      {i18n.tm("request_insurance.modal.outro.content", {
        markdownProps: { listAsChecklist: true },
      })}
    </Modal>
  );

  switch (step) {
    case EStep.Form:
      return renderForm();

    case EStep.Outro:
      return renderOutro();

    case EStep.Intro:
    default:
      return renderIntro();
  }
};

export default RequestInsuranceModal;
