var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Stack from "../Stack";
import Stage from "./Stage";
import * as S from "./Stages.styled";
var Stages = function (_a) {
    var stages = _a.stages, stageStyle = _a.stageStyle;
    return (_jsx(S.Wrapper, { children: _jsx(Stack, __assign({ distribution: "fillEvenly" }, { children: stages.map(function (stage, index) {
                var stageComponent = (_jsx(Stage, { stage: stage, index: index, isLast: stages.length - 1 === index, stageStyle: stageStyle }));
                if (stage.url) {
                    return (_jsx(S.StyledLink, __assign({ to: stage.url }, { children: stageComponent }), "stage-".concat(stage.heading, "-").concat(index)));
                }
                return (_jsx(React.Fragment, { children: stageComponent }, "stage-".concat(stage.heading, "-").concat(index)));
            }) })) }));
};
export default Stages;
