var _a;
import api from "../../utils/api/api.utils";
import { apiGenerator } from "../generic/all.api";
import CONFIG from "./broker.config";
export var getDetail = (_a = apiGenerator({
    resource: CONFIG.RESOURCE,
    defaultInclude: ["thirdPartyBankAccount", "pspInfo"],
}), _a.getDetail), getPaged = _a.getPaged, update = _a.update, deleteObject = _a.deleteObject, create = _a.create;
export var suspend = function (id) { return api.post("/brokers/".concat(id, "/suspend")); };
export var unsuspend = function (id) {
    return api.post("/brokers/".concat(id, "/unsuspend"));
};
export var getAllEmployees = function (id) {
    return api.get("/brokers/".concat(id, "/employee-accounts"));
};
