import { Box } from "@rebass/grid";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  DisplayText,
  Grid,
  Icon,
  Lozenge,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { ts } from "../../../services";

interface IContactOverviewRowProps {
  contact: IContact;
}

export const ContactOverviewRow: React.FC<IContactOverviewRowProps> = ({
  contact,
}) => {
  const iconSource = contact.isCompany ? "briefcase" : "profile";
  const hasAccepted = contact.inviteAcceptedAt;

  return (
    <ResourceListItem
      item={contact}
      link={`/contacts/${contact.id}`}
      media={<Icon source={iconSource} />}
      mediaSize="large"
    >
      <Grid alignItems="center">
        <Grid.Item flex={1}>
          <Box mb={1}>
            <DisplayText size="small">{getName(contact)}</DisplayText>
          </Box>
          {(contact.email || contact.phone) && (
            <TextStyle variation="subdued">
              <Grid alignItems="center">
                {contact.phone && (
                  <Grid.Item>
                    <Grid spacing="extraTight" alignItems="center">
                      <Grid.Item>
                        <Icon source="smartphone" size="small" />
                      </Grid.Item>
                      <Grid.Item>{formatPhone(contact.phone)}</Grid.Item>
                    </Grid>
                  </Grid.Item>
                )}
                {contact.email && (
                  <Grid.Item>
                    <Grid spacing="extraTight" alignItems="center">
                      <Grid.Item>
                        <Icon source="email" size="small" />
                      </Grid.Item>
                      <Grid.Item>{contact.email}</Grid.Item>
                    </Grid>
                  </Grid.Item>
                )}
              </Grid>
            </TextStyle>
          )}
        </Grid.Item>
        <Grid.Item>
          <Lozenge appearance={hasAccepted ? "success" : "error"}>
            {hasAccepted
              ? ts.contactConnectionSuccess()
              : ts.contactConnectionPending()}
          </Lozenge>
        </Grid.Item>
      </Grid>
    </ResourceListItem>
  );
};
