var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EContractPaydate, EContractPaymentRepetitionType, } from "@rentiohq/shared/dist/types/contract.types";
import { EPaymentOrderType } from "@rentiohq/shared/dist/types/payment.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue, getValueOrExtraValue, } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getVatFieldsForSchema, getVatFieldsForUiSchema, } from "../createContract/schema.createContract.utils.vat";
import { EField } from "./schema.createPayment.types";
import { checkIfTypeIsOther, getNavigationTitle, getRelatedContract, isRentOrCost, } from "./schema.createPayment.utils";
export var shouldShowCommonCosts = function (formData, contract) {
    if (!contract) {
        return false;
    }
    if ((contract.currentCostsCommon || 0) === 0) {
        return false;
    }
    var type = formData[EField.TypeRecurring] || "-";
    // return EPaymentOrderType.RentAndCosts === type;
    return [
        EPaymentOrderType.CommonCosts,
        EPaymentOrderType.RentAndCosts,
    ].includes(type);
};
export var shouldShowNonCommonCosts = function (formData, contract) {
    if (!contract) {
        return false;
    }
    if ((contract.currentCostsNonCommon || 0) === 0) {
        return false;
    }
    var type = formData[EField.TypeRecurring] || "-";
    // return EPaymentOrderType.RentAndCosts === type;
    return [
        EPaymentOrderType.NonCommonCosts,
        EPaymentOrderType.RentAndCosts,
    ].includes(type);
};
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a, _b, _c;
        var contract = getRelatedContract(extraData, defaultValues);
        return {
            required: [],
            properties: __assign(__assign(__assign(__assign(__assign((_a = {}, _a[EField.Header + "2"] = {
                default: undefined,
            }, _a[EField.Info] = {
                default: undefined,
                // @ts-ignore
                showIf: function (formData) { return !!formData[EField.TypeRecurring]; },
            }, _a[EField.OtherDescription] = {
                default: getValue(EField.OtherDescription, initialValues, defaultValues),
                // @ts-ignore
                showIf: checkIfTypeIsOther,
                // @ts-ignore
                requiredIf: checkIfTypeIsOther,
            }, _a[EField.PaymentRepetition] = {
                default: (function () {
                    if ((contract === null || contract === void 0 ? void 0 : contract.repetitionType) &&
                        defaultValues[EField.TypeRecurring] !==
                            EPaymentOrderType.OtherRecurring) {
                        return contract.repetitionType;
                    }
                    return getValueOrExtraValue(EField.PaymentRepetition, defaultValues, (contract === null || contract === void 0 ? void 0 : contract.repetitionType) ||
                        EContractPaymentRepetitionType.Monthly);
                })(),
                // @ts-ignore
                options: [
                    EContractPaymentRepetitionType.Monthly,
                    EContractPaymentRepetitionType.Quarterly,
                    EContractPaymentRepetitionType.Yearly,
                ].map(function (id) { return ({
                    id: id,
                    value: getLocalizedText("contract.payment_repetition.option.".concat(id).toLowerCase()),
                }); }),
                // @ts-ignore
                showIf: function (formData) { return !!formData[EField.TypeRecurring]; },
                requiredIf: function (formData) { return !!formData[EField.TypeRecurring]; },
            }, _a[EField.Price] = {
                exclusiveMinimum: 0,
                default: (function () {
                    if (contract) {
                        switch (defaultValues[EField.TypeRecurring]) {
                            case EPaymentOrderType.RentAndCosts:
                            case EPaymentOrderType.Rent:
                                return contract.currentPrice || 0;
                            case EPaymentOrderType.CommonCosts:
                                return contract.currentCostsCommon || 0;
                            case EPaymentOrderType.NonCommonCosts:
                                return contract.currentCostsNonCommon || 0;
                            default:
                                break;
                        }
                    }
                    return getValue(EField.Price, initialValues, defaultValues);
                })(),
                // @ts-ignore
                requiredIf: function (formData) {
                    var type = formData[EField.TypeRecurring] || "-";
                    return ![
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(type);
                },
                // @ts-ignore
                showIf: function (formData) {
                    var type = formData[EField.TypeRecurring] || "-";
                    return ![
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(type);
                },
            }, _a), getVatFieldsForSchema({
                defaultValues: defaultValues,
                initialValues: initialValues,
                fieldKey: EField.PriceVat,
                infoFieldKey: EField.PriceVatHelpInfo,
                baseAmountFieldKey: EField.Price,
                defaultValue: (function () {
                    if (contract) {
                        switch (defaultValues[EField.TypeRecurring] ||
                            defaultValues[EField.TypeOnce]) {
                            case EPaymentOrderType.RentAndCosts:
                            case EPaymentOrderType.Rent:
                                return contract.priceVat || 0;
                            case EPaymentOrderType.CommonCosts:
                                return contract.costsCommonVat || 0;
                            case EPaymentOrderType.NonCommonCosts:
                                return contract.costsNonCommonVat || 0;
                            case EPaymentOrderType.BrokerFee:
                            case EPaymentOrderType.ManagementCosts:
                            case EPaymentOrderType.Rentio:
                            case EPaymentOrderType.LocationDescription:
                                return getValueOrExtraValue(EField.PriceVat, defaultValues, 21);
                            default:
                                break;
                        }
                    }
                    return getValue(EField.PriceVat, initialValues, defaultValues);
                })(),
                showIf: function (formData) {
                    var type = formData[EField.TypeRecurring] || "-";
                    return ![
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(type);
                },
            })), (_b = {}, _b[EField.DiscountAmount] = {
                default: (contract === null || contract === void 0 ? void 0 : contract.rentDiscountAmount)
                    ? "".concat(contract === null || contract === void 0 ? void 0 : contract.rentDiscountAmount)
                    : 0,
                // @ts-ignore
                showIf: function (formData) {
                    if (!(contract === null || contract === void 0 ? void 0 : contract.rentDiscountEnabled)) {
                        return false;
                    }
                    var type = formData[EField.TypeRecurring] || "-";
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                    ].includes(type);
                },
            }, _b[EField.PriceCommonCosts] = {
                default: contract ? contract.currentCostsCommon || 0 : 0,
                // @ts-ignore
                requiredIf: function (formData) {
                    return shouldShowCommonCosts(formData, contract);
                },
                showIf: function (formData) { return shouldShowCommonCosts(formData, contract); },
            }, _b)), getVatFieldsForSchema({
                defaultValues: defaultValues,
                initialValues: initialValues,
                fieldKey: EField.PriceCommonCostsVat,
                infoFieldKey: EField.PriceCommonCostsVatHelpInfo,
                baseAmountFieldKey: EField.PriceCommonCosts,
                // show
                defaultValue: (contract === null || contract === void 0 ? void 0 : contract.costsCommonVat) || 0,
                showIf: function (formData) { return shouldShowCommonCosts(formData, contract); },
            })), (_c = {}, _c[EField.PriceNonCommonCosts] = {
                default: contract ? contract.currentCostsNonCommon || 0 : 0,
                // @ts-ignore
                showIf: function (formData) {
                    return shouldShowNonCommonCosts(formData, contract);
                },
                requiredIf: function (formData) {
                    return shouldShowNonCommonCosts(formData, contract);
                },
            }, _c)), getVatFieldsForSchema({
                defaultValues: defaultValues,
                initialValues: initialValues,
                fieldKey: EField.PriceNonCommonCostsVat,
                infoFieldKey: EField.PriceNonCommonCostsVatHelpInfo,
                baseAmountFieldKey: EField.PriceNonCommonCosts,
                defaultValue: (contract === null || contract === void 0 ? void 0 : contract.costsNonCommonVat) || 0,
                showIf: function (formData) {
                    return shouldShowNonCommonCosts(formData, contract);
                },
            })),
        };
    };
};
export var uiSchema = function (initialValues, extraData) {
    var _a, _b, _c;
    var getPriceTypeCommonCosts = function (contract) {
        return getLocalizedText("payment.payment_order.payment_type.value.".concat((contract === null || contract === void 0 ? void 0 : contract.priceTypeCommonCosts) || "fixed").toLowerCase());
    };
    var getPriceTypeNonCommonCosts = function (contract) {
        return getLocalizedText("payment.payment_order.payment_type.value.".concat((contract === null || contract === void 0 ? void 0 : contract.priceTypeNonCommonCosts) || "fixed").toLowerCase());
    };
    return __assign(__assign(__assign(__assign(__assign((_a = { "rentio:title": getLocalizedText("payments.create.first_payment.heading.summary"), "rentio:navigationTitle": getNavigationTitle(extraData) }, _a[EField.Header + "2"] = {
        "ui:field": ERentioFormField.SectionHeader,
        getTitle: function (formData) {
            return getLocalizedText("payments.create.first_payment.heading", {
                type: getLocalizedText("payment.payment_order.type.".concat(formData[EField.TypeRecurring] || formData[EField.TypeOnce]).toLowerCase()),
            });
        },
    }, _a[EField.Info] = {
        "ui:field": ERentioFormField.HelpInfo,
        title: getLocalizedText("payment.payment_order.contract_info.heading"),
        getSubtitle: function (state, formData) {
            var contract = getRelatedContract(extraData, formData);
            if (!contract) {
                return getLocalizedText("payments.create.add_costs.no_contract.content");
            }
            var commonCostsCopy = contract.currentCostsCommon
                ? getLocalizedText("payment.payment_order.contract_info.content.basic.common_costs", {
                    commonCosts: formatCurrency(contract.currentCostsCommon),
                })
                : null;
            var nonCommonCostsCopy = contract.currentCostsNonCommon
                ? getLocalizedText("payment.payment_order.contract_info.content.basic.non_common_costs", {
                    nonCommonCosts: formatCurrency(contract.currentCostsNonCommon),
                })
                : null;
            var costsArray = [commonCostsCopy, nonCommonCostsCopy].filter(Boolean);
            return getLocalizedText("payment.payment_order.contract_info.content.basic".concat(contract.stopDate ? "" : ".no_end_date"), {
                startDate: formatDate(contract.startDate),
                stopDate: contract.stopDate ? formatDate(contract.stopDate) : "",
                amount: formatCurrency(contract.currentPrice),
                costs: costsArray.length > 0
                    ? " + ".concat(costsArray.join(" ".concat(getLocalizedText("system.and"), " ")))
                    : "",
                payDate: contract.payDateStart === EContractPaydate.Calendar
                    ? getLocalizedText("contract.paydate.before_month")
                    : getLocalizedText("contract.paydate.before_period"),
            });
        },
        // TODO: Icon
        // icon: images.ICO_CHAT_SEND
    }, _a[EField.OtherDescription] = {
        "ui:field": ERentioFormField.Text,
        title: getLocalizedText("payment.payment_order.other_description.label"),
        placeholder: getLocalizedText("payment.payment_order.other_description.label"),
        autoFocus: true,
    }, _a[EField.PaymentRepetition] = {
        "ui:field": ERentioFormField.InlineSelect,
        title: getLocalizedText("contract.payment_repetition.option.label"),
        formatSummary: function (value) {
            return getLocalizedText("contract.payment_repetition.option.".concat(value).toLowerCase());
        },
        isDisabled: function (formData) {
            return formData[EField.TypeRecurring] !== EPaymentOrderType.OtherRecurring;
        },
    }, _a[EField.Price] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("payment.payment_order.amount"),
        placeholder: getLocalizedText("payment.payment_order.amount"),
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        isDisabled: function (formData) {
            return isRentOrCost(formData[EField.TypeRecurring]);
        },
        autoFocus: true,
    }, _a), getVatFieldsForUiSchema({
        fieldKey: EField.PriceVat,
        infoFieldKey: EField.PriceVatHelpInfo,
        baseAmountFieldKey: EField.Price,
        isDisabled: function (formData) {
            return !!isRentOrCost(formData[EField.TypeRecurring]);
        },
    })), (_b = {}, _b[EField.DiscountAmount] = {
        "ui:field": ERentioFormField.Text,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("contract.discount.amount.label"),
        getInfo: function (formData) {
            var contract = getRelatedContract(extraData, formData);
            return getLocalizedText("contract.discount.amount.info_extended", {
                amount: "".concat(formatCurrency((contract === null || contract === void 0 ? void 0 : contract.rentDiscountAmount) || 0)),
                offset: "".concat((contract === null || contract === void 0 ? void 0 : contract.rentDiscountOffset) || 0),
            }, (contract === null || contract === void 0 ? void 0 : contract.rentDiscountOffset) || 0);
        },
        suffixText: "EUR",
        hideOnSummaryIfEmpty: true,
        isDisabled: function () { return true; },
    }, _b[EField.PriceCommonCosts] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        getTitle: function (formData) {
            var contract = getRelatedContract(extraData, formData);
            return getLocalizedText("payment.payment_order.common_costs", {
                priceType: getPriceTypeCommonCosts(contract),
            });
        },
        getPlaceholder: function (formData) {
            var contract = getRelatedContract(extraData, formData);
            return getLocalizedText("payment.payment_order.common_costs", {
                priceType: getPriceTypeCommonCosts(contract),
            });
        },
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        isDisabled: function () { return true; },
    }, _b)), getVatFieldsForUiSchema({
        fieldKey: EField.PriceCommonCostsVat,
        infoFieldKey: EField.PriceCommonCostsVatHelpInfo,
        baseAmountFieldKey: EField.PriceCommonCosts,
        isDisabled: function () { return true; },
    })), (_c = {}, _c[EField.PriceNonCommonCosts] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        getTitle: function (formData) {
            var contract = getRelatedContract(extraData, formData);
            return getLocalizedText("payment.payment_order.non_common_costs", {
                priceType: getPriceTypeNonCommonCosts(contract),
            });
        },
        getPlaceholder: function (formData) {
            var contract = getRelatedContract(extraData, formData);
            return getLocalizedText("payment.payment_order.non_common_costs", {
                priceType: getPriceTypeNonCommonCosts(contract),
            });
        },
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        isDisabled: function () { return true; },
    }, _c)), getVatFieldsForUiSchema({
        fieldKey: EField.PriceNonCommonCostsVat,
        infoFieldKey: EField.PriceNonCommonCostsVatHelpInfo,
        baseAmountFieldKey: EField.PriceNonCommonCosts,
        isDisabled: function () { return true; },
    }));
};
export var getValidate = function (_) { return function (formData, errors) {
    if (errors[EField.OtherDescription]) {
        var otherDescription = (formData[EField.OtherDescription] ||
            "");
        if (otherDescription.length > 0) {
            // Contains only alphanumeric characters & space
            var isValid = CONFIG.PAYOUT_REFERENCE_REGEX.test(otherDescription);
            if (!isValid) {
                errors[EField.OtherDescription].addError(getLocalizedText("payment.payment_order.other_description.error.no_special_characters"));
            }
        }
    }
    return errors;
}; };
