import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Modal } from "@rentiohq/web-shared/dist/components";
import { Label } from "@rentiohq/web-shared/dist/components/Labelled/Labelled.styled";
import { useState } from "react";
import { ChromePicker } from "react-color";
import * as S from "./CustomColorPicker.styles";

export interface CustomColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  title: string;
  modalHeading: string;
  buttonText?: string;
  successMessage?: string;
  resetColor?: string;
}

export default function CustomColorPicker({
  color,
  onChange,
  title,
  buttonText,
  modalHeading,
  successMessage,
  resetColor = "white",
}: CustomColorPickerProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pickedColor, setPickedColor] = useState<string | undefined>();

  return (
    <S.ColorPickerWrapper>
      <S.ColorCircle brandColor={color} />
      <div>
        <Label>{title}</Label>
        <Spacer weight={ESpacerWeight.W16} />

        <div style={{ display: "flex" }}>
          <ButtonBasic
            title={
              buttonText ??
              getLocalizedText(
                "editor.template_document.document_config.cta.change",
              )
            }
            onPress={() => {
              setPickedColor(color);
              setModalOpen(true);
            }}
          />
          <ButtonBasic
            style={{ marginLeft: 16 }}
            title={getLocalizedText(
              "editor.template_document.document_config.reset_color",
            )}
            onPress={() => {
              setPickedColor(resetColor);
              onChange(resetColor);
            }}
          />
        </div>
      </div>

      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          heading={modalHeading}
          shouldCloseOnOverlayClick={true}
          hasDismiss={true}
          width="medium"
          actions={[
            {
              content: getLocalizedText("system.ok"),
              onClick: () => {
                if (pickedColor) onChange(pickedColor);
                setModalOpen(false);
                showAlert({
                  type: "success",
                  message:
                    successMessage ??
                    getLocalizedText("preference.updated.alert.title"),
                });
              },
              appearance: "primary",
            },
          ]}
        >
          <S.ChromePickerWrapper>
            <ChromePicker
              color={pickedColor}
              onChangeComplete={color => setPickedColor(color.hex)}
            />
          </S.ChromePickerWrapper>
        </Modal>
      )}
    </S.ColorPickerWrapper>
  );
}
