import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import {
  EExportStatus,
  EExportType,
  IExportFile,
  exportFileModule,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { formatDateAndTime } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  DisplayText,
  EmptyState,
  Error,
  Loading,
  Pagination,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { NumberParam, withDefault } from "serialize-query-params";

export const ExportFiles = () => {
  const [queryParams, setQueryParamValue] = useQueryParams({
    page: withDefault(NumberParam, 1),
  });

  const { items, isFetching, fetchError, totalPages, refetch } =
    exportFileModule.hooks.usePaged({
      query: {
        page: queryParams.page,
        limit: 20,
        sort: [{ field: "createdAt", method: "DESC" }],
        filter: {
          exportType: { neq: EExportType.BeneficiaryReport },
        },
      },
    });

  // Event handlers
  const generateHandlePressDownload =
    (exportFile: IExportFile, file: string) => async () => {
      try {
        const { data } = await exportFileModule.api.getFileUrl({
          id: exportFile.id,
          file,
        });

        const fileUrl = data.data;

        window.open(fileUrl, "_blank");
      } catch (error) {
        showAlert({
          type: "error",
          error,
        });
      }
    };

  const getAppearanceVariationForStatus = (status: EExportStatus) => {
    switch (status) {
      case EExportStatus.New:
        return "warn";

      case EExportStatus.Failed:
        return "negative";

      case EExportStatus.Generated:
        return "positive";

      default:
        return "default";
    }
  };

  // Render
  const renderItem = (item: IExportFile) => {
    return (
      <ResourceListItem
        actions={item.files?.map(file => ({
          content: getLocalizedText(`system.download.filename`, {
            value: `${file}.${item.exportFileType.toLowerCase()}`,
          }),
          onClick: generateHandlePressDownload(item, file),
        }))}
      >
        <DisplayText size="small" space="extraTight">
          {getLocalizedText(
            `export_file.export_type.${item.exportType}`.toLowerCase(),
          )}
        </DisplayText>

        <TextStyle variation="subdued">
          {[
            <TextStyle variation={getAppearanceVariationForStatus(item.status)}>
              {getLocalizedText(
                `export_file.status.${item.status}`.toLowerCase(),
              )}
            </TextStyle>,
            formatDateAndTime(item.createdAt),
            item.exportFileType,
          ].map((item, index) => (
            <>
              {index > 0 && " | "}
              {item}
            </>
          ))}
        </TextStyle>
      </ResourceListItem>
    );
  };

  return (
    <Box>
      {!items && isFetching && <Loading />}

      {!items && fetchError && <Error errors={[fetchError]} />}

      {items?.length === 0 && (
        <EmptyState
          icon="archive"
          heading={getLocalizedText("export_files.empty.title")}
          action={{
            content: getLocalizedText("system.reload"),
            onClick: () => {
              refetch();
            },
          }}
        />
      )}

      <ResourceList items={items || []} renderItem={renderItem} />

      {!!totalPages && totalPages > 1 && (
        <Pagination
          initialPage={queryParams.page - 1}
          pageCount={totalPages}
          onPageChange={({ selected }) => {
            setQueryParamValue({ page: selected + 1 });
          }}
        />
      )}
    </Box>
  );
};
