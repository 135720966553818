import { getSchema, uiSchema } from "./schema.cancelContract.1";
import { getInitialValues } from "./schema.cancelContract.utils";
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema(initialValues, extraData),
            uiSchema: uiSchema(extraData),
        },
    ];
});
