var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FloatingPortal, autoUpdate, flip, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useMergeRefs, useRole, } from "@floating-ui/react";
import * as React from "react";
function useTooltip(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.initialOpen, initialOpen = _c === void 0 ? false : _c, _d = _b.placement, placement = _d === void 0 ? "top" : _d, controlledOpen = _b.open, setControlledOpen = _b.onOpenChange;
    var _e = React.useState(initialOpen), uncontrolledOpen = _e[0], setUncontrolledOpen = _e[1];
    var open = controlledOpen !== null && controlledOpen !== void 0 ? controlledOpen : uncontrolledOpen;
    var setOpen = setControlledOpen !== null && setControlledOpen !== void 0 ? setControlledOpen : setUncontrolledOpen;
    var data = useFloating({
        placement: placement,
        open: open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                crossAxis: placement.includes("-"),
                fallbackAxisSideDirection: "start",
                padding: 5,
            }),
            shift({ padding: 5 }),
        ],
    });
    var context = data.context;
    var hover = useHover(context, {
        move: false,
        enabled: controlledOpen == null,
    });
    var focus = useFocus(context, {
        enabled: controlledOpen == null,
    });
    var dismiss = useDismiss(context);
    var role = useRole(context, { role: "tooltip" });
    var interactions = useInteractions([hover, focus, dismiss, role]);
    return React.useMemo(function () { return (__assign(__assign({ open: open, setOpen: setOpen }, interactions), data)); }, [open, setOpen, interactions, data]);
}
var TooltipContext = React.createContext(null);
var useTooltipContext = function () {
    var context = React.useContext(TooltipContext);
    if (context == null) {
        throw new Error("Tooltip components must be wrapped in <Tooltip />");
    }
    return context;
};
export function CustomTooltip(_a) {
    var children = _a.children, options = __rest(_a, ["children"]);
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    var tooltip = useTooltip(options);
    return (_jsx(TooltipContext.Provider, __assign({ value: tooltip }, { children: children })));
}
export var TooltipTrigger = React.forwardRef(function TooltipTrigger(_a, propRef) {
    var children = _a.children, _b = _a.asChild, asChild = _b === void 0 ? false : _b, props = __rest(_a, ["children", "asChild"]);
    var context = useTooltipContext();
    var childrenRef = children.ref;
    var ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);
    // `asChild` allows the user to pass any element as the anchor
    if (asChild && React.isValidElement(children)) {
        return React.cloneElement(children, context.getReferenceProps(__assign(__assign(__assign({ ref: ref }, props), children.props), { "data-state": context.open ? "open" : "closed" })));
    }
    return (_jsx("button", __assign({ ref: ref, "data-state": context.open ? "open" : "closed" }, context.getReferenceProps(props), { children: children })));
});
export var TooltipContent = React.forwardRef(function TooltipContent(_a, propRef) {
    var style = _a.style, props = __rest(_a, ["style"]);
    var context = useTooltipContext();
    var ref = useMergeRefs([context.refs.setFloating, propRef]);
    if (!context.open) {
        return null;
    }
    return (_jsx(FloatingPortal, { children: _jsx("div", __assign({ ref: ref, style: __assign(__assign({ zIndex: 99999999999999 }, context.floatingStyles), style) }, context.getFloatingProps(props))) }));
});
