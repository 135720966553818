import * as contractActions from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.actions";
import * as contractSelectors from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import { isValidStopDateForTacitRenewal } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { isResidential } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { EContractLengthType } from "@rentiohq/shared/dist/types/contract.types";
import {
  addYears,
  isBefore,
  startOfDay,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { EField, IExtraData } from "./schema.renewContract.types";

export const isPerformingSubmitActionSelector =
  (contractId: string) => (state: IPartialRootState) =>
    contractSelectors.isRenewing(state, contractId);

export const performingSubmitActionErrorSelector =
  (contractId: string) => (state: IPartialRootState) =>
    contractSelectors.renewError(state, contractId);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { property, contract } = extraData;

    const startDate = formData[EField.StartDate];
    const stopDate = formData[EField.NewEndDate];

    const canRenewTacitly =
      isResidential(property) &&
      isValidStopDateForTacitRenewal({
        startDate,
        stopDate,
      });

    let contractLengthType = null;
    if (isResidential(property)) {
      const finalDateShortContract = addYears(startOfDay(startDate), 3);
      if (isBefore(stopDate, finalDateShortContract)) {
        contractLengthType = EContractLengthType.Short;
      } else {
        contractLengthType = EContractLengthType.Long;
      }
    }

    return contractActions.renewStart.getAction({
      id: contract.id,
      stopDate: formData[EField.NewEndDate],
      tacitRenewal: canRenewTacitly
        ? formData[EField.TacitRenewal] === "yes"
        : false,
      contractLengthType,
    });
  };
