import { Box } from "@rebass/grid";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import { EPaymentRequestFollowUpType } from "@rentiohq/shared/dist/types/payment.types";
import {
  DisplayText,
  ESpacings,
  MultiStepForm as Form,
} from "@rentiohq/web-shared/dist/components";
import { AccountList } from "@rentiohq/web-shared/dist/components/AccountList";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { IPaymentFollowUpScreenProps } from "../PaymentFollowUpModal.types";
import createFollowUpSmsForm from "../forms/followUpSms";

const formId = generateFormId();

export const SmsScreen: React.FC<any> = React.forwardRef(
  (
    {
      followUpItem,
      renderActivities,
      onClose,
      setIsLoading,
      contact,
    }: IPaymentFollowUpScreenProps,
    formRef: any,
  ) => {
    // State

    const dispatch = useDispatch();

    const isPendingFollowUp =
      useSelector((state: IRootStore) =>
        paymentSelectors.isPendingFollowUp(
          state,
          followUpItem.id,
          EPaymentRequestFollowUpType.Sms,
        ),
      ) || false;

    const followUpError =
      useSelector((state: IRootStore) =>
        paymentSelectors.followUpError(
          state,
          followUpItem.id,
          EPaymentRequestFollowUpType.Sms,
        ),
      ) || false;
    const prevIsPendingFollowUp = usePrevious(isPendingFollowUp);

    React.useEffect(() => {
      setIsLoading?.(isPendingFollowUp);
    }, [isPendingFollowUp, setIsLoading]);

    React.useEffect(() => {
      if (!!prevIsPendingFollowUp && !isPendingFollowUp && !followUpError) {
        onClose?.();
      }
    }, [prevIsPendingFollowUp, isPendingFollowUp, followUpError]);

    // Events

    const handleSuccess = async (values: any) => {
      const { smsContent, smsNumber } = values;
      dispatch(
        paymentActions.paymentRequestFollowUpSms.actions.start({
          paymentRequestId: followUpItem.id,
          body: {
            smsContent,
            smsNumber,
          },
        }),
      );
    };

    // Render

    if (!contact) {
      return null;
    }

    return (
      <>
        <DisplayText size="small">
          {getLocalizedText("contact.dashboard.contact_data.heading")}
        </DisplayText>
        <AccountList accounts={[followUpItem.payerAccount] as IAccount[]} />
        <Box mt={ESpacings.loose}>
          <Form
            ref={formRef}
            formId={`follow-up-sms-${formId}`}
            preventSubmitThroughEnter={true}
            schemas={createFollowUpSmsForm({ contact })}
            showControls={false}
            withAside={false}
            onSuccess={handleSuccess}
            isLoading={isPendingFollowUp}
          />
        </Box>
        {renderActivities}
      </>
    );
  },
);
