import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Label } from "@rentiohq/web-shared/dist/components/Labelled/Labelled.styled";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as S from "./Communication.style";

interface CommunicationPreviewProps {
  title?: string;
  logoPreferenceKey: string;
  bgColorPreferenceKey?: string;
  textColorPreferenceKey?: string;
}

export default function CommunicationPreview({
  title,
  logoPreferenceKey,
  textColorPreferenceKey,
}: // bgColorPreferenceKey,
CommunicationPreviewProps) {
  const communicationsImageId = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<any>(state, logoPreferenceKey),
  );
  const fetchedDocument = useSelector((state: IRootStore) =>
    documentSelectors.getDetail(state, communicationsImageId),
  );

  // const bgColor = useSelector((state: IRootStore) =>
  //   systemSelectors.getPreference<string>(state, bgColorPreferenceKey),
  // );

  const color = useSelector((state: IRootStore) =>
    textColorPreferenceKey
      ? systemSelectors.getPreference<string>(state, textColorPreferenceKey)
      : undefined,
  );

  return (
    <>
      <Label>{title}</Label>

      <Spacer weight={ESpacerWeight.W16} />

      {fetchedDocument ? (
        <S.CommunicationPreviewWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {color && (
              <>
                <span style={{ color }}>
                  {getLocalizedText(
                    "editor.template_document.document_config.communication_preview.preview_text",
                  )}
                </span>

                <Spacer weight={ESpacerWeight.W16} />
              </>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                alt="communication-preview"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={fetchedDocument.url}
              />
            </div>
          </div>
        </S.CommunicationPreviewWrapper>
      ) : null}
    </>
  );
}
