var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { uniq } from "lodash";
import React from "react";
export var useMultiselect = function (initialSelection, limit) {
    //State
    var _a = React.useState(initialSelection), selectedIds = _a[0], setSelectedIds = _a[1];
    // Events
    var onSelectIds = function (ids) {
        var newItems = uniq(__spreadArray(__spreadArray([], selectedIds, true), ids, true));
        if (limit) {
            newItems = newItems.slice(0, limit);
        }
        setSelectedIds(newItems);
    };
    var onDeselectIds = function (ids) {
        setSelectedIds(selectedIds.filter(function (i) { return !ids.includes(i); }));
    };
    var onSelectId = function (id) {
        onSelectIds([id]);
    };
    var onDeselectId = function (id) {
        onDeselectIds([id]);
    };
    var onDeselectAll = function () {
        setSelectedIds([]);
    };
    // Lifecycle
    // React.useEffect(() => {
    //   setSelectedIds(initialSelection);
    // }, [initialSelection]);
    return {
        selectedIds: selectedIds,
        onSelectIds: onSelectIds,
        onDeselectIds: onDeselectIds,
        onSelectId: onSelectId,
        onDeselectId: onDeselectId,
        onDeselectAll: onDeselectAll,
    };
};
