import { JSONSchema6 } from "json-schema";
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { EField } from "./schema.followUpRegisteredMail.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ts } from "../../../../services";
import { addBusinessDays } from "@rentiohq/shared/dist/utils/date-fns.utils";

export const getSchema =
  (initialValues: { [key: string]: any }) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [
      EField.Subject,
      EField.Content,
      EField.FirstName,
      EField.LastName,
      EField.Email,
      EField.Address,
      EField.CutOffDate,
    ],
    properties: {
      [EField.Subject]: {
        default: getValue(EField.Subject, initialValues, defaultValues),
      },
      [EField.Content]: {
        default: getValue(EField.Content, initialValues, defaultValues),
      },
      [EField.FirstName]: {
        default: getValue(EField.FirstName, initialValues, defaultValues),
      },
      [EField.LastName]: {
        default: getValue(EField.LastName, initialValues, defaultValues),
      },
      [EField.Email]: {
        format: "email",
        default: getValue(EField.Email, initialValues, defaultValues),
      },
      [EField.CutOffDate]: {
        default: getValue(EField.CutOffDate, initialValues, defaultValues),
      },
      [EField.Address]: {
        default: getValue(EField.Address, initialValues, defaultValues),
      },
    },
  });

export const uiSchema = () => ({
  [EField.Subject]: {
    "ui:field": ERentioFormField.Text,
    title: ts.paymentsFollowUpRegisteredMailSubjectLabel(),
  },
  [EField.Content]: {
    "ui:field": ERentioFormField.Text,
    title: ts.paymentsFollowUpRegisteredMailContentLabel(),
    multiline: true,
  },
  [EField.FirstName]: {
    "ui:field": ERentioFormField.Text,
    title: ts.firstname(),
  },
  [EField.LastName]: {
    "ui:field": ERentioFormField.Text,
    title: ts.lastname(),
  },
  [EField.Email]: {
    "ui:field": ERentioFormField.Email,
    title: ts.paymentsFollowUpModalPhoneEmailLabel(),
  },
  [EField.CutOffDate]: {
    "ui:field": ERentioFormField.Date,
    title: ts.paymentsFollowUpModalPhoneCutOffDateDataLabel(),
    minDate: addBusinessDays(new Date(), 2),
  },
  [EField.Address]: {
    "ui:field": ERentioFormField.Address,
    title: ts.paymentsFollowUpModalPhoneAddressLabel(),
  },
});
