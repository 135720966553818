import { Box } from "@rebass/grid";
import createRentDepositForm from "@rentiohq/shared-frontend/dist/forms/createRentDeposit";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/createRentDeposit/schema.createRentDeposit.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";
import {
  Card,
  DisplayText,
  Error,
  ESpacings,
  List,
  Loading,
  MultiStepForm,
  Page,
  SuccessModal,
} from "@rentiohq/web-shared/dist/components";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ts } from "../../services";

const formId = generateFormId();

export const RentDepositForm: FC<{}> = () => {
  const navigate = useNavigate();

  const { contractId, ...params } = useParams<{
    propertyId: string;
    contractId: string;
  }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const [createdRentDepositId, setCreatedRentDepositId] = useState<number>();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { isBroker } = authHooks.useSelf();
  const contactMeMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactMeMaster(state),
  );
  const legalContact = useSelector((state: IRootStore) =>
    isBroker
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.Legal)
      : undefined,
  );
  const isFetchingLegalContact = useSelector((state: IRootStore) =>
    isBroker
      ? state.contact.contactsById[EContactCustomId.Legal].isFetching
      : false,
  );
  const hasInsurance = useBrokerFeature(EBrokerFeature.Insurance);

  const { detail: contract, fetchError: contractFetchError } =
    contractHooks.useDetail({ id: contractId });

  const { detail: property, fetchError: propertyFetchError } =
    propertyHooks.useDetail({
      id: propertyId,
    });

  const fetchError = contractFetchError || propertyFetchError;

  if (isBroker && isFetchingLegalContact) {
    return <Loading />;
  }

  if (fetchError) {
    return <Error errors={[fetchError]} />;
  }

  const handleSuccess = (rentDepositId: number) => {
    setCreatedRentDepositId(rentDepositId);

    setModalOpen(true);
  };

  const handleModalClose = () => {
    handleFinish();
  };

  const handleFinish = () => {
    navigate(
      `/properties/${propertyId}?rentDepositId=${createdRentDepositId}&requestInsuranceContractId=${contractId}`,
    );
  };

  const renderForm = () => {
    if (!property) {
      return null;
    }

    if (!contract) {
      return null;
    }

    const extraData = {
      property,
      contract,
      isBroker,
      legalContact,
      contactMeMaster,
      withInsuranceRequest: hasInsurance,
    };

    return (
      <MultiStepForm
        formId={`rent-deposit-form-${formId}`}
        // schemas={schemas}
        schemas={createRentDepositForm(extraData)}
        withSummary={true}
        submitLabel={ts.system("save_send")}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelector()}
        performingSubmitActionResultSelector={performingSubmitActionResultSelector()}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelector()}
        submitActionCreator={(formData: any) => {
          return submitActionCreator(extraData)(formData);
        }}
        onSuccess={handleSuccess}
      />
    );
  };

  const modalActions = [
    {
      content: ts.system("close"),
      onClick: handleModalClose,
      appearance: "primary",
    } as any,
  ];

  return (
    <Page
      title={ts.rentDepositCreateHeading()}
      // subtitle={formatAddress(property)}
      breadcrumbs={{
        to: !!propertyId
          ? `/properties/${propertyId}`
          : "/follow-up/rent-deposits?page=1",
        content: !!propertyId
          ? ts.contractDetailBreadcrumb()
          : ts.followUpRentDepositsBreadcrumb(),
      }}
    >
      {!isModalOpen && <Card>{renderForm()}</Card>}
      {isModalOpen && (
        <SuccessModal onClose={handleModalClose} actions={modalActions}>
          <DisplayText>
            {ts.contractOpenRentDepositAddedModalHeading()}
          </DisplayText>
          <Box mb={ESpacings.base}>
            {ts.contractOpenRentDepositAddedModalIntro()}
          </Box>
          <Box mb={ESpacings.base}>
            <DisplayText size="medium">
              {ts.contractOpenRentDepositAddedModalNextStepsHeading()}
            </DisplayText>
            <List>
              <List.Item>
                {ts.contractOpenRentDepositAddedModalNextStepsStep(1)}
              </List.Item>
              <List.Item>
                {ts.contractOpenRentDepositAddedModalNextStepsStep(2)}
              </List.Item>
              <List.Item>
                {ts.contractOpenRentDepositAddedModalNextStepsStep(3)}
              </List.Item>
            </List>
          </Box>
          <Box>{ts.contractOpenRentDepositAddedModalOutro()}</Box>
        </SuccessModal>
      )}
    </Page>
  );
};
