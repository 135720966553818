var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from "./LoadingWrapper.styled";
var LoadingWrapper = function (props) {
    var isLoading = props.isLoading, children = props.children;
    return (_jsxs(S.Wrapper, { children: [_jsx(S.Content, __assign({ isLoading: isLoading }, { children: children })), isLoading && (_jsx(S.LoaderContainer, { children: _jsx(S.Loader, { children: _jsx(S.Dots, { asDots: true }) }) }))] }));
};
export default LoadingWrapper;
