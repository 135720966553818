import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ENaturalGasAvailable,
  ENaturalGasMeterType,
} from "@rentiohq/shared/dist/types/property.types";
import { JSONSchema6 } from "json-schema";
import { FormValidation, UiSchema } from "react-native-jsonschema-form-test";
import { EAN_MASK } from "./schema.requestMoveService.config";
import { EField, IExtraData } from "./schema.requestMoveService.types";
import { validationErrorsForEan } from "./schema.requestMoveService.utils";

export const getSchema =
  (initialValues: { [key: string]: any }, _: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.NaturalGasAvailable],
      properties: {
        [EField.NaturalGasAvailable]: {
          default: getValue(
            EField.NaturalGasAvailable,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            ENaturalGasAvailable.Available,
            ENaturalGasAvailable.Unavailable,
            ENaturalGasAvailable.Unknown,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.natural_gas_type.${id}`.toLowerCase(),
            ),
          })),
        },
        [EField.NaturalGasEan]: {
          default: getValue(EField.NaturalGasEan, initialValues, defaultValues),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
        },
        [EField.NaturalGasMeterNumber]: {
          default: getValue(
            EField.NaturalGasMeterNumber,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
        },
        [EField.NaturalGasMeterType]: {
          default: getValue(
            EField.NaturalGasMeterType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            ENaturalGasMeterType.Standard,
            ENaturalGasMeterType.Opening,
            ENaturalGasMeterType.Budget,
            ENaturalGasMeterType.Empty,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.natural_gas_meter_type.${id}`.toLowerCase(),
            ),
          })),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
          requiredIf: (formData: any) =>
            formData[EField.NaturalGasAvailable] ===
            ENaturalGasAvailable.Available,
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): UiSchema => ({
  [EField.NaturalGasAvailable]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_type.title"),
  },
  [EField.NaturalGasEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_ean.title"),
    optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
  },
  [EField.NaturalGasMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.natural_gas_meter_number.title"),
    optional: true,
  },
  [EField.NaturalGasMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_meter_type.title"),
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    validationErrorsForEan(formData[EField.NaturalGasEan]).forEach(error => {
      errors[EField.NaturalGasEan].addError(error);
    });

    return errors;
  };
