import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EField,
  ELocationDescriptionType,
  IExtraData,
} from "./schema.orderLocationDescription.types";

export const getInitialValues = ({
  newContract,
  oldContract,
}: IExtraData): IValues => {
  if (!newContract) {
    return {
      [EField.LocationDescriptionType]: ELocationDescriptionType.Out,
    };
  }
  if (!oldContract) {
    return {
      [EField.LocationDescriptionType]: ELocationDescriptionType.In,
    };
  }
  return {};
};
