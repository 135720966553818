import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import {
  EPropertyMemberTypes,
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import { Loading, TextStyle } from "@rentiohq/web-shared/dist/components";
import { useIsRentioLegalActivated } from "scenes/RentioLegal/hooks/useIsRentioLegalActivated";
import PropertyListItem from "../PropertyListItem";
import {
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";
import ContractsFetchListItem from "../PropertyListItemRent/ContractsFetchListItem";
import StatusDot from "../StatusDot";
import { EStatus } from "../StatusDot/StatusDot.types";
import StatusDotContractIndexStatus from "../StatusDotContractIndexStatus";
import { StatusDotLegal } from "../StatusDotLegal";
import StatusDotPropertyPaymentRequests from "../StatusDotPropertyPaymentRequests";
import StatusDotPropertyTasks from "../StatusDotPropertyTasks";
import {
  WIDTH_CONTRACT_DATE,
  WIDTH_OWNERS,
  WIDTH_TENANTS,
} from "./PropertyListItemManagement.constants";
import * as S from "./PropertyListItemManagement.styles";

interface IProps {
  property: IProperty;
  selectedParentId?: number;
  onSetParentId: (parentId?: number) => void;
}

const PropertyListItemManagement = (props: IProps) => {
  const { property, onSetParentId } = props;

  const hasRentioLegal = useIsRentioLegalActivated();

  // Render
  if (property.typeId === EPropertyTypeId.Group) {
    return (
      <PropertyListItem property={property} onSetParentId={onSetParentId} />
    );
  }

  const renderOwners = () => {
    const ownerCount = property.members.filter(member =>
      member.roles.includes(EPropertyMemberTypes.Owner),
    ).length;

    const hasOwners = ownerCount > 0;

    return (
      <PropertyListItemItem
        style={{ width: WIDTH_OWNERS, textAlign: "center" }}
      >
        <TextStyle variation={hasOwners ? [] : ["strong", "warn"]}>
          {ownerCount}
        </TextStyle>
      </PropertyListItemItem>
    );
  };

  const renderTenants = (contract?: IContract) => {
    if (!contract) {
      return (
        <TextStyle variation={"subdued"}>
          {getLocalizedText("contracts.overview.contract.no_tenants")}
        </TextStyle>
      );
    }

    const tenants = contract.members.filter(
      member =>
        member.roles.includes(EContractMemberTypes.Tenant) ||
        member.roles.includes(EContractMemberTypes.Parent),
    );

    const resultComponents = [];
    if (tenants.length > 0) {
      resultComponents.push(`${getName(tenants[0].account)}`);
    }
    if (tenants.length > 1) {
      resultComponents.push(`+ ${tenants.length - 1}`);
    }

    return <TextStyle>{resultComponents.join(" ")}</TextStyle>;
  };

  const renderStartDate = (contract?: IContract) => {
    if (!contract) {
      return <TextStyle variation={"subdued"}>-</TextStyle>;
    }

    return <TextStyle>{formatDate(contract.startDate)}</TextStyle>;
  };

  const renderStopDate = (contract?: IContract) => {
    if (!contract) {
      return <TextStyle variation={"subdued"}>-</TextStyle>;
    }

    if (contract.stopDate) {
      return <TextStyle>{formatDate(contract.stopDate)}</TextStyle>;
    }

    return <TextStyle variation="subdued">-</TextStyle>;
  };

  const renderContractInfo = (params: {
    contract?: IContract;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    const { contract, isFirst = true, isLast = true } = params;

    return (
      <div
        key={contract?.id || "empty"}
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: isFirst ? 0 : 4,
          paddingBottom: isLast ? 0 : 4,
        }}
      >
        <PropertyListItemItem
          style={{
            width: WIDTH_TENANTS - 8,
            marginRight: 8,
          }}
          tooltipContent={renderTenants(contract)}
        >
          {renderTenants(contract)}
        </PropertyListItemItem>

        <S.StartDateWrap>
          <PropertyListItemItem
            style={{
              width: WIDTH_CONTRACT_DATE,
            }}
          >
            {renderStartDate(contract)}
          </PropertyListItemItem>
        </S.StartDateWrap>

        <S.EndDateWrap>
          <PropertyListItemItem
            style={{
              width: WIDTH_CONTRACT_DATE,
            }}
          >
            {renderStopDate(contract)}
          </PropertyListItemItem>
        </S.EndDateWrap>
      </div>
    );
  };

  const renderContractStatusDots = (params: {
    contract?: IContract;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    const { contract, isFirst = true, isLast = true } = params;

    return (
      <div
        key={contract?.id || "empty"}
        style={{
          display: "flex",
          flexDirection: "row",
          paddingTop: isFirst ? 0 : 4,
          paddingBottom: isLast ? 0 : 4,
        }}
      >
        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotPropertyPaymentRequests
              propertyId={property.id}
              contractId={contract.id}
            />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotContractIndexStatus
              property={property}
              contract={contract}
            />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotPropertyTasks propertyId={property.id} />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? (
            <StatusDotLegal
              property={property}
              contract={contract}
              hasLegalFeature={hasRentioLegal}
            />
          ) : (
            <StatusDot status={EStatus.None} />
          )}
        </PropertyListItemItem>
      </div>
    );
  };

  const renderNoTenants = () => (
    <>
      <S.InfoWrap>{renderContractInfo({})}</S.InfoWrap>
      <PropertyListItemSpacer />
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {renderContractStatusDots({})}
      </div>
    </>
  );

  const renderContracts = () => {
    const hasTenants = !!property.members.find(
      member =>
        member.roles.includes(EPropertyMemberTypes.Tenant) ||
        member.roles.includes(EPropertyMemberTypes.Parent),
    );

    if (!hasTenants) {
      return renderNoTenants();
    }

    return (
      <ContractsFetchListItem
        propertyId={property.id}
        renderLoading={() => {
          return (
            <S.LoadingWrap>
              <S.LoadingInnerWrap>
                <Loading asDots={true} />
              </S.LoadingInnerWrap>
            </S.LoadingWrap>
          );
        }}
        renderContracts={(contracts: IContract[]) => {
          if (contracts.length === 0) {
            return renderNoTenants();
          }

          return (
            <>
              <S.InfoWrap>
                {contracts.map((contract, index) => {
                  const isFirst = index === 0;
                  const isLast = index === contracts.length - 1;

                  return renderContractInfo({ contract, isFirst, isLast });
                })}
              </S.InfoWrap>
              <PropertyListItemSpacer />
              <div
                style={{ flex: 1, display: "flex", flexDirection: "column" }}
              >
                {contracts.map((contract, index) => {
                  const isFirst = index === 0;
                  const isLast = index === contracts.length - 1;

                  return renderContractStatusDots({
                    contract,
                    isFirst,
                    isLast,
                  });
                })}
              </div>
            </>
          );
        }}
      />
    );
  };

  return (
    <PropertyListItem property={property} onSetParentId={onSetParentId}>
      {renderOwners()}

      <PropertyListItemSpacer />

      <S.ChildrenWrap>{renderContracts()}</S.ChildrenWrap>
    </PropertyListItem>
  );
};

export default PropertyListItemManagement;
