var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import logger from "@rentiohq/shared/dist/logger";
import { logError } from "@rentiohq/shared/dist/logger/logger";
import HttpStatus from "http-status-codes";
import { END, eventChannel } from "redux-saga";
import { all, call, put, select, take, takeEvery, takeLatest, } from "redux-saga/effects";
import api from "../../utils/api/api.utils";
import { getLocale } from "../../utils/i18n/i18n.utils";
import * as authUtils from "../auth/auth.utils";
import * as socketActions from "./socket.actions";
import * as socketSelectors from "./socket.selectors";
import { ESocketType, ISocketReadyState } from "./socket.types";
var socket;
var createSocketChannel = function (accessToken) {
    return eventChannel(function (emitter) {
        var url = CONFIG.URL_SOCKETS.replace("{{locale}}", getLocale());
        var configuredSocketApi = url.replace("{{accessToken}}", accessToken);
        socket = new WebSocket(configuredSocketApi);
        // Poll socket state
        var pollInterval = setInterval(function () {
            if (!socket) {
                return;
            }
            emitter({ type: ESocketType.OnPoll, payload: socket.readyState });
        }, 50);
        socket.onopen = function () {
            emitter({ type: ESocketType.OnOpen });
        };
        socket.onmessage = function (message) {
            emitter({ type: ESocketType.OnMessage, payload: message });
        };
        socket.onerror = function () {
            emitter({ type: ESocketType.OnError });
            if (socket) {
                socket.close();
            }
        };
        socket.onclose = function () {
            emitter({ type: ESocketType.OnClose });
            emitter(END);
        };
        return function () {
            socket = undefined;
            clearInterval(pollInterval);
        };
    });
};
function socketChannelEventHandlerFlow(_a) {
    var _b, sendPendingMessagesOnNextOpen, _c, _d, pendingMessages, _e, _f, jsonData, readyState, prevReadyState, unknownError_1, error;
    var _g;
    var type = _a.type, payload = _a.payload;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _h.trys.push([0, 24, , 25]);
                _b = type;
                switch (_b) {
                    case ESocketType.OnOpen: return [3 /*break*/, 1];
                    case ESocketType.OnMessage: return [3 /*break*/, 7];
                    case ESocketType.OnError: return [3 /*break*/, 13];
                    case ESocketType.OnClose: return [3 /*break*/, 15];
                    case ESocketType.OnPoll: return [3 /*break*/, 18];
                }
                return [3 /*break*/, 22];
            case 1:
                _d = (_c = socketSelectors).sendPendingMessagesOnNextOpen;
                return [4 /*yield*/, select()];
            case 2:
                sendPendingMessagesOnNextOpen = _d.apply(_c, [_h.sent()]);
                if (!sendPendingMessagesOnNextOpen) return [3 /*break*/, 5];
                _f = (_e = socketSelectors).pendingMessages;
                return [4 /*yield*/, select()];
            case 3:
                pendingMessages = _f.apply(_e, [_h.sent()]);
                return [4 /*yield*/, all(Object.values(pendingMessages).map(function (pendingMessage) {
                        return put(socketActions.socketMessageSend(pendingMessage));
                    }))];
            case 4:
                _h.sent();
                _h.label = 5;
            case 5: return [4 /*yield*/, put(socketActions.openSocket.actions.success({}))];
            case 6:
                _h.sent();
                return [3 /*break*/, 23];
            case 7:
                if (!payload) return [3 /*break*/, 12];
                jsonData = JSON.parse(payload.data);
                if (!(((_g = jsonData === null || jsonData === void 0 ? void 0 : jsonData.message) === null || _g === void 0 ? void 0 : _g.statusCode) === HttpStatus.UNAUTHORIZED)) return [3 /*break*/, 10];
                // TODO: Refactor to pure token refresh
                return [4 /*yield*/, call(api.get, "/users/me")];
            case 8:
                // TODO: Refactor to pure token refresh
                _h.sent();
                return [4 /*yield*/, put(socketActions.closeSocket.actions.start({
                        autoReopenAfterClose: true,
                        sendPendingMessagesOnNextOpen: true,
                    }))];
            case 9:
                _h.sent();
                return [3 /*break*/, 23];
            case 10: return [4 /*yield*/, put(socketActions.socketMessageReceived(payload))];
            case 11:
                _h.sent();
                _h.label = 12;
            case 12: return [3 /*break*/, 23];
            case 13: return [4 /*yield*/, put(socketActions.closeSocket.actions.start({
                    autoReopenAfterClose: true,
                    sendPendingMessagesOnNextOpen: true,
                }))];
            case 14:
                _h.sent();
                return [3 /*break*/, 23];
            case 15: return [4 /*yield*/, put(socketActions.closeSocket.actions.success({}))];
            case 16:
                _h.sent();
                return [4 /*yield*/, put(socketActions.socketUpdateReadyState(undefined))];
            case 17:
                _h.sent();
                return [3 /*break*/, 23];
            case 18:
                if (!payload) return [3 /*break*/, 21];
                readyState = payload;
                return [4 /*yield*/, select(function (state) { return state.socket.readyState; })];
            case 19:
                prevReadyState = _h.sent();
                if (!(readyState !== prevReadyState)) return [3 /*break*/, 21];
                return [4 /*yield*/, put(socketActions.socketUpdateReadyState(readyState))];
            case 20:
                _h.sent();
                _h.label = 21;
            case 21: return [3 /*break*/, 23];
            case 22: return [3 /*break*/, 23];
            case 23: return [3 /*break*/, 25];
            case 24:
                unknownError_1 = _h.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [3 /*break*/, 25];
            case 25: return [2 /*return*/];
        }
    });
}
function openSocketStartFlow(_) {
    var accessToken, readyState, channel, event_1, unknownError_2, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 17, , 19]);
                return [4 /*yield*/, call(authUtils.getAccessToken)];
            case 1:
                accessToken = _a.sent();
                if (!!accessToken) return [3 /*break*/, 3];
                return [4 /*yield*/, put(socketActions.openSocket.actions.failure({
                        error: new Error("Could not open socket. Reason: access token undefined"),
                    }))];
            case 2:
                _a.sent();
                return [2 /*return*/];
            case 3:
                if (!socket) return [3 /*break*/, 5];
                return [4 /*yield*/, put(socketActions.openSocket.actions.failure({
                        error: new Error("Could not open socket. Reason: socket already exists"),
                    }))];
            case 4:
                _a.sent();
                return [2 /*return*/];
            case 5: return [4 /*yield*/, select(function (state) { var _a; return (_a = state.socket) === null || _a === void 0 ? void 0 : _a.readyState; })];
            case 6:
                readyState = _a.sent();
                if (!(readyState &&
                    [ISocketReadyState.Open, ISocketReadyState.Connecting].includes(readyState))) return [3 /*break*/, 8];
                return [4 /*yield*/, put(socketActions.openSocket.actions.failure({
                        error: new Error("Could not open socket. Reason: already " + readyState),
                    }))];
            case 7:
                _a.sent();
                return [2 /*return*/];
            case 8: return [4 /*yield*/, call(createSocketChannel, accessToken)];
            case 9:
                channel = _a.sent();
                _a.label = 10;
            case 10:
                _a.trys.push([10, , 15, 16]);
                _a.label = 11;
            case 11:
                if (!true) return [3 /*break*/, 14];
                return [4 /*yield*/, take(channel)];
            case 12:
                event_1 = _a.sent();
                return [4 /*yield*/, call(socketChannelEventHandlerFlow, event_1)];
            case 13:
                _a.sent();
                return [3 /*break*/, 11];
            case 14: return [3 /*break*/, 16];
            case 15:
                console.info("receive action event channel terminated");
                return [7 /*endfinally*/];
            case 16: return [3 /*break*/, 19];
            case 17:
                unknownError_2 = _a.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(socketActions.openSocket.actions.failure({ error: error }))];
            case 18:
                _a.sent();
                return [3 /*break*/, 19];
            case 19: return [2 /*return*/];
        }
    });
}
function closeSocketStartFlow(_) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(socketActions.socketUpdateReadyState(undefined))];
            case 1:
                _a.sent();
                socket === null || socket === void 0 ? void 0 : socket.close();
                socket = undefined;
                return [4 /*yield*/, put(socketActions.closeSocket.actions.success({}))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function closeSocketSuccessFlow(_) {
    var autoReopenAfterClose, _a, _b, unknownError_3, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 3, , 4]);
                _b = (_a = socketSelectors).autoReopenAfterClose;
                return [4 /*yield*/, select()];
            case 1:
                autoReopenAfterClose = _b.apply(_a, [_c.sent()]);
                if (!autoReopenAfterClose) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, put(socketActions.openSocket.actions.start({}))];
            case 2:
                _c.sent();
                return [3 /*break*/, 4];
            case 3:
                unknownError_3 = _c.sent();
                error = unknownError_3;
                logError({ error: error });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function socketMessageSendFlow(_a) {
    var payload = _a.payload;
    try {
        if (!socket) {
            throw new Error("Could not send message over socket. Reason: socket does not exist");
        }
        socket.send(JSON.stringify(payload));
    }
    catch (unknownError) {
        var error = unknownError;
        logger.logError({ error: error });
    }
}
function signOutFlow() {
    var unknownError_4, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, put(socketActions.closeSocket.actions.start({
                        autoReopenAfterClose: false,
                        sendPendingMessagesOnNextOpen: false,
                    }))];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                unknownError_4 = _a.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(socketActions.openSocket.types.START, openSocketStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(socketActions.closeSocket.types.START, closeSocketStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(socketActions.closeSocket.types.SUCCESS, closeSocketSuccessFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(socketActions.socketMessageSend, socketMessageSendFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery("LOG_OUT", signOutFlow)];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default saga;
