import { IProperty } from "@rentiohq/shared/dist/types/property.types";

export enum EExtraInfoGroup {
  Epc = "epc",
  Bookkeeping = "bookkeeping",
  Electricity = "electricity",
  NaturalGas = "natural_gas",
  Water = "water",
  OtherProperties = "other_properties",
}

export interface IExtraData {
  property: IProperty;
  extraInfoGroup: EExtraInfoGroup;
}

export enum EField {
  Header = "_header",

  // EPC
  // Custom EPC-schema

  // Bookkeeping
  CostUnit = "costUnit",
  BookkeepingPaidOutStartDate = "bookkeepingPaidOutStartDate",

  // Electricity
  ElectricityType = "electricityType",
  ElectricityEan = "electricityEan",
  ElectricityMeterNumberBoth = "electricityMeterNumberBoth",
  ElectricityMeterType = "electricityMeterType",

  // Natural Gas
  NaturalGasAvailable = "naturalGasAvailable",
  NaturalGasEan = "naturalGasEan",
  NaturalGasMeterNumber = "naturalGasMeterNumber",
  NaturalGasMeterType = "naturalGasMeterType",

  // Water
  WaterMeterNumber = "waterMeterNumber",

  // Physical Info
  BuildingSurfaceArea = "buildingSurfaceArea",
  BuildingFloor = "buildingFloor",
  BuildingFloorCount = "buildingFloorCount",
  BuildingAdjoined = "buildingAdjoined",
  RoomCountBedroom = "roomCountBedroom",
  RoomCountBathroom = "roomCountBathroom",
  IsFurnished = "isFurnished",
}
