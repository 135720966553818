import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createRentDeposit.types";
import { getNavigationTitle, getTenants, } from "./schema.createRentDeposit.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Payer],
            properties: (_a = {},
                _a[EField.Header + "6"] = {
                    default: undefined,
                },
                _a[EField.Payer] = {
                    default: getValue(EField.Payer, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("rent_deposit.payer.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "6"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit.payer.heading"),
        },
        _a[EField.Payer] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("rent_deposit.payer.label"),
            isMultiSelect: false,
            asOptionList: true,
            valueSelector: function (state, selectedAccountIds, formData) {
                if (selectedAccountIds === void 0) { selectedAccountIds = []; }
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, extraData.contract.members.map(function (member) { return member.account; }));
            },
            customGetSubtitleSelector: function (_, formData, item) {
                var accountId = item.accountId, contact = item.contact;
                var member = extraData.contract.members.find(function (member) {
                    if (accountId && accountId === member.account.id) {
                        return true;
                    }
                    if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
                        return true;
                    }
                    return false;
                });
                if (!member) {
                    return;
                }
                return member.roles
                    .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
                    .join(", ");
            },
            customExtraAccountsSelector: function (_, formData) {
                return getTenants(formData, extraData);
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a);
};
