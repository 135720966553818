import { ERegistrationDocumentType } from "@rentiohq/shared/dist/types/registration.types";
import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.registrationAddDocument.1";
import {
  getSchema as getSchema2,
  uiSchema as uiSchema2,
} from "./schema.registrationAddDocument.2";
import {
  getSchema as getSchema3,
  uiSchema as uiSchema3,
} from "./schema.registrationAddDocument.3";
import {
  getSchema as getSchema4,
  uiSchema as uiSchema4,
} from "./schema.registrationAddDocument.4";
import { IExtraData } from "./schema.registrationAddDocument.types";
import { getInitialValues } from "./schema.registrationAddDocument.utils";

export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);

  return [
    {
      schema: getSchema1(initialValues, extraData),
      uiSchema: uiSchema1(extraData),
    },
    {
      schema: getSchema2(initialValues, extraData),
      uiSchema: uiSchema2(extraData),
    },
    {
      schema: getSchema3(initialValues, extraData),
      uiSchema: uiSchema3(extraData),
    },
    {
      schema: getSchema4(initialValues, extraData),
      uiSchema: uiSchema4(extraData),
      showIf: () =>
        extraData.documentType === ERegistrationDocumentType.Addendum,
    },
  ];
};
