import { ThemeProvider as ThemeProviderNative } from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import rentioTheme, {
  rentioThemeNative,
} from "@rentiohq/shared-frontend/dist/components/theme/theme.rentio";
import RequirementsProvider from "@rentiohq/shared-frontend/dist/providers/RequirementsProvider";
import {
  IAlert,
  registerAlertTrigger,
} from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import {
  getLocale,
  getLocalizedText,
  setLocale,
} from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { setStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EProduct } from "@rentiohq/shared/dist/config/app.config.types";
import logger from "@rentiohq/shared/dist/logger";
import consoleLogger from "@rentiohq/shared/dist/logger/loggers/logger.console";
import sentryLogger from "@rentiohq/shared/dist/logger/loggers/logger.sentry";
import { ErrorBoundary, Loading } from "@rentiohq/web-shared/dist/components";
import ModalProvider from "@rentiohq/web-shared/dist/components/Modal/components/ModalProvider";
import {
  ToastContainer,
  toast,
} from "@rentiohq/web-shared/dist/managers/Toast";
import { GlobalStyle, ThemeProvider, defaultTheme } from "@rentiohq/web-theme";
import * as Sentry from "@sentry/browser";
import { createBrowserHistory } from "history";
import merge from "lodash/merge";
import InvitesModal from "modals/InvitesModal";
import * as ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.minimal.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import { ConfirmProvider } from "./providers/ConfirmProvider";
import store from "./redux/store";
import { AppRouter } from "./router";
import * as serviceWorker from "./serviceWorker";

setLocale(navigator.language);
setStore(store);

// @ts-ignore
window.zESettings = {
  webWidget: {
    color: {
      theme: defaultTheme.colors.primary[800],
      launcherText: defaultTheme.colors.neutral0,
    },
    chat: {
      suppress: false,
    },
    helpCenter: {
      suppress: false,
    },
    contactForm: {
      suppress: false,
      attachments: true,
    },
  },
};

setTimeout(() => {
  // @ts-ignore
  window.zE?.("webWidget", "setLocale", getLocale());

  // @ts-ignore
  window.zE?.("webWidget", "hide");
}, 100);

const SENTRY_FEEDBACK_KEYS = {
  title: getLocalizedText("sentry.feedback_modal.title"),
  subtitle: getLocalizedText("sentry.feedback_modal.subtitle"),
  subtitle2: getLocalizedText("sentry.feedback_modal.subtitle_2"),
  labelName: getLocalizedText("sentry.feedback_modal.label_name"),
  labelEmail: getLocalizedText("sentry.feedback_modal.label_email"),
  labelComments: getLocalizedText("sentry.feedback_modal.label_comments"),
  labelSubmit: getLocalizedText("sentry.feedback_modal.label_submit"),
  labelClose: getLocalizedText("sentry.feedback_modal.label_close"),
  errorFormEntry: getLocalizedText("sentry.feedback_modal.error_form_entry"),
  errorGeneric: getLocalizedText("sentry.feedback_modal.error_generic"),
  successMessage: getLocalizedText("sentry.feedback_modal.success_message"),
};

export const history = createBrowserHistory();

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: CONFIG.SENTRY[EProduct.Dashboard],
    environment: CONFIG.ENVIRONMENT,
    release: CONFIG.SENTRY_RELEASE,
    beforeSend(event) {
      if (event.extra?.fatal === "true") {
        Sentry.showReportDialog({
          // TODO: Prefill user name & email
          // user: {
          //   name: "demo",
          //   email: "dries@rentio.be",
          // },
          ...SENTRY_FEEDBACK_KEYS,
          eventId: event.event_id,
        });
      }
      return event;
    },
  });
  logger.registerLogger(sentryLogger);

  console.log("Sentry release version: ", CONFIG.SENTRY_RELEASE);
}

if (process.env.NODE_ENV === "development") {
  logger.registerLogger(consoleLogger);
}

// @ts-ignore
window.pageDate = new Date();

const alertTrigger = ({ message = "", error, type, content }: IAlert) => {
  toast({
    variation: type,
    heading: error ? getMessageForError(error) : message,
    content,
  });
};
registerAlertTrigger(alertTrigger);

ReactDOM.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <PersistGate
        // @ts-ignore
        persistor={persistStore(store)}
        loading={<Loading />}
      >
        <ThemeProvider theme={merge({}, rentioTheme, defaultTheme)}>
          <ThemeProviderNative theme={rentioThemeNative}>
            <GlobalStyle />
            <ErrorBoundary>
              <ToastContainer />
              <ModalProvider>
                <RequirementsProvider>
                  <ConfirmProvider>
                    <AppRouter />
                    <InvitesModal />
                  </ConfirmProvider>
                </RequirementsProvider>
              </ModalProvider>
            </ErrorBoundary>
          </ThemeProviderNative>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  </BrowserRouter>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
