export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["Info"] = "info";
    EField["PropertyType"] = "propertyType";
    EField["Address"] = "address";
    EField["Name"] = "name";
    EField["Group"] = "group";
    EField["Owners"] = "owners";
    EField["BrokerContact"] = "brokerContact";
    EField["Roles"] = "roles";
})(EField || (EField = {}));
