import { REDUCER_KEY_INSURANCE } from "./insurance.reducer";
export var isFetching = function (state, insuranceId) {
    var _a;
    return ((_a = state[REDUCER_KEY_INSURANCE].insuranceDetail[insuranceId]) === null || _a === void 0 ? void 0 : _a.isFetching) ||
        false;
};
export var fetchError = function (state, insuranceId) { var _a; return (_a = state[REDUCER_KEY_INSURANCE].insuranceDetail[insuranceId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var insurance = function (state, insuranceId) {
    return state[REDUCER_KEY_INSURANCE].insurance[insuranceId];
};
export var isFetchingByContract = function (state, contractId) {
    var _a;
    return ((_a = state[REDUCER_KEY_INSURANCE].insuranceDetailByContract[contractId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false;
};
export var fetchErrorByContract = function (state, contractId) {
    var _a;
    return (_a = state[REDUCER_KEY_INSURANCE].insuranceDetailByContract[contractId]) === null || _a === void 0 ? void 0 : _a.fetchError;
};
export var insuranceByContract = function (state, contractId) {
    return Object.values(state[REDUCER_KEY_INSURANCE].insurance).find(function (x) { return (x === null || x === void 0 ? void 0 : x.contractId) === contractId; });
};
