import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as paymentRequestUtils from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import {
  EPaymentRequestStatus,
  EPayoutType,
  IPaymentRequest,
} from "@rentiohq/shared/dist/types/payment.types";
import {
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import {
  isSameMonth,
  startOfMonth,
  subMonths,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { Loading, TextStyle } from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { uniqBy } from "lodash";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { renderTooltipChecklist } from "../../../../utils/tooltip.utils";
import PropertyListItem from "../PropertyListItem";
import {
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";
import { ETooltipStatus, Tooltip } from "../StatusDot";
import * as constants from "./PropertyListItemFinancial.constants";
import * as S from "./PropertyListItemFinancial.styles";
import OlderNewerButton from "./components/OlderNewerButton";

interface IProps {
  property: IProperty;
  selectedParentId?: number;
  onSetParentId: (parentId?: number) => void;
}

const PropertyListItemFinancial = (props: IProps) => {
  const { property, onSetParentId } = props;

  // Redux
  const dispatch = useDispatch();

  const mostLeftDate = useSelector(
    (state: IRootStore) =>
      systemSelectors.getPreference<Date>(
        state,
        constants.PREFERENCE_KEY_PROPERTIES_FINANCIAL_TAB_DATE,
      ) || constants.START_DATE,
  );

  const filterDataUnpaid = {
    where: {
      requestPropertyId: property.id,
      status: { neq: EPaymentRequestStatus.Paid },
      payoutType: EPayoutType.Charge,
      // dueDateAt: {
      //   lte: constants.NOW,
      // },
    },
  };

  const identifierUnpaid = `payment-requests-${hash(filterDataUnpaid)}`;

  const filterDataPeriod = {
    where: {
      and: [
        {
          requestPropertyId: property.id,
          payoutType: EPayoutType.Charge,
          dueDateAt: {
            lte: mostLeftDate,
          },
        },
        {
          requestPropertyId: property.id,
          payoutType: EPayoutType.Charge,
          dueDateAt: {
            gte: startOfMonth(
              subMonths(mostLeftDate, constants.MONTHS_PER_VIEW),
            ),
          },
        },
      ],
    },
  };

  const identifierPeriod = `payment-requests-${hash(filterDataPeriod)}`;

  const unpaidPaymentRequests = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentRequestsByIdentifier(state, identifierUnpaid),
  );
  const isFetchingUnpaidPaymentRequests = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentRequestsByIdentifier(
      state,
      identifierUnpaid,
    ),
  );
  const unpaidPaymentRequestsFetchError = useSelector((state: IRootStore) =>
    paymentSelectors.paymentRequestsByIdentifierFetchError(
      state,
      identifierUnpaid,
    ),
  );

  const periodPaymentRequests = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentRequestsByIdentifier(state, identifierPeriod),
  );
  const isFetchingPeriodPaymentRequests = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentRequestsByIdentifier(
      state,
      identifierPeriod,
    ),
  );
  const periodPaymentRequestsFetchError = useSelector((state: IRootStore) =>
    paymentSelectors.paymentRequestsByIdentifierFetchError(
      state,
      identifierPeriod,
    ),
  );

  // Data
  const fetchUnpaid = () => {
    dispatch(
      paymentActions.getPaymentRequestsByIdentifier.actions.start({
        paymentRequestsIdentifier: identifierUnpaid,
        filterData: filterDataUnpaid,
        refetch: true,
      }),
    );
  };

  const fetchPeriod = () => {
    dispatch(
      paymentActions.getPaymentRequestsByIdentifier.actions.start({
        paymentRequestsIdentifier: identifierPeriod,
        filterData: filterDataPeriod,
        refetch: true,
      }),
    );
  };

  // Lifecycle methods
  React.useEffect(() => {
    if (isFetchingUnpaidPaymentRequests) {
      return;
    }

    fetchUnpaid();
  }, [property]);

  React.useEffect(() => {
    if (isFetchingPeriodPaymentRequests) {
      return;
    }

    fetchPeriod();
  }, [property, mostLeftDate]);

  const payers = uniqBy(
    [...(unpaidPaymentRequests || []), ...(periodPaymentRequests || [])],
    (pr: IPaymentRequest) => pr.payerAccount.id,
  ).map((pr: IPaymentRequest) => pr.payerAccount);

  const unpaidPaymentRequestsForPayer = (accountId: number) => {
    if (!unpaidPaymentRequests) {
      return [];
    }

    return unpaidPaymentRequests.filter(
      paymentRequest => accountId === paymentRequest.payerAccount.id,
    );
  };

  const paymentRequestsAmount = (paymentRequests: IPaymentRequest[]) =>
    paymentRequests
      .map(pr => pr.originalAmount)
      .reduce((a, b) => {
        return a + b;
      }, 0);

  const unpaidAmountForPayer = (accountId: number) =>
    paymentRequestsAmount(unpaidPaymentRequestsForPayer(accountId));

  const periodPaymentRequestsForPayer = (accountId: number) => {
    if (!periodPaymentRequests) {
      return [];
    }

    return periodPaymentRequests.filter(
      paymentRequest => accountId === paymentRequest.payerAccount.id,
    );
  };

  const paymentRequestsPerMonthForPayer = (date: Date, accountId: number) => {
    return periodPaymentRequestsForPayer(accountId).filter(paymentRequest => {
      if (!isSameMonth(date, paymentRequest.dueDateAt)) {
        return false;
      }

      return true;
    });
  };

  const unpaidPaymentRequestsPerMonthForPayer = (
    date: Date,
    accountId: number,
  ) =>
    paymentRequestsPerMonthForPayer(date, accountId).filter(
      paymentRequest => paymentRequest.status !== EPaymentRequestStatus.Paid,
    );

  const unpaidAmountPerMonthForPayer = (date: Date, accountId: number) =>
    paymentRequestsAmount(
      unpaidPaymentRequestsPerMonthForPayer(date, accountId),
    );

  const paidPaymentRequestsPerMonthForPayer = (date: Date, accountId: number) =>
    paymentRequestsPerMonthForPayer(date, accountId).filter(
      paymentRequest => paymentRequest.status === EPaymentRequestStatus.Paid,
    );

  const paidAmountPerMonthForPayer = (date: Date, accountId: number) =>
    paymentRequestsAmount(paidPaymentRequestsPerMonthForPayer(date, accountId));

  // Render
  if (property.typeId === EPropertyTypeId.Group) {
    return (
      <PropertyListItem property={property} onSetParentId={onSetParentId} />
    );
  }

  const renderLoading = () => {
    return (
      <S.LoadingWrap>
        <S.LoadingInnerWrap>
          <Loading asDots={true} />
        </S.LoadingInnerWrap>
      </S.LoadingWrap>
    );
  };

  const renderPayer = (params: {
    payer?: IAccount;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    const { payer, isFirst = true, isLast = true } = params;

    if (!payer) {
      return (
        <S.PayerRow
          style={{
            paddingTop: isFirst ? 0 : 4,
            paddingBottom: isLast ? 0 : 4,
          }}
        >
          <PropertyListItemItem
            style={{ width: constants.WIDTH_PAYERS, textAlign: "left" }}
          >
            <TextStyle variation={"subdued"}>{"-"}</TextStyle>
          </PropertyListItemItem>

          <PropertyListItemItem
            style={{ width: constants.WIDTH_PAYERS_TOTAL, textAlign: "center" }}
          >
            <TextStyle variation={"subdued"}>{"-"}</TextStyle>
          </PropertyListItemItem>
        </S.PayerRow>
      );
    }

    const unpaidPaymentRequests = payer
      ? unpaidPaymentRequestsForPayer(payer.id)
      : [];
    const unpaidAmount = payer ? unpaidAmountForPayer(payer.id) : 0;

    let status = ETooltipStatus.Success;
    if (unpaidAmount > 0) {
      status = ETooltipStatus.Error;
    }

    return (
      <S.PayerRow
        style={{
          paddingTop: isFirst ? 0 : 4,
          paddingBottom: isLast ? 0 : 4,
        }}
      >
        <PropertyListItemItem
          style={{ width: constants.WIDTH_PAYERS, textAlign: "left" }}
        >
          <TextStyle variation={"default"}>{getName(payer)}</TextStyle>
        </PropertyListItemItem>

        <PropertyListItemItem
          style={{ width: constants.WIDTH_PAYERS_TOTAL, textAlign: "center" }}
        >
          <Tooltip
            tooltipContent={renderPaymentRequestsTooltip(unpaidPaymentRequests)}
            status={status}
          >
            <TextStyle variation={"code"}>
              {unpaidAmount === 0 ? (
                <TextStyle variation={"positive"}>
                  {formatCurrency(0)}
                </TextStyle>
              ) : (
                <TextStyle variation={["negative", "strong"]}>
                  {formatCurrency(unpaidAmount)}
                </TextStyle>
              )}
            </TextStyle>
          </Tooltip>
        </PropertyListItemItem>

        {/* <PropertyListItemItem style={{ width: 0, textAlign: "center" }}>
          <TextStyle variation={"code"}>
            <TextStyle variation={"positive"}>&nbsp;</TextStyle>
            <Spacer weight={ESpacerWeight.W04} />
            <TextStyle variation={["negative", "strong"]}>{"x"}</TextStyle>
          </TextStyle>
        </PropertyListItemItem> */}
      </S.PayerRow>
    );
  };

  const renderPayers = () => {
    const isFirstMonthsView = isSameMonth(mostLeftDate, constants.START_DATE);

    // If initial dates: wait for payers & all months to be fetched
    // If older dates: wait only for payers to be fetched
    let showLoading = true;
    if (isFirstMonthsView) {
      showLoading =
        (isFetchingUnpaidPaymentRequests || isFetchingPeriodPaymentRequests) &&
        (!unpaidPaymentRequests || !periodPaymentRequests);
    } else {
      showLoading = isFetchingUnpaidPaymentRequests && !unpaidPaymentRequests;
    }

    if (showLoading) {
      return renderLoading();
    }

    const showError =
      periodPaymentRequestsFetchError || unpaidPaymentRequestsFetchError;
    if (showError) {
      return null;
    }

    if (payers.length === 0) {
      return renderPayer({ payer: undefined, isFirst: true, isLast: true });
    }

    return payers.map((payer, index) => {
      const isFirst = index === 0;
      const isLast = index === 2 - 1;

      return renderPayer({ payer, isFirst, isLast });
    });
  };

  const renderPaymentRequestsTooltip = (
    paymentRequests?: IPaymentRequest[],
  ) => {
    if (!paymentRequests || paymentRequests.length === 0) {
      return getLocalizedText(
        "properties.overview.open_payment_requests",
        {},
        0,
      );
    }

    return renderTooltipChecklist(
      paymentRequests.map(paymentRequest => {
        const title = paymentRequestUtils.getTitle(paymentRequest);
        const amount = formatCurrency(paymentRequest.originalAmount);

        return {
          success: !!paymentRequest.paidAt,
          label: `${title} ${amount}`,
        };
      }),
    );
  };

  const renderMonth = (params: {
    payer?: IAccount;
    isFirst?: boolean;
    isLast?: boolean;
  }) => {
    const { payer, isFirst = true, isLast = true } = params;

    return (
      <S.PayerRow
        style={{
          paddingTop: isFirst ? 0 : 4,
          paddingBottom: isLast ? 0 : 4,
        }}
      >
        {Array(constants.MONTHS_PER_VIEW)
          .fill(0)
          .map((month, index) => {
            if (!payer) {
              return (
                <PropertyListItemItem
                  key={month}
                  style={{ flex: 1, textAlign: "center" }}
                >
                  <TextStyle variation={"subdued"}>{"-"}</TextStyle>
                </PropertyListItemItem>
              );
            }

            const currentDate = subMonths(mostLeftDate, index);

            const unpaidAmount = unpaidAmountPerMonthForPayer(
              currentDate,
              payer.id,
            );

            const paidAmount = paidAmountPerMonthForPayer(
              currentDate,
              payer.id,
            );

            const isEmpty = unpaidAmount + paidAmount === 0;
            if (isEmpty) {
              return (
                <PropertyListItemItem style={{ flex: 1, textAlign: "center" }}>
                  <TextStyle variation={"subdued"}>{"-"}</TextStyle>
                </PropertyListItemItem>
              );
            }

            const unpaidPaymentRequests = unpaidPaymentRequestsPerMonthForPayer(
              currentDate,
              payer.id,
            );

            const paidPaymentRequests = paidPaymentRequestsPerMonthForPayer(
              currentDate,
              payer.id,
            );

            return (
              <PropertyListItemItem style={{ flex: 1, textAlign: "center" }}>
                {unpaidAmount > 0 && (
                  <Tooltip
                    tooltipContent={renderPaymentRequestsTooltip(
                      unpaidPaymentRequests,
                    )}
                    status={ETooltipStatus.Error}
                  >
                    <TextStyle variation={["code", "negative", "strong"]}>
                      {formatCurrency(unpaidAmount)}
                    </TextStyle>
                  </Tooltip>
                )}

                {unpaidAmount > 0 && paidAmount > 0 && (
                  <Spacer weight={ESpacerWeight.W02} />
                )}

                {paidAmount > 0 && (
                  <Tooltip
                    tooltipContent={renderPaymentRequestsTooltip(
                      paidPaymentRequests,
                    )}
                    status={ETooltipStatus.Success}
                  >
                    <TextStyle variation={["code", "positive"]}>
                      {formatCurrency(paidAmount)}
                    </TextStyle>
                  </Tooltip>
                )}
              </PropertyListItemItem>
            );
          })}
      </S.PayerRow>
    );
  };

  const renderMonths = () => {
    const showLoading =
      (isFetchingUnpaidPaymentRequests || isFetchingPeriodPaymentRequests) &&
      (!unpaidPaymentRequests || !periodPaymentRequests);

    if (showLoading) {
      return renderLoading();
    }

    const fetchError =
      periodPaymentRequestsFetchError || unpaidPaymentRequestsFetchError;
    if (fetchError) {
      return (
        <S.ErrorWrap>
          <TextStyle variation={"subdued"}>
            {getMessageForError(fetchError)}
          </TextStyle>

          <Spacer weight={ESpacerWeight.W04} />

          <Button
            appearance="link"
            onClick={() => {
              fetchUnpaid();
              fetchPeriod();
            }}
          >
            {getLocalizedText("try.again")}
          </Button>
        </S.ErrorWrap>
      );
    }

    if (payers.length === 0) {
      return renderMonth({ payer: undefined, isFirst: true, isLast: true });
    }

    return payers.map((payer, index) => {
      const isFirst = index === 0;
      const isLast = index === 2 - 1;

      return renderMonth({ payer, isFirst, isLast });
    });
  };

  return (
    <PropertyListItem
      property={property}
      link={`/properties/${property.id}/payments`}
      onSetParentId={onSetParentId}
    >
      <PropertyListItemSpacer />

      <PropertyListItemItem
        style={{
          width: constants.WIDTH_PAYERS + constants.WIDTH_PAYERS_TOTAL,
        }}
      >
        {renderPayers()}
      </PropertyListItemItem>

      <PropertyListItemSpacer />

      <S.MonthsWrap>
        <PropertyListItemItem style={{ width: constants.WIDTH_NEXT_PREV }}>
          <OlderNewerButton olderNewer={"newer"} />
        </PropertyListItemItem>

        <PropertyListItemItem style={{ flex: 1 }}>
          {renderMonths()}
        </PropertyListItemItem>

        <PropertyListItemItem style={{ width: constants.WIDTH_NEXT_PREV }}>
          <OlderNewerButton olderNewer={"older"} />
        </PropertyListItemItem>
      </S.MonthsWrap>
    </PropertyListItem>
  );
};

export default PropertyListItemFinancial;
