export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["Iban"] = "iban";
    EField["OwnerName"] = "ownerName";
    EField["IsRevenue"] = "isRevenueIban";
    // cspell:ignore thirdparty
    EField["IsThirdParty"] = "isThirdpartyIban";
    EField["IsInvoice"] = "isInvoiceIban";
})(EField || (EField = {}));
