import { REDUCER_KEY_FEATURE_FLAG, } from "./featureFlag.reducer";
export var isFetchingFlags = function (state) {
    return state.featureFlag.isFetching;
};
export var fetchError = function (state) {
    return state.featureFlag.fetchError;
};
export var getFlag = function (state, key, defaultValue) {
    var _a;
    var value = (_a = state.featureFlag.flags) === null || _a === void 0 ? void 0 : _a[key];
    if (value !== undefined) {
        return value;
    }
    return defaultValue;
};
