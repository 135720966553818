var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as S from "./SegmentedControl.styled";
export var SegmentedControl = function (props) {
    var name = props.name, selected = props.selected, options = props.options, error = props.error, onChange = props.onChange, disabled = props.disabled, _a = props.size, size = _a === void 0 ? "regular" : _a;
    return (_jsx(S.Wrapper, __assign({ hasError: !!error }, { children: options.map(function (_a) {
            var id = _a.id, value = _a.value, renderLeadingIcon = _a.renderLeadingIcon;
            var isSelected = selected === id;
            return (_jsxs(S.Button, __assign({ disabled: disabled, onClick: onChange.bind(null, id), selected: isSelected, size: size }, { children: [renderLeadingIcon === null || renderLeadingIcon === void 0 ? void 0 : renderLeadingIcon(), value] }), "".concat(name, "-").concat(id)));
        }) })));
};
