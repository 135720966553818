var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ButtonPrimary from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import React from "react";
import { useNavigate } from "react-router";
import { DisplayText, Icon, TextStyle } from "../../../../../../components";
import { EDisplayType, } from "../../Editor.types";
import { DisplayTypeContext } from "../../contexts/DisplayType.context";
import { DocumentConfigContext } from "../../contexts/DocumentConfig.context";
import { DocumentIdsContext } from "../../contexts/DocumentIds.context";
import { EditorStateContext } from "../../contexts/EditorState.context";
import { ExitPromptContext } from "../../contexts/ExitPrompt.context";
import { FieldsContext } from "../../contexts/Fields.context";
import { VariablesDataContext } from "../../contexts/VariablesData.context";
import VariablesForm from "../VariablesForm";
import * as S from "./EditorSidebar.styles";
export var EditorSidebar = function (props) {
    var title = props.title, subtitle = props.subtitle, onPressSave = props.onPressSave, onPressUpdate = props.onPressUpdate;
    // Router
    var navigate = useNavigate();
    // Context
    var _a = React.useContext(DisplayTypeContext), displayType = _a.displayType, setDisplayType = _a.setDisplayType, isTemplateDocumentEditor = _a.isTemplateDocumentEditor;
    var fields = React.useContext(FieldsContext).fields;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var documentConfig = React.useContext(DocumentConfigContext).documentConfig;
    var editorState = React.useContext(EditorStateContext).editorState;
    var documentIds = React.useContext(DocumentIdsContext).documentIds;
    var setExitPromptEnabled = React.useContext(ExitPromptContext).setExitPromptEnabled;
    // Render
    var renderTemplateDocumentEditorInfo = function () {
        return (_jsxs(S.TemplateDocumentEditorInfoWrap, { children: [_jsx(DisplayText, __assign({ space: "none", size: "large" }, { children: getLocalizedText("editor.template_document.sidebar_blocked.title") })), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: getLocalizedText("editor.template_document.sidebar_blocked.info") })), _jsx(Spacer, { weight: ESpacerWeight.W16 }), _jsx(ButtonPrimary, { title: getLocalizedText("editor.template_document.sidebar_blocked.cta.finish_editing_template"), onPress: function () {
                        setDisplayType(EDisplayType.TemplateDocument);
                    } })] }));
    };
    return (_jsx(_Fragment, { children: _jsx(S.Wrap, { children: _jsxs(S.InnerWrap, { children: [_jsxs(S.TitleWrap, { children: [_jsx("button", __assign({ onClick: function () {
                                    confirm({
                                        type: "warning",
                                        modalProps: {
                                            hasDismiss: true,
                                            shouldCloseOnOverlayClick: true,
                                        },
                                        title: getLocalizedText("editor.confirm_exit.modal.title"),
                                        primaryActions: [
                                            {
                                                title: getLocalizedText("system.save_and_back"),
                                                onPress: function () {
                                                    if (!editorState || (!onPressSave && !onPressUpdate)) {
                                                        return;
                                                    }
                                                    setExitPromptEnabled(false);
                                                    onPressSave === null || onPressSave === void 0 ? void 0 : onPressSave({
                                                        withGeneratePdf: false,
                                                        editorState: editorState,
                                                        fields: fields,
                                                        documentConfig: documentConfig,
                                                        variablesData: variablesData,
                                                        documentIds: documentIds,
                                                        goBackOnSuccess: true,
                                                    });
                                                    onPressUpdate === null || onPressUpdate === void 0 ? void 0 : onPressUpdate({
                                                        editorState: editorState,
                                                        fields: fields,
                                                        documentIds: documentIds,
                                                        goBackOnSuccess: true,
                                                    });
                                                },
                                            },
                                        ],
                                        secondaryAction: {
                                            title: getLocalizedText("system.back"),
                                            onPress: function () {
                                                setExitPromptEnabled(false);
                                                navigate(-1);
                                            },
                                        },
                                    });
                                } }, { children: _jsx(Icon, { source: "chevronLeft", size: "large", color: "primary" }) })), _jsxs("div", { children: [_jsx(Spacer, { weight: ESpacerWeight.W16 }), _jsx(DisplayText, __assign({ space: "none", size: "medium" }, { children: title || "Template" })), _jsx(TextStyle, __assign({ variation: "subdued" }, { children: subtitle ||
                                            getLocalizedText("editor.display_type.".concat(stringToSnakeCase(displayType))) }))] })] }), isTemplateDocumentEditor ? (renderTemplateDocumentEditorInfo()) : (_jsx(VariablesForm, {}))] }) }) }));
};
export default EditorSidebar;
