var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import api from "../../utils/api/api.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./contract.constants";
export var FETCH_LIMIT = 20;
export var getPaged = (_a = generateApi(GENERATOR_PARAMS), _a.getPaged), getDetail = _a.getDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, update = _a.update, create = _a.create, remove = _a.remove, removeMembers = _a.removeMembers, addMembers = _a.addMembers;
export var index = function (params) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(params.id, "/index"), {
        isV2: true,
        data: {
            newPrice: params.newPrice,
            retroactivityMonths: params.retroactivityMonths,
            forceFullIndexation: params.forceFullIndexation,
        },
    });
};
export var indexAskOwner = function (params) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(params.id, "/index-ask-owner"), { isV2: true });
};
export var indexSkip = function (params) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(params.id, "/index-skip"), { isV2: true });
};
export var renew = function (params) {
    var id = params.id, resetParams = __rest(params, ["id"]);
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(id, "/renew"), {
        isV2: true,
        data: resetParams,
    });
};
export var activateLegal = function (params) {
    var contractId = params.contractId;
    return api.post("".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(contractId, "/activate-legal"), { isV2: true });
};
export var cancel = function (contractId, params) {
    var data = params;
    return api.patch("".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(contractId, "/cancel"), {
        isV2: true,
        data: data,
    });
};
export var deleteCancellation = function (contractId, params) {
    var data = params;
    return api.patch("".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(contractId, "/delete-cancellation"), {
        isV2: true,
        data: data,
    });
};
export var getIndexInfoSimulation = function (contractId, params) {
    var forceFullIndexation = params.forceFullIndexation, newPrice = params.newPrice, retroactivityMonths = params.retroactivityMonths;
    return api.get(appendQueryParams({
        path: "/contracts/".concat(contractId, "/index-simulation-info"),
        queryParams: {
            forceFullIndexation: forceFullIndexation,
            newPrice: newPrice,
            retroactivityMonths: retroactivityMonths,
        },
    }), {
        isV2: true,
    });
};
