var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ERecurrenceType, ETaskMemberType, ETaskPartner, ETaskReminder, } from "@rentiohq/shared/dist/types/task.types";
import { setHours, startOfDay, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { taskModule, } from "../../reduxV2/task";
import { EField } from "./schema.createTask.types";
export var isPerformingSubmitActionSelector = function (taskId) { return function (state) {
    if (taskId) {
        return taskModule.selectors.isUpdating(state, taskId);
    }
    return taskModule.selectors.isCreating(state);
}; };
export var performingSubmitActionResultSelector = function (taskId) { return function (state) {
    if (taskId) {
        return taskModule.selectors.getDetail(state, taskId);
    }
    return taskModule.selectors.getCreatedDetail(state);
}; };
export var performingSubmitActionErrorSelector = function (taskId) { return function (state) {
    if (taskId) {
        return taskModule.selectors.updateError(state, taskId);
    }
    return taskModule.selectors.createError(state);
}; };
var findCategoryField = function (formData) {
    var found = Object.entries(formData).find(function (_a) {
        var key = _a[0], value = _a[1];
        return key.includes(EField.Category) && value !== undefined;
    });
    if (found) {
        var result = found[1];
        return result;
    }
};
var getCommonTaskData = function (formData, isKeypoint) {
    return {
        title: formData[EField.Title],
        // TODO: Change to note
        description: formData[EField.Description] || null,
        propertyId: formData[EField.PropertySelect] || null,
        executeDate: formData[EField.ExecuteDate]
            ? setHours(new Date(formData[EField.ExecuteDate]), new Date().getHours())
            : null,
        dueDate: formData[EField.DueDate]
            ? setHours(startOfDay(new Date(formData[EField.DueDate])), new Date().getHours())
            : null,
        reminderOption: formData[EField.ReminderOption] &&
            formData[EField.ReminderType] === ETaskReminder.HasDate
            ? formData[EField.ReminderOption]
            : ETaskReminder.None,
        nextReminderDate: formData[EField.ReminderOption] &&
            formData[EField.ReminderType] === ETaskReminder.HasDate
            ? formData[EField.NextReminderDate]
            : null,
        recurrenceType: formData[EField.RecurrenceSelection] === ERecurrenceType.Once
            ? ERecurrenceType.Once
            : formData[EField.RecurrenceType],
        recurrenceInfo: formData[EField.RecurrenceSelection] !== ERecurrenceType.Once
            ? {
                recurrenceEndDate: formData[EField.RecurrenceEndDate]
                    ? formData[EField.RecurrenceEndDate]
                    : null,
            }
            : undefined,
        incidentDate: formData[EField.IncidentDate],
        payerAccountId: formData[EField.Payer]
            ? formData[EField.Payer][0]
            : undefined,
        repairAccountId: formData[EField.ContactPerson]
            ? formData[EField.ContactPerson][0]
            : undefined,
        damageType: formData[EField.DamageType],
        taskPartner: isKeypoint ? ETaskPartner.Keypoint : ETaskPartner.None,
        category: findCategoryField(formData),
        location: formData[EField.Location],
        type: formData[EField.Type],
    };
};
export var submitCreateActionCreator = function (params) {
    return function (formData) {
        var isKeypoint = params.isKeypoint, employees = params.employees, broker = params.broker;
        var taskBase = getCommonTaskData(formData, isKeypoint);
        // We need to filter out the employees from the selected account ids again (executors).
        var selectedExecutors = formData[EField.Executors] || [];
        var employeeExecutors = selectedExecutors.reduce(function (employeeExecutors, executor) {
            var foundEmployee = employees === null || employees === void 0 ? void 0 : employees.find(function (employee) { return employee.id === executor; });
            if (foundEmployee) {
                return __spreadArray(__spreadArray([], employeeExecutors, true), [foundEmployee], false);
            }
            return employeeExecutors;
        }, []);
        // We need to filter out the employees from the selected account ids again (followers).
        var selectedFollowers = formData[EField.Followers] || [];
        var employeeFollowers = selectedFollowers.reduce(function (employeeFollowers, follower) {
            var foundEmployee = employees === null || employees === void 0 ? void 0 : employees.find(function (employee) { return employee.id === follower; });
            if (foundEmployee) {
                return __spreadArray(__spreadArray([], employeeFollowers, true), [foundEmployee], false);
            }
            return employeeFollowers;
        }, []);
        // Remove the employees from regular executors and followers
        var executors = selectedExecutors.filter(function (ex) { return !employeeExecutors.map(function (ee) { return ee.id; }).includes(ex); });
        var followers = selectedFollowers.filter(function (ex) { return !employeeFollowers.map(function (ef) { return ef.id; }).includes(ex); });
        var members = __spreadArray(__spreadArray([], (executors || []).map(function (accountId) { return ({
            roles: [ETaskMemberType.Executor],
            accountId: accountId,
        }); }), true), (followers || []).map(function (accountId) { return ({
            roles: [ETaskMemberType.Follower],
            accountId: accountId,
        }); }), true);
        if (broker && employeeExecutors) {
            employeeExecutors.forEach(function (_a) {
                var id = _a.id;
                members.push({
                    roles: [ETaskMemberType.Executor],
                    accountId: broker.accountId,
                    employeeAccountId: id,
                });
            });
            employeeFollowers.forEach(function (_a) {
                var id = _a.id;
                members.push({
                    roles: [ETaskMemberType.Follower],
                    accountId: broker.accountId,
                    employeeAccountId: id,
                });
            });
        }
        var task = __assign(__assign({}, taskBase), { members: members });
        return taskModule.actions.createStart.getAction({ data: task });
    };
};
export var submitUpdateActionCreator = function (params) { return function (formData) {
    var taskId = params.taskId, isKeypoint = params.isKeypoint;
    var taskBase = getCommonTaskData(formData, isKeypoint);
    return taskModule.actions.updateStart.getAction({
        id: taskId,
        data: taskBase,
    });
}; };
