var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import logger from "@rentiohq/shared/dist/logger";
import { call, put, takeLatest } from "redux-saga/effects";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateFailureText, getGeneralCreateSuccessText, getGeneralRemoveFailureText, getGeneralRemoveSuccessText, getGeneralUpdateFailureText, getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import * as subscriptionActions from "./subscription.actions";
import * as subscriptionApi from "./subscription.api";
function getSubscriptionStartFlow(_a) {
    var subscriptionId, subscription, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                subscriptionId = payload.subscriptionId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(subscriptionApi.getSubscription, subscriptionId)];
            case 2:
                subscription = (_b.sent()).data;
                return [4 /*yield*/, put(subscriptionActions.getSubscription.actions.success({
                        subscriptionId: subscriptionId,
                        subscription: subscription,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(subscriptionActions.getSubscription.actions.failure({
                        subscriptionId: subscriptionId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function createSubscriptionStartFlow(_a) {
    var createdSubscription, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(subscriptionApi.createSubscription, payload)];
            case 1:
                createdSubscription = (_b.sent()).data;
                return [4 /*yield*/, put(subscriptionActions.createSubscription.actions.success(createdSubscription))];
            case 2:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("subscription"),
                });
                return [3 /*break*/, 5];
            case 3:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(subscriptionActions.createSubscription.actions.failure({ error: error }))];
            case 4:
                _b.sent();
                showAlert({
                    type: "error",
                    message: getGeneralCreateFailureText("subscription"),
                });
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function updateSubscriptionStartFlow(_a) {
    var subscriptionId, subscription, updatedSubscription, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                subscriptionId = payload.subscriptionId, subscription = payload.subscription;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(subscriptionApi.updateSubscription, subscriptionId, subscription)];
            case 2:
                updatedSubscription = (_b.sent()).data;
                return [4 /*yield*/, put(subscriptionActions.updateSubscription.actions.success({
                        subscriptionId: subscriptionId,
                        subscription: updatedSubscription,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("subscription"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(subscriptionActions.updateSubscription.actions.failure({
                        subscriptionId: subscriptionId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    message: getGeneralUpdateFailureText("subscription"),
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function deleteSubscriptionStartFlow(_a) {
    var subscriptionId, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                subscriptionId = payload.subscriptionId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(subscriptionApi.deleteSubscription, subscriptionId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(subscriptionActions.deleteSubscription.actions.success({
                        subscriptionId: subscriptionId,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralRemoveSuccessText("subscription"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(subscriptionActions.deleteSubscription.actions.failure({
                        subscriptionId: subscriptionId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    message: getGeneralRemoveFailureText("subscription"),
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function submitSubscriptionStartFlow(_a) {
    var subscriptionId, subscription, unknownError_5, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                subscriptionId = payload.subscriptionId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(subscriptionApi.submitSubscription, subscriptionId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(subscriptionApi.getSubscription, subscriptionId)];
            case 3:
                subscription = (_b.sent()).data;
                return [4 /*yield*/, put(subscriptionActions.submitSubscription.actions.success({
                        subscriptionId: subscriptionId,
                        subscription: subscription,
                    }))];
            case 4:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("subscriptions.submit.toast.heading"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(subscriptionActions.submitSubscription.actions.failure({
                        subscriptionId: subscriptionId,
                        error: error,
                    }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    message: getLocalizedText("subscriptions.submit.error.toast.heading"),
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function markSubscriptionAsPaidStartFlow(_a) {
    var subscriptionId, subscription, unknownError_6, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                subscriptionId = payload.subscriptionId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(subscriptionApi.markSubscriptionAsPaid, subscriptionId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(subscriptionApi.getSubscription, subscriptionId)];
            case 3:
                subscription = (_b.sent()).data;
                return [4 /*yield*/, put(subscriptionActions.markSubscriptionAsPaid.actions.success({
                        subscriptionId: subscriptionId,
                        subscription: subscription,
                    }))];
            case 4:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("subscriptions.mark_paid.toast.heading"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_6 = _b.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(subscriptionActions.markSubscriptionAsPaid.actions.failure({
                        subscriptionId: subscriptionId,
                        error: error,
                    }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    message: getLocalizedText("subscriptions.mark_paid.error.toast.heading"),
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getSubscriptionsPagedStartFlow(_a) {
    var identifier, page, _b, limit, filterData, skip, subscriptions, unknownError_7, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                identifier = payload.identifier, page = payload.page, _b = payload.limit, limit = _b === void 0 ? subscriptionApi.FETCH_LIMIT : _b, filterData = payload.filterData;
                skip = (page - 1) * limit;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(subscriptionApi.getSubscriptions, skip, filterData, limit)];
            case 2:
                subscriptions = (_c.sent()).data;
                return [4 /*yield*/, put(subscriptionActions.getSubscriptionsPaged.actions.success({
                        identifier: identifier,
                        subscriptions: subscriptions,
                        page: page,
                    }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_7 = _c.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                return [4 /*yield*/, put(subscriptionActions.getSubscriptionsPaged.actions.failure({
                        identifier: identifier,
                        page: page,
                        error: error,
                    }))];
            case 5:
                _c.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(subscriptionActions.getSubscription.types.START, getSubscriptionStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(subscriptionActions.createSubscription.types.START, createSubscriptionStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(subscriptionActions.updateSubscription.types.START, updateSubscriptionStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(subscriptionActions.deleteSubscription.types.START, deleteSubscriptionStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(subscriptionActions.submitSubscription.types.START, submitSubscriptionStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(subscriptionActions.markSubscriptionAsPaid.types.START, markSubscriptionAsPaidStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(subscriptionActions.getSubscriptionsPaged.types.START, getSubscriptionsPagedStartFlow)];
            case 7:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default saga;
