var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { transparentize } from "polished";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 48px;\n  min-height: 48px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  transition: 0.2s;\n  color: ", ";\n  opacity: ", ";\n  background-color: ", ";\n  &:hover {\n    background-color: rgb(220, 220, 220);\n  }\n"], ["\n  min-width: 48px;\n  min-height: 48px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  transition: 0.2s;\n  color: ", ";\n  opacity: ", ";\n  background-color: ", ";\n  &:hover {\n    background-color: rgb(220, 220, 220);\n  }\n"])), function (props) { return (props.isActive ? props.theme.colors.global5 : "black"); }, function (props) { return (props.isDisabled ? 0.2 : 1); }, function (props) {
    if (props.isActive) {
        return transparentize(0.92, props.theme.colors.global5);
    }
    return "transparent";
});
export var Title = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 15px;\n  line-height: 0;\n"], ["\n  font-size: 15px;\n  line-height: 0;\n"])));
export var DropdownIconWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 12px;\n"], ["\n  margin-right: 12px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
