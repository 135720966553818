var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import logger from "@rentiohq/shared/dist/logger";
import { EDocumentCategoryLabels, } from "@rentiohq/shared/dist/types/document.types";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as documentApi from "../../reduxV2/documents/document.api";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateFailureText, getGeneralCreateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { uploadDocumentFailed, uploadDocumentSucceeded, } from "../documents/document.actions";
import { generateSaga } from "../generators/generator.saga";
import { activateLegalFailed, activateLegalStart, activateLegalSucceeded, cancelFailed, cancelStart, cancelSucceeded, deleteCancellationStart, deleteCancellationSucceeded, getCountStart, indexAskOwnerFailed, indexAskOwnerStart, indexAskOwnerSucceeded, indexSkipFailed, indexSkipStart, indexSkipSucceeded, indexStart, indexSucceeded, renewFailed, renewStart, renewSucceeded, } from "./contract.actions";
import { activateLegal, cancel, deleteCancellation, index, indexAskOwner, indexSkip, renew, } from "./contract.api";
import { GENERATOR_PARAMS } from "./contract.constants";
import * as contractTypes from "./contract.types";
function uploadNewDocument(params) {
    var file, contractId, category, presignedUrl, metaData, document_1, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                file = params.file, contractId = params.contractId, category = params.category;
                return [4 /*yield*/, call(documentApi.uploadDocumentToS3, file)];
            case 1:
                presignedUrl = _a.sent();
                metaData = {
                    filename: file.name,
                    contractId: contractId,
                    categoryId: category.id,
                };
                return [4 /*yield*/, call(documentApi.uploadDocument, presignedUrl, metaData)];
            case 2:
                document_1 = (_a.sent()).data;
                if (!document_1) return [3 /*break*/, 4];
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("document"),
                });
                return [4 /*yield*/, put(uploadDocumentSucceeded.getAction({
                        documentId: document_1.id,
                    }))];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/, document_1];
            case 5:
                error_1 = _a.sent();
                showAlert({
                    type: "error",
                    message: getGeneralCreateFailureText("document"),
                });
                return [4 /*yield*/, put(uploadDocumentFailed.getAction({
                        error: error_1,
                    }))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function refreshCounts() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(getCountStart.getAction({
                    query: contractTypes.ContractFilters.contractsIndexable,
                }))];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(getCountStart.getAction({
                        query: contractTypes.ContractFilters.contractsIndexOwnerAsked,
                    }))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(getCountStart.getAction({
                        query: contractTypes.ContractFilters.contractsIndexed,
                    }))];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function indexStartFlow(action) {
    var _a, id, newPrice, retroactivityMonths, forceFullIndexation, onSuccess, onFailure, response, unknownError_1, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, newPrice = _a.newPrice, retroactivityMonths = _a.retroactivityMonths, forceFullIndexation = _a.forceFullIndexation, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(index, {
                        id: id,
                        newPrice: newPrice,
                        retroactivityMonths: retroactivityMonths,
                        forceFullIndexation: forceFullIndexation,
                    })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, put(indexSucceeded.getAction({
                        id: id,
                        result: response.data,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("contract.index.index_now.success.heading"),
                    content: getLocalizedText("contract.index.index_now.success.content"),
                });
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(indexAskOwnerFailed.getAction({ id: id, error: error }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function indexAskOwnerStartFlow(action) {
    var _a, id, onSuccess, onFailure, response, unknownError_2, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(indexAskOwner, {
                        id: id,
                    })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, put(indexAskOwnerSucceeded.getAction({
                        id: id,
                        result: response.data,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, refreshCounts()];
            case 4:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 7];
            case 5:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(indexAskOwnerFailed.getAction({ id: id, error: error }))];
            case 6:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function indexSkipStartFlow(action) {
    var _a, id, onSuccess, onFailure, response, unknownError_3, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(indexSkip, {
                        id: id,
                    })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, put(indexSkipSucceeded.getAction({
                        id: id,
                        result: response.data,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, refreshCounts()];
            case 4:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 7];
            case 5:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(indexSkipFailed.getAction({ id: id, error: error }))];
            case 6:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function renewStartFlow(action) {
    var _a, id, contractLengthType, stopDate, tacitRenewal, onSuccess, response, unknownError_4, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, contractLengthType = _a.contractLengthType, stopDate = _a.stopDate, tacitRenewal = _a.tacitRenewal, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(renew, {
                        id: id,
                        contractLengthType: contractLengthType,
                        stopDate: stopDate,
                        tacitRenewal: tacitRenewal,
                    })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, put(renewSucceeded.getAction({
                        id: id,
                        result: response.data,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [4 /*yield*/, refreshCounts()];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(renewFailed.getAction({ id: id, error: error }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function activateLegalStartFlow(action) {
    var _a, id, onFailure, onSuccess, response, unknownError_5, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, onFailure = _a.onFailure, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(activateLegal, {
                        contractId: id,
                    })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, put(activateLegalSucceeded.getAction({
                        id: id,
                        result: response.data,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 6];
            case 4:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(activateLegalFailed.getAction({ id: id, error: error }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function cancelStartFlow(action) {
    var _a, id, data, onFailure, onSuccess, documents, response, unknownError_6, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onFailure = _a.onFailure, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                documents = data.documents;
                if (!documents) return [3 /*break*/, 3];
                return [4 /*yield*/, all(documents.map(function (file) {
                        return uploadNewDocument({
                            file: file,
                            contractId: id,
                            category: {
                                label: EDocumentCategoryLabels.CancelledContract,
                                id: 21,
                            },
                        });
                    }))];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3:
                delete data.documents;
                return [4 /*yield*/, call(cancel, id, data)];
            case 4:
                response = _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("contract.cancellation.success_message"),
                });
                return [4 /*yield*/, put(cancelSucceeded.getAction({ id: id, result: response.data }))];
            case 5:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
                return [3 /*break*/, 8];
            case 6:
                unknownError_6 = _b.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cancelFailed.getAction({ id: id, error: error }))];
            case 7:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function deleteCancellationStartFlow(action) {
    var _a, id, data, onFailure, onSuccess, response, unknownError_7, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, onFailure = _a.onFailure, onSuccess = _a.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(deleteCancellation, id, {
                        stopDate: data.stopDate,
                    })];
            case 2:
                response = _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("contract.delete_cancellation.success_message"),
                });
                return [4 /*yield*/, put(deleteCancellationSucceeded.getAction({ id: id, result: response.data }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response === null || response === void 0 ? void 0 : response.data.data);
                return [3 /*break*/, 6];
            case 4:
                unknownError_7 = _b.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cancelFailed.getAction({ id: id, error: error }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: 
            //These should be before the "generateSaga" function, because that doesn't return a generator function
            return [4 /*yield*/, takeEvery(indexAskOwnerStart.type, indexAskOwnerStartFlow)];
            case 1:
                //These should be before the "generateSaga" function, because that doesn't return a generator function
                _a.sent();
                return [4 /*yield*/, takeEvery(indexSkipStart.type, indexSkipStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(indexStart.type, indexStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(renewStart.type, renewStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(activateLegalStart.type, activateLegalStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(cancelStart.type, cancelStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(deleteCancellationStart.type, deleteCancellationStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, generateSaga(GENERATOR_PARAMS)];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
