import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import PropertyListItemHeader, {
  IPropertyListAddressSortProps,
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";

export interface IPropertyListItemGeneralHeaderProps
  extends IPropertyListAddressSortProps {}

const PropertyListItemGeneralHeader = ({
  addressOnChangeOrder,
  addressOrder,
}: IPropertyListItemGeneralHeaderProps) => {
  // Render
  return (
    <PropertyListItemHeader
      addressOnChangeOrder={addressOnChangeOrder}
      addressOrder={addressOrder}
    >
      <div style={{ width: 40 }} />

      <PropertyListItemItem
        text={getLocalizedText("properties.owners")}
        style={{ flex: 1 }}
      />

      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.financial_managers")}
        style={{ flex: 1 }}
      />

      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.technical_managers")}
        style={{ flex: 1 }}
      />
      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.indexation_managers")}
        style={{ flex: 1 }}
      />
      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.administrative_managers")}
        style={{ flex: 1 }}
      />
    </PropertyListItemHeader>
  );
};

export default PropertyListItemGeneralHeader;
