import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { ERentioFormField } from "../../components/components/Form";
import { getIbans } from "../../redux/contact/contact.actions";
import * as contactSelectors from "../../redux/contact/contact.selectors";
import { getValue, ibanSelectInlineValueSelector, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getReference } from "./schema.createPayment.6.utils";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle, getStartDate } from "./schema.createPayment.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Iban, EField.PayoutReferenceType],
            properties: (_a = {},
                _a[EField.Header + "6"] = {
                    default: undefined,
                },
                _a[EField.Iban] = {
                    default: getValue(EField.Iban, initialValues, defaultValues),
                },
                _a[EField.PayoutReferenceType] = {
                    default: getValue(EField.PayoutReferenceType, initialValues, defaultValues),
                    // @ts-ignore
                    options: ["DEFAULT", "CUSTOM"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("payment.payment_order.payout_reference_type.".concat(id).toLowerCase()),
                    }); }),
                },
                _a[EField.PayoutReference] = {
                    default: getValue(EField.PayoutReference, initialValues, defaultValues),
                    maxLength: 10,
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.PayoutReferenceType] !== "CUSTOM";
                    },
                },
                _a[EField.PayoutReferenceCustom] = {
                    default: getValue(EField.PayoutReferenceCustom, initialValues, defaultValues),
                    maxLength: 106,
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.PayoutReferenceType] === "CUSTOM";
                    },
                    requiredIf: function (formData) {
                        return formData[EField.PayoutReferenceType] === "CUSTOM";
                    },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payments.create.payee_iban.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "6"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function (_a) {
                var otherDescription = _a.otherDescription, typeRecurring = _a.typeRecurring, typeOnce = _a.typeOnce;
                return getLocalizedText("payments.create.payee_iban.heading", {
                    type: otherDescription ||
                        getLocalizedText("payment.payment_order.type.".concat(typeRecurring || typeOnce).toLowerCase()),
                });
            },
        },
        _a[EField.Iban] = {
            "ui:field": ERentioFormField.IbanSelectInline,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.payee_iban.heading");
            },
            addIbanCtaTitle: getLocalizedText("payment.payment_order.iban.add.cta"),
            // Custom selectors & actions
            valueSelector: function (state, input) {
                return ibanSelectInlineValueSelector(state, input);
            },
            accountIdToAddIban: function (formData) { return formData[EField.Payee][0]; },
            customIsFetchingSelector: function (state, formData) {
                return contactSelectors.isFetchingIbans(state, undefined, formData[EField.Payee][0]);
            },
            customIbansSelector: function (state, formData) {
                return contactSelectors.getIbans(state, undefined, formData[EField.Payee][0]);
            },
            customGetIbansActions: function (formData) { return [
                function () { return getIbans.actions.start({ accountId: formData[EField.Payee][0] }); },
            ]; },
        },
        _a[EField.PayoutReferenceType] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("payment.payment_order.payout_reference_type.label"),
            formatSummary: function (input) {
                return input
                    ? getLocalizedText("payment.payment_order.payout_reference_type.".concat(input).toLowerCase())
                    : "";
            },
        },
        _a[EField.PayoutReference] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("payment.payment_order.payout_reference.label"),
            placeholder: getLocalizedText("payment.payment_order.payout_reference.placeholder"),
            info: getLocalizedText("payment.payment_order.payout_reference.info"),
            extraInfoUrl: getLocalizedText("payment.payment_order.payout_reference.url"),
            optional: true,
            getIMaskProps: function (formData) { return ({
                mask: getReference({
                    asMask: true,
                    property: extraData.property,
                    paymentOrderType: formData[EField.TypeOnce] || formData[EField.TypeRecurring],
                    startDate: getStartDate(formData),
                    otherDescription: formData[EField.OtherDescription],
                }),
                placeholderChar: ". ",
                prepare: function (input) { return input.toUpperCase(); },
                lazy: false,
                unmask: true,
            }); },
            // hideOnSummaryIfEmpty: true,
            formatSummary: function (value, formData) {
                return getReference({
                    asMask: false,
                    reference: value,
                    property: extraData.property,
                    paymentOrderType: formData[EField.TypeOnce] || formData[EField.TypeRecurring],
                    startDate: getStartDate(formData),
                    otherDescription: formData[EField.OtherDescription],
                }).toUpperCase();
            },
        },
        _a[EField.PayoutReferenceCustom] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("payment.payment_order.payout_reference_custom.label"),
            placeholder: getLocalizedText("payment.payment_order.payout_reference_custom.placeholder"),
            info: getLocalizedText("payment.payment_order.payout_reference_custom.info"),
            // extraInfoUrl: getLocalizedText(
            //   "payment.payment_order.payout_reference_custom.url",
            // ),
        },
        _a);
};
export var getValidate = function (_) { return function (formData, errors) {
    if (errors[EField.PayoutReference]) {
        var payoutReference = (formData[EField.PayoutReference] ||
            "");
        if (payoutReference.length > 0) {
            // Contains only alphanumeric characters & space
            var isValid = CONFIG.PAYOUT_REFERENCE_REGEX.test(payoutReference);
            if (!isValid) {
                errors[EField.PayoutReference].addError(getLocalizedText("payment.payment_order.payout_reference.error.no_special_characters"));
            }
        }
    }
    if (errors[EField.PayoutReferenceCustom]) {
        var payoutReference = (formData[EField.PayoutReferenceCustom] ||
            "");
        if (payoutReference.length > 0) {
            // Contains only alphanumeric characters & space
            var isValid = CONFIG.PAYOUT_REFERENCE_REGEX.test(payoutReference);
            if (!isValid) {
                errors[EField.PayoutReferenceCustom].addError(getLocalizedText("payment.payment_order.payout_reference.error.no_special_characters"));
            }
        }
    }
    return errors;
}; };
