import { IApplication } from "@rentiohq/shared/dist/types/application.types";
import utils from "@rentiohq/web-shared/dist/utils";
import * as t from "../../../../../services/translationService";

export const getSetInfo = (application: IApplication) => {
  // TODO: @dries move interface extension to shared
  const { step, stepStartedAt, stepCompletedAt } =
    application as IApplication & {
      step: number;
      stepStartedAt: string;
      stepCompletedAt?: string;
    };

  if (stepCompletedAt) {
    return `${t.applicationStepCompletedAt(step)} ${utils.date.format(
      stepCompletedAt,
      "dd/MM/yyyy HH:mm",
    )}`;
  }

  return `${t.applicationStepProvidedAt(step)} ${utils.date.format(
    stepStartedAt,
    "dd/MM/yyyy HH:mm",
  )}`;
};
