import * as actions from "./document.actions";
import * as api from "./document.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./document.constants";
import * as hooks from "./document.hooks";
import * as promises from "./document.promises";
import reducer from "./document.reducer";
import saga from "./document.saga";
import * as selectors from "./document.selectors";
export var REDUCER_KEY_DOCUMENTS = REDUCER_KEY;
export var documentModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
