import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EField } from "./schema.addBankAccount.types";
export var getInitialValues = function (_a) {
    var _b;
    var contact = _a.contact;
    return (_b = {},
        _b[EField.Iban] = "BE",
        _b[EField.OwnerName] = getName(contact),
        _b);
};
