var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isBefore } from "date-fns";
import hash from "object-hash";
import { cancel, fork, put, take, takeEvery } from "redux-saga/effects";
// @deprecated Use `throttleWithUniquePayload` instead
export var takeLatestWithUniquePayload = function (getUniqueKeyForAction, pattern, saga) {
    var args = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        args[_i - 3] = arguments[_i];
    }
    return fork(function () {
        var lastTasks, action, uniqueKeyForAction, _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    lastTasks = {};
                    _c.label = 1;
                case 1:
                    if (!true) return [3 /*break*/, 6];
                    return [4 /*yield*/, take(pattern)];
                case 2:
                    action = _c.sent();
                    uniqueKeyForAction = getUniqueKeyForAction(action);
                    if (!lastTasks[uniqueKeyForAction]) return [3 /*break*/, 4];
                    return [4 /*yield*/, cancel(lastTasks[uniqueKeyForAction])];
                case 3:
                    _c.sent();
                    delete lastTasks[uniqueKeyForAction];
                    _c.label = 4;
                case 4:
                    _a = lastTasks;
                    _b = uniqueKeyForAction;
                    return [4 /*yield*/, fork.apply(void 0, __spreadArray([saga], args.concat(action), false))];
                case 5:
                    _a[_b] = _c.sent();
                    return [3 /*break*/, 1];
                case 6: return [2 /*return*/];
            }
        });
    });
};
var throttleWithUniquePayloadSaga = function (params) {
    var patternNoThrottle = params.patternNoThrottle, delay = params.delay, lastTasks = params.lastTasks;
    return function (actionThrottle) {
        var actionToHash, uniqueKeyForAction, lastTask, dispatchTime, skipThrottle, canInvoke, actionNoThrottle, _a, _b, _c;
        var _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    actionToHash = __assign(__assign({}, actionThrottle), { payload: __assign(__assign({}, actionThrottle.payload), { skipThrottle: undefined }) });
                    uniqueKeyForAction = hash(actionToHash);
                    lastTask = lastTasks[uniqueKeyForAction];
                    dispatchTime = new Date();
                    skipThrottle = ((_d = actionThrottle.payload) === null || _d === void 0 ? void 0 : _d.skipThrottle) || false;
                    canInvoke = skipThrottle ||
                        !(lastTask === null || lastTask === void 0 ? void 0 : lastTask.dispatchTime) ||
                        isBefore(new Date(lastTask.dispatchTime.getTime() + delay), dispatchTime);
                    if (!canInvoke) return [3 /*break*/, 2];
                    actionNoThrottle = __assign(__assign({}, actionThrottle), { type: patternNoThrottle });
                    _a = lastTasks;
                    _b = uniqueKeyForAction;
                    _c = {};
                    return [4 /*yield*/, put(actionNoThrottle)];
                case 1:
                    _a[_b] = (_c.action = _e.sent(),
                        _c.dispatchTime = dispatchTime,
                        _c);
                    _e.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    };
};
export var throttleWithUniquePayload = function (params) {
    return fork(function () {
        var patternThrottle, patternNoThrottle, saga, _a, delay, lastTasks;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    patternThrottle = params.patternThrottle, patternNoThrottle = params.patternNoThrottle, saga = params.saga, _a = params.delay, delay = _a === void 0 ? 500 : _a;
                    lastTasks = {};
                    return [4 /*yield*/, takeEvery(patternNoThrottle, saga)];
                case 1:
                    _b.sent();
                    // Take every, and the saga will check if the no throttle action can be dispatched
                    return [4 /*yield*/, takeEvery(patternThrottle, throttleWithUniquePayloadSaga({
                            patternNoThrottle: patternNoThrottle,
                            delay: delay,
                            lastTasks: lastTasks,
                        }))];
                case 2:
                    // Take every, and the saga will check if the no throttle action can be dispatched
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
