import { Box } from "@rebass/grid";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import * as cardActions from "@rentiohq/shared-frontend/dist/redux/card/card.actions";
import * as cardSelectors from "@rentiohq/shared-frontend/dist/redux/card/card.selectors";
import {
  ECardType,
  ICard,
} from "@rentiohq/shared-frontend/dist/redux/card/card.types";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import { formatDateWithCustomClosebyFormat } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { startOfDay } from "@rentiohq/shared/dist/utils/date-fns.utils";
import {
  DisplayText,
  DropdownMenu,
  ESpacings,
  Icon,
  Loading,
  Lozenge,
  Tabs,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { groupBy } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as S from "./HeaderNotificationMenu.styles";
import Card from "./components/Card";

interface IProps {}

const HeaderNotificationMenu: React.FC<IProps> = () => {
  // State
  const [type, setType] = React.useState(ECardType.Payment);

  // Redux
  const dispatch = useDispatch();

  const { count: cardsCount = 0 } = useCount({
    countBase: "/cards/count",
  });

  const cards = useSelector<
    IRootStore,
    ReturnType<typeof cardSelectors.unclosedCardsSelector>
  >(state => cardSelectors.unclosedCardsSelector(state));
  const isFetching = useSelector<IRootStore, boolean>(
    state => state.card.isFetchingCards,
  );
  const groupedCards = groupBy(
    cards,
    (card: ICard) => card.type || ECardType.General,
  );
  const types = Object.keys(groupedCards)
    .sort()
    .sort((a, b) => {
      if (a === ECardType.Payment) return -1;
      if (b === ECardType.Payment) return 1;
      if (a === ECardType.General) return 1;
      if (b === ECardType.General) return -1;
      return 0;
    }) as ECardType[];

  // Lifecycle
  React.useEffect(() => {
    if (types.length === 0) {
      return;
    }

    if (types.includes(type)) {
      return;
    }

    setType(types[0]);
  }, [types]);

  // Event handlers
  const handleVisibilityChange = (visible: boolean) => {
    if (!visible) {
      return;
    }

    dispatch(cardActions.getCards.actions.start({}));
  };

  // Render
  const renderContent = (closeDropdownMenu: () => void) => {
    if (isFetching && !cards) {
      return <Loading />;
    }

    // TODO: @dries display error
    if (!cards) {
      return null;
    }

    const cardsForType = groupedCards[type] || [];

    return (
      <>
        {cardsForType.length === 0 && (
          <Box m={ESpacings.loose}>
            <DisplayText size="small" subdued={true}>
              {getLocalizedText("menu.notifications.empty")}
            </DisplayText>
          </Box>
        )}

        {cardsForType[0]?.createdAt
          ? Object.values(
              groupBy(cardsForType, x =>
                x.createdAt ? startOfDay(x.createdAt) : null,
              ),
            ).map(cardsForDate => {
              return (
                <>
                  <S.DateSectionWrap>
                    <TextStyle variation="subdued">
                      {cardsForDate[0]?.createdAt
                        ? formatDateWithCustomClosebyFormat(
                            cardsForDate[0].createdAt,
                            "d MMM yyyy",
                          )
                        : // TODO: Remove
                          "..."}
                    </TextStyle>
                  </S.DateSectionWrap>

                  {cardsForDate.map(card => (
                    <Card
                      card={card}
                      key={card.id}
                      onDone={closeDropdownMenu}
                    />
                  ))}
                </>
              );
            })
          : cardsForType.map(card => (
              <Card card={card} key={card.id} onDone={closeDropdownMenu} />
            ))}
      </>
    );
  };

  return (
    <DropdownMenu
      passDownClickEvents={false}
      children={
        <div style={{ position: "relative" }}>
          <Icon source="bell" />
          {cardsCount > 0 && (
            <div style={{ position: "absolute", top: -8, right: -5 }}>
              <Lozenge isBold={true} appearance={"error"}>
                {cardsCount}
              </Lozenge>
            </div>
          )}
        </div>
      }
      extraData={{ type, types, groupedCards }}
      renderContent={(closeDropdownMenu: () => void) => (
        <S.ContentWrap>
          <S.HeaderWrap>
            <S.TitleWrap>
              <DisplayText size="medium">
                {getLocalizedText("menu.notifications.title")}
              </DisplayText>
            </S.TitleWrap>

            {types.length > 0 && (
              <>
                <S.TabsWrap>
                  <Tabs
                    style={{
                      paddingLeft: 16,
                    }}
                    tabStyle={{
                      padding: "8px 6px",
                      fontSize: 14,
                      fontWeight: 500,
                      letterSpacing: 0.2,
                      textTransform: "inherit",
                    }}
                    tabCountStyle={{
                      marginTop: -1,
                      marginBottom: -1,
                      padding: "1px 3px",
                      fontSize: 12,
                    }}
                    paddingRight={40}
                    tabs={types.map(randomType => {
                      const title = getLocalizedText(
                        `menu.notifications.section.${randomType}`.toLowerCase(),
                      );
                      const count = (groupedCards[randomType] || []).length;

                      return {
                        content: title,
                        isActive: type === randomType,
                        onClick: () => {
                          setType(randomType);
                        },
                        count,
                      };
                    })}
                  />
                  {/* <S.TabGradientLeft /> */}
                  <S.TabGradientRight />
                </S.TabsWrap>

                <ListItemSeparator />
              </>
            )}
          </S.HeaderWrap>
          {renderContent(closeDropdownMenu)}
        </S.ContentWrap>
      )}
      shouldCloseOnClick={false}
      onVisibilityChange={handleVisibilityChange}
    />
  );
};

export default HeaderNotificationMenu;
