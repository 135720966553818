import styled from "@rentiohq/web-theme";

export const ContentWrap = styled.div`
  width: 520px;
  margin: 32px 0 8px;
`;

export const HeaderWrap = styled.div`
  /* margin-bottom: 8px; */
  margin-bottom: 8px;
`;

export const TitleWrap = styled.div`
  padding-left: 24px;
`;

export const TabsWrap = styled.div`
  position: relative;
  margin-top: -12px;
`;

export const TabGradientLeft = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 3px;
  width: 20px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 20%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const TabGradientRight = styled.div`
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 3px;
  width: 65px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const DateSectionWrap = styled.div`
  padding: 24px 24px 4px;
`;
