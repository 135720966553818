var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { reverse, sortBy, uniq } from "lodash";
import { generateReducer, } from "../generators/generator.reducer";
import { counterInfoReceivedStart, isTypingReceivedStart, messagesReceivedStart, requestForMessagesFulfilledStart, requestForMessagesStart, sendReadReceiptStart, setChatIsExhaustedStart, statusInfoReceivedStart, stopTypingStart, } from "./chat.actions";
import { GENERATOR_PARAMS, REDUCER_KEY } from "./chat.constants";
export var REDUCER_KEY_CHAT = REDUCER_KEY;
var getExtraDefaultState = function () { return ({
    messages: {},
    messagesRequests: {},
    typing: [],
}); };
var _reducer = generateReducer(GENERATOR_PARAMS, getExtraDefaultState);
var reducer = _reducer;
reducer.on(requestForMessagesStart.type, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { messagesRequests: __assign(__assign({}, state.messagesRequests), (_a = {}, _a[payload.ref] = {
            amountRequested: payload.amountRequested,
            chatId: payload.chatId,
        }, _a)) }));
});
reducer.on(requestForMessagesFulfilledStart.type, function (state, payload) {
    var messagesRequests = __assign({}, state.messagesRequests);
    delete messagesRequests[payload.ref];
    return __assign(__assign({}, state), { messagesRequests: messagesRequests });
});
reducer.on(setChatIsExhaustedStart.type, function (state, payload) {
    var _a;
    return (__assign(__assign({}, state), { chatExhausted: __assign(__assign({}, state.chatExhausted), (_a = {}, _a[payload.chatId] = true, _a)) }));
});
reducer.on(messagesReceivedStart.type, function (state, payload) {
    var _a;
    var messages = payload;
    if (messages.length === 0) {
        return state;
    }
    var chatId = messages[0].chatId;
    // Merge new messages with old messages
    var allMessages = __spreadArray(__spreadArray([], (state.messages[chatId] || []), true), messages, true);
    var filteredMessagesObject = allMessages.reduce(function (accumulator, message) {
        accumulator[message.id] = message;
        return accumulator;
    }, {});
    // Get array from unique messages object
    var filteredMessages = Object.values(filteredMessagesObject);
    return __assign(__assign({}, state), { messages: __assign(__assign({}, state.messages), (_a = {}, _a[chatId] = reverse(sortBy(filteredMessages, ["sendAt"])), _a)), typing: state.typing.filter(function (randomChatId) { return randomChatId !== chatId; }) });
});
reducer.on(isTypingReceivedStart.type, function (state, payload) { return (__assign(__assign({}, state), { typing: uniq(__spreadArray([payload.chatId], state.typing, true)) })); });
reducer.on(counterInfoReceivedStart.type, function (state, payload) {
    var _a;
    var _b;
    return (__assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[payload.chatId] = {
            entity: __assign(__assign({}, (_b = state.detail[payload.chatId]) === null || _b === void 0 ? void 0 : _b.entity), { unreadCounter: payload.unread }),
        }, _a)) }));
});
reducer.on(stopTypingStart.type, function (state, payload) { return (__assign(__assign({}, state), { typing: state.typing.filter(function (chatId) { return chatId !== payload.chatId; }) })); });
reducer.on(statusInfoReceivedStart.type, function (state, _a) {
    var _b;
    var chatId = _a.chatId, chatMessageId = _a.chatMessageId, accountId = _a.accountId, readAt = _a.readAt, deliveredAt = _a.deliveredAt;
    return (__assign(__assign({}, state), { messages: __assign(__assign({}, state.messages), (_b = {}, _b[chatId] = reverse(sortBy(__spreadArray([], (state.messages[chatId] || []).map(function (message) {
            if (message.id === chatMessageId) {
                return __assign(__assign({}, message), { status: message.status.map(function (status) {
                        if (status.accountId === accountId) {
                            return {
                                accountId: accountId,
                                readAt: readAt,
                                deliveredAt: deliveredAt,
                            };
                        }
                        return status;
                    }) });
            }
            return message;
        }), true), ["sendAt"])), _b)) }));
});
reducer.on(sendReadReceiptStart.type, function (state, _a) {
    var _b;
    var chatId = _a.chatId, messageId = _a.messageId, accountId = _a.accountId;
    return (__assign(__assign({}, state), { messages: __assign(__assign({}, state.messages), (_b = {}, _b[chatId] = reverse(sortBy(__spreadArray([], (state.messages[chatId] || []).map(function (message) {
            if (message.id === messageId) {
                return __assign(__assign({}, message), { status: message.status.map(function (status) {
                        if (status.accountId === accountId) {
                            return {
                                accountId: accountId,
                                readAt: new Date(),
                                deliveredAt: new Date(),
                            };
                        }
                        return status;
                    }) });
            }
            return message;
        }), true), ["sendAt"])), _b)) }));
});
export default reducer;
