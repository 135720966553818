import { REDUCER_KEY_SOCKET } from "./socket.reducer";
export var pendingMessages = function (state) {
    return state.socket.pendingMessages;
};
export var autoReopenAfterClose = function (state) {
    return state.socket.autoReopenAfterClose;
};
export var sendPendingMessagesOnNextOpen = function (state) {
    return state.socket.sendPendingMessagesOnNextOpen;
};
