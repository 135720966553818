var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import api from "../../utils/api/api.utils";
export var getPartnerships = function () {
    return api.get("/partnerships");
};
export var requestLocationDescription = function (data) {
    return api.post("/tasks/location-description", {
        data: data,
    });
};
export var requestLocationDescriptionLegacyMexp = function (data) {
    return api.post("/tasks/mexp", {
        data: __assign(__assign({}, data), { partner: "MEXP" }),
    });
};
