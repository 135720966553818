var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { DisplayText, Image, TextContainer } from "../../components";
import { ButtonActions } from "../Button/components/ButtonActions";
import ButtonGroup from "../ButtonGroup";
import Card from "../Card";
import { ESpacings } from "../Grid";
import * as S from "./CallOutCard.styled";
var CallOutCard = function (_a) {
    var heading = _a.heading, _b = _a.appearance, appearance = _b === void 0 ? "default" : _b, children = _a.children, customImage = _a.customImage, primaryAction = _a.primaryAction, secondaryAction = _a.secondaryAction, _c = _a.spacing, spacing = _c === void 0 ? ESpacings.loose : _c;
    var primaryActionMarkup = primaryAction ? (_jsx(ButtonActions, { actions: [primaryAction], options: { overrides: { appearance: "primary" } } })) : null;
    var secondaryActionMarkup = secondaryAction ? (_jsx(ButtonActions, { actions: [secondaryAction], options: { overrides: { appearance: "outline" } } })) : null;
    var buttonMarkup = secondaryActionMarkup ? (_jsxs(ButtonGroup, { children: [primaryActionMarkup, secondaryActionMarkup] })) : (primaryActionMarkup);
    return (_jsx(Card, __assign({ hasSections: true, appearance: appearance }, { children: _jsxs(S.Wrapper, __assign({ p: spacing }, { children: [_jsx(S.Heading, { children: heading && React.isValidElement(heading) ? (heading) : (_jsx(DisplayText, __assign({ size: "small" }, { children: heading }))) }), _jsxs(S.Body, { children: [_jsxs(S.Content, { children: [_jsx(TextContainer, { children: children }), _jsx(S.Buttons, { children: buttonMarkup })] }), customImage && (_jsx(S.Image, { children: _jsx(Image, { alt: "", source: customImage }) }))] })] })) })));
};
export default CallOutCard;
