import styled from "@rentiohq/web-theme";

export const WIDTH_TITLE_COLUMN = 250;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-bottom: 2px;
`;
