var _a;
import { getAllActions } from "../generic/all.actions";
import { createRoutine } from "../utils/routine.utils";
import CONFIG from "./templateDocument.config";
export var create = (_a = getAllActions({
    entityName: CONFIG.ENTITY_NAME,
}), _a.create), update = _a.update, deleteObject = _a.deleteObject, getDetail = _a.getDetail, getPaged = _a.getPaged;
export var getTemplateDocumentDetail = createRoutine("TEMPLATE_DOCUMENT_DETAIL_GET");
export var updateTemplateDocumentDetail = createRoutine("TEMPLATE_DOCUMENT_DETAIL_UPDATE");
export var generateTemplateDocumentPdf = createRoutine("TEMPLATE_DOCUMENT_GENERATE_PDF");
