var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CustomTooltip, TooltipContent } from "./CustomTooltip";
import * as S from "./Tooltip.styled";
export var Tooltip = function (props) {
    var children = props.children, style = props.style, noCursor = props.noCursor, tooltipContent = props.tooltipContent, tooltipContentStyle = props.tooltipContentStyle, placement = props.placement;
    if (!tooltipContent) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsxs(CustomTooltip, __assign({ placement: placement || "top" }, { children: [_jsx(S.TooltipTrigger, __assign({ style: style, noCursor: noCursor }, { children: children })), _jsx(TooltipContent, { children: _jsx(S.TooltipContentWrap, __assign({ style: tooltipContentStyle, placement: placement }, { children: tooltipContent })) })] })));
};
