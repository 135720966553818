var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { View } from "react-native";
import styled from "../../theme/styled-components.native";
export var WrapNative = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  aspect-ratio: ", ";\n"], ["\n  width: 100%;\n  aspect-ratio: ", ";\n"])), function (props) { return props.aspectRatio; });
export var WrapWeb = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 0;\n  padding-bottom: ", "%;\n"], ["\n  position: relative;\n  width: 100%;\n  height: 0;\n  padding-bottom: ", "%;\n"])), function (props) { return (1 / props.aspectRatio) * 100; });
export var InnerWrapWeb = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
