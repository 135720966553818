export var EPayInAllocationLinkingType;
(function (EPayInAllocationLinkingType) {
    EPayInAllocationLinkingType["AutoPerfectMatch"] = "auto_perfect_match";
    EPayInAllocationLinkingType["AutoTookOldest"] = "auto_took_oldest";
    EPayInAllocationLinkingType["AutoNoReferenceMatchTookCorrespondingAmount"] = "auto_no_reference_match_took_corresponding_amount";
    EPayInAllocationLinkingType["AutoNoReferenceMatchTookOldest"] = "auto_no_reference_match_took_oldest";
    EPayInAllocationLinkingType["ManualMatched"] = "manual_matched";
})(EPayInAllocationLinkingType || (EPayInAllocationLinkingType = {}));
export var EPayInType;
(function (EPayInType) {
    EPayInType["Sepa"] = "sepa";
    EPayInType["Ibanisation"] = "ibanisation";
    EPayInType["Bankwire"] = "bankwire";
})(EPayInType || (EPayInType = {}));
export var EPayInStatus;
(function (EPayInStatus) {
    EPayInStatus["None"] = "none";
    EPayInStatus["Matched"] = "matched";
    EPayInStatus["Unmatched"] = "unmatched";
    EPayInStatus["MatchedPartially"] = "matched_partially";
})(EPayInStatus || (EPayInStatus = {}));
