var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { darken, ellipsis, rem, transparentize } from "polished";
var getAppearanceColor = function (theme, appearance) {
    switch (appearance) {
        case "success":
            return theme.colors.green[800];
        case "warning":
            return theme.colors.yellow[800];
        case "error":
            return theme.colors.red[800];
        default:
            return theme.colors.neutral80;
    }
};
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: 1px solid\n    ", ";\n  & + & {\n    margin-top: ", ";\n  }\n"], ["\n  border-radius: ", ";\n  border: 1px solid\n    ", ";\n  & + & {\n    margin-top: ", ";\n  }\n"])), borderRadius(), function (_a) {
    var theme = _a.theme, appearance = _a.appearance;
    return transparentize(0.5, getAppearanceColor(theme, appearance));
}, spacing());
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 12px;\n  background: ", ";\n  color: ", ";\n"], ["\n  padding: 12px;\n  background: ", ";\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme, appearance = _a.appearance;
    return transparentize(0.9, getAppearanceColor(theme, appearance));
}, function (_a) {
    var theme = _a.theme, appearance = _a.appearance;
    return darken(0.1, getAppearanceColor(theme, appearance));
});
export var Heading = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var Subheading = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ellipsis());
export var Actions = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var ActionTrigger = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n  opacity: 0.8;\n  &:hover {\n    color: ", ";\n    opacity: 1;\n  }\n"], ["\n  cursor: pointer;\n  opacity: 0.8;\n  &:hover {\n    color: ", ";\n    opacity: 1;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral0;
});
export var Content = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: ", ";\n"], ["\n  padding: ", ";\n"])), spacing());
export var Property = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: ", ";\n  border-top: 1px solid ", ";\n  color: ", ";\n  font-size: ", ";\n"], ["\n  padding: ", ";\n  border-top: 1px solid ", ";\n  color: ", ";\n  font-size: ", ";\n"])), spacing("tight"), function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral30;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral60;
}, function (_a) {
    var theme = _a.theme;
    return rem("11px", theme.baseFontSize);
});
export var PropertyContent = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), ellipsis());
export var ItemIcon = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  margin-right: ", ";\n"], ["\n  color: ", ";\n  margin-right: ", ";\n"])), function (_a) {
    var theme = _a.theme, appearance = _a.appearance;
    return getAppearanceColor(theme, appearance);
}, spacing("tight"));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
