import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import {
  formatDate,
  mapObjectWithTimeZonedDatesToUtcIsoString,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EPaymentRequestFeature,
  EPaymentRequestStatus,
  IPaymentRequest,
} from "@rentiohq/shared/dist/types/payment.types";
import { append } from "@rentiohq/shared/dist/utils/api.utils";
import { differenceInCalendarDays } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import {
  AddressCell,
  DataTable,
  ESpacings,
  Loading,
  Lozenge,
  Pagination,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { TActions } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  EPaymentRequestFilterFrom,
  EPaymentRequestFilterPhase,
  EPaymentRequestFilterStatus,
  getPaymentRequestsFilter,
} from "scenes/FollowUp/Payments/Payments.utils";
import { NumberParam, withDefault } from "serialize-query-params";
import { ts } from "../../../services";
import {
  createTitle,
  getAppearanceByPhase,
  getFollowUpReason,
  getPaymentOrders,
  getUrgencyByPhase,
  isFromRent,
} from "../../../utils/payment";
import { IbanisationStatusInfo } from "./IbanisationStatusInfo";

const FETCH_LIMIT = 15;

interface IProps {
  getPaymentRequestActions: (paymentRequest: IPaymentRequest) => TActions;
  propertyId?: number;
  search?: string;
  phase?: EPaymentRequestFilterPhase;
  status?: EPaymentRequestFilterStatus;
  from?: EPaymentRequestFilterFrom;
}

export const PaymentRequestsTable: React.FC<IProps> = React.memo(props => {
  const { propertyId, search, phase, status, from, getPaymentRequestActions } =
    props;

  const filter = getPaymentRequestsFilter({
    propertyId,
    search,
    phase,
    status,
    from,
  });
  const identifier = hash(filter);

  const [queryParams, setQueryParamValue] = useQueryParams({
    page: withDefault(NumberParam, 1),
    paymentRequestId: NumberParam,
  });

  // Redux
  const dispatch = useDispatch();

  const paymentRequests = useSelector(
    (state: IRootStore) =>
      paymentSelectors.getPaymentRequestsForPage(
        state,
        identifier,
        queryParams.page,
      ) || [],
  );

  const isFetching = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentRequestsForPage(
      state,
      identifier,
      queryParams.page,
    ),
  );
  const fetchError = useSelector((state: IRootStore) =>
    paymentSelectors.paymentRequestsForPageFetchError(
      state,
      identifier,
      queryParams.page,
    ),
  );

  const query = getPaymentRequestsFilter({
    propertyId,
    search,
    status,
    phase,
    from,
  });
  // Hooks
  const { count } = useCount({
    countBase: append("/payment-requests/count", {
      ...query,
      where: mapObjectWithTimeZonedDatesToUtcIsoString(query.where),
    }),
  });

  const pageCount = count ? Math.ceil(count / FETCH_LIMIT) : 1;

  // Lifecycle
  React.useEffect(() => {
    setQueryParamValue({ page: 1 });
  }, [phase]);

  React.useEffect(() => {
    if (isFetching) {
      return;
    }

    dispatch(
      paymentActions.getPaymentRequestsPaged.actions.start({
        identifier,
        page: queryParams.page,
        filterData: mapObjectWithTimeZonedDatesToUtcIsoString(filter),
        limit: FETCH_LIMIT,
      }),
    );
  }, [identifier, queryParams.page, search, phase, status, from]);

  const handlePageClick = ({ selected }: any) => {
    setQueryParamValue({ page: selected + 1 });
  };

  const renderContent = () => {
    if (paymentRequests && paymentRequests.length > 0) {
      return (
        <>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "action",
            ]}
            headings={[
              getLocalizedText("payment_request.follow_up.property.heading"),
              `${getLocalizedText(
                "payment_request.follow_up.payment.heading",
              )} / ${getLocalizedText(
                "payment_request.follow_up.amount.heading",
              )}`,
              getLocalizedText("payment_request.follow_up.due_date.heading"),
              getLocalizedText("payment_request.follow_up.payer.heading"),
              getLocalizedText("payment_request.follow_up.status.heading"),
              "",
            ]}
            onRowClick={rowIndex => {
              setQueryParamValue({
                paymentRequestId: paymentRequests?.[rowIndex].id,
              });
            }}
            rows={paymentRequests.map(paymentRequest => {
              const paymentOrders = getPaymentOrders(paymentRequest);
              const dso = differenceInCalendarDays(
                new Date(),
                paymentRequest.dueDateAt,
              );

              const urgency = getUrgencyByPhase(phase);
              const hasFromRent = isFromRent(paymentOrders);
              const amount =
                paymentRequest.status === EPaymentRequestStatus.Partial
                  ? paymentRequest.amount
                  : paymentRequest.originalAmount;

              return {
                id: paymentRequest.id,
                urgency,
                content: [
                  <AddressCell
                    property={paymentRequest.property}
                    cellKey={`${paymentRequest.id}-property`}
                  />,
                  <div>
                    <TextStyle variation={"strong"}>
                      {createTitle({
                        paymentOrders,
                        paymentRequest,
                        withPayoutType: true,
                      })}
                    </TextStyle>
                    <Box mt={ESpacings.tight}>
                      <TextStyle
                        variation={["code"]}
                        key={`${paymentRequest.id}-amount`}
                        element="div"
                      >
                        {formatCurrency(amount)}
                      </TextStyle>
                    </Box>
                  </div>,
                  <div>
                    {formatDate(paymentRequest.dueDateAt)}
                    {!hasFromRent && (
                      <Box mt={ESpacings.tight}>
                        <Lozenge appearance={getAppearanceByPhase(phase)}>
                          {ts.paymentsFollowUpCardDaysMessage({ days: dso })}
                        </Lozenge>
                      </Box>
                    )}
                  </div>,

                  getName(paymentRequest.payerAccount),
                  <div>
                    {getFollowUpReason(paymentRequest)}
                    {!!(paymentRequest.features || []).includes(
                      EPaymentRequestFeature.ThirdPartyPayInBrokerDirect,
                    ) && (
                      <Box mt={ESpacings.tight}>
                        <Lozenge isBold={true}>
                          {ts.paymentRequestFeatureThirdPartyAccountLabel()}
                        </Lozenge>
                      </Box>
                    )}

                    <IbanisationStatusInfo
                      propertyId={
                        paymentRequest.paymentRequestItems[0]?.paymentOrder
                          ?.propertyId
                      }
                    />
                  </div>,
                ],
                actions: getPaymentRequestActions(paymentRequest),
              };
            })}
          />
          {!!count && pageCount > 1 && (
            <Pagination
              initialPage={queryParams.page ? queryParams.page - 1 : 0}
              pageCount={pageCount}
              onPageChange={handlePageClick}
            />
          )}
        </>
      );
    }
    if (fetchError) {
      return <p>{getLocalizedText("fetch.error")}</p>;
    }

    if (isFetching) {
      return <Loading />;
    }

    return <p>{getLocalizedText("payment_requests.empty")}</p>;
  };

  return <>{renderContent()}</>;
}, utils.components.arePropsEqual);
