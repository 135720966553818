export var EExpertInquiryPropertySituation;
(function (EExpertInquiryPropertySituation) {
    EExpertInquiryPropertySituation["Inhabited"] = "inhabited";
    EExpertInquiryPropertySituation["Uninhabited"] = "uninhabited";
})(EExpertInquiryPropertySituation || (EExpertInquiryPropertySituation = {}));
export var ELocationDescriptionType;
(function (ELocationDescriptionType) {
    ELocationDescriptionType["In"] = "in";
    ELocationDescriptionType["Out"] = "out";
    ELocationDescriptionType["Both"] = "both";
})(ELocationDescriptionType || (ELocationDescriptionType = {}));
