import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { Card } from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../services";
import { getContactActivityFilterContactPart } from "../../utils";

export const ContactHistory = () => {
  const params = useParams<{ contactId: string }>();
  const contactId = params.contactId ? +params.contactId : undefined;

  const contact = useSelector((state: IRootStore) =>
    contactId ? contactSelectors.getContact(state, contactId) : undefined,
  );

  if (!contact) {
    return null;
  }

  return (
    <Card
      heading={ts.contactHistoryHeading({
        values: { contact: contact.firstname },
      })}
    >
      <Activities
        identifier={`contact-${contact.id}`}
        filter={getContactActivityFilterContactPart(contact)}
        hasFetchMore={true}
      />
    </Card>
  );
};
