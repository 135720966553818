import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { subMonths } from "@rentiohq/shared/dist/utils/date-fns.utils";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import PropertyListItemHeader, {
  IPropertyListAddressSortProps,
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";
import * as constants from "./PropertyListItemFinancial.constants";
import OlderNewerButton from "./components/OlderNewerButton";

export interface IPropertyListItemFinancialHeaderProps
  extends IPropertyListAddressSortProps {}

const PropertyListItemFinancialHeader = ({
  addressOnChangeOrder,
  addressOrder,
}: IPropertyListItemFinancialHeaderProps) => {
  // Redux
  const mostLeftDate = useSelector(
    (state: IRootStore) =>
      systemSelectors.getPreference<Date>(
        state,
        constants.PREFERENCE_KEY_PROPERTIES_FINANCIAL_TAB_DATE,
      ) || constants.START_DATE,
  );

  // Return
  return (
    <PropertyListItemHeader
      addressOnChangeOrder={addressOnChangeOrder}
      addressOrder={addressOrder}
    >
      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("role.payer")}
        style={{ width: constants.WIDTH_PAYERS }}
      />

      <PropertyListItemItem
        text={getLocalizedText("system.total")}
        style={{ width: constants.WIDTH_PAYERS_TOTAL }}
        contentStyle={{ justifyContent: "center" }}
      />

      <PropertyListItemSpacer />

      <PropertyListItemItem style={{ width: constants.WIDTH_NEXT_PREV }}>
        <OlderNewerButton olderNewer={"newer"} />
      </PropertyListItemItem>

      {Array(constants.MONTHS_PER_VIEW)
        .fill(0)
        .map((month, index) => {
          const currentDate = subMonths(mostLeftDate, index);

          return (
            <PropertyListItemItem
              key={month}
              text={formatDate(currentDate, "LLL yyyy")}
              style={{ flex: 1 }}
              contentStyle={{ justifyContent: "center" }}
            />
          );
        })}

      <PropertyListItemItem style={{ width: constants.WIDTH_NEXT_PREV }}>
        <OlderNewerButton olderNewer={"older"} />
      </PropertyListItemItem>
    </PropertyListItemHeader>
  );
};

export default PropertyListItemFinancialHeader;
