export var EPaymentOrderRole;
(function (EPaymentOrderRole) {
    EPaymentOrderRole["Read"] = "READ";
    EPaymentOrderRole["Write"] = "WRITE";
})(EPaymentOrderRole || (EPaymentOrderRole = {}));
export var EPaymentOrderType;
(function (EPaymentOrderType) {
    // Recurring
    EPaymentOrderType["RentAndCosts"] = "RENT_AND_COSTS";
    EPaymentOrderType["Rent"] = "RENT";
    EPaymentOrderType["CommonCosts"] = "COMMON_COSTS";
    EPaymentOrderType["NonCommonCosts"] = "NON_COMMON_COSTS";
    EPaymentOrderType["ManagementCosts"] = "MANAGEMENT_COSTS";
    EPaymentOrderType["Rentio"] = "RENTIO";
    EPaymentOrderType["OtherRecurring"] = "OTHER_RECURRING";
    // Non recurring
    EPaymentOrderType["RentDeposit"] = "RENT_DEPOSIT";
    EPaymentOrderType["LocationDescription"] = "LOCATION_DESCRIPTION";
    EPaymentOrderType["BrokerFee"] = "BROKER_FEE";
    EPaymentOrderType["OtherOneoff"] = "OTHER_ONEOFF";
    // Non creatable
    EPaymentOrderType["RentDepositPayout"] = "RENT_DEPOSIT_PAYOUT";
    EPaymentOrderType["RentDiscount"] = "RENT_DISCOUNT";
})(EPaymentOrderType || (EPaymentOrderType = {}));
export var EPaymentOrderOwnerPaymentMethod;
(function (EPaymentOrderOwnerPaymentMethod) {
    EPaymentOrderOwnerPaymentMethod["FromRent"] = "FROM_RENT";
    EPaymentOrderOwnerPaymentMethod["Direct"] = "DIRECT";
})(EPaymentOrderOwnerPaymentMethod || (EPaymentOrderOwnerPaymentMethod = {}));
export var EPaymentRepetitionType;
(function (EPaymentRepetitionType) {
    EPaymentRepetitionType["Once"] = "ONCE";
    EPaymentRepetitionType["Monthly"] = "MONTHLY";
    EPaymentRepetitionType["Yearly"] = "YEARLY";
    EPaymentRepetitionType["Quarterly"] = "QUARTERLY";
})(EPaymentRepetitionType || (EPaymentRepetitionType = {}));
var EPaymentFeatures;
(function (EPaymentFeatures) {
    EPaymentFeatures["BancontactBankWireOnly"] = "BANCONTACT_BANKWIRE_ONLY";
    EPaymentFeatures["BancontactIbanisationOnly"] = "BANCONTACT_IBANISATION_ONLY";
    EPaymentFeatures["BancontactOnly"] = "BANCONTACT_ONLY";
    EPaymentFeatures["BlockedPayment"] = "BLOCKED_PAYMENT";
    EPaymentFeatures["BlockedPayout"] = "BLOCKED_PAYOUT";
    EPaymentFeatures["NoNotifications"] = "NO_NOTIFICATIONS";
    EPaymentFeatures["RentDeposit"] = "RENT_DEPOSIT";
})(EPaymentFeatures || (EPaymentFeatures = {}));
export var EPaymentRequestStatus;
(function (EPaymentRequestStatus) {
    EPaymentRequestStatus["New"] = "new";
    EPaymentRequestStatus["Approved"] = "approved";
    EPaymentRequestStatus["Pending"] = "pending";
    EPaymentRequestStatus["PendingThirdParty"] = "pending_3rd_party";
    EPaymentRequestStatus["PayInPlanned"] = "planned_pay_in";
    EPaymentRequestStatus["PaidIn"] = "paid_in";
    EPaymentRequestStatus["Partial"] = "partial";
    // cSpell:ignore transfering
    EPaymentRequestStatus["Transferring"] = "transfering";
    // cSpell:ignore transfered
    EPaymentRequestStatus["Transferred"] = "transfered";
    EPaymentRequestStatus["PayingOut"] = "paying_out";
    EPaymentRequestStatus["FailedPaidIn"] = "failed_paid_in";
    EPaymentRequestStatus["FailedTransfer"] = "failed_transfer";
    EPaymentRequestStatus["FailedPayout"] = "failed_pay_out";
    EPaymentRequestStatus["FailedKyc"] = "failed_kyc";
    EPaymentRequestStatus["Failed"] = "failed";
    EPaymentRequestStatus["Paid"] = "paid";
})(EPaymentRequestStatus || (EPaymentRequestStatus = {}));
export var EPaymentMethod;
(function (EPaymentMethod) {
    EPaymentMethod["Sepa"] = "sepa";
    EPaymentMethod["Ibanisation"] = "ibanisation";
    EPaymentMethod["BankWire"] = "bankwire";
    EPaymentMethod["Bancontact"] = "bancontact";
    EPaymentMethod["Maestro"] = "maestro";
    EPaymentMethod["Payconiq"] = "payconiq";
    EPaymentMethod["Ideal"] = "ideal";
})(EPaymentMethod || (EPaymentMethod = {}));
export var EPayoutType;
(function (EPayoutType) {
    EPayoutType["Charge"] = "CHARGE";
    EPayoutType["Payout"] = "PAYOUT";
})(EPayoutType || (EPayoutType = {}));
export var EExternalBookkeepingType;
(function (EExternalBookkeepingType) {
    EExternalBookkeepingType["EasyInvoicing"] = "EF";
})(EExternalBookkeepingType || (EExternalBookkeepingType = {}));
export var EPaymentRequestFetchType;
(function (EPaymentRequestFetchType) {
    EPaymentRequestFetchType["Open"] = "open";
    EPaymentRequestFetchType["Pending"] = "pending";
    EPaymentRequestFetchType["Done"] = "done";
})(EPaymentRequestFetchType || (EPaymentRequestFetchType = {}));
export var ETransferType;
(function (ETransferType) {
    ETransferType["TransferPayout"] = "TRANSFER_PAYOUT";
    ETransferType["TransferAdministrative"] = "TRANSFER_ADMINISTRATIVE";
    ETransferType["TransferReverseAdministrative"] = "TRANSFER_REVERSE_ADMINISTRATIVE";
    ETransferType["Payout"] = "PAYOUT";
})(ETransferType || (ETransferType = {}));
export var ETransactionStatus;
(function (ETransactionStatus) {
    ETransactionStatus["Success"] = "success";
    ETransactionStatus["Transferred"] = "transfered";
    ETransactionStatus["Skipped"] = "skipped";
    ETransactionStatus["New"] = "new";
    ETransactionStatus["Paid"] = "paid";
})(ETransactionStatus || (ETransactionStatus = {}));
export var EPaymentRequestFeature;
(function (EPaymentRequestFeature) {
    EPaymentRequestFeature["PlannedPayOut"] = "PLANNED_PAY_OUT";
    EPaymentRequestFeature["BlockedPayment"] = "BLOCKED_PAYMENT";
    EPaymentRequestFeature["ThirdPartyPayInBrokerDirect"] = "THIRD_PARTY_PAYIN_BROKER_DIRECT";
    EPaymentRequestFeature["RestAmount"] = "REST_AMOUNT";
    EPaymentRequestFeature["PartialAmount"] = "PARTIAL_AMOUNT";
    EPaymentRequestFeature["RentDepositIn"] = "RENT_DEPOSIT_IN";
    EPaymentRequestFeature["DisputePayment"] = "DISPUTE_PAYMENT";
    EPaymentRequestFeature["MangoPayWebPayments"] = "MANGO_PAY_WEB_PAYMENTS";
})(EPaymentRequestFeature || (EPaymentRequestFeature = {}));
export var EPaymentRequestFollowUpType;
(function (EPaymentRequestFollowUpType) {
    EPaymentRequestFollowUpType["Mail"] = "MAIL";
    EPaymentRequestFollowUpType["App"] = "APP";
    EPaymentRequestFollowUpType["Phone"] = "PHONE";
    EPaymentRequestFollowUpType["RegisteredMail"] = "REGISTERED_MAIL";
    EPaymentRequestFollowUpType["Sms"] = "SMS";
})(EPaymentRequestFollowUpType || (EPaymentRequestFollowUpType = {}));
export var EMissingKYCStatus;
(function (EMissingKYCStatus) {
    EMissingKYCStatus["None"] = "none";
    EMissingKYCStatus["New"] = "new";
    EMissingKYCStatus["Requested"] = "requested";
    EMissingKYCStatus["Failed"] = "failed";
    EMissingKYCStatus["Approved"] = "approved";
})(EMissingKYCStatus || (EMissingKYCStatus = {}));
export var EMangoPayKycFailedStatus;
(function (EMangoPayKycFailedStatus) {
    EMangoPayKycFailedStatus["DocumentUnreadable"] = "DOCUMENT_UNREADABLE";
    EMangoPayKycFailedStatus["DocumentNotAccepted"] = "DOCUMENT_NOT_ACCEPTED";
    EMangoPayKycFailedStatus["DocumentHasExpired"] = "DOCUMENT_HAS_EXPIRED";
    EMangoPayKycFailedStatus["DocumentIncomplete"] = "DOCUMENT_INCOMPLETE";
    EMangoPayKycFailedStatus["DocumentDoNotMatchUserData"] = "DOCUMENT_DO_NOT_MATCH_USER_DATA";
    EMangoPayKycFailedStatus["DocumentDoNotMatchAccountData"] = "DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA";
    EMangoPayKycFailedStatus["DocumentFalsified"] = "DOCUMENT_FALSIFIED";
    EMangoPayKycFailedStatus["UnderagePerson"] = "UNDERAGE_PERSON";
    EMangoPayKycFailedStatus["SpecificCase"] = "SPECIFIC_CASE";
})(EMangoPayKycFailedStatus || (EMangoPayKycFailedStatus = {}));
// TODO: Remove
export var EPaymentRequestsDisplayType;
(function (EPaymentRequestsDisplayType) {
    EPaymentRequestsDisplayType["Grid"] = "grid";
    EPaymentRequestsDisplayType["Table"] = "table";
})(EPaymentRequestsDisplayType || (EPaymentRequestsDisplayType = {}));
