import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IPaymentRequest } from "@rentiohq/shared/dist/types/payment.types";
import { differenceInCalendarDays } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import {
  DisplayText,
  ESpacings,
  Grid,
  Icon,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { getReadableType } from "@rentiohq/web-shared/dist/utils/paymentRequest";
import { ts } from "../../../../../services";

interface IPaymentRequestRowProps {
  paymentRequest: IPaymentRequest;
}

const getTitle = (paymentRequest: IPaymentRequest) => {
  const costType = getReadableType(paymentRequest, key =>
    getLocalizedText(`payment.payment_order.type.${key.toLowerCase()}`),
  );
  return `${costType} (${formatDate(paymentRequest.startedAt)} - ${formatDate(
    paymentRequest.endedAt,
  )})`;
};

export const PaymentRequestRow = ({
  paymentRequest,
}: IPaymentRequestRowProps) => {
  const dso = differenceInCalendarDays(new Date(), paymentRequest.dueDateAt);

  return (
    <ResourceListItem
      boxProps={{ p: ESpacings.tight }}
      item={paymentRequest}
      media={<Icon source="forRentSign" />}
      mediaSize="medium"
      actions={
        [
          /**TODO */
        ]
      }
    >
      <Grid alignItems="center" justifyContent="space-between">
        <Grid.Item>
          <div>{getTitle(paymentRequest)}</div>
          <TextStyle variation="negative">
            <div>{ts.paymentsFollowUpCardDaysMessage({ days: dso })}</div>
          </TextStyle>
        </Grid.Item>
        <Grid.Item>
          <DisplayText size="medium" element="div">
            <TextStyle
              variation="code"
              element="div"
              style={{ textAlign: "right" }}
            >
              {formatCurrency(paymentRequest.amount)}
            </TextStyle>
          </DisplayText>
        </Grid.Item>
      </Grid>
    </ResourceListItem>
  );
};
