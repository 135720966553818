import { ITask } from "@rentiohq/shared/dist/types/task.types";
import React from "react";
import { TaskDocumentsOverview } from "./TaskDocumentsOverview";

interface IProps {
  taskId: number;
  task?: ITask;
}

export const TaskDocumentsOverviewContainer: React.FC<IProps> = props => {
  const { taskId, task } = props;

  if (!task) {
    return null;
  }

  return <TaskDocumentsOverview taskId={taskId} task={task} />;
};
