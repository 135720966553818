import { Box } from "@rebass/grid";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import { Grid, Icon } from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import * as t from "../../../services/translationService";

interface IProps {
  contact: IContact;
}

export const ContactDetails: React.FC<IProps> = (props: IProps) => {
  const { contact } = props;

  const handleEmailContact = () => {
    if (contact.email) {
      return;
    }

    window.location.href = `mailto:${contact.email}`;
  };

  const handleCallContact = () => {
    if (contact.phone) {
      return;
    }

    window.location.href = `tel:${contact.phone}`;
  };

  return (
    <>
      {contact.phone && (
        <Box mb={1}>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item alignItems="center">
              <Icon source="smartphone" size="small" />
            </Grid.Item>
            <Grid.Item>
              <Button appearance="reset" onClick={handleCallContact}>
                {formatPhone(contact.phone)}
              </Button>
            </Grid.Item>
          </Grid>
        </Box>
      )}

      {contact.email && (
        <Box mb={1}>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="email" size="small" />
            </Grid.Item>
            <Grid.Item>
              <Button appearance="reset" onClick={handleEmailContact}>
                {contact.email}
              </Button>
            </Grid.Item>
          </Grid>
        </Box>
      )}

      {contact.locale && (
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item alignItems="center">
            <Icon source="locale" size="small" />
          </Grid.Item>
          <Grid.Item>{t.locale(contact.locale)}</Grid.Item>
        </Grid>
      )}
    </>
  );
};
