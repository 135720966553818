import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { Can } from "@rentiohq/web-shared/dist/components";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { DocumentsOverview } from "./DocumentsOverview";

export const DocumentsOverviewContainer = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectors.getDetail(state, propertyId) : undefined,
  );

  if (!property) {
    return null;
  }

  const renderContent = () => <DocumentsOverview property={property} />;

  return (
    <Can
      role={property.roles}
      perform="propertyDocuments:visit"
      yes={renderContent}
      no={() => <Navigate to={`/properties/${propertyId}`} />}
    />
  );
};
