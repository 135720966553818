import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as S from "./AssignRoles.styled";

import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  EPropertyMemberTypes,
  TPropertyMember,
} from "@rentiohq/shared/dist/types/property.types";
import { Switch, TextStyle } from "@rentiohq/web-shared/dist/components";
import { TTextStyleVariation } from "@rentiohq/web-shared/dist/components/TextStyle/TextStyle.types";
import { TAppearance } from "@rentiohq/web-shared/dist/types";
import { compact, flatten, uniq } from "lodash";

interface IValues {
  [accountId: number]: EPropertyMemberTypes[] | undefined;
}

export interface IAssignRolesProps {
  roles: EPropertyMemberTypes[];
  members: TPropertyMember[];
  values: IValues;
  onChange: (newValues: IValues) => void;
}

export const AssignRoles = (props: IAssignRolesProps) => {
  const { roles, members, values, onChange } = props;

  const handleSwitchMemberRole =
    (role: EPropertyMemberTypes, accountId: number) => (newValue: boolean) => {
      const member = members.find(member => member.account.id === accountId);
      if (!member) {
        return;
      }

      if (newValue) {
        onChange({
          ...values,
          [accountId]: uniq([...(values[accountId] || []), role]),
        });
      } else {
        onChange({
          ...values,
          [accountId]: (values[accountId] || []).filter(
            randomRole => randomRole !== role,
          ),
        });
      }
    };

  const getHasRole = (role: EPropertyMemberTypes) =>
    compact(flatten(Object.values(values))).includes(role);

  const getAppearance = (
    role: EPropertyMemberTypes,
  ): TAppearance | undefined => {
    const hasRole = getHasRole(role);
    switch (role) {
      case EPropertyMemberTypes.IndexationManager:
        if (!hasRole) {
          return "warning";
        }

        return;

      case EPropertyMemberTypes.FinancialManager:
      case EPropertyMemberTypes.TechnicalManager:
        if (!hasRole) {
          return "error";
        }

        return;

      default:
        return;
    }
  };

  const getVariation = (
    role: EPropertyMemberTypes,
  ): TTextStyleVariation | undefined => {
    const hasRole = getHasRole(role);
    switch (role) {
      case EPropertyMemberTypes.IndexationManager:
        if (!hasRole) {
          return ["warn", "strong"];
        }

        return;

      case EPropertyMemberTypes.FinancialManager:
      case EPropertyMemberTypes.TechnicalManager:
        if (!hasRole) {
          return ["negative", "strong"];
        }

        return;

      default:
        return;
    }
  };

  const getMessage = (role: EPropertyMemberTypes) => {
    const hasRole = getHasRole(role);

    const brokerAccount = members.find(
      member => member.roles?.includes(EPropertyMemberTypes.Broker),
    )?.account;
    const brokerHasRole =
      brokerAccount && values[brokerAccount.id]?.includes(role);

    const membersWithRole = members.filter(
      member => values[member.account.id]?.includes(role),
    );

    switch (role) {
      case EPropertyMemberTypes.IndexationManager:
        if (!hasRole) {
          return getLocalizedText(
            "property.management.billing_info.no_rentio_indexation",
          );
        }

        return;

      case EPropertyMemberTypes.FinancialManager: {
        if (!hasRole) {
          return getLocalizedText("system.required");
        }

        if (!brokerAccount) {
          return;
        }

        if (brokerHasRole) {
          return getLocalizedText(
            "property.management.billing_info.broker_charges",
            {
              value: getName(brokerAccount),
            },
          );
        } else {
          return getLocalizedText(
            "property.management.billing_info.no_payment_charges",
          );
        }
      }

      case EPropertyMemberTypes.TechnicalManager: {
        if (!hasRole) {
          return getLocalizedText("system.required");
        }

        if (!brokerAccount) {
          return;
        }

        if (brokerHasRole) {
          return getLocalizedText(
            "property.management.billing_info.broker_charges",
            {
              value: getName(brokerAccount),
            },
          );
        } else {
          return getLocalizedText(
            "property.management.billing_info.no_charges",
            {
              value: join(
                membersWithRole.map(member => getName(member.account)),
              ),
            },
          );
        }
      }

      default:
        return;
    }
  };

  return (
    <S.Wrap>
      <S.Row>
        <S.RoleCell />
        {members.map(member => (
          <S.HeaderCell key={member.account.id}>
            <TextStyle variation="strong" size="medium">
              {getName(member.account)}
            </TextStyle>
          </S.HeaderCell>
        ))}
      </S.Row>

      {roles.map(role => {
        const appearance = getAppearance(role);
        const variation = getVariation(role);
        const message = getMessage(role);
        return (
          <S.Row key={role} appearance={appearance}>
            <S.RoleCell>
              <TextStyle variation="strong" size="medium" element="div">
                {getLocalizedText(`role.${role}`.toLowerCase())}
              </TextStyle>
              <TextStyle variation={variation || "subdued"}>
                {message}
              </TextStyle>
            </S.RoleCell>
            {members.map(member => {
              const hasRole =
                values[member.account.id]?.includes(role) ?? false;

              return (
                <S.Cell key={member.account.id}>
                  <Switch
                    onChange={handleSwitchMemberRole(role, member.account.id)}
                    isChecked={hasRole}
                  />
                </S.Cell>
              );
            })}
          </S.Row>
        );
      })}
    </S.Wrap>
  );
};
