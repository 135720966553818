import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";
import { appendQueryParams } from "@rentiohq/shared/dist/utils/url.utils";
import {
  DisplayText,
  ESpacings,
  Grid,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button/Button";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { Tasks } from "../../../../scenes/Tasks/Tasks";

export const TasksForProperty = () => {
  const navigate = useNavigate();

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectors.getDetail(state, propertyId) : undefined,
  );
  const hasKeypointRepair = brokerHooks.useBrokerFeature(
    EBrokerFeature.KeypointRepair,
  );

  if (!property) return null;

  const handleAddTaskClick = (_: any, isKeypoint?: boolean) => {
    if (!propertyId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/tasks/add",
        queryParams: {
          propertyId,
          keypoint: isKeypoint ? 1 : 0,
        },
      }),
    );
  };
  return (
    <>
      <Grid
        mb={ESpacings.base}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid.Item>
          <DisplayText size="medium">
            {getLocalizedText("properties.tasks")}
          </DisplayText>
        </Grid.Item>

        <Grid.Item>
          <div style={{ display: "flex" }}>
            {hasKeypointRepair && (
              <>
                <Button
                  appearance="outline"
                  color="blue"
                  size="small"
                  onClick={event => handleAddTaskClick(event, true)}
                >
                  {getLocalizedText("task.repair.service.keypoint")}
                </Button>
                <div style={{ width: ESpacings.loose }} />
              </>
            )}
            <Button
              appearance="primary"
              size="small"
              onClick={handleAddTaskClick}
            >
              {getLocalizedText("tasks.new")}
            </Button>
          </div>
        </Grid.Item>
      </Grid>
      <Tasks propertyId={property.id} asPage={false} showCounts={false} />
    </>
  );
};
