import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as beneficiaryReportHooks from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary/report.beneficiary.hooks";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EPaymentRequestStatus } from "@rentiohq/shared/dist/types/payment.types";
import {
  endOfMonth,
  startOfMonth,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { Filters, Stages } from "@rentiohq/web-shared/dist/components";
import {
  getRangeForQuickFilter,
  translateDateRange,
} from "@rentiohq/web-shared/dist/components/Filters";
import {
  EDateQuickFilters,
  EFilterType,
} from "@rentiohq/web-shared/dist/components/Filters/Filters.types";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { compact } from "lodash";
import { useEffect } from "react";
import usePreference from "scenes/Settings/hooks/usePreference";
import { createEnumParam } from "serialize-query-params";
import {
  EBeneficiaryReportReported,
  EBeneficiaryReportReportingEnabledValues,
} from "./BeneficiaryReport.types";

export interface IResultingFilter {
  customFilters?: Array<string>;
  filter?: Record<string, any>;
}

interface IBeneficiaryReportFilterProps {
  searchQuery?: string | null;
  onFilterChange: (resultingFilter: IResultingFilter) => void;
  onSearchChange: (searchTerm: string) => void;
}

enum EPaidFilter {
  Paid = "paid",
  NotPaid = "NOT_PAID",
}

const PREFERENCE_KEY_PAYOUT_DATE_QUICK =
  "pref_beneficiary_reports_payout_date_quick";
const PREFERENCE_KEY_REPORTED = "pref_beneficiary_reports_reported";
const PREFERENCE_KEY_PAID = "pref_beneficiary_reports_paid";
// const PREFERENCE_KEY_REPORTING_ENABLED =
//   "pref_beneficiary_reports_reporting_enabled";
const PREFERENCE_KEY_DATE_RANGE = "pref_beneficiary_reports_date_range";

// No payouts created before this date are shown.
export const BENEFICIARY_REPORTS_CUTOFF_DATE = new Date(2022, 11, 1);

export default function BeneficiaryReportFilter({
  searchQuery,
  onFilterChange,
  onSearchChange,
}: IBeneficiaryReportFilterProps) {
  const [queryParams] = useQueryParams({
    reported: createEnumParam(Object.values(EBeneficiaryReportReported)),
  });

  const [
    reported = EBeneficiaryReportReported.PaymentRequestNotReported,
    setReported,
  ] = usePreference<EBeneficiaryReportReported | null>({
    preferenceKey: PREFERENCE_KEY_REPORTED,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paid = EPaidFilter.Paid, setPaid] = usePreference<EPaidFilter | null>({
    preferenceKey: PREFERENCE_KEY_PAID,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [payoutDateQuick, setPayoutDateQuick, clearPayoutDateQuick] =
    usePreference<EDateQuickFilters | null>({
      preferenceKey: PREFERENCE_KEY_PAYOUT_DATE_QUICK,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  useEffect(() => {
    if (queryParams?.reported) {
      setReported(queryParams.reported);
      setPaid(null);
      setPayoutDateQuick(null);
    }
  }, [queryParams]);

  // const [
  //   reportingEnabled = EBeneficiaryReportReportingEnabledValues.ReportingEnabled,
  //   setReportingEnabled,
  // ] = usePreference<EBeneficiaryReportReportingEnabledValues | null>({
  //   preferenceKey: PREFERENCE_KEY_REPORTING_ENABLED,
  //   preferencePersistScope: EPreferencePersistScope.LocalStorage,
  // });

  const [payoutDateRange, setPayoutDateRange, clearPayoutDateRange] =
    usePreference<[Date | null, Date | null]>({
      preferenceKey: PREFERENCE_KEY_DATE_RANGE,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  useEffect(() => {
    let dateRange: any[] = [];

    if (payoutDateRange) {
      dateRange = [null, null];
      const start = payoutDateRange[0];
      const end = payoutDateRange[1];

      if (start) {
        dateRange[0] = {
          payoutDate: { gte: startOfMonth(start) },
        };
      }

      if (end) {
        dateRange[1] = {
          payoutDate: { lte: endOfMonth(end) },
        };
      }
    } else if (
      payoutDateQuick &&
      payoutDateQuick !== EDateQuickFilters.DateRange
    ) {
      const range = getRangeForQuickFilter(payoutDateQuick, "payoutDate");
      if (range) dateRange = range;
    }

    let paidFilter;
    if (paid !== null) {
      const condition = paid === EPaidFilter.Paid ? "eq" : "neq";
      paidFilter = {
        status: {
          [condition]: EPaymentRequestStatus.Paid,
        },
      };
    }

    const filterObject: IResultingFilter = {};
    const customFilters: (
      | EBeneficiaryReportReported
      | EBeneficiaryReportReportingEnabledValues
    )[] = [];
    if (reported) {
      customFilters.push(reported);
    }
    // if (reportingEnabled) {
    //   customFilters.push(reportingEnabled);
    // }
    if (customFilters.length > 0) {
      filterObject.customFilters = customFilters;
    }

    const filters = compact([
      ...dateRange,
      paidFilter,
      { createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE } },
    ]);

    if (filters.length > 0) {
      filterObject.filter = { and: filters };
    }

    onFilterChange(filterObject);
  }, [reported, payoutDateQuick, payoutDateRange, paid]);

  //Counts
  const { count: notReportedCount } = beneficiaryReportHooks.useCount({
    query: {
      filter: { createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE } },
      customFilters: [EBeneficiaryReportReported.PaymentRequestNotReported],
    },
  });

  const { count: reportedCount } = beneficiaryReportHooks.useCount({
    query: {
      filter: { createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE } },
      customFilters: [EBeneficiaryReportReported.PaymentRequestReported],
    },
  });

  return (
    <>
      <Stages
        stages={[
          {
            heading: getLocalizedText(
              "reports.beneficiary.payouts.not_reported",
              {
                cutoffdate: formatDate(BENEFICIARY_REPORTS_CUTOFF_DATE),
              },
            ),
            icon: "fileQuestion",
            count: notReportedCount ?? 0,
            // appearance: "warning",
            onClick: () => {
              clearPayoutDateRange();
              setPayoutDateQuick(null);
              // setReportingEnabled(null);
              setReported(EBeneficiaryReportReported.PaymentRequestNotReported);
              setPaid(null);
            },
          },
          {
            heading: getLocalizedText("reports.beneficiary.payouts.reported"),
            icon: "sendingEnvelope",
            count: reportedCount ?? 0,
            // appearance: "success",
            onClick: () => {
              clearPayoutDateRange();
              setPayoutDateQuick(null);
              // setReportingEnabled(null);
              setReported(EBeneficiaryReportReported.PaymentRequestReported);
              setPaid(null);
            },
          },
          // {
          //   heading: getLocalizedText("reports.beneficiary.payouts.all"),
          //   icon: "logo",
          //   appearance: "default",
          //   count: all ?? 0,
          //   onClick: () => {
          //     setPayoutDateQuick(null);
          //     clearPayoutDateRange();
          //     setReportingEnabled(null);
          //     setReported(null);
          //   },
          // },
        ]}
      />

      <Spacer weight={ESpacerWeight.W16} />

      <Filters
        queryPlaceholder={getLocalizedText(
          "reports.beneficiary.search.placeholder",
        )}
        queryValue={searchQuery ?? ""}
        onQueryChange={searchTerm => {
          onSearchChange(searchTerm);
        }}
        onQueryClear={() => {
          onSearchChange("");
        }}
        filterConfigs={[
          {
            filters: [
              {
                type: EFilterType.SingleSelect,
                options: [EPaidFilter.Paid, EPaidFilter.NotPaid],
                values: paid ? [paid] : [],
                translate: value =>
                  getLocalizedText(
                    `report.beneficiary.filters.payment_status.${(
                      value as string
                    ).toLowerCase()}`,
                  ),
                filterKey: "paid-filter",
                onChange: values => {
                  setPaid(values[0]);
                },
                onRemove: () => setPaid(null),
              },
            ],
            label: getLocalizedText("reports.beneficiary.header.payout"),
            groupKey: "paid",
          },
          {
            filters: [
              {
                type: EFilterType.SingleSelect,
                options: Object.values(EBeneficiaryReportReported),
                values: reported ? [reported] : [],
                translate: value =>
                  getLocalizedText(
                    `reports.beneficiary.filters.custom.${(
                      value as string
                    ).toLowerCase()}`,
                  ),
                filterKey: "reported-filter",
                onChange: values => {
                  setReported(values[0]);
                },
                onRemove: () => setReported(null),
              },
            ],
            label: getLocalizedText("reports.report"),
            groupKey: "report",
          },
          // {
          //   filters: [
          //     {
          //       type: EFilterType.SingleSelect,
          //       options: Object.values(
          //         EBeneficiaryReportReportingEnabledValues,
          //       ),
          //       values: reportingEnabled ? [reportingEnabled] : [],
          //       translate: value =>
          //         getLocalizedText(
          //           `reports.beneficiary.filters.custom.${(value as string).toLowerCase()}`,
          //         ),
          //       filterKey: "reported-filter",
          //       onChange: values => {
          //         setReportingEnabled(
          //           values[0] as EBeneficiaryReportReportingEnabledValues,
          //         );
          //       },
          //       onRemove: () => setReportingEnabled(null),
          //     },
          //   ],
          //   label: getLocalizedText("system.payees"),
          //   groupKey: "beneficiaries",
          // },
          {
            filters: [
              {
                label: getLocalizedText("filters.title.quick_filters"),
                filterKey: "payout-date-quick",
                type: EFilterType.SingleSelect,
                options: Object.values(EDateQuickFilters),
                values: payoutDateQuick ? [payoutDateQuick] : undefined,
                translate: value =>
                  getLocalizedText(`filters.quick_dates.${value}`),
                onChange: quickFilter => {
                  setPayoutDateQuick(quickFilter?.[0]);
                  clearPayoutDateRange();
                },
                onRemove: () => {
                  clearPayoutDateQuick();
                },
                showInAppliedFilters: values =>
                  values?.[0] !== EDateQuickFilters.DateRange,
              },
              {
                label: getLocalizedText("filters.title.date_range"),
                filterKey: "payout-date-range",
                type: EFilterType.MonthRange,
                values: payoutDateRange
                  ? {
                      start:
                        typeof payoutDateRange[0] === "string"
                          ? new Date(payoutDateRange[0])
                          : payoutDateRange[0],
                      end:
                        typeof payoutDateRange[1] === "string"
                          ? new Date(payoutDateRange[1])
                          : payoutDateRange[1],
                    }
                  : undefined,
                minStartDate: BENEFICIARY_REPORTS_CUTOFF_DATE,
                maxStartDate: new Date(),
                minEndDate: BENEFICIARY_REPORTS_CUTOFF_DATE,
                maxEndDate: new Date(),
                translate: (value: any) => translateDateRange(value),
                onRemove: () => {
                  clearPayoutDateRange();
                },
                onChange: (newValues: {
                  start: Date | null;
                  end: Date | null;
                }) => {
                  setPayoutDateRange([newValues.start, newValues.end]);
                  setPayoutDateQuick(EDateQuickFilters.DateRange);
                },
              },
            ],
            label: getLocalizedText(
              "payment_history.filter.payment_date.section.quick.title",
            ),
            groupKey: "payout-date",
          },
        ]}
      />
    </>
  );
}
