var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 100%;\n  width: 100%;\n"], ["\n  position: relative;\n  height: 100%;\n  width: 100%;\n"])));
export var InnerWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  overflow: scroll;\n  height: 100%;\n  width: 100%;\n  padding: 30px 0 120px;\n  display: flex;\n  flex-direction: column;\n"], ["\n  position: absolute;\n  overflow: scroll;\n  height: 100%;\n  width: 100%;\n  padding: 30px 0 120px;\n  display: flex;\n  flex-direction: column;\n"])));
export var TitleWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin: 0 0 24px 8px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin: 0 0 24px 8px;\n"])));
export var SidebarFooterButtonsWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 1;\n  padding: 16px 24px;\n  box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.02);\n  border-top: 1px solid ", ";\n  background-color: ", ";\n"], ["\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 1;\n  padding: 16px 24px;\n  box-shadow: 0px -6px 10px 0px rgba(0, 0, 0, 0.02);\n  border-top: 1px solid ", ";\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global4; }, function (props) { return props.theme.colors.neutral2; });
export var TemplateDocumentEditorInfoWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 0 40px;\n  text-align: center;\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 0 40px;\n  text-align: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
