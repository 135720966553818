import * as templateActions from "@rentiohq/shared-frontend/dist/redux/template/template.actions";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import { ETemplateAccessScope, } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { EField } from "./schema.template.types";
export var isPerformingSubmitActionSelector = function (id) { return function (state) {
    return id
        ? templateSelectors.update.isUpdating(state, id)
        : templateSelectors.create.isCreating(state);
}; };
export var performingSubmitActionResultSelector = function (id) { return function (state) {
    return id
        ? templateSelectors.detail.detail(state, id)
        : templateSelectors.create.lastCreatedId(state);
}; };
export var performingSubmitActionErrorSelector = function (id) { return function (state) {
    return id
        ? templateSelectors.detail.fetchError(state, id)
        : templateSelectors.create.createError(state);
}; };
export var submitActionCreator = function (id) { return function (formData) {
    var data = {
        accessScope: formData[EField.AccessScope] || ETemplateAccessScope.Private,
        name: formData[EField.Name],
        type: formData[EField.Type],
        tags: formData[EField.Tags],
        country: formData[EField.Country] || null,
        region: formData[EField.Region] || null,
        availableFrom: formData[EField.AvailableFrom] || null,
        availableTo: formData[EField.AvailableTo] || null,
    };
    if (id) {
        return templateActions.update.actions.start({ id: id, data: data });
    }
    return templateActions.create.actions.start({ data: data });
}; };
