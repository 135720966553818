import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import PropertyListItemHeader, {
  IPropertyListAddressSortProps,
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";
import { ESortOrder, OrderChangeFunction } from "../SortableResourceListHeader";
import {
  WIDTH_CONTRACT_DATE,
  WIDTH_OWNERS,
  WIDTH_TENANTS,
} from "./PropertyListItemManagement.constants";
import * as S from "./PropertyListItemManagement.styles";
interface IPropertyListItemManagementHeaderProps
  extends IPropertyListAddressSortProps {
  contractStartDateOrder: ESortOrder | undefined;
  contractStartDateOnOrderChange: OrderChangeFunction;
}

const PropertyListItemManagementHeader = ({
  contractStartDateOrder,
  contractStartDateOnOrderChange,
  addressOnChangeOrder,
  addressOrder,
}: IPropertyListItemManagementHeaderProps) => (
  <PropertyListItemHeader
    addressOnChangeOrder={addressOnChangeOrder}
    addressOrder={addressOrder}
  >
    <PropertyListItemItem
      text={getLocalizedText("properties.owners")}
      style={{ width: WIDTH_OWNERS }}
      contentStyle={{ justifyContent: "center" }}
    />

    <PropertyListItemSpacer />

    <PropertyListItemItem
      text={getLocalizedText("properties.tenants")}
      style={{ width: WIDTH_TENANTS }}
    />

    <S.StartDateWrap>
      <PropertyListItemItem
        text={getLocalizedText("contract.startdate")}
        style={{
          width: WIDTH_CONTRACT_DATE,
        }}
        sortable={{
          order: contractStartDateOrder,
          onOrderChange: contractStartDateOnOrderChange,
        }}
      />
    </S.StartDateWrap>

    <S.EndDateWrap>
      <PropertyListItemItem
        text={getLocalizedText("contract.enddate")}
        style={{ width: WIDTH_CONTRACT_DATE }}
      />
    </S.EndDateWrap>

    <PropertyListItemSpacer />

    {[
      "properties.open_payment_requests",
      "properties.indexation",
      "properties.tasks",
      "properties.legal",
    ].map(key => (
      <PropertyListItemItem
        key={key}
        text={getLocalizedText(key)}
        style={{ flex: 1 }}
        contentStyle={{ justifyContent: "center" }}
      />
    ))}
  </PropertyListItemHeader>
);

export default PropertyListItemManagementHeader;
