export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["Info"] = "info";
    EField["TenantAmount"] = "tenantAmount";
    EField["TenantBankAccountId"] = "tenantBankAccountId";
    EField["OwnerAmount"] = "ownerAmount";
    EField["OwnerBankAccountId"] = "ownerBankAccountId";
    EField["BrokerAmount"] = "brokerAmount";
    EField["BrokerBankAccountId"] = "brokerBankAccountId";
    EField["Signers"] = "signers";
    EField["SignAsBroker"] = "signAsBroker";
    EField["Broker"] = "broker";
    EField["TenantReceiver"] = "tenantReceiver";
    EField["OwnerReceiver"] = "ownerReceiver";
    EField["JudicialRelease"] = "judicialRelease";
})(EField || (EField = {}));
