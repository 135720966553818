var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EPaymentOrderOwnerPaymentMethod, EPaymentOrderType, } from "@rentiohq/shared/dist/types/payment.types";
import { isAfter } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { getSchema as getSchema0, uiSchema as uiSchema0, } from "./schema.createPayment.0";
import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.createPayment.1";
import { getSchema as getSchema12, uiSchema as uiSchema12, } from "./schema.createPayment.12";
import { getSchema as getSchema12a, uiSchema as uiSchema12a, } from "./schema.createPayment.12a";
import { getSchema as getSchema13, getValidate as getValidate13, uiSchema as uiSchema13, } from "./schema.createPayment.13";
import { getSchema as getSchema15, uiSchema as uiSchema15, } from "./schema.createPayment.15";
import { getSchema as getSchema16, uiSchema as uiSchema16, } from "./schema.createPayment.16";
import { getSchema as getSchema17, getValidate as getValidate17, uiSchema as uiSchema17, } from "./schema.createPayment.17";
import { getSchema as getSchema18, getValidate as getValidate18, uiSchema as uiSchema18, } from "./schema.createPayment.18";
import { getSchema as getSchema2a, getValidate as getValidate2a, uiSchema as uiSchema2a, } from "./schema.createPayment.2a";
import { getSchema as getSchema2b, uiSchema as uiSchema2b, } from "./schema.createPayment.2b";
import { getSchema as getSchema3, uiSchema as uiSchema3, } from "./schema.createPayment.3";
import { getSchema as getSchema4, uiSchema as uiSchema4, } from "./schema.createPayment.4";
import { getSchema as getSchema4b, uiSchema as uiSchema4b, } from "./schema.createPayment.4b";
import { getSchema as getSchema5, uiSchema as uiSchema5, } from "./schema.createPayment.5";
import { getSchema as getSchema6, getValidate as getValidate6, uiSchema as uiSchema6, } from "./schema.createPayment.6";
import { getSchema as getSchema7, uiSchema as uiSchema7, } from "./schema.createPayment.7";
import { getSchema as getSchema8, uiSchema as uiSchema8, } from "./schema.createPayment.8";
import { getSchema as getSchema9, getValidate as getValidate9, uiSchema as uiSchema9, } from "./schema.createPayment.9";
import { getSchema as getSchema9a, uiSchema as uiSchema9a, } from "./schema.createPayment.9a";
import { EField } from "./schema.createPayment.types";
import { getInitialValues, getRelatedContract, hasProRata, isNewContract, shouldAskOwnerPaymentMethod, } from "./schema.createPayment.utils";
var NOW = new Date();
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    var isBroker = extraData.isBroker, _a = extraData.hasBookkeeping, hasBookkeeping = _a === void 0 ? false : _a, paymentOrder = extraData.paymentOrder, endedContracts = extraData.endedContracts, activeContracts = extraData.activeContracts;
    return __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        {
            schema: getSchema0(initialValues, extraData),
            uiSchema: uiSchema0(extraData),
        },
        {
            schema: getSchema17(initialValues, extraData),
            uiSchema: uiSchema17(extraData),
            validate: getValidate17(extraData),
            showIf: function (formData) {
                return __spreadArray(__spreadArray([], (endedContracts || []), true), (activeContracts || []), true).length > 0;
            },
        },
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: uiSchema1(extraData),
        },
        {
            schema: getSchema2a(initialValues, extraData),
            uiSchema: uiSchema2a(initialValues, extraData),
            validate: getValidate2a(extraData),
        },
        {
            schema: getSchema2b(initialValues, extraData),
            uiSchema: uiSchema2b(initialValues, extraData),
        },
        {
            schema: getSchema3(initialValues, extraData),
            uiSchema: uiSchema3(initialValues, extraData),
            showIf: function (formData) {
                // Don't show if not non-recurring
                if (!formData[EField.TypeRecurring]) {
                    return false;
                }
                // Don't show if old contract
                var contract = getRelatedContract(extraData, formData);
                if (contract && !isNewContract(contract)) {
                    return false;
                }
                // Don't show if no pro-rata
                if (!hasProRata(formData, contract)) {
                    return false;
                }
                // Update
                if (paymentOrder) {
                    var firstPaymentAt = paymentOrder.firstPaymentAt, firstPayDateAt = paymentOrder.firstPayDateAt;
                    // Is already paid
                    if (firstPaymentAt) {
                        return false;
                    }
                    // First paydate has passed
                    if (isAfter(NOW, firstPayDateAt)) {
                        return false;
                    }
                }
                return true;
            },
        },
        {
            schema: getSchema4(initialValues, extraData),
            uiSchema: uiSchema4(extraData),
        },
        {
            schema: getSchema15(initialValues, extraData),
            uiSchema: uiSchema15(extraData),
            showIf: function (formData) {
                return !!shouldAskOwnerPaymentMethod(formData, extraData);
            },
        },
        {
            schema: getSchema4b(initialValues, extraData),
            uiSchema: uiSchema4b(extraData),
            showIf: function (formData) {
                var shouldAsk = shouldAskOwnerPaymentMethod(formData, extraData);
                var ownerPaymentMethod = formData[EField.OwnerPaymentMethod];
                return (!shouldAsk ||
                    ownerPaymentMethod !== EPaymentOrderOwnerPaymentMethod.FromRent);
            },
        },
        {
            schema: getSchema5(initialValues, extraData),
            uiSchema: uiSchema5(extraData),
        },
        {
            schema: getSchema6(initialValues, extraData),
            uiSchema: uiSchema6(extraData),
            validate: getValidate6(extraData),
        }
    ], (extraData.paymentOrder && extraData.hasOpenPaymentRequests
        ? [
            {
                schema: getSchema16(initialValues, extraData),
                uiSchema: uiSchema16(extraData),
            },
        ]
        : []), true), (extraData.paymentOrder || !isBroker
        ? []
        : [
            {
                schema: getSchema7(initialValues, extraData),
                uiSchema: uiSchema7(extraData),
                showIf: function (formData) {
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(formData[EField.TypeRecurring]);
                },
            },
            {
                schema: getSchema8(initialValues, extraData),
                uiSchema: uiSchema8(extraData),
                showIf: function (formData) {
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(formData[EField.TypeRecurring]) &&
                        formData[EField.IncludeCommission] === "1";
                },
            },
            {
                schema: getSchema9a(initialValues, extraData),
                uiSchema: uiSchema9a(extraData),
                showIf: function (formData) {
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(formData[EField.TypeRecurring]) &&
                        formData[EField.IncludeCommission] === "1";
                },
            },
            {
                schema: getSchema9(initialValues, extraData),
                uiSchema: uiSchema9(extraData),
                validate: getValidate9(extraData),
                showIf: function (formData) {
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(formData[EField.TypeRecurring]) &&
                        formData[EField.IncludeCommission] === "1";
                },
            },
        ]), true), [
        {
            schema: getSchema18(initialValues, extraData),
            uiSchema: uiSchema18(extraData),
            validate: getValidate18(extraData),
            showIf: function (formData) {
                return hasBookkeeping &&
                    (formData[EField.TypeRecurring] === EPaymentOrderType.OtherRecurring ||
                        formData[EField.TypeOnce] === EPaymentOrderType.OtherOneoff);
            },
        }
    ], false), (extraData.paymentOrder || !isBroker
        ? []
        : [
            {
                schema: getSchema12(initialValues, extraData),
                uiSchema: uiSchema12(extraData),
                showIf: function (formData) {
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(formData[EField.TypeRecurring]) &&
                        formData[EField.IncludeManagementFee] === "1";
                },
                // formData[EField.ManagementFeeType] === "FIXED"
            },
            {
                schema: getSchema12a(initialValues, extraData),
                uiSchema: uiSchema12a(extraData),
                showIf: function (formData) {
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(formData[EField.TypeRecurring]) &&
                        formData[EField.IncludeManagementFee] === "1";
                },
            },
            {
                schema: getSchema13(initialValues, extraData),
                uiSchema: uiSchema13(extraData),
                validate: getValidate13(extraData),
                showIf: function (formData) {
                    return [
                        EPaymentOrderType.RentAndCosts,
                        EPaymentOrderType.Rent,
                        EPaymentOrderType.CommonCosts,
                        EPaymentOrderType.NonCommonCosts,
                    ].includes(formData[EField.TypeRecurring]) &&
                        formData[EField.IncludeManagementFee] === "1";
                },
            },
        ]), true);
});
