import { IDocumentPackage } from "@rentiohq/shared/dist/types/documentPackage.types";

export interface IDocumentPackageProps {
  documentPackageId: string;
  onDrawerClose: () => void;
}

export interface IDocumentPackageTabProps {
  documentPackage: IDocumentPackage;
  setTab: (tab: EDocumentPackageTabs) => void;
}

export enum EDocumentPackageTabs {
  Info,
  History,
}
