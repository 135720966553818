export var EIntakeStep;
(function (EIntakeStep) {
    EIntakeStep[EIntakeStep["candidateVisitor"] = 1] = "candidateVisitor";
    EIntakeStep[EIntakeStep["candidateTenant"] = 2] = "candidateTenant";
    EIntakeStep[EIntakeStep["tenant"] = 3] = "tenant";
    EIntakeStep[EIntakeStep["notEligible"] = 4] = "notEligible";
    EIntakeStep[EIntakeStep["visitor"] = 5] = "visitor";
    EIntakeStep[EIntakeStep["planAsVisitor"] = 6] = "planAsVisitor";
    EIntakeStep[EIntakeStep["waitingList"] = 7] = "waitingList";
})(EIntakeStep || (EIntakeStep = {}));
