export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["RecurrenceType"] = "recurrenceType";
    EField["RecurrenceSelection"] = "recurrenceSelection";
    EField["Title"] = "title";
    EField["Subtitle"] = "_subtitle";
    EField["Description"] = "description";
    // Note = "note",
    EField["Executors"] = "executors";
    EField["Followers"] = "followers";
    EField["ExecuteDate"] = "executeDate";
    EField["DueDate"] = "dueDate";
    EField["ReminderType"] = "reminderType";
    EField["ReminderOption"] = "reminderOption";
    EField["RecurrenceEndDate"] = "RecurrenceEndDate";
    EField["Reminder"] = "reminder";
    EField["NextReminderDate"] = "nextReminderDate";
    EField["PropertySelect"] = "propertySelect";
    EField["DocumentSelect"] = "documentSelect";
    EField["IncidentDate"] = "incidentDate";
    EField["DamageType"] = "damageType";
    EField["ContactPerson"] = "contactPerson";
    EField["Payer"] = "payer";
    EField["Type"] = "taskType";
    EField["Location"] = "location";
    EField["Category"] = "category";
})(EField || (EField = {}));
