import styled, { spacing } from "@rentiohq/web-theme";

export const AccountName = styled.div<{ isContact?: boolean }>`
  ${({ isContact }) =>
    isContact
      ? `&:hover {
    cursor: pointer;
    text-decoration: none;
  }`
      : ""}
  padding-right: ${spacing("tight")};
`;

export const DotSeparator = styled.div`
  padding-right: ${spacing("tight")};
`;

export const CommentCreatedAt = styled.div`
  padding-right: ${spacing("tight")};
`;
export const CommentContent = styled.div`
  margin-top: ${spacing("tight")};
`;

export const IconWrap = styled.div`
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;
