export var EField;
(function (EField) {
    EField["Name"] = "name";
    EField["NameNew"] = "nameNew";
    EField["Group"] = "group";
    EField["GroupNew"] = "groupNew";
    EField["Required"] = "required";
    EField["Type"] = "type";
    EField["DefaultText"] = "defaultText";
    EField["IsMultilineText"] = "isMultilineText";
    EField["DefaultDate"] = "defaultDate";
    EField["DefaultDateFormatting"] = "defaultDateFormatting";
    EField["MinDate"] = "minDate";
    EField["MaxDate"] = "maxDate";
    EField["DefaultNumber"] = "defaultNumber";
    EField["MinNumber"] = "minNumber";
    EField["MaxNumber"] = "maxNumber";
    EField["DefaultToggle"] = "defaultToggle";
    EField["DropdownValues"] = "dropdownValues";
    EField["Persisted"] = "persisted";
})(EField || (EField = {}));
