import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { Drawer } from "@rentiohq/web-shared/dist/components";
import InvoiceItem from "@rentiohq/web-shared/dist/components/DetailDrawers/InvoiceItem";
import { NumberParam, StringParam } from "serialize-query-params";
import { CancelledContract } from "./Drawers/CancelledContract";
import Document from "./Drawers/Document";
import { DocumentPackage } from "./Drawers/DocumentPackage";
import Insurance from "./Drawers/Insurance";
import LegalCaseInfo from "./Drawers/LegalCase/LegalCase";
import MoveRequest from "./Drawers/MoveRequest";
import { PaymentOrder } from "./Drawers/PaymentOrder";
import PaymentPayIn from "./Drawers/PaymentPayIn";
import PaymentRequest from "./Drawers/PaymentRequest";
import Registration from "./Drawers/Registration";
import RentDeposit from "./Drawers/RentDeposit";
import TemplateDocument from "./Drawers/TemplateDocument";

export const DetailDrawers = () => {
  const [queryParams, setQueryParamValue] = useQueryParams({
    documentPackageId: StringParam,
    documentId: StringParam,
    paymentOrderId: NumberParam,
    paymentRequestId: NumberParam,
    rentDepositId: NumberParam,
    registrationId: NumberParam,
    templateDocumentId: StringParam,
    invoiceItemId: NumberParam,
    moveRequestId: NumberParam,
    insuranceContractId: StringParam,
    payInId: NumberParam,
    legalCaseId: StringParam,
    cancelledContractId: StringParam,
  });

  const handleDrawerClose = (key: string) => () => {
    setQueryParamValue({ [key]: undefined });
  };

  return (
    <>
      <Drawer
        isOpen={!!queryParams.moveRequestId}
        onClose={handleDrawerClose("moveRequestId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.moveRequestId && (
          <MoveRequest
            moveRequestId={queryParams.moveRequestId}
            onDrawerClose={handleDrawerClose("moveRequestId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.templateDocumentId}
        onClose={handleDrawerClose("templateDocumentId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.templateDocumentId && (
          <TemplateDocument
            templateDocumentId={queryParams.templateDocumentId}
            onDrawerClose={handleDrawerClose("templateDocumentId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.invoiceItemId}
        onClose={handleDrawerClose("invoiceItemId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.invoiceItemId && (
          <InvoiceItem
            invoiceItemId={queryParams.invoiceItemId}
            onDrawerClose={handleDrawerClose("invoiceItemId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.documentId}
        onClose={handleDrawerClose("documentId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.documentId && (
          <Document
            documentId={queryParams.documentId}
            onDrawerClose={handleDrawerClose("documentId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.documentPackageId}
        onClose={handleDrawerClose("documentPackageId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.documentPackageId && (
          <DocumentPackage
            documentPackageId={queryParams.documentPackageId}
            onDrawerClose={handleDrawerClose("documentPackageId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.paymentOrderId}
        onClose={handleDrawerClose("paymentOrderId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.paymentOrderId && (
          <PaymentOrder
            paymentOrderId={queryParams.paymentOrderId}
            onDrawerClose={handleDrawerClose("paymentOrderId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.paymentRequestId}
        onClose={handleDrawerClose("paymentRequestId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.paymentRequestId && (
          <PaymentRequest
            paymentRequestId={queryParams.paymentRequestId}
            onDrawerClose={handleDrawerClose("paymentRequestId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.rentDepositId}
        onClose={handleDrawerClose("rentDepositId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.rentDepositId && (
          <RentDeposit
            rentDepositId={queryParams.rentDepositId}
            onDrawerClose={handleDrawerClose("rentDepositId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.registrationId}
        onClose={handleDrawerClose("registrationId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.registrationId && (
          <Registration
            registrationId={queryParams.registrationId}
            onDrawerClose={handleDrawerClose("registrationId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.insuranceContractId}
        onClose={handleDrawerClose("insuranceContractId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.insuranceContractId && (
          <Insurance
            contractId={queryParams.insuranceContractId}
            onDrawerClose={handleDrawerClose("insuranceContractId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.payInId}
        onClose={handleDrawerClose("payInId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.payInId && (
          <PaymentPayIn
            payInId={queryParams.payInId}
            onDrawerClose={handleDrawerClose("payInId")}
          />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.legalCaseId}
        onClose={handleDrawerClose("legalCaseId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.legalCaseId && (
          <LegalCaseInfo legalCaseId={queryParams.legalCaseId} />
        )}
      </Drawer>

      <Drawer
        isOpen={!!queryParams.cancelledContractId}
        onClose={handleDrawerClose("cancelledContractId")}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        {queryParams.cancelledContractId && (
          <CancelledContract
            cancelledContractId={queryParams.cancelledContractId}
          />
        )}
      </Drawer>
    </>
  );
};
