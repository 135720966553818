import { Box } from "@rebass/grid";
import PropertyFetchListItem from "@rentiohq/shared-frontend/dist/components/components/PropertyFetchListItem";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ESpacings,
  Grid,
  Icon,
  Loading,
  Lozenge,
  Page,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../services";
import { documentPackageUtils } from "../../../../utils";
import {
  checkIfRevokable,
  getSignerInfo,
} from "../../../../utils/documentPackage.utils";
import {
  EDocumentPackageTabs,
  IDocumentPackageProps,
} from "./DocumentPackage.types";
import { DocumentPackageHistory } from "./components/DocumentPackageHistory";
import { DocumentPackageInfo } from "./components/DocumentPackageInfo";

export const DocumentPackage: React.FC<IDocumentPackageProps> = ({
  documentPackageId,
  onDrawerClose,
}) => {
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState<EDocumentPackageTabs>(
    EDocumentPackageTabs.Info,
  );
  const documentPackage = useSelector((state: IRootStore) =>
    documentPackageSelectors.getDocumentPackage(state, documentPackageId),
  );
  const isFetchingDocumentPackage = useSelector((state: IRootStore) =>
    documentPackageSelectors.isFetchingDocumentPackage(
      state,
      documentPackageId,
    ),
  );
  const isRevokingDocumentPackage = useSelector(
    (state: IRootStore) => state.documentPackage.isRevokingDocumentPackage,
  );
  const prevIsRevokingDocumentPackage = usePrevious(isRevokingDocumentPackage);
  const isRevokable = documentPackage && checkIfRevokable(documentPackage);

  React.useEffect(() => {
    dispatch(
      documentPackageActions.getDocumentPackage.actions.start({
        documentPackageId,
      }),
    );
  }, [documentPackageId]);

  React.useEffect(() => {
    if (!isRevokingDocumentPackage && prevIsRevokingDocumentPackage) {
      onDrawerClose();
    }
  }, [isRevokingDocumentPackage, onDrawerClose, prevIsRevokingDocumentPackage]);

  if (isFetchingDocumentPackage) {
    return <Loading />;
  }

  if (!documentPackage) {
    return null;
  }

  const handleInfoTabClick = () => {
    setTab(EDocumentPackageTabs.Info);
  };

  const handleHistoryTabClick = () => {
    setTab(EDocumentPackageTabs.History);
  };

  let tabs = [
    {
      name: "dashboard",
      // permission: 'propertyDashboard:visit',
      content: ts.documentPackageDetailTabsInfo(),
      onClick: handleInfoTabClick,
      isActive: tab === EDocumentPackageTabs.Info,
    },
    {
      name: "payments",
      // permission: 'propertyPayments:visit',
      content: ts.documentPackageDetailTabsHistory(),
      onClick: handleHistoryTabClick,
      isActive: tab === EDocumentPackageTabs.History,
    },
  ];

  const handleRevokeClick = () => {
    confirm({
      title: getLocalizedText("document_package.revoke.action.confirm.title"),
      primaryActions: [
        {
          title: ts.documentPackageRevokeAction(),
          onPress: () => {
            dispatch(
              documentPackageActions.revokeDocumentPackage.actions.start({
                documentPackageId,
              }),
            );
          },
        },
      ],
    });
  };

  const renderInfo = () => (
    <>
      <DocumentPackageInfo documentPackage={documentPackage} setTab={setTab} />
      {isRevokable && (
        <Box mt={ESpacings.extraLoose}>
          <Button
            appearance="outline"
            color="red"
            onClick={handleRevokeClick}
            isSubmitting={isRevokingDocumentPackage}
          >
            {ts.documentPackageRevokeAction()}
          </Button>
        </Box>
      )}
    </>
  );
  const renderHistory = () => (
    <DocumentPackageHistory documentPackage={documentPackage} setTab={setTab} />
  );

  const renderSubtitle = () => {
    const simpleStage =
      documentPackage.status &&
      documentPackageUtils.getSimpleStages(documentPackage.status);
    const { signedCount } = getSignerInfo(documentPackage);

    return (
      <Lozenge
        isBold
        key={`${documentPackage.id}-payment-status`}
        appearance={documentPackageUtils.getSimpleStageVariation(simpleStage!)}
      >
        {ts.followUpDocumentPackagesFilterStageLabel({
          extra: { key: simpleStage },
        })}
        :{` ${signedCount}/${documentPackage.signers.length}`}
      </Lozenge>
    );
  };

  const renderMeta = () => {
    return (
      <div>
        {documentPackage.propertyId && (
          <>
            <Spacer weight={ESpacerWeight.W08} />

            <PropertyFetchListItem
              propertyId={documentPackage.propertyId}
              property={documentPackage.property}
              renderAlways={property => {
                if (!property) {
                  return null;
                }

                return (
                  <Grid spacing="extraTight" alignItems="center">
                    <Grid.Item>
                      <Icon
                        source={utils.properties.getIcon(property.typeId || 0)}
                        size="small"
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        appearance="link"
                        url={`/properties/${documentPackage.propertyId}`}
                      >
                        {formatAddress(property)}
                      </Button>
                    </Grid.Item>
                  </Grid>
                );
              }}
            />
          </>
        )}

        <Spacer weight={ESpacerWeight.W08} />
        <Box>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="calendar" size="small" />
            </Grid.Item>
            <Grid.Item>
              {ts.system("drawn_up_at")}:{" "}
              {utils.date.format(documentPackage.createdAt, "dd/MM/yyyy")}
            </Grid.Item>
          </Grid>
        </Box>
      </div>
    );
  };

  return (
    <Page
      title={documentPackage.name}
      subtitle={renderSubtitle()}
      metadata={renderMeta()}
      tabs={tabs}
      setDocumentTitle={false}
    >
      <>
        {tab === EDocumentPackageTabs.Info && renderInfo()}
        {tab === EDocumentPackageTabs.History && renderHistory()}
      </>
    </Page>
  );
};
