import { getSchema as documentsSchema, uiSchema as documentsUiSchema, } from "./schema.addLegalCase.1";
import { getSchema as latePaymentsSchema, uiSchema as latePaymentsUiSchema, getValidate as latePaymentsValidate, } from "./schema.addLegalCase.2";
import { getSchema as damagesSchema, uiSchema as damagesUiSchema, } from "./schema.addLegalCase.3";
import { getSchema as extraInfoSchema, uiSchema as extraInfoUiSchema, } from "./schema.addLegalCase.4";
import { getInitialValues } from "./schema.addLegalCase.utils";
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: documentsSchema(initialValues, extraData),
            uiSchema: documentsUiSchema(extraData),
        },
        {
            schema: latePaymentsSchema(initialValues, extraData),
            uiSchema: latePaymentsUiSchema(extraData),
            validate: latePaymentsValidate(extraData),
        },
        {
            schema: damagesSchema(initialValues, extraData),
            uiSchema: damagesUiSchema(extraData),
        },
        {
            schema: extraInfoSchema(initialValues, extraData),
            uiSchema: extraInfoUiSchema(extraData),
        },
    ];
});
