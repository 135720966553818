type TRouteArgument = number | string;

export const createBasicContractInfoRoute = (propertyId: TRouteArgument) => {
  return `/properties/${propertyId}/contracts/add`;
};

export const updateBasicContractInfoRoute = (
  propertyId: TRouteArgument,
  contractId: TRouteArgument,
) => {
  return `/properties/${propertyId}/contracts/${contractId}/edit`;
};
export const createMandateRoute = (propertyId: TRouteArgument) => {
  return `/properties/${propertyId}/contracts/mandate`;
};

export const updateMandateRoute = (
  propertyId: TRouteArgument,
  contractId: TRouteArgument,
) => {
  return `/properties/${propertyId}/contracts/mandate/${contractId}/edit`;
};

export const createRentDepositRoute = (
  propertyId: TRouteArgument,
  basicContractId: TRouteArgument,
) => {
  return `/properties/${propertyId}/contracts/${basicContractId}/rent-deposit`;
};

export const createRentDepositReleaseRoute = (
  propertyId: TRouteArgument,
  rentDepositId: TRouteArgument,
) => {
  return `/properties/${propertyId}/rent-deposit/${rentDepositId}/release`;
};
