export var mapItemsToDataTableProps = function (items, params) {
    var _a = (params || {}).hasActions, hasActions = _a === void 0 ? false : _a;
    var headings = items.map(function (item) { return item.heading; });
    var columnContentTypes = items.map(function (item) { return item.columnContentType; });
    var sortable = items.map(function (item) { return item.sortable || false; });
    var orderMap = items.map(function (item) { return item.order || undefined; });
    if (hasActions) {
        headings.push("");
        columnContentTypes.push("action");
        sortable.push(false);
        orderMap.push(undefined);
    }
    return {
        headings: headings,
        columnContentTypes: columnContentTypes,
        sortable: sortable,
        orderMap: orderMap,
    };
};
