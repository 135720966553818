import { Box } from "@rebass/grid";
import { ESpacings, Icon, Tooltip } from "@rentiohq/web-shared/dist/components";
import { memo } from "react";
interface IProps {
  children: React.ReactNode;
  renewalInfo?: string;
}

const InfoCircle = ({ children, renewalInfo }: IProps) => {
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Box
        style={{
          marginRight: ESpacings.tight,
          wordBreak: "break-all",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </Box>
      {renewalInfo && (
        <Tooltip tooltipContent={renewalInfo}>
          <Icon source="informationCircle" size="small" />
        </Tooltip>
      )}
    </Box>
  );
};

export const MemoizedInfoCircle = memo(InfoCircle);
