import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import {
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import { ts } from "../../../../../../services";

interface IProps {
  contract: IContract;
}

export const CancelledContractHistory = ({ contract }: IProps) => {
  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`contract-cancellation-${contract.id}`}
        hasFetchMore={true}
        isCompact={true}
        filter={{
          where: {
            contractId: contract.id,
          },
        }}
      />
    </Card>
  );
};
