var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EPropertyEPCLabel, EPropertyMemberTypes, EPropertyTypeId, } from "@rentiohq/shared/dist/types/property.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField as EEpcField } from "../epc/schema.epcInfo.types";
import { EField } from "./schema.createProperty.types";
export var getNavigationTitle = function (_a) {
    var propertyId = _a.propertyId;
    if (propertyId) {
        return getLocalizedText("properties.edit");
    }
    return getLocalizedText("properties.new");
};
export var getInitialValues = function (_a) {
    var _b, _c, _d, _e;
    var isDuplicate = _a.isDuplicate, _f = _a.owners, owners = _f === void 0 ? [] : _f, property = _a.property, propertyId = _a.propertyId, parentId = _a.parentId, isGroup = _a.isGroup;
    var epcValues = (_b = {},
        _b[EEpcField.Label] = (property === null || property === void 0 ? void 0 : property.epcLabel) || EPropertyEPCLabel.Unknown,
        _b[EEpcField.Value] = (property === null || property === void 0 ? void 0 : property.epcValue) || undefined,
        _b[EEpcField.ExpirationDate] = (property === null || property === void 0 ? void 0 : property.epcExpirationDate) || undefined,
        _b);
    if (isDuplicate && property) {
        return __assign((_c = {}, _c[EField.PropertyType] = property.typeId && !!EPropertyTypeId[property.typeId]
            ? property.typeId
            : undefined, _c[EField.Address] = {
            street: property.street,
            number: property.number,
            box: property.box,
            zip: property.zip,
            city: property.city,
            country: property.countryId,
            state: property.state,
        }, _c[EField.Name] = property.name, _c[EField.Owners] = property.members
            .filter(function (member) { return member.roles.includes(EPropertyMemberTypes.Owner); })
            .map(function (member) { return member.account.id; }), _c[EField.Roles] = property.members
            .map(function (member) {
            var result = [];
            if (member.roles.includes(EPropertyMemberTypes.FinancialManager)) {
                result.push("".concat(member.account.id, "-").concat(EPropertyMemberTypes.FinancialManager));
            }
            if (member.roles.includes(EPropertyMemberTypes.TechnicalManager)) {
                result.push("".concat(member.account.id, "-").concat(EPropertyMemberTypes.TechnicalManager));
            }
            if (member.roles.includes(EPropertyMemberTypes.IndexationManager)) {
                result.push("".concat(member.account.id, "-").concat(EPropertyMemberTypes.IndexationManager));
            }
            return result;
        })
            .flat(), _c[EField.Group] = property.parentId, _c), epcValues);
    }
    if (property) {
        return __assign((_d = {}, _d[EField.PropertyType] = property.typeId && !!EPropertyTypeId[property.typeId]
            ? property.typeId
            : undefined, _d[EField.Address] = {
            street: property.street,
            number: property.number,
            box: property.box,
            zip: property.zip,
            city: property.city,
            country: property.countryId,
            state: property.state,
        }, _d[EField.Name] = property.name, _d[EField.Group] = property.parentId, _d), epcValues);
    }
    return __assign((_e = {}, _e[EField.Owners] = owners, _e[EField.Group] = parentId, _e[EField.PropertyType] = isGroup ? EPropertyTypeId.Group : undefined, _e), epcValues);
};
