import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import PropertyListItemHeader, {
  IPropertyListAddressSortProps,
  PropertyListItemItem,
} from "../PropertyListItem/PropertyListItem.header";
import { PropertyListItemSpacer } from "../PropertyListItem/PropertyListItem.styles";
import { ESortOrder, OrderChangeFunction } from "../SortableResourceListHeader";

interface IProps extends IPropertyListAddressSortProps {
  contractStartDateOrder: ESortOrder | undefined;
  contractStartDateOnOrderChange: OrderChangeFunction;
}

export const PropertyListItemContractsHeader = ({
  contractStartDateOrder,
  contractStartDateOnOrderChange,
  addressOnChangeOrder,
  addressOrder,
}: IProps) => {
  return (
    <PropertyListItemHeader
      addressOnChangeOrder={addressOnChangeOrder}
      addressOrder={addressOrder}
    >
      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.tenants")}
        style={{ flex: 1 }}
      />
      <PropertyListItemItem
        text={getLocalizedText("system.amount")}
        style={{ flex: 1 }}
      />

      <PropertyListItemItem
        text={getLocalizedText("contract.startdate")}
        style={{ flex: 1 }}
        sortable={{
          order: contractStartDateOrder,
          onOrderChange: contractStartDateOnOrderChange,
        }}
      />

      <PropertyListItemItem
        text={getLocalizedText("contract.enddate")}
        style={{ flex: 1 }}
      />
      <PropertyListItemItem
        text={getLocalizedText("system.type")}
        style={{ flex: 1 }}
      />
      <PropertyListItemItem
        text={getLocalizedText("contract_info.create.duration.heading")}
        style={{ flex: 1 }}
      />
      <PropertyListItemItem
        text={getLocalizedText("contract.renewal_deadline.short")}
        style={{ flex: 1 }}
      />
      <PropertyListItemItem
        text={getLocalizedText("system.status")}
        style={{ flex: 1 }}
      />
      <PropertyListItemItem
        text={""}
        // Width of the "actions" icon to make sure columns align
        style={{ width: 24 }}
      />
    </PropertyListItemHeader>
  );
};
