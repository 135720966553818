import { IPaymentRequest } from "@rentiohq/shared/dist/types/payment.types";

export interface IContainerProps {
  paymentRequestId: number;
  onDrawerClose: () => void;
}

export interface IPaymentRequestProps extends IContainerProps {
  paymentRequest: IPaymentRequest;
}

export enum EPaymentRequestTabs {
  Info,
  History,
}
