import { ERentioFormField } from "../../components/components/Form";
import { EField } from "./schema.createPayment.types";
import { getValue } from "../../redux/form/form.utils";
import { getNavigationTitle } from "./schema.createPayment.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.UpdatePaymentRequests],
            properties: (_a = {},
                _a[EField.Header + "16"] = {
                    default: undefined,
                },
                _a[EField.UpdatePaymentRequests] = {
                    default: getValue(EField.UpdatePaymentRequests, initialValues, defaultValues),
                    // @ts-ignore
                    options: ["UPDATE_TRUE", "UPDATE_FALSE"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("payment.payment_order.update_payment_request.".concat(id).toLowerCase()),
                    }); }),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payment.payment_order.update_payment_request.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "16"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.update_payment_request.heading");
            },
        },
        _a[EField.UpdatePaymentRequests] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("payment.payment_order.update_payment_request.label"),
            formatSummary: function (input) {
                return input
                    ? getLocalizedText("payment.payment_order.update_payment_request.".concat(input).toLowerCase())
                    : "";
            },
        },
        _a);
};
