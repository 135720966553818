var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EContractMemberTypes } from "@rentiohq/shared/dist/types/contract.types";
import Ajv from "ajv";
import { uniqBy } from "lodash";
import { ERentioFormField } from "../../components/components/Form";
import * as contactSelectors from "../../redux/contact/contact.selectors";
import { contactSelectInlineV2ValueSelector, formatContactForEdit, getValue, } from "../../redux/form/form.utils";
import { getAllRequiredCompanyFields, getAllRequiredContactFields, } from "../../utils/forms.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getStore } from "../../utils/redux/redux.utils";
import { EField as EContactField } from "../addContact/schema.addContact.types";
import { EField } from "./schema.releaseRentDeposit.types";
import { getNavigationTitle } from "./schema.releaseRentDeposit.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Signers],
            properties: (_a = {},
                _a[EField.Header + "3"] = {
                    default: undefined,
                },
                _a[EField.Signers] = {
                    default: getValue(EField.Signers, initialValues, defaultValues),
                },
                _a[EField.SignAsBroker] = {
                    default: getValue(EField.SignAsBroker, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (_) {
                        return extraData.isBroker &&
                            extraData.legalContact &&
                            extraData.contactMeMaster;
                    },
                    options: (function () {
                        if (!extraData.contactMeMaster) {
                            return [];
                        }
                        return [
                            {
                                id: "".concat(extraData.contactMeMaster.account.id),
                                value: extraData.contactMeMaster.account.company ||
                                    "".concat(extraData.contactMeMaster.account.firstname, " ").concat(extraData.contactMeMaster.account.lastname),
                                subValue: getLocalizedText("role.broker"),
                            },
                        ];
                    })(),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("rent_deposit_release.signers.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "3"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit_release.signers.heading"),
        },
        _a[EField.Signers] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("rent_deposit_release.signers.label"),
            isMultiSelect: true,
            asOptionList: true,
            getRequiredContactFieldsSchema: function (contact) {
                return getAllRequiredContactFields({
                    contact: contact,
                    requiredFields: [
                        EContactField.Address,
                        EContactField.DateOfBirth,
                        EContactField.Nationality,
                        EContactField.PlaceOfBirth,
                    ],
                    appendIdentityNumberToRequiredFields: true,
                });
            },
            getRequiredCompanyFieldsSchema: function (contact) {
                return getAllRequiredCompanyFields({
                    contact: contact,
                    requiredFields: [
                        EContactField.Address,
                        EContactField.DateOfBirth,
                        EContactField.Nationality,
                        EContactField.PlaceOfBirth,
                        EContactField.VATNumber,
                    ],
                    appendIdentityNumberToRequiredFields: true,
                });
            },
            valueSelector: function (state, selectedAccountIds) {
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, extraData.relatedContract.members.map(function (member) { return member.account; }));
            },
            customGetSubtitleSelector: function (state, formData, item) {
                var accountId = item.accountId, contact = item.contact;
                var member = extraData.relatedContract.members.find(function (member) {
                    if (accountId && accountId === member.account.id) {
                        return true;
                    }
                    if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
                        return true;
                    }
                    return false;
                });
                if (!member) {
                    return;
                }
                return member.roles
                    .filter(function (role) { return role !== EContractMemberTypes.Signer; })
                    .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
                    .join(", ");
            },
            customExtraAccountsSelector: function () {
                return extraData.relatedContract.members
                    .filter(function (member) {
                    return member.roles.some(function (role) {
                        return [
                            EContractMemberTypes.Owner,
                            EContractMemberTypes.Tenant,
                            EContractMemberTypes.Parent,
                        ].includes(role);
                    });
                })
                    .map(function (member) { return member.account; });
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a[EField.SignAsBroker] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("document_package.form.sign_as_broker.label"),
            isMultiSelect: true,
            asOptionList: true,
            hideOnSummaryIfEmpty: true,
            formatSummary: function (formData) {
                if (!formData || !extraData.contactMeMaster) {
                    return;
                }
                return (extraData.contactMeMaster.account.company ||
                    "".concat(extraData.contactMeMaster.account.firstname, " ").concat(extraData.contactMeMaster.account.lastname));
            },
        },
        _a);
};
export var getValidate = function (extraData) { return function (formData, errors) {
    var hasSignAsBroker = formData[EField.SignAsBroker] && formData[EField.SignAsBroker].length > 0;
    if (!formData[EField.Signers] && !hasSignAsBroker) {
        errors[EField.Signers].addError(getLocalizedText("rent_deposit.form.signers.error.required"));
    }
    var signers = uniqBy(extraData.relatedContract.members.filter(function (member) {
        return (formData[EField.Signers] || []).includes(member.account.id);
    }), "account.id");
    // Validate if all owners have complete info when broker is selected as signer
    if (hasSignAsBroker) {
        var ajv_1 = new Ajv({ allErrors: true, jsonPointers: true });
        extraData.relatedContract.members.forEach(function (member) {
            if (member.roles.includes(EContractMemberTypes.Owner)) {
                var store = getStore();
                if (!store) {
                    return;
                }
                var contactForMember = contactSelectors.getContact(store.getState(), undefined, member.account.id);
                if (!contactForMember) {
                    return;
                }
                var validateContact = ajv_1.compile(uiSchema(extraData)[EField.Signers].getRequiredContactFieldsSchema(contactForMember));
                var validateCompany = ajv_1.compile(uiSchema(extraData)[EField.Signers].getRequiredCompanyFieldsSchema(contactForMember));
                var validate = !!contactForMember.company
                    ? validateCompany
                    : validateContact;
                if (!validate(formatContactForEdit(contactForMember))) {
                    errors[EField.Signers].addError(getLocalizedText("rent_deposit.form.signers.owner_extra_info.error.required"));
                }
            }
        });
    }
    var allPickedRoles = signers.reduce(function (acc, member) {
        return member.roles ? __spreadArray(__spreadArray([], acc, true), member.roles, true) : __spreadArray(__spreadArray([], acc, true), [], false);
    }, []);
    var hasTenant = allPickedRoles.includes(EContractMemberTypes.Tenant) ||
        allPickedRoles.includes(EContractMemberTypes.Parent);
    var hasOwner = allPickedRoles.includes(EContractMemberTypes.Owner) || hasSignAsBroker;
    if (!hasTenant || !hasOwner) {
        errors[EField.Signers].addError(getLocalizedText("rent_deposit.signers.error.owner_and_tenant.required"));
    }
    return errors;
}; };
