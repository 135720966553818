import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IAccount, IMember } from "@rentiohq/shared/dist/types/auth.types";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { useMemo } from "react";

export interface MemberToShow {
  account: IAccount;
  isEmployee: boolean;
}

interface IMembersWithRoleProps<T extends string> {
  members: IMember<T>[];
  role: T;
}

export default function PropertyMembersWithRole<T extends string>({
  members,
  role,
}: IMembersWithRoleProps<T>) {
  const { broker } = authHooks.useSelf();

  const membersToShow: MemberToShow[] = useMemo(
    () =>
      members.reduce((allWithRole, member) => {
        const employees = member.employeeRoles?.[role];
        if (employees) {
          return [
            ...allWithRole,
            ...employees.map(employee => ({
              account: employee,
              isEmployee: true,
            })),
          ];
        } else {
          if (member.roles.includes(role)) {
            return [
              ...allWithRole,
              { account: member.account, isEmployee: false },
            ];
          }
        }
        return allWithRole;
      }, [] as MemberToShow[]),
    [members],
  );

  if (membersToShow.length === 0) {
    return (
      <ul style={{ listStyle: "none", padding: 0, margin: 0, flex: 1 }}>
        <li>
          <TextStyle variation={"subdued"}>-</TextStyle>
        </li>
      </ul>
    );
  }

  return (
    <ul style={{ listStyle: "none", padding: 0, margin: 0, flex: 1 }}>
      {membersToShow
        .sort(({ account: accountA }, { account: accountB }) => {
          if (accountA.lastname && accountB.lastname)
            return accountA.lastname.localeCompare(accountB.lastname);
          return 0;
        })
        .map(({ account, isEmployee }) => (
          <li>
            {getName(account)}
            {isEmployee && broker && (
              <TextStyle variation={"subdued"}> ({broker.name})</TextStyle>
            )}
          </li>
        ))}
    </ul>
  );
}
