import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import {
  DisplayText,
  ESpacings,
  Grid,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { DocumentOverview } from "../../../../components/Documents";

interface IProps {
  property: IProperty;
}

export const DocumentsOverview: React.FC<IProps> = ({ property }) => (
  <>
    <Grid
      mb={ESpacings.base}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid.Item>
        <DisplayText size="medium">{"Documents"}</DisplayText>
      </Grid.Item>
    </Grid>

    <DocumentOverview
      extraFilterData={{ propertyId: { eq: property.id } }}
      passPropsDocumentDetail={{ propertyId: property.id }}
      withTemplates={true}
    />
  </>
);
