import { Box, Flex } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as commentActions from "@rentiohq/shared-frontend/dist/redux/comment/comment.actions";
import { getContactMeMaster } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import {
  ESpacings,
  ProfilePicture,
  TextField,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../services/translationService";

interface IProps {
  commentIdentifier: string;
  baseUrl: string;
}

const CommentSendBar: React.FC<IProps> = props => {
  const { commentIdentifier, baseUrl } = props;

  // Redux
  const dispatch = useDispatch();

  const isCreatingComment = useSelector(
    (state: IRootStore) => state.comment.isCreatingComment,
  );
  const { user } = authHooks.useSelf();
  const contactMeMaster = useSelector((state: IRootStore) =>
    getContactMeMaster(state),
  );

  // State
  const [query, setQuery] = React.useState<string>("");
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);

  // Event handlers
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      target: { value },
    } = event;
    value.length === 0 ? setQuery("") : setQuery(value);
  };

  const handleSubmit = () => {
    if (query.length > 0) {
      dispatch(
        commentActions.createComment.actions.start({
          content: query,
          commentIdentifier,
          baseUrl,
        }),
      );
      setQuery("");
      dispatch(commentActions.setShowLimit({ showLimit: 5 }));
    }
  };

  // Render
  const profile = contactMeMaster || user;

  let value = query;
  if (!hasFocus) {
    const splitted = query.split("\n")[0];
    value = /\r|\n/.exec(query) ? `${splitted}...` : splitted;
  }

  return (
    <Flex
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="center"
      mb={ESpacings.base}
    >
      {profile && (
        <ProfilePicture
          size="mediumLarge"
          variationProfile="light"
          profile={profile}
        />
      )}
      <Box flex={1} mx={ESpacings.base}>
        <TextField
          multiline={hasFocus}
          size="small"
          name="add-comment"
          value={value}
          placeholder={t.addCommentPlaceholder()}
          onChange={handleChange}
          autoFocus={hasFocus}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
      </Box>
      <Button
        size={"small"}
        appearance="outline"
        onClick={handleSubmit}
        isSubmitting={isCreatingComment}
      >
        {t.systemSubmit()}
      </Button>
    </Flex>
  );
};

export default CommentSendBar;
