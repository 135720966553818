import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
export var getPreferences = function () {
    return api.get("/users/me/preferences");
};
export var setPreferencesForUser = function (data) {
    return api.put("/users/me/preferences", { data: data });
};
export var setPreferencesForBroker = function (data) {
    return api.put("/brokers/me/preferences", { data: data });
};
export var getIndexLastModified = function (url) { return api.head(url); };
export var search = function (search) {
    return api.post("/system/search", { data: { search: search } });
};
