var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appendFilter } from "@rentiohq/shared/dist/utils/api.utils";
import api from "../../utils/api/api.utils";
export var getDetailGenerator = function (paramsGenerator) {
    var resource = paramsGenerator.resource, defaultInclude = paramsGenerator.defaultInclude;
    return function (params) {
        var path = "/".concat(resource, "/").concat(params.id);
        // Build filter
        var filter = {};
        if (defaultInclude) {
            filter.include = defaultInclude;
        }
        if (params.filter) {
            filter = __assign(__assign({}, filter), params.filter);
        }
        // Append filter
        if (Object.keys(filter).length > 0) {
            path = appendFilter(path, filter);
        }
        return api.get(path);
    };
};
