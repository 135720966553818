import { Box } from "@rebass/grid";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as countUtils from "@rentiohq/shared-frontend/dist/redux/count/count.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { canMarkPaid } from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  EPaymentOrderType,
  EPaymentRequestFeature,
  EPaymentRequestStatus,
  EPaymentRequestsDisplayType,
  EPayoutType,
  IPaymentRequest,
} from "@rentiohq/shared/dist/types/payment.types";
import { append } from "@rentiohq/shared/dist/utils/api.utils";
import {
  Card,
  ESpacings,
  Filters,
  Icon,
  OptionListShared,
  Stages,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAppliedFilterInterface } from "@rentiohq/web-shared/dist/components/Filters/Filters.types";
import RentioInternalRenderer from "@rentiohq/web-shared/dist/components/RentioInternalRenderer";
import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { compact } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  EPaymentRequestFilterFrom,
  EPaymentRequestFilterPhase,
  EPaymentRequestFilterStatus,
  getPaymentRequestsFilter,
} from "scenes/FollowUp/Payments/Payments.utils";
import {
  NumberParam,
  StringParam,
  createEnumParam,
} from "serialize-query-params";
import { ts } from "../../services";
import {
  getPaymentOrders,
  isFromRent,
  isRentDiscount,
} from "../../utils/payment";
import { PaymentFollowUpModal } from "../PaymentFollowUpModal";
import { PaymentRequestsGrid } from "./components/PaymentRequestsGrid";
import { PaymentRequestsTable } from "./components/PaymentRequestsTable";
import { ThirdPartyAccountModal } from "./components/ThirdPartyAccountModal";

const PREFERENCE_KEY_PAYMENT_REQUESTS_DISPLAY_TYPE =
  "PAYMENT_REQUESTS_DISPLAY_TYPE";

interface IProps {
  propertyId?: number;
}

export const OpenPaymentRequests: React.FC<IProps> = props => {
  const { propertyId } = props;
  const isPropertyDetail = !!propertyId;

  const [queryParams, setQueryParamValue] = useQueryParams({
    query: StringParam,
    paymentRequestId: NumberParam,
    phase: createEnumParam(Object.values(EPaymentRequestFilterPhase)),
    status: createEnumParam(Object.values(EPaymentRequestFilterStatus)),
    from: createEnumParam(Object.values(EPaymentRequestFilterFrom)),
    page: NumberParam,
  });
  const [queryDebounced] = useDebounce(queryParams.query);

  const [modalFollowUpItem, setModalFollowUpItem] =
    React.useState<IPaymentRequest>();

  const [modalThirdPartyAccountItem, setModalThirdPartyAccountItem] =
    React.useState<IPaymentRequest>();

  // Redux
  const dispatch = useDispatch();

  const paymentRequestsDisplay = useSelector(
    (state: IRootStore) =>
      systemSelectors.getPreference<EPaymentRequestsDisplayType>(
        state,
        PREFERENCE_KEY_PAYMENT_REQUESTS_DISPLAY_TYPE,
      ) || EPaymentRequestsDisplayType.Grid,
  );

  // Hooks
  const { user, broker } = authHooks.useSelf();
  const { internalModeEnabled } = useInternalMode();

  const { count: paymentRequestsIncomingCount } = useCount({
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({
          phase: EPaymentRequestFilterPhase.Incoming,
          propertyId,
        }).where,
      ),
    }),
  });

  const { count: paymentRequestsRentioCount } = useCount({
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({
          phase: EPaymentRequestFilterPhase.Rentio,
          propertyId,
        }).where,
      ),
    }),
  });

  const { count: paymentRequestsActionRequiredCount } = useCount({
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({
          phase: EPaymentRequestFilterPhase.Action,
          propertyId,
        }).where,
      ),
    }),
  });

  // Helpers
  const getPaymentRequestActions = (paymentRequest: IPaymentRequest) => {
    const paymentOrders = getPaymentOrders({
      paymentRequestItems: paymentRequest.paymentRequestItems,
    });

    const hasFromRent = isFromRent(paymentOrders);
    const hasRentDiscount = isRentDiscount(paymentOrders);

    const isEditable = paymentOrders[0]?.isEditable;

    const hasThirdPartyAction =
      isEditable &&
      paymentRequest.payoutType === EPayoutType.Charge &&
      [EPaymentRequestStatus.New, EPaymentRequestStatus.FailedPaidIn].includes(
        paymentRequest.status,
      ) &&
      !!broker?.thirdPartyBankAccount?.mandateApprovedAt &&
      !paymentRequest.features.includes(
        EPaymentRequestFeature.ThirdPartyPayInBrokerDirect,
      );

    const isDeletable =
      isEditable &&
      [
        EPaymentRequestStatus.New,
        EPaymentRequestStatus.Partial,
        EPaymentRequestStatus.FailedPaidIn,
      ].includes(paymentRequest.status as EPaymentRequestStatus);

    const viewActions: IAction[] = [];
    const paymentRequestActions: IAction[] = [];
    const thirdPartyActions: IAction[] = [];

    if (paymentRequest.property) {
      viewActions.push({
        content: ts.paymentsFollowUpCardActionsDetailPaymentRequest(),
        onClick: handleShowPaymentRequestDetailClick(paymentRequest),
      });
    }

    if (!isPropertyDetail) {
      viewActions.push({
        content: getLocalizedText("property.view_property.action"),
        url: `/properties/${paymentRequest.requestPropertyId}/payments`,
      });
    }

    if (
      !!(paymentRequest.payerAccount as IAccount & { contact?: IContact })
        .contact
    ) {
      viewActions.push({
        content: ts.contactViewContactAction(),
        // @ts-ignore
        url: `/contacts/${paymentRequest.payerAccount.contact!.id}`,
      });
    }

    if (!hasFromRent && !hasRentDiscount) {
      paymentRequestActions.push({
        content: ts.paymentRequestResendInvitationAction(),
        onClick: handleInvitationClick(paymentRequest),
      });
    }

    if (
      queryParams.phase !== EPaymentRequestFilterPhase.Incoming &&
      !hasFromRent
    ) {
      paymentRequestActions.push({
        content: ts.paymentsFollowUpCardActionsContactPayer(),
        onClick: handleContactPayerClick(paymentRequest),
      });
    }

    if (user && canMarkPaid({ paymentRequest, user })) {
      paymentRequestActions.push({
        content: <TextStyle>{ts.paymentRequestMarkPaidAction()}</TextStyle>,
        onClick: handleMarkPaidClick(paymentRequest),
      });
    } else if (internalModeEnabled) {
      paymentRequestActions.push({
        content: (
          <RentioInternalRenderer>
            {ts.paymentRequestMarkPaidAction()}
          </RentioInternalRenderer>
        ),
        onClick: handleMarkPaidClick(paymentRequest),
      });
    }

    if (hasThirdPartyAction) {
      thirdPartyActions.push({
        content: ts.paymentsFollowUpCardActionsThirdPartyAccountAction(),
        onClick: handleThirdPartyClick(paymentRequest),
      });
    }

    if (isDeletable) {
      paymentRequestActions.push({
        content: <TextStyle variation="negative">{ts.remove()}</TextStyle>,
        onClick: () => handleRemoveWithConfirm(paymentRequest),
      });
    }

    return [viewActions, paymentRequestActions, thirdPartyActions];
  };

  const getPrimaryPaymentRequestActions = (
    paymentRequest: IPaymentRequest,
    phase: EPaymentRequestFilterPhase,
  ) => {
    let actions: IAction[] = [];

    if (
      phase === EPaymentRequestFilterPhase.Action &&
      paymentRequest.status !== EPaymentRequestStatus.Transferring
    ) {
      actions.push({
        content: ts.paymentsFollowUpActionRequiredAction(),
        onClick: handleContactPayerClick(paymentRequest),
        appearance: "danger",
      });
    }

    return actions;
  };

  // Events
  const handleTogglePaymentRequestsDisplay = () => {
    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.LocalStorage,
        preferences: {
          [PREFERENCE_KEY_PAYMENT_REQUESTS_DISPLAY_TYPE]:
            paymentRequestsDisplay === EPaymentRequestsDisplayType.Grid
              ? EPaymentRequestsDisplayType.Table
              : EPaymentRequestsDisplayType.Grid,
        },
      }),
    );
  };

  const handleChangeQuery = (newQuery: string | undefined) => {
    if (!newQuery || newQuery.length === 0) {
      setQueryParamValue({ query: undefined, page: 1 });
      return;
    }

    setQueryParamValue({ query: newQuery, page: 1 });
  };

  const handleClearQuery = () => {
    setQueryParamValue({
      query: undefined,
    });
  };

  const handleChangeStatuses = (value: EPaymentRequestFilterStatus[]) => {
    setQueryParamValue({ status: value[0], page: 1 });
  };

  const handleClearStatus = () => {
    setQueryParamValue({ status: undefined, page: 1 });
  };

  const handleChangePhase = (value: EPaymentRequestFilterPhase[]) => {
    setQueryParamValue({ phase: value[0], page: 1 });
  };

  const handleClearPhase = () => {
    setQueryParamValue({ phase: undefined, page: 1 });
  };

  const handleChangeFrom = (value: EPaymentRequestFilterFrom[]) => {
    setQueryParamValue({ from: value[0], page: 1 });
  };

  const handleClearFrom = () => {
    setQueryParamValue({ from: undefined, page: 1 });
  };

  const handleInvitationClick = (item: IPaymentRequest) => () => {
    dispatch(
      paymentActions.sendPaymentRequestInvitation.actions.start({
        paymentRequestId: item.id,
      }),
    );
  };

  const handleRemoveWithConfirm = (paymentRequest: IPaymentRequest) => {
    const handleRemove = () => {
      dispatch(
        paymentActions.deletePaymentRequest.actions.start({
          id: paymentRequest.id,
        }),
      );
    };

    confirm({
      title: getLocalizedText("system.delete.confirm", {
        typePrefix: getLocalizedText(`system.model.payment_request.prefix`),
        type: getLocalizedText("system.model.payment_request").toLowerCase(),
      }),
      primaryActions: [
        {
          title: getLocalizedText("system.remove"),
          onPress: handleRemove,
        },
      ],
    });
  };

  const handleContactPayerClick = (item: IPaymentRequest) => () => {
    setModalFollowUpItem(item);
  };

  const handleMarkPaidClick = (item: IPaymentRequest) => () => {
    confirm({
      title: getLocalizedText(
        "payment_request.alert.mark_paid_confirm.message",
      ),
      info: getLocalizedText("payment_request.alert.mark_paid_confirm.info"),
      inputValueProps: {
        type: "date",
        initialValue: new Date(),
        maxDate: new Date(),
      },
      primaryActions: [
        {
          title: getLocalizedText("payment_request.mark_paid.action"),
          onPress: (params?: { inputValue?: string | Date }) => {
            const { inputValue } = params || {};

            if (typeof inputValue === "string") {
              return;
            }

            handleMarkPaid(item, inputValue);
          },
        },
      ],
    });
  };

  const handleThirdPartyClick = (item: IPaymentRequest) => () => {
    setModalThirdPartyAccountItem(item);
  };

  const handleShowPaymentRequestDetailClick = (item: IPaymentRequest) => () => {
    setQueryParamValue({
      paymentRequestId: item.id,
    });
  };

  const handleFollowUpModalClose = () => {
    setModalFollowUpItem(undefined);
  };

  const handleMarkPaid = (item: IPaymentRequest, manuallyPaidAt?: Date) => {
    dispatch(
      paymentActions.markPaymentRequestPaid.actions.start({
        paymentRequestId: item.id,
        manuallyPaidAt,
        onSuccess: () => {
          // Show refresh modal there is a from rent payment order

          // Check if manually paid request is for rent
          if (
            !item.paymentRequestItems.find(
              item => item.paymentOrder.type === EPaymentOrderType.Rent,
            )
          ) {
            return;
          }

          // Get number of active payment orders with from rent
          dispatch(
            countActions.getCount.actions.start({
              ...countUtils.getActionPayloadActiveFromRentPaymentOrdersForProperty(
                item.requestPropertyId,
              ),
              onSuccess: (count: number) => {
                if (count === 0) {
                  return;
                }

                // Show page refresh modal
                confirm({
                  title: getLocalizedText(
                    "payment_request.alert.from_rent_refresh.title",
                  ),
                  info: getLocalizedText(
                    "payment_request.alert.from_rent_refresh.info",
                  ),
                  primaryActions: [
                    {
                      title: getLocalizedText(
                        "payment_request.alert.from_rent_refresh.cta.refresh",
                      ),
                      onPress: () => {
                        setTimeout(() => {
                          window.location.reload();
                        }, 300);
                      },
                    },
                  ],
                });
              },
            }),
          );
        },
      }),
    );
  };

  const handleThirdPartyAccountModalClose = () => {
    setModalThirdPartyAccountItem(undefined);
  };

  // Render

  const renderFilter = () => {
    const appliedFilters: IAppliedFilterInterface[] = [];

    if (
      paymentRequestsDisplay === EPaymentRequestsDisplayType.Table &&
      queryParams.phase
    ) {
      const key = "paymentRequestPhase";
      appliedFilters.push({
        key,
        label: getLocalizedText(
          `payment_request.follow_up.filter.phase.option.${queryParams.phase.toLowerCase()}.label`,
        ),
        onRemove: handleClearPhase,
      });
    }

    if (queryParams.status) {
      const key = "paymentRequestStatus";
      appliedFilters.push({
        key,
        label: getLocalizedText(
          `payment_request.follow_up.filter.status.option.${queryParams.status.toLowerCase()}.label`,
        ),
        onRemove: handleClearStatus,
      });
    }

    if (queryParams.from) {
      const key = "paymentRequestFrom";
      appliedFilters.push({
        key,
        label: getLocalizedText(
          `payment_request.follow_up.filter.from.option.${queryParams.from.toLowerCase()}.label`,
        ),
        onRemove: handleClearFrom,
      });
    }

    const filters = compact([
      paymentRequestsDisplay === EPaymentRequestsDisplayType.Table
        ? {
            key: "paymentRequestPhase",
            label: getLocalizedText(
              "payment_request.follow_up.filter.phase.label",
            ),
            content: (
              <OptionListShared
                id="paymentRequestPhase"
                value={queryParams.phase ? [queryParams.phase] : []}
                variant="simple"
                multiple={false}
                options={Object.values(EPaymentRequestFilterPhase).map(
                  phase => ({
                    label: getLocalizedText(
                      `payment_request.follow_up.filter.phase.option.${phase.toLowerCase()}.label`,
                    ),
                    id: phase,
                    value: phase,
                  }),
                )}
                onChange={handleChangePhase}
              />
            ),
          }
        : undefined,

      {
        key: "paymentRequestStatuses",
        label: getLocalizedText(
          "payment_request.follow_up.filter.status.label",
        ),
        content: (
          <OptionListShared
            id="paymentRequestStatuses"
            value={queryParams.status ? [queryParams.status] : []}
            variant="simple"
            multiple={false}
            options={Object.values(EPaymentRequestFilterStatus).map(status => ({
              label: getLocalizedText(
                `payment_request.follow_up.filter.status.option.${stringToSnakeCase(
                  status,
                )}`,
              ),
              id: status,
              value: status,
            }))}
            onChange={handleChangeStatuses}
          />
        ),
      },

      {
        key: "paymentRequestFrom",
        label: getLocalizedText("payment_request.follow_up.filter.from.label"),
        content: (
          <OptionListShared
            id="paymentRequestFrom"
            value={queryParams.from ? [queryParams.from] : []}
            variant="simple"
            multiple={false}
            options={Object.values(EPaymentRequestFilterFrom).map(phase => ({
              label: getLocalizedText(
                `payment_request.follow_up.filter.from.option.${phase.toLowerCase()}.label`,
              ),
              id: phase,
              value: phase,
            }))}
            onChange={handleChangeFrom}
          />
        ),
      },
    ]);

    return (
      <Box mb={ESpacings.loose}>
        <Filters
          queryValue={queryParams.query || ""}
          onQueryChange={handleChangeQuery}
          onQueryClear={handleClearQuery}
          appliedFilters={appliedFilters}
          filters={filters}
          queryPlaceholder={getLocalizedText(
            "payment_requests.filter.query.placeholder",
          )}
        />
      </Box>
    );
  };

  const renderContent = () => {
    if (paymentRequestsDisplay === EPaymentRequestsDisplayType.Table) {
      return (
        <PaymentRequestsTable
          getPaymentRequestActions={getPaymentRequestActions}
          propertyId={propertyId}
          search={queryDebounced}
          phase={queryParams.phase}
          status={queryParams.status}
          from={queryParams.from}
        />
      );
    }

    return (
      <PaymentRequestsGrid
        getPaymentRequestActions={getPaymentRequestActions}
        getPrimaryPaymentRequestActions={getPrimaryPaymentRequestActions}
        propertyId={propertyId}
        search={queryDebounced}
        status={queryParams.status}
        from={queryParams.from}
      />
    );
  };

  return (
    <Card
      heading={getLocalizedText("payments.follow_up.payment_requests.heading")}
      primaryActions={[
        {
          content:
            paymentRequestsDisplay === EPaymentRequestsDisplayType.Grid ? (
              <Icon source="listView" color="primary" />
            ) : (
              <Icon source="layout" color="primary" />
            ),
          appearance: "reset",
          onClick: handleTogglePaymentRequestsDisplay,
        },
      ]}
    >
      {paymentRequestsDisplay === EPaymentRequestsDisplayType.Table && (
        <Box mb={ESpacings.loose}>
          <Stages
            stages={[
              {
                heading: getLocalizedText(
                  "payments.follow_up.incoming.heading",
                ),
                icon: "billStack",
                count: paymentRequestsIncomingCount || 0,
                appearance: "success",
                isActive:
                  queryParams.phase === EPaymentRequestFilterPhase.Incoming,
                onClick: () => {
                  setQueryParamValue({
                    phase: EPaymentRequestFilterPhase.Incoming,
                  });
                },
              },

              {
                heading: getLocalizedText(
                  "payments.follow_up.reminder_rentio.heading",
                ),
                icon: "billStack",
                count: paymentRequestsRentioCount || 0,
                appearance: "warning",
                isActive:
                  queryParams.phase === EPaymentRequestFilterPhase.Rentio,
                onClick: () => {
                  setQueryParamValue({
                    phase: EPaymentRequestFilterPhase.Rentio,
                  });
                },
              },

              {
                heading: getLocalizedText(
                  "payments.follow_up.action_required.heading",
                ),
                icon: "alertDiamond",
                count: paymentRequestsActionRequiredCount || 0,
                appearance: "error",
                isActive:
                  queryParams.phase === EPaymentRequestFilterPhase.Action,
                onClick: () => {
                  setQueryParamValue({
                    phase: EPaymentRequestFilterPhase.Action,
                  });
                },
              },
            ]}
          />
        </Box>
      )}

      {!isPropertyDetail && renderFilter()}

      {renderContent()}

      {modalFollowUpItem && (
        <PaymentFollowUpModal
          onClose={handleFollowUpModalClose}
          followUpItem={modalFollowUpItem}
        />
      )}

      {modalThirdPartyAccountItem && (
        <ThirdPartyAccountModal
          paymentRequest={modalThirdPartyAccountItem as any}
          onClose={handleThirdPartyAccountModalClose}
        />
      )}
    </Card>
  );
};
