import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { Stages } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { EEndDateWithinMonths } from "../../EndingContracts.types";
import { getEndDateFilterQuery } from "../../EndingContracts.utils";

interface IProps {
  activeEndDateWithinMonths?: EEndDateWithinMonths;
  onClickActiveEndDate: (activeEndDate: EEndDateWithinMonths) => void;
  getUrl?: (renewal: EEndDateWithinMonths) => string;
}

const EndingContractsStageEndDate: React.FC<IProps> = props => {
  const { activeEndDateWithinMonths, onClickActiveEndDate, getUrl } = props;

  const { count: endDateWithin1MonthCount = 0 } = contractHooks.useCount({
    query: getEndDateFilterQuery({
      tacitRenewal: false,
      endDateWithinMonths: EEndDateWithinMonths.Within1Month,
    }),
    shouldRefetch: true,
  });
  const { count: endDateWithin4MonthsCount = 0 } = contractHooks.useCount({
    query: getEndDateFilterQuery({
      tacitRenewal: false,
      endDateWithinMonths: EEndDateWithinMonths.Within4Months,
    }),
    shouldRefetch: true,
  });
  const { count: endDateWithin7MonthsCount = 0 } = contractHooks.useCount({
    query: getEndDateFilterQuery({
      tacitRenewal: false,
      endDateWithinMonths: EEndDateWithinMonths.Within7Months,
    }),
    shouldRefetch: true,
  });

  return (
    <Stages
      stages={[
        EEndDateWithinMonths.Within1Month,
        EEndDateWithinMonths.Within4Months,
        EEndDateWithinMonths.Within7Months,
      ].map(id => {
        let count = 0;
        switch (id) {
          case EEndDateWithinMonths.Within7Months:
            count = endDateWithin7MonthsCount;
            break;

          case EEndDateWithinMonths.Within4Months:
            count = endDateWithin4MonthsCount;
            break;

          case EEndDateWithinMonths.Within1Month:
            count = endDateWithin1MonthCount;
            break;

          default:
            break;
        }

        return {
          heading: getLocalizedText(
            `ending_contracts.option.end_date_within_months.${stringToSnakeCase(
              id,
            )}`,
          ),
          icon: "businessContractBreak",
          count,
          url: getUrl?.(id),
          onClick: () => {
            onClickActiveEndDate(id);
          },
          isActive: id === activeEndDateWithinMonths,
        };
      })}
    />
  );
};

export default EndingContractsStageEndDate;
