var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import { onMultiple } from "../utils/reducer.utils";
import * as authActions from "./auth.actions";
import { getUser, updateUser } from "./auth.actions";
export var REDUCER_KEY_AUTH = "auth";
var getDefaultState = function () { return ({
    // hasAccessToken: false,
    isSigningIn: false,
    signInError: undefined,
    isSigningInAutomatically: false,
    signInAutomaticallyError: undefined,
    isSigningUp: false,
    signUpError: undefined,
    isSignUpVerifyingPhone: false,
    signUpVerifyPhoneError: undefined,
    user: undefined,
    isFetchingUser: false,
    fetchUserError: undefined,
    isUpdatingUser: false,
    updateUserError: undefined,
    isAdminVerifyingEmailOrPhone: false,
    adminVerifyError: undefined,
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return (__assign({ hasAccessToken: false }, getDefaultState())); });
reducer.on(authActions.signIn.types.START, function (state) { return (__assign(__assign({}, state), { isSigningIn: true, signInError: undefined })); });
reducer.on(authActions.signIn.types.SUCCESS, function (state) { return (__assign(__assign({}, state), { hasAccessToken: true, isSigningIn: false, signInError: undefined })); });
reducer.on(authActions.signIn.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isSigningIn: false, signInError: error }));
});
reducer.on(authActions.signInAutomatically.types.START, function (state) { return (__assign(__assign({}, state), { hasAccessToken: false, user: undefined, isSigningInAutomatically: true, signInAutomaticallyError: undefined })); });
reducer.on(authActions.signInAutomatically.types.SUCCESS, function (state) { return (__assign(__assign({}, state), { hasAccessToken: true, isSigningInAutomatically: false, signInAutomaticallyError: undefined })); });
reducer.on(authActions.signInAutomatically.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isSigningInAutomatically: false, signInAutomaticallyError: error }));
});
reducer.on(authActions.setAccessTokensWithoutSideEffects, function (state) { return (__assign(__assign({}, state), { hasAccessToken: true })); });
reducer.on(authActions.refreshSuccess, function (state) { return (__assign({}, state)); });
reducer.on(authActions.signUp.types.START, function (state) { return (__assign(__assign({}, state), { isSigningUp: true, signUpError: undefined })); });
reducer.on(authActions.signUp.types.SUCCESS, function (state, _a) {
    var user = _a.user;
    return (__assign(__assign({}, state), { user: user, isSigningUp: false, signUpError: undefined }));
});
reducer.on(authActions.signUp.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isSigningUp: false, signUpError: error }));
});
reducer.on(authActions.signUpVerifyPhone.types.START, function (state) { return (__assign(__assign({}, state), { isSignUpVerifyingPhone: true, signUpVerifyPhoneError: undefined })); });
reducer.on(authActions.signUpVerifyPhone.types.SUCCESS, function (state) { return (__assign(__assign({}, state), { hasAccessToken: true, isSignUpVerifyingPhone: false, signUpVerifyPhoneError: undefined })); });
reducer.on(authActions.signUpVerifyPhone.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isSignUpVerifyingPhone: false, signUpVerifyPhoneError: error }));
});
reducer.on(getUser.types.START, function (state) { return (__assign(__assign({}, state), { isFetchingUser: true, fetchUserError: undefined })); });
reducer.on(getUser.types.SUCCESS, function (state, user) { return (__assign(__assign({}, state), { user: user, isFetchingUser: false, fetchUserError: undefined })); });
reducer.on(getUser.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetchingUser: false, fetchUserError: error }));
});
reducer.on(updateUser.types.START, function (state) { return (__assign(__assign({}, state), { isUpdatingUser: true, updateUserError: undefined })); });
reducer.on(updateUser.types.SUCCESS, function (state, _a) {
    var user = _a.user;
    return (__assign(__assign({}, state), { user: user, isUpdatingUser: false, updateUserError: undefined }));
});
reducer.on(updateUser.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isUpdatingUser: false, updateUserError: error }));
});
onMultiple(reducer, [
    authActions.adminVerifyEmail.types.START,
    authActions.adminVerifyPhone.types.START,
], function (state, _) { return (__assign(__assign({}, state), { isAdminVerifyingEmailOrPhone: true, adminVerifyError: undefined })); });
onMultiple(reducer, [
    authActions.adminVerifyEmail.types.SUCCESS,
    authActions.adminVerifyPhone.types.SUCCESS,
], function (state, _) { return (__assign(__assign({}, state), { isAdminVerifyingEmailOrPhone: false, adminVerifyError: undefined })); });
onMultiple(reducer, [
    authActions.adminVerifyEmail.types.FAILURE,
    authActions.adminVerifyPhone.types.FAILURE,
], function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isAdminVerifyingEmailOrPhone: false, adminVerifyError: error }));
});
export default reducer;
