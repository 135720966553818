import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getTitle } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { ACTIVITY_SUBTYPES } from "@rentiohq/shared/dist/types/activity.types";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import {
  EPaymentMethod,
  EPaymentOrderOwnerPaymentMethod,
  EPaymentOrderType,
  EPaymentRepetitionType,
  EPaymentRequestFeature,
  EPaymentRequestStatus,
  EPayoutType,
  IPaymentOrder,
  IPaymentRequest,
} from "@rentiohq/shared/dist/types/payment.types";
import {
  isAfter,
  startOfDay,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { addWeekdays } from "@rentiohq/shared/dist/utils/date.utils";
import { EUrgency } from "@rentiohq/web-shared/dist/components/DataTable/DataTable.types";
import { IPaymentRequestItemProps } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { capitalize } from "lodash";
import uniq from "ramda/es/uniq";
import { EPaymentRequestFilterPhase } from "scenes/FollowUp/Payments/Payments.utils";
import { ts } from "../services";

export const END_INCOMING_DSO = 0;
export const END_RENTIO_FOLLOW_UP_DSO = 20;

const NOW = new Date();
const START_OF_DAY = startOfDay(NOW);

export const getPeriod = (
  paymentRequest: IPaymentRequest,
  dateFormat?: string,
) => {
  const { startedAt, repetitionType, endedAt } = paymentRequest;

  if (!startedAt) {
    return utils.date.format(paymentRequest.dueDateAt, dateFormat);
  }

  switch (repetitionType) {
    case EPaymentRepetitionType.Once:
      return utils.date.format(startedAt, dateFormat);

    default:
      return `${utils.date.format(startedAt, dateFormat)} - ${utils.date.format(
        endedAt,
        dateFormat,
      )}`;
  }
};

export const hasMandate = (paymentOrders: IPaymentOrder[]) => {
  // @ts-ignore
  return paymentOrders.every(item => {
    return (
      !!item.mandate && item.repetitionType !== EPaymentRepetitionType.Once
    );
  });
};

export const isFromRent = (paymentOrders: IPaymentOrder[]) =>
  !!paymentOrders.find(
    item =>
      item.ownerPaymentMethod === EPaymentOrderOwnerPaymentMethod.FromRent,
  );

export const isRentDiscount = (paymentOrders: IPaymentOrder[]) =>
  paymentOrders.some(item => item.type === EPaymentOrderType.RentDiscount);

export const getPaymentOrders = (params: {
  paymentRequestItems: IPaymentRequestItemProps[];
}) =>
  uniq(
    params.paymentRequestItems
      .map(item => item.paymentOrder)
      // TODO: Dirty fix
      .filter(x => x !== undefined),
  );

export const getPayees = (paymentOrders: IPaymentOrder[]): IAccount[] => {
  const payees = paymentOrders
    .map((item: any) => {
      return (
        item.payeeBankaccount && {
          ...item.payee,
          bankaccount: item.payeeBankaccount,
        }
      );
    })
    .filter(Boolean);
  return uniq(payees);
};

export const createTitle = (params: {
  paymentOrders: IPaymentOrder[];
  paymentRequest?: IPaymentRequest;
  withPayoutType?: boolean;
}) => {
  const { paymentOrders, paymentRequest, withPayoutType = false } = params;

  const result = [];

  if (paymentRequest && withPayoutType) {
    const type = getLocalizedText(
      `payment_request.payout_type.${paymentRequest.payoutType}.short`.toLowerCase(),
    );
    result.push(`${type}:`);
  }

  const types = paymentOrders.map(paymentOrder => getTitle(paymentOrder));
  result.push(join(types));

  if (paymentRequest) {
    const period = getPeriod(paymentRequest);
    result.push(`(${period})`);
  }

  return capitalize(result.join(" ").toLowerCase());
};

export const getPaymentOrderActivityFilter = (paymentOrder: IPaymentOrder) => {
  return {
    where: {
      or: [
        {
          paymentOrderId: paymentOrder.id,
          subtype: {
            inq: [
              ACTIVITY_SUBTYPES.PAYMENT.ORDER_CREATED,
              ACTIVITY_SUBTYPES.PAYMENT.ORDER_REMOVED,
              ACTIVITY_SUBTYPES.PAYMENT.ORDER_RECURRING_MANDATE_REMOVED,
            ],
          },
        },
      ],
    },
  };
};

export const getPaymentRequestActivityFilter = (
  paymentRequest: IPaymentRequest,
) => {
  const { id: paymentRequestId, paymentRequestItems } = paymentRequest;
  const paymentOrderId = paymentRequestItems[0]?.paymentOrder.id;

  return {
    where: {
      or: [
        { paymentRequestId },
        {
          paymentOrderId,
          subtype: {
            inq: [
              ACTIVITY_SUBTYPES.PAYMENT.ORDER_CREATED,
              ACTIVITY_SUBTYPES.PAYMENT.ORDER_REMOVED,
              ACTIVITY_SUBTYPES.PAYMENT.ORDER_RECURRING_MANDATE_REMOVED,
            ],
          },
        },
      ],
    },
  };
};

export const getFollowUpReason = (item: IPaymentRequest) => {
  const paymentOrders = getPaymentOrders({
    paymentRequestItems: item.paymentRequestItems as any,
  });
  const hasFromRent = isFromRent(paymentOrders);

  const date = item.estimatedCollectionDate || item.dueDateAt;
  if (!date) {
    return "";
  }

  const suppressFromRentMessage = [
    EPaymentRequestStatus.FailedPaidIn,
    EPaymentRequestStatus.FailedTransfer,
    EPaymentRequestStatus.FailedKyc,
    EPaymentRequestStatus.Failed,
    EPaymentRequestStatus.PendingThirdParty,
  ].includes(item.status);

  if (hasFromRent && !suppressFromRentMessage) {
    return ts.paymentsFollowUpReason({
      reason: "from_rent",
      mandate: hasMandate(paymentOrders) ? "with_mandate" : "without_mandate",
      date: formatDate(date),
    });
  }

  if (item.status === EPaymentRequestStatus.Transferring) {
    return getLocalizedText("payment_request.payout_type.payout.transferring");
  }

  if (
    item.payoutType === EPayoutType.Payout &&
    [EPaymentRequestStatus.New, EPaymentRequestStatus.Pending].includes(
      item.status,
    )
  ) {
    if (item.paymentMethod === EPaymentMethod.Bancontact) {
      return getLocalizedText(
        "payments.follow_up.reason.pending.payout_bancontact",
        {
          paidAt: formatDate(
            item.paymentApprovedAt || item.paidAt || new Date(),
          ),
          estimatedPayoutDate: formatDate(
            addWeekdays(item.estimatedCollectionDate || new Date(), 1),
          ),
          estimatedCollectionDate: formatDate(
            item.estimatedCollectionDate || new Date(),
          ),
        },
      );
    }

    return getLocalizedText("payment_request.payout_type.payout");
  }

  if (
    item.status === EPaymentRequestStatus.Paid &&
    item.payoutType === EPayoutType.Charge &&
    item.features.includes(EPaymentRequestFeature.PlannedPayOut) &&
    item.plannedExecutionAt &&
    isAfter(item.plannedExecutionAt, START_OF_DAY)
  ) {
    return getLocalizedText(
      `payments.follow_up.reason.pending.planned_pay_out`,
      { date: formatDate(item.plannedExecutionAt) },
    );
  }

  if (
    [
      EPaymentRequestStatus.Approved, // OK
      EPaymentRequestStatus.Pending, // OK
      EPaymentRequestStatus.PendingThirdParty, // OK
      EPaymentRequestStatus.PayInPlanned, // OK
      EPaymentRequestStatus.PaidIn, // OK
      EPaymentRequestStatus.Partial, // Copy to check, wrong
      EPaymentRequestStatus.Transferring, // OK
      EPaymentRequestStatus.Transferred, // OK
      // EPaymentRequestStatus.PayingOut, // Moved to bottom
    ].includes(item.status)
  ) {
    return ts.paymentsFollowUpReasonWithEstimatedCollectionDate({
      reason: EPaymentRequestStatus.Pending,
      date: formatDate(date),
    });
  }

  return ts.paymentsFollowUpReason({
    reason: item.status,
    mandate: hasMandate(paymentOrders) ? "with_mandate" : "without_mandate",
    date: formatDate(date),
  });
};

export const getUrgencyByPhase = (phase?: EPaymentRequestFilterPhase) => {
  switch (phase) {
    case EPaymentRequestFilterPhase.Incoming:
      return EUrgency.Low;

    case EPaymentRequestFilterPhase.Rentio:
      return EUrgency.Medium;

    case EPaymentRequestFilterPhase.Action:
      return EUrgency.High;

    default:
      return EUrgency.None;
  }
};

export const getTextStyleVariationByPhase = (
  phase: EPaymentRequestFilterPhase,
) => {
  switch (phase) {
    case EPaymentRequestFilterPhase.Incoming:
      return "positive";

    case EPaymentRequestFilterPhase.Rentio:
      return "warn";

    case EPaymentRequestFilterPhase.Action:
      return "negative";

    default:
      return "default";
  }
};

export const getAppearanceByPhase = (phase?: EPaymentRequestFilterPhase) => {
  switch (phase) {
    case EPaymentRequestFilterPhase.Incoming:
      return "success";

    case EPaymentRequestFilterPhase.Rentio:
      return "warning";

    case EPaymentRequestFilterPhase.Action:
      return "error";

    default:
      return "default";
  }
};
