import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EPayInStatus,
  EPayInType,
  IPayIn,
} from "@rentiohq/shared/dist/types/payin.types";
import {
  Card,
  DisplayText,
  EmptyState,
  Error,
  Loading,
  Pagination,
} from "@rentiohq/web-shared/dist/components";
import { groupBy } from "lodash";
import { NumberParam, withDefault } from "serialize-query-params";
import { PayInAllocationModal } from "./PayInAllocationModal";
import { PayInTable } from "./PayInTable";

interface IProps {
  propertyId?: number;
}

export const PayInsFollowUp = (props: IProps) => {
  const { propertyId } = props;

  const [queryParams, setQueryParams] = useQueryParams({
    payInToAllocateId: NumberParam,
    payInId: NumberParam,
    page: withDefault(NumberParam, 1),
  });

  // Redux
  const paged = payInModule.hooks.usePaged({
    query: {
      page: queryParams.page,
      limit: propertyId ? 100 : 20,
      sort: [{ field: "createdAt", method: "DESC" }],
      filter: {
        type: EPayInType.Ibanisation,
        status: {
          inq: [EPayInStatus.MatchedPartially, EPayInStatus.Unmatched],
        },
      },
    },
    customPath: propertyId ? `/properties/${propertyId}/pay-ins` : undefined,
    skipLegacyCount: !!propertyId,
  });

  // Event handlers
  const handleMatch = (payIn: IPayIn) => {
    setQueryParams({
      payInToAllocateId: payIn.id,
    });
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setQueryParams({ page: selected + 1 });
  };

  // Render
  const renderContent = () => {
    if (paged.isFetching && !paged.items) {
      if (propertyId) {
        return null;
      }

      return <Loading />;
    }

    if (paged.fetchError) {
      return <Error errors={[paged.fetchError]} />;
    }

    if (!paged.items || paged.items.length === 0) {
      if (propertyId) {
        return null;
      }

      return (
        <Card>
          <EmptyState
            heading={getLocalizedText(
              "follow_up.unmatched_pay_ins.empty.heading",
            )}
          />
        </Card>
      );
    }

    if (propertyId) {
      const payInsPerWallet = Object.values(
        groupBy(paged.items, "mangoWalletId"),
      );

      if (payInsPerWallet.length > 1) {
        return (
          <>
            <Spacer weight={ESpacerWeight.W24} />

            <DisplayText size="medium">
              {getLocalizedText("follow_up.unmatched_pay_ins.title")}
            </DisplayText>

            {payInsPerWallet.map(payIns => (
              <PayInTable
                showGeneralTitle={false}
                payIns={payIns}
                onMatch={handleMatch}
                showFooter={true}
                showPropertyInfo={!propertyId}
              />
            ))}
          </>
        );
      }
    }

    return (
      <PayInTable
        showGeneralTitle={true}
        payIns={paged.items}
        onMatch={handleMatch}
        showFooter={!!propertyId}
        showPropertyInfo={!propertyId}
        customPreFooter={
          paged.totalPages && paged.totalPages > 1 ? (
            <Pagination
              initialPage={queryParams.page - 1}
              pageCount={paged.totalPages}
              onPageChange={handlePageClick}
            />
          ) : undefined
        }
      />
    );
  };

  return (
    <>
      {renderContent()}

      {!!queryParams.payInToAllocateId && (
        <PayInAllocationModal
          payInId={queryParams.payInToAllocateId}
          onClose={() => {
            setQueryParams({ payInToAllocateId: undefined });
          }}
          onSuccess={() => {
            setQueryParams({
              payInToAllocateId: undefined,
              payInId: queryParams.payInToAllocateId,
            });
          }}
        />
      )}
    </>
  );
};
