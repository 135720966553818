var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EPropertyTypeId } from "@rentiohq/shared/dist/types/property.types";
import { Platform } from "react-native";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { PROPERTY_TYPES } from "../../reduxV2/property/property.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getAvailableEpcLabelsForProperty } from "../../utils/property.utils";
import { uiSchema as epcUiSchema, getSchema as getEpcSchema, } from "../epc/schema.epcInfo";
import { EField as EEpcField } from "../epc/schema.epcInfo.types";
import { EField } from "./schema.createProperty.types";
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a, _b;
        var _c, _d;
        var epcSchema = getEpcSchema(extraData, initialValues)(defaultValues);
        var epcRequiredFields = (_c = epcSchema.required) !== null && _c !== void 0 ? _c : [];
        var epcProperties = (_d = epcSchema.properties) !== null && _d !== void 0 ? _d : {};
        var propertyTypesToDisplay = extraData.isGroup
            ? PROPERTY_TYPES.filter(function (x) { return x.id === EPropertyTypeId.Group; })
            : PROPERTY_TYPES.filter(function (x) { return x.id !== EPropertyTypeId.Group; });
        return {
            required: __spreadArray([
                EField.Address,
                EField.PropertyType,
                EField.Name
            ], epcRequiredFields, true),
            properties: __assign(__assign((_a = {}, _a[EField.Header + 1] = {
                default: undefined,
                //@ts-ignore
                showIf: function () { return Platform.OS === "web"; },
            }, _a[EField.PropertyType] = {
                default: getValue(EField.PropertyType, initialValues, defaultValues),
                // @ts-ignore
                options: propertyTypesToDisplay.map(function (type) { return ({
                    id: type.id,
                    value: getLocalizedText("property.type.".concat(type.label).toLowerCase()),
                    icon: type.icon,
                }); }),
            }, _a[EField.Header + 2] = {
                //@ts-ignore
                showIf: function () { return Platform.OS === "web"; },
                default: undefined,
            }, _a[EField.Address] = {
                default: getValue(EField.Address, initialValues, defaultValues),
            }, _a[EField.Header + 3] = {
                //@ts-ignore
                showIf: function () { return Platform.OS === "web"; },
                default: undefined,
            }, _a), epcProperties), (_b = {}, _b[EField.Header + 4] = {
                //@ts-ignore
                showIf: function () { return Platform.OS === "web"; },
                default: undefined,
            }, _b[EField.Name] = {
                default: (function () {
                    var addressValue = getValue(EField.Address, initialValues, defaultValues);
                    var propertyTypeValue = getValue(EField.PropertyType, initialValues, defaultValues);
                    var nameValue = getValue(EField.Name, initialValues, defaultValues);
                    if (addressValue && propertyTypeValue) {
                        var propertyTypeName = PROPERTY_TYPES.find(function (type) {
                            return type.id === propertyTypeValue;
                        });
                        var propertyTranslated = propertyTypeName &&
                            getLocalizedText("property.type.".concat(propertyTypeName.label).toLowerCase());
                        if (!nameValue) {
                            return "".concat(propertyTranslated, " ").concat(defaultValues.address.street, " ").concat(defaultValues.address.number);
                        }
                        return getValue(EField.Name, initialValues, defaultValues);
                    }
                })(),
                // @ts-ignore TODO: Typescript upgrade
                handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                    if (fieldName === EField.Name) {
                        return { shouldSetNewValue: false };
                    }
                    if (myFieldValue) {
                        return { shouldSetNewValue: false };
                    }
                    if (formData[EField.Address] && formData[EField.PropertyType]) {
                        var propertyTypeName = PROPERTY_TYPES.find(function (type) {
                            return type.id === formData[EField.PropertyType];
                        });
                        var propertyTranslated = propertyTypeName &&
                            getLocalizedText("property.type.".concat(propertyTypeName.label).toLowerCase());
                        if (myFieldValue) {
                            return { shouldSetNewValue: false };
                        }
                        var _a = formData[EField.Address], street = _a.street, number = _a.number;
                        if (street && number) {
                            return {
                                shouldSetNewValue: true,
                                newValue: "".concat(propertyTranslated, " ").concat(formData.address.street, " ").concat(formData.address.number),
                            };
                        }
                        if (street) {
                            return {
                                shouldSetNewValue: true,
                                newValue: "".concat(propertyTranslated, " ").concat(formData.address.street),
                            };
                        }
                    }
                    if (myFieldValue) {
                        return { shouldSetNewValue: true, newValue: myFieldValue };
                    }
                    return { shouldSetNewValue: false };
                },
            }, _b[EField.Header + 5] = {
                //@ts-ignore
                showIf: function (formData) {
                    return Platform.OS === "web" && (extraData.groupsCount || 0) > 0;
                },
                default: undefined,
            }, _b[EField.Group] = {
                default: getValue(EField.Group, initialValues, defaultValues),
                // @ts-ignore
                showIf: function (formData) { return (extraData.groupsCount || 0) > 0; },
            }, _b)),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a, _b;
    var epcSchema = epcUiSchema(extraData, initialValues);
    return __assign(__assign((_a = { "rentio:title": getLocalizedText("property.create.step.1"), "rentio:navigationTitle": getLocalizedText(extraData.property ? "properties.edit" : "properties.new"), "ui:field": ERentioFormField.Grid, grid: {
                row: [
                    { col: { width: 1, children: [EField.Header + 1] } },
                    { col: { width: 1, children: [EField.PropertyType] } },
                    { col: { width: 1, children: [EField.Header + 2] } },
                    { col: { width: 1, children: [EField.Address] } },
                    { col: { width: 1, children: [EField.Header + 3] } },
                    {
                        col: {
                            width: 1 / 3,
                            children: [EEpcField.Label],
                        },
                    },
                    {
                        col: {
                            width: 1 / 3,
                            children: [EEpcField.Value],
                        },
                    },
                    {
                        col: {
                            width: 1 / 3,
                            children: [EEpcField.ExpirationDate],
                        },
                    },
                    { col: { width: 1, children: [EField.Header + 4] } },
                    { col: { width: 1, children: [EField.Name] } },
                    { col: { width: 1, children: [EField.Header + 5] } },
                    { col: { width: 1, children: [EField.Group] } },
                ],
            } }, _a[EField.Header + 1] = {
        "ui:field": ERentioFormField.SectionHeader,
        title: getLocalizedText("property.create.type.legend"),
    }, _a[EField.PropertyType] = {
        "ui:field": ERentioFormField.InlineSelect,
        "ui:options": {
            variant: "card",
            size: "small",
            iconSize: "large",
        },
        title: getLocalizedText("properties.type"),
        placeholder: getLocalizedText("properties.type.placeholder"),
        customIsFetchingSelector: function () { return false; },
    }, _a[EField.Header + 2] = {
        "ui:field": ERentioFormField.SectionHeader,
        title: getLocalizedText("property.create.full_address.legend"),
    }, _a[EField.Address] = {
        "ui:field": ERentioFormField.Address,
        title: getLocalizedText("system.address"),
        placeholder: getLocalizedText("system.address"),
    }, _a[EField.Header + 3] = {
        "ui:field": ERentioFormField.SectionHeader,
        title: getLocalizedText("property.create.epc.legend"),
    }, _a), epcSchema), (_b = {}, _b[EField.Header + 4] = {
        "ui:field": ERentioFormField.SectionHeader,
        title: getLocalizedText("property.create.name.legend"),
    }, _b[EField.Name] = {
        "ui:field": ERentioFormField.Text,
        title: getLocalizedText("properties.name"),
        placeholder: getLocalizedText("properties.name.placeholder"),
    }, _b[EField.Header + 5] = {
        "ui:field": ERentioFormField.SectionHeader,
        title: getLocalizedText("property.create.group.placeholder"),
    }, _b[EField.Group] = {
        "ui:field": ERentioFormField.PropertySelectInline,
        title: getLocalizedText("property.create.group.legend"),
        optional: true,
        customPropertyFilter: extraData.propertyId
            ? {
                typeId: { eq: 1 },
                // Exclude self from filter
                id: { neq: extraData.propertyId },
            }
            : {
                typeId: { eq: 1 },
            },
    }, _b));
};
export var getValidate = function (_) { return function (formData, errors) {
    var _a, _b;
    // Check if the EPC-Label is valid for the country & zip
    var selectedEpcLabel = formData[EEpcField.Label];
    if (selectedEpcLabel) {
        var countryId = (_a = formData[EField.Address]) === null || _a === void 0 ? void 0 : _a.country;
        var zip = (_b = formData[EField.Address]) === null || _b === void 0 ? void 0 : _b.zip;
        var availableEpcLabelsForProperty = getAvailableEpcLabelsForProperty({
            countryId: countryId,
            zip: zip,
        });
        var isEpcLabelValid = availableEpcLabelsForProperty.find(function (x) { return x.id === selectedEpcLabel; });
        if (!isEpcLabelValid) {
            errors[EEpcField.Label].addError(getLocalizedText("system.form.error.epc_label_for_zip"));
        }
    }
    return errors;
}; };
