import axios from "axios";
import api from "../../utils/api/api.utils";
export var getCards = function () { return api.get("/cards"); };
export var closeCard = function (id) { return api.post("/cards/".concat(id, "/close")); };
export var getCardActionDynamicLink = function (url) {
    return axios.post("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDqY3XBF5LQd7QTK22_kbcIYxt4J4OcU0Q", {
        dynamicLinkInfo: {
            domainUriPrefix: "https://rentio.page.link",
            link: url,
            androidInfo: {
                androidPackageName: "be.rentio.app",
            },
            iosInfo: {
                iosBundleId: "be.rentio.app",
            },
        },
    });
};
