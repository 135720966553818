import styled from "@rentiohq/web-theme";

export const Wrapper = styled.div<{ isDragActive: boolean }>`
  height: 100%;
  /* height: calc(100vh - ${props => props.theme.header.height}); */
  display: flex;
  flex-direction: column;
  ${({ isDragActive }) => (isDragActive ? "opacity: .2" : "")}
`;

export const ChatSendBar = styled.div`
  padding: ${({ theme }) => theme.spacing("tight")};
  textarea {
    max-height: 200px;
  }
`;

export const ChatSupportBar = styled.div`
  padding: ${({ theme }) => theme.spacing()};
  text-align: center;
  color: ${props => props.theme.getColor("blue", 1000)};
  background-color: ${props => props.theme.getColor("blue", 0)};
`;

export const ChatRemovedBar = styled.div`
  padding: ${({ theme }) => theme.spacing()};
  text-align: center;
  color: ${props => props.theme.getColor("red", 1000)};
  background-color: ${props => props.theme.getColor("red", 0)};
`;

export const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  /* height: calc(100vh - 160px);
  margin-top: auto;
  > div {
    height: 100%;
  } */
`;
