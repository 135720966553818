import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import { EField, EStep, IExtraData } from "./schema.moveRequestUpdate.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { moveRequest, step } = extraData;

  const {
    leavingContactFirstname,
    leavingContactLastname,
    leavingContactEmail,
    energyInvoiceNote,
    telcoInvoiceNote,
    electricityType,
    electricityEan,
    naturalGasAvailable,
    naturalGasEan,
    naturalGasMeterNumber,
    electricityMeterNumberBoth,
    electricityMeterReadingBothDate,
    electricityMeterReadingBoth,
    electricityMeterReadingDayDate,
    electricityMeterReadingDay,
    electricityMeterReadingNightDate,
    electricityMeterType,
    waterMeterNumber,
    waterMeterReading,
    waterMeterReadingDate,
    naturalGasMeterType,
    naturalGasMeterReading,
    naturalGasMeterReadingDate,
    electricityMeterReadingNight,
  } = moveRequest;

  const result: { [key: string]: any } = {};

  switch (step) {
    case EStep.LeavingContact:
      result[EField.LeavingContactFirstname] = leavingContactFirstname;
      result[EField.LeavingContactLastname] = leavingContactLastname;
      result[EField.LeavingContactEmail] = leavingContactEmail;
      break;

    case EStep.EnergyNote:
      result[EField.NoteEnergy] = energyInvoiceNote;
      break;

    case EStep.TelcoNote:
      result[EField.NoteTelco] = telcoInvoiceNote;
      break;

    case EStep.ElectricityGeneral:
      result[EField.ElectricityType] = electricityType;
      result[EField.ElectricityEan] = electricityEan?.startsWith(EAN_PREFIX)
        ? electricityEan.replace(EAN_PREFIX, "")
        : electricityEan;
      result[EField.ElectricityMeterNumberBoth] = electricityMeterNumberBoth;
      break;

    case EStep.ElectricityHandover:
      result[EField.ElectricityMeterReadingBoth] = electricityMeterReadingBoth;
      result[EField.ElectricityMeterReadingBothDate] =
        electricityMeterReadingBothDate;
      result[EField.ElectricityMeterReadingDay] = electricityMeterReadingDay;
      result[EField.ElectricityMeterReadingDayDate] =
        electricityMeterReadingDayDate;
      result[EField.ElectricityMeterReadingNight] =
        electricityMeterReadingNight;
      result[EField.ElectricityMeterReadingNightDate] =
        electricityMeterReadingNightDate;
      result[EField.ElectricityMeterType] = electricityMeterType;
      break;

    case EStep.NaturalGasGeneral:
      result[EField.NaturalGasAvailable] = naturalGasAvailable;
      result[EField.NaturalGasEan] = naturalGasEan?.startsWith(EAN_PREFIX)
        ? naturalGasEan.replace(EAN_PREFIX, "")
        : naturalGasEan;
      result[EField.NaturalGasMeterNumber] = naturalGasMeterNumber;
      break;

    case EStep.NaturalGasHandover:
      result[EField.NaturalGasMeterReading] = naturalGasMeterReading;
      result[EField.NaturalGasMeterReadingDate] = naturalGasMeterReadingDate;
      result[EField.NaturalGasMeterType] = naturalGasMeterType;
      break;

    case EStep.WaterGeneral:
      result[EField.WaterMeterNumber] = waterMeterNumber;
      break;

    case EStep.WaterHandover:
      result[EField.WaterMeterReading] = waterMeterReading;
      result[EField.WaterMeterReadingDate] = waterMeterReadingDate;
      break;

    default:
      break;
  }

  return result;
};
