var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import logger from "@rentiohq/shared/dist/logger";
import { EPropertyMemberTypes, } from "@rentiohq/shared/dist/types/property.types";
import { includesAny } from "@rentiohq/shared/dist/utils/array.utils";
import isEqual from "lodash/isEqual";
import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import { EPropertyFetchType } from "../../reduxV2/property";
import * as propertyActionsV2 from "../../reduxV2/property/property.actions";
import * as propertySelectorsV2 from "../../reduxV2/property/property.selectors";
import { getFilter } from "../../reduxV2/property/property.utils";
import { doesSuccessActionMatchStartAction, } from "../../reduxV2/utils/action.utils";
import { showAlert } from "../../utils/alert/alert.utils";
import api from "../../utils/api/api.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EHttpMethod } from "../../utils/types";
import * as authActions from "../auth/auth.actions";
import * as authSelectors from "../auth/auth.selectors";
import * as countActions from "../count/count.actions";
import * as countSelectors from "../count/count.selectors";
import { COUNT_BASE, ECountIdentifier } from "../count/count.types";
import * as cardActions from "./card.actions";
import { closeCard, getCardActionDynamicLink, getCards, } from "./card.api";
import { cardsSelector } from "./card.selectors";
import { ECardButtonActionType, EExtraCardType, EUserType, } from "./card.types";
import { getSelectedUserType, setSelectedUserType } from "./card.utils";
function getCardsStartFlow(_) {
    var data, unknownError_1, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(getCards)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(cardActions.getCards.actions.success(data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                unknownError_1 = _a.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cardActions.getCards.actions.failure({ error: error }))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function selectUserTypeFlow(_a) {
    var unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(setSelectedUserType, payload)];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(cardActions.generateExtraCards.actions.start({ refetch: true }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
var FETCH_LIMIT = 2;
function generateExtraCardsStartFlow(_a) {
    var refetch, actionsToTrigger, successCheckForTriggeredActions, oldUser, _b, _c, oldContactsCount, _d, _e, ownedPropertiesPagedQuery, oldOwnedPropertiesResult, _f, _g, getOwnedPropertiesAction_1, rentedPropertiesPagedQuery, oldRentedPropertiesResult, _h, _j, getRentedPropertiesAction_1, user_1, _k, _l, contactsCount, _m, _o, ownedPropertiesResult, _p, _q, rentedPropertiesResult, _r, _s, ownedProperties, rentedProperties, activeProperties, isBroker, isTenant, isLandlord, hasNoProperties, hasSingleProperty, hasContacts, userType, extraCards_1, property, extraData, unknownError_3, error;
    var _t, _u;
    var payload = _a.payload;
    return __generator(this, function (_v) {
        switch (_v.label) {
            case 0:
                _v.trys.push([0, 15, , 17]);
                refetch = payload === null || payload === void 0 ? void 0 : payload.refetch;
                actionsToTrigger = [];
                successCheckForTriggeredActions = [];
                _c = (_b = authSelectors).getUser;
                return [4 /*yield*/, select()];
            case 1:
                oldUser = _c.apply(_b, [_v.sent()]);
                if (refetch || !oldUser) {
                    actionsToTrigger.push(authActions.getUser.actions.start({}));
                    successCheckForTriggeredActions.push(function (_a) {
                        var type = _a.type;
                        return isEqual(type, authActions.getUser.types.SUCCESS);
                    });
                }
                _e = (_d = countSelectors).getCount;
                return [4 /*yield*/, select()];
            case 2:
                oldContactsCount = _e.apply(_d, [_v.sent(), ECountIdentifier.Contacts]);
                if (refetch || oldContactsCount === undefined) {
                    actionsToTrigger.push(countActions.getCount.actions.start({
                        countIdentifier: ECountIdentifier.Contacts,
                        countBase: COUNT_BASE[ECountIdentifier.Contacts],
                    }));
                    successCheckForTriggeredActions.push(function (_a) {
                        var type = _a.type, _b = _a.payload, payload = _b === void 0 ? {} : _b;
                        return isEqual(type, countActions.getCount.types.SUCCESS) &&
                            isEqual(payload.countIdentifier, ECountIdentifier.Contacts);
                    });
                }
                ownedPropertiesPagedQuery = __assign({ page: 1, limit: FETCH_LIMIT }, getFilter(EPropertyFetchType.Owned));
                _g = (_f = propertySelectorsV2).getPaged;
                return [4 /*yield*/, select()];
            case 3:
                oldOwnedPropertiesResult = _g.apply(_f, [_v.sent(), ownedPropertiesPagedQuery]);
                if (refetch || (((_t = oldOwnedPropertiesResult === null || oldOwnedPropertiesResult === void 0 ? void 0 : oldOwnedPropertiesResult.items) === null || _t === void 0 ? void 0 : _t.length) || 0) === 0) {
                    getOwnedPropertiesAction_1 = propertyActionsV2.getPagedStart.getAction({
                        query: ownedPropertiesPagedQuery,
                    });
                    actionsToTrigger.push(getOwnedPropertiesAction_1);
                    successCheckForTriggeredActions.push(function (successAction) {
                        return doesSuccessActionMatchStartAction({
                            startAction: getOwnedPropertiesAction_1,
                            successAction: successAction,
                        });
                    });
                }
                rentedPropertiesPagedQuery = __assign({ page: 1, limit: FETCH_LIMIT }, getFilter(EPropertyFetchType.Rented));
                _j = (_h = propertySelectorsV2).getPaged;
                return [4 /*yield*/, select()];
            case 4:
                oldRentedPropertiesResult = _j.apply(_h, [_v.sent(), rentedPropertiesPagedQuery]);
                if (refetch || (((_u = oldRentedPropertiesResult === null || oldRentedPropertiesResult === void 0 ? void 0 : oldRentedPropertiesResult.items) === null || _u === void 0 ? void 0 : _u.length) || 0) === 0) {
                    getRentedPropertiesAction_1 = propertyActionsV2.getPagedStart.getAction({
                        query: rentedPropertiesPagedQuery,
                    });
                    actionsToTrigger.push(getRentedPropertiesAction_1);
                    successCheckForTriggeredActions.push(function (successAction) {
                        return doesSuccessActionMatchStartAction({
                            startAction: getRentedPropertiesAction_1,
                            successAction: successAction,
                        });
                    });
                }
                if (!(actionsToTrigger.length > 0)) return [3 /*break*/, 6];
                return [4 /*yield*/, all(actionsToTrigger.map(function (action) { return put(action); }))];
            case 5:
                _v.sent();
                _v.label = 6;
            case 6:
                if (!(successCheckForTriggeredActions.length > 0)) return [3 /*break*/, 8];
                return [4 /*yield*/, all(successCheckForTriggeredActions.map(function (action) {
                        return take(function (randomAction) { return action(randomAction); });
                    }))];
            case 7:
                _v.sent();
                _v.label = 8;
            case 8:
                _l = (_k = authSelectors).getUser;
                return [4 /*yield*/, select()];
            case 9:
                user_1 = _l.apply(_k, [_v.sent()]);
                if (!user_1)
                    throw new Error("No user");
                _o = (_m = countSelectors).getCount;
                return [4 /*yield*/, select()];
            case 10:
                contactsCount = _o.apply(_m, [_v.sent(), ECountIdentifier.Contacts]);
                _q = (_p = propertySelectorsV2).getPaged;
                return [4 /*yield*/, select()];
            case 11:
                ownedPropertiesResult = _q.apply(_p, [_v.sent(), ownedPropertiesPagedQuery]);
                _s = (_r = propertySelectorsV2).getPaged;
                return [4 /*yield*/, select()];
            case 12:
                rentedPropertiesResult = _s.apply(_r, [_v.sent(), rentedPropertiesPagedQuery]);
                ownedProperties = (ownedPropertiesResult === null || ownedPropertiesResult === void 0 ? void 0 : ownedPropertiesResult.items) || [];
                rentedProperties = (rentedPropertiesResult === null || rentedPropertiesResult === void 0 ? void 0 : rentedPropertiesResult.items) || [];
                activeProperties = __spreadArray(__spreadArray([], ownedProperties, true), rentedProperties, true);
                isBroker = user_1.personalAccountId !== user_1.activeAccountId;
                isTenant = rentedProperties.length > 0;
                isLandlord = ownedProperties.length > 0;
                hasNoProperties = activeProperties.length === 0;
                hasSingleProperty = activeProperties.length === 1;
                hasContacts = (contactsCount || 0) > 1;
                return [4 /*yield*/, call(getSelectedUserType)];
            case 13:
                userType = _v.sent();
                if (isBroker) {
                    userType = EUserType.Broker;
                }
                else if (isTenant) {
                    userType = EUserType.Tenant;
                }
                else if (isLandlord) {
                    userType = EUserType.Landlord;
                }
                extraCards_1 = [];
                switch (userType) {
                    case EUserType.Unknown: {
                        extraCards_1.push({
                            type: EExtraCardType.SelectUserType,
                            insertAt: "trailing",
                        });
                        break;
                    }
                    case EUserType.Tenant: {
                        if (!hasContacts) {
                            extraCards_1.push({
                                type: EExtraCardType.InviteLandlord,
                                insertAt: "trailing",
                            });
                        }
                        rentedProperties.forEach(function (property) {
                            property.members.forEach(function (member) {
                                // INFO: Only add technical and financial managers
                                if (!includesAny(member.roles, [
                                    EPropertyMemberTypes.FinancialManager,
                                    EPropertyMemberTypes.TechnicalManager,
                                ])) {
                                    return;
                                }
                                if (user_1.activeAccountId === member.account.activeAccountId) {
                                    return;
                                }
                                var extraData = {
                                    member: member,
                                    property: property,
                                };
                                extraCards_1.push({
                                    type: EExtraCardType.PropertyManagerContact,
                                    insertAt: "leading",
                                    extraData: extraData,
                                });
                            });
                        });
                        break;
                    }
                    case EUserType.Broker: {
                        if (!isBroker) {
                            extraCards_1.push({
                                type: EExtraCardType.BrokerInfo,
                                insertAt: "leading",
                                extraData: {},
                            });
                            break;
                        }
                    }
                    case EUserType.Landlord: {
                        if (hasNoProperties) {
                            extraCards_1.push({
                                type: EExtraCardType.CreateProperty,
                                insertAt: "trailing",
                                extraData: {},
                            });
                            // Added check, see comment above.
                        }
                        else if (hasSingleProperty) {
                            property = ownedProperties[0];
                            extraData = {
                                propertyId: property.id,
                            };
                            extraCards_1.push({
                                type: EExtraCardType.PropertyInfo,
                                insertAt: "leading",
                                extraData: extraData,
                            });
                        }
                        else {
                            extraCards_1.push({
                                type: EExtraCardType.GeneralStatusInfo,
                                insertAt: "leading",
                                extraData: {},
                            });
                        }
                        break;
                    }
                    default:
                        break;
                }
                return [4 /*yield*/, put(cardActions.generateExtraCards.actions.success({ extraCards: extraCards_1 }))];
            case 14:
                _v.sent();
                return [3 /*break*/, 17];
            case 15:
                unknownError_3 = _v.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cardActions.generateExtraCards.actions.failure({ error: error }))];
            case 16:
                _v.sent();
                return [3 /*break*/, 17];
            case 17: return [2 /*return*/];
        }
    });
}
function cardActionCallbackStartFlow(_a) {
    var cardId, actionIndex, action, type, url, callbackMethod, _b, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                cardId = payload.cardId, actionIndex = payload.actionIndex, action = payload.action;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 13, , 15]);
                type = action.type, url = action.url, callbackMethod = action.callbackMethod;
                if (type !== ECardButtonActionType.Callback || !url || !callbackMethod) {
                    throw Error("cardActionCallbackStartFlow received incorrect payload");
                }
                _b = callbackMethod;
                switch (_b) {
                    case EHttpMethod.GET: return [3 /*break*/, 2];
                    case EHttpMethod.PUT: return [3 /*break*/, 4];
                    case EHttpMethod.POST: return [3 /*break*/, 6];
                    case EHttpMethod.DELETE: return [3 /*break*/, 8];
                    case EHttpMethod.PATCH: return [3 /*break*/, 10];
                    case EHttpMethod.HEAD: return [3 /*break*/, 10];
                }
                return [3 /*break*/, 10];
            case 2: return [4 /*yield*/, call(api.get, url, {
                    isFullUrl: true,
                })];
            case 3:
                _c.sent();
                return [3 /*break*/, 11];
            case 4: return [4 /*yield*/, call(api.put, url, {
                    isFullUrl: true,
                })];
            case 5:
                _c.sent();
                return [3 /*break*/, 11];
            case 6: return [4 /*yield*/, call(api.post, url, {
                    isFullUrl: true,
                })];
            case 7:
                _c.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, call(api.delete, url, {
                    isFullUrl: true,
                })];
            case 9:
                _c.sent();
                return [3 /*break*/, 11];
            case 10: throw Error("cardActionCallbackStartFlow callback methods (".concat(callbackMethod, ") not supported"));
            case 11: return [4 /*yield*/, put(cardActions.cardActionCallback.actions.success({
                    cardId: cardId,
                    actionIndex: actionIndex,
                    action: action,
                }))];
            case 12:
                _c.sent();
                return [3 /*break*/, 15];
            case 13:
                unknownError_4 = _c.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cardActions.cardActionCallback.actions.failure({
                        cardId: cardId,
                        actionIndex: actionIndex,
                        error: error,
                    }))];
            case 14:
                _c.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
function cardActionCallbackSuccessFlow(_a) {
    var cardId, unknownError_5, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                cardId = payload.cardId;
                return [4 /*yield*/, put(cardActions.closeCard.actions.start({ cardId: cardId }))];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(cardActions.generateExtraCards.actions.start({ refetch: true }))];
            case 2:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("general.success"),
                });
                return [3 /*break*/, 4];
            case 3:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
export function cardActionCallbackFailureFlow(_a) {
    var payload = _a.payload;
    try {
        showAlert({
            type: "error",
            error: payload.error,
        });
    }
    catch (unknownError) {
        var error = unknownError;
        logger.logError({ error: error });
    }
}
function cardActionDeeplinkStartFlow(_a) {
    var cardId, actionIndex, action, type, url, data, unknownError_6, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                cardId = payload.cardId, actionIndex = payload.actionIndex, action = payload.action;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                type = action.type, url = action.url;
                if (type !== ECardButtonActionType.Deeplink || !url) {
                    throw Error("cardActionDeeplinkStartFlow received incorrect payload");
                }
                return [4 /*yield*/, call(getCardActionDynamicLink, url)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(cardActions.cardActionDeeplink.actions.success({
                        cardId: cardId,
                        actionIndex: actionIndex,
                        dynamicLink: data.shortLink,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_6 = _b.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cardActions.cardActionDeeplink.actions.failure({
                        cardId: cardId,
                        actionIndex: actionIndex,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function cardActionDeeplinkFailureFlow(_a) {
    var payload = _a.payload;
    try {
        showAlert({
            type: "error",
            error: payload.error,
        });
    }
    catch (unknownError) {
        var error = unknownError;
        logger.logError({ error: error });
    }
}
function closeCardStartFlow(_a) {
    var cardId, unknownError_7, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                cardId = payload.cardId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(closeCard, cardId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(cardActions.closeCard.actions.success({
                        cardId: cardId,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_7 = _b.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cardActions.closeCard.actions.failure({
                        cardId: cardId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function closeIndexContractCardsStartFlow(_a) {
    var contractId, cards, _b, cardIdsToClose, unknownError_8, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                contractId = payload.contractId;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 7]);
                _b = cardsSelector;
                return [4 /*yield*/, select()];
            case 2:
                cards = _b.apply(void 0, [_c.sent()]);
                cardIdsToClose = (cards || [])
                    .filter(function (card) {
                    var buttonsWithIndexContractDeeplink = (card.buttons || []).filter(function (cardButton) {
                        var _a = cardButton.action, type = _a.type, _b = _a.url, url = _b === void 0 ? "" : _b;
                        return (type === ECardButtonActionType.Deeplink &&
                            url.includes("index_contract?contractId=".concat(contractId)));
                    });
                    return buttonsWithIndexContractDeeplink.length > 0;
                })
                    .map(function (card) { return card.id; });
                return [4 /*yield*/, all(cardIdsToClose.map(function (cardId) { return call(closeCard, cardId); }))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(cardActions.closeIndexContractCards.actions.success({ contractId: contractId }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_8 = _c.sent();
                error = unknownError_8;
                logger.logError({ error: error });
                return [4 /*yield*/, put(cardActions.closeIndexContractCards.actions.failure({
                        contractId: contractId,
                        error: error,
                    }))];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(cardActions.getCards.types.START, getCardsStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.selectUserType.getType(), selectUserTypeFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.generateExtraCards.types.START, generateExtraCardsStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.cardActionCallback.types.START, cardActionCallbackStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.cardActionCallback.types.SUCCESS, cardActionCallbackSuccessFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.cardActionCallback.types.FAILURE, cardActionCallbackFailureFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.cardActionDeeplink.types.START, cardActionDeeplinkStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.cardActionDeeplink.types.FAILURE, cardActionDeeplinkFailureFlow)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.closeCard.types.START, closeCardStartFlow)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeLatest(cardActions.closeIndexContractCards.types.START, closeIndexContractCardsStartFlow)];
            case 10:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default saga;
