import { EContractMemberTypes } from "@rentiohq/shared/dist/types/contract.types";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.addLegalCase.types";
import { getRentioLegalDocumentsWhereFilter, printDocumentNamesSelector, } from "./schema.addLegalCase.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var requiredProperties = [];
        if (!extraData.leaseAgreementDocumentId)
            requiredProperties.push(EField.LeaseAgreement);
        if (!extraData.proofOfContractRegistration)
            requiredProperties.push(EField.ContractRegistrationProof);
        if (!extraData.rentDepositDocumentId)
            requiredProperties.push(EField.ActiveRentalDeposit);
        return {
            required: requiredProperties,
            properties: (_a = {},
                _a[EField.ContractInformation] = {
                    default: undefined,
                },
                _a[EField.Header + "1"] = {
                    default: undefined,
                },
                _a[EField.LeaseAgreement] = {
                    default: getValue(EField.LeaseAgreement, initialValues, defaultValues),
                },
                _a[EField.ContractRegistrationProof] = {
                    default: getValue(EField.ContractRegistrationProof, initialValues, defaultValues),
                },
                _a[EField.Header + "2"] = {
                    default: undefined,
                },
                _a[EField.ActiveRentalDeposit] = {
                    default: getValue(EField.ActiveRentalDeposit, initialValues, defaultValues),
                },
                _a[EField.Header + "3"] = {
                    default: undefined,
                },
                _a[EField.PropertyInspections] = {
                    default: getValue(EField.PropertyInspections, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    var hasLeaseAgreement = !!extraData.leaseAgreementDocumentId;
    var hasProofOfContractRegistration = !!extraData.proofOfContractRegistration;
    var hasRentDeposit = !!extraData.rentDepositDocumentId;
    var hasPropertyInspection = !!extraData.propertyInspectionDocumentId;
    var contract = extraData.contract, contractCost = extraData.contractCost;
    return _a = {
            "rentio:title": getLocalizedText("rentio_legal.form.add.step_1"),
            "rentio:navigationTitle": getLocalizedText("rentio_legal.form.title"),
            "rentio:trackingKey": "rentio-legal-case"
        },
        _a[EField.ContractInformation] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("rentio_legal.form.contract_info.details", {
                amount: contractCost,
                start_date: formatDate(contract.startDate),
                end_date: contract.stopDate ? formatDate(contract.stopDate) : "...",
                tenants: contract.members
                    .filter(function (m) { return m.roles.includes(EContractMemberTypes.Tenant); })
                    .map(function (_a) {
                    var account = _a.account;
                    return "".concat(account.firstname, " ").concat(account.lastname);
                })
                    .join(", "),
            }),
            title: getLocalizedText("rentio_legal.form.contract_info.title"),
        },
        _a[EField.Header + "1"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rentio_legal.form.lease_agreement"),
        },
        _a[EField.LeaseAgreement] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            title: getLocalizedText("rentio_legal.form.lease_agreement.signed"),
            multiple: true,
            useExistingDocuments: !hasLeaseAgreement,
            getExistingDocumentsWhereFilter: function () {
                return getRentioLegalDocumentsWhereFilter(extraData);
            },
            useNewDocuments: true,
            optional: hasLeaseAgreement,
            valueSelector: printDocumentNamesSelector,
        },
        _a[EField.ContractRegistrationProof] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            title: getLocalizedText("rentio_legal.form.proof_of_contract_registration"),
            multiple: true,
            useExistingDocuments: !hasProofOfContractRegistration,
            getExistingDocumentsWhereFilter: function () {
                return getRentioLegalDocumentsWhereFilter(extraData);
            },
            useNewDocuments: true,
            optional: hasProofOfContractRegistration,
            valueSelector: printDocumentNamesSelector,
        },
        _a[EField.Header + "2"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rentio_legal.form.rental_deposit"),
        },
        _a[EField.ActiveRentalDeposit] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            title: getLocalizedText("rentio_legal.form.rental_deposit"),
            multiple: true,
            useExistingDocuments: !hasRentDeposit,
            getExistingDocumentsWhereFilter: function () {
                return getRentioLegalDocumentsWhereFilter(extraData);
            },
            useNewDocuments: true,
            optional: hasRentDeposit,
            valueSelector: printDocumentNamesSelector,
            hideOnSummaryIfEmpty: true,
        },
        _a[EField.Header + "3"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rentio_legal.form.property_inspection"),
        },
        _a[EField.PropertyInspections] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            title: getLocalizedText("rentio_legal.form.property_inspection"),
            multiple: true,
            useExistingDocuments: hasPropertyInspection ? false : true,
            getExistingDocumentsWhereFilter: function () {
                return getRentioLegalDocumentsWhereFilter(extraData);
            },
            useNewDocuments: true,
            optional: true,
            valueSelector: printDocumentNamesSelector,
            hideOnSummaryIfEmpty: true,
        },
        _a;
};
