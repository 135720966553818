import { getSchema as getSchema1, getValidate as getValidate1, uiSchema as uiSchema1, } from "./schema.insertLoop.1";
import { getInitialValues } from "./schema.insertLoop.utils";
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: uiSchema1(extraData),
            validate: getValidate1(extraData),
        },
    ];
});
