import {
  ERevenuesInvoicedStatus,
  ERevenuesPaymentStatus,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { IUser } from "@rentiohq/shared/dist/types/auth.types";
import {
  EPaymentRequestStatus,
  EPayoutType,
} from "@rentiohq/shared/dist/types/payment.types";
import { TAppearance } from "@rentiohq/web-shared/dist/types";
import mergeDeepLeft from "ramda/es/mergeDeepLeft";

export enum ERevenueType {
  Income = "income",
  Other = "other",
  Deleted = "deleted",
}

export const FETCH_LIMIT = 20;

export const getFilterQuery = (params: {
  type: ERevenueType;
  query?: string;
  page: number;
  order?: { field: string; method: string };
  paymentStatus: ERevenuesPaymentStatus[];
  invoicesStatus: ERevenuesInvoicedStatus[];
  user?: IUser;
}) => {
  const { type, query, page, order, paymentStatus, invoicesStatus, user } =
    params;

  let whereForType = {};
  switch (type) {
    case ERevenueType.Income:
      whereForType = {
        requestPayeeAccountId: user?.activeAccountId,
        payoutType: EPayoutType.Payout,
      };
      break;

    case ERevenueType.Other:
      whereForType = {
        requestPayeeAccountId: { neq: user?.activeAccountId },
        payoutType: EPayoutType.Payout,
      };
      break;

    case ERevenueType.Deleted:
      // TODO: Check if used
      whereForType = {
        deletedAt: { neq: null },
        payoutType: EPayoutType.Payout,
      };

      break;

    default:
      break;
  }

  let newFilter: {
    where: any;
    include?: string[];
    order?: string;
    search?: string;
    page?: number;
    limit?: number;
  } = {
    where: whereForType,
    include: ["paymentOrder"],
    order: "startedAt ASC, id DESC",
    limit: FETCH_LIMIT,
  };

  if (
    invoicesStatus.length < Object.values(ERevenuesInvoicedStatus).length &&
    invoicesStatus.length > 0
  ) {
    if (invoicesStatus[0] === ERevenuesInvoicedStatus.Invoiced) {
      // @ts-ignore
      newFilter = mergeDeepLeft(
        {
          where: {
            or: [
              {
                invoicedAt: { neq: null },
              },
              {
                externalInvoiceId: { neq: null },
              },
            ],
          },
        },
        newFilter,
      );
    } else {
      // @ts-ignore
      newFilter = mergeDeepLeft(
        {
          where: {
            and: [
              {
                invoicedAt: null,
              },
              {
                externalInvoiceId: null,
              },
            ],
          },
        },
        newFilter,
      );
    }
  }

  let statuses: EPaymentRequestStatus[] = [];
  paymentStatus.forEach((simpleStatus: ERevenuesPaymentStatus) => {
    statuses = [...statuses, ...getAdvancedStatuses(simpleStatus)];
  });

  if (statuses.length > 0) {
    // @ts-ignore
    newFilter = mergeDeepLeft(
      { where: { status: { inq: statuses } } },
      newFilter,
    );
  }

  if (order) {
    // @ts-ignore
    newFilter = mergeDeepLeft(
      { order: `${order.field} ${order.method}, id DESC` },
      newFilter,
    );
  }

  if (query && query?.length > 0) {
    // @ts-ignore
    newFilter = mergeDeepLeft({ search: query }, newFilter);
  }

  if (page) {
    // @ts-ignore
    newFilter = mergeDeepLeft({ page: page || 1 }, newFilter);
  }

  return newFilter;
};

export const getSimpleStatus = (
  status: EPaymentRequestStatus,
): [ERevenuesPaymentStatus, TAppearance] => {
  switch (status) {
    case EPaymentRequestStatus.Paid:
      return [ERevenuesPaymentStatus.Paid, "success"];

    case EPaymentRequestStatus.New:
      return [ERevenuesPaymentStatus.Unpaid, "warning"];

    default:
      return [ERevenuesPaymentStatus.Pending, "info"];
  }
};

export const getAdvancedStatuses = (status: ERevenuesPaymentStatus) => {
  switch (status) {
    case ERevenuesPaymentStatus.Paid:
      return [EPaymentRequestStatus.Paid];

    case ERevenuesPaymentStatus.Unpaid:
      return [EPaymentRequestStatus.New];

    case ERevenuesPaymentStatus.Pending:
      return [
        EPaymentRequestStatus.Approved,
        EPaymentRequestStatus.Pending,
        EPaymentRequestStatus.PendingThirdParty,
        EPaymentRequestStatus.PayInPlanned,
        EPaymentRequestStatus.PaidIn,
        EPaymentRequestStatus.Partial,
        EPaymentRequestStatus.Transferring,
        EPaymentRequestStatus.Transferred,
        EPaymentRequestStatus.PayingOut,
        EPaymentRequestStatus.FailedPaidIn,
        EPaymentRequestStatus.FailedTransfer,
        EPaymentRequestStatus.FailedPayout,
        EPaymentRequestStatus.FailedKyc,
        EPaymentRequestStatus.Failed,
      ];

    default:
      return [];
  }
};
