import styled from "@rentiohq/web-theme";

export const ChecklistProgressWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 9px;
  border-radius: 100px;
  margin-bottom: 8px;
  background-color: ${props => props.theme.colors.global3};
`;

export const Pending = styled.div<{ pending: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => Math.max(0, Math.min(100, props.pending * 100))}%;
  transition: width 0.3s ease;
  height: 100%;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.yellow[800]};
`;

export const Completed = styled.div<{ completed: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => Math.max(0, Math.min(100, props.completed * 100))}%;
  transition: width 0.3s ease;
  height: 100%;
  border-radius: 100px;
  background-color: ${props => props.theme.colors.global5};
`;
