import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Card, Loading, Page } from "@rentiohq/web-shared/dist/components";
import { IInvoiceItemsUninvoicedOutletContextType } from "@rentiohq/web-shared/dist/components/InvoiceItemsUninvoiced/InvoiceItemsUninvoiced.types";
import { IInvoiceTableOutletContextType } from "@rentiohq/web-shared/dist/components/InvoiceTable/InvoiceTable.types";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { Outlet, useLocation } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../services/translationService";

export const Profile = () => {
  const { internalModeEnabled } = useInternalMode();

  let location = useLocation();

  const dispatch = useDispatch();

  const { user } = authHooks.useSelf();
  const isFetchingUser = useSelector(
    (state: IRootStore) => state.auth.isFetchingUser,
  );
  const fetchUserError = useSelector(
    (state: IRootStore) => state.auth.fetchUserError,
  );

  React.useEffect(() => {
    dispatch(authActions.getUser.actions.start({}));
  }, []);

  if (isFetchingUser) {
    return <Loading />;
  }

  if (!user) {
    return null;
  }

  const tabs = [];

  tabs.push({
    name: "personal",
    content: t.profileDetailTabsPersonal(),
    url: "personal",
    isActive: !!matchPath("/profile/personal", location.pathname),
  });

  if (user.activeAccountId !== user.personalAccountId) {
    tabs.push({
      name: "financial",
      content: t.profileDetailTabsFinancial(),
      url: "financial",
      isActive: !!matchPath("/profile/financial", location.pathname),
    });
  }

  tabs.push({
    name: "extra",
    content: t.profileDetailTabsExtra(),
    url: "extra",
    isActive: !!matchPath("/profile/extra", location.pathname),
  });

  if (internalModeEnabled) {
    tabs.push({
      name: "invoice",
      content: getLocalizedText("user.detail.tabs.invoices"),
      url: "invoices",
      isActive: !!matchPath("/profile/invoices", location.pathname),
    });
  }

  tabs.push({
    name: "tac",
    content: t.profileDetailTabsTac(),
    url: "tac",
    isActive: !!matchPath("/profile/tac", location.pathname),
  });

  tabs.push({
    name: "export-files",
    content: getLocalizedText("user.detail.tabs.export_files"),
    url: "export-files",
    isActive: !!matchPath("/profile/export-files", location.pathname),
  });

  const context: IInvoiceTableOutletContextType &
    IInvoiceItemsUninvoicedOutletContextType &
    IInvoiceTableOutletContextType = {
    invoiceAccountId: user.activeAccountId,
  };

  return (
    <Page title={t.profileTitle()} tabs={tabs} error={fetchUserError}>
      <Card>
        <Outlet context={context} />
      </Card>
    </Page>
  );
};
