var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  background-color: ", ";\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.theme.colors.global5; });
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  max-width: 340px;\n  padding: 4px 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  max-width: 340px;\n  padding: 4px 16px;\n"])));
export var Title = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 600;\n  font-size: 24px;\n  padding: 24px 0 8px;\n  text-align: center;\n"], ["\n  color: ", ";\n  font-weight: 600;\n  font-size: 24px;\n  padding: 24px 0 8px;\n  text-align: center;\n"])), function (props) { return props.theme.colors.neutral2; });
export var Message = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 16px;\n  margin-bottom: 16px;\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: 16px;\n  margin-bottom: 16px;\n  text-align: center;\n"])), function (props) { return props.theme.colors.neutral2; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
