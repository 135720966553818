import { Route } from "react-router-dom";
import { Properties } from "./Properties";
import { getPropertyDetailScene } from "./scenes/Detail";
import { PropertyForm } from "./scenes/PropertyForm";

export const getPropertiesScene = () => (
  <Route path="properties">
    <Route index element={<Properties />} />

    <Route path="add" element={<PropertyForm />} />

    {getPropertyDetailScene()}
  </Route>
);
