import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import { ILegalCase } from "@rentiohq/shared/dist/types/legalCase.types";
import { getMembersWithRole } from "@rentiohq/shared/dist/utils/roles.utils";
import {
  Icon,
  Loading,
  TextStyle,
  TruncateMiddle,
} from "@rentiohq/web-shared/dist/components";
import { IDataTableItem } from "@rentiohq/web-shared/dist/components/DataTable/DataTable.utils";
import { compact } from "lodash";
import StatusDotContractRentDeposit from "scenes/Properties/components/StatusDotContractRentDeposit/StatusDotContractRentDeposit";
import LatePaymentsCount from "./components/LatePaymentsCount";
import { LegalCaseActionButton } from "./components/LegalCaseActionButton";

export const getDataTableItems = (params: {
  openCases?: ILegalCase[];
}): IDataTableItem<IContract>[] => {
  const { openCases } = params;

  return [
    {
      heading: getLocalizedText("rentio_legal.overview.columns.property"),
      columnContentType: "text",
      sortable: false,
      renderContent: (contract: IContract) => {
        const { propertyAddress } = contract;
        if (!propertyAddress) {
          return null;
        }

        return (
          <>
            {propertyAddress.line1 && (
              <TextStyle>
                <TruncateMiddle
                  input={propertyAddress.line1}
                  skipInitialChars={14}
                />
              </TextStyle>
            )}
            {propertyAddress.line2 && (
              <TextStyle variation={"subdued"}>
                {propertyAddress.line2}
              </TextStyle>
            )}
          </>
        );
      },
    },

    {
      heading: getLocalizedText("rentio_legal.overview.columns.owners"),
      columnContentType: "text",
      sortable: false,
      renderContent: ({ members }: IContract) => (
        <>
          {getMembersWithRole(members, EContractMemberTypes.Owner).map(
            (member, index) => (
              <>
                {index > 0 && <br />}
                {getName(member.account)}
              </>
            ),
          )}
        </>
      ),
    },

    {
      heading: getLocalizedText("rentio_legal.overview.columns.tenants"),
      columnContentType: "text",
      sortable: false,
      renderContent: ({ members }: IContract) => (
        <>
          {getMembersWithRole(members, EContractMemberTypes.Tenant).map(
            (member, index) => (
              <>
                {index > 0 && <br />}
                {getName(member.account)}
              </>
            ),
          )}
        </>
      ),
    },

    {
      heading: getLocalizedText(
        "rentio_legal.overview.columns.rentio_legal_state",
      ),
      columnContentType: "icon",
      sortable: false,
      renderContent: ({ legalLicenseActivatedAt }: IContract) => {
        return legalLicenseActivatedAt ? (
          <Icon source="close" size="small" color="red" />
        ) : (
          <Icon source="checkboxMark" size="large" color="green" />
        );
      },
    },

    {
      heading: getLocalizedText(
        "rentio_legal.overview.columns.contract_period",
      ),
      columnContentType: "text",
      sortable: false,
      renderContent: ({ startDate, stopDate }: IContract) =>
        compact([startDate, stopDate])
          .map(date => formatDate(date))
          .join(" - "),
    },

    {
      heading: getLocalizedText("rentio_legal.overview.columns.rental_deposit"),
      columnContentType: "text",
      sortable: false,
      renderContent: (contract: IContract) => (
        <StatusDotContractRentDeposit contract={contract} />
      ),
    },

    {
      heading: getLocalizedText("rentio_legal.overview.columns.late_payments"),
      columnContentType: "text",
      sortable: false,
      renderContent: (contract: IContract) => (
        <LatePaymentsCount contract={contract} />
      ),
    },

    {
      heading: getLocalizedText("rentio_legal.overview.columns.actions"),
      columnContentType: "action",
      sortable: false,
      renderContent: (contract: IContract) => {
        if (!openCases) {
          return <Loading />;
        }

        const openCase = openCases?.find(x => x.contractId === contract.id);

        return (
          <LegalCaseActionButton contract={contract} openCase={openCase} />
        );
      },
    },
  ];
};
