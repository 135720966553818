import api from "../../utils/api/api.utils";
export var getInsuranceForContract = function (contractId) {
    return api.get("/contracts/".concat(contractId, "/insurance-status"));
};
export var getInsurance = function (id) {
    return api.get("/insurance/id/".concat(id));
};
export var pollStatusExternal = function (id) {
    return api.post("/insurance/".concat(id, "/poll-status-external"));
};
export var resendInviteExternal = function (id) {
    return api.post("/insurance/".concat(id, "/resend-invite-external"));
};
export var resetInsuranceProposal = function (externalCode) {
    return api.post("/insurance/".concat(externalCode, "/reset"));
};
