import { EContractPaydate, EContractPaymentRepetitionType, } from "@rentiohq/shared/dist/types/contract.types";
import { addMonths, addQuarters, addYears, getDay, getMonth, getQuarter, getYear, isAfter, isBefore, isEqual, isToday, max, setDay, setQuarter, setYear, startOfDay, startOfMonth, startOfQuarter, startOfYear, subMonths, subQuarters, subYears, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { stripTime } from "@rentiohq/shared/dist/utils/date.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle, getRelatedContract, hasProRata, isNewContract, isRentOrCost, showStartDateAsDropdown, } from "./schema.createPayment.utils";
var NOW = startOfDay(new Date());
var formatDateValue = function (date) {
    return isToday(date)
        ? "".concat(formatDate(date), " (").concat(getLocalizedText("system.today"), ")")
        : formatDate(date);
};
var getStartDateDropdownOptions = function (contract) {
    if (!contract) {
        return [];
    }
    var startDateWithTime = contract.startDate, payDateStart = contract.payDateStart, _a = contract.repetitionType, repetitionType = _a === void 0 ? EContractPaymentRepetitionType.Monthly : _a;
    var startDate = new Date(getYear(startDateWithTime), getMonth(startDateWithTime), getDay(startDateWithTime));
    // Create options for first start date by adding month
    var options = [];
    // Function to add/get start of month, quarter, year based on repetition type
    var addX = function (date, _) { return date; };
    var startOfX = function (date) { return date; };
    // 1
    // Get first possible start date (not including pro-rata)
    var startDateOptions = NOW;
    var startDateCappedNow = startDate;
    var dateOptionsIterations = 0;
    switch (repetitionType) {
        case EContractPaymentRepetitionType.Monthly: {
            addX = addMonths;
            startOfX = startOfMonth;
            dateOptionsIterations = 5;
            startDateCappedNow = max([startDate, setDay(NOW, getDay(startDate))]);
            break;
        }
        case EContractPaymentRepetitionType.Quarterly: {
            addX = addQuarters;
            startOfX = startOfQuarter;
            dateOptionsIterations = 4;
            startDateCappedNow = startDate;
            while (isBefore(startDateCappedNow, NOW)) {
                startDateCappedNow = addQuarters(startDateCappedNow, 1);
            }
            break;
        }
        case EContractPaymentRepetitionType.Yearly: {
            addX = addYears;
            startOfX = startOfYear;
            dateOptionsIterations = 3;
            startDateCappedNow = max([startDate, setYear(startDate, getYear(NOW))]);
            break;
        }
        default:
            break;
    }
    // Get startDateOptions based on contract type (can be in past)
    if (payDateStart === EContractPaydate.Calendar) {
        startDateOptions = startOfX(startDateCappedNow);
    }
    else if (payDateStart === EContractPaydate.Contract) {
        startDateOptions = startDateCappedNow;
    }
    // If startDateOptions is in past add x
    if (
    // Is before now
    isBefore(startDateOptions, NOW) ||
        // Is before start date & start date in future
        (isBefore(startDateOptions, startDate) && isAfter(startDate, NOW))) {
        startDateOptions = addX(startDateOptions, 1);
    }
    // Add possibility to select start of running period (month, quarter, year)
    var prevDate = stripTime(addX(startDateOptions, -1));
    if (!isToday(startDateOptions) &&
        (isBefore(startDate, prevDate) || isEqual(startDate, prevDate)) &&
        isBefore(startDate, NOW)) {
        options.push({
            id: prevDate.toISOString(),
            value: formatDateValue(prevDate),
        });
    }
    // 2
    // Get pro-rata option (1st option)
    if (isNewContract(contract) && payDateStart === EContractPaydate.Calendar) {
        var proRataDate = void 0;
        switch (repetitionType) {
            case EContractPaymentRepetitionType.Monthly: {
                var hasProRata_1 = getDay(startDate) !== 1;
                if (hasProRata_1) {
                    proRataDate = setDay(subMonths(startDateOptions, 1), getDay(startDate));
                }
                break;
            }
            case EContractPaymentRepetitionType.Quarterly: {
                var hasProRata_2 = getDay(startDate) !== 1 ||
                    ![1, 4, 7, 10].includes(getMonth(startDate) + 1);
                if (hasProRata_2) {
                    proRataDate = subQuarters(setQuarter(setYear(startDate, getYear(startDateOptions)), getQuarter(startDateOptions)), 1);
                }
                break;
            }
            case EContractPaymentRepetitionType.Yearly: {
                var hasProRata_3 = getDay(startDate) !== 1 || getMonth(startDate) + 1 !== 1;
                if (hasProRata_3) {
                    proRataDate = subYears(setYear(startDate, getYear(startDateOptions)), 1);
                }
                break;
            }
            default:
                break;
        }
        if (proRataDate) {
            options.push({
                id: proRataDate.toISOString(),
                value: formatDateValue(proRataDate),
                isDefault: true,
            });
        }
    }
    // Create options based on startDateOptions
    for (var periodIndex = 0; periodIndex < dateOptionsIterations; periodIndex++) {
        var date = stripTime(addX(startDateOptions, periodIndex));
        options.push({
            id: date.toISOString(),
            value: formatDateValue(date),
            isDefault: periodIndex === 0,
        });
    }
    return options;
};
var getPaydateValueForIndex = function (index) {
    var normalizedIndex = typeof index === "string" ? parseInt(index, 0) : index;
    return getLocalizedText("payment.payment_order.paydate_offset.value".concat(normalizedIndex < 0 ? "_negative" : ""), {
        days: "".concat(Math.abs(normalizedIndex)),
    }, Math.abs(normalizedIndex));
};
var getPaydateOffsetDropdownOptions = function () {
    var options = [];
    for (var index = -14; index < 0; index++) {
        options.push({
            id: "".concat(index),
            value: getPaydateValueForIndex("".concat(index)),
        });
    }
    options.push({ id: "0", value: getPaydateValueForIndex("0") });
    for (var index = 1; index <= 14; index++) {
        options.push({
            id: "".concat(index),
            value: getPaydateValueForIndex("".concat(index)),
        });
    }
    return options;
};
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var contract = getRelatedContract(extraData, defaultValues);
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header + "2"] = {
                    default: undefined,
                },
                _a[EField.Info] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (formData) { return !!formData[EField.TypeRecurring]; },
                },
                _a[EField.StartDate] = {
                    default: (function () {
                        if (contract && isRentOrCost(defaultValues[EField.TypeRecurring])) {
                            return contract.startDate;
                        }
                        return getValue(EField.StartDate, initialValues, defaultValues);
                    })(),
                    // @ts-ignore
                    showIf: function (formData) { return !showStartDateAsDropdown(formData); },
                    // @ts-ignore
                    requiredIf: function (formData) { return !showStartDateAsDropdown(formData); },
                },
                _a[EField.StartDateDropdown] = {
                    default: (function () {
                        var value = getValue(EField.StartDateDropdown, initialValues, defaultValues);
                        if (!value) {
                            var options = getStartDateDropdownOptions(contract);
                            if (options.length > 0) {
                                var defaultItem = options.find(function (item) { return item.isDefault; });
                                if (defaultItem) {
                                    return defaultItem.id;
                                }
                                return options[0].id;
                            }
                        }
                        return value;
                    })(),
                    // @ts-ignore
                    options: getStartDateDropdownOptions(contract),
                    // @ts-ignore
                    showIf: function (formData) { return showStartDateAsDropdown(formData); },
                    // @ts-ignore
                    requiredIf: function (formData) { return showStartDateAsDropdown(formData); },
                },
                _a[EField.PaydateOffset] = {
                    default: getValue(EField.PaydateOffset, initialValues, defaultValues),
                    // @ts-ignore
                    options: getPaydateOffsetDropdownOptions(),
                    showIf: function (formData) { return formData[EField.TypeRecurring]; },
                },
                _a[EField.FirstPaymentReceivedDate] = {
                    default: getValue(EField.FirstPaymentReceivedDate, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        // Hide for non-recurring payments
                        if (formData[EField.TypeOnce]) {
                            return false;
                        }
                        // Hide for old contracts
                        if (contract && !isNewContract(contract)) {
                            return false;
                        }
                        // Show if no pro rata
                        return !hasProRata(formData, contract);
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function (initialValues, extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payments.create.date.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "2"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function () { return getLocalizedText("payments.create.date.heading"); },
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("payment.payment_order.contract_info.heading"),
            getSubtitle: function (state, formData) {
                var contract = getRelatedContract(extraData, formData);
                if (!contract) {
                    return getLocalizedText("payments.create.add_costs.no_contract.content");
                }
                var commonCostsCopy = contract.currentCostsCommon
                    ? getLocalizedText("payment.payment_order.contract_info.content.basic.common_costs", {
                        commonCosts: formatCurrency(contract.currentCostsCommon),
                    })
                    : null;
                var nonCommonCostsCopy = contract.currentCostsNonCommon
                    ? getLocalizedText("payment.payment_order.contract_info.content.basic.non_common_costs", {
                        nonCommonCosts: formatCurrency(contract.currentCostsNonCommon),
                    })
                    : null;
                var costsArray = [commonCostsCopy, nonCommonCostsCopy].filter(Boolean);
                return getLocalizedText("payment.payment_order.contract_info.content.basic".concat(contract.stopDate ? "" : ".no_end_date"), {
                    startDate: formatDate(contract.startDate),
                    stopDate: contract.stopDate ? formatDate(contract.stopDate) : "",
                    amount: formatCurrency(contract.currentPrice || 0),
                    costs: costsArray.length > 0
                        ? " + ".concat(costsArray.join(" ".concat(getLocalizedText("system.and"), " ")))
                        : "",
                    payDate: contract.payDateStart === EContractPaydate.Calendar
                        ? getLocalizedText("contract.paydate.before_month")
                        : getLocalizedText("contract.paydate.before_period"),
                });
            },
            // TODO: Icon
            // icon: images.ICO_CHAT_SEND
        },
        _a[EField.StartDate] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("payment.payment_order.due_date.once.label.basic"),
            minDate: subYears(NOW, 1),
            maxDate: addYears(NOW, 40),
            formatSummary: function (formData) { return formData && formatDate(formData); },
            hideOnSummaryIfEmpty: true,
            isDisabled: function (formData) { return isRentOrCost(formData[EField.TypeRecurring]); },
        },
        _a[EField.StartDateDropdown] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("payment.payment_order.start_date.label"),
            formatSummary: function (formData) { return formData && formatDate(formData); },
            hideOnSummaryIfEmpty: true,
        },
        _a[EField.PaydateOffset] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("payment.payment_order.paydate_offset.label"),
            formatSummary: function (formData) { return getPaydateValueForIndex(formData); },
            hideOnSummaryIfEmpty: true,
        },
        _a[EField.FirstPaymentReceivedDate] = {
            "ui:field": ERentioFormField.Date,
            getTitle: function (formData) {
                return getLocalizedText("payment.payment_order.due_date.label.basic", {
                    type: formData[EField.OtherDescription] ||
                        getLocalizedText("payment.payment_order.type.".concat(formData[EField.TypeRecurring] || formData[EField.TypeOnce]).toLowerCase()),
                });
            },
            minDate: NOW,
            maxDate: addYears(NOW, 1),
            formatSummary: function (formData) { return formData && formatDate(formData); },
            hideOnSummaryIfEmpty: true,
        },
        _a);
};
