import styled from "@rentiohq/web-theme";
import { lighten } from "polished";

export const Wrap = styled.div`
  position: relative;
  width: 100%;
`;

export const InviteCardsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
`;

export const InviteCardOuterWrap = styled.div<{ isLeft: boolean }>`
  flex: 0 50%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: ${props => (props.isLeft ? 0 : 6)}px;
  padding-right: ${props => (props.isLeft ? 6 : 0)}px;
`;

export const InviteCardWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  background-color: ${props => lighten(0.6, props.theme.colors.global5)};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
`;

export const InviteCardTitle = styled.span`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.global1};
`;

export const InviteCardInfo = styled.span`
  text-align: center;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-bottom: 20px;
  max-width: 350px;
  color: ${props => props.theme.colors.global2};
  flex: 1;
`;

export const InviteCardButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const InviteCardButtonWrap = styled.div`
  flex: 1;
`;
