var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { DEFAULT_GROUPS, DEFAULT_VARIABLES, EDefaultVariableId, } from "../../Editor/Editor.config";
export var OPTION_NEW = "NEw";
export var getNameOptions = function (params) {
    var fields = params.fields, withNewOption = params.withNewOption;
    var groups = __spreadArray(__spreadArray([], DEFAULT_GROUPS, true), fields.groups, true);
    var result = [];
    result.push.apply(result, fields.variables.map(function (variable) {
        var group = groups.find(function (x) { return x.id === (variable === null || variable === void 0 ? void 0 : variable.groupId); });
        return {
            id: variable.id,
            value: "".concat(getLocalizedText((group === null || group === void 0 ? void 0 : group.name) || ""), " - ").concat(getLocalizedText(variable.name)),
        };
    }));
    result.push.apply(result, DEFAULT_VARIABLES.map(function (variable) {
        var group = groups.find(function (x) { return x.id === variable.groupId; });
        return {
            id: variable.id,
            value: "".concat(getLocalizedText((group === null || group === void 0 ? void 0 : group.name) || ""), " - ").concat(getLocalizedText(variable.name)),
        };
    }));
    result = sortBy(uniqBy(result, "id"), "value");
    if (withNewOption) {
        result = __spreadArray([
            {
                id: OPTION_NEW,
                value: getLocalizedText("editor.insert_custom.insert_variable.field.name.option.new"),
            }
        ], result, true);
    }
    return result;
};
export var getGroupOptions = function (params) {
    var fields = params.fields;
    var result = [];
    result.push.apply(result, fields.groups.map(function (group) {
        return {
            id: group.id,
            value: getLocalizedText((group === null || group === void 0 ? void 0 : group.name) || ""),
        };
    }));
    result.push.apply(result, DEFAULT_GROUPS.map(function (group) { return ({
        id: group.id,
        value: getLocalizedText(group.name),
    }); }));
    result = sortBy(uniqBy(result, "id"), "value");
    result = __spreadArray([
        {
            id: OPTION_NEW,
            value: getLocalizedText("editor.insert_custom.insert_variable.field.group.option.new"),
        }
    ], result, true);
    return result;
};
export var isDefaultVariable = function (params) {
    var variable = params.variable;
    return Object.values(EDefaultVariableId).includes(variable.id);
};
