import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EPaymentOrderType } from "@rentiohq/shared/dist/types/payment.types";
import { ERentioFormField } from "../../components/components/Form";
import { getIbans } from "../../redux/contact/contact.actions";
import * as contactSelectors from "../../redux/contact/contact.selectors";
import { getValue, ibanSelectInlineValueSelector, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getReference } from "./schema.createPayment.6.utils";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle, getStartDate } from "./schema.createPayment.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [
                EField.ManagementFeeIban,
                EField.ManagementFeePayoutReferenceType,
            ],
            properties: (_a = {},
                _a[EField.Header + "13"] = {
                    default: undefined,
                },
                _a[EField.ManagementFeeIban] = {
                    default: getValue(EField.ManagementFeeIban, initialValues, defaultValues),
                },
                _a[EField.ManagementFeePayoutReferenceType] = {
                    default: getValue(EField.ManagementFeePayoutReferenceType, initialValues, defaultValues),
                    // @ts-ignore
                    options: ["DEFAULT", "CUSTOM"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("payment.payment_order.payout_reference_type.".concat(id).toLowerCase()),
                    }); }),
                },
                _a[EField.ManagementFeePayoutReference] = {
                    default: getValue(EField.ManagementFeePayoutReference, initialValues, defaultValues),
                    maxLength: 10,
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.ManagementFeePayoutReferenceType] !== "CUSTOM";
                    },
                },
                _a[EField.ManagementFeePayoutReferenceCustom] = {
                    default: getValue(EField.ManagementFeePayoutReferenceCustom, initialValues, defaultValues),
                    maxLength: 106,
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.ManagementFeePayoutReferenceType] === "CUSTOM";
                    },
                    requiredIf: function (formData) {
                        return formData[EField.ManagementFeePayoutReferenceType] === "CUSTOM";
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payment.payment_order.management_iban.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "13"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.management_iban.heading");
            },
        },
        _a[EField.ManagementFeeIban] = {
            "ui:field": ERentioFormField.IbanSelectInline,
            title: getLocalizedText("payment.payment_order.management_iban.field"),
            addIbanCtaTitle: getLocalizedText("payment.payment_order.iban.add.cta"),
            valueSelector: function (state, input) {
                return ibanSelectInlineValueSelector(state, input);
            },
            accountIdToAddIban: function (formData) { var _a; return (_a = formData[EField.ManagementFeePayee]) === null || _a === void 0 ? void 0 : _a[0]; },
            customIsFetchingSelector: function (state, formData) {
                var _a;
                return contactSelectors.isFetchingIbans(state, undefined, (_a = formData[EField.ManagementFeePayee]) === null || _a === void 0 ? void 0 : _a[0]);
            },
            customIbansSelector: function (state, formData) {
                var _a;
                return contactSelectors.getIbans(state, undefined, (_a = formData[EField.ManagementFeePayee]) === null || _a === void 0 ? void 0 : _a[0]);
            },
            customGetIbansActions: function (formData) { return [
                function () {
                    var _a;
                    return getIbans.actions.start({
                        accountId: (_a = formData[EField.ManagementFeePayee]) === null || _a === void 0 ? void 0 : _a[0],
                    });
                },
            ]; },
        },
        _a[EField.ManagementFeePayoutReferenceType] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("payment.payment_order.payout_reference_type.label"),
            formatSummary: function (input) {
                return input
                    ? getLocalizedText("payment.payment_order.payout_reference_type.".concat(input).toLowerCase())
                    : "";
            },
        },
        _a[EField.ManagementFeePayoutReference] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("payment.payment_order.payout_reference.label"),
            placeholder: getLocalizedText("payment.payment_order.payout_reference.placeholder"),
            info: getLocalizedText("payment.payment_order.payout_reference.info"),
            extraInfoUrl: getLocalizedText("payment.payment_order.payout_reference.url"),
            optional: true,
            getIMaskProps: function (formData) { return ({
                mask: getReference({
                    asMask: true,
                    property: extraData.property,
                    paymentOrderType: EPaymentOrderType.ManagementCosts,
                    startDate: getStartDate(formData),
                }),
                placeholderChar: ". ",
                prepare: function (input) { return input.toUpperCase(); },
                lazy: false,
                unmask: true,
            }); },
            hideOnSummaryIfEmpty: true,
            formatSummary: function (value, formData) {
                return getReference({
                    asMask: false,
                    reference: value,
                    property: extraData.property,
                    paymentOrderType: EPaymentOrderType.ManagementCosts,
                    startDate: getStartDate(formData),
                }).toUpperCase();
            },
        },
        _a[EField.ManagementFeePayoutReferenceCustom] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("payment.payment_order.payout_reference_custom.label"),
            placeholder: getLocalizedText("payment.payment_order.payout_reference_custom.placeholder"),
            info: getLocalizedText("payment.payment_order.payout_reference_custom.info"),
            // extraInfoUrl: getLocalizedText(
            //   "payment.payment_order.payout_reference_custom.url",
            // ),
        },
        _a);
};
export var getValidate = function (_) { return function (formData, errors) {
    if (errors[EField.ManagementFeePayoutReference]) {
        var payoutReference = (formData[EField.ManagementFeePayoutReference] ||
            "");
        if (payoutReference.length > 0) {
            // Contains only alphanumeric characters & space
            var isValid = CONFIG.PAYOUT_REFERENCE_REGEX.test(payoutReference);
            if (!isValid) {
                errors[EField.ManagementFeePayoutReference].addError(getLocalizedText("payment.payment_order.payout_reference.error.no_special_characters"));
            }
        }
    }
    if (errors[EField.ManagementFeePayoutReferenceCustom]) {
        var payoutReference = (formData[EField.ManagementFeePayoutReferenceCustom] || "");
        if (payoutReference.length > 0) {
            // Contains only alphanumeric characters & space
            var isValid = CONFIG.PAYOUT_REFERENCE_REGEX.test(payoutReference);
            if (!isValid) {
                errors[EField.ManagementFeePayoutReferenceCustom].addError(getLocalizedText("payment.payment_order.payout_reference.error.no_special_characters"));
            }
        }
    }
    return errors;
}; };
