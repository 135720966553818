import { Route } from "react-router-dom";
import { RentDepositForm } from "../../../RentDepositForm";
import { ContractsOverview } from "./ContractsOverview";
import { ContractInfo } from "./scenes/ContractInfo";

export const getContractsSceneAtPropertyDetailRoot = () => (
  <Route path="contracts">
    <Route path="add" element={<ContractInfo />} />

    <Route path=":contractId/edit" element={<ContractInfo />} />
    <Route
      path=":contractId/delete-cancellation"
      element={<ContractInfo deleteCancellation />}
    />

    <Route path=":contractId/rent-deposit" element={<RentDepositForm />} />
  </Route>
);

export const getContractsScene = () => (
  <Route path="contracts">
    <Route index element={<ContractsOverview />} />
  </Route>
);
