import { Box } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ESpacings, MultiStepForm } from "@rentiohq/web-shared/dist/components";
import Banner from "@rentiohq/web-shared/dist/components/Banner";
import React from "react";
import createSchema from "../../../forms/tenantProfileBroker";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "../../../forms/tenantProfileBroker/schema.tenantProfileBroker.actions";

const formId = generateFormId();

const TenantProfilePreferences: React.FC<{}> = () => {
  const { broker } = authHooks.useSelf();

  const renderContent = () => {
    if (!broker) {
      return null;
    }

    return (
      <>
        <Box mb={ESpacings.loose}>
          <Banner
            hasDismiss={false}
            icon="contentPenWrite"
            title={getLocalizedText("broker.tenant_profile.info.heading")}
          />
        </Box>
        <MultiStepForm
          formId={`update-broker-${formId}`}
          schemas={createSchema({ broker })}
          withAside={false}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            broker.id,
          )}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector(
            broker.id,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            broker.id,
          )}
          submitActionCreator={submitActionCreator(broker.id)}
          submitLabel={getLocalizedText("system.save")}
        />
      </>
    );
  };

  return renderContent();
};

export default TenantProfilePreferences;
