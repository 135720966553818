export var EMoveServiceManager;
(function (EMoveServiceManager) {
    EMoveServiceManager["TenantAndBroker"] = "tenantAndBroker";
    EMoveServiceManager["Tenant"] = "tenant";
    EMoveServiceManager["MijnVerhuis"] = "mijnVerhuis";
})(EMoveServiceManager || (EMoveServiceManager = {}));
export var EMoveServiceSupplier;
(function (EMoveServiceSupplier) {
    // cspell:disable
    EMoveServiceSupplier["Bolt"] = "Bolt";
    EMoveServiceSupplier["Ecopower"] = "Ecopower";
    EMoveServiceSupplier["Elegant"] = "Elegant";
    EMoveServiceSupplier["Eneco"] = "Eneco";
    EMoveServiceSupplier["Engie"] = "Engie";
    EMoveServiceSupplier["Essent"] = "Essent";
    EMoveServiceSupplier["Lampiris"] = "Lampiris";
    EMoveServiceSupplier["Luminus"] = "Luminus";
    EMoveServiceSupplier["Mega"] = "Mega";
    EMoveServiceSupplier["OctaPlus"] = "Octa+";
    EMoveServiceSupplier["Trevision"] = "Trevision";
    EMoveServiceSupplier["VlaamseEnergieleverancier"] = "Vlaamse Energieleverancier";
    EMoveServiceSupplier["Watz"] = "Watz";
    // cspell:enable
})(EMoveServiceSupplier || (EMoveServiceSupplier = {}));
export var EMoveServiceService;
(function (EMoveServiceService) {
    EMoveServiceService["Energy"] = "energy";
    EMoveServiceService["Telco"] = "telco";
    EMoveServiceService["Water"] = "water";
})(EMoveServiceService || (EMoveServiceService = {}));
export var EMoveRequestContactReachability;
(function (EMoveRequestContactReachability) {
    EMoveRequestContactReachability["AM"] = "AM";
    EMoveRequestContactReachability["PM"] = "PM";
    EMoveRequestContactReachability["Evening"] = "Evening";
})(EMoveRequestContactReachability || (EMoveRequestContactReachability = {}));
export var ETelcoProduct;
(function (ETelcoProduct) {
    ETelcoProduct["Internet"] = "internet";
    ETelcoProduct["Tv"] = "tv";
    ETelcoProduct["Mobile"] = "mobile";
    ETelcoProduct["Landline"] = "landline";
})(ETelcoProduct || (ETelcoProduct = {}));
export var EMoveRequestDocumentCategory;
(function (EMoveRequestDocumentCategory) {
    EMoveRequestDocumentCategory[EMoveRequestDocumentCategory["ElectricityGeneral"] = 0] = "ElectricityGeneral";
    EMoveRequestDocumentCategory[EMoveRequestDocumentCategory["NaturalGasGeneral"] = 1] = "NaturalGasGeneral";
    EMoveRequestDocumentCategory[EMoveRequestDocumentCategory["WaterGeneral"] = 2] = "WaterGeneral";
})(EMoveRequestDocumentCategory || (EMoveRequestDocumentCategory = {}));
