var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EExtraIbanSelectValues } from "@rentiohq/shared/dist/types/contact.types";
import { EContractMemberTypes, EContractType, } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyMemberTypes, } from "@rentiohq/shared/dist/types/property.types";
import compact from "lodash/compact";
import { releaseRentDeposit } from "../../redux/rentDeposit/rentDeposit.actions";
import { EField } from "./schema.releaseRentDeposit.types";
export var isPerformingSubmitActionSelector = function () { return function (state) {
    return !!state.rentDeposit.isReleasingRentDeposit;
}; };
export var performingSubmitActionResultSelector = function () { return function (state) {
    return state.rentDeposit.releasedRentDepositId;
}; };
export var performingSubmitActionErrorSelector = function () { return function (state) {
    return state.rentDeposit.releaseRentDepositError;
}; };
export var submitActionCreator = function (rentDepositId, relatedContract, property) {
    return function (formData) {
        var signers = __spreadArray([], (formData[EField.Signers] || []), true);
        var signAsBroker = formData[EField.SignAsBroker] || [];
        if (signAsBroker.length > 0) {
            signers.push(+signAsBroker[0]);
        }
        var mappedSigners = compact(signers.map(function (accountId) {
            var _a;
            var contractMember = relatedContract.members.find(function (member) { return member.account.id === accountId; });
            var propertyMember = property.members.find(function (member) { return member.account.id === accountId; });
            if (!contractMember && !propertyMember) {
                return undefined;
            }
            var roles = [EContractMemberTypes.Signer];
            if ((contractMember === null || contractMember === void 0 ? void 0 : contractMember.roles.includes(EContractMemberTypes.Tenant)) ||
                (propertyMember === null || propertyMember === void 0 ? void 0 : propertyMember.roles.includes(EPropertyMemberTypes.Tenant))) {
                roles.push(EContractMemberTypes.Tenant);
            }
            if ((contractMember === null || contractMember === void 0 ? void 0 : contractMember.roles.includes(EContractMemberTypes.Parent)) ||
                (propertyMember === null || propertyMember === void 0 ? void 0 : propertyMember.roles.includes(EPropertyMemberTypes.Parent))) {
                roles.push(EContractMemberTypes.Parent);
            }
            if ((contractMember === null || contractMember === void 0 ? void 0 : contractMember.roles.includes(EContractMemberTypes.Owner)) ||
                (propertyMember === null || propertyMember === void 0 ? void 0 : propertyMember.roles.includes(EPropertyMemberTypes.Owner))) {
                roles.push(EContractMemberTypes.Owner);
            }
            if ((contractMember === null || contractMember === void 0 ? void 0 : contractMember.roles.includes(EContractMemberTypes.Broker)) ||
                (propertyMember === null || propertyMember === void 0 ? void 0 : propertyMember.roles.includes(EPropertyMemberTypes.Broker))) {
                roles.push(EContractMemberTypes.Broker);
            }
            if ((_a = formData[EField.TenantReceiver]) === null || _a === void 0 ? void 0 : _a.includes(accountId)) {
                roles.push(EContractMemberTypes.Receiver);
            }
            return {
                roles: roles,
                accountId: accountId,
            };
        }));
        var data = {
            rentDepositId: rentDepositId,
            propertyId: property.id,
            relatedContractId: relatedContract.id,
            contractType: EContractType.RentDepositRelease,
            ownerAmount: formData[EField.OwnerAmount],
            ownerBankAccountId: formData[EField.OwnerBankAccountId],
            tenantAmount: formData[EField.TenantAmount],
            tenantBankAccountId: formData[EField.TenantBankAccountId] === EExtraIbanSelectValues.Unknown
                ? null
                : formData[EField.TenantBankAccountId],
            brokerAmount: formData[EField.BrokerAmount],
            brokerBankAccountId: formData[EField.BrokerBankAccountId],
            startDate: new Date(),
            members: mappedSigners,
            isJudicialRelease: formData[EField.JudicialRelease],
        };
        return releaseRentDeposit.actions.start(data);
    };
};
