import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ELegalCaseContractStatus } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import { Filters, Stages } from "@rentiohq/web-shared/dist/components";
import { EFilterType } from "@rentiohq/web-shared/dist/components/Filters/Filters.types";
import { useEffect } from "react";
import { NumberParam, StringParam } from "serialize-query-params";

interface IRentioLegalFilterProps {
  searchQuery?: string | null;
  onFilterChange: (resultingFilter: any) => void;
  onSearchChange: (searchTerm: string) => void;
  refetchRef?: (x: Function) => void;
}

enum ECasesState {
  OngoingCase = "ongoing_cases",
  WithRentioLegalContract = "with_rentio_legal_contract",
  WithoutRentioLegalContract = "without_rentio_legal_contract",
}

//const PREFERENCE_KEY_CASES_STATE = "cases-state";
const NOW = new Date();

type TPropertyRolesFilter =
  | EPropertyMemberTypes.Admin
  | EPropertyMemberTypes.Broker
  | EPropertyMemberTypes.TaskMember
  | EPropertyMemberTypes.TechnicalManager
  | EPropertyMemberTypes.FinancialManager;

const getContractsFilter = (extraFilter?: Record<string, any>) => {
  // This shuts up the TS compiler
  const propertyRolesFilter: TPropertyRolesFilter[] = [
    EPropertyMemberTypes.FinancialManager,
  ];

  return {
    filter: {
      stopDate: { gte: NOW },
      ...extraFilter,
    },
    propertyRolesFilter,
    customFilters: ["PROPERTY_TYPE_RESIDENTIAL"],
  };
};

export const RentioLegalFilter = ({
  searchQuery,
  onFilterChange,
  onSearchChange,
}: IRentioLegalFilterProps) => {
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    caseState: StringParam,
    legalCaseId: StringParam,
  });

  const { count: contractsCount } = contractHooks.useCount({
    query: getContractsFilter(),
  });

  const { count: contractsWithLegalCount } = contractHooks.useCount({
    query: getContractsFilter({ legalLicenseActivatedAt: { isNot: null } }),
  });
  const { count: pendingCasesCount } = contractHooks.useCount({
    query: getContractsFilter({
      legalStatus: { eq: ELegalCaseContractStatus.Created },
    }),
  });
  const { count: closedCasesCount } = contractHooks.useCount({
    query: getContractsFilter({
      legalStatus: { eq: ELegalCaseContractStatus.Closed },
    }),
  });

  useEffect(() => {
    let filter = {};
    if (queryParams.caseState) {
      if (queryParams.caseState === ECasesState.WithRentioLegalContract) {
        filter = {
          legalLicenseActivatedAt: { isNot: null },
        };
      } else if (
        queryParams.caseState === ECasesState.WithoutRentioLegalContract
      ) {
        filter = {
          legalLicenseActivatedAt: { is: null },
        };
      } else if (queryParams.caseState === "closed") {
        filter = { legalStatus: { eq: ELegalCaseContractStatus.Closed } };
      } else {
        filter = { legalStatus: { eq: ELegalCaseContractStatus.Created } };
      }
    }
    onFilterChange({ ...filter });
  }, [queryParams.caseState]);

  return (
    <>
      <Stages
        stages={[
          {
            heading: getLocalizedText("rentio_legal.filter.contracts"),
            icon: "singleNeutralActionsHome",
            count: contractsCount ?? 0,
            onClick: () => {
              setQueryParams({
                page: 1,
                caseState: undefined,
                legalCaseId: null,
              });
              onSearchChange("");
              onFilterChange({});
            },
          },
          {
            heading: getLocalizedText("rentio_legal.filter.activated_contacts"),
            icon: "documentLegal",
            count: contractsWithLegalCount ?? 0,
            onClick: () => {
              setQueryParams({
                page: 1,
                caseState: ECasesState.WithRentioLegalContract,
                legalCaseId: null,
              });
              onSearchChange("");
            },
          },
          {
            heading: getLocalizedText("rentio_legal.filter.ongoing_cases"),
            icon: "legalHammer",
            count: pendingCasesCount ?? 0,
            onClick: () => {
              setQueryParams({
                page: 1,
                caseState: ECasesState.OngoingCase,
                legalCaseId: null,
              });
              onSearchChange("");
            },
          },
          {
            heading: getLocalizedText(
              "rentio_legal.filter.contracts_with_closed_cases",
            ),
            icon: "legalHammer",
            count: closedCasesCount ?? 0,
            onClick: () => {
              setQueryParams({
                page: 1,
                caseState: "closed",
                legalCaseId: undefined,
              });
            },
          },
        ]}
      />
      <Spacer weight={ESpacerWeight.W16} />
      <Filters
        queryPlaceholder={getLocalizedText(
          "rentio_legal.filter.search.placeholder",
        )}
        queryValue={searchQuery ?? ""}
        onQueryChange={searchTerm => {
          onSearchChange(searchTerm);
        }}
        onQueryClear={() => {
          onSearchChange("");
        }}
        filterConfigs={[
          {
            filters: [
              {
                type: EFilterType.SingleSelect,
                options: Object.values(ECasesState),
                values: queryParams.caseState ? [queryParams.caseState] : [],
                translate: value =>
                  getLocalizedText(
                    `rentio_legal.filter.case_state.${(value as string)
                      .toLowerCase()
                      .replaceAll(" ", "_")}`,
                  ),
                filterKey: "cases-filter",
                onChange: values => {
                  setQueryParams({
                    caseState: values[0] as ECasesState,
                    legalCaseId: null,
                  });
                },
                onRemove: () => setQueryParams({ caseState: null }),
              },
            ],
            label: getLocalizedText("rentio_legal.filter.dropdown.label"),
            groupKey: "cases",
          },
        ]}
      />
    </>
  );
};
