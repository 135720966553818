import { noteModule } from "../../reduxV2/note";
import { EField } from "./schema.createNote.types";
export var isPerformingSubmitActionSelector = function (noteId) { return function (state) {
    if (noteId) {
        return (noteModule.selectors.isUpdating(state, noteId) ||
            noteModule.selectors.isRemoving(state, noteId));
    }
    return noteModule.selectors.isCreating(state);
}; };
export var performingSubmitActionErrorSelector = function (noteId) { return function (state) {
    if (noteId) {
        return (noteModule.selectors.updateError(state, noteId) ||
            noteModule.selectors.removeError(state, noteId));
    }
    return noteModule.selectors.createError(state);
}; };
export var submitActionCreator = function (customPath, noteId) { return function (formData) {
    var note = {
        content: formData[EField.Content],
    };
    if (noteId) {
        if (note.content) {
            return noteModule.actions.updateStart.getAction({
                id: noteId,
                data: note,
            });
        }
        else {
            return noteModule.actions.removeStart.getAction({
                id: noteId,
            });
        }
    }
    return noteModule.actions.createStart.getAction({
        data: note,
        customPath: customPath,
    });
}; };
