var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { VAT_PERCENTAGES } from "@rentiohq/shared/dist/types/contract.types";
import { formatCurrency, getAmountWithVat, } from "@rentiohq/shared/dist/utils/number.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
export var getVatFieldsForSchema = function (params) {
    var _a;
    var _b, _c;
    var initialValues = params.initialValues, defaultValues = params.defaultValues, fieldKey = params.fieldKey, infoFieldKey = params.infoFieldKey, baseAmountFieldKey = params.baseAmountFieldKey, defaultValue = params.defaultValue, getForcedValue = params.getForcedValue, showIf = params.showIf, _d = params.showInfoIfEmpty, showInfoIfEmpty = _d === void 0 ? false : _d, shouldSetNewBaseAmount = params.shouldSetNewBaseAmount;
    var forcedValue = getForcedValue === null || getForcedValue === void 0 ? void 0 : getForcedValue({ initialValues: initialValues, defaultValues: defaultValues });
    var valueParsed = (_c = (_b = forcedValue !== null && forcedValue !== void 0 ? forcedValue : defaultValue) !== null && _b !== void 0 ? _b : getValue(fieldKey, initialValues, defaultValues)) !== null && _c !== void 0 ? _c : 0;
    return _a = {},
        _a[fieldKey] = {
            default: valueParsed,
            // @ts-ignore
            options: VAT_PERCENTAGES.map(function (id) { return ({
                id: id,
                value: getLocalizedText("contract.item.vat", { value: "".concat(id) }, id),
            }); }),
            showIf: showIf,
        },
        _a[infoFieldKey] = {
            default: {},
            // @ts-ignore
            showIf: function (formData) {
                return (showIf ? showIf(formData) : true) &&
                    (showInfoIfEmpty ? true : formData[fieldKey] > 0);
            },
            // @ts-ignore
            handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                var _a, _b;
                var setNewBaseAmount = shouldSetNewBaseAmount === null || shouldSetNewBaseAmount === void 0 ? void 0 : shouldSetNewBaseAmount(fieldName, fieldValue, myFieldValue, formData);
                if (setNewBaseAmount === null || setNewBaseAmount === void 0 ? void 0 : setNewBaseAmount.shouldSetNewValue) {
                    return {
                        shouldSetNewValue: true,
                        newValue: __assign(__assign({}, myFieldValue), (_a = {}, _a[baseAmountFieldKey] = setNewBaseAmount.newValue, _a)),
                    };
                }
                if (fieldName === fieldKey || fieldName === baseAmountFieldKey) {
                    return {
                        shouldSetNewValue: true,
                        newValue: __assign(__assign({}, myFieldValue), (_b = {}, _b[fieldName] = fieldValue, _b)),
                    };
                }
                return { shouldSetNewValue: false };
            },
        },
        _a;
};
export var getVatFieldsForUiSchema = function (params) {
    var _a;
    var fieldKey = params.fieldKey, infoFieldKey = params.infoFieldKey, baseAmountFieldKey = params.baseAmountFieldKey, isDisabled = params.isDisabled;
    return _a = {},
        _a[fieldKey] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("contract_info.vat_amount.title"),
            shouldShowOnSummary: function (formData) {
                return formData[fieldKey] && formData[fieldKey] !== VAT_PERCENTAGES[0];
            },
            formatSummary: function (formData) {
                return formData &&
                    getLocalizedText("contract.item.vat", { value: "".concat(formData) }, formData);
            },
            isDisabled: isDisabled,
        },
        _a[infoFieldKey] = {
            "ui:field": ERentioFormField.HelpInfo,
            // title: getLocalizedText("contract.price.vat"),
            getSubtitle: function (state, formData, fieldValue) {
                var baseAmount = fieldValue[baseAmountFieldKey] || formData[baseAmountFieldKey] || 0;
                var vat = fieldValue[fieldKey] || formData[fieldKey] || 0;
                return getLocalizedText("system.vat_total_info", {
                    amount: formatCurrency(baseAmount),
                    amountWithVat: formatCurrency(getAmountWithVat(baseAmount, vat / 100)),
                    vat: vat,
                });
            },
        },
        _a;
};
