import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registration.types";
import { getDocumentSignedAt } from "./schema.registration.utils";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    return {
      required: [EField.LocationDescriptionSignedAt],
      properties: {
        title1: {
          default: undefined,
        },
        // info1: {
        //   default: undefined,
        // },
        [EField.LocationDescriptionSignedAt]: {
          default: getDocumentSignedAt({
            documentField: EField.LocationDescriptionDocumentToUpload,
            signedAtField: EField.LocationDescriptionSignedAt,
            initialValues,
            defaultValues,
          }),
        },
      },
    };
  };

export const uiSchema = (_: IExtraData) => {
  return {
    "rentio:title": utils.i18n.t(
      "registration.create.form.step.location_description_meta",
    ),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t(
        "registration.create.form.field.location_description_meta_title",
      ),
    },
    // info1: {
    //   "ui:field": ERentioFormField.HelpInfo,
    //   subtitle: getLocalizedText(
    //     "registration.create.form.field.location_description_meta_info",
    //   ),
    // },
    [EField.LocationDescriptionSignedAt]: {
      "ui:field": ERentioFormField.Date,
      title: getLocalizedText(
        "registration.create.form.field.location_description_signed_at",
      ),
      maxDate: new Date(),
      formatSummary: (formData: Date) => formData && formatDate(formData),
    },
  };
};
