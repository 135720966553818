import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EField } from "./schema.indexContractBulkIndex.types";
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        var _b;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Retroactivity] = {
                    type: "boolean",
                    default: getValue(EField.Retroactivity, initialValues, defaultValues),
                },
                _a[EField.GenerateLetter] = {
                    type: "boolean",
                    default: getValue(EField.GenerateLetter, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return extraData.showGenerateLetter; },
                },
                _a[EField.LetterType] = {
                    default: getValue(EField.LetterType, initialValues, defaultValues),
                    // @ts-ignore
                    options: (_b = extraData.letterTemplates) === null || _b === void 0 ? void 0 : _b.map(function (template) { return ({
                        id: template.id,
                        value: template.name,
                    }); }),
                    showIf: function (formData) { return formData[EField.GenerateLetter] === true; },
                },
                _a[EField.ForceFullIndexation] = {
                    type: "boolean",
                    default: getValue(EField.ForceFullIndexation, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a;
    return _a = {},
        _a[EField.Retroactivity] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contract.index.retroactivity"),
        },
        _a[EField.GenerateLetter] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contract.index.generate_letter"),
        },
        _a[EField.LetterType] = {
            "ui:field": ERentioFormField.DropdownSelect,
            label: getLocalizedText("contract.index.letter_type"),
        },
        _a[EField.ForceFullIndexation] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contract.index.force_full"),
        },
        _a;
};
