import { IProperty } from "@rentiohq/shared/dist/types/property.types";

export interface IExtraData {
  property: IProperty;
}

export enum EField {
  Header = "_header",
  Price = "price",
  Costs = "costs",
}
