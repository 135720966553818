import { createDocumentPackage } from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import {
  IDocumentPackageCreate,
  IDocumentPackageCreateSigner,
} from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.types";
import { EContractMemberTypes } from "@rentiohq/shared/dist/types/contract.types";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import {
  getPrioritizedPropertyRole,
  getRelatedContract,
  getRelatedProperty,
  getSigningTypes,
} from "./schema.createDocumentPackage.utils";

export const isPerformingSubmitActionSelector = () => (state: IRootStore) => {
  return !!state.documentPackage.isCreatingDocumentPackage;
};

export const performingSubmitActionResultSelector =
  () => (state: IRootStore) => {
    const documentPackageId: any =
      state.documentPackage.createdDocumentPackageId;
    return state.documentPackage.documentPackages[documentPackageId];
  };

export const performingSubmitActionErrorSelector =
  () => (state: IRootStore) => {
    return state.documentPackage.createDocumentPackageError;
  };

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const relatedContract = getRelatedContract(extraData, formData);
    const relatedProperty = getRelatedProperty(extraData, formData);

    const allowedSigningTypes = getSigningTypes(
      formData[EField.SigningTypes] || [],
    );

    const signers = [...(formData[EField.Signers] || [])];

    const signAsBroker = formData[EField.SignAsBroker] || [];
    if (signAsBroker.length > 0) {
      signers.push(+signAsBroker[0]);
    }

    const signerObjects = signers.map(
      (accountId: number): IDocumentPackageCreateSigner => {
        if (!!relatedContract) {
          const contractMember = (relatedContract || []).members.find(
            member => member.account.id === accountId,
          );
          if (!contractMember) {
            return {
              accountId,
              role: "SIGNER",
              allowedSigningTypes,
            };
          }

          let role = contractMember.roles!.find((role: string) =>
            [
              EContractMemberTypes.Tenant,
              EContractMemberTypes.Parent,
              EContractMemberTypes.Owner,
              EContractMemberTypes.Broker,
            ].includes(role as any),
          );

          if (!role && !!relatedProperty) {
            const isBroker = (relatedProperty || []).members.find(
              member =>
                member.account.id === accountId &&
                member.roles.includes(EPropertyMemberTypes.Broker),
            );
            if (isBroker) {
              role = EContractMemberTypes.Broker;
            }
          }

          if (role) {
            return {
              accountId,
              role,
              allowedSigningTypes,
            };
          }
        }

        if (!!relatedProperty) {
          const propertyMember = (relatedProperty || []).members.find(
            member => member.account.id === accountId,
          );

          if (!propertyMember) {
            return {
              accountId,
              role: "SIGNER",
              allowedSigningTypes,
            };
          }

          return {
            accountId,
            role: getPrioritizedPropertyRole(propertyMember) as string,
            allowedSigningTypes,
          };
        }

        return {
          accountId,
          role: "SIGNER",
          allowedSigningTypes,
        };
      },
    );

    const documentPackage: IDocumentPackageCreate = {
      type: formData[EField.DocumentPackageType],

      propertyId: relatedProperty?.id,
      contractId: relatedContract?.id,

      documents: formData[EField.Documents] || [],

      name: formData[EField.Name],
      signers: signerObjects,
    };

    return createDocumentPackage.actions.start(documentPackage);
  };
