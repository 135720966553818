var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EContractCostsPriceType } from "@rentiohq/shared/dist/types/contract.types";
import { getYear, startOfMonth, subDays, subMonths, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createContract.types";
import { getNavigationTitle } from "./schema.createContract.utils";
import { getVatFieldsForSchema, getVatFieldsForUiSchema, } from "./schema.createContract.utils.vat";
var getValueForBaseYear = function (baseYear) {
    var result = "".concat(baseYear);
    var baseYearKey = "contract.index_base_year.".concat(baseYear);
    if (baseYearKey !== getLocalizedText(baseYearKey)) {
        result = getLocalizedText(baseYearKey);
    }
    return result;
};
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a, _b, _c, _d;
        return ({
            required: [EField.PriceInitial, EField.IndexInitial],
            properties: __assign(__assign(__assign(__assign(__assign(__assign((_a = {}, _a[EField.Header + "5"] = {
                default: undefined,
            }, _a[EField.PriceInitial] = {
                default: getValue(EField.PriceInitial, initialValues, defaultValues),
                minimum: 0,
            }, _a), getVatFieldsForSchema({
                initialValues: initialValues,
                defaultValues: defaultValues,
                fieldKey: EField.PriceInitialVat,
                infoFieldKey: EField.PriceInitialVatHelpInfo,
                baseAmountFieldKey: EField.PriceInitial,
            })), (_b = {}, _b[EField.CostsCommonInitial] = {
                default: getValue(EField.CostsCommonInitial, initialValues, defaultValues),
                minimum: 0,
            }, _b[EField.PriceTypeCommonCostsInitial] = {
                default: getValue(EField.PriceTypeCommonCostsInitial, initialValues, defaultValues),
                // @ts-ignore
                options: [
                    EContractCostsPriceType.Fixed,
                    EContractCostsPriceType.Provision,
                ].map(function (id) { return ({
                    id: id,
                    value: getLocalizedText("contract_info.costs.price_type.value.".concat(id.toLowerCase())),
                }); }),
            }, _b)), getVatFieldsForSchema({
                initialValues: initialValues,
                defaultValues: defaultValues,
                fieldKey: EField.CommonCostsInitialVat,
                infoFieldKey: EField.CommonCostsInitialVatHelpInfo,
                baseAmountFieldKey: EField.CostsCommonInitial,
            })), (_c = {}, _c[EField.CostsNonCommonInitial] = {
                default: getValue(EField.CostsNonCommonInitial, initialValues, defaultValues),
                minimum: 0,
            }, _c[EField.PriceTypeNonCommonCostsInitial] = {
                default: getValue(EField.PriceTypeNonCommonCostsInitial, initialValues, defaultValues),
                // @ts-ignore
                options: [
                    EContractCostsPriceType.Fixed,
                    EContractCostsPriceType.Provision,
                ].map(function (id) { return ({
                    id: id,
                    value: getLocalizedText("contract_info.costs.price_type.value.".concat(id.toLowerCase())),
                }); }),
            }, _c)), getVatFieldsForSchema({
                initialValues: initialValues,
                defaultValues: defaultValues,
                fieldKey: EField.NonCommonCostsInitialVat,
                infoFieldKey: EField.NonCommonCostsInitialVatHelpInfo,
                baseAmountFieldKey: EField.CostsNonCommonInitial,
            })), (_d = {}, _d[EField.BaseYear] = {
                default: (function () {
                    var value = getValue(EField.BaseYear, initialValues, defaultValues);
                    if (!value && defaultValues.startDate) {
                        var year_1 = getYear(subMonths(defaultValues.startDate, 1));
                        var baseYear_1 = extraData.baseYears[0];
                        extraData.baseYears.find(function (randomBaseYear) {
                            if (randomBaseYear <= year_1) {
                                baseYear_1 = randomBaseYear;
                                return true;
                            }
                            return false;
                        });
                        return baseYear_1;
                    }
                    return value;
                })(),
                // @ts-ignore
                options: extraData.baseYears.map(function (baseYear) { return ({
                    id: baseYear,
                    value: getValueForBaseYear(baseYear),
                }); }),
                handleChange: function (fieldName, fieldValue) {
                    if (fieldName === EField.IndexInitial) {
                        var year_2 = getYear(subMonths(fieldValue, 1));
                        var baseYear_2 = extraData.baseYears[0];
                        extraData.baseYears.find(function (randomBaseYear) {
                            if (randomBaseYear <= year_2) {
                                baseYear_2 = randomBaseYear;
                                return true;
                            }
                            return false;
                        });
                        return { shouldSetNewValue: true, newValue: baseYear_2 };
                    }
                    return { shouldSetNewValue: false };
                },
            }, _d[EField.IndexInitial] = {
                default: (function () {
                    var value = getValue(EField.IndexInitial, initialValues, defaultValues);
                    if (!value && defaultValues.startDate) {
                        return subMonths(defaultValues.startDate, 1);
                    }
                    return value;
                })(),
            }, _d)),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a, _b, _c, _d;
    var EARLIEST_BASE_YEAR = extraData.baseYears[extraData.baseYears.length - 1];
    return __assign(__assign(__assign(__assign(__assign(__assign((_a = { "rentio:title": getLocalizedText("contract_info.add.initial_data.heading.summary"), "rentio:navigationTitle": getNavigationTitle(extraData), "ui:field": ERentioFormField.Grid, grid: {
                row: [
                    {
                        col: {
                            width: 1 /* EMobileFieldWidth.Full */,
                            children: [EField.Header + "5"],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.4166666666666667 /* EDesktopFieldWidth.FiveTwelfths */],
                            children: [EField.PriceInitial],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.25 /* EDesktopFieldWidth.ThreeTwelfths */],
                            children: [EField.PriceInitialVat],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.3333333333333333 /* EDesktopFieldWidth.FourTwelfths */],
                            children: [],
                        },
                    },
                    {
                        col: {
                            width: 1 /* EMobileFieldWidth.Full */,
                            children: [EField.PriceInitialVatHelpInfo],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.4166666666666667 /* EDesktopFieldWidth.FiveTwelfths */],
                            children: [EField.CostsCommonInitial],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.25 /* EDesktopFieldWidth.ThreeTwelfths */],
                            children: [EField.CommonCostsInitialVat],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.3333333333333333 /* EDesktopFieldWidth.FourTwelfths */],
                            children: [EField.PriceTypeCommonCostsInitial],
                        },
                    },
                    {
                        col: {
                            width: 1 /* EMobileFieldWidth.Full */,
                            children: [EField.CommonCostsInitialVatHelpInfo],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.4166666666666667 /* EDesktopFieldWidth.FiveTwelfths */],
                            children: [EField.CostsNonCommonInitial],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.25 /* EDesktopFieldWidth.ThreeTwelfths */],
                            children: [EField.NonCommonCostsInitialVat],
                        },
                    },
                    {
                        col: {
                            width: [1 /* EMobileFieldWidth.Full */, 0.3333333333333333 /* EDesktopFieldWidth.FourTwelfths */],
                            children: [EField.PriceTypeNonCommonCostsInitial],
                        },
                    },
                    {
                        col: {
                            width: 1 /* EMobileFieldWidth.Full */,
                            children: [EField.NonCommonCostsInitialVatHelpInfo],
                        },
                    },
                    {
                        col: {
                            width: 1 /* EMobileFieldWidth.Full */,
                            children: [EField.BaseYear, EField.IndexInitial],
                        },
                    },
                ],
            } }, _a[EField.Header + "5"] = {
        "ui:field": ERentioFormField.SectionHeader,
        title: getLocalizedText("contract_info.add.initial_data.heading"),
    }, _a[EField.PriceInitial] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("contract.initial_price.label"),
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        autoFocus: true,
    }, _a), getVatFieldsForUiSchema({
        fieldKey: EField.PriceInitialVat,
        infoFieldKey: EField.PriceInitialVatHelpInfo,
        baseAmountFieldKey: EField.PriceInitial,
    })), (_b = {}, _b[EField.CostsCommonInitial] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("rental.contract.initial_costs.common"),
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        optional: true,
    }, _b)), getVatFieldsForUiSchema({
        fieldKey: EField.CommonCostsInitialVat,
        infoFieldKey: EField.CommonCostsInitialVatHelpInfo,
        baseAmountFieldKey: EField.CostsCommonInitial,
    })), (_c = {}, _c[EField.CostsNonCommonInitial] = {
        "ui:field": ERentioFormField.Number,
        formatSummary: function (formData) {
            return formData && formatCurrency(formData);
        },
        title: getLocalizedText("rental.contract.initial_costs.non_common"),
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
        optional: true,
    }, _c)), getVatFieldsForUiSchema({
        fieldKey: EField.NonCommonCostsInitialVat,
        infoFieldKey: EField.NonCommonCostsInitialVatHelpInfo,
        baseAmountFieldKey: EField.CostsNonCommonInitial,
    })), (_d = {}, _d[EField.PriceTypeCommonCostsInitial] = {
        "ui:field": ERentioFormField.SegmentedControl,
        title: getLocalizedText("contract_info.common_costs.price_type.label"),
        extraInfoUrl: "contract_info.price_type.url",
        formatSummary: function (formData) {
            return formData &&
                getLocalizedText("contract_info.costs.price_type.value.".concat(formData.toLowerCase()));
        },
        shouldShowOnSummary: function (formData) {
            return !!formData[EField.CostsCommonInitial];
        },
    }, _d[EField.PriceTypeNonCommonCostsInitial] = {
        "ui:field": ERentioFormField.SegmentedControl,
        title: getLocalizedText("contract_info.non_common_costs.price_type.label"),
        extraInfoUrl: "contract_info.price_type.url",
        formatSummary: function (formData) {
            return formData &&
                getLocalizedText("contract_info.costs.price_type.value.".concat(formData.toLowerCase()));
        },
        shouldShowOnSummary: function (formData) {
            return !!formData[EField.CostsNonCommonInitial];
        },
    }, _d[EField.BaseYear] = {
        "ui:field": ERentioFormField.DropdownSelect,
        title: getLocalizedText("contract.current_index_base.label"),
        extraInfoUrl: "contract.current_index.url",
        formatSummary: function (input) {
            return input ? getValueForBaseYear(input) : "";
        },
    }, _d[EField.IndexInitial] = {
        "ui:field": ERentioFormField.Date,
        formatSummary: function (input) {
            return input ? formatDate(input, "M/yyyy") : "";
        },
        title: getLocalizedText("contract.initial_index.label"),
        dateFormat: "M/yyyy",
        showMonthYearPicker: true,
        minDate: new Date(EARLIEST_BASE_YEAR, 0, 1),
        getMaxDate: function (formData) {
            return subDays(startOfMonth(formData[EField.StartDate]), 1);
        },
        inverseYears: true,
        extraInfoUrl: "contract.index_initial.url",
    }, _d));
};
