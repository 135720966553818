import { addYears, isAfter, isBefore, isSameDay, startOfDay, subDays, subYears, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { isValidStopDateForTacitRenewal } from "../../reduxV2/contract/contract.utils";
import { isResidential } from "../../reduxV2/property/property.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EContractDuration, EField, } from "./schema.createContract.types";
import { getEndDate, getNavigationTitle } from "./schema.createContract.utils";
var NOW = startOfDay(new Date());
export var getSchema = function (initialValues, _a) {
    var contract = _a.contract, property = _a.property;
    return function (defaultValues) {
        var _a;
        var isHouseOrApartment = isResidential(property);
        return {
            required: [EField.StartDate],
            properties: (_a = {},
                _a[EField.Header + "4"] = {
                    default: undefined,
                },
                _a[EField.StartDate] = {
                    default: getValue(EField.StartDate, initialValues, defaultValues),
                },
                _a[EField.Duration] = {
                    default: getValue(EField.Duration, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(EContractDuration).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("contract.duration.option.".concat(id).concat(isHouseOrApartment ? ".residential" : "")),
                    }); }),
                    // @ts-ignore
                    showIf: function (_) { return !contract; },
                    requiredIf: function (_) { return !contract; },
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        if (fieldName === EField.EndDate) {
                            return {
                                shouldSetNewValue: true,
                                newValue: EContractDuration.Other,
                            };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.EndDate] = {
                    default: getValue(EField.EndDate, initialValues, defaultValues),
                    // @ts-ignore
                    // showIf: (formData: any) =>
                    //   formData[EField.Duration] || !contract
                    //     ? formData[EField.Duration] === EContractDuration.Other
                    //     : true,
                    // @ts-ignore
                    requiredIf: function (formData) {
                        return formData[EField.Duration] || !contract
                            ? formData[EField.Duration] === EContractDuration.Other
                            : true;
                    },
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        if (fieldName === EField.EndDate) {
                            return { shouldSetNewValue: false };
                        }
                        if (formData[EField.Duration] !== EContractDuration.Other) {
                            return {
                                shouldSetNewValue: true,
                                newValue: getEndDate(formData),
                            };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.IsPreviouslyRenewed] = {
                    default: getValue(EField.IsPreviouslyRenewed, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        if (!isHouseOrApartment) {
                            return false;
                        }
                        var startDate = formData[EField.StartDate];
                        var endDate = formData[EField.EndDate];
                        if (!startDate || !endDate) {
                            return false;
                        }
                        if (isAfter(startDate, NOW)) {
                            return false;
                        }
                        return isSameDay(subDays(addYears(startDate, 2), 1), endDate);
                    },
                    // @ts-ignore
                    options: ["yes", "no"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.".concat(id).toLowerCase()),
                    }); }),
                },
                _a[EField.TacitRenewalUnavailableInfo] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (_) { return !isHouseOrApartment; },
                },
                _a[EField.TacitRenewalUnavailableInvalidInfo] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (formData) {
                        return isHouseOrApartment &&
                            isValidStopDateForTacitRenewal({
                                startDate: formData[EField.StartDate],
                                stopDate: formData[EField.EndDate],
                            }) === false;
                    },
                },
                _a[EField.TacitRenewal] = {
                    default: getValue(EField.TacitRenewal, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        if (!isHouseOrApartment) {
                            return false;
                        }
                        var endDate = formData[EField.EndDate];
                        var startDate = formData[EField.StartDate];
                        if (!endDate) {
                            return true;
                        }
                        return isValidStopDateForTacitRenewal({
                            startDate: startDate,
                            stopDate: endDate,
                        });
                    },
                    // @ts-ignore
                    options: ["yes", "no"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.".concat(id).toLowerCase()),
                    }); }),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    /*  const EARLIEST_BASE_YEAR =
      extraData.baseYears[extraData.baseYears.length - 1];
    const DATE_START_MIN = new Date(EARLIEST_BASE_YEAR, 0, 1); */
    var _a;
    // Sum of those two should at most be 120
    var MAX_ADDED_YEARS = 60;
    var MAX_SUBTRACTED_YEARS = 60;
    return _a = {
            "rentio:title": getLocalizedText("contract_info.create.duration.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "4"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.create.duration.heading"),
        },
        _a[EField.StartDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) { return (input ? formatDate(input) : ""); },
            title: getLocalizedText("contract.start_date.label"),
            minDate: subYears(NOW, MAX_SUBTRACTED_YEARS),
            maxDate: addYears(NOW, MAX_ADDED_YEARS),
        },
        _a[EField.Duration] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("contract.duration.option.label"),
            extraInfoUrl: "contract.duration.option.info.url",
            shouldShowOnSummary: function (formData) {
                return formData[EField.Duration] !== EContractDuration.Other;
            },
            formatSummary: function (value) {
                return getLocalizedText("contract.duration.option.".concat(value));
            },
        },
        _a[EField.EndDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) { return (input ? formatDate(input) : ""); },
            title: getLocalizedText("contract.end_date.label"),
            minDate: subYears(NOW, MAX_SUBTRACTED_YEARS),
            maxDate: addYears(NOW, MAX_ADDED_YEARS),
            shouldShowOnSummary: function (formData) {
                return formData[EField.Duration]
                    ? formData[EField.Duration] === EContractDuration.Other
                    : true;
            },
        },
        _a[EField.IsPreviouslyRenewed] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("contract.is_previously_renewed.title"),
            info: getLocalizedText("contract.is_previously_renewed.label"),
            formatSummary: function (value) {
                return getLocalizedText("system.".concat(value ? "yes" : "no").toLowerCase());
            },
        },
        _a[EField.TacitRenewalUnavailableInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("contract.tacit_renewal_unavailable.label"),
            subtitle: getLocalizedText("contract.tacit_renewal_unavailable.info"),
        },
        _a[EField.TacitRenewalUnavailableInvalidInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("contract.tacit_renewal_unavailable.invalid.label"),
            subtitle: getLocalizedText("contract.tacit_renewal_unavailable.invalid.info"),
        },
        _a[EField.TacitRenewal] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("contract.tacit_renewal.label"),
            info: getLocalizedText("contract.tacit_renewal.info"),
            extraInfoUrl: "contract.tacit_renewal.info.url",
            formatSummary: function (value) {
                return getLocalizedText("system.".concat(value).toLowerCase());
            },
        },
        _a;
};
export var getValidate = function (extraData) { return function (formData, errors) {
    if (!formData[EField.EndDate]) {
        return errors;
    }
    if (isBefore(formData[EField.EndDate], formData[EField.StartDate])) {
        errors[EField.EndDate].addError(getLocalizedText("form.error.end_date.error.not_before_start_date"));
    }
    if (!extraData.contract && isBefore(formData[EField.EndDate], new Date())) {
        errors[EField.EndDate].addError(getLocalizedText("form.error.end_date.error.not_in_past"));
    }
    return errors;
}; };
