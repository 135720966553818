import {
  addMonths,
  endOfMonth,
  isBefore,
  min,
  subMonths,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { Icon } from "@rentiohq/web-shared/dist/components";
import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as constants from "../../PropertyListItemFinancial.constants";
import * as S from "./OlderNewerButton.styles";

interface IProps {
  olderNewer: "older" | "newer";
}

const OlderNewerButton = (props: IProps) => {
  const { olderNewer } = props;

  // Redux
  const dispatch = useDispatch();

  const mostLeftDate = useSelector(
    (state: IRootStore) =>
      systemSelectors.getPreference<Date>(
        state,
        constants.PREFERENCE_KEY_PROPERTIES_FINANCIAL_TAB_DATE,
      ) || constants.START_DATE,
  );

  const handlePress = (e: any) => {
    e.preventDefault?.();
    e.stopPropagation?.();
    e.stopImmediatePropagation?.();
    e.nativeEvent?.stopImmediatePropagation?.();

    let nextDate = mostLeftDate;

    if (olderNewer === "older") {
      nextDate = subMonths(mostLeftDate, constants.MONTHS_PER_VIEW);
    }

    if (olderNewer === "newer") {
      nextDate = min([
        constants.START_DATE,
        addMonths(mostLeftDate, constants.MONTHS_PER_VIEW),
      ]);
    }

    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.InMemory,
        preferences: {
          [constants.PREFERENCE_KEY_PROPERTIES_FINANCIAL_TAB_DATE]: nextDate,
        },
      }),
    );
  };

  // Render
  let pressable = true;
  if (olderNewer === "newer") {
    pressable = isBefore(endOfMonth(mostLeftDate), constants.START_DATE);
  }

  return (
    <S.Wrap onClick={handlePress} pressable={pressable}>
      {olderNewer === "newer" ? (
        <Icon source="chevronLeft" />
      ) : (
        <Icon source="chevronRight" />
      )}
    </S.Wrap>
  );
};

export default OlderNewerButton;
