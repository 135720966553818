import { Box } from "@rebass/grid";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import { IDocument } from "@rentiohq/shared/dist/types/document.types";
import {
  EDocumentPackageSignerStatus,
  EDocumentPackageTypes,
} from "@rentiohq/shared/dist/types/documentPackage.types";
import {
  Card,
  DisplayText,
  DocumentFetchListItem,
  ESpacings,
  Icon,
} from "@rentiohq/web-shared/dist/components";
import { AccountList } from "@rentiohq/web-shared/dist/components/AccountList";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import Banner from "@rentiohq/web-shared/dist/components/Banner";
import React from "react";
import { useDispatch } from "react-redux";
import { ts } from "../../../../../services";
import { documentPackageUtils } from "../../../../../utils";
import { ContractRows } from "../../../../ContractRows";
import { DocumentRow } from "../../RentDeposit/components/RentDepositInfo/components/DocumentRow";
import {
  EDocumentPackageTabs,
  IDocumentPackageTabProps,
} from "../DocumentPackage.types";

export const DocumentPackageInfo: React.FC<IDocumentPackageTabProps> = ({
  documentPackage,
  setTab,
}) => {
  const dispatch = useDispatch();

  const handleActivityMoreClick = () => {
    setTab(EDocumentPackageTabs.History);
  };

  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const renderDocument = (document: IDocument) => {
    return (
      <DocumentRow
        hasRevoke={false}
        documentPackage={{ ...documentPackage, documentsInPackage: [document] }}
      />
    );
  };

  const createSignerSubtitle = (accountIds: number[]) => {
    const signer = documentPackage?.signers.find(signer =>
      accountIds.includes(signer.accountId),
    );
    if (!signer) {
      return null;
    }

    return documentPackageUtils.renderSignerSubtitle(signer);
  };

  const renderRentalContractBanner =
    documentPackage.type === EDocumentPackageTypes.Contract &&
    documentPackageUtils.hasAllTenantSignatures(documentPackage) ? (
      <Box mb={ESpacings.loose}>
        <Banner
          variation="warning"
          // icon="alertDiamond"
          title={ts.documentPackageContractWaitingForTenantNoticeHeading()}
          hasDismiss={false}
        >
          {ts.documentPackageContractWaitingForTenantNoticeContent()}
        </Banner>
      </Box>
    ) : null;

  const getActions = (params: { accountIds: number[] }) => {
    const { accountIds } = params;

    const signer = documentPackage.signers.find(randomSigner =>
      accountIds.includes(randomSigner.accountId),
    );
    if (!signer) {
      return [];
    }

    const result = [];

    // Open connective url
    if (signer.connectiveUrl) {
      result.push({
        content: getLocalizedText("document_package.sign_for_signer.cta.title"),
        media: <Icon source="contentPenWrite" />,
        onClick: () => {
          window.open(signer.connectiveUrl, "_blank");
        },
      });
    }

    // Reminder
    if (signer.status !== EDocumentPackageSignerStatus.Finished) {
      result.push({
        content: getLocalizedText("document_package.remind_signer.cta.title"),
        media: <Icon source="email" />,
        onClick: () => {
          dispatch(
            documentPackageActions.remindDocumentPackageSigner.actions.start({
              documentPackageId: documentPackage.id,
              signerAccountId: signer.accountId,
            }),
          );
        },
      });
    }

    return result;
  };

  return (
    <>
      {documentPackage.documentsInPackageIds && (
        <Card
          heading={renderCardHeader(ts.system("documents"))}
          space={ESpacings.base}
        >
          <DocumentFetchListItem
            documentId={documentPackage.documentsInPackageIds[0]}
            renderDocument={renderDocument}
          />
        </Card>
      )}
      <Card
        heading={renderCardHeader(ts.system("signers"))}
        space={ESpacings.base}
      >
        {renderRentalContractBanner}
        <AccountList
          accounts={
            documentPackage
              ? (documentPackage.signers.map(signer => ({
                  id: signer.accountId,
                  // cspell:ignore firstame
                  firstname: signer.firstame,
                  lastname: signer.lastname,
                  company: signer.company,
                })) as IAccount[])
              : []
          }
          getActions={getActions}
          getSubtitle={createSignerSubtitle}
        />
      </Card>
      {documentPackage.contractId && (
        <Card
          heading={renderCardHeader(ts.system("contract"))}
          space={ESpacings.base}
        >
          <ContractRows contractIds={[documentPackage.contractId]} />
        </Card>
      )}
      <Card
        heading={renderCardHeader(ts.system("history"))}
        space={ESpacings.base}
      >
        <Box mb={ESpacings.base}>
          <Activities
            identifier={`document-package-${documentPackage.id}-compact`}
            filter={documentPackageUtils.getDocumentPackageActivityFilter(
              documentPackage,
            )}
            limit={3}
            isCompact={true}
            onMoreClick={handleActivityMoreClick}
          />
        </Box>
      </Card>
    </>
  );
};
