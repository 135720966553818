import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  EDocumentPackageSignerStatus,
  EDocumentPackageStatus,
  EDocumentPackageTypes,
  IDocumentPackage,
  IDocumentPackageSigner,
} from "@rentiohq/shared/dist/types/documentPackage.types";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { EDocumentPackageStageSimple } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { ts } from "../services";
import { renderTooltipChecklist } from "./tooltip.utils";

export const renderSignersTooltip = (documentPackage: IDocumentPackage) => {
  const checklist = documentPackage.signers.map((signer: any) => {
    const hasSigned =
      signer.hasOwnProperty("status") &&
      signer.status === EDocumentPackageSignerStatus.Finished;

    return {
      label: getName(signer),
      success: hasSigned,
    };
  });

  return renderTooltipChecklist(checklist);
};

export const getSignerInfo = (documentPackage: IDocumentPackage) => {
  const signedCount = documentPackage.signers.filter(
    signer =>
      signer.hasOwnProperty("status") &&
      signer.status === EDocumentPackageSignerStatus.Finished,
  ).length;
  const signerCount = documentPackage.signers.length;

  return {
    signedCount,
    signerCount,
  };
};

export const getAdvancedStages = (stage: EDocumentPackageStageSimple) => {
  switch (stage) {
    case EDocumentPackageStageSimple.Finished:
      return [EDocumentPackageStatus.Finished];
    default:
      return [
        EDocumentPackageStatus.New,
        EDocumentPackageStatus.Draft,
        EDocumentPackageStatus.Pending,
        EDocumentPackageStatus.PDFReady,
      ];
  }
};

export const getSimpleStageVariation = (
  status: EDocumentPackageStageSimple,
) => {
  switch (status) {
    case EDocumentPackageStageSimple.Failed:
    case EDocumentPackageStageSimple.Revoked:
    case EDocumentPackageStageSimple.Finished:
      return "default";
    default:
      return "warning";
  }
};

export const getSimpleStages = (status: EDocumentPackageStatus) => {
  switch (status) {
    case EDocumentPackageStatus.New:
    case EDocumentPackageStatus.PDFReady:
    case EDocumentPackageStatus.Pending:
    case EDocumentPackageStatus.Draft:
      return EDocumentPackageStageSimple.Pending;
    case EDocumentPackageStatus.Finished:
      return EDocumentPackageStageSimple.Finished;
    case EDocumentPackageStatus.Revoked:
      return EDocumentPackageStageSimple.Revoked;
    default:
      return EDocumentPackageStageSimple.Failed;
  }
};

export const renderSignerSubtitle = (signer: IDocumentPackageSigner) => {
  const hasSigned = signer.status === EDocumentPackageSignerStatus.Finished;
  return (
    <>
      <TextStyle variation={"subdued"} element="div">
        {[
          signer.role && ts.role(signer.role),
          !signer.usedSigningType &&
            !!signer.allowedSigningTypes &&
            ts.documentPackageSignerToSignWith({
              values: {
                methods: signer.allowedSigningTypes
                  .map(type => ts.documentPackageSigningType(type))
                  .join(", "),
              },
            }),
        ]
          .filter(Boolean)
          .join(" | ")}
        {}
      </TextStyle>
      <TextStyle variation={hasSigned ? "positive" : "warn"} element="div">
        {!!signer.usedSigningType && signer.completedAt ? (
          ts.documentPackageSignerUsedSigningType({
            values: {
              method: ts.documentPackageSigningType(signer.usedSigningType),
              date: signer.completedAt
                ? utils.date.format(signer.completedAt, "dd/MM/yyyy HH:mm")
                : undefined,
            },
          })
        ) : (
          <>
            {ts.system(hasSigned ? "signed_at" : "not_signed")}{" "}
            {hasSigned && signer.completedAt
              ? utils.date.format(signer.completedAt, "dd/MM/yyyy HH:mm")
              : undefined}
          </>
        )}
      </TextStyle>
    </>
  );
};

export const getDocumentPackageActivityFilter = (
  documentPackage: IDocumentPackage,
) => {
  const { id: documentPackageId } = documentPackage;
  return {
    where: {
      or: [
        { documentPackageId },

        // !!contractId && {
        //   contractId: contractId,
        //   subtype: {
        //     inq: [ACTIVITY_SUBTYPES.CONTRACT.CREATED, ACTIVITY_SUBTYPES.CONTRACT.SIGNED],
        //   },
        // },
      ].filter(Boolean),
    },
  };
};

export const checkIfRevokable = (documentPackage: IDocumentPackage) => {
  if (!documentPackage.status) {
    return false;
  }
  const simpleStage = getSimpleStages(documentPackage.status);
  if (
    [
      EDocumentPackageTypes.RentDeposit,
      EDocumentPackageTypes.RentDepositRelease,
    ].includes(documentPackage.type)
  ) {
    return false;
  }
  if (simpleStage !== EDocumentPackageStageSimple.Pending) {
    return false;
  }

  return true;
};

export const hasAllTenantSignatures = (documentPackage: IDocumentPackage) => {
  const unsignedTenants = documentPackage.signers.filter(
    signer =>
      signer.role === "TENANT" &&
      signer.status !== EDocumentPackageSignerStatus.Finished,
  );
  return unsignedTenants.length !== 0;
};
