export var ESocketSource;
(function (ESocketSource) {
    ESocketSource["System"] = "system";
    ESocketSource["Chat"] = "chat";
})(ESocketSource || (ESocketSource = {}));
export var ESocketEventMessage;
(function (ESocketEventMessage) {
    ESocketEventMessage["Message"] = "message";
    ESocketEventMessage["Messages"] = "messages";
    ESocketEventMessage["Status"] = "status";
    ESocketEventMessage["Typing"] = "typing";
    ESocketEventMessage["Counter"] = "counter";
    ESocketEventMessage["Notification"] = "notification";
})(ESocketEventMessage || (ESocketEventMessage = {}));
