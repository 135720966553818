var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DisplayTypeContext } from "./DisplayType.context";
// Context
export var GroupSidebarContext = React.createContext({
    openGroups: [],
    setOpenGroups: function (groupIds) { },
    toggleOpenGroup: function (groupId, shouldScrollToGroup) { },
    onOpenGroup: function (groupId, shouldScrollToGroup) { },
});
// Provider
export var GroupSidebarProvider = function (props) {
    var children = props.children;
    // Context
    var isTemplate = React.useContext(DisplayTypeContext).isTemplate;
    // State
    var _a = React.useState([]), openGroups = _a[0], setOpenGroups = _a[1];
    // Helpers
    var scrollToGroup = function (groupId) {
        var variableElement = document.getElementsByClassName("sidebar-group-".concat(groupId))[0];
        variableElement === null || variableElement === void 0 ? void 0 : variableElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };
    // Event handlers
    var handleOpenGroup = function (groupId, shouldScrollToGroup) {
        if (shouldScrollToGroup === void 0) { shouldScrollToGroup = false; }
        isTemplate
            ? setOpenGroups(__spreadArray([groupId], openGroups, true))
            : setOpenGroups([groupId]);
        if (shouldScrollToGroup) {
            setTimeout(function () {
                scrollToGroup(groupId);
            }, 300);
        }
    };
    var toggleOpenGroup = function (groupId, shouldScrollToGroup) {
        if (shouldScrollToGroup === void 0) { shouldScrollToGroup = false; }
        if (openGroups.includes(groupId)) {
            isTemplate
                ? setOpenGroups(__spreadArray([], openGroups, true).filter(function (x) { return x !== groupId; }))
                : setOpenGroups([]);
            return;
        }
        isTemplate
            ? setOpenGroups(__spreadArray([groupId], openGroups, true))
            : setOpenGroups([groupId]);
        if (shouldScrollToGroup) {
            setTimeout(function () {
                scrollToGroup(groupId);
            }, 300);
        }
    };
    // Render
    return (_jsx(GroupSidebarContext.Provider, __assign({ value: {
            openGroups: openGroups,
            setOpenGroups: setOpenGroups,
            toggleOpenGroup: toggleOpenGroup,
            onOpenGroup: handleOpenGroup,
        } }, { children: children })));
};
