var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import { uniqBy } from "lodash";
import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createTask.types";
var getBrokerFilter = function (accountIdsToRender, extraData) {
    return function (accountId) {
        var employees = extraData.employees, broker = extraData.broker;
        if (broker) {
            var employeeIncluded = employees === null || employees === void 0 ? void 0 : employees.some(function (employee) {
                return accountIdsToRender.includes(employee.id);
            });
            if (employeeIncluded) {
                return accountId !== broker.accountId;
            }
        }
        return true;
    };
};
var getDisallowedAccountIds = function (extraData) { return function (_, selectedAccountIds) {
    var employees = extraData.employees, broker = extraData.broker;
    if (broker) {
        var includesAtLeastOneEmployee = employees === null || employees === void 0 ? void 0 : employees.some(function (employee) {
            return selectedAccountIds.includes(employee.id);
        });
        // We don't allow the broker to be selected when an employee is selected
        if (includesAtLeastOneEmployee) {
            return [broker.accountId];
        }
    }
    return [];
}; };
var filterPropertyMembers = function (state, members) {
    var allAccounts = members
        .filter(function (member) {
        //Filter out members that only have the role "TASK_MEMBER"
        return !(member.roles.includes(EPropertyMemberTypes.TaskMember) &&
            member.roles.length === 1);
    })
        .map(function (member) { return member.account; })
        .filter(function (account) {
        // Filter out accounts that have no contact
        return Object.values(state.contact.contacts).some(function (contact) {
            return (contact === null || contact === void 0 ? void 0 : contact.accountId) === account.id ||
                (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(account.id));
        });
    });
    // If there is no User ID we fallback to account uniqueness
    return uniqBy(uniqBy(allAccounts, "id"), function (x) { return x.userId || Math.random(); });
};
var customSubtitleSelector = function (members, item) {
    var accountId = item.accountId, contact = item.contact;
    var member = members
        .filter(function (member) {
        // Filter out members that only have the role "TASK_MEMBER"
        return !(member.roles.includes(EPropertyMemberTypes.TaskMember) &&
            member.roles.length === 1);
    })
        .find(function (member) {
        if (accountId && accountId === member.account.id) {
            return true;
        }
        if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
            return true;
        }
        return false;
    });
    if (!member) {
        return;
    }
    return member.roles
        .filter(function (role) { return role !== EPropertyMemberTypes.ContractMember; })
        .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
        .join(", ");
};
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        return {
            required: extraData.keypoint ? [] : [EField.Executors],
            properties: (_a = {},
                _a[EField.Executors] = {
                    default: getValue(EField.Executors, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return !extraData.keypoint; },
                },
                _a[EField.Followers] = {
                    default: getValue(EField.Followers, initialValues, defaultValues),
                },
                _a),
        };
    };
};
// TODO: type for uiSchema
export var uiSchema = function (extraData, _) {
    var _a;
    return _a = {
            "rentio:navigationTitle": getLocalizedText("task.new"),
            "rentio:title": getLocalizedText("task.members.section_header")
        },
        _a[EField.Executors] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("tasks.executors.label"),
            selectContactFromListCtaTitle: getLocalizedText("tasks.add.executors"),
            addCtaLabel: getLocalizedText("tasks.add.executors"),
            isMultiSelect: true,
            asOptionList: true,
            includeEmployees: true,
            employeeAndBrokerCanBeSelectedAtTheSameTime: false,
            // Custom selectors & actions
            customGetSubtitleSelector: function (_, __, item) { var _a; return customSubtitleSelector(((_a = extraData.property) === null || _a === void 0 ? void 0 : _a.members) || [], item); },
            accountIdsToRenderFilter: function (accountIdsToRender) {
                return getBrokerFilter(accountIdsToRender, extraData);
            },
            customExtraAccountsSelector: function (state) {
                var _a, _b;
                if ((_a = extraData.property) === null || _a === void 0 ? void 0 : _a.members) {
                    return filterPropertyMembers(state, (_b = extraData.property) === null || _b === void 0 ? void 0 : _b.members);
                }
                return undefined;
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
            valueSelector: function (state, selectedAccountIds) {
                var _a;
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, __spreadArray(__spreadArray([], (((_a = extraData.property) === null || _a === void 0 ? void 0 : _a.members.map(function (member) { return member.account; })) || []), true), (extraData.employees || []), true));
            },
            getDisallowedAccountIds: getDisallowedAccountIds(extraData),
        },
        _a[EField.Followers] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("tasks.followers.label"),
            selectContactFromListCtaTitle: getLocalizedText("task.add.follower"),
            addCtaLabel: getLocalizedText("task.add.follower"),
            isMultiSelect: true,
            asOptionList: true,
            extraInfoUrl: "task.members_info_url",
            includeEmployees: true,
            employeeAndBrokerCanBeSelectedAtTheSameTime: false,
            // Custom selectors & actions
            customGetSubtitleSelector: function (_, __, item) { var _a; return customSubtitleSelector(((_a = extraData.property) === null || _a === void 0 ? void 0 : _a.members) || [], item); },
            customExtraAccountsSelector: function (state) {
                var _a, _b;
                if ((_a = extraData.property) === null || _a === void 0 ? void 0 : _a.members) {
                    return filterPropertyMembers(state, (_b = extraData.property) === null || _b === void 0 ? void 0 : _b.members);
                }
                return undefined;
            },
            accountIdsToRenderFilter: function (accountIdsToRender) {
                return getBrokerFilter(accountIdsToRender, extraData);
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
            valueSelector: function (state, selectedAccountIds) {
                var _a;
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, __spreadArray(__spreadArray([], (((_a = extraData.property) === null || _a === void 0 ? void 0 : _a.members.map(function (member) { return member.account; })) || []), true), (extraData.employees || []), true));
            },
            getDisallowedAccountIds: getDisallowedAccountIds(extraData),
        },
        _a;
};
