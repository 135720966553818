import { Box } from "@rebass/grid";
import FeatureFlag from "@rentiohq/shared-frontend/dist/components/components/FeatureFlag";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getCanEditTask } from "@rentiohq/shared-frontend/dist/utils/task.utils";
import { EBrokerFeature } from "@rentiohq/shared/dist/types/broker.types";
import { ETaskMemberType, ITask } from "@rentiohq/shared/dist/types/task.types";
import { isAfter } from "@rentiohq/shared/dist/utils/date-fns.utils";
import {
  Card,
  DisplayText,
  ESpacings,
  Grid,
  MarkdownWrapper,
  ModalConfirmation,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InlineComments from "../../../../components/InlineComments";
import { InlineNotes } from "../../../../components/InlineNotes";
import * as t from "../../../../services/translationService";
import { RepairInfo } from "./components/RepairInfo/RepairInfo";
import { TaskMembers } from "./components/TaskMembers";

const NOW = new Date();

export interface IProps {
  taskId: number;
  task?: ITask;
}

export const TaskDetails = (props: IProps) => {
  const { taskId } = props;

  const { update } = taskModule.hooks.useUpdate({ id: taskId });
  const { detail: task, refetch } = taskModule.hooks.useDetail({
    id: taskId,
  });

  const navigate = useNavigate();
  const hasKeypointRepair = brokerHooks.useBrokerFeature(
    EBrokerFeature.KeypointRepair,
  );
  // Hooks
  useEffect(() => {
    if (!task) {
      refetch();
    }
  }, []);

  const onEditTask = () => {
    navigate(`/tasks/${taskId}/edit?keypoint=1`);
  };

  // State
  const [showFinishConfirmationModal, setShowFinishConfirmationModal] =
    useState(false);

  // Event handlers
  const handleFinishTask = () => {
    if (!task) {
      return;
    }

    const shouldFinish = !task.finishedAt;

    if (
      !showFinishConfirmationModal &&
      shouldFinish &&
      isAfter(task.startDate, NOW)
    ) {
      setShowFinishConfirmationModal(true);
      return;
    }

    if (showFinishConfirmationModal) {
      setShowFinishConfirmationModal(false);
    }

    update({
      data: {
        finishedAt: shouldFinish ? new Date() : null,
      },
      customSuccessMessage: shouldFinish
        ? getLocalizedText("task.completed")
        : getLocalizedText("task.activated"),
    });
  };

  // Helpers
  const getFinishButtonAppearance = () => {
    if (!task) {
      return "outline";
    }

    if (task.finishedAt) {
      return "outline";
    }

    if (isAfter(task.startDate, NOW)) {
      return "outline";
    }

    return "primary";
  };

  // Render
  if (!task) {
    return null;
  }

  const isKeypointTask = !!task.status;

  return (
    <>
      <Grid spacing="loose">
        <Grid.Item width={[1, 1, 10 / 16]}>
          {task.description && (
            <Box mb={ESpacings.base}>
              <Card heading={t.taskDescription()}>
                <MarkdownWrapper source={task.description} />
              </Card>
            </Box>
          )}
          {task.note && (
            <Box mb={ESpacings.base}>
              <Card heading={getLocalizedText("system.note")}>
                <MarkdownWrapper source={task.note} />
              </Card>
            </Box>
          )}
          <FeatureFlag flagKey={"task:comments"} defaultValue={true}>
            <Card heading={t.commentTitle()}>
              <InlineComments
                commentIdentifier={`task-${task.id}`}
                baseUrl={`tasks/${task.id}`}
              />
            </Card>
          </FeatureFlag>
        </Grid.Item>
        <Grid.Item width={[1, 1, 6 / 16]}>
          <Button
            isFullWidth
            appearance={getFinishButtonAppearance()}
            onClick={handleFinishTask}
          >
            {task.finishedAt ? t.taskActivate() : t.taskCompletedBtn()}
          </Button>
          <Box mt={ESpacings.base}>
            <InlineNotes customPath={`/tasks/${task.id}/notes`} />
          </Box>
          {isKeypointTask && hasKeypointRepair && (
            <Box mt={ESpacings.base}>
              <RepairInfo task={task} />
            </Box>
          )}
          {!isKeypointTask && hasKeypointRepair && (
            <Box mt={ESpacings.base}>
              <Card
                heading={getLocalizedText(
                  "task.repair.service.keypoint.status",
                )}
              >
                <Button
                  appearance="outline"
                  size="medium"
                  color="blue"
                  onClick={onEditTask}
                >
                  {getLocalizedText("task.repair.keypoint.send")}
                </Button>
              </Card>
            </Box>
          )}
          {!isKeypointTask && (
            <Box mt={ESpacings.base}>
              <TaskMembers
                taskId={taskId}
                members={task.members}
                heading={
                  <DisplayText size="extraSmall">
                    {t.taskExecutors()}
                  </DisplayText>
                }
                role={ETaskMemberType.Executor}
                canEditTask={getCanEditTask(task)}
              />
            </Box>
          )}
          <Box mt={ESpacings.base}>
            <TaskMembers
              taskId={taskId}
              members={task.members}
              heading={
                <DisplayText size="extraSmall">{t.taskFollowers()}</DisplayText>
              }
              role={ETaskMemberType.Follower}
              canEditTask={getCanEditTask(task)}
            />
          </Box>
        </Grid.Item>
      </Grid>
      {showFinishConfirmationModal && (
        <ModalConfirmation
          heading={getLocalizedText("task.finish.confirm.title")}
          onConfirm={handleFinishTask}
          onCancel={() => {
            setShowFinishConfirmationModal(false);
          }}
        >
          <TextStyle>{getLocalizedText("task.finish.confirm.body")}</TextStyle>
        </ModalConfirmation>
      )}
    </>
  );
};
