import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";

export interface IExtraData {
  property: IProperty;
  contract: IContract;
}

export enum EField {
  Services = "services",
  Manager = "manager",
  IsCorporateAddress = "isCorporateAddress",

  ElectricityType = "electricityType",
  ElectricityEan = "electricityEan",
  ElectricityMeterNumberBoth = "electricityMeterNumberBoth",
  ElectricityMeterType = "electricityMeterType",

  NaturalGasAvailable = "naturalGasAvailable",
  NaturalGasEan = "naturalGasEan",
  NaturalGasMeterNumber = "naturalGasMeterNumber",
  NaturalGasMeterType = "naturalGasMeterType",
}
