export var rules = {
    BROKER: [
        "propertyDashboard:visit",
        "propertyPayments:visit",
        "propertyContracts:visit",
        "propertyApplications:visit",
        "propertyDocuments:visit",
        "propertyHistory:visit",
        "propertyContractRentalAgreement:create",
        "propertyContractMandate:create",
        "propertyContractBasicInfo:create",
        "propertyPayments:create",
        "document:delete",
        "document:update",
    ],
    TENANT: [
        "propertyDashboard:visit",
        // 'propertyPayments:visit',
        "propertyContracts:visit",
        // 'propertyApplications:visit',
        "propertyDocuments:visit",
        "propertyHistory:visit",
    ],
    OWNER: [
        "propertyDashboard:visit",
        "propertyPayments:visit",
        "propertyContracts:visit",
        "propertyApplications:visit",
        "propertyDocuments:visit",
        "propertyHistory:visit",
        "propertyContractRentalAgreement:create",
        "propertyContractMandate:create",
        "propertyContractBasicInfo:create",
        "propertyPayments:create",
    ],
    ADMIN: [
        "propertyDashboard:visit",
        "propertyPayments:visit",
        "propertyContracts:visit",
        "propertyApplications:visit",
        "propertyDocuments:visit",
        "propertyHistory:visit",
        "propertyContractRentalAgreement:create",
        "propertyContractMandate:create",
        "propertyContractBasicInfo:create",
        "propertyPayments:create",
        "document:delete",
        "document:update",
    ],
    INDEXATION_MANAGER: [
        "propertyDashboard:visit",
        "propertyPayments:visit",
        "propertyContracts:visit",
        "propertyDocuments:visit",
        "propertyHistory:visit",
        "propertyContractRentalAgreement:create",
        "propertyContractMandate:create",
        "propertyContractBasicInfo:create",
        "propertyPayments:create",
    ],
    FINANCIAL_MANAGER: [
        "propertyDashboard:visit",
        "propertyPayments:visit",
        "propertyContracts:visit",
        "propertyApplications:visit",
        "propertyDocuments:visit",
        "propertyHistory:visit",
        "propertyContractRentalAgreement:create",
        "propertyContractMandate:create",
        "propertyContractBasicInfo:create",
        "propertyPayments:create",
    ],
    TECHNICAL_MANAGER: [
        "propertyDashboard:visit",
        // 'propertyPayments:visit',
        // 'propertyContracts:visit',
        // 'propertyApplications:visit',
        "propertyDocuments:visit",
        "propertyHistory:visit",
        "propertyContractRentalAgreement:create",
        "propertyContractMandate:create",
        "propertyContractBasicInfo:create",
        "propertyPayments:create",
    ],
    VIEWER: ["document:delete"],
    CONTRACT_MEMBER: [
        "propertyDashboard:visit",
        "propertyContracts:visit",
        "propertyDocuments:visit",
        "propertyHistory:visit",
    ],
    TASK_MEMBER: [
        "propertyDashboard:visit",
        // "propertyPayments:visit",
        // "propertyContracts:visit",
        // "propertyApplications:visit",
        // "propertyDocuments:visit",
        // "propertyHistory:visit",
        // "propertyPayments:create",
    ],
};
