export var ENTITY = "payIn";
export var REDUCER_KEY = "PAY_IN";
export var BASE_PATH_ENTITY = "payment-pay-ins";
export var GENERATOR_PARAMS = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    legacyGetPaged: true,
    legacyGetDetail: true,
};
