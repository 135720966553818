import styled, { borderRadius, keyframes } from "@rentiohq/web-theme";
import { rem } from "polished";

const blink = keyframes`
  50% {
    opacity: 1;
  }
`;

export const Indicator = styled.div`
  display: inline-flex;
  padding: 12px; /* from app... */
  border-radius: ${borderRadius()};
  border-bottom-left-radius: 0px;

  background-color: ${props => {
    // @ts-ignore
    return props.theme.colors.global4;
  }};
  span {
    width: ${props => rem("8px", props.theme.baseFontSize)};
    height: ${props => rem("8px", props.theme.baseFontSize)};
    border-radius: 50%;
    margin: 0 1px;
    background-color: ${props => props.theme.colors.neutral60};
    display: block;
    opacity: 0.4;
    &:nth-of-type(1) {
      animation: 1s ${blink} infinite ${1 * 0.3333}s;
    }
    &:nth-of-type(2) {
      animation: 1s ${blink} infinite ${2 * 0.3333}s;
    }
    &:nth-of-type(3) {
      animation: 1s ${blink} infinite ${3 * 0.3333}s;
    }
  }
`;
