var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as invoiceActions from "@rentiohq/shared-frontend/dist/redux/invoice/invoice.actions";
import * as invoiceHooks from "@rentiohq/shared-frontend/dist/redux/invoice/invoice.hooks";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { EProduct } from "@rentiohq/shared/dist/config/app.config.types";
import { append } from "@rentiohq/shared/dist/utils/api.utils";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { NumberParam, withDefault } from "serialize-query-params";
import { DisplayText, Drawer, Grid, Loading, Lozenge, Page, Pagination, ResourceList, ResourceListItem, TextStyle, } from "../";
var InvoiceTable = function () {
    var accountId = useOutletContext().invoiceAccountId;
    var navigate = useNavigate();
    var location = useLocation();
    var _a = useQueryParams({
        page: withDefault(NumberParam, 0),
        invoiceId: NumberParam,
    }), queryParams = _a[0], setQueryParamValue = _a[1];
    var countIdentifier = accountId ? "invoices-".concat(accountId, "-count") : undefined;
    // Redux
    var dispatch = useDispatch();
    var _b = invoiceHooks.usePaged({
        page: queryParams.page,
        filter: { where: { accountId: accountId } },
    }), invoices = _b.data, isFetchingInvoices = _b.isFetching, invoicesFetchError = _b.fetchError;
    var invoice = invoiceHooks.useDetail({
        id: queryParams.invoiceId ? queryParams.invoiceId : undefined,
    }).detail;
    var totalCount = useSelector(function (state) {
        return countSelectors.getCount(state, countIdentifier);
    }) || 0;
    var pageCount = totalCount ? Math.ceil(totalCount / 20) : 1;
    // Lifecycle
    React.useEffect(function () {
        if (!countIdentifier) {
            return;
        }
        dispatch(countActions.getCount.actions.start({
            countIdentifier: countIdentifier,
            countBase: append("/invoices/count", {
                where: { accountId: accountId },
            }),
        }));
    }, [countIdentifier]);
    // Event handlers
    var handlePageClick = function (_a) {
        var selected = _a.selected;
        setQueryParamValue({ page: selected + 1 });
    };
    var handleDrawerClose = function () {
        setQueryParamValue({ invoiceId: undefined });
    };
    var handleViewClick = function (invoiceId) { return function () {
        navigate({
            pathname: "".concat(location.pathname, "/").concat(invoiceId),
        });
    }; };
    var handleMarkPaidClick = function (invoiceId) { return function () {
        dispatch(invoiceActions.markInvoicePaid.actions.start({ invoiceId: invoiceId }));
    }; };
    var handleMarkUnpaidClick = function (invoiceId) { return function () {
        dispatch(invoiceActions.markInvoiceUnpaid.actions.start({ invoiceId: invoiceId }));
    }; };
    // Render functions
    var renderInvoice = function (invoice) {
        // const status = getInvoiceStatus(invoice);
        var actions = [
            {
                content: getLocalizedText("system.view"),
                onClick: handleViewClick(invoice.id),
            },
        ];
        if (CONFIG.PRODUCT === EProduct.Backoffice) {
            if (!!invoice.manuallyPaidAt) {
                actions.push({
                    content: getLocalizedText("invoices.mark_unpaid.action"),
                    onClick: handleMarkUnpaidClick(invoice.id),
                });
            }
            else {
                actions.push({
                    content: getLocalizedText("invoices.mark_paid.action"),
                    onClick: handleMarkPaidClick(invoice.id),
                });
            }
        }
        return (_jsx(ResourceListItem, __assign({ variation: !invoice.manuallyPaidAt ? "error" : undefined, actions: actions, onClick: handleViewClick(invoice.id) }, { children: _jsxs(Grid, __assign({ alignItems: "center", justifyContent: "space-between" }, { children: [_jsxs(Grid.Item, { children: [_jsxs(DisplayText, __assign({ size: "small", space: "extraTight" }, { children: [getLocalizedText("invoices.invoice.label"), " - ", formatDate(invoice.billingDate)] })), invoice.manuallyPaidAt ? (_jsx(Lozenge, __assign({ isBold: true, appearance: "success" }, { children: getLocalizedText("invoice.manually_paid_at", {
                                    date: formatDate(invoice.manuallyPaidAt),
                                }) }))) : (_jsx(Lozenge, __assign({ isBold: true, appearance: "error" }, { children: getLocalizedText("invoices.status.not_paid") }))), CONFIG.PRODUCT === EProduct.Backoffice &&
                                invoice.teamleaderInvoiceId && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(Lozenge, __assign({ appearance: "success" }, { children: "In teamleader" }))] }))] }), _jsx(Grid.Item, { children: _jsxs(TextStyle, __assign({ variation: "code" }, { children: [formatCurrency(invoice.totalPriceVATExcluded), " ", getLocalizedText("system.vat.exclusive")] })) })] })) })));
    };
    var renderContent = function () {
        if (invoices && invoices.length > 0) {
            return (_jsxs(_Fragment, { children: [_jsx(ResourceList, { items: invoices, renderItem: renderInvoice }), !!totalCount && pageCount > 1 && (_jsx(Pagination, { initialPage: queryParams.page, pageCount: pageCount, onPageChange: handlePageClick }))] }));
        }
        if (invoicesFetchError) {
            return _jsx("p", { children: getLocalizedText("fetch.error") });
        }
        if (isFetchingInvoices) {
            return _jsx(Loading, {});
        }
        return _jsx("p", { children: getLocalizedText("invoices.empty") });
    };
    return (_jsxs(_Fragment, { children: [renderContent(), _jsx(Drawer, __assign({ isOpen: !!invoice, onClose: handleDrawerClose, width: "wide", position: "right", isFullWidth: true }, { children: _jsx(Page, { children: _jsx("pre", { children: JSON.stringify(invoice, null, 2) }) }) }))] }));
};
export default InvoiceTable;
