import {
  IApplicationContact,
  IApplicationResident,
} from "@rentiohq/shared/dist/types/application.types";

export const getTotalIncome = (contacts: IApplicationContact[]) =>
  contacts.reduce((contactAcc: number, contactCurr: any) => {
    const incomeValue = contactCurr.income.reduce(
      (incomeAcc: number, incomeCurr: any) => incomeAcc + incomeCurr.value,
      0,
    );
    return contactAcc + incomeValue;
  }, 0);

export const getResidentsCount = (
  residents: IApplicationResident[],
  residentId: string,
) => {
  return residents.reduce(
    (currentCount, resident) =>
      resident.id === residentId ? currentCount + resident.value : currentCount,
    0,
  );
};

/**
 * Find common numbers in multiple arrays.
 * @param arrays - Arrays containing numbers.
 * @returns An array containing numbers that appear in each input array.
 */
export const findCommonNumbers = (...arrays: number[][]): number[] => {
  if (arrays.length === 0) {
    return [];
  }

  // Use the first array as a reference
  const referenceArray = arrays[0];

  // Filter numbers that appear in all arrays
  const commonNumbers = referenceArray.filter(num =>
    arrays.every(array => array.includes(num)),
  );

  return commonNumbers;
};
