const DEFAULT_EXCLUDED_KEYS = ["password"];

export const removeLeadingAndTrailingSpaces = (input: string) => {
  if (!input) return;
  return input.trim().replace(/^[\r|\n|\r\n]$/, "");
};

export const trimAllValuesInObject = (
  values: Record<string, string>,
  excludedKeys?: string[],
) => {
  const _excludedKeys = [...DEFAULT_EXCLUDED_KEYS, ...(excludedKeys || [])];
  return Object.entries(values).reduce(
    (finalObject, [key, value]: [string, unknown]) => ({
      ...finalObject,
      [key]:
        _excludedKeys.includes(key) || typeof value !== "string"
          ? value
          : removeLeadingAndTrailingSpaces(value as string),
    }),
    {},
  );
};
