export enum EContractStatus {
  Upcoming = "upcoming",
  Running = "running",
  Ending = "ending",
  NoContract = "no_contract",
  Finished = "finished",
}

export enum EContractEndingStatus {
  LongTerm = "long_term",
  ShortTerm = "short_term",
  AutoEnding = "auto_ending",
  Cancelled = "cancelled",
}

export interface ChipAppearance {
  statusText: string;
  color: string;
  textColor: string;
}

export enum EContractChipColors {
  Gray = "#B9B9B9",
  Green = "#17A680",
  Blue = "blue",
  Red = "red",
  Orange = "orange",
}
