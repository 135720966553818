export enum EField {
  HasAnswered = "hasAnswered",
  Summary = "summary",
  PromisedDate = "paymentPromisedAt",
  SendToPayer = "sendToPayer",
}

export enum EHasAnswered {
  NotAnswered = "0",
  Answered = "1",
}
