import { Route } from "react-router-dom";
import { DocumentPackages } from "./DocumentPackages";
import EndingContracts from "./EndingContracts";
import { IndexableContracts } from "./IndexableContracts";
import { Payments } from "./Payments";
import { RegistrationDocuments } from "./RegistrationDocuments";
import Registrations from "./Registrations";
import { RentDeposits } from "./RentDeposits";

export const getFollowUpScene = () => (
  <Route path="follow-up">
    <Route path="ending-contracts" element={<EndingContracts />} />

    <Route path="indexable-contracts" element={<IndexableContracts />} />

    <Route path="payments">
      <Route path="*" element={<Payments />} />
    </Route>

    <Route path="registrations" element={<Registrations />} />

    <Route path="registration-documents" element={<RegistrationDocuments />} />

    <Route path="rent-deposits" element={<RentDeposits />} />

    <Route path="signatures" element={<DocumentPackages />} />
  </Route>
);
