import { EField } from "./schema.kycVerification.types";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";

export const submitActionCreator =
  (contactId: number, asBroker = false) =>
  (formData: any) =>
    contactActions.kycContact.actions.start({
      contactId,
      contact: {
        dateOfBirth: formData[EField.DateOfBirth],
        nationality: formData[EField.Nationality],
        ...formData[EField.Address],
        countryId: formData[EField.Address].country,
      },
      idCardFront: formData[EField.IdCardFront][0],
      idCardBack: formData[EField.IdCardBack][0],
      asBroker,
    });
