import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as requirementsSelectors from "@rentiohq/shared-frontend/dist/redux/requirements/requirements.selectors";
import { makeGetUnreadChatsCount } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { ETacPlatform } from "@rentiohq/shared/dist/types/auth.types";
import { UpdateTac } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";

interface IBackgroundChecksProps {}

// TODO: Split into multiple files
// 1. Show TAC modal or not
// 2. Update favicon for unread chats
export const BackgroundChecks: React.FC<IBackgroundChecksProps> = () => {
  const faviconElement = React.useRef<HTMLLinkElement>(
    document.querySelector("link[rel*='icon']"),
  );

  const unreadChatsCount: number = useSelector(makeGetUnreadChatsCount());

  const { user } = authHooks.useSelf();

  const requirements = useSelector((state: IRootStore) =>
    requirementsSelectors.getRequirements(state),
  );
  const { internalModeEnabled } = useInternalMode();

  let showUpdateTac = false;
  if (!internalModeEnabled && user && requirements) {
    const tacDashboard = user.tac?.dashboard;
    if (
      // No TAC (nothing accepted so far)
      !tacDashboard ||
      // Accepted TAC version is outdated
      tacDashboard.version < requirements.dashboardRequiredTac
    ) {
      showUpdateTac = true;
    }
  }

  React.useEffect(() => {
    if (!faviconElement.current) {
      return;
    }
    if (unreadChatsCount > 0) {
      faviconElement.current.href = faviconElement.current.dataset
        .notificationIcon as string;
    } else {
      faviconElement.current.href = faviconElement.current.dataset
        .originalIcon as string;
    }
  }, [unreadChatsCount]);

  if (!showUpdateTac) {
    return null;
  }
  return (
    <UpdateTac
      brokerId={CONFIG.RENTIO_BROKER_ID}
      platform={ETacPlatform.Dashboard}
      locale={getLocale()}
    />
  );
};
