import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EPayInStatus,
  EPayInType,
} from "@rentiohq/shared/dist/types/payin.types";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { memo, useMemo } from "react";

interface IProps {
  propertyId?: number;
  contractId?: string;
}

const IbanisationStatusInfoBase: React.FC<IProps> = ({
  propertyId,
  contractId,
}) => {
  // Redux
  const { items } = payInModule.hooks.usePaged({
    query: {
      limit: 20,
      page: 1,
      filter: {
        and: [
          { type: EPayInType.Ibanisation },
          {
            status: {
              inq: [EPayInStatus.MatchedPartially, EPayInStatus.Unmatched],
            },
          },
        ],
      },
    },
    customPath: propertyId ? `/properties/${propertyId}/pay-ins` : undefined,
  });

  const payInsForProperty = useMemo(() => {
    if (!items) return null;
    return items.filter(item => item.contractId === contractId);
  }, [items]);

  // Render
  if (!payInsForProperty) {
    return null;
  }

  if (payInsForProperty.length === 0) {
    return null;
  }

  return (
    <TextStyle element="div" variation="subdued">
      {getLocalizedText("payments.follow_up.has_unmatched_pay_ins")}
    </TextStyle>
  );
};

export const IbanisationStatusInfo = memo(IbanisationStatusInfoBase);
