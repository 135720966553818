import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing("extraLoose")};
  background: ${props => props.theme.colors.green10};
`;

export const CloseModal = styled.div`
  position: absolute;
  top: ${props => rem(16, props.theme.baseFontSize)};
  right: ${props => rem(16, props.theme.baseFontSize)};
  button {
    color: ${props => props.theme.colors.neutral90};
  }
`;
