var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createChat.types";
export var getSchema = function (initialValues, _a) {
    var chat = _a.chat;
    return function (defaultValues) {
        var _a;
        var nameMemberLimit = !chat ? 2 : 3;
        return {
            required: [EField.Property],
            properties: (_a = {},
                _a[EField.Members] = {
                    default: getValue(EField.Members, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function () { return !chat; },
                    // @ts-ignore
                    requiredIf: function () { return !chat; },
                },
                _a[EField.Name] = {
                    default: getValue(EField.Name, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Members] &&
                            formData[EField.Members].length >= nameMemberLimit;
                    },
                    // @ts-ignore
                    requiredIf: function (formData) {
                        return formData[EField.Members] &&
                            formData[EField.Members].length >= nameMemberLimit;
                    },
                    maxLength: 64,
                },
                _a[EField.Property] = {
                    default: getValue(EField.Property, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("rent_deposit.info.heading.summary"),
            "rentio:navigationTitle": getLocalizedText("chats.main.title.onexone")
        },
        _a[EField.Members] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("chat.participants"),
            selectContactFromListCtaTitle: getLocalizedText("chats.add.participants"),
            addCtaLabel: getLocalizedText("chats.add.participants"),
            isMultiSelect: true,
            includeSupport: false,
            valueSelector: function (state, selectedAccountIds) {
                if (selectedAccountIds === void 0) { selectedAccountIds = []; }
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds);
            },
            customGetSubtitleSelector: function () { return undefined; },
            customExtraAccountsSelector: function () { return []; },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
            getDisallowedAccountIds: function (_) {
                return !extraData.chat
                    ? __spreadArray([
                        extraData.contactMeMaster.accountId
                    ], extraData.contactMeMaster.accountIds, true) : [];
            },
        },
        _a[EField.Name] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("chats.subject.label"),
            placeholder: getLocalizedText("chats.subject.placeholder"),
            getInfo: function () { return getLocalizedText("chats.subject.description"); },
        },
        _a[EField.Property] = {
            "ui:field": ERentioFormField.PropertySelectInline,
            title: getLocalizedText("system.property"),
            selectPropertyFromListCtaTitle: undefined,
        },
        _a);
};
