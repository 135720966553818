import { EContractMemberTypes, } from "@rentiohq/shared/dist/types/contract.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.releaseRentDeposit.types";
export var getNavigationTitle = function (_) {
    return getLocalizedText("new.rent_deposit_release.contract");
};
export var getInitialValues = function (_a) {
    var _b;
    var relatedContract = _a.relatedContract;
    return _b = {},
        _b[EField.TenantAmount] = relatedContract.amount,
        _b[EField.OwnerAmount] = 0,
        _b[EField.BrokerAmount] = 0,
        _b[EField.JudicialRelease] = false,
        _b[EField.Signers] = relatedContract.members
            .filter(function (member) {
            return !member.roles.includes(EContractMemberTypes.Broker) &&
                member.roles.includes(EContractMemberTypes.Signer);
        })
            .map(function (member) { return member.account.id; }),
        _b;
};
export var getTenants = function (relatedContract) {
    return relatedContract.members
        .filter(function (member) {
        return member.roles.includes(EContractMemberTypes.Tenant) ||
            member.roles.includes(EContractMemberTypes.Parent);
    })
        .map(function (member) { return member.account; });
};
export var getOwners = function (relatedContract) {
    return relatedContract.members
        .filter(function (member) { return member.roles.includes(EContractMemberTypes.Owner); })
        .map(function (member) { return member.account; });
};
