var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css, } from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
var WrapAsInline = css(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var WrapAsBlock = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  /* background-color: red; */\n"], ["\n  display: block;\n  width: 100%;\n  /* background-color: red; */\n"])));
export var Wrap = styled.div.attrs(function (props) { return ({ as: props.renderType === "INLINE" ? "span" : "div" }); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return (props.renderType === "INLINE" ? WrapAsInline : WrapAsBlock); });
var ButtonAsInline = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: ", "px;\n  padding-right: ", "px;\n  margin-left: 2px;\n  margin-right: 2px;\n  border-top-left-radius: ", "px;\n  border-bottom-left-radius: ", "px;\n  border-top-right-radius: ", "px;\n  border-bottom-right-radius: ", "px;\n"], ["\n  padding-left: ", "px;\n  padding-right: ", "px;\n  margin-left: 2px;\n  margin-right: 2px;\n  border-top-left-radius: ", "px;\n  border-bottom-left-radius: ", "px;\n  border-top-right-radius: ", "px;\n  border-bottom-right-radius: ", "px;\n"])), function (props) { return (props.isStart ? 12 : 4); }, function (props) { return (props.isStart ? 4 : 12); }, function (props) { return (props.isStart ? 100 : 0); }, function (props) { return (props.isStart ? 100 : 0); }, function (props) { return (!props.isStart ? 100 : 0); }, function (props) { return (!props.isStart ? 100 : 0); });
var ButtonAsBlock = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  text-align: center;\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  border-top-left-radius: ", "px;\n  border-top-right-radius: ", "px;\n  border-bottom-left-radius: ", "px;\n  border-bottom-right-radius: ", "px;\n"], ["\n  width: 100%;\n  text-align: center;\n  margin-top: ", "px;\n  margin-bottom: ", "px;\n  border-top-left-radius: ", "px;\n  border-top-right-radius: ", "px;\n  border-bottom-left-radius: ", "px;\n  border-bottom-right-radius: ", "px;\n"])), function (props) { return (props.isStart ? 3 : 0); }, function (props) { return (props.isStart ? 0 : 3); }, function (props) { return (props.isStart ? 100 : 0); }, function (props) { return (props.isStart ? 100 : 0); }, function (props) { return (!props.isStart ? 100 : 0); }, function (props) { return (!props.isStart ? 100 : 0); });
export var Button = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  background-color: ", ";\n"], ["\n  ", ";\n  background-color: ", ";\n"])), function (props) { return (props.renderType === "INLINE" ? ButtonAsInline : ButtonAsBlock); }, function (props) { return props.tintColor; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
