import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countHooks from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { ECountIdentifier } from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractTypes from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as beneficiaryReportHooks from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary/report.beneficiary.hooks";
import * as tasksHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import { ETaskCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { ELegalCaseContractStatus } from "@rentiohq/shared/dist/types/contract.types";
import { EPayoutType } from "@rentiohq/shared/dist/types/payment.types";
import { EPropertyMemberTypes } from "@rentiohq/shared/dist/types/property.types";
import { ETaskMemberType } from "@rentiohq/shared/dist/types/task.types";
import { append } from "@rentiohq/shared/dist/utils/api.utils";
import { Page } from "@rentiohq/web-shared/dist/components";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  EEndDateWithinMonths,
  ERenewalDateWithinMonths,
} from "scenes/FollowUp/EndingContracts/EndingContracts.types";
import {
  getEndDateFilterQuery,
  getRenewalDateFilterQuery,
} from "scenes/FollowUp/EndingContracts/EndingContracts.utils";
import {
  EPaymentRequestFilterPhase,
  getPaymentRequestsFilter,
} from "scenes/FollowUp/Payments/Payments.utils";
import { BENEFICIARY_REPORTS_CUTOFF_DATE } from "scenes/Reports/Beneficiary/BeneficiaryReport.filter";
import { EBeneficiaryReportReported } from "scenes/Reports/Beneficiary/BeneficiaryReport.types";
import { Dashboard } from "./Dashboard";

const NOW = new Date();

export const DashboardContainer: FC<{}> = () => {
  const dispatch = useDispatch();

  const { user } = authHooks.useSelf();

  const { count: tasksToExecuteCount = 0 } = tasksHooks.useCount({
    query: {
      filter: {
        finishedAt: { is: null },
      },
      customFilters: [ETaskCustomFilter.NotArchived],
      rolesFilter: [ETaskMemberType.Executor],
    },
    shouldRefetch: true,
  });

  const { count: ongoingLegalCasesCount = 0 } = contractHooks.useCount({
    query: {
      filter: {
        stopDate: { gte: NOW },
        legalStatus: { eq: ELegalCaseContractStatus.Created },
      },
      propertyRolesFilter: [EPropertyMemberTypes.FinancialManager],
      customFilters: ["PROPERTY_TYPE_RESIDENTIAL"],
    },
  });

  const { count: tasksKeyPointRepair = 0 } = tasksHooks.useCount({
    query: {
      filter: {
        finishedAt: { is: null },
        status: { isNot: null },
      },
      customFilters: [ETaskCustomFilter.NotArchived],
    },
    shouldRefetch: true,
  });

  const { count: indexableContractsCount = 0 } = contractHooks.useCount({
    query: contractTypes.ContractFilters.contractsIndexable,
    shouldRefetch: true,
  });

  const { count: contractsWithDeadlineWithin1MonthCount = 0 } =
    contractHooks.useCount({
      query: getRenewalDateFilterQuery({
        tacitRenewal: true,
        renewalDateWithinMonths: ERenewalDateWithinMonths.Within1Month,
      }),
      shouldRefetch: true,
    });

  const { count: contractsWithEndDateWithin1MonthCount = 0 } =
    contractHooks.useCount({
      query: getEndDateFilterQuery({
        tacitRenewal: false,
        endDateWithinMonths: EEndDateWithinMonths.Within1Month,
      }),
      shouldRefetch: true,
    });

  const signaturesPendingCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.DocumentPackagesPending,
      ) || 0,
  );

  const revenuesCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(state, ECountIdentifier.Revenues) || 0,
  );

  const registrationsContractsActionRequiredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        ECountIdentifier.RegistrationsContractsActionRequired,
      ) || 0,
  );

  const registrationAddendaActionRequiredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        ECountIdentifier.RegistrationsAddendaActionRequired,
      ) || 0,
  );

  const registrationLocationDescriptionActionRequiredCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        ECountIdentifier.RegistrationLocationDescriptionActionRequired,
      ) || 0,
  );
  const { count: paymentRequestsCount = 0 } = countHooks.useCount({
    countBase: append("/payment-requests/count", {
      where: getPaymentRequestsFilter({}).where,
    }),
  });

  const { count: paymentRequestsRentioCount = 0 } = countHooks.useCount({
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({
          phase: EPaymentRequestFilterPhase.Rentio,
        }).where,
      ),
    }),
  });

  const { count: paymentRequestsActionRequiredCount = 0 } = countHooks.useCount(
    {
      countBase: append("/payment-requests/count", {
        where: mapObjectWithTimeZonedDatesToUtcIsoString(
          getPaymentRequestsFilter({
            phase: EPaymentRequestFilterPhase.Action,
          }).where,
        ),
      }),
    },
  );

  const paymentsTooLate =
    paymentRequestsRentioCount + paymentRequestsActionRequiredCount;

  const activeAccountId = user?.activeAccountId;

  //NEW
  const toSignRentDepositsCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(state, ECountIdentifier.RentDepositsToBeSigned) ||
      0,
  );

  const { count: toBeReportedCount = 0 } = beneficiaryReportHooks.useCount({
    query: {
      filter: { createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE } },
      customFilters: [EBeneficiaryReportReported.PaymentRequestNotReported],
    },
  });

  useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.RentDepositsToBeSigned,
        countBase:
          countTypes.COUNT_BASE[ECountIdentifier.RentDepositsToBeSigned],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.DocumentPackagesPending,
        countBase:
          countTypes.COUNT_BASE[ECountIdentifier.DocumentPackagesPending],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.Revenues,
        countBase: append("/payment-requests/count", {
          where: {
            invoicedAt: null,
            externalInvoiceId: null,
            requestPayeeAccountId: activeAccountId,
            payoutType: EPayoutType.Payout,
          },
          selection: ["FUTURE_REVENUES"],
        }),
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.RegistrationsContractsActionRequired,
        countBase:
          countTypes.COUNT_BASE[
            ECountIdentifier.RegistrationsContractsActionRequired
          ],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: ECountIdentifier.RegistrationsAddendaActionRequired,
        countBase:
          countTypes.COUNT_BASE[
            ECountIdentifier.RegistrationsAddendaActionRequired
          ],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier:
          ECountIdentifier.RegistrationLocationDescriptionActionRequired,
        countBase:
          countTypes.COUNT_BASE[
            ECountIdentifier.RegistrationLocationDescriptionActionRequired
          ],
      }),
    );
  }, []);

  if (!user) {
    return null;
  }

  return (
    <Page>
      <Dashboard
        counts={{
          paymentRequestsCount,
          revenuesCount,
          indexableContractsCount,
          signaturesPendingCount,
          registrationLocationDescriptionActionRequiredCount,
          registrationAddendaActionRequiredCount,
          registrationsContractsActionRequiredCount,
          toSignRentDepositsCount,
          tasksToExecuteCount,
          tasksKeyPointRepair,
          deadlineWithin1MonthCount: contractsWithDeadlineWithin1MonthCount,
          endDateWithin1MonthCount: contractsWithEndDateWithin1MonthCount,
          toBeReportedCount,
          ongoingLegalCasesCount,
          paymentsTooLate,
        }}
      />
    </Page>
  );
};
