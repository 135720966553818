import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { IInsurance } from "@rentiohq/shared-frontend/dist/redux/insurance/insurance.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import CONFIG from "@rentiohq/shared/dist/config/app.config";
import { ACTIVITY_TYPES } from "@rentiohq/shared/dist/types/activity.types";
import {
  Card,
  DisplayText,
  ESpacings,
  Icon,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { ETabs } from "utils/tabs.utils";

interface IProps {
  setTab: (tab: ETabs) => void;
  insurance: IInsurance;
  onDrawerClose?: () => void;
  onPollExternal: () => void;
  onResendInvite: () => void;
}

const InsuranceInfo: React.FC<IProps> = props => {
  const { setTab, insurance, onPollExternal, onResendInvite } = props;

  // Hooks
  const { internalModeEnabled } = useInternalMode();
  const { isBroker } = authHooks.useSelf();

  const {
    externalPolicyholderEmail: email,
    externalPolicyholderFirstName: firstName,
    externalPolicyholderLastName: lastName,
  } = insurance;

  // Event handlers
  const handleActivityMoreClick = () => {
    setTab(ETabs.History);
  };

  const handleOpenInsuranceLink = () => {
    if (!insurance.externalId || !insurance.externalAccessCode) {
      return;
    }

    const url = new URL(CONFIG.INSURANCE_TENANT_FLOW_URL);
    url.searchParams.set("leadId", insurance.externalId || "");
    url.searchParams.set("publicId", insurance.externalAccessCode || "");
    if (internalModeEnabled) {
      url.searchParams.set("internalMode", "1");
    }

    window.open(url.toString(), "_blank");
  };

  // Render
  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const renderPolicyHolderCard = () => {
    if (!firstName || !lastName || !email) {
      return (
        <Card
          heading={renderCardHeader(
            getLocalizedText("insurance.detail.card.no_information.title"),
          )}
          space={ESpacings.base}
        >
          <TextStyle element="div" size="medium" variation="subdued">
            {getLocalizedText("insurance.detail.card.no_information.body")}
          </TextStyle>

          <Spacer weight={ESpacerWeight.W08} />

          <Button appearance="link" onClick={onPollExternal}>
            {getLocalizedText("insurance.poll.cta.title")}
          </Button>
        </Card>
      );
    }

    return (
      <Card
        heading={renderCardHeader(
          getLocalizedText("insurance.detail.card.policyholder.title"),
        )}
        space={ESpacings.base}
      >
        <ResourceListItem
          media={<Icon source="contact" />}
          mediaSize="medium"
          boxProps={{
            px: ESpacings.base,
            py: ESpacings.tight,
            pl: ESpacings.tight,
          }}
          actions={[
            {
              content: getLocalizedText("insurance.resend_invite.cta.title"),
              onClick: onResendInvite,
            },
          ]}
        >
          <TextStyle element="div" size="medium">
            {`${firstName} ${lastName}`}
          </TextStyle>

          <Spacer weight={ESpacerWeight.W04} />

          <TextStyle element="div" variation="subdued">
            {email}
          </TextStyle>
        </ResourceListItem>
      </Card>
    );
  };

  const renderActivities = () => (
    <Card
      heading={renderCardHeader(getLocalizedText("system.history"))}
      space={ESpacings.base}
    >
      <Box mb={ESpacings.base}>
        <Activities
          identifier={`insurance-${insurance.id}-compact`}
          filter={{
            where: {
              insuranceId: insurance.id,
              type: ACTIVITY_TYPES.INSURANCE_DASHBOARD,
            },
          }}
          limit={3}
          isCompact={true}
          onMoreClick={handleActivityMoreClick}
        />
      </Box>
    </Card>
  );

  return (
    <>
      {isBroker && insurance && (
        <Button
          appearance="primary"
          isFullWidth={true}
          onClick={handleOpenInsuranceLink}
        >
          {getLocalizedText("insurance.detail.cta.title")}
        </Button>
      )}
      <Spacer weight={ESpacerWeight.W32} />
      {renderPolicyHolderCard()}
      {renderActivities()}
    </>
  );
};

export default InsuranceInfo;
