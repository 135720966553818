import { composeWithDevTools } from "@redux-devtools/extension";
import applyWindowStateListener from "@rentiohq/web-shared/dist/redux/applyWindowStateListener";
import { applyMiddleware, createStore } from "redux";
import middlewares, { sagaMiddleware } from "./middlewares";
import { reducers } from "./reducers";
import { rootSaga } from "./sagas";

const store = createStore(
  reducers,
  composeWithDevTools(
    applyWindowStateListener(),
    applyMiddleware(...middlewares),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
