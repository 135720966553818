var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonActions } from "../Button/components/ButtonActions";
import Loading from "../Loading";
import NoResults from "../NoResults";
import { StyledEmptyState, StyledEmptyStateActions } from "./EmptyState.styled";
var EmptyState = function (_a) {
    var icon = _a.icon, children = _a.children, heading = _a.heading, action = _a.action, _b = _a.size, size = _b === void 0 ? "small" : _b, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c, _d = _a.isShownInline, isShownInline = _d === void 0 ? false : _d, _e = _a.backgroundColor, backgroundColor = _e === void 0 ? "primary" : _e;
    return (_jsx(StyledEmptyState, __assign({ backgroundColor: backgroundColor, isShownInline: isShownInline }, { children: isLoading ? (_jsx(Loading, {})) : (_jsxs(NoResults, __assign({ headingSize: size, icon: icon, heading: heading, maxWidth: size === "large" ? 800 : 400 }, { children: [children, action && (_jsx(StyledEmptyStateActions, { children: _jsx(ButtonActions, { actions: [action], options: {
                            overrides: {
                                size: action.size || "large",
                                appearance: action.appearance || "primary",
                            },
                        } }) }))] }))) })));
};
export default EmptyState;
