var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { OptionalText } from "@rentiohq/shared-frontend/dist/components/components/FormInputBase/FormInputBase.styles";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { areContactFieldsValid } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import { EContactType, isContact, } from "@rentiohq/shared/dist/types/contact.types";
import uniq from "lodash/uniq";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextStyle } from "../../../../..";
import { ts } from "../../../../../../services";
import AddContactModal from "../../../../../AddContactModal";
import Button from "../../../../../Button";
import { ContactConnectionIndicator } from "../../../../../ContactConnectionIndicator";
import ContactEditModal from "../../../../../ContactEditModal";
import ContactFetchListItem from "../../../../../ContactFetchListItem";
import { DefaultContactListItemRender } from "../../../../../ContactList/ContactList";
import ContactSearch from "../../../../../ContactSearch";
import { StyledError, StyledHelpText } from "../../../../../Form/Form.styled";
import { ESpacings } from "../../../../../Grid";
import Icon from "../../../../../Icon";
import { Label } from "../../../../../Labelled/Labelled.styled";
import { OptionListItem } from "../../../../../OptionList/components/OptionListItem";
import { ResourceListItem, StyledItemWrapper, StyledList, StyledListWrapper, } from "../../../../../ResourceList";
import Stack from "../../../../../Stack";
export var ContactSelect = function (_a) {
    var formData = _a.formData, info = _a.info, error = _a.error, optional = _a.optional, readOnly = _a.readOnly, disabled = _a.disabled, _b = _a.asOptionList, asOptionList = _b === void 0 ? false : _b, _c = _a.isMultiSelect, isMultiSelect = _c === void 0 ? true : _c, prefilledAddress = _a.prefilledAddress, initialType = _a.initialType, _d = _a.includeEmployees, includeEmployees = _d === void 0 ? false : _d, getDisallowedAccountIds = _a.getDisallowedAccountIds, getDisallowedContactIds = _a.getDisallowedContactIds, _e = _a.selectedAccountIds, selectedAccountIds = _e === void 0 ? [] : _e, customGetSubtitleSelector = _a.customGetSubtitleSelector, customExtraAccountsSelector = _a.customExtraAccountsSelector, customIsFetchingSelector = _a.customIsFetchingSelector, _f = _a.customGetContactsActions, customGetContactsActions = _f === void 0 ? [] : _f, requiredContactFieldsSchema = _a.requiredContactFieldsSchema, requiredCompanyFieldsSchema = _a.requiredCompanyFieldsSchema, onContactSelectChange = _a.onContactSelectChange, required = _a.required, _g = _a.includeSupport, includeSupport = _g === void 0 ? false : _g, getRequiredContactFieldsSchema = _a.getRequiredContactFieldsSchema, getRequiredCompanyFieldsSchema = _a.getRequiredCompanyFieldsSchema, accountIdsToRenderFilter = _a.accountIdsToRenderFilter, props = __rest(_a, ["formData", "info", "error", "optional", "readOnly", "disabled", "asOptionList", "isMultiSelect", "prefilledAddress", "initialType", "includeEmployees", "getDisallowedAccountIds", "getDisallowedContactIds", "selectedAccountIds", "customGetSubtitleSelector", "customExtraAccountsSelector", "customIsFetchingSelector", "customGetContactsActions", "requiredContactFieldsSchema", "requiredCompanyFieldsSchema", "onContactSelectChange", "required", "includeSupport", "getRequiredContactFieldsSchema", "getRequiredCompanyFieldsSchema", "accountIdsToRenderFilter"]);
    var dispatch = useDispatch();
    // Map state to props
    var getSubtitle = useSelector(function (state) { return function (item) {
        return customGetSubtitleSelector(state, formData, item);
    }; });
    var extraAccounts = useSelector(function (state) {
        return customExtraAccountsSelector(state, formData);
    }) || [];
    var accountIdsToRender = uniq(__spreadArray(__spreadArray([], extraAccounts.map(function (account) { return account.id; }), true), selectedAccountIds, true)).filter(function (accountId) {
        if (!asOptionList) {
            return accountId && selectedAccountIds.includes(accountId);
        }
        return true;
    });
    var isFetching = useSelector(function (state) {
        return customIsFetchingSelector(state);
    });
    // Custom variables
    var maxItems = isMultiSelect ? undefined : 1;
    var mediaSize = "mediumLarge";
    // State
    var _h = React.useState(false), showSearch = _h[0], setShowSearch = _h[1];
    var _j = React.useState(), contactToComplete = _j[0], setContactToComplete = _j[1];
    var _k = React.useState(), accountToAdd = _k[0], setAccountToAdd = _k[1];
    React.useEffect(function () {
        customGetContactsActions.forEach(function (action) {
            dispatch(action());
        });
    }, [customGetContactsActions]);
    React.useEffect(function () {
        if (isFetching) {
            return;
        }
        if (selectedAccountIds.length === 0 &&
            extraAccounts.length === 0 &&
            !showSearch &&
            required) {
            setShowSearch(true);
            return;
        }
        setShowSearch(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);
    var selectRequiredContactFieldsSchema = function (contact) {
        return getRequiredContactFieldsSchema && contact
            ? getRequiredContactFieldsSchema(contact)
            : requiredContactFieldsSchema;
    };
    var selectRequiredCompanyFieldsSchema = function (contact) {
        return getRequiredCompanyFieldsSchema && contact
            ? getRequiredCompanyFieldsSchema(contact)
            : requiredCompanyFieldsSchema;
    };
    // Event handlers
    var handleContactAdd = function (contactOrAccount) {
        var extraAccount = undefined;
        if (isContact(contactOrAccount)) {
            var isValid = areContactFieldsValid({
                contact: contactOrAccount,
                requiredContactFieldsSchema: selectRequiredContactFieldsSchema(contactOrAccount),
                requiredCompanyFieldsSchema: selectRequiredCompanyFieldsSchema(contactOrAccount),
            });
            if (!isValid) {
                handleContactEdit(contactOrAccount)();
                return;
            }
            extraAccount = extraAccounts.find(function (account) {
                return contactOrAccount.accountIds.includes(account.id);
            });
        }
        var accountId = isContact(contactOrAccount)
            ? contactOrAccount.accountId
            : contactOrAccount.id;
        onContactSelectChange({
            addIds: extraAccount ? extraAccount.id : accountId,
        });
        setShowSearch(false);
    };
    var handleContactRemove = function (contact) {
        onContactSelectChange({ removeIds: contact.accountIds });
    };
    var handleContactEdit = function (contact) { return function () {
        setContactToComplete(contact);
    }; };
    var handleAccountAdd = function (account) { return function () {
        setAccountToAdd(account);
    }; };
    var handleShowForm = function () {
        if (isMultiSelect) {
            // TODO: Why is this triggered when search is already showing?
            if (showSearch) {
                return;
            }
            setShowSearch(function (prevValue) { return !prevValue; });
            return;
        }
        if (asOptionList && !isMultiSelect) {
            onContactSelectChange({ removeIds: selectedAccountIds });
        }
        setShowSearch(true);
    };
    var handleModalClose = function () {
        setShowSearch(false);
        setContactToComplete(undefined);
        setAccountToAdd(undefined);
    };
    var handleChange = function (accountId) { return function () {
        var _a;
        var idThatIsAlreadyIncluded = undefined;
        var foundContact = undefined;
        var state = (_a = getStore()) === null || _a === void 0 ? void 0 : _a.getState();
        if (state) {
            idThatIsAlreadyIncluded = selectedAccountIds.reduce(function (finalId, currentId) {
                foundContact = contactSelectors.getContact(state, undefined, accountId);
                if (foundContact === null || foundContact === void 0 ? void 0 : foundContact.accountIds.includes(currentId)) {
                    finalId = currentId;
                }
                return finalId;
            }, undefined);
        }
        var ids = idThatIsAlreadyIncluded !== null && idThatIsAlreadyIncluded !== void 0 ? idThatIsAlreadyIncluded : accountId;
        if (idThatIsAlreadyIncluded || selectedAccountIds.includes(accountId)) {
            onContactSelectChange({
                removeIds: ids,
            });
            return;
        }
        setShowSearch(false);
        onContactSelectChange({ addIds: ids });
    }; };
    var handleClickContact = function (contact) { return function () {
        var extraAccount = extraAccounts.find(function (account) {
            return contact.accountIds.includes(account.id);
        });
        handleChange(extraAccount ? extraAccount.id : contact.accountId)();
    }; };
    var handleSelectMoreContacts = function (contact) {
        // is this contact 1 of the extraAccounts
        var extraAccount = extraAccounts.find(function (account) {
            return contact.accountIds.includes(account.id);
        });
        setShowSearch(false);
        onContactSelectChange({
            addIds: extraAccount ? extraAccount.id : contact.accountId,
        });
    };
    // Render methods
    var renderContactSearchField = function () {
        var disallowedAccountIds = (getDisallowedAccountIds === null || getDisallowedAccountIds === void 0 ? void 0 : getDisallowedAccountIds(formData, accountIdsToRender)) || [];
        var disallowedContactIds = getDisallowedContactIds === null || getDisallowedContactIds === void 0 ? void 0 : getDisallowedContactIds(formData, accountIdsToRender);
        if (maxItems && selectedAccountIds.length >= maxItems && !asOptionList) {
            return null;
        }
        return (_jsx(ContactSearch, { includeEmployees: includeEmployees, onContactSelect: handleContactAdd, optional: optional, includeSupport: includeSupport, error: error, errorMessageHidden: true, disallowedAccountIds: __spreadArray(__spreadArray([], disallowedAccountIds, true), selectedAccountIds, true), disallowedContactIds: disallowedContactIds, prefilledAddress: prefilledAddress, initialType: initialType }));
    };
    var renderAccount = function (hasSeparator) { return function (finalAccount) {
        var description = requiredContactFieldsSchema ? (_jsx(Button, __assign({ appearance: "link", onClick: handleAccountAdd(finalAccount) }, { children: ts.contactAddAsContact() }))) : (_jsx(_Fragment, { children: getSubtitle({ accountId: finalAccount.id, account: finalAccount }) }));
        var selected = selectedAccountIds.includes(finalAccount.id);
        return asOptionList ? (_jsx(OptionListItem, { type: isMultiSelect ? "checkbox" : "radio", title: getName(finalAccount), value: finalAccount.id, error: error, subtitle: description, selected: selected, onChange: requiredContactFieldsSchema
                ? handleAccountAdd(finalAccount)
                : handleChange(finalAccount.id), appearance: requiredContactFieldsSchema ? "error" : undefined })) : (_jsx(StyledItemWrapper, __assign({ hasSeparator: hasSeparator }, { children: _jsx(ResourceListItem, __assign({ item: finalAccount, media: _jsx(Icon, { source: "profile", size: mediaSize }), mediaSize: mediaSize, boxProps: { p: 2 }, variation: requiredContactFieldsSchema ? "warning" : undefined, actions: [
                    !readOnly && {
                        media: _jsx(Icon, { source: "bin", color: "red" }),
                        content: (_jsx(TextStyle, __assign({ variation: "negative" }, { children: ts.contactSelectRemoveContact("contact") }))),
                        onClick: function () {
                            handleContactRemove(finalAccount);
                        },
                    },
                ].filter(Boolean) }, { children: _jsx(DefaultContactListItemRender, { contactName: getName(finalAccount), contactMeta: description }) })) })));
    }; };
    var renderContact = function (hasSeparator) { return function (finalContact) {
        var item = { contact: finalContact };
        var account = extraAccounts.find(function (randomAccount) {
            return finalContact.accountIds.includes(randomAccount.id);
        });
        if (account) {
            item.accountId = account.id;
            item.account = account;
        }
        var selected = !!finalContact.accountIds.find(function (randomAccountId) {
            return selectedAccountIds.includes(randomAccountId);
        });
        var isValid = areContactFieldsValid({
            contact: finalContact,
            requiredContactFieldsSchema: selectRequiredContactFieldsSchema(finalContact),
            requiredCompanyFieldsSchema: selectRequiredCompanyFieldsSchema(finalContact),
        });
        var showWarning = !isValid;
        var description = (_jsxs(Stack, __assign({ display: "inline-flex" }, { children: [_jsxs(Stack.Item, { children: [asOptionList && (_jsx(ContactConnectionIndicator, { contact: finalContact })), getSubtitle(item) ||
                            (finalContact.type === EContactType.Personal
                                ? ts.system("yourself")
                                : ts.system("contact"))] }), showWarning && (_jsx(Button, __assign({ appearance: "link", onClick: handleContactEdit(finalContact) }, { children: ts.formCompleteMissingFields() })))] })));
        var iconSource = !!finalContact.company ? "briefcase" : "profile";
        if (asOptionList) {
            return (_jsx(OptionListItem, { type: isMultiSelect ? "checkbox" : "radio", title: getName(finalContact), value: finalContact.id, error: error, subtitle: description, selected: selected, disabled: disabled, onChange: !isValid
                    ? handleContactEdit(finalContact)
                    : handleClickContact(finalContact), appearance: showWarning ? "warning" : undefined }));
        }
        return (_jsx(StyledItemWrapper, __assign({ hasSeparator: hasSeparator }, { children: _jsx(ResourceListItem, __assign({ item: finalContact, media: _jsx(Icon, { source: iconSource, size: mediaSize }), mediaSize: mediaSize, boxProps: { p: 2 }, variation: showWarning ? "warning" : undefined, actions: [
                    {
                        content: ts.contactEditContact(),
                        media: _jsx(Icon, { source: "edit", size: "small" }),
                        isContactAction: true,
                        onClick: handleContactEdit(finalContact),
                    },
                    !readOnly && {
                        media: _jsx(Icon, { source: "bin", color: "red" }),
                        content: (_jsx(TextStyle, __assign({ variation: "negative" }, { children: ts.contactSelectRemoveContact("contact") }))),
                        onClick: function () {
                            handleContactRemove(finalContact);
                        },
                    },
                ].filter(Boolean) }, { children: _jsx(DefaultContactListItemRender, { contactName: getName(finalContact), contactMeta: description, contact: finalContact }) })) })));
    }; };
    var getCTALabel = function () {
        var emptySelectCtaLabel = props.emptySelectCtaLabel, initialCtaLabel = props.addCtaLabel, customAddCtaLabel = props.customAddCtaLabel;
        var addCtaLabel = !!customAddCtaLabel
            ? customAddCtaLabel(formData)
            : initialCtaLabel;
        var showEmptyExtraLabel = selectedAccountIds.length === 0 && extraAccounts.length === 0;
        var emptyCTALabel = emptySelectCtaLabel || addCtaLabel;
        return showEmptyExtraLabel ? emptyCTALabel : addCtaLabel;
    };
    var renderAddContact = function () {
        if (disabled) {
            return null;
        }
        var ctaLabel = getCTALabel();
        var addForm = ctaLabel && !readOnly;
        var showForm = !readOnly || (maxItems && selectedAccountIds.length < maxItems);
        return (_jsx(Box, __assign({ mt: ESpacings.tight }, { children: asOptionList
                ? addForm && (_jsx(OptionListItem, { type: isMultiSelect ? "checkbox" : "radio", title: showSearch ? (_jsxs(_Fragment, { children: [ctaLabel, renderContactSearchField()] })) : (ctaLabel), error: error, selected: showSearch, onChange: handleShowForm }))
                : showForm && renderContactSearchField() })));
    };
    var getContactFieldsForModal = function () {
        var fields = undefined;
        if (!!accountToAdd) {
            fields = __spreadArray([
                "firstname",
                "lastname",
                "email",
                "phone"
            ], (accountToAdd.company ? ["company", "VATNumber"] : []), true);
        }
        var contactsSchema = selectRequiredContactFieldsSchema(contactToComplete);
        var companiesSchema = selectRequiredCompanyFieldsSchema(contactToComplete);
        if (contactsSchema) {
            var requiredFields = contactToComplete && !!contactToComplete.company
                ? companiesSchema || contactsSchema
                : contactsSchema;
            fields = __spreadArray(__spreadArray([], (fields || []), true), requiredFields.required, true);
        }
        return fields;
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Label, { children: [props.title, optional && (_jsx(OptionalText, { children: getLocalizedText("system.optional").toUpperCase() }))] }), _jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(StyledListWrapper, { children: _jsx(StyledList, { children: accountIdsToRender
                        .filter(accountIdsToRenderFilter
                        ? accountIdsToRenderFilter(accountIdsToRender)
                        : function () { return true; })
                        .map(function (accountId, index) {
                        var account = extraAccounts.find(function (randomAccount) { return accountId === randomAccount.id; });
                        var hasSeparator = index < accountIdsToRender.length - 1;
                        return (_jsx(ContactFetchListItem, { account: account, accountId: accountId, renderAccount: renderAccount(hasSeparator), renderContact: renderContact(hasSeparator) }, accountId));
                    }) }) }), renderAddContact(), info && _jsx(StyledHelpText, { children: info }), error && _jsx(StyledError, { children: error }), !!contactToComplete && (_jsx(ContactEditModal, { onClose: handleModalClose, onSubmit: function (values, formType) {
                    if (formType === "requiredFields") {
                        handleSelectMoreContacts(values);
                    }
                    handleModalClose();
                }, contactId: contactToComplete.id, fields: getContactFieldsForModal() })), !!accountToAdd && (_jsx(AddContactModal, { type: !!accountToAdd.company ? "company" : "contact", contact: __assign(__assign({}, (accountToAdd.contactInfo || {})), { firstname: accountToAdd.firstname, lastname: accountToAdd.lastname, company: accountToAdd.company }), baseAccountId: accountToAdd.id, onClose: handleModalClose, onSubmit: function (values) {
                    handleSelectMoreContacts(values);
                    handleModalClose();
                } }))] }));
};
