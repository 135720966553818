import { Route } from "react-router-dom";
import { ContactDashboard } from "../ContactDashboard";
import { ContactDocuments } from "../ContactDocuments";
import { ContactHistory } from "../ContactHistory";
import { ContactDetail } from "./ContactDetail";

export const getContactDetailScene = () => (
  <Route path=":contactId" element={<ContactDetail />}>
    <Route index element={<ContactDashboard />} />

    <Route path="documents" element={<ContactDocuments />} />

    <Route path="history" element={<ContactHistory />} />
  </Route>
);
