import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { EField as EContactField } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import { ETemplateType } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentHooks from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.hooks";
import {
  ITemplateDocument,
  ITemplateDocumentDetail,
} from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as bankAccountHooks from "@rentiohq/shared-frontend/dist/reduxV2/bankAccount/bankAccount.hooks";
import * as contractActions from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.actions";
import * as contractSelectors from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getContractAmountAndTenants } from "@rentiohq/shared-frontend/dist/utils/contract.utils";
import { getTerm } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import {
  getAllRequiredCompanyFields,
  getAllRequiredContactFields,
} from "@rentiohq/shared-frontend/dist/utils/forms.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  join,
  stringToSnakeCase,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { IAccount, IMember } from "@rentiohq/shared/dist/types/auth.types";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import {
  EContractIbanisationStatus,
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared/dist/types/property.types";
import { getMembersWithOneOfRoles } from "@rentiohq/shared/dist/utils/roles.utils";
import {
  ContactEditModal,
  Labelled,
  Loading,
  Modal,
  MultiStepForm,
  Select,
  TextField,
} from "@rentiohq/web-shared/dist/components";
import { AccountList } from "@rentiohq/web-shared/dist/components/AccountList";
import AddContactModal from "@rentiohq/web-shared/dist/components/AddContactModal";
import Banner from "@rentiohq/web-shared/dist/components/Banner";
import Button from "@rentiohq/web-shared/dist/components/Button";
import { OptionList } from "@rentiohq/web-shared/dist/components/OptionList";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { EDefaultVariableId } from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.config";
import { IVariablesData } from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.types";
import {
  parseFields,
  parseVariablesData,
} from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { compact } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { getAvailableTemplatesForType } from "scenes/Properties/scenes/Contracts/components/ContractCard/ContractCard.utils";
import { ts } from "../../services";
import {
  contactIsValidForTemplate,
  getDefaultDocumentConfig,
  getDefaultFilename,
  getDefaultVariablesData,
} from "./TemplateDocumentContractCreateModal.utils";
import duplicateTemplatePropertySelectSchemas from "./forms/duplicateTemplatePropertySelect/schema.duplicateTemplatePropertySelect.list";

const PREFERENCE_KEY_BRAND_COLOR = "BRAND_COLOR";
const PREFERENCE_KEY_BRAND_LOGO = "BRAND_LOGO";

const { i18n } = utils;

enum EStep {
  Intro,
  DuplicateProperty,
  DuplicateTemplateDocument,
  Contacts,
}

const formId = generateFormId();

interface IProps {
  type: "general" | "contract";
  property: IProperty;
  contract?: IContract;
  onClose: () => void;
}

const TemplateDocumentContractCreateModal: React.FC<IProps> = props => {
  const { type, property, contract, onClose } = props;

  const navigate = useNavigate();

  // State
  const [isPristine, setIsPristine] = React.useState(true);
  const [step, setStep] = React.useState(EStep.Intro);
  const [selectedDuplicatePropertyId, setSelectedDuplicatePropertyId] =
    React.useState<number>();
  const [
    selectedDuplicateTemplateDocumentId,
    setSelectedDuplicateTemplateDocumentId,
  ] = React.useState<string>();
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<
    string | undefined
  >();
  const [selectedContractId, setSelectedContractId] = React.useState<
    string | undefined
  >();
  const [filename, setFilename] = React.useState<string | undefined>();
  const [contactToComplete, setContactToComplete] = React.useState<IContact>();
  const [accountToAdd, setAccountToAdd] = React.useState<IAccount>();

  // Redux
  const dispatch = useDispatch();

  const contacts: IContact[] = useSelector((state: IRootStore) =>
    contactSelectors.getAllContacts(state),
  );
  const legalContact = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.Legal),
  );
  const meMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.MeMaster),
  );

  const { broker, isBroker } = authHooks.useSelf();

  const brandColor = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_COLOR),
  );
  const brokerLogoDocumentId = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_LOGO),
  );

  const preferences = useSelector(
    (state: IRootStore) => state.systemLocal.preferences,
  );

  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );

  const availableTemplates = getAvailableTemplatesForType({
    type,
    isBroker,
    templates,
    contract,
    property,
  });

  const activeContractsForPropertyFilter = property.id
    ? contractUtils.getFilterForProperty(property.id, EContractFetchType.Active)
    : undefined;

  const activeContracts = useSelector(
    (state: IRootStore) =>
      contractSelectors.getAllWhere(state, {
        filter: activeContractsForPropertyFilter,
      })?.items || [],
  );

  const selectedContract = useSelector((state: IRootStore) =>
    contractSelectors.getDetail(state, selectedContractId),
  );

  const { detail: ibanisationBankAccount } = bankAccountHooks.useDetail({
    customPath: contract?.id
      ? `/contracts/${contract?.id}/ibanisation-bank-account`
      : undefined,
  });

  const templateDocumentsPaged = templateDocumentHooks.usePaged({
    preventDefaultFetching: !selectedDuplicatePropertyId,
    page: 0,
    limit: 20,
    filter: {
      where: {
        propertyId: selectedDuplicatePropertyId,
        type:
          type === "contract"
            ? {
                inq: [
                  ETemplateType.ContractResidential,
                  ETemplateType.ContractResidentialShort,
                  ETemplateType.ContractResidentialLong,
                  ETemplateType.ContractStudentResidence,
                  ETemplateType.ContractCommercial,
                  ETemplateType.ContractParkingLot,
                  ETemplateType.ContractGarage,
                  ETemplateType.ContractOther,
                ],
              }
            : {
                inq: [
                  ETemplateType.KeyHandover,
                  ETemplateType.Indexation,
                  ETemplateType.Other,
                ],
              },
      },
    },
  });

  // Lifecycle
  React.useEffect(() => {
    if (selectedTemplateId) {
      return;
    }

    if (!availableTemplates) {
      return;
    }

    if (availableTemplates.length === 1) {
      setSelectedTemplateId(availableTemplates[0].id);
      setFilename(
        getDefaultFilename({ type, template: availableTemplates[0], property }),
      );
    }
  }, [availableTemplates]);

  React.useEffect(() => {
    if (!selectedContractId) {
      return;
    }

    dispatch(
      contractActions.getDetailStart.getAction({ id: selectedContractId }),
    );
  }, [selectedContractId]);

  // Event handlers
  const handleChangeTemplate = (option: { value: string }) => {
    const template = availableTemplates?.find(x => x.id === option.value);
    if (!template) {
      setSelectedTemplateId(undefined);
      setFilename(undefined);
      return;
    }

    setSelectedTemplateId(template.id);
    setFilename(getDefaultFilename({ type, template, property }));
  };

  const duplicateFromTemplateDocument = () => {
    if (!selectedDuplicatePropertyId) {
      return;
    }

    if (!selectedDuplicateTemplateDocumentId) {
      return;
    }

    const templateDocument = templateDocumentsPaged.data?.find(
      (x: ITemplateDocument) => x.id === selectedDuplicateTemplateDocumentId,
    );
    if (!templateDocument) {
      return;
    }

    dispatch(
      templateDocumentActions.getTemplateDocumentDetail.actions.start({
        id: templateDocument.id,
        onSuccess: (templateDocumentDetail: ITemplateDocumentDetail) => {
          if (!templateDocumentDetail.fields) {
            return;
          }

          const variablesData = parseVariablesData(
            templateDocumentDetail.variablesData,
          );

          const variablesDataToOverride: IVariablesData = {};

          Object.keys(variablesData).forEach(key => {
            // Not a default value = allow
            if (
              !Object.values(EDefaultVariableId).includes(
                key as EDefaultVariableId,
              )
            ) {
              variablesDataToOverride[key] = variablesData[key];

              return;
            }

            // Default value & in whitelist = allow
            const whitelist = [
              EDefaultVariableId.LocationDescriptionPerson,

              EDefaultVariableId.BrokerEnabled,
              EDefaultVariableId.BrokerBiv,
              EDefaultVariableId.BrokerOfficeName,
              EDefaultVariableId.BrokerFullName,
              EDefaultVariableId.BrokerFirstName,
              EDefaultVariableId.BrokerLastName,
              EDefaultVariableId.BrokerBirthPlace,
              EDefaultVariableId.BrokerBirthDate,
              EDefaultVariableId.BrokerAddressFull,
              EDefaultVariableId.BrokerAddressStreet,
              EDefaultVariableId.BrokerAddressNumber,
              EDefaultVariableId.BrokerAddressBox,
              EDefaultVariableId.BrokerAddressZip,
              EDefaultVariableId.BrokerAddressCity,
              EDefaultVariableId.BrokerAddressCountry,
              EDefaultVariableId.BrokerLogo,
              EDefaultVariableId.BrokerIsSignerOnBehalfOfOwners,
              EDefaultVariableId.BrokerSignature,
              EDefaultVariableId.BrokerCivilLiabilityAtCompany,
              EDefaultVariableId.BrokerCivilLiabilityPolicyNumber,
            ];
            if (whitelist.includes(key as EDefaultVariableId)) {
              variablesDataToOverride[key] = variablesData[key];

              return;
            }
          });

          dispatch(
            templateDocumentActions.create.actions.start({
              data: {
                templateId: templateDocument.templateId,
                propertyId: property.id,
                contractId: contract?.id,
                name: filename && filename?.length > 0 ? filename : undefined,
                type: templateDocument.type,
                documentConfig: templateDocumentDetail.documentConfig,
                documentIds: templateDocumentDetail.documentIds,
                variablesData: getDefaultVariablesData({
                  legalContact,
                  meMaster,
                  broker,
                  brokerLogoDocumentId,
                  property,
                  contract,
                  contacts,
                  fields: parseFields(templateDocumentDetail.fields),
                  preferences,
                  ibanisationBankAccount,
                  variablesDataToOverride,
                }),
              },
              onSuccess: (templateDocument: ITemplateDocument) => {
                navigate(`/template-document/${templateDocument.id}`);
              },
            }),
          );
        },
      }),
    );
  };

  const createTemplateDocument = () => {
    const templateId = selectedTemplateId;
    if (!templateId) {
      return;
    }

    const templateContract = selectedContract || contract;

    const template = availableTemplates?.find(x => x.id === templateId);
    if (!template?.fields) {
      return;
    }

    dispatch(
      templateDocumentActions.create.actions.start({
        data: {
          templateId,
          propertyId: property.id,
          contractId: templateContract?.id,
          name: filename && filename?.length > 0 ? filename : undefined,
          type: template.type,
          documentConfig: getDefaultDocumentConfig({
            brandColor,
          }),
          variablesData: getDefaultVariablesData({
            legalContact,
            meMaster,
            broker,
            brokerLogoDocumentId,
            property,
            contract: templateContract,
            contacts,
            fields: parseFields(template.fields),
            preferences,
            ibanisationBankAccount,
          }),
        },
        onSuccess: (templateDocument: ITemplateDocument) => {
          navigate(`/template-document/${templateDocument.id}`);
        },
      }),
    );
  };

  const handleCreateTemplateDocument = () => {
    if (selectedDuplicatePropertyId && selectedDuplicateTemplateDocumentId) {
      duplicateFromTemplateDocument();
      return;
    }

    createTemplateDocument();
  };

  // Helpers
  const getContactFieldsForModal = (): EContactField[] | undefined => {
    if (!contactToComplete) {
      return undefined;
    }

    let fields = undefined;
    if (!!accountToAdd) {
      fields = [
        EContactField.Firstname,
        EContactField.Lastname,
        EContactField.Email,
        EContactField.Phone,
        ...(accountToAdd.company
          ? [EContactField.Company, EContactField.VATNumber]
          : []),
      ];
    }

    const contactsSchema = getAllRequiredContactFields({
      contact: contactToComplete,
      requiredFields: [
        EContactField.Address,
        EContactField.DateOfBirth,
        EContactField.Nationality,
        EContactField.PlaceOfBirth,
      ],
      appendIdentityNumberToRequiredFields: true,
    });
    const companiesSchema = getAllRequiredCompanyFields({
      contact: contactToComplete,
      requiredFields: [
        EContactField.Address,
        EContactField.DateOfBirth,
        EContactField.Nationality,
        EContactField.PlaceOfBirth,
        EContactField.VATNumber,
      ],
      appendIdentityNumberToRequiredFields: true,
    });

    if (contactsSchema) {
      const requiredFields =
        contactToComplete && !!contactToComplete.company
          ? companiesSchema || contactsSchema
          : contactsSchema;

      fields = [...(fields || []), ...requiredFields.required];
    }

    return fields;
  };

  // Render
  if (!availableTemplates || availableTemplates.length === 0) {
    return null;
  }

  const heading = getLocalizedText(
    `create_template_document${
      type === "general" ? "_general" : "_contract"
    }.modal.heading`,
  );

  if (step === EStep.DuplicateProperty) {
    return (
      <MultiStepForm
        asModal={true}
        formId={`template-duplicate-from-property-${formId}`}
        schemas={duplicateTemplatePropertySelectSchemas({
          propertyId: selectedDuplicatePropertyId,
        })}
        withSummary={false}
        showErrorList={false}
        withAside={false}
        modalActions={[
          {
            content: getLocalizedText("system.back"),
            onClick: () => {
              setStep(EStep.Intro);
            },
            appearance: "outline",
          },
        ]}
        modalProps={{
          onClose,
          heading,
          width: "medium",
        }}
        submitLabel={getLocalizedText("system.next")}
        onSuccess={(params: { property: number }) => {
          setSelectedDuplicatePropertyId(params.property);
          setStep(EStep.DuplicateTemplateDocument);
        }}
      />
    );
  }

  if (step === EStep.DuplicateTemplateDocument && selectedDuplicatePropertyId) {
    return (
      <Modal
        width="medium"
        onClose={onClose}
        heading={heading}
        actions={[
          {
            content: getLocalizedText("system.back"),
            onClick: () => {
              setStep(EStep.DuplicateProperty);
            },
            appearance: "outline",
          },
          {
            content: getLocalizedText("system.next"),
            onClick: () => {
              if (
                !filename ||
                !selectedDuplicatePropertyId ||
                !selectedDuplicateTemplateDocumentId
              ) {
                setIsPristine(false);
                return;
              }

              if (type === "contract" && contract) {
                setStep(EStep.Contacts);
                return;
              }

              handleCreateTemplateDocument();
            },
            appearance: "primary",
          },
        ]}
      >
        {templateDocumentsPaged?.isFetching &&
          (templateDocumentsPaged?.data || []).length > 0 && <Loading />}

        {!templateDocumentsPaged?.isFetching &&
          templateDocumentsPaged?.data?.length === 0 && (
            <Labelled
              label={getLocalizedText(
                "create_template_document_contract.modal.duplicate_template_document_select",
              )}
              error={getLocalizedText(
                "create_template_document_contract.modal.duplicate_template_document_select.empty",
              )}
            />
          )}

        {(templateDocumentsPaged?.data || []).length > 0 && (
          <OptionList
            id={"template-duplicate-template-document"}
            title={getLocalizedText(
              "create_template_document_contract.modal.duplicate_template_document_select",
            )}
            value={
              selectedDuplicateTemplateDocumentId
                ? [selectedDuplicateTemplateDocumentId]
                : undefined
            }
            variant="simple"
            multiple={false}
            options={(templateDocumentsPaged.data || []).map(
              (option: ITemplateDocument) => ({
                label: option.name,
                value: option.id,
                id: option.id,
              }),
            )}
            onChange={(event: string[]) => {
              setSelectedDuplicateTemplateDocumentId(event[0]);
              const templateDocument = templateDocumentsPaged.data?.find(
                (x: ITemplateDocument) => x.id === event[0],
              );
              if (templateDocument) {
                setFilename(
                  getDefaultFilename({ type, templateDocument, property }),
                );
              }
            }}
            error={
              !selectedDuplicateTemplateDocumentId && !isPristine
                ? getLocalizedText("system.form.error.required.field", {
                    field: getLocalizedText(
                      "create_template_document_contract.modal.duplicate_template_document_select",
                    ),
                  })
                : undefined
            }
          />
        )}

        {selectedDuplicateTemplateDocumentId && (
          <>
            <Spacer weight={ESpacerWeight.W16} />

            <TextField
              size="small"
              label={getLocalizedText(
                "create_template_document.modal.name_template",
              )}
              name={getLocalizedText(
                "create_template_document.modal.name_template",
              )}
              value={filename}
              placeholder={getLocalizedText(
                "create_template_document.modal.name_template",
              )}
              error={
                (!filename || filename.length === 0) && !isPristine
                  ? getLocalizedText("system.form.error.required.field", {
                      field: getLocalizedText(
                        "create_template_document.modal.name_template",
                      ),
                    })
                  : undefined
              }
              onChange={(
                event: React.ChangeEvent<
                  HTMLInputElement | HTMLTextAreaElement
                >,
              ) => {
                const {
                  target: { value },
                } = event;

                setFilename(value.length > 0 ? value : undefined);
              }}
            />
          </>
        )}
      </Modal>
    );
  }

  if (step === EStep.Contacts && contract) {
    const accounts: IAccount[] = getMembersWithOneOfRoles(contract.members, [
      EContractMemberTypes.Owner,
      EContractMemberTypes.Tenant,
      EContractMemberTypes.Parent,
    ]).map((member: IMember<string>) => member.account);

    const validAccounts = accounts.filter(account => {
      const contact = contacts.find(contact =>
        contact.accountIds.includes(account.id),
      );
      if (!contact) {
        return false;
      }
      return contactIsValidForTemplate(contact);
    });
    let isValid = validAccounts.length >= accounts.length;

    const contactFieldsForModal = getContactFieldsForModal();

    return (
      <>
        <Modal
          width="medium"
          onClose={onClose}
          heading={heading}
          actions={[
            {
              content: getLocalizedText("system.back"),
              onClick: () => {
                if (selectedDuplicateTemplateDocumentId) {
                  setStep(EStep.DuplicateTemplateDocument);
                  return;
                }

                setStep(EStep.Intro);
              },
              appearance: "outline",
            },
            {
              content: getLocalizedText("system.next"),
              onClick: () => {
                if (isValid) {
                  handleCreateTemplateDocument();
                  return;
                }

                confirm({
                  type: "warning",
                  modalProps: {
                    hasDismiss: true,
                    shouldCloseOnOverlayClick: true,
                  },
                  title: getLocalizedText(
                    "create_template_document_contract.modal.confirm.invalid_linked_contacts.title",
                  ),
                  info: getLocalizedText(
                    "create_template_document_contract.modal.confirm.invalid_linked_contacts.content",
                  ),
                  secondaryAction: {
                    title: getLocalizedText("system.continue"),
                    onPress: () => {
                      handleCreateTemplateDocument();
                    },
                  },
                  primaryActions: [
                    {
                      title: getLocalizedText(
                        "create_template_document_contract.modal.confirm.invalid_linked_contacts.cta.complete_contact_content",
                      ),
                    },
                  ],
                });
              },
              appearance: "primary",
            },
          ]}
        >
          {!isValid && (
            <Box mb={4}>
              <Banner
                title={getLocalizedText(
                  "create_template_document_contract.modal.banner.invalid_linked_contacts.title",
                )}
                variation="warning"
                hasDismiss={false}
                icon="alertDiamond"
              >
                {getLocalizedText(
                  "create_template_document_contract.modal.banner.invalid_linked_contacts.content",
                )}
              </Banner>
            </Box>
          )}

          <Labelled
            label={getLocalizedText(
              "create_template_document_contract.modal.linked_contacts.label",
            )}
          >
            <AccountList
              accounts={accounts}
              getSubtitle={(accountIds: number[]) => {
                const member = contract.members.find(member =>
                  accountIds.includes(member.account.id),
                );
                if (!member) {
                  return;
                }

                return join(
                  member.roles.map(role =>
                    getLocalizedText(`role.${role}`.toLowerCase()),
                  ),
                );
              }}
              getExtraSubtitle={(accountIds: number[], contact?: IContact) => {
                if (contact) {
                  if (contactIsValidForTemplate(contact)) {
                    return;
                  }

                  return (
                    <>
                      <Spacer weight={ESpacerWeight.W02} />
                      <Button
                        appearance="link"
                        onClick={() => {
                          setContactToComplete(contact);
                        }}
                      >
                        {getLocalizedText("form.complete_missing_fields")}
                      </Button>
                    </>
                  );
                }

                const member = contract.members.find(member =>
                  accountIds.includes(member.account.id),
                );
                if (!member) {
                  return;
                }

                return (
                  <>
                    <Spacer weight={ESpacerWeight.W02} />
                    <Button
                      appearance="link"
                      onClick={() => {
                        setAccountToAdd(member.account);
                      }}
                    >
                      {getLocalizedText("add.to.contacts")}
                    </Button>
                  </>
                );
              }}
            />
          </Labelled>
        </Modal>

        {contactToComplete && contactFieldsForModal && (
          <ContactEditModal
            fields={contactFieldsForModal}
            onClose={() => {
              setContactToComplete(undefined);
            }}
            onSubmit={() => {
              setContactToComplete(undefined);
            }}
            contactId={contactToComplete.id}
          />
        )}

        {accountToAdd && (
          <AddContactModal
            type={!!accountToAdd.company ? "company" : "contact"}
            fields={[
              EContactField.Firstname,
              EContactField.Lastname,
              EContactField.Email,
              EContactField.Phone,
              ...(accountToAdd.company
                ? [EContactField.Company, EContactField.VATNumber]
                : []),
              EContactField.PlaceOfBirth,
              EContactField.DateOfBirth,
              EContactField.Nationality,
              EContactField.Address,
              EContactField.NationalRegisterNumber,
              EContactField.ForeignIdentityNumber,
            ]}
            contact={{
              ...(accountToAdd.contactInfo || {}),
              firstname: accountToAdd.firstname,
              lastname: accountToAdd.lastname,
              company: accountToAdd.company,
            }}
            baseAccountId={accountToAdd.id}
            onClose={() => {
              setAccountToAdd(undefined);
            }}
            onSubmit={() => {
              setAccountToAdd(undefined);
            }}
          />
        )}
      </>
    );
  }

  const templateDropdownLabel = getLocalizedText(
    `create_template_document${
      type === "general" ? "_general" : "_contract"
    }.modal.select_template`,
  );

  return (
    <>
      <Modal
        width="medium"
        onClose={onClose}
        heading={heading}
        actions={compact([
          type === "contract" && {
            content: getLocalizedText(
              "create_template_document.modal.duplicate_other_template",
            ),
            onClick: () => {
              setFilename(undefined);
              setSelectedTemplateId(undefined);
              setSelectedDuplicatePropertyId(undefined);
              setSelectedDuplicateTemplateDocumentId(undefined);
              setIsPristine(true);

              setStep(EStep.DuplicateProperty);
            },
            appearance: "link",
          },
          {
            content: ts.system("cancel"),
            onClick: onClose,
            appearance: "outline",
          },
          {
            content: ts.system("next"),
            onClick: () => {
              if (!selectedTemplateId || !filename) {
                setIsPristine(false);
                return;
              }

              if (type === "contract" && contract) {
                setSelectedDuplicatePropertyId(undefined);
                setSelectedDuplicateTemplateDocumentId(undefined);
                setIsPristine(true);

                setStep(EStep.Contacts);
                return;
              }

              handleCreateTemplateDocument();
            },
            appearance: "primary",
          },
        ])}
      >
        {i18n.tm(
          `create_template_document${
            type === "general" ? "_general" : "_contract"
          }.modal.content`,
          {
            markdownProps: { listAsChecklist: true },
          },
        )}

        <>
          <Spacer weight={ESpacerWeight.W16} />

          <Select
            id={"template"}
            label={templateDropdownLabel}
            disabled={availableTemplates.length === 1}
            error={
              !selectedTemplateId && !isPristine
                ? getLocalizedText("system.form.error.required.field", {
                    field: templateDropdownLabel,
                  })
                : undefined
            }
            options={[
              ...(availableTemplates.length > 1
                ? [{ label: "-", value: undefined }]
                : []),
              ...availableTemplates.map(template => {
                const { type, country, region } = template;

                const metaComponents = [
                  getLocalizedText(template.name),
                  getLocalizedText(
                    `template.type.${stringToSnakeCase(type)}`.toLowerCase(),
                  ),
                ];

                if (country) {
                  metaComponents.push(
                    getLocalizedText(`system.country.${country.toLowerCase()}`),
                  );
                }

                if (region) {
                  metaComponents.push(
                    getLocalizedText(`template.region.${region.toLowerCase()}`),
                  );
                }

                return {
                  label: `${metaComponents.join(" - ")}`,
                  value: template.id,
                };
              }),
            ]}
            value={selectedTemplateId}
            onChange={handleChangeTemplate}
          />

          {selectedTemplateId && (
            <>
              <Spacer weight={ESpacerWeight.W16} />

              <TextField
                size="small"
                label={getLocalizedText(
                  "create_template_document.modal.name_template",
                )}
                name={getLocalizedText(
                  "create_template_document.modal.name_template",
                )}
                value={filename}
                placeholder={getLocalizedText(
                  "create_template_document.modal.name_template",
                )}
                error={
                  (!filename || filename.length === 0) && !isPristine
                    ? getLocalizedText("system.form.error.required.field", {
                        field: getLocalizedText(
                          "create_template_document.modal.name_template",
                        ),
                      })
                    : undefined
                }
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => {
                  const {
                    target: { value },
                  } = event;

                  setFilename(value.length > 0 ? value : undefined);
                }}
              />

              {!contract && activeContracts.length > 0 && (
                <>
                  <Spacer weight={ESpacerWeight.W16} />

                  <OptionList
                    id="templateDocumentContracts"
                    title={getLocalizedText("system.contracts.active")}
                    value={
                      selectedContractId ? [selectedContractId] : undefined
                    }
                    optional={true}
                    variant="default"
                    multiple={false}
                    options={activeContracts.map((contract: IContract) => ({
                      id: contract.id,
                      value: contract.id,
                      label: getTerm(contract),
                      description: getContractAmountAndTenants(contract),
                    }))}
                    onChange={(event: string[]) =>
                      setSelectedContractId(event[0])
                    }
                  />
                </>
              )}
            </>
          )}
        </>

        {type === "contract" &&
          contract &&
          [
            EContractIbanisationStatus.Enabling,
            EContractIbanisationStatus.EnablingFailed,
          ].includes(contract.ibanisationStatus) && (
            <Banner
              title={getLocalizedText(
                `create_template_document_contract.modal.banner.ibanisation.${stringToSnakeCase(
                  contract.ibanisationStatus,
                )}.title`.toLowerCase(),
              )}
              variation="warning"
              hasDismiss={false}
              icon="alertDiamond"
            >
              {getLocalizedText(
                `create_template_document_contract.modal.banner.ibanisation.${stringToSnakeCase(
                  contract.ibanisationStatus,
                )}.content`.toLowerCase(),
              )}
            </Banner>
          )}
      </Modal>
    </>
  );
};

export default TemplateDocumentContractCreateModal;
