import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IContract } from "@rentiohq/shared/dist/types/contract.types";
import {
  differenceInDays,
  startOfDay,
} from "@rentiohq/shared/dist/utils/date-fns.utils";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { TTextStyleVariation } from "@rentiohq/web-shared/dist/components/TextStyle/TextStyle.types";
import { memo } from "react";

interface IProps {
  contract: IContract;
}

const NOW = new Date();
const START_OF_DAY = startOfDay(NOW);

const FinalNoticeDate = ({ contract }: IProps) => {
  if (contract.tacitRenewal === false) {
    return <>-</>;
  }

  if (contract.dateOfCancellation) {
    return <>-</>;
  }

  const renewalDeadline = contractUtils.getRenewalDeadline(contract);

  let renewalDeadlineVariation: TTextStyleVariation = !!renewalDeadline
    ? "default"
    : "subdued";
  if (renewalDeadline) {
    const daysTilDeadline = differenceInDays(renewalDeadline, START_OF_DAY);
    if (daysTilDeadline < 0) {
      renewalDeadlineVariation = "negative";
    } else if (daysTilDeadline < 30) {
      renewalDeadlineVariation = "warn";
    }
  }

  return (
    <TextStyle variation={renewalDeadlineVariation}>
      {renewalDeadline
        ? formatDate(renewalDeadline)
        : getLocalizedText("contract.renewal_deadline.unknown")}
    </TextStyle>
  );
};

export const MemoizedFinalNoticeDate = memo(FinalNoticeDate);
