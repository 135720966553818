var _a;
import { getAllSelectors } from "../generic/all.selectors";
import { REDUCER_KEY_TEMPLATE } from "./template.reducer";
export var create = (_a = getAllSelectors(function (state) { return state[REDUCER_KEY_TEMPLATE]; }), _a.create), update = _a.update, deleteObject = _a.deleteObject, detail = _a.detail, paged = _a.paged;
export var templateDetail = function (state, templatedId) {
    return (templatedId &&
        state[REDUCER_KEY_TEMPLATE].dataTemplateDetail[templatedId]) ||
        undefined;
};
export var isFetchingTemplateDetail = function (state, templatedId) {
    var _a;
    return (templatedId &&
        ((_a = state[REDUCER_KEY_TEMPLATE].templateDetail[templatedId]) === null || _a === void 0 ? void 0 : _a.isFetching)) ||
        false;
};
export var templateDetailFetchError = function (state, templatedId) {
    var _a;
    return (templatedId &&
        ((_a = state[REDUCER_KEY_TEMPLATE].templateDetail[templatedId]) === null || _a === void 0 ? void 0 : _a.fetchError)) ||
        undefined;
};
export var isUpdatingTemplateDetail = function (state, templatedId) {
    var _a;
    return (templatedId &&
        ((_a = state[REDUCER_KEY_TEMPLATE].templateDetail[templatedId]) === null || _a === void 0 ? void 0 : _a.isUpdating)) ||
        false;
};
export var templateDetailUpdateError = function (state, templatedId) {
    var _a;
    return (templatedId &&
        ((_a = state[REDUCER_KEY_TEMPLATE].templateDetail[templatedId]) === null || _a === void 0 ? void 0 : _a.updateError)) ||
        undefined;
};
