import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ENaturalGasMeterType } from "@rentiohq/shared/dist/types/property.types";
import { JSONSchema6 } from "json-schema";
import { FormValidation, UiSchema } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.moveRequestUpdate.types";

const NOW = new Date();

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.NaturalGasMeterType],
      properties: {
        [EField.NaturalGasMeterReading]: {
          default: getValue(
            EField.NaturalGasMeterReading,
            initialValues,
            defaultValues,
          ),
        },
        [EField.NaturalGasMeterReadingDate]: {
          default: getValue(
            EField.NaturalGasMeterReadingDate,
            initialValues,
            defaultValues,
          ),
        },
        [EField.HandoverDocuments]: {
          default: getValue(
            EField.HandoverDocuments,
            initialValues,
            defaultValues,
          ),
        },
        [EField.NaturalGasMeterType]: {
          default: getValue(
            EField.NaturalGasMeterType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            ENaturalGasMeterType.Standard,
            ENaturalGasMeterType.Opening,
            ENaturalGasMeterType.Budget,
            ENaturalGasMeterType.Empty,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.natural_gas_meter_type.${id}`.toLowerCase(),
            ),
          })),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): UiSchema => ({
  "ui:field": ERentioFormField.Grid,
  grid: {
    row: [
      {
        col: {
          width: [1, 1 / 2],
          children: [EField.NaturalGasMeterReading],
        },
      },
      {
        col: {
          width: [1, 1 / 2],
          children: [EField.NaturalGasMeterReadingDate],
        },
      },
      {
        col: {
          width: 1,
          children: [EField.HandoverDocuments],
        },
      },
      {
        col: {
          width: 1,
          children: [EField.NaturalGasMeterType],
        },
      },
    ],
  },
  [EField.NaturalGasMeterReading]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("move.field.natural_gas_meter_reading.title"),
    optional: true,
  },
  [EField.NaturalGasMeterReadingDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("move.field.natural_gas_meter_reading_date.title"),
    maxDate: NOW,
    // optional: true,
  },
  [EField.HandoverDocuments]: {
    "ui:field": ERentioFormField.DocumentSelectInline,
    title: getLocalizedText("move.field.natural_gas_handover_documents.title"),
    optional: true,
    multiple: true,
    accept: ["application/*", "image/*"],
  },
  [EField.NaturalGasMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.natural_gas_meter_type.title"),
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    [
      {
        meterReadingKey: EField.NaturalGasMeterReading,
        meterReadingDateKey: EField.NaturalGasMeterReadingDate,
      },
    ].forEach(value => {
      const { meterReadingKey, meterReadingDateKey } = value;

      const meterReading = formData[meterReadingKey] || "";
      const meterReadingDate = formData[meterReadingDateKey] || "";

      if (meterReading === "" && meterReadingDate !== "") {
        errors[meterReadingKey].addError(
          getLocalizedText("move.field.error.meter_reading.required"),
        );
      }

      if (meterReading !== "" && meterReadingDate === "") {
        errors[meterReadingDateKey].addError(
          getLocalizedText("move.field.error.meter_reading_date.required"),
        );
      }
    });

    return errors;
  };
