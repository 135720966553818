import { createSelector } from "reselect";
import { REDUCER_KEY_CARD } from "./card.reducer";
export var cardsSelector = function (state) { return state.card.cards; };
var closedCardIdsSelector = function (state) {
    return state.card.closedCardIds;
};
export var unclosedCardsSelector = createSelector(cardsSelector, closedCardIdsSelector, function (cards, closedCardIds) {
    if (!cards) {
        return undefined;
    }
    return cards.filter(function (card) { return !closedCardIds.includes(card.id); });
});
var getExtraCards = function (state, type) {
    if (!state.card.extraCards) {
        return undefined;
    }
    return state.card.extraCards.filter(function (card) { return card.insertAt === type; });
};
export var getExtraLeadingCards = function (state) {
    return getExtraCards(state, "leading");
};
export var getExtraTrailingCards = function (state) {
    return getExtraCards(state, "trailing");
};
export var isGeneratingExtraCards = function (state) {
    return state.card.isGeneratingExtraCards;
};
export var generateExtraCardsError = function (state) {
    return state.card.generateExtraCardsError;
};
var getCardActionState = function (state, actionId) {
    return state.card.cardActions[actionId];
};
export var makeGetIsPerformingCardAction = function () {
    return createSelector([getCardActionState], function (cardActionState) {
        if (!cardActionState) {
            return false;
        }
        return cardActionState.isPerformingAction;
    });
};
export var makeGetCardActionDynamicLink = function () {
    return createSelector([getCardActionState], function (cardActionState) {
        if (!cardActionState) {
            return undefined;
        }
        return cardActionState.dynamicLink;
    });
};
