import { Box } from "@rebass/grid";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { append } from "@rentiohq/shared/dist/utils/api.utils";
import {
  DisplayText,
  ESpacings,
  Grid,
  Loading,
  Lozenge,
} from "@rentiohq/web-shared/dist/components";
import Button from "@rentiohq/web-shared/dist/components/Button";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { getPaymentRequestsFilter } from "scenes/FollowUp/Payments/Payments.utils";
import { IPaymentRequestsGridColumnProps } from "../OpenPaymentRequests.types";
import { PaymentRequestsGridItem } from "./PaymentRequestsGridItem";

const FETCH_LIMIT = 5;

export const PaymentRequestsGridColumn: React.FC<
  IPaymentRequestsGridColumnProps
> = props => {
  const {
    propertyId,
    search,
    phase,
    status,
    from,
    heading,
    emptyState,
    getPaymentRequestActions,
    getPrimaryPaymentRequestActions,
  } = props;

  const filter = getPaymentRequestsFilter({
    propertyId,
    search,
    phase,
    status,
    from,
  });
  const identifier = hash(filter);

  // Redux
  const dispatch = useDispatch();
  const paymentRequests = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentRequestsByIdentifier(state, identifier),
  );
  const isFetching = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentRequestsByIdentifier(state, identifier),
  );
  const isExhausted = useSelector((state: IRootStore) =>
    paymentSelectors.isExhaustedPaymentRequestsByIdentifier(state, identifier),
  );
  const fetchError = useSelector((state: IRootStore) =>
    paymentSelectors.paymentRequestsByIdentifierFetchError(state, identifier),
  );

  // Hooks
  const { count } = useCount({
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({ phase, propertyId }).where,
      ),
    }),
  });

  // Helpers
  const fetchPaymentRequests = (refetch: boolean) => {
    if (isFetching) {
      return;
    }

    if (!refetch && isExhausted) {
      return;
    }

    const filterData = getPaymentRequestsFilter({
      propertyId,
      search,
      status,
      phase,
      from,
    });

    dispatch(
      paymentActions.getPaymentRequestsByIdentifier.actions.start({
        paymentRequestsIdentifier: identifier,
        refetch,
        filterData,
        limit: FETCH_LIMIT,
      }),
    );
  };

  // Events
  const handleLoadMoreClick = () => {
    fetchPaymentRequests(false);
  };

  React.useEffect(() => {
    fetchPaymentRequests(true);
  }, [propertyId, search, status, phase, from]);

  const renderContent = () => {
    if (paymentRequests && paymentRequests.length > 0) {
      return (
        <>
          {paymentRequests.map(paymentRequest => (
            <PaymentRequestsGridItem
              key={paymentRequest.id}
              item={paymentRequest}
              actions={getPaymentRequestActions(paymentRequest)}
              primaryActions={getPrimaryPaymentRequestActions(
                paymentRequest,
                phase,
              )}
              phase={phase}
            />
          ))}

          {!isExhausted && (count || 0) > paymentRequests.length && (
            <Box mt={ESpacings.base}>
              <Button
                onClick={handleLoadMoreClick}
                isSubmitting={isFetching}
                size="small"
                appearance="outline"
                isFullWidth
              >
                {getLocalizedText("system.load_more")}
              </Button>
            </Box>
          )}
        </>
      );
    }
    if (fetchError) {
      return <p>{getLocalizedText("fetch.error")}</p>;
    }

    if (isFetching) {
      return <Loading />;
    }

    return emptyState;
  };

  return (
    <div>
      <Grid alignItems="center" spacing="tight" mb={ESpacings.base}>
        <DisplayText subdued={true} space="none" size="extraSmall">
          {heading}
        </DisplayText>
        <Lozenge>{count}</Lozenge>
      </Grid>
      {renderContent()}
    </div>
  );
};
