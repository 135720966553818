import { EPropertyEPCLabel } from "@rentiohq/shared/dist/types/property.types";
import { addYears, endOfYear, startOfYear, subtractYears, } from "@rentiohq/shared/dist/utils/date-fns.utils";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getAvailableEpcLabelsForProperty } from "../../utils/property.utils";
import { EField } from "./schema.epcInfo.types";
var hasEpcLabel = function (formData) {
    var epcLabel = formData[EField.Label];
    if (!epcLabel) {
        return false;
    }
    return ![
        EPropertyEPCLabel.Unknown,
        EPropertyEPCLabel.NoEPCAvailable,
    ].includes(epcLabel);
};
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        var _b, _c;
        return {
            required: [EField.Label],
            properties: (_a = {},
                _a[EField.Label] = {
                    default: getValue(EField.Label, initialValues, defaultValues),
                    //@ts-ignore
                    options: getAvailableEpcLabelsForProperty({
                        countryId: (_b = extraData.property) === null || _b === void 0 ? void 0 : _b.countryId,
                        zip: (_c = extraData.property) === null || _c === void 0 ? void 0 : _c.zip,
                    }),
                },
                _a[EField.Value] = {
                    default: getValue(EField.Value, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: hasEpcLabel,
                    // requiredIf: hasEpcLabel,
                },
                _a[EField.ExpirationDate] = {
                    default: getValue(EField.ExpirationDate, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: hasEpcLabel,
                    requiredIf: hasEpcLabel,
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a;
    var NOW = new Date();
    return _a = {},
        _a[EField.Label] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.variable.property_epc_label"),
        },
        _a[EField.Value] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("properties.epc.value"),
            suffixText: getLocalizedText("epc.energy_usage.per_year"),
            optional: true,
            isFloat: true,
            min: 0,
        },
        _a[EField.ExpirationDate] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("editor.variable.property_epc_end_date"),
            formatSummary: function (input) { return (input ? formatDate(input) : ""); },
            minDate: startOfYear(subtractYears(NOW, 20)),
            maxDate: endOfYear(addYears(NOW, 10)),
        },
        _a;
};
