var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CompositeDecorator, convertFromRaw, EditorState } from "draft-js";
import React from "react";
import Loading from "../../../../components/Loading";
import EditorPreview from "../EditorPreview";
import EditorData from "./components/EditorData";
import EditorSidebar from "./components/EditorSidebar";
import { DisplayTypeContext } from "./contexts/DisplayType.context";
import { DocumentConfigContext } from "./contexts/DocumentConfig.context";
import EditorStateContextProvider from "./contexts/EditorState.context";
import { FieldsContext } from "./contexts/Fields.context";
import { VariablesDataContext } from "./contexts/VariablesData.context";
import decoratorConditionRenderer from "./decorators/ConditionRenderer";
import decoratorIndexRenderer from "./decorators/IndexCountRenderer";
import decoratorLoopRenderer from "./decorators/LoopRenderer";
import decoratorVariableRenderer from "./decorators/VariableRenderer";
import * as S from "./Editor.styles";
var Editor = function (props) {
    var title = props.title, subtitle = props.subtitle, onPressUpdate = props.onPressUpdate, onPressSave = props.onPressSave, isFetching = props.isFetching, rawState = props.rawState;
    // Context
    var _a = React.useContext(DisplayTypeContext), setDisplayType = _a.setDisplayType, isTemplateDocument = _a.isTemplateDocument, isPrint = _a.isPrint;
    var setDocumentConfig = React.useContext(DocumentConfigContext).setDocumentConfig;
    var resetFields = React.useContext(FieldsContext).resetFields;
    var setVariablesData = React.useContext(VariablesDataContext).setVariablesData;
    // State
    var _b = React.useState(), editorState = _b[0], setEditorState = _b[1];
    // Lifecycle
    React.useEffect(function () {
        if (props.fields) {
            resetFields(props.fields);
        }
    }, [props.fields]);
    React.useEffect(function () {
        if (props.displayType) {
            setDisplayType(props.displayType);
        }
    }, [props.displayType]);
    React.useEffect(function () {
        if (props.documentConfig) {
            setDocumentConfig(props.documentConfig);
        }
    }, [props.documentConfig]);
    React.useEffect(function () {
        setVariablesData(props.variablesData || {});
    }, [props.variablesData]);
    React.useEffect(function () {
        if (!editorState && rawState) {
            // setEditorState(EditorState.createEmpty(decorator));
            var decorator = new CompositeDecorator([
                decoratorConditionRenderer,
                decoratorIndexRenderer,
                decoratorLoopRenderer,
                decoratorVariableRenderer,
            ]);
            var newEditorState = EditorState.createWithContent(convertFromRaw(rawState), decorator);
            setEditorState(newEditorState);
        }
    }, [rawState]);
    // Render
    var renderEditor = function () {
        if (isFetching || !editorState) {
            return _jsx(Loading, {});
        }
        if (isTemplateDocument || isPrint) {
            return (_jsx(S.ContentFontWrap, { children: _jsx(EditorPreview, { editorState: editorState, onPressUpdate: onPressUpdate, onPressSave: onPressSave }) }));
        }
        return (_jsx(EditorData, { editorState: editorState, setEditorState: setEditorState, onPressUpdate: onPressUpdate, onPressSave: onPressSave }));
    };
    return (_jsx(EditorStateContextProvider, __assign({ editorState: editorState, setEditorState: setEditorState }, { children: _jsxs(S.Wrap, { children: [!isPrint && (_jsx(S.SidebarWrap, { children: isFetching || !editorState ? (_jsx(Loading, {})) : (_jsx(_Fragment, { children: _jsx(EditorSidebar, { title: title, subtitle: subtitle, onPressUpdate: onPressUpdate, onPressSave: onPressSave }) })) })), _jsx(S.EditorWrap, { children: renderEditor() })] }) })));
};
export default Editor;
