// Revenues
export var ERevenuesPaymentStatus;
(function (ERevenuesPaymentStatus) {
    ERevenuesPaymentStatus["Unpaid"] = "UNPAID";
    ERevenuesPaymentStatus["Pending"] = "PENDING_AT_RENTIO";
    ERevenuesPaymentStatus["Paid"] = "PAID";
})(ERevenuesPaymentStatus || (ERevenuesPaymentStatus = {}));
export var ERevenuesInvoicedStatus;
(function (ERevenuesInvoicedStatus) {
    ERevenuesInvoicedStatus["Invoiced"] = "INVOICED";
    ERevenuesInvoicedStatus["NotInvoiced"] = "NOT_INVOICED";
})(ERevenuesInvoicedStatus || (ERevenuesInvoicedStatus = {}));
// Entity
export var EExportFileType;
(function (EExportFileType) {
    EExportFileType["Csv"] = "CSV";
    EExportFileType["Pdf"] = "PDF";
})(EExportFileType || (EExportFileType = {}));
export var EExportType;
(function (EExportType) {
    EExportType["Revenues"] = "REVENUES";
    EExportType["Contact"] = "CONTACT";
    EExportType["BeneficiaryReport"] = "BENEFICIARY_REPORT";
})(EExportType || (EExportType = {}));
export var EExportStatus;
(function (EExportStatus) {
    EExportStatus["New"] = "NEW";
    EExportStatus["Generated"] = "GENERATED";
    EExportStatus["Failed"] = "FAILED";
})(EExportStatus || (EExportStatus = {}));
