import { EContractMemberTypes, } from "@rentiohq/shared/dist/types/contract.types";
import { formatCurrency } from "@rentiohq/shared/dist/utils/number.utils";
import { getMembersWithRole } from "@rentiohq/shared/dist/utils/roles.utils";
import { round } from "lodash";
import { getName } from "../redux/contact/contact.utils";
import { getLocalizedText } from "./i18n/i18n.utils";
import { join } from "./string.utils";
export var getContractAmountAndTenants = function (contract) {
    var total = (contract.currentCostsCommon || 0) +
        (contract.currentCostsNonCommon || 0) +
        (contract.currentPrice || 0);
    var amount = getLocalizedText("system.amount.".concat(contract.repetitionType).toLowerCase(), {
        amount: formatCurrency(round(total, 2)),
    });
    var tenants = join(getMembersWithRole(contract.members, EContractMemberTypes.Tenant).map(function (member) { return getName(member.account); }), " - ");
    return "".concat(amount, " - ").concat(tenants);
};
