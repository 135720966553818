import * as propertyActionsV2 from "../../reduxV2/property/property.actions";
import * as propertySelectorsV2 from "../../reduxV2/property/property.selectors";
export var customIsFetchingSelector = function (state, extraData) {
    var property = propertySelectorsV2.getDetail(state, extraData.property.id);
    if (property) {
        return false;
    }
    return true;
};
export var customGetContactsActions = function (extraData) {
    var result = [];
    var propertyId = extraData.property.id;
    result.push(function () {
        return propertyActionsV2.getDetailStart.getAction({ id: propertyId });
    });
    return result;
};
