var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import logger from "@rentiohq/shared/dist/logger";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateFailureText, getGeneralCreateSuccessText, getGeneralRemoveSuccessText, getGeneralUpdateFailureText, getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import * as countActions from "../count/count.actions";
import * as countUtils from "../count/count.utils";
import { takeLatestWithUniquePayload } from "../utils/saga.utils";
import * as paymentActions from "./payment.actions";
import * as paymentApi from "./payment.api";
import * as paymentSelectors from "./payment.selectors";
import { getPaymentRequestTypes } from "./payment.utils";
// TODO: Remove in favour of getPaymentOrdersByIdentifier
function getPaymentOrdersStartFlow(_a) {
    var refetch, paymentOrders, _b, _c, data, isExhausted, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                refetch = payload.refetch;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 5, , 7]);
                _c = (_b = paymentSelectors).getPaymentOrders;
                return [4 /*yield*/, select()];
            case 2:
                paymentOrders = _c.apply(_b, [_d.sent()]);
                return [4 /*yield*/, call(paymentApi.getPaymentOrders, {
                        skip: refetch ? 0 : (paymentOrders || []).length,
                    })];
            case 3:
                data = (_d.sent()).data;
                isExhausted = data.length < paymentApi.FETCH_LIMIT;
                return [4 /*yield*/, put(paymentActions.getPaymentOrders.actions.success({
                        paymentOrders: data,
                        refetch: refetch,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _d.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_1 = _d.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentOrders.actions.failure({
                        error: error,
                    }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getPaymentOrdersByIdentifierStartFlow(_a) {
    var paymentOrdersIdentifier, refetch, filterData, onSuccess, onFailure, paymentOrders, _b, _c, data, isExhausted, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                paymentOrdersIdentifier = payload.paymentOrdersIdentifier, refetch = payload.refetch, filterData = payload.filterData, onSuccess = payload.onSuccess, onFailure = payload.onFailure;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 5, , 7]);
                _c = (_b = paymentSelectors).getPaymentOrdersByIdentifier;
                return [4 /*yield*/, select()];
            case 2:
                paymentOrders = _c.apply(_b, [_d.sent(), paymentOrdersIdentifier]);
                return [4 /*yield*/, call(paymentApi.getPaymentOrders, {
                        skip: refetch ? 0 : (paymentOrders || []).length,
                        limit: paymentApi.FETCH_LIMIT,
                        filterData: filterData,
                    })];
            case 3:
                data = (_d.sent()).data;
                isExhausted = data.length < paymentApi.FETCH_LIMIT;
                return [4 /*yield*/, put(paymentActions.getPaymentOrdersByIdentifier.actions.success({
                        paymentOrdersIdentifier: paymentOrdersIdentifier,
                        paymentOrders: data,
                        filterData: filterData,
                        refetch: refetch,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _d.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data);
                return [3 /*break*/, 7];
            case 5:
                unknownError_2 = _d.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentOrdersByIdentifier.actions.failure({
                        paymentOrdersIdentifier: paymentOrdersIdentifier,
                        error: error,
                    }))];
            case 6:
                _d.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getPaymentOrdersForPropertyStartFlow(_a) {
    var propertyId, data, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                propertyId = payload.propertyId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.getPaymentOrdersForProperty, propertyId)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(paymentActions.getPaymentOrdersForProperty.actions.success({
                        propertyId: propertyId,
                        paymentOrders: data,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentOrdersForProperty.actions.failure({
                        propertyId: propertyId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getPaymentOrdersPagedStartFlow(_a) {
    var identifier, _b, page, _c, limit, filterData, skip, paymentOrders, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                identifier = payload.identifier, _b = payload.page, page = _b === void 0 ? 1 : _b, _c = payload.limit, limit = _c === void 0 ? paymentApi.FETCH_LIMIT : _c, filterData = payload.filterData;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 4, , 6]);
                skip = (page - 1) * limit;
                return [4 /*yield*/, call(paymentApi.getPaymentOrders, {
                        skip: skip,
                        filterData: filterData,
                        limit: limit,
                    })];
            case 2:
                paymentOrders = (_d.sent()).data;
                return [4 /*yield*/, put(paymentActions.getPaymentOrdersPaged.actions.success({
                        identifier: identifier,
                        page: page,
                        paymentOrders: paymentOrders,
                    }))];
            case 3:
                _d.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _d.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentOrdersPaged.actions.failure({
                        error: error,
                        identifier: identifier,
                        page: page,
                    }))];
            case 5:
                _d.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getIbanStartStartFlow(_a) {
    var paymentOrderId, payeeId, payerId, action, data, unknownError_5, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentOrderId = payload.paymentOrderId, payeeId = payload.payeeId, payerId = payload.payerId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                action = void 0;
                if (payeeId)
                    action = paymentApi.getPayeeIban;
                if (payerId)
                    action = paymentApi.getPayerIban;
                if (!action)
                    return [2 /*return*/];
                return [4 /*yield*/, call(action, paymentOrderId)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(paymentActions.getIban.actions.success({
                        paymentOrderId: paymentOrderId,
                        payeeId: payeeId,
                        payerId: payerId,
                        iban: data,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getIban.actions.failure({
                        paymentOrderId: paymentOrderId,
                        payeeId: payeeId,
                        payerId: payerId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function createPaymentOrderStartFlow(_a) {
    var data, firstPaymentOrder, unknownError_6, error;
    var paymentOrders = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(paymentApi.createPaymentOrder, paymentOrders)];
            case 1:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(paymentActions.createPaymentOrder.actions.success(data))];
            case 2:
                _b.sent();
                firstPaymentOrder = data[0];
                if (!firstPaymentOrder) return [3 /*break*/, 4];
                return [4 /*yield*/, put(countActions.getCount.actions.start(countUtils.getActionPayloadPaymentOrdersForProperty(firstPaymentOrder.propertyId)))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("payment_order"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_6 = _b.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.createPaymentOrder.actions.failure({ error: error }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    message: getGeneralCreateFailureText("payment_order"),
                    error: error,
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getPaymentOrderStartFlow(_a) {
    var paymentOrderId, paymentOrder, unknownError_7, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentOrderId = payload.paymentOrderId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.getPaymentOrder, paymentOrderId)];
            case 2:
                paymentOrder = (_b.sent()).data;
                return [4 /*yield*/, put(paymentActions.getPaymentOrder.actions.success({
                        paymentOrderId: paymentOrderId,
                        paymentOrder: paymentOrder,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_7 = _b.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentOrder.actions.failure({
                        error: error,
                        paymentOrderId: paymentOrderId,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updatePaymentOrderStartFlow(_a) {
    var paymentOrderId, paymentOrder, _b, suppressSuccessMessage, data, unknownError_8, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                paymentOrderId = payload.paymentOrderId, paymentOrder = payload.paymentOrder, _b = payload.suppressSuccessMessage, suppressSuccessMessage = _b === void 0 ? false : _b;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.updatePaymentOrder, paymentOrderId, paymentOrder)];
            case 2:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(paymentActions.updatePaymentOrder.actions.success({
                        paymentOrderId: paymentOrderId,
                        paymentOrder: data,
                    }))];
            case 3:
                _c.sent();
                if (!suppressSuccessMessage) {
                    showAlert({
                        type: "success",
                        message: getGeneralUpdateSuccessText("payment_order"),
                    });
                }
                return [3 /*break*/, 6];
            case 4:
                unknownError_8 = _c.sent();
                error = unknownError_8;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.updatePaymentOrder.actions.failure({
                        error: error,
                        paymentOrderId: paymentOrderId,
                    }))];
            case 5:
                _c.sent();
                showAlert({
                    type: "error",
                    message: getGeneralUpdateFailureText("payment_order"),
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function deletePaymentOrderStartFlow(_a) {
    var paymentOrderId, unknownError_9, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentOrderId = payload.paymentOrderId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.deletePaymentOrder, paymentOrderId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.deletePaymentOrder.actions.success({
                        paymentOrderId: paymentOrderId,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralRemoveSuccessText("payment_order"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_9 = _b.sent();
                error = unknownError_9;
                logger.logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(paymentActions.deletePaymentOrder.actions.failure({
                        error: error,
                        paymentOrderId: paymentOrderId,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getPaymentRequestsStartFlow(_a) {
    var fetchType, refetch, paymentRequests, _b, _c, data, isExhausted, unknownError_10, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                fetchType = payload.fetchType, refetch = payload.refetch;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 5, , 7]);
                _c = (_b = paymentSelectors).getPaymentRequests;
                return [4 /*yield*/, select()];
            case 2:
                paymentRequests = _c.apply(_b, [_d.sent(), fetchType]);
                return [4 /*yield*/, call(paymentApi.getPaymentRequests, {
                        skip: refetch ? 0 : (paymentRequests || []).length,
                        filterData: {
                            where: {
                                status: { inq: getPaymentRequestTypes(fetchType) },
                            },
                        },
                    })];
            case 3:
                data = (_d.sent()).data;
                isExhausted = data.length < paymentApi.FETCH_LIMIT;
                return [4 /*yield*/, put(paymentActions.getPaymentRequests.actions.success({
                        fetchType: fetchType,
                        refetch: refetch,
                        paymentRequests: data,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _d.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_10 = _d.sent();
                error = unknownError_10;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentRequests.actions.failure({
                        error: error,
                        fetchType: fetchType,
                    }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getPaymentRequestsPagedStartFlow(_a) {
    var identifier, _b, page, _c, limit, filterData, customTimeout, skip, paymentRequests, unknownError_11, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                identifier = payload.identifier, _b = payload.page, page = _b === void 0 ? 1 : _b, _c = payload.limit, limit = _c === void 0 ? paymentApi.FETCH_LIMIT : _c, filterData = payload.filterData, customTimeout = payload.customTimeout;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 4, , 6]);
                skip = (page - 1) * limit;
                return [4 /*yield*/, call(paymentApi.getPaymentRequests, {
                        skip: skip,
                        filterData: filterData,
                        limit: limit,
                        customTimeout: customTimeout,
                    })];
            case 2:
                paymentRequests = (_d.sent()).data;
                return [4 /*yield*/, put(paymentActions.getPaymentRequestsPaged.actions.success({
                        identifier: identifier,
                        page: page,
                        paymentRequests: paymentRequests,
                    }))];
            case 3:
                _d.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_11 = _d.sent();
                error = unknownError_11;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentRequestsPaged.actions.failure({
                        error: error,
                        identifier: identifier,
                        page: page,
                    }))];
            case 5:
                _d.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getPaymentRequestsByIdentifierStartFlow(_a) {
    var paymentRequestsIdentifier, refetch, filterData, _b, limit, paymentRequests, _c, _d, data, isExhausted, unknownError_12, error;
    var payload = _a.payload;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                paymentRequestsIdentifier = payload.paymentRequestsIdentifier, refetch = payload.refetch, filterData = payload.filterData, _b = payload.limit, limit = _b === void 0 ? paymentApi.FETCH_LIMIT : _b;
                _e.label = 1;
            case 1:
                _e.trys.push([1, 5, , 7]);
                _d = (_c = paymentSelectors).getPaymentRequestsByIdentifier;
                return [4 /*yield*/, select()];
            case 2:
                paymentRequests = _d.apply(_c, [_e.sent(), paymentRequestsIdentifier]);
                return [4 /*yield*/, call(paymentApi.getPaymentRequests, {
                        skip: refetch ? 0 : (paymentRequests || []).length,
                        filterData: filterData,
                        limit: limit,
                    })];
            case 3:
                data = (_e.sent()).data;
                isExhausted = data.length < limit;
                return [4 /*yield*/, put(paymentActions.getPaymentRequestsByIdentifier.actions.success({
                        paymentRequestsIdentifier: paymentRequestsIdentifier,
                        paymentRequests: data,
                        filterData: filterData,
                        refetch: refetch,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _e.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_12 = _e.sent();
                error = unknownError_12;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentRequestsByIdentifier.actions.failure({
                        paymentRequestsIdentifier: paymentRequestsIdentifier,
                        error: error,
                    }))];
            case 6:
                _e.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getPaymentRequestStartFlow(_a) {
    var id, publicId, data, unknownError_13, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, publicId = payload.publicId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.getPaymentRequest, id, publicId)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(paymentActions.getPaymentRequest.actions.success({
                        id: id,
                        paymentRequest: data,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_13 = _b.sent();
                error = unknownError_13;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getPaymentRequest.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updatePaymentRequestInvoicedAtStartFlow(_a) {
    var paymentRequestId, invoicedAt, data, unknownError_14, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId, invoicedAt = payload.invoicedAt;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.updatePaymentRequestInvoicedAt, paymentRequestId, {
                        invoicedAt: invoicedAt,
                    })];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(paymentActions.updatePaymentRequestInvoicedAt.actions.success({
                        paymentRequestId: paymentRequestId,
                        paymentRequest: data,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("payment_request"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_14 = _b.sent();
                error = unknownError_14;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.updatePaymentRequestInvoicedAt.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function deletePaymentRequestStartFlow(_a) {
    var id, unknownError_15, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.deletePaymentRequest, id)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.deletePaymentRequest.actions.success({
                        id: id,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralRemoveSuccessText("payment_request"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_15 = _b.sent();
                error = unknownError_15;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.deletePaymentRequest.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function deleteMandateStartFlow(_a) {
    var mandateId, unknownError_16, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                mandateId = payload.mandateId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.deleteMandate, mandateId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.deleteMandate.actions.success({
                        mandateId: mandateId,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_16 = _b.sent();
                error = unknownError_16;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.deleteMandate.actions.failure({
                        error: error,
                        mandateId: mandateId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function markThirdPartyReceivedStartFlow(_a) {
    var paymentRequestId, data, unknownError_17, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.markThirdPartyReceivedPaymentRequest, paymentRequestId)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(paymentActions.markThirdPartyReceivedPaymentRequest.actions.success({
                        paymentRequestId: paymentRequestId,
                        paymentRequest: data,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.mark_third_party_received.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_17 = _b.sent();
                error = unknownError_17;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.markThirdPartyReceivedPaymentRequest.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function markPaymentRequestPaidStartFlow(_a) {
    var paymentRequestId, manuallyPaidAt, onSuccess, unknownError_18, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId, manuallyPaidAt = payload.manuallyPaidAt, onSuccess = payload.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(paymentApi.markPaymentRequestPaid, paymentRequestId, manuallyPaidAt)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.markPaymentRequestPaid.actions.success({
                        paymentRequestId: paymentRequestId,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.getPaymentRequest.actions.start({
                        id: paymentRequestId,
                    }))];
            case 4:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(paymentRequestId);
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.marked_paid.toast.heading"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_18 = _b.sent();
                error = unknownError_18;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.markPaymentRequestPaid.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function paymentRequestFollowUpPhoneStartFlow(_a) {
    var paymentRequestId, body, unknownError_19, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId, body = payload.body;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.paymentRequestFollowUpPhone, paymentRequestId, body)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpPhone.actions.success({
                        paymentRequestId: paymentRequestId,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.phone.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_19 = _b.sent();
                error = unknownError_19;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpPhone.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function paymentRequestFollowUpSmsStartFlow(_a) {
    var paymentRequestId, body, unknownError_20, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId, body = payload.body;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.paymentRequestFollowUpSms, paymentRequestId, body)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpSms.actions.success({
                        paymentRequestId: paymentRequestId,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.sms.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_20 = _b.sent();
                error = unknownError_20;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpSms.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function paymentRequestFollowUpAppStartFlow(_a) {
    var paymentRequestId, body, unknownError_21, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId, body = payload.body;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.paymentRequestFollowUpApp, paymentRequestId, body)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpApp.actions.success({
                        paymentRequestId: paymentRequestId,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.app.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_21 = _b.sent();
                error = unknownError_21;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpApp.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function paymentRequestFollowUpMailStartFlow(_a) {
    var paymentRequestId, body, unknownError_22, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId, body = payload.body;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.paymentRequestFollowUpMail, paymentRequestId, body)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpMail.actions.success({
                        paymentRequestId: paymentRequestId,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.mail.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_22 = _b.sent();
                error = unknownError_22;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpMail.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function paymentRequestFollowUpRegisteredMailStartFlow(_a) {
    var paymentRequestId, body, unknownError_23, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId, body = payload.body;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentApi.paymentRequestFollowUpRegisteredMail, paymentRequestId, body)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpRegisteredMail.actions.success({
                        paymentRequestId: paymentRequestId,
                    }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.follow_up.registered_mail.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_23 = _b.sent();
                error = unknownError_23;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.paymentRequestFollowUpRegisteredMail.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getMissingKYCsStartFlow(_a) {
    var missingKYCsIdentifier, refetch, propertyId, missingKYCs, _b, _c, data, isExhausted, unknownError_24, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                missingKYCsIdentifier = payload.missingKYCsIdentifier, refetch = payload.refetch, propertyId = payload.propertyId;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 5, , 7]);
                _c = (_b = paymentSelectors).getMissingKYCs;
                return [4 /*yield*/, select()];
            case 2:
                missingKYCs = _c.apply(_b, [_d.sent(), missingKYCsIdentifier]);
                return [4 /*yield*/, call(paymentApi.getMissingKYCs, refetch ? 0 : (missingKYCs || []).length, paymentApi.FETCH_LIMIT, propertyId)];
            case 3:
                data = (_d.sent()).data;
                isExhausted = data.length < paymentApi.FETCH_LIMIT;
                return [4 /*yield*/, put(paymentActions.getMissingKYCs.actions.success({
                        items: data,
                        refetch: refetch,
                        isExhausted: isExhausted,
                        missingKYCsIdentifier: missingKYCsIdentifier,
                    }))];
            case 4:
                _d.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_24 = _d.sent();
                error = unknownError_24;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.getMissingKYCs.actions.failure({
                        missingKYCsIdentifier: missingKYCsIdentifier,
                        error: error,
                    }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function sendPaymentRequestInvitationStartFlow(_a) {
    var paymentRequestId, unknownError_25, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                paymentRequestId = payload.paymentRequestId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(paymentApi.sendPaymentRequestInvitation, paymentRequestId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.sendPaymentRequestInvitation.actions.success({
                        paymentRequestId: paymentRequestId,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(paymentActions.getPaymentRequest.actions.start({
                        id: paymentRequestId,
                    }))];
            case 4:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("payment_request.resend_invitation.sent.success"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_25 = _b.sent();
                error = unknownError_25;
                logger.logError({ error: error });
                return [4 /*yield*/, put(paymentActions.sendPaymentRequestInvitation.actions.failure({
                        error: error,
                        paymentRequestId: paymentRequestId,
                    }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(paymentActions.getPaymentOrders.types.START, getPaymentOrdersStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatestWithUniquePayload(function (_a) {
                        var payload = _a.payload;
                        return payload.paymentOrdersIdentifier;
                    }, paymentActions.getPaymentOrdersByIdentifier.types.START, getPaymentOrdersByIdentifierStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(paymentActions.getPaymentOrdersForProperty.types.START, getPaymentOrdersForPropertyStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(paymentActions.createPaymentOrder.types.START, createPaymentOrderStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(paymentActions.getPaymentOrder.types.START, getPaymentOrderStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.updatePaymentOrder.types.START, updatePaymentOrderStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(paymentActions.deletePaymentOrder.types.START, deletePaymentOrderStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.getPaymentRequests.types.START, getPaymentRequestsStartFlow)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.getPaymentRequestsByIdentifier.types.START, getPaymentRequestsByIdentifierStartFlow)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.getPaymentRequestsPaged.types.START, getPaymentRequestsPagedStartFlow)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeLatestWithUniquePayload(function (_a) {
                        var payload = _a.payload;
                        return payload.id;
                    }, paymentActions.getPaymentRequest.types.START, getPaymentRequestStartFlow)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLatest(paymentActions.updatePaymentRequestInvoicedAt.types.START, updatePaymentRequestInvoicedAtStartFlow)];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeLatest(paymentActions.deletePaymentRequest.types.START, deletePaymentRequestStartFlow)];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.deleteMandate.types.START, deleteMandateStartFlow)];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.markThirdPartyReceivedPaymentRequest.types.START, markThirdPartyReceivedStartFlow)];
            case 15:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.markPaymentRequestPaid.types.START, markPaymentRequestPaidStartFlow)];
            case 16:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.paymentRequestFollowUpPhone.types.START, paymentRequestFollowUpPhoneStartFlow)];
            case 17:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.paymentRequestFollowUpSms.types.START, paymentRequestFollowUpSmsStartFlow)];
            case 18:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.paymentRequestFollowUpApp.types.START, paymentRequestFollowUpAppStartFlow)];
            case 19:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.paymentRequestFollowUpMail.types.START, paymentRequestFollowUpMailStartFlow)];
            case 20:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.paymentRequestFollowUpRegisteredMail.types.START, paymentRequestFollowUpRegisteredMailStartFlow)];
            case 21:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.getMissingKYCs.types.START, getMissingKYCsStartFlow)];
            case 22:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.sendPaymentRequestInvitation.types.START, sendPaymentRequestInvitationStartFlow)];
            case 23:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.getPaymentOrdersPaged.types.START, getPaymentOrdersPagedStartFlow)];
            case 24:
                _a.sent();
                return [4 /*yield*/, takeEvery(paymentActions.getIban.types.START, getIbanStartStartFlow)];
            case 25:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export default saga;
