import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as propertyConstants from "@rentiohq/shared-frontend/dist/reduxV2/property/property.constants";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  ICountQuery,
  TSort,
} from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  EPropertyForRentStatus,
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared/dist/types/property.types";
import {
  Card,
  ESpacings,
  EmptyState,
  Error,
  Filters,
  Icon,
  Loading,
  Page,
  Pagination,
  ResourceList,
  Tabs,
} from "@rentiohq/web-shared/dist/components";
import {
  EFilterType,
  IDateRange,
} from "@rentiohq/web-shared/dist/components/Filters/Filters.types";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { compact, uniq } from "lodash";
import React from "react";
import {
  EEndDateWithinMonths,
  ERenewalDateWithinMonths,
} from "scenes/FollowUp/EndingContracts/EndingContracts.types";
import {
  getContractEndDateFilterForProperties,
  getContractEndingStatusFilter,
  getContractStatusFilter,
  getSingleContractRenewalDateFilterForProperties,
} from "scenes/FollowUp/EndingContracts/EndingContracts.utils";
import usePreference from "scenes/Settings/hooks/usePreference";
import { StringParam } from "serialize-query-params";
import { ts } from "../../services";
import * as t from "../../services/translationService";
import PropertyListItemApplications from "./components/PropertyListItemApplications";
import PropertyListItemApplicationsHeader from "./components/PropertyListItemApplications/PropertyListItemApplications.header";
import PropertyListItemContracts from "./components/PropertyListItemContracts";
import { PropertyListItemContractsHeader } from "./components/PropertyListItemContracts/PropertyListItemContracts.header";
import PropertyListItemFinancial from "./components/PropertyListItemFinancial";
import PropertyListItemFinancialHeader from "./components/PropertyListItemFinancial/PropertyListItemFinancial.header";
import PropertyListItemGeneral from "./components/PropertyListItemGeneral/PropertyListItemGeneral";
import PropertyListItemGeneralHeader from "./components/PropertyListItemGeneral/PropertyListItemGeneral.header";
import PropertyListItemManagement from "./components/PropertyListItemManagement";
import PropertyListItemManagementHeader from "./components/PropertyListItemManagement/PropertyListItemManagement.header";
import PropertyListItemRent from "./components/PropertyListItemRent";
import PropertyListItemRentHeader from "./components/PropertyListItemRent/PropertyListItemRent.header";
import { ESortOrder } from "./components/SortableResourceListHeader/SortableResourceListHeader.types";
import PartnerImportWhiseModal from "./modals/PartnerImportWhiseModal";
import {
  EContractEndingStatus,
  EContractStatus,
} from "./scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";

enum EPropertyGroupFilter {
  Root = "root",
  OnlyProperties = "only_properties",
  OnlyGroups = "only_groups",
}

export enum EPropertyFetchIdentifier {
  All = "all",
  WithApplications = "with_applications",
}

export enum EPropertyPageType {
  General = "general",
  Applications = "applications",
  Rent = "rent",
  Management = "management",
  Financial = "financial",
  Contracts = "contracts",
}

const PREFERENCE_KEY_PROPERTY_ADDRESS_ORDER = "PROPERTY_ADDRESS_ORDER_V2";
const PREFERENCE_KEY_PROPERTY_CONTRACT_START_DATE_ORDER =
  "PROPERTY_CONTRACT_START_DATE_ORDER_RENT_V2";
const PREFERENCE_KEY_PROPERTY_FETCH_IDENTIFIER = "PROPERTY_FETCH_IDENTIFIER_V2";
const PREFERENCE_KEY_PROPERTY_FILTER_EMPLOYEES =
  "PREFERENCE_KEY_PROPERTY_FILTER_EMPLOYEES";
const PREFERENCE_KEY_PROPERTY_FILTER_END_DATE =
  "PREFERENCE_KEY_PROPERTY_FILTER_END_DATE_V2";
const PREFERENCE_KEY_PROPERTY_FILTER_ENDING_STATUS =
  "PREFERENCE_KEY_PROPERTY_FILTER_ENDING_STATUS_V2";
const PREFERENCE_KEY_PROPERTY_FILTER_FINAL_NOTICE_DAY =
  "PREFERENCE_KEY_PROPERTY_FILTER_FINAL_NOTICE_DAY_V2";
const PREFERENCE_KEY_PROPERTY_FILTER_FOR_RENT =
  "PREFERENCE_KEY_PROPERTY_FILTER_FOR_RENT_V2";
const PREFERENCE_KEY_PROPERTY_FILTER_GROUP =
  "PREFERENCE_KEY_PROPERTY_FILTER_GROUP_V2";
const PREFERENCE_KEY_PROPERTY_FILTER_STATUS =
  "PREFERENCE_KEY_PROPERTY_FILTER_STATUS_V2";
const PREFERENCE_KEY_PROPERTY_PAGE_NUMBER = "PROPERTY_PAGE_NUMBER_V2";
const PREFERENCE_KEY_PROPERTY_PAGE_TYPE = "PROPERTY_PAGE_TYPE_V2";
const PREFERENCE_KEY_PROPERTY_PARENT_ID = "PROPERTY_PARENT_ID_V2";
const PREFERENCE_KEY_PROPERTY_QUERY = "PROPERTY_QUERY_V2";
const PREFERENCE_KEY_PROPERTY_ROLES = "PROPERTY_ROLES_V2";

const FILTER_GROUP_APPLICATIONS = "applications";
const FILTER_GROUP_QUICK_FILTERS = "quick filters";
const FILTER_GROUP_END_DATE = "end_date";
const FILTER_GROUP_FINAL_NOTICE_DATE = "final_notice_date";

const getAddressSortArray = (
  order: ESortOrder.Asc | ESortOrder.Desc,
): TSort => {
  return [
    { field: "street" },
    { field: "number" },
    { field: "box" },
    { field: "zip" },
    { field: "city" },
  ].map(sorter => ({
    ...sorter,
    method: order,
  }));
};

export const Properties = () => {
  const { broker } = authHooks.useSelf();
  const { employees: employeeAccounts } = brokerHooks.useGetBrokerEmployees();

  const [initialQueryParams] = useQueryParams({
    query: StringParam,
  });

  // State
  const [showPartnerImportWhise, setShowPartnerImportWhise] =
    React.useState(false);

  // State from selectors
  const [parentId, setParentId] = usePreference<number | undefined>({
    preferenceKey: PREFERENCE_KEY_PROPERTY_PARENT_ID,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });
  const { detail: parent } = propertyHooks.useDetail({ id: parentId });

  const [page = 1, setPage] = usePreference<number>({
    preferenceKey: PREFERENCE_KEY_PROPERTY_PAGE_NUMBER,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [query = "", setQuery] = usePreference<string>({
    preferenceKey: PREFERENCE_KEY_PROPERTY_QUERY,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [contractStartDateOrder, setContractStartDateOrder] = usePreference<
    ESortOrder | undefined
  >({
    preferenceKey: PREFERENCE_KEY_PROPERTY_CONTRACT_START_DATE_ORDER,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [addressOrder, setAddressOrder] = usePreference<ESortOrder | undefined>(
    {
      preferenceKey: PREFERENCE_KEY_PROPERTY_ADDRESS_ORDER,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    },
  );

  const [queryDebounced] = useDebounce(query);

  const [pageType = EPropertyPageType.General, setPageType] =
    usePreference<EPropertyPageType>({
      preferenceKey: PREFERENCE_KEY_PROPERTY_PAGE_TYPE,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  const [roles, setRoles] = usePreference<EPropertyMemberTypes[]>({
    preferenceKey: PREFERENCE_KEY_PROPERTY_ROLES,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [fetchIdentifier = EPropertyFetchIdentifier.All, setFetchIdentifier] =
    usePreference<EPropertyFetchIdentifier | undefined>({
      preferenceKey: PREFERENCE_KEY_PROPERTY_FETCH_IDENTIFIER,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  const [employeesFilter = [], setEmployeesFilter] = usePreference<number[]>({
    preferenceKey: PREFERENCE_KEY_PROPERTY_FILTER_EMPLOYEES,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [endDateFilter, setEndDateFilter] = usePreference<
    EEndDateWithinMonths | undefined
  >({
    preferenceKey: PREFERENCE_KEY_PROPERTY_FILTER_END_DATE,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [endingStatusFilter = [], setEndingStatus] = usePreference<
    EContractEndingStatus[]
  >({
    preferenceKey: PREFERENCE_KEY_PROPERTY_FILTER_ENDING_STATUS,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [statusFilter = [], setStatus] = usePreference<EContractStatus[]>({
    preferenceKey: PREFERENCE_KEY_PROPERTY_FILTER_STATUS,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });
  const [finalNoticeDayFilter, setFinalNoticeDayFilter] = usePreference<
    ERenewalDateWithinMonths | undefined
  >({
    preferenceKey: PREFERENCE_KEY_PROPERTY_FILTER_FINAL_NOTICE_DAY,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [groupFilter = EPropertyGroupFilter.Root, setGroupFilter] =
    usePreference<EPropertyGroupFilter | undefined>({
      preferenceKey: PREFERENCE_KEY_PROPERTY_FILTER_GROUP,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  const [forRentStatusFilter, setForRentStatusFilter] = usePreference<
    EPropertyForRentStatus | undefined
  >({
    preferenceKey: PREFERENCE_KEY_PROPERTY_FILTER_FOR_RENT,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  // Lifecycle
  React.useEffect(() => {
    const initialQuery = initialQueryParams.query;
    if (!initialQuery || initialQuery.length === 0) {
      return;
    }

    setQuery(initialQuery);
  }, []);

  const queryExtra = (() => {
    const result: ICountQuery = {};

    const andFilters: { [key: string]: any }[] = [{ archivedAt: { is: null } }];
    const customFilters: string[] = [];

    const queryTrimmed = queryDebounced.trim();
    if (queryTrimmed.length > 0) {
      result.search = queryTrimmed;
    }

    if (forRentStatusFilter) {
      if (forRentStatusFilter === EPropertyForRentStatus.None) {
        andFilters.push({
          or: [
            { forRentStatus: { eq: forRentStatusFilter } },
            { forRentStatus: { is: null } },
          ],
        });
      } else {
        andFilters.push({ forRentStatus: { eq: forRentStatusFilter } });
      }
    }

    const ignoreGroupsFilter =
      queryTrimmed.length > 0 ||
      !!forRentStatusFilter ||
      (pageType === EPropertyPageType.Applications &&
        fetchIdentifier === EPropertyFetchIdentifier.WithApplications) ||
      statusFilter.length > 0 ||
      endingStatusFilter.length > 0 ||
      finalNoticeDayFilter !== undefined ||
      (roles || []).length > 0 ||
      endDateFilter !== undefined;

    if (parentId) {
      andFilters.push({ parentId: { eq: parentId } });
    }
    // Only apply groups filter if there is no property specific filter
    else if (!ignoreGroupsFilter) {
      if (groupFilter === EPropertyGroupFilter.OnlyGroups) {
        andFilters.push({ typeid: { eq: 1 } });
      } else if (groupFilter === EPropertyGroupFilter.OnlyProperties) {
        andFilters.push({ typeid: { neq: 1 } });
      } else if (groupFilter === EPropertyGroupFilter.Root) {
        andFilters.push({ parentId: { is: null } });
      }
    }

    const endingContractStatusFilterMapped =
      getContractEndingStatusFilter(endingStatusFilter);
    if (endingContractStatusFilterMapped) {
      andFilters.push(endingContractStatusFilterMapped);
    }

    const singleStatusFilter = statusFilter[0];
    const contractStatusFilterMapped = singleStatusFilter
      ? getContractStatusFilter(singleStatusFilter)
      : undefined;
    if (contractStatusFilterMapped) {
      andFilters.push(contractStatusFilterMapped);
    }
    if (statusFilter?.includes(EContractStatus.NoContract)) {
      customFilters.push("NO_CONTRACTS");
    }

    const contractEndDateFilterMapped = endDateFilter
      ? getContractEndDateFilterForProperties(endDateFilter)
      : undefined;
    if (contractEndDateFilterMapped) {
      andFilters.push(contractEndDateFilterMapped);
    }

    const renewalDateFilterMapped = finalNoticeDayFilter
      ? getSingleContractRenewalDateFilterForProperties(finalNoticeDayFilter)
      : undefined;
    if (renewalDateFilterMapped) {
      andFilters.push(renewalDateFilterMapped);
    }

    if (roles && roles.length > 0) {
      result.rolesFilter = roles;
    }

    if (
      pageType === EPropertyPageType.Applications &&
      fetchIdentifier === EPropertyFetchIdentifier.WithApplications
    ) {
      customFilters.push("WITH_APPLICATIONS");
    }

    if (employeesFilter && employeesFilter.length > 0) {
      result.employeeAccountIds = employeesFilter;
      result.unassignedToEmployee = employeesFilter.includes(-1);
    }

    result.filter = { and: andFilters };
    result.customFilters = customFilters;

    return result;
  })();

  const sort = (() => {
    const result: TSort = [];

    if (contractStartDateOrder) {
      result.push({
        field: { contracts: "startDate" },
        method: contractStartDateOrder,
      });
    }

    if (addressOrder) {
      result.push(...getAddressSortArray(addressOrder));
    }

    if (result.length === 0) {
      return propertyConstants.GENERATOR_PARAMS.defaultSort;
    }

    return result;
  })();

  const {
    items: properties,
    fetchError,
    isFetching,
    totalPages = 1,
  } = propertyHooks.usePaged({
    query: {
      page,
      limit: 20,
      sort,
      ...queryExtra,
    },
  });

  // Event handler
  const handleSetParentId = (newParentId?: number) => {
    setPage(1);
    setParentId(newParentId);
  };

  const handleClickPageType = (newPageType: EPropertyPageType) => () => {
    setPageType(newPageType);
  };

  const handleQueryChange = (newQuery: string) => {
    setPage(1);
    setQuery(newQuery);
  };

  const handleQueryClear = () => {
    setQuery("");
  };

  const handleSetRoles = (newRoles: EPropertyMemberTypes[]) => {
    setPage(1);
    setRoles([...newRoles]);
  };

  const handleSetEndingStatus = (newValues: EContractEndingStatus[]) => {
    setPage(1);
    setEndingStatus([...newValues]);
  };
  const handleSetFinalNoticeDay = (newValues: ERenewalDateWithinMonths[]) => {
    setPage(1);
    setFinalNoticeDayFilter(newValues[0] as ERenewalDateWithinMonths);
  };
  const handleSetGroups = (newValues: EPropertyGroupFilter[]) => {
    setPage(1);
    setGroupFilter(newValues[0]);
  };
  const handleSetForRent = (newValues: EPropertyForRentStatus[]) => {
    setPage(1);
    setForRentStatusFilter(newValues[0]);
  };

  const handleSetStatus = (newStatuses: EContractStatus[]) => {
    setPage(1);
    setStatus([...newStatuses]);
  };

  const handleSetIdentifier = (
    identifier: EPropertyFetchIdentifier | undefined,
  ) => {
    setPage(1);
    setFetchIdentifier(identifier);
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  // Render
  const renderEmpty = () => (
    <EmptyState
      size="large"
      heading={t.propertyOverviewEmptyStateTitle()}
      action={{
        content: t.propertyOverviewEmptyStatePrimaryCTA(),
        url: `/properties/add?parentId=${parentId}`,
      }}
    >
      <p>{t.propertyOverviewEmptyStateContent()}</p>
    </EmptyState>
  );

  const renderTabs = () => (
    <Card.Section boxProps={{ pt: ESpacings.extraTight, px: ESpacings.loose }}>
      <Tabs
        tabs={[
          EPropertyPageType.General,
          EPropertyPageType.Applications,
          EPropertyPageType.Rent,
          EPropertyPageType.Contracts,
          EPropertyPageType.Management,
          EPropertyPageType.Financial,
        ].map(randomPageType => ({
          name: randomPageType,
          content: getLocalizedText(
            `properties.page_type.${randomPageType}`.toLowerCase(),
          ),
          isActive: randomPageType === pageType,
          onClick: handleClickPageType(randomPageType),
        }))}
      />
    </Card.Section>
  );

  const renderFilter = () => (
    <Card.Section>
      <Filters
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryClear}
        queryValue={query}
        queryPlaceholder={t.propertyOverviewSearchPlaceholder()}
        appliedFilters={
          parentId
            ? [
                {
                  key: "parent",
                  label: `${getLocalizedText("property.type.group")}: ${
                    parent ? formatAddress(parent) : parentId
                  }`,
                  onRemove: () => {
                    setPage(1);
                    setParentId(undefined);
                  },
                },
              ]
            : []
        }
        filterConfigs={compact([
          {
            label: getLocalizedText("properties.filter.employees"),
            groupKey: "employees",
            asDropdown: false,
            filters: [
              {
                type: EFilterType.EmployeeSelect,
                options: employeeAccounts,
                values: employeesFilter,
                extra: {
                  companyName: broker?.name,
                },
                filterKey: "employees",
                onChange: (selectedEmployeeId: number) => {
                  if (employeesFilter.includes(selectedEmployeeId)) {
                    const copy = [...employeesFilter];
                    copy.splice(copy.indexOf(selectedEmployeeId), 1);
                    setEmployeesFilter(copy);
                  } else {
                    setEmployeesFilter([
                      ...employeesFilter,
                      selectedEmployeeId,
                    ]);
                  }
                },
                onRemove: (removedEmployeeId: string) => {
                  const id = Number(removedEmployeeId);
                  const copy = [...employeesFilter];
                  copy.splice(copy.indexOf(id), 1);
                  setEmployeesFilter(copy);
                },
                translate: (id: string | IDateRange) => {
                  const _id = Number(id);
                  if (isNaN(_id)) return "";
                  if (_id === -1) return getLocalizedText("system.unassigned");
                  const employee = employeeAccounts.find(m => m.id === _id);
                  if (employee) {
                    return getName(employee);
                  }
                  return "";
                },
              },
            ],
          },
          {
            label: getLocalizedText("properties.filter.groups"),
            groupKey: "groups",
            filters: [
              {
                filterKey: "properties_groups",
                type: EFilterType.SingleSelect,
                options: Object.values(EPropertyGroupFilter),
                values: groupFilter ? [groupFilter] : [],
                translate: value =>
                  getLocalizedText(
                    `properties.filter.groups.${value}`.toLowerCase(),
                  ),
                onChange: handleSetGroups,
                onRemove: () => {
                  setPage(1);
                  setGroupFilter(undefined);
                },
                shownValuesInQuickFilter: (values: EPropertyGroupFilter[]) =>
                  values.filter(x => x !== EPropertyGroupFilter.Root),
              },
            ],
          },
          {
            label: getLocalizedText("properties.filter.for_rent"),
            groupKey: "for_rent",
            filters: [
              {
                filterKey: "properties_for_rent",
                type: EFilterType.SingleSelect,
                options: Object.values(EPropertyForRentStatus),
                values: forRentStatusFilter ? [forRentStatusFilter] : [],
                translate: value =>
                  getLocalizedText(
                    `property.for_rent_status.${stringToSnakeCase(
                      value as string,
                    )}`,
                  ),
                onChange: handleSetForRent,
                onRemove: () => {
                  setPage(1);
                  setForRentStatusFilter(undefined);
                },
              },
            ],
          },
          {
            label: getLocalizedText("properties.filter.type_duration"),
            groupKey: "type_duration",
            filters: [
              {
                type: EFilterType.MultiSelect,
                filterKey: "properties_type_duration",
                values: endingStatusFilter ? endingStatusFilter : [],
                options: Object.values(EContractEndingStatus),
                translate: (duration: string | IDateRange) =>
                  getLocalizedText(`contract.duration.${duration}`),
                onChange: (newValues: string | string[]) => {
                  handleSetEndingStatus(newValues as EContractEndingStatus[]);
                },
                onRemove: key => {
                  setEndingStatus(endingStatusFilter.filter(s => s !== key));
                },
              },
            ],
          },
          {
            label: getLocalizedText("properties.filter.status"),
            groupKey: "properties_status",
            filters: [
              {
                label: "Status",
                filterKey: "properties_status",
                type: EFilterType.SingleSelect,
                options: Object.values(EContractStatus),
                values: statusFilter ? statusFilter : [],
                translate: (status: string | IDateRange) =>
                  getLocalizedText(`contract.status.${status}`),
                onChange: (newValues: string | string[]) => {
                  handleSetStatus(newValues as EContractStatus[]);
                },
                onRemove: key => {
                  setStatus(statusFilter.filter(s => s !== key));
                },
              },
            ],
          },
          {
            label: getLocalizedText("properties.filter.end_date"),
            groupKey: FILTER_GROUP_END_DATE,
            filters: [
              {
                type: EFilterType.SingleSelect,
                filterKey: "properties_end_dates",
                values: endDateFilter ? [endDateFilter] : [],
                options: [
                  EEndDateWithinMonths.Within1Month,
                  EEndDateWithinMonths.Within4Months,
                  EEndDateWithinMonths.Within7Months,
                ],
                translate: (value: string | IDateRange) =>
                  getLocalizedText(
                    `ending_contracts.option.end_date_within_months.${stringToSnakeCase(
                      value as string,
                    )}`,
                  ),
                onChange: (newValues: string) => {
                  setEndDateFilter(newValues[0] as EEndDateWithinMonths);
                },
                onRemove: () => {
                  setEndDateFilter(undefined);
                },
              },
            ],
          },
          {
            label: getLocalizedText("properties.filter.final_notice"),
            groupKey: FILTER_GROUP_FINAL_NOTICE_DATE,
            filters: [
              {
                type: EFilterType.SingleSelect,
                filterKey: "properties_final_notice_date",
                values: finalNoticeDayFilter ? [finalNoticeDayFilter] : [],
                options: Object.values(ERenewalDateWithinMonths),
                translate: (value: string | IDateRange) =>
                  getLocalizedText(
                    `ending_contracts.option.renewal_date_within_months.${stringToSnakeCase(
                      value as string,
                    )}`,
                  ),
                onChange: handleSetFinalNoticeDay,
                onRemove: () => {
                  setFinalNoticeDayFilter(undefined);
                },
              },
            ],
          },
          {
            label: getLocalizedText("properties.filter.roles.title"),
            groupKey: FILTER_GROUP_QUICK_FILTERS,
            filters: [
              {
                filterKey: "FK-roles",
                type: EFilterType.MultiSelect,
                options: [
                  EPropertyMemberTypes.Owner,
                  EPropertyMemberTypes.IndexationManager,
                  EPropertyMemberTypes.FinancialManager,
                  EPropertyMemberTypes.TechnicalManager,
                  EPropertyMemberTypes.AdministrativeManager,
                  EPropertyMemberTypes.Tenant,
                  EPropertyMemberTypes.Parent,
                ],
                translate: role =>
                  getLocalizedText(`role.${role}`.toLowerCase()),
                onChange: (newRoles: EPropertyMemberTypes[]) => {
                  handleSetRoles(uniq(newRoles));
                },
                onRemove: (key: string) => {
                  if (!roles) return;
                  const withOutKey = roles.filter(role => role !== key);
                  handleSetRoles(uniq(withOutKey));
                },
                values: roles ?? [],
                showAsOneAppliedFilterBlock: true,
              },
            ],
          },
          pageType === EPropertyPageType.Applications
            ? {
                label: getLocalizedText("properties.filter.applications.title"),
                groupKey: FILTER_GROUP_APPLICATIONS,
                filters: [
                  {
                    filterKey: "applications",
                    type: EFilterType.SingleSelect,
                    options: Object.values(EPropertyFetchIdentifier),
                    translate: (fetchIdentifier: string | IDateRange) =>
                      getLocalizedText(
                        `system.properties.filter.${fetchIdentifier}`,
                      ),
                    onChange: (newValues: string | string[]) => {
                      const identifier = (
                        Array.isArray(newValues) ? newValues[0] : newValues
                      ) as EPropertyFetchIdentifier;
                      handleSetIdentifier(identifier);
                    },
                    onRemove: () => handleSetIdentifier(undefined),
                    values: fetchIdentifier ? [fetchIdentifier] : [],
                    shownValuesInQuickFilter: (
                      values: EPropertyFetchIdentifier[],
                    ) => values.filter(x => x !== EPropertyFetchIdentifier.All),
                  },
                ],
              }
            : undefined,
        ])}
      />
    </Card.Section>
  );

  const renderListHeader = () => {
    switch (pageType) {
      case EPropertyPageType.Applications:
        return (
          <PropertyListItemApplicationsHeader
            addressOrder={addressOrder}
            addressOnChangeOrder={setAddressOrder}
          />
        );

      case EPropertyPageType.Rent:
        return (
          <PropertyListItemRentHeader
            addressOrder={addressOrder}
            addressOnChangeOrder={setAddressOrder}
            contractStartDateOrder={contractStartDateOrder}
            contractStartDateOnOrderChange={setContractStartDateOrder}
          />
        );

      case EPropertyPageType.Management:
        return (
          <PropertyListItemManagementHeader
            addressOrder={addressOrder}
            addressOnChangeOrder={setAddressOrder}
            contractStartDateOrder={contractStartDateOrder}
            contractStartDateOnOrderChange={setContractStartDateOrder}
          />
        );

      case EPropertyPageType.Financial:
        return (
          <PropertyListItemFinancialHeader
            addressOrder={addressOrder}
            addressOnChangeOrder={setAddressOrder}
          />
        );
      case EPropertyPageType.Contracts:
        return (
          <PropertyListItemContractsHeader
            addressOrder={addressOrder}
            addressOnChangeOrder={setAddressOrder}
            contractStartDateOrder={contractStartDateOrder}
            contractStartDateOnOrderChange={setContractStartDateOrder}
          />
        );
      case EPropertyPageType.General:
      default:
        return (
          <PropertyListItemGeneralHeader
            addressOrder={addressOrder}
            addressOnChangeOrder={setAddressOrder}
          />
        );
    }
  };

  const renderPropertyListItem = (property: IProperty) => {
    const commonProps = {
      property,
      selectedParentId: parentId,
      onSetParentId: handleSetParentId,
    };

    switch (pageType) {
      case EPropertyPageType.Applications:
        return <PropertyListItemApplications {...commonProps} />;

      case EPropertyPageType.Rent:
        return <PropertyListItemRent {...commonProps} />;

      case EPropertyPageType.Management:
        return <PropertyListItemManagement {...commonProps} />;

      case EPropertyPageType.Financial:
        return <PropertyListItemFinancial {...commonProps} />;

      case EPropertyPageType.Contracts:
        return (
          <PropertyListItemContracts
            {...commonProps}
            includeFinishedContracts={statusFilter.includes(
              EContractStatus.Finished,
            )}
          />
        );

      case EPropertyPageType.General:
      default:
        return <PropertyListItemGeneral {...commonProps} />;
    }
  };

  const renderList = () => {
    if (!properties) {
      return null;
    }

    return (
      <ResourceList
        items={properties}
        renderItem={renderPropertyListItem}
        noResultsHeading={t.propertyOverviewNoResultsTitle()}
      />
    );
  };

  const renderPaging = () => {
    if (totalPages <= 1) {
      return;
    }

    return (
      <Pagination
        initialPage={page - 1}
        pageCount={totalPages}
        onPageChange={handlePageClick}
      />
    );
  };

  const renderContent = () => {
    if (properties) {
      if (!query && properties.length === 0) {
        return renderEmpty();
      }

      return (
        <>
          {renderListHeader()}
          {renderList()}
          {renderPaging()}
        </>
      );
    }

    if (fetchError) {
      return (
        <>
          <Error errors={[fetchError]} />
          {renderPaging()}
        </>
      );
    }

    if (isFetching) {
      return (
        <>
          <Loading />
          {renderPaging()}
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Page
        title={t.propertyOverviewTitle()}
        fullWidth
        actions={[
          {
            appearance: "primary",
            content: t.propertyOverviewPrimaryCTA(),
            dropdown: [
              {
                content: ts.propertyOverviewPrimaryCTA(),
                url: "/properties/add",
                media: <Icon source="add" />,
              },
              {
                content: ts.propertyAddGroup(),
                url: `/properties/add?group=1`,
                media: <Icon source="add" />,
              },
              ...(broker?.whiseClientId
                ? [
                    {
                      content: getLocalizedText(
                        "property.overview.partner_import.whise.cta.title",
                      ),
                      onClick: () => {
                        setShowPartnerImportWhise(true);
                      },
                    },
                  ]
                : []),
            ],
          },
        ]}
      >
        <Card hasSections={true}>
          {renderTabs()}
          {renderFilter()}
          <Card.Section hasBorder={false}>{renderContent()}</Card.Section>
        </Card>
      </Page>
      {showPartnerImportWhise && (
        <PartnerImportWhiseModal
          onClose={() => {
            setShowPartnerImportWhise(false);
          }}
        />
      )}
    </>
  );
};
