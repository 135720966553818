import * as propertyActions from "@rentiohq/shared-frontend/dist/redux/property/property.actions";
import * as propertySelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { IRootStore } from "redux/reducers";
import { EField } from "./schema.partnerImportWhise.types";

export const isPerformingSubmitActionSelector = (state: IRootStore) =>
  state.property.isCreatingProperty;

export const performingSubmitActionErrorSelector = (state: IRootStore) =>
  state.property.createPropertyError;

export const performingSubmitActionResultSelector = (state: IRootStore) =>
  propertySelectorsV2.getDetail(state, state.property.createdPropertyId);

export const submitActionCreator = (formData: any) => {
  // TODO: Implement this when the endpoint is online.
  return propertyActions.importPropertyPartnerWhise.actions.start({
    whiseId: formData[EField.WhiseId],
  });
};
