import {
  DataTable,
  EmptyState,
  Error,
  Loading,
  Pagination,
} from "@rentiohq/web-shared/dist/components";
import * as React from "react";
import { ts } from "../../../../services";
import { IDocumentsListProps } from "./DocumentList.types";

export const DocumentsList: React.FC<IDocumentsListProps> = ({
  emptyStateTitle,
  rows,
  page,
  totalPages,
  isLoadingData,
  isFetching,
  fetchError,
  isArchived,
  handlePageClick,
  handleRowClick,
}) => {
  // Render methods
  const renderPaging = () => {
    if (totalPages <= 1) {
      return;
    }

    return (
      <Pagination
        initialPage={page - 1}
        pageCount={totalPages}
        onPageChange={({ selected }: { selected: number }) => {
          handlePageClick(selected, isArchived);
        }}
      />
    );
  };

  if (fetchError) {
    return (
      <>
        <Error errors={[fetchError]} />
        {renderPaging()}
      </>
    );
  }

  if (isFetching) {
    return (
      <>
        <Loading />
        {renderPaging()}
      </>
    );
  }

  return (
    <>
      <DataTable
        columnContentTypes={["text", "text", "text", "action"]}
        headings={[
          ts.system("name"),
          ts.uploadDate(),
          // ts.fileSize(),
          ts.propertyDocumentsSharedWithTitle(),
          "",
        ]}
        emptyState={
          <EmptyState backgroundColor="gray" heading={emptyStateTitle} />
        }
        rows={rows}
        // sortable={[true, true, true]}
        // onSort={handleSort}
        isLoadingData={isLoadingData}
        asDots={true}
        onRowClick={(rowIndex: number) => {
          handleRowClick(rowIndex, isArchived);
        }}
      />
      {renderPaging()}
    </>
  );
};
