var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { getDefaultStateAll, registerActions, } from "../generic/all.reducer";
import * as actions from "./templateDocument.actions";
import CONFIG from "./templateDocument.config";
export var REDUCER_KEY_TEMPLATE_DOCUMENT = CONFIG.REDUCER_KEY;
var getDefaultState = function () {
    return merge({}, getDefaultStateAll(), {
        dataTemplateDocumentDetail: {},
        templateDocumentDetail: {},
    });
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
registerActions({
    entityName: CONFIG.ENTITY_NAME,
    reducer: reducer,
});
reducer.on(actions.getTemplateDocumentDetail.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_b = {}, _b[id] = {
            isFetching: true,
            fetchError: undefined,
        }, _b)) }));
});
reducer.on(actions.getTemplateDocumentDetail.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, templateDocumentDetail = _a.templateDocumentDetail;
    return (__assign(__assign({}, state), { dataTemplateDocumentDetail: __assign(__assign({}, state.dataTemplateDocumentDetail), (_b = {}, _b[id] = templateDocumentDetail, _b)), templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_c = {}, _c[id] = {
            isFetching: false,
            fetchError: undefined,
        }, _c)) }));
});
reducer.on(actions.getTemplateDocumentDetail.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return (__assign(__assign({}, state), { templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_b = {}, _b[id] = {
            isFetching: false,
            fetchError: error,
        }, _b)) }));
});
reducer.on(actions.updateTemplateDocumentDetail.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_b = {}, _b[id] = {
            isUpdating: true,
            updateError: undefined,
        }, _b)) }));
});
reducer.on(actions.updateTemplateDocumentDetail.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, data = _a.data;
    return (__assign(__assign({}, state), { dataTemplateDocumentDetail: __assign(__assign({}, state.dataTemplateDocumentDetail), (_b = {}, _b[id] = data, _b)), templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_c = {}, _c[id] = {
            isUpdating: false,
            updateError: undefined,
        }, _c)) }));
});
reducer.on(actions.updateTemplateDocumentDetail.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return (__assign(__assign({}, state), { templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_b = {}, _b[id] = {
            isUpdating: false,
            updateError: error,
        }, _b)) }));
});
reducer.on(actions.generateTemplateDocumentPdf.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_b = {}, _b[id] = {
            isGeneratingPdf: true,
            generatePdfError: undefined,
        }, _b)) }));
});
reducer.on(actions.generateTemplateDocumentPdf.types.SUCCESS, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_b = {}, _b[id] = {
            isGeneratingPdf: false,
            generatePdfError: undefined,
        }, _b)) }));
});
reducer.on(actions.generateTemplateDocumentPdf.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return (__assign(__assign({}, state), { templateDocumentDetail: __assign(__assign({}, state.templateDocumentDetail), (_b = {}, _b[id] = {
            isGeneratingPdf: false,
            generatePdfError: error,
        }, _b)) }));
});
export default reducer;
