import { Box } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as applicationActions from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.actions";
import * as applicationHooks from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.hooks";
import * as applicationUtils from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.utils";
import * as propertySelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { Can, ESpacings, Loading } from "@rentiohq/web-shared/dist/components";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { getFlows } from "utils/application.utils";
import PropertyDetailApplications from "./PropertyDetailApplications";
import { DragAndDropContextProvider } from "./context/DragAndDropContextProvider";
export interface IStatusChange {
  id: string;
  step: number;
  tenantFeedbackText?: string;
}

export const PropertyDetailApplicationsContainer = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectorsV2.getDetail(state, propertyId) : undefined,
  );

  const { broker } = authHooks.useSelf();

  const { items: applications, isFetching } = applicationHooks.useInfiniteLoad({
    query: applicationUtils.getApplicationsQuery({
      filter: { propertyId: { eq: propertyId } },
    }),
  });

  const dispatch = useDispatch();

  if (isFetching || !applications) {
    return (
      <Box mb={ESpacings.base}>
        <Loading />
      </Box>
    );
  }

  const intakeType = broker?.intakeType || 2;
  const steps = getFlows(intakeType);

  const handleStatusChange = async (params: IStatusChange) => {
    const { id: applicationId, step: stepString, tenantFeedbackText } = params;
    const step = Number(stepString);

    dispatch(
      applicationActions.updateApplicationStepStart.getAction({
        id: applicationId,
        step,
        tenantFeedbackText,
      }),
    );
  };

  if (!property) {
    return null;
  }

  // We're using a Context here. That's not a pattern we use a lot, but it's
  // useful here because we need the drag & drop info in almost all components lower in the hierarchy,
  // but that info doesn't need to be stored in Redux (not used anywhere else)
  const renderContent = () => (
    <DragAndDropContextProvider>
      <PropertyDetailApplications
        applications={applications}
        handleOneStatusChange={handleStatusChange}
        type={intakeType}
        visitorSteps={steps.visitorSteps}
        candidateSteps={steps.candidateSteps}
        notEligibleSteps={steps.notEligibleSteps}
        property={property}
      />
    </DragAndDropContextProvider>
  );

  return (
    <Can
      role={property.roles}
      perform="propertyApplications:visit"
      yes={renderContent}
      no={() => <Navigate to={`/properties/${propertyId}`} />}
    />
  );
};
