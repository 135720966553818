import { Box } from "@rebass/grid";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { IAccount } from "@rentiohq/shared/dist/types/auth.types";
import { IContact } from "@rentiohq/shared/dist/types/contact.types";
import { IPaymentOrder } from "@rentiohq/shared/dist/types/payment.types";
import {
  Card,
  ContactFetchListItem,
  DisplayText,
  ESpacings,
  Grid,
  Icon,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import Activities from "@rentiohq/web-shared/dist/components/Activities";
import RentioInternalRenderer from "@rentiohq/web-shared/dist/components/RentioInternalRenderer";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import * as t from "../../../../../../services/translationService";
import { getPaymentOrderActivityFilter } from "../../../../../../utils/payment";
import { EPaymentOrderTabs } from "../../PaymentOrder.types";
import { PaymentMethodsCard } from "../PaymentMethodsCard";
import { usePayeeBankAccount } from "./PayeeBankAccount";

interface IProps {
  paymentOrder: IPaymentOrder;
  setTab?: (tab: EPaymentOrderTabs) => void;
}

type TRenderRow<T> = T & { actions: (item?: any) => IAction[] };

const renderPayerRow = (paymentOrder: TRenderRow<IPaymentOrder>) => {
  const renderContact = (contact: IContact) => {
    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.tight }}
        item={contact}
        media={<Icon source="profile" />}
        mediaSize="medium"
        actions={paymentOrder.actions(contact)}
      >
        <Box mb={ESpacings.extraTight}>
          <TextStyle>{getName(contact)}</TextStyle>
        </Box>
        {(contact.email || contact.phone) && (
          <TextStyle variation="subdued">
            <Grid alignItems="center">
              {contact.phone && (
                <Grid.Item>
                  <Grid spacing="extraTight" alignItems="center">
                    <Grid.Item>
                      <Icon source="smartphone" size="small" />
                    </Grid.Item>
                    <Grid.Item>{formatPhone(contact.phone)}</Grid.Item>
                  </Grid>
                </Grid.Item>
              )}
              {contact.email && (
                <Grid.Item>
                  <Grid spacing="extraTight" alignItems="center">
                    <Grid.Item>
                      <Icon source="email" size="small" />
                    </Grid.Item>
                    <Grid.Item>{contact.email}</Grid.Item>
                  </Grid>
                </Grid.Item>
              )}
            </Grid>
          </TextStyle>
        )}
      </ResourceListItem>
    );
  };

  const renderAccount = (account: IAccount) => {
    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.tight }}
        item={account}
        media={<Icon source="profile" />}
        mediaSize="medium"
        actions={paymentOrder.actions()}
      >
        <div>{getName(account)}</div>
      </ResourceListItem>
    );
  };

  return (
    <ContactFetchListItem
      accountId={paymentOrder.payerId}
      renderContact={renderContact}
      renderAccount={renderAccount}
    />
  );
};

const renderPayeeRow = (item: TRenderRow<IAccount>) => {
  const renderContact = (contact: IContact) => {
    return (
      <>
        <Box mb={ESpacings.extraTight}>
          <TextStyle>{getName(contact)}</TextStyle>
        </Box>

        {(contact.email || contact.phone) && (
          <TextStyle variation="subdued">
            <Grid alignItems="center">
              {contact.phone && (
                <Grid.Item>
                  <Grid spacing="extraTight" alignItems="center">
                    <Grid.Item>
                      <Icon source="smartphone" size="small" />
                    </Grid.Item>
                    <Grid.Item>{formatPhone(contact.phone)}</Grid.Item>
                  </Grid>
                </Grid.Item>
              )}
              {contact.email && (
                <Grid.Item>
                  <Grid spacing="extraTight" alignItems="center">
                    <Grid.Item>
                      <Icon source="email" size="small" />
                    </Grid.Item>
                    <Grid.Item>{contact.email}</Grid.Item>
                  </Grid>
                </Grid.Item>
              )}
            </Grid>
          </TextStyle>
        )}
      </>
    );
  };

  const renderAccount = (account: IAccount) => {
    return <div>{getName(account)}</div>;
  };

  return (
    <ResourceListItem
      boxProps={{ py: ESpacings.tight }}
      item={item}
      media={<Icon source="profile" />}
      mediaSize="medium"
      actions={item.actions(item)}
    >
      <ContactFetchListItem
        accountId={item.id}
        renderContact={renderContact}
        renderAccount={renderAccount}
      />
    </ResourceListItem>
  );
};

export const PaymentOrderInfo: React.FC<IProps> = ({
  paymentOrder,
  setTab,
}) => {
  const payeeBankAccount = usePayeeBankAccount(paymentOrder);

  const generatePayerActions = () => {
    if (!paymentOrder.payer) {
      return [];
    }

    if (paymentOrder.payer.contactId) {
      return [
        {
          content: t.contactViewContactAction(),
          link: `/contacts/${paymentOrder.payer.contactId}`,
        },
      ];
    }

    return [
      {
        content: t.contactViewContactAction(),
        link: `/contacts/by-account/${paymentOrder.payer.id}`,
      },
    ];
  };

  const generatePayeeActions = () => {
    if (!paymentOrder.payee) {
      return [];
    }

    if (paymentOrder.payee.contactId) {
      return [
        {
          content: t.contactViewContactAction(),
          link: `/contacts/${paymentOrder.payee.contactId}`,
        },
      ];
    }

    return [
      {
        content: t.contactViewContactAction(),
        link: `/contacts/by-account/${paymentOrder.payee.id}`,
      },
    ];
  };

  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const handleActivityMoreClick = () => {
    setTab?.(EPaymentOrderTabs.History);
  };

  return (
    <>
      <RentioInternalRenderer
        items={{
          "Group ID": paymentOrder.paymentGroupId,
          "Group status": paymentOrder.paymentGroup?.status || "-",
          "Group repetition type":
            paymentOrder.paymentGroup?.repetitionType || "-",
        }}
      />

      <Card
        heading={renderCardHeader(t.system("payer"))}
        space={ESpacings.base}
      >
        <ResourceList
          items={[
            {
              ...paymentOrder,
              actions: generatePayerActions,
            },
          ]}
          renderItem={renderPayerRow}
        />
      </Card>

      {paymentOrder.mandate && (
        <Card
          heading={renderCardHeader(
            getLocalizedText("payment.payment_order.payer_mandate_info"),
          )}
          space={ESpacings.base}
        >
          <ResourceList
            items={[paymentOrder.mandate]}
            renderItem={() => (
              <ResourceListItem
                boxProps={{ py: ESpacings.tight }}
                item={paymentOrder.mandate}
                media={<Icon source="mandateGiven" />}
                mediaSize="medium"
              >
                {paymentOrder.mandate?.approvedAt &&
                  getLocalizedText(`bank_account.mandate.approved_at`, {
                    date: formatDate(paymentOrder.mandate.approvedAt),
                  })}
              </ResourceListItem>
            )}
          />
        </Card>
      )}

      {paymentOrder.paymentMethods &&
        paymentOrder.paymentMethods.length > 0 && (
          <PaymentMethodsCard
            paymentMethods={paymentOrder.paymentMethods}
            ibanisationReference={paymentOrder.ibanisationReference}
            contractId={paymentOrder.contractId}
          />
        )}

      {paymentOrder.payee && (
        <Card
          heading={renderCardHeader(t.system("payee"))}
          space={ESpacings.base}
        >
          <ResourceList
            items={[paymentOrder.payee].map(payee => ({
              ...payee,
              actions: generatePayeeActions,
            }))}
            renderItem={renderPayeeRow}
          />
        </Card>
      )}

      {payeeBankAccount && (
        <Card
          heading={renderCardHeader(
            getLocalizedText(
              "payment.payment_order.management_iban.heading.summary",
            ),
          )}
          space={ESpacings.base}
        >
          <ResourceList
            items={[payeeBankAccount]}
            renderItem={() => (
              <ResourceListItem
                boxProps={{ py: ESpacings.tight }}
                item={payeeBankAccount}
                media={<Icon source="billStack" />}
                mediaSize="medium"
              >
                {payeeBankAccount.iban}
              </ResourceListItem>
            )}
          />
        </Card>
      )}

      <Card
        heading={renderCardHeader(t.system("history"))}
        space={ESpacings.base}
      >
        <Box mb={ESpacings.base}>
          <Activities
            identifier={`payment-Order-${paymentOrder.id}-compact`}
            filter={getPaymentOrderActivityFilter(paymentOrder)}
            limit={3}
            isCompact={true}
            onMoreClick={handleActivityMoreClick}
          />
        </Box>
      </Card>
    </>
  );
};
