var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { generatePromises } from "../generators/generator.promises";
import * as contractActions from "./contract.actions";
import { GENERATOR_PARAMS } from "./contract.constants";
var _a = generatePromises(GENERATOR_PARAMS), getDetail = _a.getDetail, getPaged = _a.getPaged, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, internalCreate = _a.create, update = _a.update, updateMembers = _a.updateMembers;
var create = function (dispatch, payload) {
    return internalCreate(dispatch, __assign(__assign({}, payload), { afterCreate: contractActions.afterContractCreate }));
};
export { getDetail, getPaged, getCount, getInfiniteLoad, create, update, updateMembers, };
