import * as legalCaseActions from "../../reduxV2/legalCase/legalCase.actions";
import { EField } from "./schema.addLegalCase.types";
export var isPerformingSubmitActionSelector = function (action, legalCaseId) {
    return function (state) {
        if (legalCaseId && action === "update") {
            var updateState = state.LEGAL_CASES_V2.update[legalCaseId];
            if (updateState) {
                return updateState.pending || false;
            }
            return false;
        }
        return !!state.LEGAL_CASES_V2.create.pending;
    };
};
export var performingSubmitActionResultSelector = function (action, legalCaseId) {
    return function (state) {
        if (legalCaseId && action === "update") {
            return state.LEGAL_CASES_V2.update[legalCaseId];
        }
        return state.LEGAL_CASES_V2.create.id;
    };
};
export var performingSubmitActionErrorSelector = function (action, legalCaseId) {
    return function (state) {
        if (legalCaseId && action === "update") {
            var updateState = state.LEGAL_CASES_V2.update[legalCaseId];
            if (updateState) {
                return updateState.error;
            }
            return;
        }
        return state.LEGAL_CASES_V2.create.error;
    };
};
export var submitActionCreator = function (_a) {
    var contract = _a.contract, registration = _a.registration, rentDeposit = _a.rentDeposit, leaseAgreementDocumentPackageId = _a.leaseAgreementDocumentPackageId, onSuccess = _a.onSuccess, legalCase = _a.legalCase;
    return function (formData) {
        var manualContract = undefined;
        var contractSigningPackageId = undefined;
        if (formData[EField.LeaseAgreement]) {
            if (Array.isArray(formData[EField.LeaseAgreement])) {
                manualContract = formData[EField.LeaseAgreement];
            }
            else if (leaseAgreementDocumentPackageId) {
                contractSigningPackageId = leaseAgreementDocumentPackageId;
            }
        }
        var manualRentalDeposit = undefined;
        var rentDepositId = undefined;
        if (rentDeposit) {
            rentDepositId = rentDeposit.id;
        }
        if (formData[EField.ActiveRentalDeposit]) {
            if (Array.isArray(formData[EField.ActiveRentalDeposit])) {
                manualRentalDeposit = formData[EField.ActiveRentalDeposit];
            }
        }
        var manualProofOfRegistration = undefined;
        if (formData[EField.ContractRegistrationProof]) {
            if (Array.isArray(formData[EField.ContractRegistrationProof])) {
                manualProofOfRegistration = formData[EField.ContractRegistrationProof];
            }
        }
        var manualPropertyInspections = undefined;
        if (formData[EField.PropertyInspections]) {
            if (Array.isArray(formData[EField.PropertyInspections])) {
                manualPropertyInspections = formData[EField.PropertyInspections];
            }
        }
        var registeredLetters = undefined;
        if (formData[EField.RegisteredLetters]) {
            if (Array.isArray(formData[EField.RegisteredLetters])) {
                registeredLetters = formData[EField.RegisteredLetters];
            }
        }
        /*   let manualProofOfPropertyInspections:
        | IUploadableDocument[]
        | undefined = undefined;
      if (formData[EField.PropertyInspectionRegistrationProof]) {
        if (Array.isArray(formData[EField.PropertyInspectionRegistrationProof])) {
          manualProofOfPropertyInspections = formData[
            EField.PropertyInspectionRegistrationProof
          ].filter((doc: string | File) => typeof doc !== "string");
        }
      }
     */
        var manualPayments = formData[EField.ManualLatePayments];
        var previousManualPayments = formData[EField.PreviouslyAddedLatePayments];
        if (legalCase) {
            return legalCaseActions.updateWithDocumentsStart.getAction({
                legalCase: legalCase,
                contract: contract,
                manualContract: manualContract,
                manualProofOfRegistration: manualProofOfRegistration,
                manualPropertyInspections: manualPropertyInspections,
                registeredLetters: registeredLetters,
                //manualProofOfPropertyInspections,
                manualRentalDeposit: manualRentalDeposit,
                damageDocuments: formData[EField.DamageDocuments] || [],
                extraDocuments: formData[EField.ExtraDocuments] || [],
                damageInfo: formData[EField.DamageInfo],
                extraInfo: formData[EField.ExtraInfo],
                payments: formData[EField.LatePayments],
                manualPayments: manualPayments,
                previousManualPayments: previousManualPayments,
                onSuccess: onSuccess,
            });
        }
        return legalCaseActions.createWithDocumentsStart.getAction({
            contract: contract,
            manualContract: manualContract,
            manualProofOfRegistration: manualProofOfRegistration,
            manualRentalDeposit: manualRentalDeposit,
            manualPropertyInspections: manualPropertyInspections,
            contractSigningPackageId: contractSigningPackageId,
            registeredLetters: registeredLetters,
            rentDepositId: rentDepositId,
            rentRegistrationId: registration === null || registration === void 0 ? void 0 : registration.id,
            damageDocuments: formData[EField.DamageDocuments],
            extraDocuments: formData[EField.ExtraDocuments],
            damageInfo: formData[EField.DamageInfo],
            extraInfo: formData[EField.ExtraInfo],
            payments: formData[EField.LatePayments],
            manualPayments: formData[EField.ManualLatePayments],
            onSuccess: onSuccess,
        });
    };
};
