import { IContact } from "@rentiohq/shared/dist/types/contact.types";

export interface IExtraData {
  contact: IContact;
}

export enum EField {
  DateOfBirth = "dateOfBirth",
  Nationality = "nationality",
  Address = "address",
  IdCardFront = "IdCardFront",
  IdCardBack = "IdCardBack",
}
