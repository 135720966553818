import { IContact } from "@rentiohq/shared/dist/types/contact.types";

export const getContactActivityFilterContactPart = (contact: IContact) => ({
  where: {
    or: [
      {
        participantId: contact.accountId,
      },
      {
        payeeId: contact.accountId,
      },
      {
        payerId: contact.accountId,
      },
      {
        actorId: contact.accountId,
      },
      {
        contactAccountId: contact.accountId,
      },
      {
        contactId: contact.id,
      },
    ],
  },
});
