import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editor, getDefaultKeyBinding, RichUtils, } from "draft-js";
import "draft-js/dist/Draft.css";
import React from "react";
import Toolbar from "../../../Toolbar";
import ToolbarFooter from "../../../ToolbarFooter";
import { DocumentConfigContext } from "../../contexts/DocumentConfig.context";
import { ReadOnlyContext } from "../../contexts/ReadOnly.context";
import { getTextStylesBackgroundColor, getTextStylesTextColor, } from "../../Editor.config";
import AtomicBlockImage from "./AtomicBlockImage";
import AtomicBlockPageBreak from "./AtomicBlockPageBreak";
import AtomicBlockSignature from "./AtomicBlockSignature";
import * as S from "./EditorData.styles";
import { blockStyler } from "./EditorData.utils";
var EditorData = function (props) {
    var editorState = props.editorState, setEditorState = props.setEditorState, onPressUpdate = props.onPressUpdate, onPressSave = props.onPressSave;
    // Context
    var documentConfig = React.useContext(DocumentConfigContext).documentConfig;
    var isShowingModal = React.useContext(ReadOnlyContext).isShowingModal;
    // Helpers
    var textStylesTextColor = getTextStylesTextColor(documentConfig);
    var textStylesBackgroundColor = getTextStylesBackgroundColor(documentConfig);
    // Refs
    var editor = React.useRef(null);
    // Lifecycle
    React.useEffect(function () {
        var _a;
        (_a = editor.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    // Event handlers
    var handleTab = function (event) {
        // 9: tab
        if (event.keyCode === 9) {
            var newEditorState = RichUtils.onTab(event, editorState, 6);
            if (newEditorState !== editorState) {
                setEditorState(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(event);
    };
    var handleKeyCommand = function (command, editorState, eventTimeStamp) {
        var newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };
    // Render
    var customStyleMap = {};
    Object.keys(textStylesTextColor).forEach(function (key) {
        customStyleMap[key] = textStylesTextColor[key].styles;
    });
    Object.keys(textStylesBackgroundColor).forEach(function (key) {
        customStyleMap[key] = textStylesBackgroundColor[key].styles;
    });
    var blocRenderer = function (contentBlock) {
        var type = contentBlock.getType();
        if (type === "atomic") {
            var contentState = editorState.getCurrentContent();
            var entityKey = contentBlock.getEntityAt(0);
            if (!entityKey) {
                return;
            }
            var entity = contentState.getEntity(entityKey);
            if (!entity) {
                return;
            }
            switch (entity.getType()) {
                case "PAGE_BREAK":
                    return {
                        component: AtomicBlockPageBreak,
                        editable: false,
                        props: {},
                    };
                case "IMAGE":
                    return {
                        component: AtomicBlockImage,
                        editable: false,
                        props: {
                            entityKey: entityKey,
                        },
                    };
                case "SIGNATURE":
                    return {
                        component: AtomicBlockSignature,
                        editable: false,
                        props: {
                            entityKey: entityKey,
                        },
                    };
                default:
                    break;
            }
        }
    };
    return (_jsxs(S.Wrap, { children: [_jsx(S.InnerWrap, { children: _jsx(S.EditorWrap, { children: _jsx(S.ContentFontWrap, { children: _jsx(Editor, { ref: editor, readOnly: isShowingModal, editorState: editorState, onChange: setEditorState, onTab: handleTab, handleKeyCommand: handleKeyCommand, blockRendererFn: blocRenderer, blockStyleFn: blockStyler, customStyleMap: customStyleMap }) }) }) }), _jsx(Toolbar, { editorState: editorState, setEditorState: setEditorState }), _jsx(ToolbarFooter, { onPressUpdate: onPressUpdate, onPressSave: onPressSave })] }));
};
export default EditorData;
