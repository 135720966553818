var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useInternalMode } from "../../../redux/system/system.hooks";
import DropdownMenu from "../../DropdownMenu";
import RentioInternalRenderer from "../../RentioInternalRenderer";
import Stack from "../../Stack";
import Button from "../Button";
export var ButtonActions = function (props) {
    var actionsRaw = props.actions, options = props.options;
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    var actions = internalModeEnabled
        ? actionsRaw
        : actionsRaw.filter(function (action) { return !action.isInternal; });
    var _a = Object.assign({
        dropdownPlacement: "bottom-end",
    }, options), overrides = _a.overrides, distribution = _a.distribution, alignment = _a.alignment, dropdownPlacement = _a.dropdownPlacement;
    if (actions.length === 0) {
        return null;
    }
    var renderButton = function (params) {
        var action = params.action, overrides = params.overrides;
        var onClick = action.onClick, content = action.content, isInternal = action.isInternal, actionRest = __rest(action, ["onClick", "content", "isInternal"]);
        if (isInternal) {
            return (_jsx(RentioInternalRenderer, { children: _jsx(Button, __assign({ onClick: onClick }, actionRest, overrides, { children: content })) }));
        }
        return (_jsx(Button, __assign({ onClick: onClick }, actionRest, overrides, { children: content })));
    };
    var actionComponents = actions.map(function (action, index) {
        return action.dropdown ? (_jsx(DropdownMenu, __assign({ actions: action.dropdown, triggerProps: {
                placement: dropdownPlacement,
            } }, { children: _jsx("button", { children: renderButton({
                    action: action,
                    overrides: __assign(__assign({}, overrides), { iconAfterName: "chevronDown" }),
                }) }) }), index)) : (renderButton({ action: action, overrides: overrides }));
    });
    if (actions.length === 1) {
        return _jsx(_Fragment, { children: actionComponents });
    }
    return (_jsx(Stack, __assign({ distribution: distribution, alignment: alignment }, { children: actionComponents })));
};
// @deprecated Use `<ButtonActions ... />` instead
export var renderActions = function (actions, options) { return (_jsx(ButtonActions, { actions: Array.isArray(actions) ? actions : [actions], options: options })); };
